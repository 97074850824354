import { useState, useEffect } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Box, Stack } from '@mui/material'
import { LogoLayout, TextFieldClinic, ButtonClinic } from 'components'
import { useNotification } from 'hooks'
import { requestResetPassword } from 'services'
import { registerPageAccess, sanitizeString } from 'helpers'
import logoColor from 'images/wida-logo-color.svg'
import useFormPasswordReset from './password-reset-form'
import { IFormInput } from './password-reset-types'
import {
  SuccessText,
  TitleText,
  DescriptionText,
} from './password-reset-styles'

function PasswordReset({ type }: any): JSX.Element {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const { control, handleSubmit } = useFormPasswordReset()
  const { errorToast } = useNotification()

  useEffect(() => {
    registerPageAccess('Recuperacao de Senha')
  }, [])

  const onSubmit: SubmitHandler<IFormInput> = async (params) => {
    try {
      const body = {
        cpf: sanitizeString(params.cpf),
        type,
      }

      await requestResetPassword(body)

      setShowSuccessMessage(true)
    } catch (error: any) {
      console.error(error)
      errorToast(error.message)
    }
  }

  return (
    <LogoLayout textType='signin'>
      <Box
        px={{ xs: 3, sm: 14, lg: 8 }}
        pt={{ xs: 7.5, sm: 14, lg: 7.5 }}
        pb={{ xs: 4, sm: 11, lg: 8 }}
        height='100%'
        display='flex'
        flexDirection='column'
        component='form'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          width='100%'
          mt={{ xs: 5, sm: 10, lg: 5 }}
          mb={{ xs: 6, sm: 14, lg: 7 }}
          display='flex'
          justifyContent='center'
        >
          <img src={logoColor} alt='Wida Logo' height={38} />
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          flexGrow={1}
          width='100%'
        >
          {showSuccessMessage ? (
            <ResetSuccess type={type} />
          ) : (
            <ResetForm control={control} />
          )}
        </Box>
      </Box>
    </LogoLayout>
  )
}

function ResetForm({ control }: any): JSX.Element {
  return (
    <>
      <Box display='flex' flexDirection='column' height='100%'>
        <Box mb={{ xs: 1, sm: 2 }}>
          <TitleText>Redefinir sua senha</TitleText>
        </Box>
        <Stack>
          <DescriptionText>
            Insira seu CPF e enviaremos para seu e-mail um link de redefinição
            de senha.
          </DescriptionText>
          <DescriptionText>
            Cheque sua caixa de entrada ou de spam
          </DescriptionText>
        </Stack>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
        >
          <Box
            mt={{ xs: 3, sm: 6, lg: 8 }}
            mb={{ xs: 5, sm: 10, lg: 18 }}
            width={{ xs: '224px', sm: '280px' }}
          >
            <TextFieldClinic
              name='cpf'
              control={control}
              label='CPF'
              mask='000.000.000-00'
            />
          </Box>
        </Box>
      </Box>
      <Box flexGrow={1} display='flex' justifyContent='center'>
        <ButtonClinic
          width={180}
          variant='outlined'
          size='small'
          color='purple'
          fontWeight='bold'
          type='submit'
        >
          Enviar
        </ButtonClinic>
      </Box>
    </>
  )
}

function ResetSuccess({ type }: any): JSX.Element {
  const navigate = useNavigate()

  return (
    <Box display='flex' flexDirection='column' height='100%' justifyContent={{ xs: 'space-between', sm: 'flex-start', lg: 'center'}} alignItems='center'>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
      >
        <Box mb={{ xs: 4, lg: 2 }} >
          <TitleText>Redefinir sua senha</TitleText>
        </Box>
        <Box>
          <SuccessText>Enviamos uma mensagem de redefinição.</SuccessText>
          <SuccessText>Cheque sua caixa de entrada ou de spam.</SuccessText>
        </Box>
      </Box>
      <Box mt={{ sm: 11, lg: 18 }}>
        <ButtonClinic
          width={180}
          variant='outlined'
          size='small'
          color='purple'
          fontWeight='bold'
          type='button'
          onClick={() => navigate(`/signin/${type}`)}
        >
          Voltar ao login
        </ButtonClinic>
      </Box>
    </Box>
  )
}

export default PasswordReset
