import { createContext, useContext } from 'react'
import { IDLE } from 'helpers/constants'

export const CreatePatientReportContext = createContext<{
  respondersWithResponses: any
  selectedFormRequests: any
  setSelectedFormRequests: any
  activeStep: number
  setActiveStep: any
  createPatientReport: any
  setCreatePatientReport: any
  setSteps: any
  steps: any
  generatedReportParams: any
  setGeneratedReportParams: any
  selectedTags: string[]
  setSelectedTags: any
  patient: any
  setPatient: any
  clinicalOpinion: any
  setClinicalOpinion: any
  status: any
  setStatus: any
}>({
  respondersWithResponses: [],
  selectedFormRequests: [],
  setSelectedFormRequests: () => {},
  activeStep: 0,
  setActiveStep: () => {},
  createPatientReport: null,
  setCreatePatientReport: () => {},
  steps: [],
  setSteps: () => {},
  generatedReportParams: {},
  setGeneratedReportParams: () => {},
  selectedTags: [],
  setSelectedTags: () => {},
  patient: [],
  setPatient: () => {},
  clinicalOpinion: [],
  setClinicalOpinion: () => {},
  status: IDLE,
  setStatus: () => {},
})

export const useCreatePatientReportContext = () => {
  const context = useContext(CreatePatientReportContext)
  return context
}
