import { HeaderClinic } from 'components'
import { ClinicContainer, ContentContainer } from './clinic-layout-styles'

interface IDashboardLayoutProps {
  children: JSX.Element | JSX.Element[]
}

function ClinicLayout({ children }: IDashboardLayoutProps): JSX.Element {
  return (
    <ClinicContainer px={3}>
      <HeaderClinic />
      <ContentContainer>{children}</ContentContainer>
    </ClinicContainer>
  )
}

export default ClinicLayout
