import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import Button from 'components/button/button'
import { styled, keyframes } from '@mui/material/styles'
import { GRAPHIC_HEIGHT } from 'helpers/constants'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const Container = styled(Box)<any>(({ isDragging, flipped }) => {
  return {
    minWidth: '280px',
    height: '500px',
    margin: '8px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: '16px',
    border: '0.5px solid #ECECEC',
    cursor: isDragging ? 'grab !important' : 'default !important',
    transformStyle: 'preserve-3d',
    transition: 'transform ease 500ms',
    transform: flipped ? 'rotateY(-180deg)' : 'rotateY(0deg)',
  }
})

export const ContentContainer = styled(Box)<any>(({ theme, flipped }) => ({
  transform: flipped ? 'rotateY(-180deg)' : 'rotateY(0deg)',
  animation: flipped
    ? `${fadeIn} 1000ms ${theme.transitions.easing.easeInOut}`
    : 'none',
}))

export const InfosContainer = styled(Box)({
  padding: '32px 0px 0px 0px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flexGrow: 1,
})

export const DividerLine = styled('div')<any>(({ flipped }) => ({
  borderTop: '1px solid #858585',
  width: flipped ? '48px' : '78px',
  position: 'relative',
  top: -GRAPHIC_HEIGHT / 2,
  zIndex: 2,
}))

export const TagName = styled('span')({
  fontFamily: 'Quicksand',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '21px',
  color: '#525252',
})

export const DateLabel = styled('span')<any>({
  fontFamily: 'Zen Kaku Gothic Antique',
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '16px',
  color: '#525252',
})

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    padding: '16px',
    background: '#FFFFFF',
    border: '1px solid #ECECEC',
    boxShadow: '6px 4px 16px rgba(0, 0, 0, 0.04)',
  },
}))

export const CopyResumeButton = styled(Button)({
  height: '25px',
  color: '#B1B1B1',
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '10px',
  lineHeight: '13px',
  border: '1px solid #B1B1B1',
  '&:hover': {
    backgroundColor: 'transparent',
  },
})
