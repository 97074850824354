import { useForm } from 'react-hook-form'
import { clinicProfileValidationCodeResolver } from 'resolvers'

export default function useCommunicationValidationCodeForm() {
  const methods = useForm<any>({
    mode: 'onBlur',
    resolver: clinicProfileValidationCodeResolver,
    defaultValues: {
      validationCode: '',
    },
  })

  return methods
}
