import { Rect, Svg } from '@react-pdf/renderer'

const DashedSvg = () => {
  return (
    <Svg width='549px' height='67px' viewBox='0 0 549 66'>
      <Rect
        width='547px'
        color='#DBDCE1'
        height='66px'
        fill='none'
        rx='13'
        ry='13'
        stroke='#DBDCE1'
        strokeWidth='0.7'
        strokeDasharray='5, 4'
        stroke-dashoffset='0'
        stroke-linecap='round'
      />
    </Svg>
  )
}

export default DashedSvg