import { useEffect } from 'react'
import { Stack, Box } from '@mui/material'
import PaymentIcon from 'components/icons/clinic-profile/payment-icon'
import { useClinicContext } from 'contexts'
import { TitleText, SubtitleText } from './payment-tab-styles'

function PaymentTab(): JSX.Element {
  const { handleSave } = useClinicContext()

  useEffect(() => {
    handleSave.current = () => true

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Stack
      p='0 40px'
      flexGrow={1}
      height='100%'
      justifyContent='center'
      alignItems='center'
    >
      <PaymentIcon />
      <Box mt={'32px'} mb={'8px'}>
        <TitleText>Estamos em versão beta</TitleText>
      </Box>
      <Box mb={10}>
        <SubtitleText>
          No momento, você pode desfrutar do Wida gratuitamente
        </SubtitleText>
      </Box>
    </Stack>
  )
}

export default PaymentTab
