import { useEffect, useState, SyntheticEvent, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { ForwardToInboxOutlined } from '@mui/icons-material'
import {
  ClinicLayout,
  PatientListEmpty,
  PatientList,
  PendingInviteList,
  InviteListEmpty,
  ArchivedPatientList,
  ArchivedPatientListEmpty,
  ButtonClinic,
} from 'components'
import ListIcon from 'components/icons/list-icon'
import ArchiveIcon from 'components/icons/archive-icon'
import { useAuthContext } from 'contexts'
import { getClinicPatients, getPendingInvites } from 'services'
import {
  getPatientFullName,
  registerPageAccess,
  registerTrack,
  findInWord,
} from 'helpers'
import {
  formatPatientFromBack,
  formatCaregiverFromBack,
} from 'helpers/formatters'
import { FETCHING, IDLE } from 'helpers/constants'
import { UserStatus } from 'types/user-status'
import { Tabs, Tab } from './my-patients-styles'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={
        value === index ? { display: 'flex', flexGrow: 1, height: '100%' } : {}
      }
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 2, display: 'flex', flexGrow: 1 }}>{children}</Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

function MyPatients(): JSX.Element {
  const [status, setStatus] = useState(FETCHING)
  const [patients, setPatients] = useState([])
  const [invites, setInvites] = useState([])
  const [displayedPatients, setDisplayedPatients] = useState([])
  const [displayedInvites, setDisplayedInvites] = useState([])
  const [filter, setFilter] = useState('')
  const [activeTab, setActiveTab] = useState<number>(0)

  const navigate = useNavigate()
  const { user } = useAuthContext()

  const fetchData = useCallback(async () => {
    try {
      const [patients, invites] = await Promise.all([
        getClinicPatients(),
        getPendingInvites(),
      ])

      const formattedPatients = patients.map((patientData: any) => {
        return patientData.caregiver.accountInfo.isTutor
          ? formatCaregiverFromBack(patientData)
          : formatPatientFromBack(patientData)
      })

      setPatients(formattedPatients)
      setInvites(invites)
    } catch (error: any) {
      console.error(error.message)
    } finally {
      setStatus(IDLE)
    }
  }, [])

  const filterPatients = (status: UserStatus) => {
    return displayedPatients.filter(
      (patient: any) => patient.user.status === status
    )
  }

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    registerPageAccess('Home Clinico', { clinic_id: user.clinicId })
  }, [user])

  useEffect(() => {
    setDisplayedPatients(
      patients.filter((p: any) => findInWord(getPatientFullName(p), filter))
    )

    setDisplayedInvites(invites.filter((i: any) => findInWord(i.name, filter)))
  }, [filter, patients, invites])

  const handleChange = async (event: SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }

  const onFilter = async (value: string) => {
    setFilter(value)
  }

  if (status !== IDLE) return <></>

  return (
    <ClinicLayout>
      <Box
        py={4}
        px={10}
        display='flex'
        flexDirection='column'
        height='100%'
        flexGrow={1}
      >
        <Tabs
          TabIndicatorProps={{
            style: {
              backgroundColor: 'transparent',
              borderRadius: '4px',
            },
          }}
          value={activeTab}
          onChange={handleChange}
        >
          <Tab
            icon={
              <ListIcon
                color={activeTab === 0 ? '#EE7262' : '#657787'}
                margin='0 8px 0 0'
              />
            }
            iconPosition='start'
            label='Meus pacientes'
            onClick={() => {
              registerTrack('Clica Pacientes Ativos', {
                clinic_id: user.clinicId,
              })
            }}
            {...a11yProps(0)}
          />
          <Tab
            icon={<ForwardToInboxOutlined />}
            iconPosition='start'
            label={`Convites pendentes ${
              invites.length === 0 ? '' : `(${invites.length})`
            }`}
            onClick={() => {
              registerTrack('Clica Convites Pendentes', {
                clinic_id: user.clinicId,
              })
            }}
            {...a11yProps(1)}
          />
          <Tab
            icon={
              <ArchiveIcon
                color={activeTab === 2 ? '#EE7262' : '#657787'}
                margin='0 8px 0 0'
              />
            }
            iconPosition='start'
            label='Pacientes arquivados'
            onClick={() => {
              registerTrack('Clica Pacientes Arquivados', {
                clinic_id: user.clinicId,
              })
            }}
            {...a11yProps(1)}
          />
          <Box display='flex' flexGrow={1} justifyContent='flex-end'>
            <ButtonClinic
              width={159}
              variant='contained'
              color='green'
              fontWeight='bold'
              size='small'
              onClick={() => {
                registerTrack('Clica Novo Questionario', {
                  clinic_id: user.clinicId,
                  source: 'home',
                })
                navigate('/invite_patient')
              }}
              icon='addPerson'
              iconPosition='start'
              iconSize={24}
            >
              Novo paciente
            </ButtonClinic>
          </Box>
        </Tabs>
        <TabPanel value={activeTab} index={0}>
          {!Boolean(filterPatients(UserStatus.ACTIVE).length) &&
          !Boolean(filter) ? (
            <PatientListEmpty />
          ) : (
            <PatientList
              filterValue={filter}
              onFilter={onFilter}
              patients={filterPatients(UserStatus.ACTIVE)}
              fetchPatients={fetchData}
            />
          )}
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          {!Boolean(displayedInvites.length) && !Boolean(filter) ? (
            <InviteListEmpty />
          ) : (
            <PendingInviteList
              invites={displayedInvites}
              onFilter={onFilter}
              fetchPatients={fetchData}
            />
          )}
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          {!Boolean(filterPatients(UserStatus.ARCHIVED).length) &&
          !Boolean(filter) ? (
            <ArchivedPatientListEmpty />
          ) : (
            <ArchivedPatientList
              archivedPatients={filterPatients(UserStatus.ARCHIVED)}
              onFilter={onFilter}
              fetchPatients={fetchData}
            />
          )}
        </TabPanel>
      </Box>
    </ClinicLayout>
  )
}

export default MyPatients
