import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { Stack } from '@mui/material'
import { TagCard, ResultsEmpty } from 'components/results'
import { useResultsContext } from 'contexts'
import { sortDataset } from 'helpers'
import { reorder } from 'helpers/dashboards'
import { Container } from './tags-container-styles'

function TagsContainer(): JSX.Element {
  const [groupByTags, setGroupByTags] = useState<any>([])

  const { dataset, databaseTags, filteredDataset } = useResultsContext()
  const { getValues } = useFormContext()

  useEffect(() => {
    const selectedTags = getValues('selectedTags')

    const groupByTags = selectedTags.map((tagId: string) => {
      const findTag = databaseTags.find(
        (databaseTag: any) => databaseTag.id === tagId
      )

      const orderedTagResponses = sortDataset(
        filteredDataset.filter((data: any) => tagId === data.tag.id),
        'DESC'
      )

      return {
        id: tagId,
        name: findTag.name,
        responses: orderedTagResponses,
        flipped: false,
      }
    })

    setGroupByTags(groupByTags)
  }, [dataset, databaseTags, filteredDataset, getValues])

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return // dropped outside the list

    const newItems = reorder(groupByTags, source.index, destination.index)

    setGroupByTags(newItems)
  }

  const handleFlipTagCard = (tagId: any) => {
    setGroupByTags((groupByTags: any) =>
      groupByTags.map((groupByTag: any) =>
        groupByTag.id === tagId
          ? { ...groupByTag, flipped: !groupByTag.flipped }
          : groupByTag
      )
    )
  }

  if (groupByTags.length === 0) {
    return <ResultsEmpty />
  }

  return (
    <Stack alignItems='center'>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable-list' direction='horizontal'>
          {(provided: any) => {
            return (
              <Container
                width='100%'
                p={1}
                direction='row'
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {groupByTags.map((tag: any, index: Number) => {
                  return (
                    <TagCard
                      key={tag.id}
                      tag={{
                        id: tag.id,
                        name: tag.name,
                      }}
                      index={index}
                      responses={tag.responses}
                      flipped={tag.flipped}
                      setFlipped={handleFlipTagCard}
                    />
                  )
                })}
                {provided.placeholder}
              </Container>
            )
          }}
        </Droppable>
      </DragDropContext>
    </Stack>
  )
}

export default TagsContainer
