import { CSSProperties } from 'react'

const EditIcon = ({
  color = '#8D92BE',
  style,
}: {
  color?: string
  style?: CSSProperties
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='20'
      viewBox='0 0 17 20'
      fill={color}
      style={style}
    >
      <path d='M15.5003 18H1.5003C1.23508 18 0.980726 18.1054 0.79319 18.2929C0.605654 18.4804 0.500297 18.7348 0.500297 19C0.500297 19.2652 0.605654 19.5196 0.79319 19.7071C0.980726 19.8947 1.23508 20 1.5003 20H15.5003C15.7655 20 16.0199 19.8947 16.2074 19.7071C16.3949 19.5196 16.5003 19.2652 16.5003 19C16.5003 18.7348 16.3949 18.4804 16.2074 18.2929C16.0199 18.1054 15.7655 18 15.5003 18ZM1.5003 16H1.5903L5.7603 15.62C6.21709 15.5745 6.64433 15.3732 6.9703 15.05L15.9703 6.05001C16.3196 5.68098 16.5084 5.18852 16.4953 4.68055C16.4822 4.17257 16.2682 3.69052 15.9003 3.34001L13.1603 0.600011C12.8027 0.264106 12.3341 0.0713693 11.8436 0.0584625C11.3532 0.0455557 10.8751 0.21338 10.5003 0.530011L1.5003 9.53001C1.17706 9.85598 0.975801 10.2832 0.930297 10.74L0.500297 14.91C0.486826 15.0565 0.505831 15.2041 0.555958 15.3424C0.606085 15.4807 0.686099 15.6062 0.790297 15.71C0.883737 15.8027 0.994553 15.876 1.11639 15.9258C1.23823 15.9755 1.36869 16.0008 1.5003 16ZM11.7703 2.00001L14.5003 4.73001L12.5003 6.68001L9.8203 4.00001L11.7703 2.00001ZM2.8703 10.91L8.5003 5.32001L11.2003 8.02001L5.6003 13.62L2.6003 13.9L2.8703 10.91Z' />
    </svg>
  )
}

export default EditIcon
