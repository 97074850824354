import { format } from 'date-fns'
import { toDate } from 'helpers'

export function generateUpdateBody(role: string, params: any) {
  if (role === 'patient') {
    return {
      personalInfo: {
        firstName: params.firstName.trim(),
        lastName: params.lastName.trim(),
        pronoun: params.pronoun.toUpperCase(),
        birthdate: format(toDate(params.birthdate), 'yyyy-MM-dd'),
      },
    }
  }

  if (role === 'informant') {
    return {
      personalInfo: {
        firstName: params.firstName.trim(),
        lastName: params.lastName.trim(),
      },
    }
  }

  if (role === 'caregiver') {
    return {
      personalInfo: {
        firstName: params.caregiverFirstName.trim(),
        lastName: params.caregiverLastName.trim(),
      },
      patient: {
        personalInfo: {
          firstName: params.firstName.trim(),
          lastName: params.lastName.trim(),
          pronoun: params.pronoun.toUpperCase(),
          birthdate: format(toDate(params.birthdate), 'yyyy-MM-dd'),
        },
      },
    }
  }
}
