import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  footer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginBottom: 12,
    marginTop: 24,
    minHeight: 24,
    position: 'relative',
  },
  copyrightView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    left: -12,
    bottom: 19,
    transform: 'rotate(-90deg)',
  },
  copyrightText: {
    fontSize: 7,
    fontWeight: 400,
    fontFamily: 'Zen Kaku Gothic Antique',
    color: '#596B7B',
    width: '42px',
  },
})
