export const formatClinicFromBack = (payload: any) => ({
  id: payload.id,
  professionalCouncil: payload.professionalCouncil,
  professionalCouncilState: payload.professionalCouncilState,
  register: payload.register,
  state: payload.state,
  telephone: payload.telephone,
  email: payload.user.email,
  firstName: payload.personalInfo.firstName,
  lastName: payload.personalInfo.lastName,
  user: payload.user,
  cpf: payload.user.cpf,
})

export const formatNotificationFromBack = (payload: any) => ({
  ...payload,
  message: 'Uma nova resposta foi registrada nos resultados de ',
})
