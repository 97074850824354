import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  titleView: {
    position: 'relative',
    justifyContent: 'center',
    height: 32,
    width: 146,
    marginTop: 36,
    backgroundColor: '#8D92BE',
    borderTopRightRadius: 24,
    borderBottomRightRadius: 24,
    paddingLeft: 22,
  },
  secondaryTitleView: {
    position: 'absolute',
    width: 150,
    height: 32,
    borderWidth: 1,
    borderColor: '#8D92BE',
    borderTopRightRadius: 24,
    borderBottomRightRadius: 24,
    top: 6,
  },
  title: {
    color: '#FFFFFF',
    fontSize: '13px',
    fontWeight: 600,
    fontStyle: 'bold',
    lineHeight: 1.5,
  },
  logoView: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 16,
    marginTop: 24,
    marginRight: 24,
  },
  logoText: {
    color: '#596B7B',
    fontSize: '10px',
    fontWeight: 500,
    fontStyle: 'semibold',
    lineHeight: 1.5,
  },
  headerText: {
    color: '#596B7B',
    fontSize: 10,
    lineHeight: 1.5,
    fontFamily: 'Zen Kaku Gothic Antique',
  },
  logo: {
    height: 18,
    width: 65,
    marginBottom: 2,
  },
  logoW: {
    height: 16,
    width: 29,
    marginLeft: 8,
  },
  logoWView: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  dashedBox: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    padding: '12px 20px',
    width: '100%',
  },
  dashedSvgView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
  },
  infoText: {
    fontSize: 9.5,
    fontWeight: 400,
    fontFamily: 'Zen Kaku Gothic Antique',
    color: '#596B7B',
  },
})
