import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import AccordionMui from '@mui/material/Accordion'
import AccordionSummaryMui from '@mui/material/AccordionSummary'

export const QuestionText = styled(Typography)({
  fontSize: '16px',
  color: '#353F48',
})

export const Accordion = styled(AccordionMui)({
  boxShadow: 'none',
  '&::before': { content: 'none' },
  '.MuiAccordionSummary-root': {
    padding: 0,
  },
})

export const AccordionSummary = styled(AccordionSummaryMui)({
  justifyContent: 'start',
  '.MuiAccordionSummary-content': {
    flexGrow: 0,
    mr: 1,
    my: 2,
  },
})
