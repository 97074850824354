import yesIcon from 'images/icons/yes.svg'
import sosoIcon from 'images/icons/soso.svg'
import noIcon from 'images/icons/no.svg'
import expandIcon from 'images/icons/expand-icon.svg'
import flipIcon from 'images/icons/flip-icon.svg'

export function LabelIconYes(): JSX.Element {
  return <img src={yesIcon} height='8px' alt='ícone de presente' />
}

export function LabelIconSoso(): JSX.Element {
  return <img src={sosoIcon} height='8px' alt='ícone de indeterminado' />
}

export function LabelIconNo(): JSX.Element {
  return <img src={noIcon} height='8px' alt='ícone de ausente' />
}

export function LabelIconFlip(): JSX.Element {
  return (
    <img src={flipIcon} height='14px' alt='ícone de transformação do card' />
  )
}

export function LabelIconExpand(): JSX.Element {
  return <img src={expandIcon} height='12px' alt='ícone de expansão' />
}
