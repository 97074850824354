import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Stack, IconButton } from '@mui/material'
import { Modal } from 'components'
import { useAuthContext } from 'contexts'
import { isUserInformant } from 'helpers'
import {
  DividerStyled,
  MenuItemText,
  TermsAndPoliciesText,
  TermsAndPoliciesLink,
  CallMadeRoundedIconStyled,
  LogoutRoundedIconStyled,
} from './root-styles'

function SettingsRoot(): JSX.Element {
  const [termsModalOpen, setTermsModalOpen] = useState(false)
  const [policyModalOpen, setPolicyModalOpen] = useState(false)

  const { signout, userInfos } = useAuthContext()

  const navigate = useNavigate()

  const menuList: any[] = useMemo(() => {
    const isInformant = isUserInformant(userInfos.user)

    return [
      {
        label: 'Dados pessoais',
        icon: <CallMadeRoundedIconStyled />,
        onClick: () => navigate('/settings/personal_infos'),
      },
      {
        label: 'Notificações',
        icon: <CallMadeRoundedIconStyled />,
        onClick: () => navigate('/settings/notifications'),
      },
      ...(!isInformant
        ? [
            {
              label: 'Meus convidados',
              icon: <CallMadeRoundedIconStyled />,
              onClick: () => navigate('/settings/informants'),
            },
          ]
        : []),
      {
        label: 'Segurança',
        icon: <CallMadeRoundedIconStyled />,
        onClick: () => navigate('/settings/security'),
      },
      {
        label: 'Fale conosco',
        icon: <CallMadeRoundedIconStyled />,
        onClick: () => navigate('/settings/contact_us'),
      },
      {
        label: 'Sair',
        icon: <LogoutRoundedIconStyled />,
        onClick: () => signout('patient'),
      },
    ]

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signout, navigate])

  return (
    <>
      <Box
        flexGrow={1}
        mb={3}
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        px={2}
      >
        {menuList.map((menuItem: any) => (
          <Stack key={menuItem.label} width='100%'>
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              my={2}
              onClick={menuItem.onClick}
              sx={{ cursor: 'pointer' }}
            >
              <MenuItemText>{menuItem.label}</MenuItemText>
              <IconButton onClick={menuItem.onClick} sx={{ color: '#fff' }}>
                {menuItem.icon}
              </IconButton>
            </Box>
            <DividerStyled />
          </Stack>
        ))}
      </Box>
      <Box
        mb={3}
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
      >
        <TermsAndPoliciesText>
          <TermsAndPoliciesLink onClick={() => setTermsModalOpen(true)}>
            Termos de uso
          </TermsAndPoliciesLink>{' '}
          e{' '}
          <TermsAndPoliciesLink onClick={() => setPolicyModalOpen(true)}>
            política de privacidade
          </TermsAndPoliciesLink>
        </TermsAndPoliciesText>
      </Box>
      <Modal
        title=''
        isOpen={termsModalOpen}
        handleClose={() => setTermsModalOpen(false)}
        component={
          <iframe
            title='term of use'
            src='./term-of-use.html'
            style={{ width: '100%', border: 'none' }}
          />
        }
        headerPadding='16px 16px 0'
        bodyPadding='0 0 40px 36px'
        width='100%'
      />
      <Modal
        title=''
        isOpen={policyModalOpen}
        handleClose={() => setPolicyModalOpen(false)}
        component={
          <iframe
            title='privacy policy'
            src='./privacy-policy.html'
            style={{ width: '100%', border: 'none' }}
          />
        }
        headerPadding='16px 16px 0'
        bodyPadding='0 0 40px 36px'
        width='100%'
      />
    </>
  )
}

export default SettingsRoot
