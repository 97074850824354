import { useEffect, useMemo } from 'react'
import { Box, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { RouteLayout, ButtonClean } from 'components'
import { useAuthContext } from 'contexts'
import { getUserQuestionnaire } from 'services'
import { useNotification } from 'hooks'
import {
  isUserInformant,
  getUserRole,
  registerTrack,
  isUserPatient,
  registerPageAccess,
} from 'helpers'
import logoBW from 'images/wida-logo.svg'
import {
  Title,
  Loading,
  SuccessIcon,
  ErrorIcon,
} from './finish-questionnaire-styles'

function FinishQuestionnaire({
  patientInfo,
  submitting,
  error,
  resendQuestionnaire,
  isInformantPatient,
  formId,
}: any): JSX.Element {
  const navigate = useNavigate()
  const { user } = useAuthContext()
  const { errorToast } = useNotification()

  const userRole = useMemo(() => getUserRole(user), [user])

  const rolesText: any = useMemo(
    () => ({
      patient: 'Agora você pode convidar outras pessoas para responder.',
      caregiver: 'Agora você pode convidar outras pessoas para responder.',
      informant: `Muito obrigado por suas informações${
        isInformantPatient ? '.' : ` sobre ${patientInfo?.firstName || '_'}.`
      }`,
    }),
    [patientInfo, isInformantPatient]
  )

  useEffect(() => {
    if (!(submitting || error)) {
      registerTrack('Questionário respondido', {
        type: userRole,
        form_id: formId,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitting, error])

  const handleInformantInvite = async () => {
    try {
      const form = await getUserQuestionnaire()

      if (!form) throw new Error('Ocorreu um erro ao buscar o questionário')

      registerPageAccess('Convidar Informantes', {
        user_id: user.userId,
        patient_id: patientInfo.patientId,
        source: isUserPatient(user) ? 'patient' : 'caregiver',
        answered: true,
      })

      if (!form?.lastAvailableFormId) {
        throw new Error('Ops, algo estranho aconteceu')
      }

      navigate(`/invite_informant?form_id=${form?.lastAvailableFormId}`)
    } catch (error: any) {
      errorToast(error?.message || 'Erro desconhecido')
    }
  }

  return (
    <RouteLayout bg='gradient'>
      <Stack
        flexGrow={1}
        alignItems='center'
        justifyContent='space-between'
        m={5}
      >
        <Stack
          mt={5}
          alignItems='center'
          justifyContent='space-between'
          height={290}
          width={240}
        >
          <Box>
            <img
              src={logoBW}
              height='30px'
              alt='Logo Wida'
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            />
          </Box>

          <Box display='flex' justifyContent='center'>
            {submitting && <Loading />}
            {error && <ErrorIcon />}
            {!(submitting || error) && <SuccessIcon />}
          </Box>
          <Box>
            <Stack spacing={3}>
              <Title>
                {(() => {
                  if (submitting)
                    return 'Sua resposta está sendo registrada, por favor aguarde e não feche essa tela.'

                  if (error)
                    return 'Ocorreu um erro ao registrar as resposta, por favor envie novamente'

                  return 'Respostas registradas!'
                })()}
              </Title>
              {!(submitting || error) && (
                <Box>
                  <Title>{rolesText[userRole]}</Title>
                </Box>
              )}
            </Stack>
          </Box>
        </Stack>

        <Box width='100%'>
          {submitting || error ? (
            <Box mt={5}>
              <ButtonClean
                onClick={resendQuestionnaire}
                disabled={submitting}
                label='Enviar Novamente'
                icon='call-made'
              />
            </Box>
          ) : (
            <Stack width='100%' spacing={2}>
              {!isUserInformant(user) && (
                <ButtonClean
                  onClick={handleInformantInvite}
                  label='Convidar'
                  icon='call-made'
                />
              )}
              <ButtonClean
                onClick={() => {
                  registerTrack('Clica Voltar Inicio', {
                    user_id: user.userId,
                    patient_id: patientInfo.patientId,
                    source: isUserPatient(user) ? 'patient' : 'caregiver',
                  })
                  navigate('/')
                }}
                label='Finalizar'
                icon='call-made'
              />
            </Stack>
          )}
        </Box>
      </Stack>
    </RouteLayout>
  )
}

export default FinishQuestionnaire
