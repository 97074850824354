import { Input } from '@mui/material'
import { styled } from '@mui/material/styles'
import FormControlMui from '@mui/material/FormControl'
import FormHelperTextMui from '@mui/material/FormHelperText'

export const StyledInput = styled(Input)({
  fontFamily: "'Zen Kaku Gothic Antique', sans-serif",
  padding: '5px 15px',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '23px',
  'input[disabled]': {
    color: '#353F48',
    WebkitTextFillColor: '#353F48',
  },
})

export const FieldContainer = styled(FormControlMui)<any>({
  m: 3,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  '& > div': {
    width: '222px !important',
  },

  '& > div > div > input': {
    width: '37px !important',
    height: '35px !important',
  },

  '@media (min-width: 600px)': {
    '& > div': {
      width: '348px !important',
    },

    '& > div > div > input': {
      width: '58px !important',
      height: '54px !important',
    },
  },
})

export const HelperText = styled(FormHelperTextMui)({
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Quicksand',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '14px',
  margin: 0,
  marginTop: '8px',
})
