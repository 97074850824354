import client from 'services/client'

export const sendLastFormByEmail = async (body: any) => {
  try {
    await client.post('/form-requests/send-email', body)
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const configFormRequestEnvironments = async (id: string, body: any) => {
  try {
    await client.patch(`/form-requests/${id}`, body)
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const createFormRequest = async (body: any) => {
  try {
    await client.post('/form-requests', body)
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const getFormRequest = async (id: string) => {
  try {
    const { data: formRequest } = await client.get(`/form-requests/${id}`)
    return formRequest
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const getUserResponses = async (userId: string) => {
  try {
    const { data: formRequest } = await client.get(
      `/form-requests/patient-history/${userId}`
    )
    return formRequest
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const updateViewedFormRequest = async (id: string) => {
  try {
    const { data: formRequest } = await client.post(
      `/form-requests/viewed/${id}`
    )
    return formRequest
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const sendFormRequestReminder = async (formRequestId: string) => {
  try {
    await client.post(`form-requests/${formRequestId}/send-reminder`)
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}
