import { Controller } from 'react-hook-form'
import { Box, Checkbox, Stack, Tooltip } from '@mui/material'
import { formatDbDateToLabel } from 'helpers'
import {
  answersDictFromBack,
  answersDictToBack,
  answerDict,
} from 'helpers/constants'
import { QuestionText, StyledChip } from './select-questions-styles'

function SelectQuestions({
  questions,
  selectedQuestions,
  name,
  control,
  selectedTag,
  handleTagByQuestion,
  patientResponses,
  activeHistory,
  questionTextConfig = 'questionSelfM',
  highlightTagQuestion,
}: any): JSX.Element {
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { value: oldSelectedQuestions } }: any) => {
          return questions
            .filter((question: any) =>
              question.tags.includes(selectedTag.value)
            )
            .map((question: any) => {
              const isChecked = oldSelectedQuestions.some(
                ({ id }: any) => id === question.id
              )
              const isDisabled =
                isChecked &&
                selectedQuestions.some(({ id, tag }: any) => {
                  if (tag?.value) {
                    return id === question.id && tag.value !== selectedTag.value
                  }
                  return false
                })

              const history = patientResponses[question.id]

              const hasNotPresence =
                history?.length >= 2 &&
                history.every(
                  ({ answer }: any) => answer === answersDictToBack.no
                )

              return (
                <Box
                  key={question.id}
                  px={1}
                  display='flex'
                  alignItems='center'
                  borderLeft='2px solid #F7F7F7'
                >
                  {isDisabled ? (
                    <Tooltip
                      title={'Esta pergunta está selecionada em outro domínio'}
                      arrow
                    >
                      <span style={{ cursor: 'pointer' }}>
                        <Checkbox
                          disabled={true}
                          checked={isChecked}
                          sx={{ p: '6px' }}
                        />
                      </span>
                    </Tooltip>
                  ) : (
                    <Checkbox
                      checked={isChecked}
                      onChange={({ target: { checked } }: any) => {
                        handleTagByQuestion(
                          { ...question, tag: selectedTag },
                          checked
                        )
                      }}
                      sx={{
                        p: '6px',
                        color: 'rgba(0, 0, 0, 0.6)',
                        '&.Mui-checked': {
                          color: '#8D92BE',
                        },
                      }}
                    />
                  )}

                  <QuestionText
                    highlight={
                      highlightTagQuestion?.question?.id === question.id &&
                      highlightTagQuestion?.tag?.name === selectedTag.name
                    }
                  >
                    {question[questionTextConfig]}
                    {activeHistory && Boolean(history) && (
                      <Stack
                        ml={1}
                        display='inline-flex'
                        direction='row'
                        spacing={1}
                        alignItems='center'
                      >
                        {history.map((response: any) => {
                          const answer = answersDictFromBack[response.answer]

                          return (
                            <Tooltip
                              title={formatDbDateToLabel(response.responseDate)}
                              arrow
                              placement='top'
                            >
                              <img
                                src={answerDict[answer].icon}
                                height='10px'
                                width='10px'
                                alt={answerDict[answer].alt}
                              />
                            </Tooltip>
                          )
                        })}
                        {hasNotPresence && (
                          <StyledChip label='Nunca presente' />
                        )}
                      </Stack>
                    )}
                  </QuestionText>
                </Box>
              )
            })
        }}
      />
    </>
  )
}

export default SelectQuestions
