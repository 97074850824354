import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { format } from 'date-fns'
import brLocale from 'date-fns/locale/pt-BR'
import { Box, IconButton, Stack, Tooltip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { SelectStyled } from 'components'
import { useCreatePatientReportContext } from 'contexts/create-patient-report-context'

function ResponderOption({ index, remove, options }: any): JSX.Element {
  const [isHovering, setIsHovering] = useState<boolean>(false)
  const [formRequestOptions, setFormRequestOptions] = useState([])

  const { control, watch, setValue } = useFormContext()
  const { respondersWithResponses } = useCreatePatientReportContext()

  const responderField = watch(`responders.${index}.responder`)
  const formRequestField = watch(`responders.${index}.formRequest`)

  useEffect(() => {
    const findResponder = respondersWithResponses.find(
      ({ id }: any) => id === responderField
    )

    if (!findResponder) return

    setFormRequestOptions(
      findResponder.responses.map((response: any) => ({
        value: response.formRequestId,
        label: format(new Date(response.responseDate), 'dd MMM yy', {
          locale: brLocale,
        }),
      }))
    )

    setValue(`responders.${index}.formRequest`, formRequestField || '')
    setIsHovering(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, responderField, respondersWithResponses, setValue])

  useEffect(() => {
    setIsHovering(false)
  }, [formRequestField])

  return (
    <Stack
      direction='row'
      mb={3}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      alignItems='center'
      width='659px'
    >
      <Box minWidth='361px' maxWidth='361px' mr={3}>
        <SelectStyled
          name={`responders.${index}.responder`}
          control={control}
          options={options}
        />
      </Box>

      <Box minWidth='236px' maxWidth='236px' mr={'6px'}>
        <SelectStyled
          disabled={!Boolean(formRequestOptions.length)}
          name={`responders.${index}.formRequest`}
          control={control}
          options={formRequestOptions}
        />
      </Box>

      <IconButton
        sx={{
          display: isHovering ? 'default' : 'none',
          width: '32px',
          height: '32px',
        }}
        onClick={() => remove(index)}
      >
        <Tooltip title='Remover respondedor' arrow placement='top'>
          <DeleteIcon sx={{ fill: '#CBCCCF' }} />
        </Tooltip>
      </IconButton>
    </Stack>
  )
}

export default ResponderOption
