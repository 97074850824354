import { useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { Box } from '@mui/material'
import { Multiselect } from 'components'
import { IEnvironmentOption } from 'types'
import { useInvitePatientContext, useAuthContext } from 'contexts'
import { useMount, useQuery } from 'hooks'
import { AccordionTitle, TdNormal } from './questionnaire-groups-item-styles'
import { registerTrack } from 'helpers'

interface IQuestionnaireGroupsItemProps {
  environmentOption: IEnvironmentOption
  tags: any
}

function QuestionnaireGroupsItem({
  environmentOption,
  tags,
}: IQuestionnaireGroupsItemProps): JSX.Element {
  const { control, watch } = useFormContext()

  const selectedGroupTags: any = watch(
    `selectedGroupTags.${environmentOption.value}`
  )

  const {
    newQuestionnaireInfos: { selectedQuestions },
  } = useInvitePatientContext()
  const { isMounted } = useMount()
  const { user } = useAuthContext()
  const query = useQuery()

  const selectedCounter = useMemo(() => {
    const tagsQuestions = selectedQuestions.filter((question: any) => {
      return selectedGroupTags.some((selectedGroupTag: any) => {
        const findTag = tags.find((tag: any) => tag.label === selectedGroupTag)
        return question.tags.includes(findTag.value)
      })
    })

    return tagsQuestions.length
  }, [selectedGroupTags, tags, selectedQuestions])

  useEffect(() => {
    if (!isMounted) return

    registerTrack('Altera Ambiente', {
      clinic_id: user.clinicId,
      patient_id: query.get('patient_id'),
      environment: environmentOption.label,
      total: selectedCounter,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroupTags])

  return (
    <Box component='tr'>
      <TdNormal align='left' width='240px'>
        <AccordionTitle>{environmentOption.label}</AccordionTitle>
      </TdNormal>
      <Box
        component='td'
        align='left'
        maxWidth='100px'
        sx={{ borderBottom: '2px solid #E8EBF2', padding: '16px' }}
      >
        <Multiselect
          name={`selectedGroupTags.${environmentOption.value}`}
          control={control}
          options={tags.map((tag: any) => tag.label)}
        />
      </Box>
    </Box>
  )
}

export default QuestionnaireGroupsItem
