import FormControlMui from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { styled } from '@mui/material/styles'

interface SelectAnswerStyledProps {
  isOpen?: boolean
  hasSelectedItem?: boolean
  hasSpace?: boolean
}

export const SelectAnswerStyled = styled(Select)<SelectAnswerStyledProps>(
  ({ isOpen, hasSelectedItem, hasSpace }) => ({
    border: '1px solid #FFF',
    background: '#FDFDFD',
    color: hasSelectedItem ? '#596B7B' : '#CDCDCD',

    '> div': {
      width: '32px !important',
      padding: '0px',
    },

    '& .MuiSelect-select': {
      paddingRight: '0 !important',
      overflow: 'visible',
    },

    '> div > div': {
      border: isOpen ? '1.5px solid #BDC1DD' : '1.5px solid transparent',
      transition: 'cubic-bezier(0.4, 0, 0.2, 1) 157ms',
      borderRadius: hasSpace ? '16px 16px 0 0' : '0 0 16px 16px',
      borderBottom: hasSpace ? 'none' : '',
      borderTop: hasSpace ? '' : 'none',
      width: '30px',
    },

    '> div > div > div': {
      width: '12px',
      height: '12px',
    },

    ':before, :after': {
      content: 'none',
    },

    '& .MuiInput-input:focus': {
      backgroundColor: 'transparent',
    },

    // ------------------------
    '&:hover': {
      border: '',
      backgroundColor: '#FDFDFD',
    },
    '&.Mui-disabled': {
      border: '1.5px solid #F4F4F4',
      backgroundColor: '#F4F4F4',
      color: '#CDCDCD',
    },
    '&.Mui-error': {
      border: '1.5px solid #E74C3C',
      color: '#E74C3C',
      background:
        'linear-gradient(0deg, rgba(231, 76, 60, 0.05) 0%, rgba(231, 76, 60, 0.05) 100%), #FDFDFD',
    },
  })
)

export const FieldContainer = styled(FormControlMui)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  '> div': {
    backgroundColor: 'transparent !important',
    border: 'none !important',
  },
})
