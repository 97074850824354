import { Controller } from 'react-hook-form'
import { Box } from '@mui/material'
import ReactCodeInput from 'react-verification-code-input'
import { HelperText, FieldContainer } from './code-field-styles'

function CodeField({ name, control }: any) {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value = [] },
        fieldState: { error },
      }: any) => {
        return (
          <Box display='flex'>
            <FieldContainer
              sx={{
                m: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
              component='fieldset'
              variant='standard'
              error={Boolean(error?.message)}
            >
              <ReactCodeInput onChange={onChange} values={[...value]} />
              {error?.message && <HelperText>{error?.message}</HelperText>}
            </FieldContainer>
          </Box>
        )
      }}
    ></Controller>
  )
}

export default CodeField
