import { GraphicLabel } from 'components/results'
import { Container, DashedArea, StyledGraphic } from './graphic-styles'
import { QUESTION_HEIGHT, GRAPHIC_HEIGHT } from 'helpers/constants'
import { calculateQuestionHeight } from 'helpers'

const Graphic = ({
  quantities,
  maxTagQuestions,
  onClick = null,
  hasSquareBorder = false,
  lastGraph = false,
  isProportional = false,
  totalAsked,
  tooltipOpen,
  setTooltipOpen,
}: any) => {
  const questionHeight = isProportional
    ? calculateQuestionHeight(GRAPHIC_HEIGHT, maxTagQuestions)
    : QUESTION_HEIGHT

  return (
    <Container
      onMouseEnter={() => setTooltipOpen && setTooltipOpen(true)}
      onMouseLeave={() => setTooltipOpen && setTooltipOpen(false)}
    >
      <GraphicLabel quantities={quantities} open={tooltipOpen}>
        <DashedArea
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          quantities={quantities}
          maxTagQuestions={totalAsked}
          questionHeight={questionHeight}
          onClick={lastGraph ? undefined : onClick}
        >
          <StyledGraphic
            className='test'
            quantities={quantities}
            hasClick={Boolean(onClick) && !lastGraph ? true : undefined}
            questionHeight={questionHeight}
            hasSquareBorder={hasSquareBorder}
          />
        </DashedArea>
      </GraphicLabel>
    </Container>
  )
}

export default Graphic
