import { useEffect } from 'react'
import { Box } from '@mui/material'
import { Button, RouteLayout, Header } from 'components'
import { isUserPatient, registerTrack } from 'helpers'
import { useAuthContext } from 'contexts'
import { Title, sxButton } from './questionnaire-category-styles'

function QuestionnaireCategory({
  block,
  type,
  handleGoNext,
  handleGoBack,
  patientInfo,
}: any): JSX.Element {
  const { user } = useAuthContext()

  useEffect(() => {
    registerTrack('Inicia Bloco', {
      user_id: user.userId,
      patient_id: patientInfo.patientId,
      source: isUserPatient(user) ? 'patient' : 'caregiver',
      block_name: block,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <RouteLayout bg='gradient'>
      <Header
        hasGoBackButton
        disabledGoHome
        handleBack={handleGoBack}
        removeLogo
      />
      <Box
        m={5}
        flexGrow={1}
        display='flex'
        flexDirection='column'
        alignItems='center'
      >
        <Box
          flexGrow={1}
          display='flex'
          flexDirection='column'
          justifyContent='center'
        >
          <Title>
            {type === 'specific' ? (
              <>
                Vamos falar sobre <b>{block}</b>
              </>
            ) : (
              <b>Mais algumas perguntas importantes</b>
            )}
          </Title>
        </Box>
        <Box width={223}>
          <Button color='white' onClick={handleGoNext} sx={sxButton}>
            Próximo
          </Button>
        </Box>
      </Box>
    </RouteLayout>
  )
}

export default QuestionnaireCategory
