import { useFormContext } from 'react-hook-form'
import { Box } from '@mui/material'
import { CodeField } from 'components/inputs'
import { contexts } from '../helpers/contexts'
import { Title, InfoText, ResendButton } from '../styles/general-steps-styles'

interface IWhatsappValidationCodeStep {
  type: 'patient' | 'caregiver' | 'informant'
}

function WhatsappValidationCodeStep({
  type,
}: IWhatsappValidationCodeStep): JSX.Element {
  const { control } = useFormContext()
  const { sendValidationCode, loadingSendCode } = contexts[type]()

  return (
    <>
      <Title>Para sua segurança, enviamos um código para o seu celular.</Title>
      <Box mt={3} mb={10}>
        <InfoText>Digite o código recebido para validar sua conta.</InfoText>
      </Box>
      <Box display='flex' mb={5} justifyContent='center'>
        <CodeField name='whatsappValidationCode' control={control} />
      </Box>
      <Box display='flex' justifyContent='center'>
        <ResendButton
          onClick={
            loadingSendCode
              ? () => {}
              : () => sendValidationCode('whatsapp', true)
          }
          disabled={loadingSendCode}
        >
          {loadingSendCode ? 'Aguarde...' : 'Enviar novamente'}
        </ResendButton>
      </Box>
    </>
  )
}

export default WhatsappValidationCodeStep
