import { useMemo } from 'react'
import { Stepper, Step, StepLabel, Stack } from '@mui/material'
import { StepperContext } from 'contexts'
import StepIcon from './step-icon'
import FirstStepIcon from './first-step-icon'
import { Title } from './sign-up-stepper-styles'

interface StepsProps {
  activeStep: number
  totalSteps: number
  stepTitle: string
}

function SignUpStepper({ activeStep, totalSteps, stepTitle }: StepsProps) {
  const firstStepWidth = useMemo(() => {
    if (totalSteps <= 3) return 176
    if (totalSteps <= 5) return 124
    return 97
  }, [totalSteps])

  const stepWidth = useMemo(() => {
    const containerWidth =
      (window.innerWidth > 380 ? 380 : window.innerWidth) -
      (totalSteps - 1) * 4 -
      8

    return Math.floor((containerWidth - firstStepWidth) / (totalSteps - 1 || 1))
  }, [totalSteps, firstStepWidth])

  return (
    <StepperContext.Provider value={{ totalSteps, stepWidth, firstStepWidth }}>
      <Stack width='100%' mt={5} pl={1}>
        <Title>{stepTitle}</Title>

        <Stepper
          activeStep={activeStep}
          connector={null}
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
          }}
        >
          {Array.from({ length: totalSteps }, (_, i) => i).map((index) => (
            <Step
              key={index}
              sx={{
                padding: 0,
                marginRight: index === totalSteps - 1 ? 0 : '4px',
                width: `${index === 0 ? firstStepWidth : stepWidth}px`,
              }}
            >
              <StepLabel
                StepIconComponent={index === 0 ? FirstStepIcon : StepIcon}
              ></StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
    </StepperContext.Provider>
  )
}

export default SignUpStepper
