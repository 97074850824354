import { Box } from '@mui/material'
import { BoxStyled, Container, Text, TitleText } from './contact-us-styles'
import { makeButtonClinic, makeButtonLink } from './helpers/buttons'

function ContactUs() {
  const handleLink = (link: string) => () => window.open(link)

  const contactInfo = {
    FAQ_URL: 'https://www.wida.app/faq-profissionais',
    SCHEDULE_URL: 'https://calendly.com/plataformawida/demo-wida',
    EMAIL_URL: 'mailto:info@wida.app',
    WHATSAPP_URL: 'https://wa.me/5551996017718',
  }

  const items = [
    {
      title: 'Perguntas frequentes',
      text: (
        <>
          <Text>Tem dúvidas sobre o funcionamento do Wida?</Text>
          <Text>Consulte nossas FAQs.</Text>
        </>
      ),
      action: makeButtonClinic({
        text: 'Ver FAQs',
        onClick: handleLink(contactInfo.FAQ_URL),
      }),
      mb: '24px',
    },
    {
      title: 'Agende uma demonstração/treinamento',
      text: 'Se você quer ajuda para começar a usar ou aprimorar sua experiência com a plataforma, agende gratuitamente um horário com nossa equipe de suporte.',
      action: makeButtonClinic({
        text: 'Agendar',
        onClick: handleLink(contactInfo.SCHEDULE_URL),
      }),
      mb: '16px',
    },
    {
      title: 'E-mail',
      text: 'Estamos disponíveis para tirar suas dúvidas, ouvir suas sugestões e oferecer suporte por e-mail.',
      action: makeButtonLink({
        text: 'info@wida.app',
        onClick: handleLink(contactInfo.EMAIL_URL),
      }),
    },
    {
      title: 'WhatsApp',
      text: 'Se você tem alguma pergunta, sugestão ou quer mais informações, converse com a gente.',
      action: makeButtonLink({
        text: '+ 55 (51) 9 9601-7718',
        onClick: handleLink(contactInfo.WHATSAPP_URL),
        withWhatsAppIcon: true,
      }),
    },
  ]

  return (
    <Container>
      {items.map(({ title, text, action, mb }) => (
        <BoxStyled key={title}>
          <TitleText>{title}</TitleText>
          <Box mt='12px' mb={mb || '12px'}>
            <Text>{text}</Text>
          </Box>
          {action}
        </BoxStyled>
      ))}
    </Container>
  )
}

export default ContactUs
