import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Stack, ClickAwayListener } from '@mui/material'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import {
  RouteLayout,
  HeaderMenuPatientGroup,
  InformantListItem,
  SendInformantInvite,
} from 'components'
import { useNotification, useQuery } from 'hooks'
import {
  getUserInformants,
  createFormRequest,
  getPendingInvites,
} from 'services'
import { useAuthContext } from 'contexts'
import {
  Title,
  Description,
  ButtonText,
  ButtonContainer,
} from './invite-informant-styles'
import {
  sortByName,
  formatInformant,
  formatInvite,
} from './invite-informant-helpers'

function InviteInformant(): JSX.Element {
  const [informantList, setInformantList] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [selectedInvite, setSelectedInvite] = useState<any>()

  const navigate = useNavigate()
  const { errorToast, warnToast } = useNotification()
  const query = useQuery()
  const { user } = useAuthContext()

  const formId = query.get('form_id')

  const fetchData = async () => {
    try {
      setLoading(true)

      const [informants, openInvites] = await Promise.all([
        getUserInformants(),
        getPendingInvites(),
      ])

      if (!Boolean(informants.length) && !Boolean(openInvites.length)) {
        navigate(`/invite_new_informant?form_id=${formId}`)
        return
      }

      const formattedInformants = informants
        .filter(({ active }: any) => active)
        .map(formatInformant)
        .slice()
        .sort(sortByName)

      const formattedOpenInvites = openInvites
        .map(formatInvite)
        .slice()
        .sort(sortByName)

      setInformantList([...formattedInformants, ...formattedOpenInvites])
    } catch (error: any) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!formId) {
      warnToast(
        'Você ainda não tem um questionário válido para convidar outras pessoas'
      )
      navigate('/')
      return
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleNewInformantInvite = () => {
    navigate(`/invite_new_informant?form_id=${formId}`)
  }

  const handleClickSendRequest = async (informant: any) => {
    try {
      await createFormRequest({
        requestedBy: user.userId,
        sendTo: informant.user.id,
        formId,
      })

      await fetchData()
    } catch (error: any) {
      console.error(error)
      errorToast(error?.message || 'Erro desconhecido')
    }
  }

  const handleResendInvite = (invite: any) => {
    setSelectedInvite(invite)
  }

  if (loading) return <></>

  return (
    <RouteLayout bg='gradient'>
      <Box mb={4} display='flex' flexDirection='column'>
        <HeaderMenuPatientGroup />
        <Stack spacing={2} px={3}>
          <Title>Convidados</Title>
          <Description>
            Quer convidar outras pessoas para responder também? Envie as últimas
            perguntas para seus convidados já cadastrados ou adicione novos.
          </Description>
        </Stack>
      </Box>
      <Box
        flexGrow={1}
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='space-between'
        pt={5}
        sx={{
          backgroundColor: '#ffffff',
          borderRadius: '28px 28px 0px 0px',
        }}
      >
        <Stack spacing={4} my={2} px={2} width='100%'>
          {selectedInvite ? (
            <InformantListItem
              key={selectedInvite.id}
              item={selectedInvite}
              onClick={() => handleResendInvite(selectedInvite)}
              removeButton
            />
          ) : (
            informantList.map((informant: any) => (
              <InformantListItem
                key={informant.id}
                item={informant}
                onClick={() =>
                  informant.type === 'informant'
                    ? handleClickSendRequest(informant)
                    : handleResendInvite(informant)
                }
              />
            ))
          )}
        </Stack>
        {selectedInvite ? (
          <ClickAwayListener onClickAway={() => setSelectedInvite(null)}>
            <SendInformantInvite informantInvite={selectedInvite} />
          </ClickAwayListener>
        ) : (
          <Stack
            mb={5}
            mx={5}
            px={2}
            alignItems='center'
            spacing={2}
            onClick={handleNewInformantInvite}
            sx={{ cursor: 'pointer' }}
          >
            <ButtonContainer>
              <AddRoundedIcon
                sx={{
                  fontSize: 40,
                  fill: '#F36F60',
                }}
              />
            </ButtonContainer>
            <ButtonText>Adicionar convidado</ButtonText>
          </Stack>
        )}
      </Box>
    </RouteLayout>
  )
}

export default InviteInformant
