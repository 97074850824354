import { useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { Box, Stack } from '@mui/material'
import { ButtonClinic } from 'components'
import { CodeField } from 'components/inputs'
import { useSignUpClinicContext } from 'contexts'
import { generateValidationCode } from 'services'
import { useNotification } from 'hooks'
import { registerPageAccess } from 'helpers'
import { Title, InfoLeftText } from './sign-up-clinic-steps-styles'

function EmailVerificationCodeStep(): JSX.Element {
  const [loadingSendCode, setLoadingSendCode] = useState(false)

  const { goBackStep, goNextStep, loading } = useSignUpClinicContext()
  const { getValues } = useFormContext()
  const { errorToast, successToast } = useNotification()

  const sendValidationCode = async () => {
    try {
      setLoadingSendCode(true)
      const [firstName, email] = getValues(['firstName', 'email'])

      await generateValidationCode({
        name: firstName,
        type: 'email',
        contact: email.toLowerCase(),
      })

      successToast('Código enviado com sucesso!')
    } catch (error) {
      errorToast(
        'Ocorreu um erro ao solicitar o código, por favor tente novamente.'
      )
    } finally {
      setLoadingSendCode(false)
    }
  }

  useEffect(() => {
    sendValidationCode()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    registerPageAccess('Cadastro clínico: código')
  }, [])

  return (
    <Box
      flexGrow={1}
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
    >
      <Box>
        <Box mb={{ xs: 1, sm: 2 }}>
          <Title>Verifique seu e-mail</Title>
        </Box>
        <Box>
          <InfoLeftText>
            Para sua segurança, enviamos um código para o seu e-mail.
          </InfoLeftText>
          <InfoLeftText>
            Digite o código recebido para validar sua conta.
          </InfoLeftText>
        </Box>
        <Box
          display='flex'
          justifyContent='center'
         mt={{ xs: 3, sm: 4, lg: 8 }}
          mb={{ xs: 2, sm: 3, lg: 2 }}
        >
          <CodeField name='emailValidationCode' />
        </Box>
        <Box display='flex' justifyContent='center'>
          <ButtonClinic
            variant='text'
            size='small'
            color='purple'
            fontWeight='bold'
            type='button'
            onClick={loadingSendCode ? () => {} : sendValidationCode}
            disabled={loadingSendCode}
          >
            {loadingSendCode ? 'Aguarde...' : 'Enviar novamente'}
          </ButtonClinic>
        </Box>
      </Box>
      <Stack
        width='100%'
        direction='row'
        spacing={{ xs: 1, sm: 6 }}
        justifyContent={{ xs: 'space-between', sm: 'center', lg: 'space-between'}}
        alignItems='center'
        mt={4}
      >
        <ButtonClinic
          width={150}
          variant='outlined'
          size='small'
          color='purple'
          fontWeight='bold'
          type='button'
          onClick={goBackStep}
          disabled={loading}
        >
          Voltar
        </ButtonClinic>

        <ButtonClinic
          width={150}
          variant='contained'
          size='small'
          color='purple'
          fontWeight='bold'
          type='button'
          onClick={goNextStep}
          loading={loading}
        >
          Próximo
        </ButtonClinic>
      </Stack>
    </Box>
  )
}

export default EmailVerificationCodeStep
