import { Typography, Divider } from '@mui/material'
import { styled } from '@mui/material/styles'
import CallMadeRoundedIcon from '@mui/icons-material/CallMadeRounded'

interface IMenuItemText {
  color: string
}

interface IIconStyled {
  fill: string
}

export const MenuItemText = styled(Typography)<IMenuItemText>(({ color }) => ({
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '21px',
  color: color,
  whiteSpace: 'pre-line',
}))

export const DividerStyled = styled(Divider)({
  backgroundColor: '#ffffff',
})

export const CallMadeRoundedIconStyled = styled(
  CallMadeRoundedIcon
)<IIconStyled>(({ fill }) => ({
  fill: fill,
}))
