import client from 'services/client'

export const getUserInformants = async () => {
  try {
    const { data: informantsPatients }: any = await client.get(
      'informants-patients/my-informants'
    )

    return informantsPatients.map((informantPatient: any) => ({
      ...informantPatient.informant,
      relation: informantPatient.relation,
      lastFormRequest: informantPatient.lastFormRequest,
      informantPatientId: informantPatient.id,
      active: informantPatient.active,
    }))
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const getPatientInformants = async (patientId: string) => {
  try {
    const { data: patientInformants }: any = await client.get(
      `informants-patients?patientId=${patientId}`
    )
    return patientInformants
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const updateInformantPatient = async (id: string, body: any) => {
  try {
    await client.patch(`informants-patients/${id}`, body)
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}
