import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { format, isValid, isToday } from 'date-fns'
import { Box } from '@mui/material'
import brLocale from 'date-fns/locale/pt-BR'
import { ButtonClinic, SelectStyled, DateFieldStyled } from 'components'
import { useInvitePatientContext } from 'contexts'
import { useNotification } from 'hooks'
import { questionnaireScheduleStepResolver } from 'resolvers'
import { frequencyOptions, frequencyResumeTextDict } from 'helpers/constants'
import { Title, SupportText } from '../styles/common-styles'

function ScheduleStep(): JSX.Element {
  const [sending, setSending] = useState(false)

  const { errorToast } = useNotification()

  const {
    goNextStep,
    newQuestionnaireInfos,
    setNewQuestionnaireInfos,
    sendQuestionnaireType,
    handleCreateQuestionnaire,
  } = useInvitePatientContext()

  const { control, handleSubmit, setValue, watch } = useForm<any>({
    defaultValues: {
      frequency: 'once',
      startDate: '',
    },
    resolver: questionnaireScheduleStepResolver,
  })

  const [frequency, startDate] = watch(['frequency', 'startDate'])

  useEffect(() => {
    setValue('frequency', newQuestionnaireInfos.frequency || 'once')
    setValue('startDate', newQuestionnaireInfos.startDate || new Date())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (data: any) => {
    setNewQuestionnaireInfos((newQuestionnaireInfos: any) => ({
      ...newQuestionnaireInfos,
      frequency: data.frequency,
      startDate: data.startDate,
    }))

    if (sendQuestionnaireType === 'resend') {
      try {
        setSending(true)
        await handleCreateQuestionnaire({
          frequency: data.frequency,
          startDate: data.startDate,
        })
        setSending(false)
      } catch (error) {
        errorToast('Não foi possível enviar. Tente novamente')
        setSending(false)
        return
      }
    }
    goNextStep()
  }

  const supportText = useMemo(() => {
    const frequencyText = frequencyResumeTextDict[frequency]

    const dateText =
      isValid(startDate) && !isToday(startDate)
        ? format(startDate, "dd 'de' MMMM 'de' yyyy", { locale: brLocale })
        : 'hoje'

    return `O questionário será enviado ${frequencyText} ${
      frequency === 'once' ? (dateText === 'hoje' ? '' : 'em') : 'a partir de'
    } ${dateText}.`
  }, [frequency, startDate])

  const isResend = useMemo(
    () => sendQuestionnaireType === 'resend',
    [sendQuestionnaireType]
  )
  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      flexGrow={1}
      component='form'
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box mt={10}>
        <Title>
          Com que frequência você gostaria de enviar este questionário?
        </Title>
      </Box>

      <Box width='274px' mt={3} mb={6}>
        <SelectStyled
          name='frequency'
          control={control}
          options={frequencyOptions}
        />
      </Box>

      <Title>Qual será a data do primeiro envio?</Title>

      <Box width='241px' mt={3} mb={6}>
        <DateFieldStyled name='startDate' control={control} disablePast />
      </Box>

      <Box mb={12}>
        <SupportText>{supportText}</SupportText>
      </Box>

      {isResend ? (
        <ButtonClinic
          width={123}
          variant='contained'
          size='small'
          color='purple'
          fontWeight='bold'
          type='submit'
          loading={sending}
        >
          {sending ? 'Enviando' : 'Enviar'}
        </ButtonClinic>
      ) : (
        <ButtonClinic
          variant='text'
          size='medium'
          color='purple'
          fontWeight='bold'
          icon='forwardArrow'
          type='submit'
        >
          Continuar
        </ButtonClinic>
      )}
    </Box>
  )
}

export default ScheduleStep
