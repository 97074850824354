import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Description = styled(Typography)({
  color: '#596B7B',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '22px',
  textAlign: 'center',
})
