const ListIcon = ({ color = '#657787' }: { color?: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='14'
      viewBox='0 0 20 14'
      fill='none'
    >
      <g clip-path='url(#clip0_2285_5153)'>
        <path
          d='M3.9664 13.2955V10.0345C3.9664 9.77785 3.75791 9.56936 3.5013 9.56936H2.93194C2.36259 9.56936 1.8761 9.36353 1.4698 8.95456C1.0635 8.54292 0.860352 8.05108 0.860352 7.47371V5.37806C0.860352 4.80069 1.0635 4.30885 1.4698 3.89721C1.8761 3.48556 2.36259 3.28241 2.93194 3.28241H6.94147C7.02701 3.28241 7.10988 3.25835 7.18205 3.21558L11.5417 0.569294C11.8518 0.382182 12.2474 0.604043 12.2474 0.967574V11.8869C12.2474 12.2477 11.8518 12.4723 11.5417 12.2851L7.18205 9.63885C7.10988 9.59341 7.02701 9.57203 6.94147 9.57203H6.50043C6.24382 9.57203 6.03532 9.78053 6.03532 10.0371V13.2982C6.03532 13.5548 5.82683 13.7633 5.57022 13.7633H4.42884C4.1749 13.7607 3.9664 13.5522 3.9664 13.2955ZM13.2872 9.3475L13.2765 3.50962C13.2765 3.28508 13.5545 3.18351 13.6989 3.35458C13.9742 3.67802 14.2094 4.04422 14.4046 4.45052C14.6906 5.05195 14.8349 5.71219 14.8349 6.42588C14.8349 7.14225 14.6932 7.79982 14.4099 8.40392C14.2174 8.81289 13.9849 9.1791 13.7096 9.50253C13.5652 9.6736 13.2872 9.56936 13.2872 9.3475ZM2.93194 5.38073V7.47638H7.64181L10.1785 8.99466V3.86246L7.64181 5.38073H2.93194ZM17.0615 3.7395L19.0182 2.61148C19.4325 2.37091 19.5742 1.84165 19.3363 1.42733C19.0957 1.01302 18.5664 0.871345 18.1521 1.10924L16.1955 2.23726C15.7812 2.47783 15.6395 3.00709 15.8774 3.42141C16.1153 3.83573 16.6472 3.9774 17.0615 3.7395ZM16.6686 7.46569H18.9273C19.4058 7.46569 19.7934 7.0781 19.7934 6.59963C19.7934 6.12116 19.4058 5.73357 18.9273 5.73357H16.6686C16.1901 5.73357 15.8025 6.12116 15.8025 6.59963C15.7999 7.0781 16.1875 7.46569 16.6686 7.46569ZM16.1955 11.0021L18.1521 12.1301C18.5664 12.3707 19.0984 12.2263 19.3363 11.812C19.5768 11.3977 19.4325 10.8658 19.0182 10.6279L17.0615 9.49986C16.6472 9.25929 16.1153 9.40363 15.8774 9.81795C15.6368 10.2323 15.7785 10.7615 16.1955 11.0021Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_2285_5153'>
          <rect
            width='18.933'
            height='13.2582'
            fill='white'
            transform='translate(0.860352 0.502441)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ListIcon
