import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const SuccessText = styled(Typography)(({ theme }) => ({
  color: '#596B7B',
  textAlign: 'center',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '13px',
    lineHeight: '17.5px',
  },
}))

export const TitleText = styled(Typography)(({ theme }) => ({
  color: '#657787',
  fontFamily: 'Quicksand',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '24px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
  },
}))

export const DescriptionText = styled(Typography)(({ theme }) => ({
  color: '#596B7B',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '13px',
    lineHeight: '17.5px',
  },
}))
