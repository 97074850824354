import { Box, Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Title = styled(Typography)({
  color: '#8D92BE',
  textAlign: 'center',
  fontFamily: 'Quicksand',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '37px',
})

export const Subtitle = styled(Typography)({
  color: '#949494',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '22px',
})

export const StrongSubtitle = styled('span')({
  color: '#596B7B',
  fontWeight: 700,
})

export const LineDivider = styled(Box)({
  marginTop: '8px',
  width: '37px',
  height: '2px',
  background: '#8D92BE',
})

export const LegendText = styled(Typography)({
  color: '#596B7B',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '12px',
})

export const QuestionText = styled('span')({
  color: '#596B7B',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
})

const questionLineProps: any = {
  highlight: {
    border: '1px solid #DCDCDC',
    borderRadius: '32px',
    boxShadow: '4px 4px 16px rgba(0, 0, 0, 0.08)',
    opacity: 1,
  },
  opaque: {
    borderLeft: '1px solid transparent',
    boxShadow: 'none',
    opacity: 0.5,
  },
  normal: {
    borderLeft: '1px solid transparent',
    boxShadow: 'none',
    opacity: 1,
  },
}

export const QuestionLine = styled(Stack)<any>(({ highlight }) => {
  return {
    alignItems: 'center',
    ...questionLineProps[highlight],
  }
})

export const ResponderNameText = styled(Typography)({
  color: '#596B7B',
  textAlign: 'center',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
})

export const ResponderInfoSubtext = styled(Typography)({
  color: '#596B7B',
  textAlign: 'center',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
})

export const SubdomainTitle = styled(Typography)({
  color: '#EE7262',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Quicksand',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
})
