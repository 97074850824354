export function formatCpf(cpf: string) {
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export function formatPhoneBr(phone: string) {
  if (phone.length === 13) {
    return phone.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, '$1 $2 $3-$4')
  }

  if (phone.length === 11) {
    return phone.replace(/(\d{2})(\d{5})(\d{4})/, '$1 $2-$3')
  }

  if (phone.length === 9) {
    return phone.replace(/(\d{5})(\d{4})/, '$1-$2')
  }

  return phone
}
