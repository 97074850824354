import {
  NoIcon,
  NotAskedIcon,
  SkippedIcon,
  SoSoIcon,
  YesIcon,
} from 'components/icons/patient-report'
import { Question } from 'types/patient-report-types'
import { subdomainsOrderByTag } from 'helpers/subdomains'

export function orderReponderOptions(responderOptions: any) {
  return responderOptions.slice().sort((respA: any, respB: any) => {
    if (respA.isPatient) return -1
    if (respB.isPatient) return 1

    if (respA.relation === 'paciente') return -1
    if (respB.relation === 'paciente') return 1

    return respA.name.localeCompare(respB.name)
  })
}

export const legendsIcons = [
  {
    label: 'Presente',
    icon: <YesIcon />,
  },
  {
    label: 'Indeterminado',
    icon: <SoSoIcon />,
  },
  {
    label: 'Ausente',
    icon: <NoIcon />,
  },
  {
    label: 'Não respondido',
    icon: <SkippedIcon />,
  },
  {
    label: 'Não enviado',
    icon: <NotAskedIcon />,
  },
]

export const answersSymbols: any = {
  '0': <NoIcon size={14} />,
  '1': <SoSoIcon size={14} />,
  '2': <YesIcon size={14} />,
  skip: <SkippedIcon size={14} />,
  not_asked: <NotAskedIcon size={14} />,
}

export const areSameArraysOfStrings = (array1: string[], array2: string[]) =>
  array1?.every((el1: string) => array2?.includes(el1)) &&
  array2?.every((el2: string) => array1?.includes(el2))

export const getSubdomainsWithQuestions = (
  questions: Question[],
  selectedTag: any
) => {
  if (!questions?.length) return []

  const allSubdomainNames = Array.from(
    new Set(questions.map((question) => question.subdomainName))
  )

  const allSubdomainNamesOrderedByTag = orderSubdomainsByTag(
    allSubdomainNames,
    selectedTag
  )

  return allSubdomainNamesOrderedByTag.reduce(
    (accumulator: any, currentValue: any) => {
      const filteredQuestions = questions.filter(
        (question) => question.subdomainName === currentValue
      )

      if (filteredQuestions.length && currentValue) {
        accumulator.push({
          subdomainName: currentValue,
          questions: filteredQuestions,
        })
      }

      return accumulator
    },
    [] as { subdomainName: string; questions: Question[] }[]
  )
}

export const getQuestionsWithoutSubdomain = (questions?: Question[]) => {
  if (!questions?.length) return []

  return questions.filter((question) => !question.subdomainName)
}

const orderSubdomainsByTag = (subdomains: any, tag: any) => {
  const orderedSubdomains: any = subdomains
    .slice()
    .sort((subA: any, subB: any) => {
      if (!tag?.name) return 0

      const tagName = tag.name.toLowerCase()

      if (!subdomainsOrderByTag[tag.name]) return 0

      return (
        (subdomainsOrderByTag[tagName]?.[subA] || 99) -
        (subdomainsOrderByTag[tagName]?.[subB] || 99)
      )
    })

  return orderedSubdomains
}
