import { Box } from '@mui/material'
import { LogoAnimation } from './logo-animation'
import {
  LogoTextContainerSignin,
  LogoTextContainerSignup,
} from './logo-text-container/logo-text-container'
import logoW from 'images/w.svg'
import { useEffect, useState } from 'react'

interface IDashboardLayoutProps {
  textType: 'signin' | 'signup'
  children: JSX.Element
}

function LogoLayout({
  textType = 'signin',
  children,
}: IDashboardLayoutProps): JSX.Element {
  const [minHeight, setMinHeight] = useState(window.innerHeight)
  
  useEffect(() => {
    const handleResize = () => {
      setMinHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <LogoAnimation
      display='flex'
      minHeight={minHeight}
      p={{ xs: '14px', sm: '32px', lg: '20px' }}
    >
      <Box
        sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }}
      >
        <img
          src={logoW}
          alt='Wida'
          height={'65%'}
          style={{
            position: 'absolute',
            top: 100,
            left: 0,
          }}
        />
      </Box>

      <Box
        width='50%'
        display={{ xs: 'none', sm: 'none', md: 'none', lg: 'flex' }}
      >
        {textType === 'signin' ? (
          <LogoTextContainerSignin />
        ) : (
          <LogoTextContainerSignup />
        )}
      </Box>
      <Box
        width={{ xs: '100%', sm: '100%', md: '100%', lg: '50%' }}
        sx={{ backgroundColor: '#FFFFFF', borderRadius: '24px' }}
      >
        {children}
      </Box>
    </LogoAnimation>
  )
}

export default LogoLayout
