import { Box, Stack } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { InputField, SelectField } from 'components/inputs'
import { Title } from './sign-up-informant-steps-styles'
import { useSignUpInformantContext } from 'contexts'

function InformantPersonalInfoStep(): JSX.Element {
  const { control } = useFormContext()
  const { invitationInfos, relationOptions } = useSignUpInformantContext()

  const patientFirstName =
    invitationInfos?.createdBy?.name?.split(' ')[0] || 'o paciente'

  return (
    <>
      <Box mb={2}>
        <Title>Ok! Você ainda não possui conta conosco.</Title>
      </Box>
      <Box mb={10}>
        <Title>Insira algumas informações sobre você para seu cadastro. </Title>
      </Box>
      <Stack spacing={2}>
        <InputField
          name='firstName'
          control={control}
          label='Seu primeiro nome'
        />
        <InputField name='lastName' control={control} label='Seu sobrenome' />
        <SelectField
          name='relation'
          control={control}
          label={`O que você é de ${patientFirstName}?`}
          options={relationOptions}
        />
      </Stack>
    </>
  )
}

export default InformantPersonalInfoStep
