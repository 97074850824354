import { WIDTH, HEIGHT } from './question-association-constants'
import { connectionLineDict } from './connection-line'
import PointingLine from './pointing-line'
import { QuestionAssociantion as QuestionAssociantionInterface } from './question-association-types'
import { Svg } from '@react-pdf/renderer'

function QuestionAssociation({
  type,
}: QuestionAssociantionInterface): JSX.Element {
  return (
    <Svg width={WIDTH} height={HEIGHT}>
      <PointingLine />

      {connectionLineDict[type]}
    </Svg>
  )
}

export default QuestionAssociation
