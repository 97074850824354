import { Box, Stack } from '@mui/material'
import { ButtonClinic } from 'components'
import newWidaPatientIcon from 'components/icons/new-wida-patient-icon.svg'
import { useInvitePatientContext } from 'contexts'
import { Title, Description } from '../styles/common-styles'

function StartNewPatientStep(): JSX.Element {
  const { goNextStep } = useInvitePatientContext()

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      flexGrow={1}
    >
      <img
        src={newWidaPatientIcon}
        height='89px'
        alt='Ícone de mais informações'
      />

      <Stack mt={4} mb={3}>
        <Title>Pronto para iniciar a jornada</Title>
        <Title>de um novo paciente no Wida?</Title>
      </Stack>

      <Stack mb={7}>
        <Description>
          Vamos criar um questionário personalizado para o seu paciente.
        </Description>
        <Description>
          Você poderá configurar as perguntas, a frequência e o modo de envio.
        </Description>
      </Stack>

      <ButtonClinic
        width={141}
        variant='contained'
        fontWeight='bold'
        color='purple'
        size='small'
        onClick={() => goNextStep()}
      >
        Começar
      </ButtonClinic>
    </Box>
  )
}

export default StartNewPatientStep
