import ButtonMui, { ButtonProps } from '@mui/material/Button'
import { defaultButtonStyle } from './button-styles'

function Button({
  sx,
  variant,
  startIcon,
  ...props
}: ButtonProps): JSX.Element {
  return (
    <ButtonMui
      variant={variant || 'contained'}
      fullWidth
      startIcon={startIcon}
      sx={{
        ...defaultButtonStyle,
        ...sx,
        wordBreak: 'break-word',
      }}
      {...props}
    />
  )
}

export default Button
