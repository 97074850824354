import { Stack, Box } from '@mui/material'
import {
  DividerStyled,
  MenuItemText,
  CallMadeRoundedIconStyled,
} from './button-clean-styles'

interface IButtonClean {
  onClick: () => void
  label: string
  icon: string
  disabled?: boolean
  textColor?: string
  hideDivider?: boolean
}

const icons: any = {
  'call-made': (fill: string) => <CallMadeRoundedIconStyled fill={fill} />,
  default: (fill: string) => <CallMadeRoundedIconStyled fill={fill} />,
}

export function ButtonClean({
  onClick,
  label,
  icon,
  disabled = false,
  textColor = '#ffffff',
  hideDivider = false,
}: IButtonClean): JSX.Element {
  return (
    <Stack width='100%'>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        my={2}
        onClick={() => disabled || onClick()}
        sx={{ cursor: disabled ? 'default' : 'pointer' }}
      >
        <MenuItemText color={textColor}>{label}</MenuItemText>
        {icons[icon || 'default'](textColor)}
      </Box>
      {!hideDivider && <DividerStyled />}
    </Stack>
  )
}

export default ButtonClean
