export const capitalizeWordsOnSentence = (sentence: string) =>
  sentence
    .split(' ')
    .map((tagWord: any) => {
      const skipOptions = ['e', 'a', 'o', 'da', 'de', 'do', 'dos', 'das']
      return skipOptions.includes(tagWord)
        ? tagWord
        : tagWord.slice(0, 1).toUpperCase() + tagWord.slice(1)
    })
    .join(' ')

export const findInWord = (word: string = '', filter: string = '') => {
  const normalizedFilter = filter
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
  return word
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .includes(normalizedFilter)
}

export const orderArray = (array: any[], key: any) => {
  return array.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1
    }
    if (a[key] > b[key]) {
      return 1
    }
    return 0
  })
}

export const sanitizeString = (string: string) => {
  if (!string) return ''
  return string.replace(/\D/g, '')
}

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
