export const generateWhatsappPatientInviteInformantMessage = (
  patientName: string,
  informantName: string,
  inviteLink: string
) => {
  const patientFirstName = patientName.split(' ')[0] || ''
  const informantFirstName = informantName.split(' ')[0] || ''

  return (
    `Olá, ${informantFirstName}!%0a%0a` +
    `${patientName} está fazendo um acompanhamento clínico e gostaria de convidar você para responder algumas perguntas. ` +
    `Na plataforma Wida, você pode relatar a sua perspectiva ao profissional responsável pelo acompanhamento de ${patientFirstName} de modo simples e seguro.%0a%0a` +
    `Faça seu cadastro e comece já a responder usando o link abaixo.%0a%0a` +
    inviteLink
  )
}

export const generateWhatsappCaregiverInviteInformantMessage = (
  caregiverName: string,
  patientName: string,
  informantName: string,
  inviteLink: string
) => {
  const caregiverFirstName = caregiverName.split(' ')[0] || ''
  const patientFirstName = patientName.split(' ')[0] || ''
  const informantFirstName = informantName.split(' ')[0] || ''

  return (
    `Olá, ${informantFirstName}!%0a%0a` +
    `${patientName} está fazendo um acompanhamento clínico e ${caregiverFirstName} gostaria de convidar você para responder algumas perguntas. ` +
    `Na plataforma Wida, você pode relatar a sua perspectiva ao profissional responsável pelo acompanhamento de ${patientFirstName} de modo simples e seguro.%0a%0a` +
    `Faça seu cadastro e comece já a responder usando o link abaixo.%0a%0a` +
    inviteLink
  )
}

export const generateWhatsappInvitePatientMessage = (
  patientName: string,
  clinicName: string,
  inviteLink: string
) =>
  `Olá!%0a%0a` +
  `${clinicName} selecionou algumas perguntas para você no Wida, uma plataforma inovadora de cuidados em saúde mental. Para aceitar o convite e iniciar esta jornada, cadastre-se em%0a%0a` +
  inviteLink +
  '%0a%0a*Atenção: não compartilhe este link com outras pessoas. Ele é exclusivo para o titular da conta.'

export const generateWhatsappResendPatientMessage = (
  patientName: string,
  clinicName: string,
  inviteLink: string
) => {
  const patientFirstName = patientName.split(' ')[0] || ''

  return (
    `Olá, ${patientFirstName}!%0a%0a` +
    `${clinicName} selecionou um novo conjunto de perguntas para você responder.%0a%0a` +
    'Para responder, acesse sua conta Wida através do link.%0a%0a' +
    inviteLink
  )
}

export const generateWhatsappResendCaregiverMessage = (
  patientName: string,
  caregiverName: string,
  clinicName: string,
  inviteLink: string
) => {
  const caregiverFirstName = caregiverName.split(' ')[0] || ''

  return (
    `Olá, ${caregiverFirstName}!%0a%0a` +
    `${clinicName} selecionou um novo conjunto de perguntas de acompanhamento clínico sobre ${patientName}.%0a%0a` +
    'Para responder, acesse sua conta Wida através do link.%0a%0a' +
    inviteLink
  )
}
