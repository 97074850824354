import { useState } from 'react'
import { Box, Stack } from '@mui/material'
import {
  ButtonClinic,
  SecondaryConfirmationModal,
  TextFieldClinic,
} from 'components'
import { updateClinicInfo } from 'services'
import { useClinicContext } from 'contexts'
import { useNotification } from 'hooks'
import useMyInfoPasswordSectionForm from './my-info-password-section-form'
import { TooltipPasswordText } from '../clinic-profile-tabs-styles'

function MyInfoPasswordSection(): JSX.Element {
  const [isOpenPasswordModal, setIsOpenPasswordModal] = useState(false)
  const [sending, setSending] = useState(false)

  const { fetchClinic } = useClinicContext()
  const { control, handleSubmit, clearErrors, reset } = useMyInfoPasswordSectionForm()
  const { errorToast } = useNotification()

  const onSubmit = async (params: any) => {
    try {
      setSending(true)
      const body = {
        password: params.password,
      }

      await updateClinicInfo(body)
      fetchClinic()
    } catch (error: any) {
      errorToast(error.message)
    } finally {
      setSending(false)
    }
  }

  const handleOpenPasswordModal = () => {
    setIsOpenPasswordModal(!isOpenPasswordModal)
    clearErrors()
  }

  const handleClose = () => {
    setIsOpenPasswordModal(false)
    reset()
  }

  return (
    <Box mt={2}>
      <ButtonClinic
        size='small'
        variant='text'
        fontWeight='bold'
        color='purple'
        onClick={handleOpenPasswordModal}
      >
        Alterar minha senha
      </ButtonClinic>

      <SecondaryConfirmationModal
        isOpen={isOpenPasswordModal}
        title='Alteração de senha'
        hideButtons
        onClose={handleClose}
        content={
          <Box component='form' onSubmit={handleSubmit(onSubmit)}>
            <Stack alignItems='center' spacing={'30px'}>
              <TextFieldClinic
                type='password'
                name='password'
                control={control}
                label='Nova senha'
                fieldInfo={
                  <Stack>
                    <TooltipPasswordText>
                      Sua senha deve conter:
                    </TooltipPasswordText>
                    <TooltipPasswordText>
                      - no mínimo 8 caracteres
                    </TooltipPasswordText>
                    <TooltipPasswordText>
                      - pelo menos uma letra maiúscula
                    </TooltipPasswordText>
                    <TooltipPasswordText>
                      - um ou mais números ou símbolos
                    </TooltipPasswordText>
                  </Stack>
                }
              />

              <TextFieldClinic
                type='password'
                name='confirmPassword'
                control={control}
                label='Confirme sua senha'
              />
            </Stack>

            <Stack direction='row' spacing={4} mt={5} mb={5}>
              <ButtonClinic
                width={140}
                variant='outlined'
                size='small'
                color='purple'
                fontWeight='bold'
                type='button'
                onClick={handleClose}
              >
                Cancelar
              </ButtonClinic>

              <ButtonClinic
                width={140}
                variant='contained'
                size='small'
                color='purple'
                fontWeight='bold'
                type='submit'
                loading={sending}
              >
                {sending ? 'Salvando' : 'Salvar'}
              </ButtonClinic>
            </Stack>
          </Box>
        }
      />
    </Box>
  )
}

export default MyInfoPasswordSection
