import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

yup.setLocale({
  mixed: {
    required: 'Campo obrigatório',
  },
})

const testStrongPassword = (value: any) =>
  /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(value)

export const clinicProfileMyInfoGeneralResolver = yupResolver(
  yup
    .object({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
    })
    .required()
)

export const clinicProfileMyInfoPasswordResolver = yupResolver(
  yup
    .object({
      password: yup
        .string()
        .min(8, 'Senha deve ter mais que 8 caracteres')
        .test(
          'strong_password',
          'Sua senha deve ter letra minúscula, maiúscula, símbolo e número',
          testStrongPassword
        ),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Senhas devem ser iguais'),
    })
    .required()
)

export const clinicProfileContactsResolver = yupResolver(
  yup
    .object({
      email: yup.string().email('Digite um email válido').nullable(),
      telephone: yup.string().min(11, 'Telefone inválido').nullable(),
      communication: yup.object().shape(
        {
          email: yup.bool().when('whatsapp', {
            is: (whatsapp: boolean) => !whatsapp,
            then: yup
              .bool()
              .oneOf(
                [true],
                'Pelo menos uma forma de comunicação deve ser selecionada'
              ),
          }),
          whatsapp: yup.bool().when('email', {
            is: (email: boolean) => !email,
            then: yup
              .bool()
              .oneOf(
                [true],
                'Pelo menos uma forma de comunicação deve ser selecionada'
              ),
          }),
        },
        [
          ['email', 'whatsapp'],
          ['whatsapp', 'email'],
        ]
      ),
    })
    .required()
)

export const clinicProfileAditionalInfoResolver = yupResolver(
  yup
    .object({
      professionalCouncil: yup.string().required(),
      register: yup.string().required(),
    })
    .required()
)

export const clinicProfileValidationCodeResolver = yupResolver(
  yup
    .object({
      validationCode: yup
        .string()
        .required()
        .min(6, 'Código inválido')
        .max(6, 'Código inválido'),
    })
    .required()
)
