import { GradientLink } from './gradient-link'
import { Text } from './sign-in-header-styles'

function SignInHeader(): JSX.Element {
  return (
    <Text>
      Já tem conta?{' '}
      <GradientLink href='/signin/clinic' rel='noreferrer'>
        Login
      </GradientLink>
    </Text>
  )
}

export default SignInHeader
