import ButtonClinic from 'components/button-clinic/button-clinic'
import { LinkButton, LinkText } from '../contact-us-styles'
import { Box } from '@mui/material'
import { WhatsApp } from '@mui/icons-material'

interface IMakeButtonClinic {
  text: string
  onClick: () => void
}

export const makeButtonClinic = ({ text, onClick }: IMakeButtonClinic) => (
  <ButtonClinic
    variant='contained'
    color='purple'
    fontWeight='bold'
    size='small'
    onClick={onClick}
  >
    {text}
  </ButtonClinic>
)

interface IMakeButtonLink {
  text: string
  onClick: () => void
  withWhatsAppIcon?: any
}

export const makeButtonLink = ({
  text,
  onClick,
  withWhatsAppIcon,
}: IMakeButtonLink) => (
  <LinkButton type='button' onClick={onClick}>
    <Box display='flex' gap={1}>
      {withWhatsAppIcon && <WhatsApp sx={{ width: 14, color: '#8D92BE' }} />}
      <LinkText>{text}</LinkText>
    </Box>
  </LinkButton>
)
