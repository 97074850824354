import client from 'services/client'
import { formatUserResponsesFromBack } from 'helpers/formatters'

export const getPatientResponders = async (patientId: string) => {
  const { data }: any = await client.get(
    `/dashboard/patients/${patientId}/responders`
  )

  return data
}

export const getPatientResponses = async (patientId: string) => {
  const { data: responses }: any = await client.get(
    `/dashboard/patients/${patientId}/responders/patients/${patientId}`
  )

  return formatUserResponsesFromBack(responses)
}

export const getInformantResponses = async (
  patientId: string,
  informantId: string
) => {
  const { data: responses }: any = await client.get(
    `/dashboard/patients/${patientId}/responders/informants/${informantId}`
  )

  return formatUserResponsesFromBack(responses)
}
