export const emailQuestion = {
  you: (
    <>
      Vamos criar uma conta pra você. Qual seu <b>e-mail</b>?
    </>
  ),
  he: (
    <>
      Qual o seu <b>e-mail</b>?
    </>
  ),
  she: (
    <>
      Qual o seu <b>e-mail</b>?
    </>
  ),
}

export const firstNameQuestion = {
  you: 'Primeiro nome',
  he: 'Seu primeiro nome',
  she: 'Seu primeiro nome',
}

export const lastNameQuestion = {
  you: 'Sobrenome',
  he: 'Seu sobrenome',
  she: 'Seu sobrenome',
}

export const birthdateQuestion = {
  you: 'Data de nascimento',
  he: 'Sua data de nascimento',
  she: 'Sua data de nascimento',
}

export const pronounQuestion = {
  you: 'Qual pronome devemos usar?',
  he: 'Como o paciente se identifica',
  she: 'Como o paciente se identifica',
}
