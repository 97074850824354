import { Stack, Box } from '@mui/material'
import { RouteLayout, ButtonClean } from 'components'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { Title, Subtitle } from './questionnaire-skipped-category-styles'

function QuestionnaireSkippedCategory({
  skippedQuestions,
  handleYes,
  handleNo,
}: any): JSX.Element {
  return (
    <RouteLayout bg='gradient'>
      <Stack
        flexGrow={1}
        m={5}
        alignItems='center'
        justifyContent='space-between'
      >
        <Box />
        <Stack alignItems='center' justifyContent='space-between' spacing={2}>
          <Box mb={7}>
            <ErrorOutlineOutlinedIcon
              sx={{ height: 60, width: 60, fill: '#ffffff' }}
            />
          </Box>
          <Title>
            Você pulou {skippedQuestions.length}{' '}
            {skippedQuestions.length < 2 ? 'pergunta' : 'perguntas'}.
          </Title>
          <Subtitle>Deseja voltar pra responder?</Subtitle>
        </Stack>

        <Stack spacing={2} width='100%'>
          <ButtonClean
            onClick={handleYes}
            icon='call-made'
            label=' Sim, responder perguntas'
          />
          <ButtonClean
            onClick={handleNo}
            icon='call-made'
            label='Não, prefiro encerrar'
          />
        </Stack>
      </Stack>
    </RouteLayout>
  )
}

export default QuestionnaireSkippedCategory
