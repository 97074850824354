import { useEffect, useState } from 'react'
import { Box, Stack } from '@mui/material'
import { ButtonClinic } from 'components'
import { useCreatePatientReportContext } from 'contexts'
import {
  Title,
  Subtitle,
  Checkbox,
  TagLabel,
} from '../create-patient-report-styles'
import { capitalizeWordsOnSentence } from 'helpers'
import { SUBMITTING } from 'helpers/constants'

function SelectTagsStep(): JSX.Element {
  const [tagOptions, setTagOptions] = useState([])

  const {
    selectedFormRequests,
    respondersWithResponses,
    setActiveStep,
    generatedReportParams,
    selectedTags,
    setSelectedTags,
    status,
  } = useCreatePatientReportContext()

  useEffect(() => {
    const selectedResponses = respondersWithResponses
      .reduce((acc: any, { responses }: any) => [...acc, ...responses], [])
      .filter(({ formRequestId }: any) =>
        selectedFormRequests.includes(formRequestId)
      )

    const responsesTags = selectedResponses.reduce(
      (acc: any, { tags }: any) => [...acc, ...tags],
      []
    )

    const tagOptions = responsesTags.reduce((acc: any, responsesTag: any) => {
      const hasTag = acc.some(({ value }: any) => value === responsesTag.id)

      if (hasTag) return acc

      return [...acc, { value: responsesTag.id, label: responsesTag.tag }]
    }, [])

    setTagOptions(tagOptions)

    if (!generatedReportParams?.patientId) {
      // * Se não tiver essa propriedade é porque ainda não foi gerado nenhum relatório
      // * deve vir selecionado todos os domínios.
      // * Se já gerou o relatório então mantém a memória da seleção que o usuário fez.

      setSelectedTags(tagOptions.map(({ value }: any) => value))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFormRequests, respondersWithResponses, setSelectedTags])

  const handleChangeTags = (value: any) => {
    setSelectedTags((selectedTags: string[]) =>
      selectedTags.includes(value)
        ? selectedTags.filter(
            (selectedTagId: string) => selectedTagId !== value
          )
        : [...selectedTags, value]
    )
  }

  const handleForward = () => {
    setActiveStep((step: number) => step + 1)
  }

  return (
    <>
      <Stack alignItems='center'>
        <Box mt={4}>
          <Title>Quais domínios deseja incluir?</Title>
        </Box>
        <Box mt={2} maxWidth={678} mb={4}>
          <Subtitle>
            Todos os domínios com dados disponíveis para os respondedores e
            datas selecionados serão exibidos no Relatório Wida sobre Maria. Se
            desejar excluir algum, desmarque abaixo.
          </Subtitle>
        </Box>

        <Stack>
          {tagOptions.map((tagOption: any) => (
            <Stack key={tagOption.value} direction='row' alignItems='center'>
              <Checkbox
                checked={selectedTags.includes(tagOption.value)}
                onChange={() => handleChangeTags(tagOption.value)}
              />
              <TagLabel>{capitalizeWordsOnSentence(tagOption.label)}</TagLabel>
            </Stack>
          ))}
        </Stack>
      </Stack>

      <Stack direction='row' width='100%' justifyContent='space-between'>
        <ButtonClinic
          height={34}
          width={150}
          variant='outlined'
          color='purple'
          size='small'
          fontWeight='bold'
          onClick={() => setActiveStep((step: number) => step - 1)}
        >
          Voltar
        </ButtonClinic>

        <ButtonClinic
          height={34}
          width={150}
          variant='contained'
          color='purple'
          size='small'
          fontWeight='bold'
          onClick={handleForward}
          disabled={!Boolean(selectedTags?.length)}
          loading={status === SUBMITTING}
        >
          {status !== SUBMITTING ? 'Continuar' : 'Gerando'}
        </ButtonClinic>
      </Stack>
    </>
  )
}

export default SelectTagsStep
