import { Controller, Control } from 'react-hook-form'
import DatePicker from '@mui/lab/DatePicker'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { Label } from './date-field-labeled-styles'

interface DateFieldProps {
  name: string
  control: Control<any>
  label?: string
  disabled?: boolean
  placeholder?: string
}

function DateFieldLabeled({
  name,
  control,
  label = '',
  disabled,
  placeholder = 'Digite aqui',
}: DateFieldProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value },
        fieldState: { invalid, error },
      }) => (
        <Box width='100%'>
          <Box mb={0.5}>
            <Label error={invalid}>{label}</Label>
          </Box>
          <DatePicker
            value={value}
            onChange={onChange}
            cancelText='Cancelar'
            clearText='Limpar'
            toolbarTitle='Selecione uma data'
            todayText='Hoje'
            disabled={disabled}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  variant='outlined'
                  fullWidth
                  placeholder={placeholder}
                  color='secondary'
                  error={invalid}
                  helperText={error?.message}
                  inputProps={{
                    ...params.inputProps,
                    sx: {
                      ...params?.inputProps?.sx,
                      height: '10px',
                    },
                  }}
                />
              )
            }}
          />
        </Box>
      )}
    />
  )
}

export default DateFieldLabeled
