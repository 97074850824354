import {
  ClinicLayout,
  PatientCentralLayout,
  PatientReports as PatientReportsComponent,
} from 'components'

function PatientReports(): JSX.Element {
  return (
    <ClinicLayout>
      <PatientCentralLayout>
        <PatientReportsComponent />
      </PatientCentralLayout>
    </ClinicLayout>
  )
}

export default PatientReports
