import { styled } from '@mui/material/styles'
import { Tabs as TabsMui, Typography } from '@mui/material'

export const Tabs = styled(TabsMui)`
  button {
    min-height: 40px;
    border-radius: 5px;
    text-transform: none;
  }
  button[aria-selected='true'] {
    color: #353f48;
    background-color: #f9f9fb;

    svg {
      color: #f36f60;
    }
  }
  button[aria-selected='false'] {
    color: #657787;
  }
`

export const LabelText = styled(Typography)({
  color: '#657787',
  textAlign: 'center',
  fontFamily: 'Quicksand',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '17px',
})

export const ChangeTabModalTitleText = styled(Typography)({
  color: '#596B7B',
  textAlign: 'center',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '28px',
})

export const ChangeTabModalSubtitleText = styled(Typography)({
  color: '#596B7B',
  textAlign: 'center',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '28px',
})
