import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { ClinicLayout, ProgressBar } from 'components'
import { resendPatientSteps } from 'components/send-questionnaire-steps'
import { SendQuestionnaireContext } from 'contexts'
import {
  getTags,
  fetchPatient,
  createUserQuestionnaires,
  getUserGroups,
  configFormRequestEnvironments,
  activateUser,
} from 'services'
import { useQuery, useNotification } from 'hooks'
import { SENDING_TYPE_NOW } from 'helpers/constants'
import ResendPatientWarningModal from './resend-patient-warning-modal'

function ResendPatient(): JSX.Element {
  const [activeStep, setActiveStep] = useState(0)
  const [invitationToken, setInvitationToken] = useState()
  const [tags, setTags] = useState([])
  const [selectedTag, setSelectedTag] = useState<any>(null) // used for filter + question step
  const [newQuestionnaireInfos, setNewQuestionnaireInfos] = useState({
    // * Infos para criação do quesionário
    patientName: '',
    selectedQuestions: [],
    selectedTags: [],
    sendTo: null,
    // * Infos para envio do questionário
    invitationToken: null,
    invitationCode: null,
    // * Configs
    sendingType: '',
  })
  const [patient, setPatient] = useState<any>(null)
  const [openWarningModal, setOpenWarningModal] = useState<boolean>(false)

  const { errorToast } = useNotification()
  const query = useQuery()
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tags = await getTags()
        const patient = await fetchPatient(query.get('patient_id') || '')

        setTags(tags)
        setPatient(patient)
        setOpenWarningModal(patient.hasScheduledFormRequest)
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [query])

  useEffect(() => {
    setNewQuestionnaireInfos((prevInfo: any) => ({
      ...prevInfo,
      patientName: query.get('patient_name'),
    }))
  }, [query])

  const goNextStep = (offset: number = 1) => {
    setActiveStep(activeStep + offset)
  }

  const goBackStep = (offset: number = 1) => {
    if (activeStep === 0) {
      navigate('/patients')
      return
    }

    setActiveStep(activeStep - offset)
  }

  const handleCreateQuestionnaire = async ({
    frequency = 'once',
    startDate = new Date(),
  }) => {
    const userId = query.get('user_id')
    if (!userId) {
      errorToast('Paciente não foi encontrado')
      navigate('/patients')
      return
    }

    const userGroups = await getUserGroups()

    const selectedTagsIds = newQuestionnaireInfos.selectedTags.map(
      (selectedTag: any) => {
        const findTagApi: any = tags.find(
          (tag: any) => tag.name === selectedTag.name
        )
        return findTagApi.id
      }
    )

    const body = {
      name: newQuestionnaireInfos.patientName,
      questions: newQuestionnaireInfos.selectedQuestions.map(
        ({ id }: any) => id
      ),
      sendTo: userId,
      frequency: frequency,
      startDate: new Date(startDate.setHours(12, 0, 0, 0)),
      tags: selectedTagsIds,
    }

    const formData = {
      userGroups: userGroups.map(({ id: groupId }: any) => ({
        id: groupId,
        tags: selectedTagsIds,
      })),
    }

    const { formRequestId, invitationToken, invitationCode, invite, formId } =
      await createUserQuestionnaires(body)

    await activateUser(userId)

    await configFormRequestEnvironments(formRequestId, {
      userGroups: formData.userGroups,
    })

    return {
      formRequestId,
      invitationToken,
      invitationCode,
      invite,
      formId,
    }
  }

  return (
    <ClinicLayout>
      <Box
        py={4}
        display='flex'
        flexDirection='column'
        height='100%'
        flexGrow={1}
      >
        <Box mx={6}>
          <ProgressBar
            hasHandleBackButton={resendPatientSteps[activeStep].hasBackButton}
            handleBack={() => {
              if (
                resendPatientSteps[activeStep].label === 'Enviar' &&
                newQuestionnaireInfos.sendingType === SENDING_TYPE_NOW
              ) {
                goBackStep(2)
                return
              }

              goBackStep()
            }}
            totalSteps={resendPatientSteps.length - 1}
            activeStep={activeStep}
          />
        </Box>
        <SendQuestionnaireContext.Provider
          value={{
            sendQuestionnaireType: 'resend',
            goNextStep,
            goBackStep,
            invitationToken,
            setInvitationToken,
            newQuestionnaireInfos,
            setNewQuestionnaireInfos,
            selectedTag,
            setSelectedTag,
            tags,
            patient,
            handleCreateQuestionnaire,
          }}
        >
          {resendPatientSteps[activeStep].component}
        </SendQuestionnaireContext.Provider>
        {openWarningModal && (
          <ResendPatientWarningModal
            handleCancel={() => navigate('/patients')}
            handleConfirm={() => setOpenWarningModal(false)}
          />
        )}
      </Box>
    </ClinicLayout>
  )
}

export default ResendPatient
