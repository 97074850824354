export const commomProps: any = {
  stroke: '#C7C8D7',
  strokeWidth: 1.5,
  strokeLinecap: 'round',
}

export const WIDTH = 10
export const HEIGHT = 23
export const HORIZONTAL_START = WIDTH - 1
export const HORIZONTAL_CENTER = WIDTH / 2
export const VERTICAL_START = 0
export const VERTICAL_CENTER = HEIGHT / 2
export const VERTICAL_END = HEIGHT
