import { View } from '@react-pdf/renderer'

interface NotAskedIconProps {
  size?: number
}

function NotAskedIcon({ size = 9 }: NotAskedIconProps) {
  return (
    <View
      style={{
        width: size,
        height: size,
        justifyContent: 'center',
      }}
    >
      <View style={{ height: 1, width: size, backgroundColor: '#000' }} />
    </View>
  )
}

export default NotAskedIcon
