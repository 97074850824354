import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

interface StepIconRootProps {
  ownerState: { active?: boolean; totalSteps?: number; stepWidth?: number }
}

export const StepIconRoot = styled('div')<StepIconRootProps>(
  ({ ownerState }) => {
    const width = ownerState.stepWidth || 4

    return {
      color: '#CAFBE5',
      display: 'flex',
      height: 10,
      alignItems: 'center',
      ...(ownerState.active && {
        color: '#01B3A7',
      }),
      '& .StepIcon-completed': {
        width: `${width}px`,
        height: width < 4 ? `${width}px` : 4,
        borderRadius: '9px',
        backgroundColor: '#01B3A7',
        padding: 0,
      },
      '& .StepIcon-circle': {
        width: `${width}px`,
        height: width < 4 ? `${width}px` : 4,
        borderRadius: '9px',
        backgroundColor: 'currentColor',
        padding: 0,
      },
    }
  }
)

export const FirstStepIconRoot = styled('div')<any>(({ firstStepWidth }) => ({
  color: '#01B3A7',
  display: 'flex',
  height: 10,
  alignItems: 'center',
  '& .StepIcon-completed': {
    width: `${firstStepWidth}px`,
    height: 4,
    borderRadius: '9px',
    backgroundColor: '#01B3A7',
    padding: 0,
  },
}))

export const Title = styled(Typography)({
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '18px',
  color: '#01B3A7',
})
