import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const SectionLink = styled(Typography)({
  color: '#8D92BE',
  fontFamily: 'Quicksand',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '24px',
  textDecorationLine: 'underline',
  cursor: 'pointer',
})

export const Section = styled('section')({
  marginBottom: '40px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
})

export const SectionTitleText = styled(Typography)({
  color: '#8D92BE',
  fontFamily: 'Quicksand',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '24px',
})
