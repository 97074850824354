import { AttachFileOutlined, DescriptionOutlined } from '@mui/icons-material'
import {
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from '@mui/lab'
import { Box } from '@mui/material'
import { RichTextDisplay } from 'components/inputs'
import { convertFromRaw, EditorState } from 'draft-js'
import { TextDate } from './medical-record-styles'

const MedicalRecord = ({ record }: any) => {
  const { richText } = record
  const editorState = EditorState.createWithContent(convertFromRaw(richText))
  const hasText = editorState?.getCurrentContent().hasText()

  return (
    <TimelineItem>
      <TimelineSeparator sx={{ marginLeft: '30px' }}>
        <Box display={'flex'}>
          {!!record.files?.length && (
            <TimelineDot
              sx={{
                backgroundColor: 'transparent',
                border: '1px solid #D4D7DF',
                boxShadow: 'none',
                margin: hasText ? '10px 7px 10px -36px' : '10px 7px',
              }}
            >
              <AttachFileOutlined
                sx={{
                  color: '#01B3A7',
                  fontSize: '20px',
                  padding: 0,
                }}
              />
            </TimelineDot>
          )}
          {hasText && (
            <TimelineDot
              sx={{
                backgroundColor: 'transparent',
                border: '1px solid #D4D7DF',
                boxShadow: 'none',
                margin: '10px 7px',
              }}
            >
              <DescriptionOutlined
                sx={{
                  color: '#01B3A7',
                  fontSize: '20px',
                  padding: 0,
                }}
              />
            </TimelineDot>
          )}
        </Box>
        <TimelineConnector sx={{ backgroundColor: '#ECECEC' }} />
      </TimelineSeparator>
      <TimelineContent>
        <Box mb={2} mt={1}>
          <TextDate>{record.widaDate}</TextDate>
        </Box>
        <RichTextDisplay record={record} />
      </TimelineContent>
    </TimelineItem>
  )
}

export default MedicalRecord
