import { Image, Text, View } from '@react-pdf/renderer'

import logoWImage from 'images/logo-w.png'
import logoImage from 'images/logo.png'
import { PatientReport } from 'types/patient-report-types'
import { parseBirthdateIntoAge } from '../../helpers/date'
import { getFullName } from '../../helpers/name'
import InfoHeader from '../info-header/info-header'
import DashedSvg from './dashed-box'
import { styles } from './header-styles'

interface HeaderProps {
  data?: PatientReport
  isFirstPage?: boolean
}

function Header({ data, isFirstPage }: HeaderProps) {
  const renderLogoWithText = () => {
    return (
      <View style={{ alignItems: 'flex-end' }}>
        <Image src={logoImage} style={styles.logo} />
        <Text style={styles.logoText}>Cuidado na medida certa</Text>
      </View>
    )
  }

  const renderLogoWithInfo = () => {
    const fullName = getFullName({
      firstName: data?.patient.personalInfo?.firstName,
      lastName: data?.patient.personalInfo?.lastName,
    })

    const age = parseBirthdateIntoAge(data?.patient.personalInfo?.birthdate)

    return (
      <View>
        <View style={styles.logoWView}>
          <Text style={styles.headerText}>
            <Text
              style={{
                ...styles.headerText,
                fontWeight: 500,
                fontStyle: 'semibold',
              }}
            >
              {fullName}
            </Text>
            , {age}
          </Text>
          <Image src={logoWImage} style={styles.logoW} />
        </View>
      </View>
    )
  }

  return (
    <View fixed style={{ marginBottom: 24 }}>
      <View style={{ flexDirection: 'row', marginBottom: 6 }}>
        <View style={styles.titleView}>
          <View style={styles.secondaryTitleView} />
          <Text style={styles.title}>Relatório Wida</Text>
        </View>
        <View style={styles.logoView}>
          {isFirstPage ? renderLogoWithText() : renderLogoWithInfo()}
        </View>
      </View>
      {isFirstPage && <InfoHeader data={data} />}
      {isFirstPage && (
        <View style={{ paddingHorizontal: 24, marginTop: 24 }}>
          <View style={styles.dashedBox}>
            <View style={styles.dashedSvgView}>
              <DashedSvg />
            </View>
            <Text style={styles.infoText}>
              O Relatório Wida tem como objetivo apresentar de forma simples e
              organizada a análise do conjunto de informações e sintomas do
              paciente. Este relatório deve ser considerado um instrumento
              complementar de suporte ao processo de investigação diagnóstica e
              acompanhamento clínico, não substituindo a avaliação realizada por
              profissional habilitado.
             </Text>
          </View>
        </View>
      )}
    </View>
  )
}

export default Header
