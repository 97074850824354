import axios from 'axios'

const awsClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

awsClient.interceptors.request.use((config) => {
  return config
})

export default awsClient
