import { Box, Typography } from '@mui/material'
import noArchivedPatient from 'images/no-archived-patient.svg'

function ArchivedPatientListEmpty() {
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      flexGrow={1}
    >
      <img
        src={noArchivedPatient}
        height='74px'
        alt='Lista de pacientes vazia'
      />
      <Typography
        variant='title'
        color='#949494'
        fontFamily='Quicksand'
        lineHeight='36.5px'
        fontWeight={500}
        fontSize={20}
        mt={3}
        mb={1}
      >
        Você não possui pacientes arquivados
      </Typography>
      <Typography
        variant='text'
        textAlign='center'
        color='#949494'
        fontFamily='Zen Kaku Gothic Antique'
        fontSize={18}
        fontWeight={300}
        lineHeight='24.5px'
      >
        Para arquivar um paciente, acesse Meus Pacientes, <br /> clique no nome
        do paciente e acesse a aba Perfil.
      </Typography>
    </Box>
  )
}

export default ArchivedPatientListEmpty
