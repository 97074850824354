// import { useForm, Controller } from "react-hook-form";
import { Controller } from 'react-hook-form'
import {
  Checkbox,
  Box,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
} from '@mui/material'
import { styled } from '@mui/material/styles'

const sizes: any = {
  small: { fontSize: '12px', lineHeight: '17px' },
  medium: { fontSize: '16px', lineHeight: '23px' },
}

function CheckboxField({
  control,
  name,
  label = '',
  size = 'small',
  notShowError = false,
}: any) {
  return (
    <Box display='flex' alignItems='center'>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, value },
          fieldState: { invalid, error },
        }: any) => (
          <FormControl
            required
            {...((!notShowError && { error: Boolean(invalid) }) as any)}
            component='fieldset'
            variant='standard'
          >
            <FormGroup>
              <FormControlLabelStyled
                control={
                  <Checkbox checked={value} onChange={onChange} name={name} />
                }
                label={label}
                size={size}
              />
            </FormGroup>
            {!notShowError && invalid && (
              <FormHelperText>
                {error?.message || 'Erro desconhecido'}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
    </Box>
  )
}

const FormControlLabelStyled = styled(FormControlLabel)<any>(
  ({ size }: any) => ({
    '.MuiFormControlLabel-label': {
      fontFamily: 'Zen Kaku Gothic Antique',
      fontStyle: 'normal',
      fontWeight: '400',
      color: '#353f48',
      ...sizes[size],
    },
  })
)

export default CheckboxField
