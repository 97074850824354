import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Verified as VerifiedIcon } from '@mui/icons-material'
import SwitchMui, { SwitchProps as SwitchPropsMui } from '@mui/material/Switch'

export const InfoTitle = styled(Typography)`
  font-family: 'Zen Kaku Gothic Antique';
  margin-bottom: 15px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  color: #353f48;
`

export const InfoText = styled(Typography)`
  font-family: 'Zen Kaku Gothic Antique';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #596b7b;
`
export const CenterBox = styled(Box)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '10px',
})

export const InfoBox = styled(Box)({
  paddingTop: '20px',
})

export const StyledVerifiedIcon = styled(VerifiedIcon)({
  fill: '#6CC1B4',
  height: '14px',
})

export const StatusLabelStyled = styled(Typography)({
  color: '#596B7B',
  fontSize: '14px',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '14px',
})

export const SwitchStyled = styled((props: SwitchPropsMui) => (
  <SwitchMui
    focusVisibleClassName='.Mui-focusVisible'
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 32,
  height: 16,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 1,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#72C7C1',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#72C7C1',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 14,
    height: 14,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))
