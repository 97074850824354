import { useState, useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Box, Stack } from '@mui/material'
import { Button } from 'components'
import { updateUserInfos, generateValidationCode } from 'services'
import { useAuthContext } from 'contexts'
import { useMount, useNotification } from 'hooks'
import { sanitizeString } from 'helpers'
import useSettingsNotificationsForm from './notifications-form'
import { notificationsSteps } from './steps/notifications-steps'
import { NotificationsPatientGroupContext } from 'contexts/notifications-patient-group-context'

function SettingsNotifications(): JSX.Element {
  const [loadingSendCode, setLoadingSendCode] = useState(false)
  const [step, setStep] = useState<number>(0)

  const [hasChange, setHasChange] = useState<boolean>(false)
  const [formInitialState, setFormInitialState] = useState<any>({
    whatsapp: '',
    email: '',
    enableWhatsappNotification: false,
    enableEmailNotification: false,
  })

  const { userInfos, fetchUser } = useAuthContext()
  const { errorToast, successToast } = useNotification()
  const navigate = useNavigate()
  const { isMounted } = useMount()
  const methods = useSettingsNotificationsForm()
  const { trigger, handleSubmit, getValues, setValue, watch } = methods

  const [
    whatsappDirty,
    email,
    enableEmailNotification,
    enableWhatsappNotification,
  ] = watch([
    'whatsapp',
    'email',
    'enableEmailNotification',
    'enableWhatsappNotification',
  ])

  useEffect(() => {
    if (!isMounted) return

    const whatsapp = sanitizeString(whatsappDirty)

    const hasChangeEmail =
      enableEmailNotification && email !== formInitialState.email
    const hasChangeWhats =
      enableWhatsappNotification && whatsapp !== formInitialState.whatsapp

    setHasChange(
      hasChangeEmail ||
        hasChangeWhats ||
        enableEmailNotification !== formInitialState.enableEmailNotification ||
        enableWhatsappNotification !==
          formInitialState.enableWhatsappNotification
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    whatsappDirty,
    email,
    enableEmailNotification,
    enableWhatsappNotification,
  ])

  const resetForm = () => {
    setFormInitialState({
      whatsapp: userInfos?.user?.communication?.includes('whatsapp')
        ? userInfos?.user?.whatsapp
        : '',
      email: userInfos?.user?.communication?.includes('email')
        ? userInfos?.user?.email
        : '',
      enableWhatsappNotification:
        userInfos?.user?.communication?.includes('whatsapp'),
      enableEmailNotification:
        userInfos?.user?.communication?.includes('email'),
    })

    setValue('whatsappValidationCode', '')
    setValue('emailValidationCode', '')
    setValue('hasNewWhatsappValidationCode', false)
    setValue('hasNewEmailValidationCode', false)
  }

  useEffect(() => {
    resetForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfos])

  const onSubmit = async (params: any) => {
    try {
      const communication = []
      if (params.enableEmailNotification) communication.push('email')
      if (params.enableWhatsappNotification) communication.push('whatsapp')

      const body = {
        ...(Boolean(params.whatsappValidationCode) && {
          ddi: '55',
          whatsapp: sanitizeString(params.whatsapp),
          whatsappValidationCode: params.whatsappValidationCode,
        }),
        ...(Boolean(params.emailValidationCode) && {
          email: params.email.toLowerCase(),
          emailValidationCode: params.emailValidationCode,
        }),
        communication,
      }

      await updateUserInfos(body)

      successToast('Informações atualizadas com sucesso')

      fetchUser()
      navigate('/settings/notifications')
    } catch (error: any) {
      errorToast(
        error?.response?.data?.message || error?.message || 'Erro desconhecido'
      )
    }
  }

  const sendValidationCode = async (
    contactType: string,
    showToast: boolean
  ) => {
    try {
      setLoadingSendCode(true)
      const contact = getValues(contactType as any)

      await generateValidationCode({
        name: userInfos?.personalInfos?.firstName,
        type: contactType,
        contact:
          contactType === 'whatsapp'
            ? 55 + sanitizeString(contact)
            : contact.toLowerCase(),
      })

      if (showToast) successToast('Código enviado com sucesso!')
    } catch (error) {
      errorToast(
        'Ocorreu um erro ao solicitar o código, por favor tente novamente.'
      )
    } finally {
      setLoadingSendCode(false)
    }
  }

  const handleSave = async () => {
    const valid = await trigger(notificationsSteps[step].fields as any)

    if (!valid) return

    const whatsapp = sanitizeString(whatsappDirty)

    if (notificationsSteps[step].id === 'notifications-contact-step') {
      const hasToSendWhatsappCode =
        enableWhatsappNotification &&
        whatsapp &&
        whatsapp !== formInitialState.whatsapp

      const hasToSendEmailCode =
        enableEmailNotification && email && email !== formInitialState.email

      if (hasToSendWhatsappCode) {
        await sendValidationCode('whatsapp', false)
        setValue('hasNewWhatsappValidationCode', true)
        setValue('whatsappValidationCode', '')
      }

      if (hasToSendEmailCode) {
        await sendValidationCode('email', false)
        setValue('hasNewEmailValidationCode', true)
        setValue('emailValidationCode', '')
      }

      if (hasToSendWhatsappCode) return setStep((step) => step + 1)
      if (hasToSendEmailCode) return setStep((step) => step + 2)
    }

    if (
      notificationsSteps[step].id ===
      'notifications-whatsapp-validation-code-step'
    ) {
      const hasToSendEmailCode =
        enableEmailNotification && email && email !== formInitialState.email

      if (hasToSendEmailCode) {
        return setStep((step) => step + 1)
      }
    }

    handleSubmit(onSubmit)()
  }

  const handleCancel = () => {
    if (notificationsSteps[step].id === 'notifications-contact-step') {
      navigate('/settings')
    }

    if (
      notificationsSteps[step].id ===
      'notifications-whatsapp-validation-code-step'
    ) {
      resetForm()
      setStep((step) => step - 1)
    }

    if (
      notificationsSteps[step].id === 'notifications-email-validation-code-step'
    ) {
      resetForm()
      setStep((step) => step - 2)
    }
  }

  return (
    <Box
      flexGrow={1}
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='space-between'
      component='form'
      onSubmit={handleSubmit(onSubmit)}
      px={2}
      pt={5}
      sx={{
        backgroundColor: '#ffffff',
        borderRadius: '28px 28px 0px 0px',
      }}
    >
      <NotificationsPatientGroupContext.Provider
        value={{
          sendValidationCode,
          loadingSendCode,
        }}
      >
        <FormProvider {...methods}>
          {notificationsSteps[step].component}

          <Stack my={3} width='295px' spacing={1}>
            <Button type='button' onClick={handleSave} disabled={!hasChange}>
              Salvar
            </Button>
            <Button variant='text' onClick={handleCancel}>
              Voltar
            </Button>
          </Stack>
        </FormProvider>
      </NotificationsPatientGroupContext.Provider>
    </Box>
  )
}

export default SettingsNotifications
