import SelectedRespondersStep from './select-responders-step'
import SelectTagsStep from './select-tags-step'
import WithClinicalOpinion from './with-clinical-opinion'

export const createPatientReportDefaultSteps = [
  {
    id: 'select_responder',
    component: <SelectedRespondersStep />,
  },
  {
    id: 'select_tags',
    component: <SelectTagsStep />,
  },
  {
    id: 'with_clinical_opinion',
    component: <WithClinicalOpinion />,
  },
]
