import { useForm } from 'react-hook-form'
import { forgotPasswordResolver } from 'resolvers'
import { IFormInput } from './forgot-password-types'

function useFormForgotPassword() {
  const methods = useForm<IFormInput>({
    mode: 'onBlur',
    resolver: forgotPasswordResolver,
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  })

  return methods
}

export default useFormForgotPassword
