import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'

export const ResumeText = styled(Typography)({
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '10px',
  lineHeight: '13px',
  color: '#000000',
})
