import CpfStep from './general-steps/cpf-step'
import PasswordStep from './general-steps/password-step'
import EnvironmentStep from './sign-up-informant-steps/environment-step'
import { InformantStepType } from 'types'
import ContactStep from './general-steps/contact-step'
import WhatsappValidationCodeStep from './general-steps/whatsapp-validation-code-step'
import EmailValidationCodeStep from './general-steps/email-validation-code-step'
import TermsStep from './general-steps/terms-step'
import InformantPersonalInfoStep from './sign-up-informant-steps/informant-personal-info-step'

export const informantSteps: InformantStepType[] = [
  {
    id: 'cpf-step',
    component: <CpfStep />,
    fields: ['cpf'],
    hasNextButton: true,
    stepperIndex: 0,
    stepTitle: 'Acesso',
  },
  {
    id: 'informant-personal-info-step',
    component: <InformantPersonalInfoStep />,
    fields: ['firstName', 'lastName', 'relation'],
    hasNextButton: true,
    stepperIndex: 1,
    stepTitle: 'Sobre você',
  },
  {
    id: 'environment-step',
    component: <EnvironmentStep />,
    fields: ['environment'],
    hasNextButton: true,
    stepperIndex: 2,
    stepTitle: 'Ambiente',
  },
  {
    id: 'contact-step',
    component: <ContactStep />,
    fields: ['whatsapp', 'email'],
    hasNextButton: true,
    stepperIndex: 3,
    stepTitle: 'Contato',
  },
  {
    id: 'whatsapp-verification-code-step',
    component: <WhatsappValidationCodeStep type='informant' />,
    fields: ['whatsappValidationCode'],
    hasNextButton: true,
    stepperIndex: 4,
    stepTitle: 'Confirmação',
  },
  {
    id: 'email-verification-code-step',
    component: <EmailValidationCodeStep type='informant' />,
    fields: ['emailValidationCode'],
    hasNextButton: true,
    stepperIndex: 4,
    stepTitle: 'Confirmação',
  },
  {
    id: 'password-step',
    component: <PasswordStep />,
    fields: ['password', 'confirmPassword'],
    hasNextButton: true,
    stepperIndex: 5,
    stepTitle: 'Senha',
  },
  {
    id: 'terms-step',
    component: <TermsStep />,
    fields: ['acceptedShare', 'acceptedTermsAndPolicies'],
    hasNextButton: true,
    stepperIndex: 6,
    stepTitle: 'Segurança',
  },
]
