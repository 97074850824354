import { Box, Stack } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { CodeField } from 'components/inputs'
import { useNotificationsPatientGroupContext } from 'contexts'
import { Title, Description } from '../../settings-styles'
import { ResendButton } from '../notifications-styles'

function NotificationsEmailValidationCodeStep(): JSX.Element {
  const { control } = useFormContext()
  const { sendValidationCode, loadingSendCode } =
    useNotificationsPatientGroupContext()

  return (
    <Stack width='100%'>
      <Stack display='flex' flexDirection='column' spacing={2} mb={3}>
        <Title>Confirmação</Title>
        <Description>
          Digite o código recebido no seu e-mail para validar sua conta
        </Description>
      </Stack>
      <Box display='flex' mb={5} justifyContent='center'>
        <CodeField name='emailValidationCode' control={control} />
      </Box>
      <Box display='flex' justifyContent='center'>
        <ResendButton
          onClick={() => !loadingSendCode && sendValidationCode('email', true)}
          disabled={loadingSendCode}
        >
          {loadingSendCode ? 'Aguarde...' : 'Enviar novamente'}
        </ResendButton>
      </Box>
    </Stack>
  )
}

export default NotificationsEmailValidationCodeStep
