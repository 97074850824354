import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  answerVariationView: {
    flexDirection: 'row',
    gap: 46,
    backgroundColor: '#F3F3F3',
    paddingHorizontal: 20,
    paddingVertical: 4,
    borderRadius: 3,
    minHeight: 24,
  },
  answerVariationText: {
    fontSize: 9,
    fontFamily: 'Zen Kaku Gothic Antique',
    color: '#596B7B',
    lineHeight: 1.6,
  },
  answerVariationItemView: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
})
