import { useMemo } from 'react'
import { Box, Container } from '@mui/material'

interface Colors {
  [key: string]: string
}

const bgColors: Colors = {
  white: '#FFFFFF',
  green: '#06A599',
  orange: '#EE7262',
  default: '#06A599',
}

const background: any = {
  gradient:
    'linear-gradient(299.95deg, #A8ACCF 0%, #A8ACCF 59.66%, #C5C8E3 98.09%)',
  default: 'transparent',
}

function RouteLayout({ bgColor, bg, children }: any): JSX.Element {
  const innerHeight = useMemo(() => window.innerHeight, [])

  return (
    <Box
      minHeight={innerHeight}
      height='100%'
      display='flex'
      sx={{
        backgroundColor: bgColors[bgColor] || bgColors.default,
        background: background[bg],
      }}
    >
      <Container
        maxWidth='xs'
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          padding: 0,
        }}
      >
        {children}
      </Container>
    </Box>
  )
}

export default RouteLayout
