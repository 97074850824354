import { useNavigate, Outlet, useLocation } from 'react-router-dom'
import { Box, Stack, IconButton } from '@mui/material'
import Close from '@mui/icons-material/Close'
import { RouteLayout } from 'components'
import { useAuthContext } from 'contexts'
import { generateInitial } from 'helpers/formatters'
import { AvatarStyled, UserName } from './settings-styles'

function Settings(): JSX.Element {
  const { userInfos } = useAuthContext()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const handleClose = () => {
    navigate(pathname === '/settings' ? '/' : '/settings')
  }

  return (
    <RouteLayout bg='gradient'>
      <Box mt={2} display='flex' justifyContent='flex-end'>
        <IconButton onClick={handleClose} sx={{ color: '#fff' }}>
          <Close />
        </IconButton>
      </Box>
      <Stack
        spacing={2}
        mb={3}
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
      >
        <AvatarStyled>
          {generateInitial(
            userInfos.personalInfos.firstName,
            userInfos.personalInfos.lastName
          )}
        </AvatarStyled>
        <UserName>
          {userInfos.personalInfos.firstName +
            ' ' +
            userInfos.personalInfos.lastName}
        </UserName>
      </Stack>
      <Outlet />
    </RouteLayout>
  )
}

export default Settings
