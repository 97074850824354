import { useForm } from 'react-hook-form'
import { signUpClinicResolver } from 'resolvers'
import { ISignUpClinicForm } from './sign-up-clinic-types'

function useFormSignUpClinic() {
  const methods = useForm<ISignUpClinicForm>({
    mode: 'onBlur',
    resolver: signUpClinicResolver,
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      cpf: '',
      whatsapp: '',
      communication: [],
      professionalCouncil: '',
      register: '',
      emailValidationCode: '',
    },
  })

  return methods
}

export default useFormSignUpClinic
