interface CheckIconProps {
  color?: string
  margin?: string
}

const CheckIcon = ({ color = '#BDC1DD', margin }: CheckIconProps) => {
  return (
    <svg
      width='65'
      height='58'
      viewBox='0 0 65 58'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ margin }}
    >
      <path
        d='M0.00304815 38.3608C0.00304815 35.4069 1.76528 32.7724 4.66182 31.5749C7.59887 30.3574 10.8803 30.996 13.1691 33.2314C15.215 35.2273 17.2608 37.2032 19.2458 39.239C19.894 39.9176 20.2788 39.8777 20.927 39.219C31.1763 29.08 41.4459 18.9809 51.6952 8.86184C53.984 6.62647 56.6578 5.86804 59.7569 6.86597C65.2056 8.62234 66.7653 15.4083 62.6129 19.5198C56.7793 25.3278 50.8849 31.0958 45.0108 36.8839C38.8126 42.9912 32.6145 49.1186 26.396 55.2259C23.3982 58.1598 19.5294 58.7586 15.9036 56.9224C14.4655 56.1839 13.4527 54.9665 12.3184 53.8687C8.95599 50.5955 5.63409 47.2823 2.29192 43.9892C0.793014 42.5122 -0.0577184 40.7159 0.00304815 38.3608ZM60.8304 14.1709C60.8304 12.0952 59.4328 10.718 57.4882 10.6182C56.1514 10.5583 55.1994 11.1771 54.3081 12.0552C43.6335 22.5934 32.9385 33.1316 22.2436 43.6698C20.4611 45.4262 19.6914 45.4262 17.8684 43.6499C15.3365 41.155 12.8248 38.6602 10.2726 36.1853C8.75344 34.7283 6.74814 34.6684 5.26949 35.9857C3.70981 37.3828 3.73007 39.5983 5.35051 41.2149C9.01676 44.8474 12.7033 48.4599 16.3695 52.0924C18.8609 54.5473 21.2714 54.5473 23.7425 52.1124C27.8949 48.0208 32.0473 43.9293 36.1997 39.8378C43.9576 32.1936 51.7154 24.5494 59.4733 16.9052C60.2835 16.1069 60.8912 15.2287 60.8304 14.1709Z'
        fill={color}
      />
    </svg>
  )
}

export default CheckIcon
