import { useEffect, useRef } from 'react'
import { Stack, Box } from '@mui/material'
import { useClinicContext } from 'contexts'
import TermsOfUse from './terms-of-use'
import PrivacyPolice from './privacy-policy'
import { SectionLink, SectionTitleText, Section } from './terms-tab-styles'

function TermsTab(): JSX.Element {
  const { handleSave } = useClinicContext()

  const termsRef = useRef<any>(null)
  const policyRef = useRef<any>(null)

  useEffect(() => {
    handleSave.current = () => true

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const scrollToTerms = () =>
    termsRef.current?.scrollIntoView({
      behavior: 'smooth',
    })

  const scrollToPolicy = () =>
    policyRef.current?.scrollIntoView({
      behavior: 'smooth',
    })

  return (
    <Stack
      p='0 40px'
      flexGrow={1}
      height='100%'
      justifyContent='flex-start'
      alignItems='flex-start'
      sx={{
        '.clinic-profile-section-terms > *, .clinic-profile-section-privacy-policy > *':
          {
            margin: 0,
            padding: 0,
            boxSizing: 'border-box',
            fontFamily: "'Zen Kaku Gothic Antique', sans-serif",
            fontSize: '16px',
            lineHeight: '22px',
            color: '#353f48',
          },
        '.clinic-profile-section-terms > p, .clinic-profile-section-privacy-policy > p':
          {
            marginBottom: '12px',
            fontWeight: 500,
          },
      }}
    >
      <Stack component='ul' spacing='12px'>
        <li style={{ color: '#8D92BE' }}>
          <SectionLink onClick={scrollToTerms}>Termos de uso</SectionLink>
        </li>
        <li style={{ color: '#8D92BE' }}>
          <SectionLink onClick={scrollToPolicy}>
            Política de privacidade
          </SectionLink>
        </li>
      </Stack>

      <Box
        ref={termsRef}
        my={3}
        display='flex'
        width='100%'
        justifyContent='center'
      >
        <SectionTitleText>Termos de uso</SectionTitleText>
      </Box>
      <Section className='clinic-profile-section-terms'>
        <TermsOfUse scrollToPolicy={scrollToPolicy} />
      </Section>

      <Box
        ref={policyRef}
        my={3}
        display='flex'
        width='100%'
        justifyContent='center'
      >
        <SectionTitleText>Política de privacidade</SectionTitleText>
      </Box>
      <Section className='clinic-profile-section-privacy-policy'>
        <PrivacyPolice />
      </Section>
    </Stack>
  )
}

export default TermsTab
