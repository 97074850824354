import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import {
  COLOR_YES,
  COLOR_SOSO,
  COLOR_NO,
  GRAPHIC_HEIGHT,
} from 'helpers/constants'

const BORDER_RADIUS_TOP = '16px 16px 0px 0px'
const BORDER_RADIUS_ALL = '16px'
const BORDER_RADIUS_BOTTOM = '0px 0px 16px 16px'
const BORDER_RADIUS_SQUARE = '0px'

export const Container = styled(Box)({
  width: '30px',
  height: `${GRAPHIC_HEIGHT}px`,
  zIndex: 1,
})

export const DashedArea = styled(Box)<any>(
  ({ quantities, maxTagQuestions, questionHeight }: any) => {
    const heightYes = Math.ceil(questionHeight * (quantities.yes || 0))
    const heightSoso = Math.ceil(questionHeight * (quantities.soso || 0))
    const heightNo = Math.ceil(questionHeight * (quantities.no || 0))
    const heightWhite =
      (GRAPHIC_HEIGHT - (heightYes + heightSoso + heightNo)) / 2

    const heightDashed = questionHeight * maxTagQuestions
    const heightWhiteInside = Math.ceil(
      (heightDashed - (heightYes + heightSoso + heightNo)) / 2
    )

    const marginTop = Math.ceil(
      heightWhite - heightYes / 2 + heightNo / 2 - heightWhiteInside
    )

    const hasBorder = Boolean(Math.abs(heightWhiteInside))

    return {
      marginTop: `${marginTop}px`,
      width: '30px',
      height: `${heightDashed}px`,
      outline: hasBorder ? '1px dashed #DFDFDF' : 'none',
      borderRadius: '16px',
    }
  }
)

export const StyledGraphic = styled(Box)<any>(
  ({ quantities, hasClick, hasSquareBorder, questionHeight }: any) => {
    const heightYes = Math.ceil(questionHeight * (quantities.yes || 0))
    const heightSoso = Math.ceil(questionHeight * (quantities.soso || 0))
    const heightNo = Math.ceil(questionHeight * (quantities.no || 0))

    const onlyYes = heightSoso + heightNo === 0
    const onlyNo = heightYes + heightSoso === 0

    const borderRadius = hasSquareBorder
      ? BORDER_RADIUS_SQUARE
      : onlyYes
      ? BORDER_RADIUS_TOP
      : onlyNo
      ? BORDER_RADIUS_BOTTOM
      : BORDER_RADIUS_ALL

    return {
      width: 32,
      zIndex: 1,
      height: `${heightYes + heightSoso + heightNo}px`,
      background:
        `linear-gradient(to bottom, ` +
        `${COLOR_YES} 0px ${heightYes}px, ` +
        `${COLOR_SOSO} ${heightYes}px ${heightYes + heightSoso}px, ` +
        `${COLOR_NO} ${heightYes + heightSoso}px ${
          heightYes + heightSoso + heightNo
        }px)`,
      cursor: hasClick ? 'pointer' : 'default',
      borderRadius: borderRadius,
    }
  }
)
