import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  checkValidBirthdateForDependent,
  checkValidBirthdateForUser,
  testStrongPassword,
  resolveEmailValidationCode,
  resolveWhatsappValidationCode,
} from 'helpers/resolvers'

yup.setLocale({
  mixed: {
    required: 'Campo obrigatório',
  },
})

export const editPatientPersonalInfosResolver = yupResolver(
  yup
    .object({
      firstName: yup
        .string()
        .required()
        .min(2, 'Primeiro nome deve ter entre 2 e 25 caracteres')
        .max(25, 'Primeiro nome deve ter entre 2 e 25 caracteres'),
      lastName: yup
        .string()
        .required()
        .min(2, 'Sobrenome deve ter entre 2 e 50 caracteres')
        .max(50, 'Sobrenome deve ter entre 2 e 50 caracteres'),
      pronoun: yup.string().required(),
      birthdate: yup
        .string()
        .nullable()
        .required()
        .test(
          'valid_date',
          'Digite uma data válida',
          checkValidBirthdateForUser
        ),
    })
    .required()
)

export const editCaregiverPersonalInfosResolver = yupResolver(
  yup
    .object({
      caregiverFirstName: yup
        .string()
        .required()
        .min(2, 'Seu primeiro nome deve ter entre 2 e 25 caracteres')
        .max(25, 'Seu primeiro nome deve ter entre 2 e 25 caracteres'),
      caregiverLastName: yup
        .string()
        .required()
        .min(2, 'Seu sobrenome deve ter entre 2 e 50 caracteres')
        .max(50, 'Seu sobrenome deve ter entre 2 e 50 caracteres'),
      firstName: yup
        .string()
        .required()
        .min(2, 'Primeiro nome deve ter entre 2 e 25 caracteres')
        .max(25, 'Primeiro nome deve ter entre 2 e 25 caracteres'),
      lastName: yup
        .string()
        .required()
        .min(2, 'Sobrenome deve ter entre 2 e 50 caracteres')
        .max(50, 'Sobrenome deve ter entre 2 e 50 caracteres'),
      pronoun: yup.string().required(),
      birthdate: yup
        .string()
        .nullable()
        .required()
        .test(
          'valid_date',
          'Digite uma data válida',
          checkValidBirthdateForDependent
        ),
    })
    .required()
)

export const editInformantPersonalInfosResolver = yupResolver(
  yup
    .object({
      firstName: yup
        .string()
        .required()
        .min(2, 'Primeiro nome deve ter entre 2 e 25 caracteres')
        .max(25, 'Primeiro nome deve ter entre 2 e 25 caracteres'),
      lastName: yup
        .string()
        .required()
        .min(2, 'Sobrenome deve ter entre 2 e 50 caracteres')
        .max(50, 'Sobrenome deve ter entre 2 e 50 caracteres'),
    })
    .required()
)
export const editSecurityResolver = yupResolver(
  yup
    .object({
      currentPassword: yup.string().required(),
      password: yup
        .string()
        .min(8, 'Sua senha deve ter mais que 8 caracteres')
        .test(
          'strong_password',
          'Sua senha deve ter letra minúscula, maiúscula, símbolo e número',
          testStrongPassword
        )
        .required(),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Senhas devem ser iguais'),
    })
    .required()
)

export const editNotificationsResolver = yupResolver(
  yup.object().shape(
    {
      email: yup.string().when('enableEmailNotification', {
        is: true,
        then: yup.string().email('Digite um email válido').required(),
      }),
      whatsapp: yup.string().when('enableWhatsappNotification', {
        is: true,
        then: yup.string().required(),
      }),
      enableEmailNotification: yup.bool().when('enableWhatsappNotification', {
        is: false,
        then: yup
          .bool()
          .oneOf(
            [true],
            'Pelo menos um canal de comunicação deve ser selecionado'
          ),
      }),
      enableWhatsappNotification: yup.bool().when('enableEmailNotification', {
        is: false,
        then: yup
          .bool()
          .oneOf(
            [true],
            'Pelo menos um canal de comunicação deve ser selecionado'
          ),
      }),
      emailValidationCode: yup.string().when('hasNewEmailValidationCode', {
        is: true,
        then: yup
          .string()
          .required()
          .min(6, 'Código inválido')
          .max(6, 'Código inválido')
          .test('is-valid', 'Código inválido', resolveEmailValidationCode),
      }),
      whatsappValidationCode: yup
        .string()
        .when('hasNewWhatsappValidationCode', {
          is: true,
          then: yup
            .string()
            .required()
            .min(6, 'Código inválido')
            .max(6, 'Código inválido')
            .test('is-valid', 'Código inválido', resolveWhatsappValidationCode),
        }),
    },
    ['enableEmailNotification', 'enableWhatsappNotification'] as any
  )
)
