import axios from 'axios'

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

client.interceptors.request.use((config) => {
  const token = localStorage.getItem('wida:token')

  return token
    ? {
        ...config,
        headers: { ...config.headers, Authorization: `Bearer ${token}` },
      }
    : config
})

export default client
