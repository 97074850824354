import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { isToday, isValid } from 'date-fns'
import { Box, Stack } from '@mui/material'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'
import {
  SecondaryConfirmationModal,
  TextFieldClinic,
  ButtonClinic,
} from 'components'
import { sendWhatsappInvitePatient, updateSendMethod } from 'services'
import { whatsappResolver } from 'resolvers'
import { useNotification } from 'hooks'
import { useInvitePatientContext } from 'contexts'
import { sanitizeString, registerTrack } from 'helpers'
import { SendMethodEnum } from 'types/invites'
import { HelpText, ExplanationText } from './send-step-styles'

function WhatsappModal({ isOpen, setIsOpen, handleCreateQuestionnaire }: any) {
  const [expanded, setExpanded] = useState(false)
  const [sending, setSending] = useState(false)

  const { errorToast } = useNotification()
  const { control, trigger, getValues } = useForm<any>({
    defaultValues: {
      whatsapp: '',
    },
    resolver: whatsappResolver,
  })
  const { goNextStep, newQuestionnaireInfos, setNewQuestionnaireInfos } =
    useInvitePatientContext()

  const onSubmit = async () => {
    const validation = await trigger()

    if (!validation) return

    setSending(true)

    let invitationId: any = newQuestionnaireInfos?.invite?.id
    let invitationCode = newQuestionnaireInfos?.invitationCode
    let invitationToken = newQuestionnaireInfos?.invitationToken
    let formId = newQuestionnaireInfos?.formId

    try {
      if (!newQuestionnaireInfos?.formId) {
        const { whatsapp } = getValues()

        const infos = await handleCreateQuestionnaire({
          sendMethod: SendMethodEnum.WHATSAPP,
          whatsapp: '55' + sanitizeString(whatsapp),
        })
        invitationId = infos?.invite?.id
        invitationCode = infos?.invitationCode
        invitationToken = infos?.invitationToken
        formId = infos?.formId
      }
    } catch {
      errorToast(
        'Ocorreu um erro ao criar o questionário, por favor tente novamente'
      )

      registerTrack('Erro criação questionário', {
        channel: SendMethodEnum.WHATSAPP,
        type: 'first_time',
      })

      setSending(false)
      return
    }

    const isScheduled =
      isValid(newQuestionnaireInfos?.startDate) &&
      !isToday(newQuestionnaireInfos?.startDate)

    if (isScheduled) {
      goNextStep()
      setSending(false)
      return
    }

    try {
      const { whatsapp } = getValues()

      const body = {
        invitationCode,
        invitationToken,
        whatsapp: '55' + sanitizeString(whatsapp),
      }

      await sendWhatsappInvitePatient(body)

      await updateSendMethod({
        inviteId: invitationId,
        sendMethod: SendMethodEnum.WHATSAPP,
      })

      registerTrack('Questionário enviado', {
        channel: SendMethodEnum.WHATSAPP,
        type: 'first_time',
        form_id: formId,
      })

      setNewQuestionnaireInfos((newQuestionnaireInfos: any) => ({
        ...newQuestionnaireInfos,
        sendingChannel: SendMethodEnum.WHATSAPP,
      }))

      goNextStep()
    } catch {
      errorToast('Ocorreu um erro ao enviar o convite por WhatsApp')

      registerTrack('Erro envio questionário', {
        channel: SendMethodEnum.WHATSAPP,
        type: 'first_time', // first_time ou repeat
      })
    } finally {
      setSending(false)
    }
  }

  return (
    <SecondaryConfirmationModal
      isOpen={isOpen}
      title='Envio do convite por WhatsApp'
      hideButtons
      onClose={() => setIsOpen(false)}
      content={
        <Stack alignItems='center'>
          <Box width={480} mt={5}>
            <TextFieldClinic
              mask='(00) 00000-0000'
              name='whatsapp'
              control={control}
              placeholder='Digite o celular do paciente ou responsável'
            />
          </Box>
          <Stack
            mt={5}
            direction='row'
            alignItems='center'
            spacing={1}
            sx={{ cursor: 'pointer' }}
            onClick={() => setExpanded(!expanded)}
          >
            <HelpText>Para quem devo enviar este convite?</HelpText>

            {expanded ? (
              <ExpandLessRoundedIcon
                fontSize='medium'
                sx={{ fill: '#596B7B' }}
              />
            ) : (
              <ExpandMoreRoundedIcon
                fontSize='medium'
                sx={{ fill: '#596B7B' }}
              />
            )}
          </Stack>

          {expanded && (
            <Box width={447} mt={3}>
              <ExplanationText>
                Você deve enviar este convite para a pessoa que irá administrar
                a conta do Wida (paciente OU responsável). Apenas o
                administrador da conta poderá compartilhar o questionário com
                outras pessoas e ser notificado automaticamente quando existirem
                novos questionários.
              </ExplanationText>
            </Box>
          )}
          <Stack direction='row' spacing={4} mt={3} mb={3}>
            <ButtonClinic
              variant='outlined'
              onClick={() => setIsOpen(false)}
              width={140}
              color='purple'
              size='small'
              fontWeight='bold'
            >
              Cancelar
            </ButtonClinic>
            <ButtonClinic
              variant='contained'
              onClick={onSubmit}
              width={140}
              color='purple'
              size='small'
              fontWeight='bold'
              loading={sending}
            >
              {sending ? 'Enviando' : 'Enviar'}
            </ButtonClinic>
          </Stack>
        </Stack>
      }
    />
  )
}

export default WhatsappModal
