import { useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Box, Stack, Tooltip, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import { useNavigate, useParams } from 'react-router-dom'
import { ButtonClinic, SwitchIos } from 'components'
import { ResultsSelect } from 'components/results'
import { useResultsContext } from 'contexts'
import {
  SettingsLabel,
  BreadcrumbDivider,
  ExplanationContainer,
  ExplanationText,
} from './results-header-styles'
import sankeyIcon from 'components/icons/sankey-icon.svg'
import {
  LabelIconYes,
  LabelIconSoso,
  LabelIconNo,
  LabelIconExpand,
  LabelIconFlip,
} from './results-header-components'

function ResultsHeader({ showChart = true }: any): JSX.Element {
  const [explanationOpen, setExplanationOpen] = useState(true)

  const { tagOptions, responderOptions, showDetail, patient, timeline } =
    useResultsContext()
  const { control, setValue, watch } = useFormContext()

  const selectedResponders = watch('selectedResponders')

  const { patient_id } = useParams()
  const navigate = useNavigate()

  // TODO change when multiresponders
  const responder = useMemo(() => {
    return responderOptions.find(({ id }: any) => id === selectedResponders[0])
  }, [selectedResponders, responderOptions])

  const handleSelectOldTags = () => {
    setValue(
      'selectedTags',
      tagOptions.filter(({ old }: any) => old).map(({ value }: any) => value)
    )
  }

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='flex-start'
      pb={2}
      flexWrap='wrap'
      flexDirection='column'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        width='100%'
      >
        <Stack direction='row' alignItems='center'>
          <Box mx={1}>
            <ResultsSelect
              name='selectedResponders'
              control={control}
              singleSelect
              options={responderOptions}
              newOptions={responderOptions.filter(({ old }: any) => !old)}
              oldOptions={responderOptions.filter(({ old }: any) => old)}
              removeDivider={responderOptions.every(({ old }: any) => old)}
            />
          </Box>
          <BreadcrumbDivider>/</BreadcrumbDivider>
          <Box ml={1}>
            <ResultsSelect
              name='selectedTags'
              control={control}
              options={tagOptions}
              newOptions={tagOptions.filter(({ old }: any) => !old)}
              oldOptions={tagOptions.filter(({ old }: any) => old)}
              placeholder='Domínios selecionados'
              placeholderAll='Todos os domínios'
              singleSelect={showDetail}
              emptyNewText='Não há resultados nos últimos 90 dias.'
              handleClickOldLabel={handleSelectOldTags}
            />
          </Box>
        </Stack>

        <Box display='flex' justifyContent='space-between' alignItems='center'>
          {showChart && (
            <Stack direction='row' alignItems='center' spacing={5}>
              <Tooltip title='Ativar/desativar gráfico proporcional'>
                <Stack direction='row' alignItems='center' spacing={1}>
                  <SwitchIos name='activeProportion' control={control} />
                  <SettingsLabel>100%</SettingsLabel>
                </Stack>
              </Tooltip>
              {showDetail && (
                <Tooltip
                  title={
                    timeline.length === 1
                      ? 'Disponível após reenvio'
                      : 'Ativar/desativar sankey'
                  }
                >
                  <Stack direction='row' alignItems='center' spacing={1}>
                    <SwitchIos
                      name='activeSankey'
                      control={control}
                      disabled={timeline.length === 1}
                    />
                    <Box
                      width='30px'
                      height='30px'
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                    >
                      <img
                        src={sankeyIcon}
                        alt='Ícone de sankey'
                        style={{ width: '30px', height: '30px' }}
                      />
                    </Box>
                  </Stack>
                </Tooltip>
              )}

              <ButtonClinic
                height={40}
                variant='outlined'
                color='purple'
                fontWeight='bold'
                size='small'
                icon='report'
                iconPosition='start'
                onClick={() => {
                  navigate(`/patients/${patient_id}/create_report`)
                }}
              >
                Gerar relatório
              </ButtonClinic>
            </Stack>
          )}
        </Box>
      </Box>
      {!showDetail && explanationOpen && (
        <ExplanationContainer>
          <Box>
            <ExplanationText>
              Abaixo estão as últimas respostas de{' '}
              {responder.isPatient
                ? patient.firstName
                : `${responder.label} sobre ${patient.firstName}`}
              , organizadas em sintomas presentes <LabelIconYes />,
              indeterminados <LabelIconSoso /> ou ausentes <LabelIconNo />.
            </ExplanationText>
            <ExplanationText>
              Para ver um resumo das respostas, clique em <LabelIconFlip /> .
              Você também pode ver um detalhamento dos resultados clicando em{' '}
              <LabelIconExpand />
            </ExplanationText>
          </Box>
          <IconButton
            aria-label='close'
            onClick={() => setExplanationOpen(false)}
            sx={{ color: '#D7D7D7' }}
          >
            <Close />
          </IconButton>
        </ExplanationContainer>
      )}
    </Box>
  )
}

export default ResultsHeader
