import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { Box, CircularProgress, Stack } from '@mui/material'
import { ButtonClinic, ClinicLayout, ProgressBar } from 'components'
import { FETCHING, IDLE, SUBMITTING } from 'helpers/constants'
import { CreatePatientReportContext } from 'contexts'
import {
  getPatientResponders,
  getPatientResponses,
  getInformantResponses,
  setPatientReportComplete,
  fetchPatient,
} from 'services'
import { useNotification } from 'hooks'
import { Container } from './create-patient-report-styles'
import { createPatientReportDefaultSteps } from './create-patient-report-steps'
import { createPatientReportClinicalOpinion } from 'services/patient-report-clinical-opinion'

function CreatePatientReport(): JSX.Element {
  const [steps, setSteps] = useState<any>(createPatientReportDefaultSteps)
  const [activeStep, setActiveStep] = useState<number>(0)
  const [status, setStatus] = useState(FETCHING)
  const [respondersWithResponses, setRespondersWithResponses] = useState<any>(
    []
  )
  const [selectedFormRequests, setSelectedFormRequests] = useState<any>([])
  const [createPatientReport, setCreatePatientReport] = useState<any>(null)
  const [generatedReportParams, setGeneratedReportParams] = useState({})
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [patient, setPatient] = useState<any>()
  const [clinicalOpinion, setClinicalOpinion] = useState<any>()

  const { patient_id } = useParams()
  const { errorToast } = useNotification()

  const methods = useForm({
    defaultValues: {
      responders: [{ responder: '', formRequest: '' }],
      tags: [],
    },
  })

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 })
  }, [activeStep])

  const isLastStep = useMemo(
    () => activeStep === steps.length - 2,
    [activeStep, steps]
  )

  const handleForward = async () => {
    try {
      if (isLastStep) {
        const tags = methods.getValues()?.tags
        const tagKeys = Object.keys(tags)

        const patientReportId = createPatientReport.id

        const clinicalOpinionPayload = tagKeys.reduce(
          (accumulator: any, currentValue) => {
            const questions = Object.keys(tags[currentValue as never])

            const newData = questions
              .map((question) => {
                const answer = tags[currentValue as never][question]
                if (answer && answer !== 'skip' && answer !== 'not_answer') {
                  return {
                    questionId: question,
                    tagId: currentValue,
                    answer: answer,
                  }
                }
                return null
              })
              .filter(Boolean)

            return [...accumulator, ...newData]
          },
          []
        )

        const payload = {
          clinicalOpinions: clinicalOpinionPayload,
          patientReportId,
        }

        await createPatientReportClinicalOpinion(payload)

        setStatus(SUBMITTING)
        await setPatientReportComplete(createPatientReport.id!)
        setStatus(IDLE)
      }

      setActiveStep((step: number) => step + 1)
    } catch (error) {
      errorToast(
        'Ocorreu um erro ao tentar completar o relatório, por favor tente novamente.'
      )
    } finally {
      setStatus(IDLE)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responders = await getPatientResponders(patient_id as string)

        const formattedResponders = responders.map((responder: any) => {
          return {
            id: responder.id,
            isPatient: responder.isPatient,
            name: responder.fullName,
            relation:
              !responder.relation?.name || responder.relation?.name === 'Sou eu'
                ? 'paciente'
                : responder.relation?.name?.toLowerCase(),
          }
        })

        const responsesArray = await Promise.all(
          formattedResponders.map(({ id, isPatient }: any) =>
            isPatient
              ? getPatientResponses(id)
              : getInformantResponses(patient_id as string, id)
          )
        )

        const respondersWithResponses = formattedResponders.map(
          (selectedResponder: any, index: number) => {
            const orderedResponseDates = responsesArray[index].sort(
              (respA: any, respB: any) =>
                (new Date(respB.responseDate) as any) -
                (new Date(respA.responseDate) as any)
            )

            return {
              ...selectedResponder,
              responses: orderedResponseDates,
            }
          }
        )

        setRespondersWithResponses(respondersWithResponses)

        if (patient_id) {
          const patientData = await fetchPatient(patient_id)
          setPatient(patientData)
        }
      } catch (error: any) {
        errorToast('Ocorreu um erro ao buscar as informações do paciente.')
        console.error(error.message)
      } finally {
        setStatus(IDLE)
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_id])

  return (
    <ClinicLayout>
      <CreatePatientReportContext.Provider
        value={{
          respondersWithResponses,
          selectedFormRequests,
          setSelectedFormRequests,
          activeStep,
          setActiveStep,
          createPatientReport,
          setCreatePatientReport,
          steps,
          setSteps,
          generatedReportParams,
          setGeneratedReportParams,
          selectedTags,
          setSelectedTags,
          patient,
          setPatient,
          clinicalOpinion,
          setClinicalOpinion,
          status,
          setStatus,
        }}
      >
        <FormProvider {...methods}>
          <>
            <Box m={'30px 68px 0px 68px'}>
              <ProgressBar
                totalSteps={steps.length - 1}
                activeStep={activeStep}
              />
            </Box>

            {status === FETCHING ? (
              <Box
                display='flex'
                width='100%'
                height='100%'
                alignItems='center'
                justifyContent='center'
              >
                <CircularProgress />
              </Box>
            ) : (
              <Container>
                {steps[activeStep]?.component}
                {steps[activeStep]?.id?.includes('report_by_tag') && (
                  <Stack
                    direction='row'
                    width='100%'
                    justifyContent='space-between'
                  >
                    <ButtonClinic
                      height={34}
                      width={150}
                      variant='outlined'
                      color='purple'
                      size='small'
                      fontWeight='bold'
                      onClick={() => setActiveStep((step: number) => step - 1)}
                    >
                      Voltar
                    </ButtonClinic>

                    <ButtonClinic
                      height={34}
                      width={150}
                      variant='contained'
                      color='purple'
                      size='small'
                      fontWeight='bold'
                      onClick={handleForward}
                      loading={status === SUBMITTING}
                    >
                      {isLastStep
                        ? status === SUBMITTING
                          ? 'Gerando'
                          : 'Gerar relatório'
                        : 'Continuar'}
                    </ButtonClinic>
                  </Stack>
                )}
              </Container>
            )}
          </>
        </FormProvider>
      </CreatePatientReportContext.Provider>
    </ClinicLayout>
  )
}

export default CreatePatientReport
