import { Close } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { Box } from '@mui/system'
import { ButtonClinic } from 'components'
import {
  DialogStyled,
  ContainerStyled,
  HeaderContainerStyled,
  TitleStyled,
  TextStyled,
  FooterContainerStyled,
} from './secondary-confirmation-modal-styles'

interface SecondaryConfirmationModalProps {
  title: string
  text?: string
  isOpen: boolean
  hideCancelButton?: boolean
  content?: React.ReactNode
  onClose?: () => void
  onConfirm?: () => void
  confirmText?: string
  hideButtons?: boolean
}

function SecondaryConfirmationModal({
  title,
  text,
  isOpen,
  hideCancelButton,
  content,
  onClose,
  onConfirm,
  confirmText = 'Confirmar',
  hideButtons = false,
}: SecondaryConfirmationModalProps) {
  return (
    <DialogStyled onBackdropClick={onClose} open={isOpen}>
      <HeaderContainerStyled>
        <Box display='flex' justifyContent='center' flexGrow={1}>
          <TitleStyled ml={5}>{title}</TitleStyled>
        </Box>
        <Box alignSelf='flex-start'>
          <IconButton onClick={onClose}>
            <Close color='disabled' />
          </IconButton>
        </Box>
      </HeaderContainerStyled>
      <ContainerStyled>
        {content ?? (
          <TextStyled style={{ padding: '0 100px' }}>{text}</TextStyled>
        )}
      </ContainerStyled>
      {!hideButtons && (
        <FooterContainerStyled>
          {!hideCancelButton && (
            <ButtonClinic
              variant='outlined'
              onClick={onClose}
              width={148}
              color='orange'
              size='small'
              fontWeight='bold'
            >
              Cancelar
            </ButtonClinic>
          )}
          <ButtonClinic
            variant='contained'
            onClick={onConfirm}
            width={148}
            color='orange'
            size='small'
            fontWeight='bold'
          >
            {confirmText}
          </ButtonClinic>
        </FooterContainerStyled>
      )}
    </DialogStyled>
  )
}

export default SecondaryConfirmationModal
