import { useMemo, useState } from 'react'
import { isToday, isValid } from 'date-fns'
import { Box, Stack, Tooltip } from '@mui/material'
import { ButtonClinic } from 'components'
import { useInvitePatientContext } from 'contexts'
import { useQuery } from 'hooks'
import {
  createUserQuestionnaires,
  getUserGroups,
  configFormRequestEnvironments,
  activateUser,
} from 'services'
import WhatsappModal from './whatsapp-modal'
import EmailModal from './email-modal'
import LinkModal from './link-modal'
import { Title, SupportText } from '../styles/common-styles'
import { TooltipText } from './send-step-styles'

const buttonCommonProps: any = {
  width: 300,
  variant: 'outlined',
  size: 'large',
  color: 'purple',
  fontWeight: 'bold',
}

type modalTypes = 'whatsapp' | 'email' | 'link' | ''

function SendStep(): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState<modalTypes>('')

  const { setNewQuestionnaireInfos, newQuestionnaireInfos, tags } =
    useInvitePatientContext()
  const query = useQuery()

  const patientName = useMemo(
    () => newQuestionnaireInfos.patientName?.split(' ')[0] || 'o paciente',
    [newQuestionnaireInfos.patientName]
  )

  const handleOpenModal = (type: modalTypes) => {
    setIsModalOpen(type)
  }

  const handleCreateQuestionnaire = async (methodInfos: any) => {
    const userId = query.get('user_id')
    const userGroups = await getUserGroups()

    const selectedTagsIds = newQuestionnaireInfos.selectedTags.map(
      (selectedTag: any) => {
        const findTagApi = tags.find(
          (tag: any) => tag.name === selectedTag.name
        )
        return findTagApi.id
      }
    )

    const body = {
      name: newQuestionnaireInfos.patientName,
      questions: newQuestionnaireInfos.selectedQuestions.map(
        ({ id }: any) => id
      ),
      sendTo: userId,
      frequency: newQuestionnaireInfos.frequency,
      startDate: newQuestionnaireInfos?.startDate
        ? new Date(newQuestionnaireInfos?.startDate?.setHours(12, 0, 0, 0))
        : new Date(new Date().setHours(12, 0, 0, 0)),
      tags: selectedTagsIds,
      ...methodInfos,
    }

    const formData = {
      userGroups: userGroups.map(({ id: groupId }: any) => ({
        id: groupId,
        tags: selectedTagsIds,
      })),
    }

    const { formRequestId, invitationToken, invitationCode, invite, formId } =
      await createUserQuestionnaires(body)

    if (userId) {
      await activateUser(userId)
    }

    if (!userId) {
      setNewQuestionnaireInfos((newQuestionnaireInfos: any) => ({
        ...newQuestionnaireInfos,
        invitationToken,
        invitationCode,
        invite,
        formId,
      }))
    }

    await configFormRequestEnvironments(formRequestId, {
      userGroups: formData.userGroups,
    })

    return {
      formRequestId,
      invitationToken,
      invitationCode,
      invite,
      formId,
    }
  }

  const isScheduled = useMemo(() => {
    return (
      isValid(newQuestionnaireInfos?.startDate) &&
      !isToday(newQuestionnaireInfos?.startDate)
    )
  }, [newQuestionnaireInfos?.startDate])

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      flexGrow={1}
    >
      <Title>Como vamos convidar {patientName} para</Title>
      <Title>responder ao questionário?</Title>
      <Stack my={6} spacing={4}>
        <ButtonClinic
          {...buttonCommonProps}
          onClick={() => handleOpenModal('whatsapp')}
          icon='whatsapp'
          iconPosition='start'
          iconSize={24}
        >
          Whatsapp
        </ButtonClinic>

        <ButtonClinic
          {...buttonCommonProps}
          onClick={() => handleOpenModal('email')}
          icon='email'
          iconPosition='start'
          iconSize={24}
        >
          E-mail
        </ButtonClinic>

        <Tooltip
          title={
            isScheduled ? (
              <>
                <TooltipText>
                  A opção de compartilhamento por link não
                </TooltipText>
                <TooltipText>
                  está disponível para envios programados
                </TooltipText>
              </>
            ) : (
              ''
            )
          }
          arrow
          placement='top'
        >
          <span>
            <ButtonClinic
              {...buttonCommonProps}
              onClick={() => handleOpenModal('link')}
              icon='link'
              iconPosition='start'
              iconSize={24}
              disabled={isScheduled}
            >
              Link
            </ButtonClinic>
          </span>
        </Tooltip>
      </Stack>
      <Stack>
        <SupportText>
          Utilize um dos canais acima para convidar{' '}
          <SupportText highlight>{patientName}</SupportText> ou
        </SupportText>
        <SupportText>
          <SupportText highlight>seu responsável</SupportText> para se cadastrar
          na plataforma.
        </SupportText>
      </Stack>

      {isModalOpen === 'whatsapp' && (
        <WhatsappModal
          isOpen={true}
          setIsOpen={setIsModalOpen}
          handleCreateQuestionnaire={handleCreateQuestionnaire}
        />
      )}

      {isModalOpen === 'email' && (
        <EmailModal
          isOpen={true}
          setIsOpen={setIsModalOpen}
          handleCreateQuestionnaire={handleCreateQuestionnaire}
        />
      )}

      {isModalOpen === 'link' && (
        <LinkModal
          isOpen={true}
          setIsOpen={setIsModalOpen}
          handleCreateQuestionnaire={handleCreateQuestionnaire}
        />
      )}
    </Box>
  )
}

export default SendStep
