import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { ButtonClinic } from 'components'
import { registerPageAccess } from 'helpers'
import { Title, WelcomeText } from './sign-up-clinic-steps-styles'

function WelcomeStep(): JSX.Element {
  const navigate = useNavigate()

  useEffect(() => {
    registerPageAccess('Cadastro clínico: onboarding')
  }, [])

  return (
    <Box
      flexGrow={1}
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent={{ xs: 'space-between', sm: 'flex-start'}}
    >
      <Box display='flex' flexDirection='column' alignItems='center'>
        <Box mt={{ lg: 9 }} mb={{ xs: 4, sm: 3}}>
          <Title>Boas vindas!</Title>
        </Box>
        <Box maxWidth='310px'>
          <WelcomeText>
            Você já pode acessar o Wida e convidar seus primeiros pacientes para
            fazer parte da inovação nos cuidados em saúde mental.
          </WelcomeText>
        </Box>
      </Box>
      <Box mt={{ sm: 11, lg: 15}}>
        <ButtonClinic
          width={150}
          variant='outlined'
          size='small'
          color='purple'
          fontWeight='bold'
          type='button'
          onClick={() => navigate('/')}
        >
          Começar
        </ButtonClinic>
      </Box>
    </Box>
  )
}

export default WelcomeStep
