export const formatPatientFromBack = (payload: any) => ({
  patientId: payload.patient.id,
  isTutor: payload.caregiver.accountInfo.isTutor,
  lastFormId: payload.formId,
  isActive: payload.isActive,
  isCompleted: payload.isCompleted,
  formRequestSendDate: payload.formRequestSendDate,
  formRequestSendStatus: payload.formRequestSendStatus,
  firstName: payload.caregiver.accountInfo.personalInfo.firstName,
  lastName: payload.caregiver.accountInfo.personalInfo.lastName,
  age: payload.caregiver.age,
  email: payload.caregiver.accountInfo.user.email,
  roles: payload.caregiver.accountInfo.user.roles,
  formRequestFrequency: payload.formRequestFrequency,
  responseDate: payload.responseDate,
  lastResponseDate: payload.lastResponseDate,
  user: payload.caregiver.accountInfo.user,
  formRequestId: payload.formRequestId,
  viewed: payload.viewed,
  createdAt: payload.createdAt,
  updatedAt: payload.updatedAt,
})

export const formatCaregiverFromBack = (payload: any) => ({
  patientId: payload.patient.id,
  isTutor: payload.caregiver.accountInfo.isTutor,
  lastFormId: payload.formId,
  isActive: payload.isActive,
  isCompleted: payload.isCompleted,
  formRequestSendDate: payload.formRequestSendDate,
  formRequestSendStatus: payload.formRequestSendStatus,
  firstName: payload.patient.accountInfo.personalInfo.firstName,
  lastName: payload.patient.accountInfo.personalInfo.lastName,
  age: payload.patient.age,
  email: payload.caregiver.accountInfo.user.email,
  caregiver: {
    firstName: payload.caregiver.accountInfo.personalInfo.firstName,
    lastName: payload.caregiver.accountInfo.personalInfo.lastName,
    relation: payload.caregiver.relation,
    userGroup: payload.caregiver.userGroup,
  },
  roles: payload.caregiver.accountInfo.user.roles,
  formRequestFrequency: payload.formRequestFrequency,
  responseDate: payload.responseDate,
  lastResponseDate: payload.lastResponseDate,
  user: payload.caregiver.accountInfo.user,
  formRequestId: payload.formRequestId,
  viewed: payload.viewed,
  createdAt: payload.createdAt,
  updatedAt: payload.updatedAt,
})
