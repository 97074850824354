import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Button, { ButtonProps } from '@mui/material/Button'
import { styled } from '@mui/material/styles'

export const Container = styled(Box)({
  background: '#FFFFFF',
  boxShadow: '0px 6px 34px rgba(115, 122, 182, 0.25)',
  borderRadius: '0px 0px 10px 10px',
  padding: '16px',
  height: '62px',
  minHeight: '62px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export const MenuButton = styled(Button)<ButtonProps & { selected: boolean }>(
  ({ selected }) => ({
    textTransform: 'none',
    color: '#353F48',
    fontFamily: 'QuickSand',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '18px',
    background: selected ? '#F9F9FB' : 'transparent',
  })
)

export const BetaLabel = styled(Typography)({
  fontFamily: 'Zen Kaku Gothic Antique',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '6px',
  lineHeight: '9px',
  textAlign: 'center',
  color: '#FFFFFF',
  background: 'rgba(173, 173, 173, 0.6)',
  borderRadius: '2px',
  width: '19px',
  height: '10px',
})

export const UserNameText = styled(Typography)({
  color: '#596B7B',
  textAlign: 'center',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Quicksand',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '20px',
})
