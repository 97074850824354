import { Typography, TableRow } from '@mui/material'
import { styled } from '@mui/material/styles'

export const DescriptionText = styled(Typography)({
  color: '#596B7B',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
})

export const PatientTableRow = styled(TableRow)({
  zIndex: '0',
  // cursor: 'pointer',
  transition: '0.4s',
  '&:hover': {
    opacity: '0.7',
    backgroundColor: '#F9F9FB',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  td: {
    fontFamily: "'Zen Kaku Gothic Antique', sans-serif",
    fontSize: '16px',
  },
})
