import { forwardRef, useMemo, useState, MouseEvent } from 'react'
import { Control, Controller } from 'react-hook-form'
import { IMaskInput } from 'react-imask'
import { InputAdornment, IconButton, Stack, Tooltip } from '@mui/material'
import { Visibility, VisibilityOff, InfoOutlined } from '@mui/icons-material'
import inputErrorIcon from 'components/icons/input-error-icon.svg'
import {
  FieldContainer,
  InputBase,
  HelperText,
  LabelText,
} from './text-field-clinic-styles'

interface ITextFieldClinic {
  name: string
  control: Control<any>
  type?: string
  disabled?: boolean
  invalidChars?: string[]
  placeholder?: string
  mask?: string
  label?: string
  fieldInfo?: any
  onBlur?: () => void
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
  mask: string
}

const TextMaskCustom = forwardRef(function TextMaskCustom(
  props: CustomProps,
  ref: any
) {
  const { onChange, mask, ...other } = props

  return (
    <IMaskInput
      {...other}
      mask={mask}
      inputRef={ref}
      onAccept={(value: any) =>
        onChange({ target: { name: props.name, value } })
      }
    />
  )
})

function TextFieldClinic({
  name,
  control,
  type,
  disabled,
  label,
  fieldInfo,
  invalidChars = [],
  placeholder = '',
  mask,
  onBlur: customBlur = () => {},
}: ITextFieldClinic) {
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const maskProps = mask && {
    inputProps: { mask },
    inputComponent: TextMaskCustom as any,
  }

  const isPassword = useMemo(() => type === 'password', [type])

  const inputType = useMemo(() => {
    if (!isPassword) return type
    return showPassword ? 'text' : 'password'
  }, [type, showPassword, isPassword])

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { invalid, error },
      }) => (
        <FieldContainer disabled={disabled} error={invalid} fullWidth>
          {label && <Label label={label} info={fieldInfo} />}
          <InputBase
            hasLabel={Boolean(label)}
            onChange={
              Boolean(invalidChars.length)
                ? (value: any) => {
                    const sanitizedValue = invalidChars.reduce(
                      (acc: string, invalidChar: string) =>
                        acc.replaceAll(invalidChar, ''),
                      value.target.value
                    )
                    return onChange(sanitizedValue)
                  }
                : onChange
            }
            onBlur={() => {
              onBlur()
              customBlur()
            }}
            value={value}
            color='secondary'
            type={inputType}
            placeholder={placeholder}
            endAdornment={
              isPassword ? (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showPassword ? (
                      <VisibilityOff sx={{ fill: '#C3C3C3' }} />
                    ) : (
                      <Visibility sx={{ fill: '#C3C3C3' }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ) : (
                error && (
                  <img
                    src={inputErrorIcon}
                    height='24px'
                    alt='Ícone de erro nos campos'
                  />
                )
              )
            }
            {...maskProps}
          />
          {invalid && (
            <HelperText>{error?.message || 'Erro desconhecido'}</HelperText>
          )}
        </FieldContainer>
      )}
    />
  )
}

function Label({ label, info }: any): JSX.Element {
  return (
    <Stack
      width='100%'
      mb='4px'
      direction='row'
      alignItems='center'
      justifyContent='flex-start'
      spacing={1}
    >
      <LabelText>{label}</LabelText>
      {info && (
        <Tooltip title={info} arrow placement='top'>
          <InfoOutlined
            sx={{ height: '18px', width: '18px', fill: '#657787' }}
          />
        </Tooltip>
      )}
    </Stack>
  )
}

export default TextFieldClinic
