import {
  Container,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material'
import { Close, ArrowBack } from '@mui/icons-material'
import { Button } from 'components'
import {
  Dialog,
  CancelButton,
  InformationTitle,
  InformationText,
} from './confirmation-modal-styles'

interface InformationModalProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  infos: {
    title?: string
    message?: string
    confirmText?: string
    cancelText?: string
  }
  variant?: 'standart' | 'secondary'
}

function ConfirmationModal({
  onClose,
  open,
  infos,
  onConfirm,
  variant = 'standart',
}: InformationModalProps) {
  const {
    title = 'Confirmar?',
    message = '',
    confirmText = 'Sim',
    cancelText = 'Não',
  } = infos

  if (variant === 'secondary') {
    return (
      <Dialog onClose={onClose} open={open}>
        <Container maxWidth='xs'>
          <IconButton
            aria-label='close'
            onClick={onClose}
            sx={{ color: '#657787', position: 'absolute', right: 2, top: 2 }}
          >
            <Close />
          </IconButton>
          <DialogTitle>
            <InformationTitle>{title}</InformationTitle>
          </DialogTitle>
          <DialogContent>
            <InformationText>{message}</InformationText>
          </DialogContent>
          <DialogActions>
            <CancelButton onClick={onClose}>
              <ArrowBack fontSize='small' sx={{ mx: 1 }} />
              {cancelText}
            </CancelButton>
            <Button color='secondary' onClick={onConfirm} sx={{ width: '50%' }}>
              {confirmText}
            </Button>
          </DialogActions>
        </Container>
      </Dialog>
    )
  }
  return (
    <Dialog onClose={onClose} open={open}>
      <Container maxWidth='xs'>
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{ color: '#657787', position: 'absolute', right: 2, top: 2 }}
        >
          <Close />
        </IconButton>
        <DialogTitle>
          <InformationTitle>{title}</InformationTitle>
        </DialogTitle>
        <DialogContent>
          <InformationText>{message}</InformationText>
        </DialogContent>
        <DialogActions>
          <Button variant='text' color='secondary' onClick={onClose}>
            {cancelText}
          </Button>
          <Button variant='text' color='secondary' onClick={onConfirm}>
            {confirmText}
          </Button>
        </DialogActions>
      </Container>
    </Dialog>
  )
}

export default ConfirmationModal
