import { Stack, Box, IconButton } from '@mui/material'
import Close from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import { RouteLayout } from 'components'
import {
  Title,
  Description,
} from './password-reset-success-patient-group-styles'

function PasswordResetSuccessPatientGroup(): JSX.Element {
  const navigate = useNavigate()

  return (
    <RouteLayout bg='gradient'>
      <Box mt={2} mr={2} display='flex' justifyContent='flex-end'>
        <IconButton
          onClick={() => navigate('/signin/patient')}
          sx={{ color: '#fff' }}
        >
          <Close />
        </IconButton>
      </Box>
      <Stack
        flexGrow={1}
        spacing={2}
        alignItems='center'
        justifyContent='center'
        px={10}
      >
        <Title>Verifique</Title>
        <Description>
          Se este CPF estiver registrado no Wida, uma mensagem com instruções
          será enviada ao e-mail ou WhatsApp associado à conta.
        </Description>
      </Stack>
    </RouteLayout>
  )
}

export default PasswordResetSuccessPatientGroup
