import { useEffect } from 'react'
import { Box, Stack } from '@mui/material'
import { SubmitHandler } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Button, RouteLayout, HeaderLoginPatientGroup } from 'components'
import { InputField } from 'components/inputs'
import { requestResetPassword } from 'services'
import { useNotification } from 'hooks'
import { registerPageAccess, sanitizeString } from 'helpers'
import { PATIENT } from 'helpers/constants'
import { IFormInput } from './password-reset-patient-group-types'
import useFormPasswordReset from './password-reset-patient-group-form'
import {
  LoginContainer,
  Title,
  InviteClinicText,
  Description,
  GradientLink,
} from './password-reset-patient-group-styles'

function PasswordResetPatientGroup(): JSX.Element {
  const navigate = useNavigate()
  const { control, handleSubmit, watch } = useFormPasswordReset()
  const { errorToast } = useNotification()

  const cpf = watch('cpf')

  useEffect(() => {
    registerPageAccess('Recuperacao de Senha')
  }, [])

  const onSubmit: SubmitHandler<IFormInput> = async (params) => {
    try {
      const body = {
        cpf: sanitizeString(params.cpf),
        type: PATIENT,
      }

      await requestResetPassword(body)

      navigate('/password_reset/patient/success')
    } catch (error: any) {
      console.error(error)
      errorToast(error.message)
    }
  }
  return (
    <RouteLayout bg='gradient'>
      <HeaderLoginPatientGroup />

      <LoginContainer component='form' onSubmit={handleSubmit(onSubmit)}>
        <Box mb={3}>
          <Title>Esqueci minha senha</Title>
        </Box>

        <Box mb={1} display='flex' justifyContent='flex-end'>
          <Description>
            Insira seu CPF para solicitar uma nova senha.
          </Description>
        </Box>
        <Box mb={4}>
          <InputField
            name='cpf'
            control={control}
            label='CPF'
            mask='000.000.000-00'
          />
        </Box>
        <Button disabled={!cpf} type='submit'>
          Solicitar
        </Button>
        <Stack mt={10} alignItems='center'>
          <InviteClinicText>Lembrou da senha?</InviteClinicText>
          <GradientLink onClick={() => navigate('/signin/patient')}>
            Login
          </GradientLink>
        </Stack>
      </LoginContainer>
    </RouteLayout>
  )
}

export default PasswordResetPatientGroup
