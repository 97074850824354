import { Typography } from '@mui/material'
import DialogMui from '@mui/material/Dialog'
import { styled } from '@mui/material/styles'

export const Dialog = styled(DialogMui)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '16px',
  },
  '& .MuiDialogTitle-root': {
    marginTop: theme.spacing(4),
  },
  '& .MuiDialogActions-root': {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export const InformationTitle = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '14px',
  lineHeight: '144%',
  color: '#657787',
  textAlign: 'center',
})

export const InformationText = styled(Typography)({
  fontWeight: 'normal',
  fontSize: '12px',
  lineHeight: '144%',
  color: '#657787',
  textAlign: 'center',
})
