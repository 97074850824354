const TermsOfUseIcon = ({ color = '#657787' }: { color?: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M4.58984 10.0399C4.58984 7.19286 4.58984 5.76894 5.47469 4.88485C6.35879 4 7.7827 4 10.6298 4H12.1398C14.9868 4 16.4107 4 17.2948 4.88485C18.1797 5.76894 18.1797 7.19286 18.1797 10.0399V13.0599C18.1797 15.907 18.1797 17.3309 17.2948 18.215C16.4107 19.0998 14.9868 19.0998 12.1398 19.0998H10.6298C7.7827 19.0998 6.35879 19.0998 5.47469 18.215C4.58984 17.3309 4.58984 15.907 4.58984 13.0599V10.0399Z'
        stroke={color}
        stroke-width='1.5'
      />
      <path
        d='M8.36523 10.0399H14.4052M8.36523 13.0599H12.1402'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
      />
    </svg>
  )
}

export default TermsOfUseIcon
