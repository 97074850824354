import { forwardRef, useMemo } from 'react'
import { Box, Stack } from '@mui/material'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import EmailIcon from '@mui/icons-material/EmailOutlined'
import LinkIcon from '@mui/icons-material/Link'
import { useAuthContext } from 'contexts'
import { useNotification } from 'hooks'
import {
  generateWhatsappPatientInviteInformantMessage,
  generateWhatsappCaregiverInviteInformantMessage,
  isUserCaregiver,
  formatUserName,
  registerTrack,
  isUserPatient,
} from 'helpers'
import {
  InviteText,
  MethodContainer,
  MethodIconContainer,
  MethodText,
} from './send-informant-invite-styles'

const SendInformantInvite = forwardRef(({ informantInvite }: any, ref) => {
  const { userInfos } = useAuthContext()
  const { successToast } = useNotification()

  const link = `${process.env.REACT_APP_WIDA_URL as string}/r/${
    informantInvite.code
  }`

  const whatsappMessage = useMemo(() => {
    if (isUserCaregiver(userInfos.user)) {
      return generateWhatsappCaregiverInviteInformantMessage(
        formatUserName(userInfos),
        userInfos.patient?.personalInfo?.firstName || 'paciente',
        informantInvite.name,
        link
      )
    } else {
      return generateWhatsappPatientInviteInformantMessage(
        formatUserName(userInfos),
        informantInvite.name,
        link
      )
    }
  }, [informantInvite.name, link, userInfos])

  const handleClickWhatsapp = () => {
    registerTrack('Clica Whatsapp', {
      user_id: userInfos.user.id,
      source: isUserPatient(userInfos.user) ? 'patient' : 'caregiver',
    })

    window.open(`https://api.whatsapp.com/send?text=${whatsappMessage}`)
  }

  const handleClickEmail = () => {
    registerTrack('Clica Email', {
      user_id: userInfos.user.userId,
      source: isUserPatient(userInfos.user) ? 'patient' : 'caregiver',
    })

    const subject = `${userInfos.personalInfos.firstName} está convidando você para a plataforma Wida`

    window.open(`mailto:?subject=${subject}&body=${whatsappMessage}`)
  }

  const handleClickLink = () => {
    registerTrack('Clica Link', {
      user_id: userInfos.user.userId,
      source: isUserPatient(userInfos.user) ? 'patient' : 'caregiver',
    })

    navigator.clipboard.writeText(whatsappMessage.replaceAll('%0a', '\n') || '')

    successToast('Link copiado! Compartilhe como desejar.')
  }

  return (
    <Box
      ref={ref}
      flexGrow={1}
      width='100%'
      maxHeight='270px'
      display='flex'
      flexDirection='column'
      alignItems='center'
      px={2}
      pt={5}
      sx={{
        backgroundColor: '#FEF3F2',
        borderRadius: '28px 28px 0px 0px',
      }}
    >
      <InviteText>
        Como deseja enviar o convite para {informantInvite.name.split(' ')[0]}?
      </InviteText>
      <Stack mt={4} width='100%' direction='row' justifyContent='space-evenly'>
        <MethodContainer spacing={2} onClick={handleClickWhatsapp}>
          <MethodIconContainer>
            <WhatsAppIcon sx={{ fill: '#F36F60' }} />
          </MethodIconContainer>
          <MethodText>WhatsApp</MethodText>
        </MethodContainer>

        <MethodContainer spacing={2} onClick={handleClickEmail}>
          <MethodIconContainer>
            <EmailIcon sx={{ fill: '#F36F60' }} />
          </MethodIconContainer>
          <MethodText>E-mail</MethodText>
        </MethodContainer>

        <MethodContainer spacing={2} onClick={handleClickLink}>
          <MethodIconContainer>
            <LinkIcon sx={{ fill: '#F36F60' }} />
          </MethodIconContainer>
          <MethodText>Link</MethodText>
        </MethodContainer>
      </Stack>
    </Box>
  )
})
export default SendInformantInvite
