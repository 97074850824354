import QuestionStep from './questions-step/questions-step'
import StartNewPatientStep from './start-new-patient-step/start-new-patient-step'
import PatientNameStep from './patient-name-step/patient-name-step'
import QuestionsPronounStep from './questions-pronoun-step/questions-pronoun-step'
import QuestionsPersonStep from './questions-person-step/questions-person-step'
import SendingTypeStep from './sending-type-step/sending-type-step'
import ScheduleStep from './schedule-step/schedule-step'
import SendStep from './send-step/send-step'
import SendSuccessStep from './send-success-step/send-success-step'

export const invitePatientSteps: any = [
  {
    label: 'Iniciar',
    component: <StartNewPatientStep />,
  },
  {
    label: 'Nome do paciente',
    component: <PatientNameStep />,
    hasBackButton: true,
  },
  {
    label: 'Gênero das perguntas',
    component: <QuestionsPronounStep />,
    hasBackButton: true,
  },
  {
    label: 'Pessoa das perguntas',
    component: <QuestionsPersonStep />,
    hasBackButton: true,
  },
  {
    label: 'Selecionar perguntas',
    component: <QuestionStep />,
    hasBackButton: true,
  },
  {
    label: 'Escolher programar envio',
    component: <SendingTypeStep />,
    hasBackButton: true,
  },
  {
    label: 'Programar envio',
    component: <ScheduleStep />,
    hasBackButton: true,
  },
  {
    label: 'Enviar',
    component: <SendStep />,
    hasBackButton: true,
  },
  {
    label: 'Sucesso do Envio',
    component: <SendSuccessStep />,
    hasBackButton: false,
  },
]

export const resendPatientSteps: any = [
  {
    label: 'Selecionar perguntas',
    component: <QuestionStep />,
    hasBackButton: true,
  },

  {
    label: 'Escolher programar envio',
    component: <SendingTypeStep />,
    hasBackButton: true,
  },
  {
    label: 'Programar envio',
    component: <ScheduleStep />,
    hasBackButton: true,
  },

  {
    label: 'Sucesso do Envio',
    component: <SendSuccessStep />,
    hasBackButton: false,
  },
]
