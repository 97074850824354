import { styled } from '@mui/material/styles'
import DividerMui from '@mui/material/Divider'
import { Box, Typography } from '@mui/material'

export const Divider = styled(DividerMui)({
  color: '#E8EBF2',
  borderBottomWidth: '2px',
})

export const TagName = styled(Typography)({
  fontFamily: 'Zen Kaku Gothic Antique',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
})

// * habilitado
export const TagContainer = styled(Box)<any>(({ selected }: any) => ({
  paddingLeft: '24px',
  paddingRight: '24px',
  cursor: 'pointer',
  backgroundColor: selected ? '#F9F9FB' : 'transparent',
}))

export const TagBox = styled(Box)<any>(({ checked }: any) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '8px 24px',
  backgroundColor: checked ? 'rgba(231, 232, 244, 0.80)' : '#F9F9FB',
  border: `1px solid ${checked ? '#8D92BE' : '#E3E3E3'}`,
  borderRadius: '50px',
  margin: '10px 15px',
  wordBreak: 'break-word',
}))

export const SupportText = styled(Typography)({
  color: '#949494',
  textAlign: 'center',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '21px',
})

export const TagsTitle = styled(Typography)({
  color: 'rgba(0, 0, 0, 0.87)',
  fontFamily: 'Quicksand',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '31px',
})

export const TooltipText = styled(Typography)({
  color: '#FFF',
  textAlign: 'center',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '14px',
})

export const EmptyText = styled(Typography)({
  color: '#949494',
  textAlign: 'center',
  fontFamily: 'Quicksand',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '36px',
})
