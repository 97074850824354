import React from 'react'
import { Text, View } from '@react-pdf/renderer'
import { styles } from '../tag-answers-styles'

interface auxiliaryTagTextsProps {
  tagName: string
  headerText: () => JSX.Element
  footerText: () => JSX.Element
}

export const auxiliaryTagTexts: auxiliaryTagTextsProps[] = [
  {
    tagName: 'ansiedade generalizada',
    headerText: () => {
      return (
        <Text style={styles.text}>
          A ansiedade generalizada é caracterizada por preocupações excessivas,
          persistentes, intensas e de difícil controle, sobre atividades da
          rotina diária, responsabilidades, trabalho, saúde, finanças,
          tragédias, entre outros. As preocupações costumam ser desproporcionais
          à probabilidade real ou ao impacto antecipado do evento.
        </Text>
      )
    },
    footerText: () => {
      return (
        <Text style={styles.text}>
          De acordo com o DSM-5-TR, para o diagnóstico de Transtorno de
          Ansiedade Generalizada, devem estar presentes necessariamente{' '}
          <Text style={styles.strong}>sintomas nucleares</Text> de ansiedade e
          preocupações excessivas; além disso, o indivíduo deve considerar
          difícil controlar a preocupação. Também devem estar presentes pelo
          menos três <Text style={styles.strong}>sintomas adicionais</Text> (um
          para crianças). As{' '}
          <Text style={styles.strong}>perguntas complementares</Text> constituem
          exemplos ou outros fenômenos comumente observados nestes quadros
          clínicos.
        </Text>
      )
    },
  },
  {
    tagName: 'ansiedade social',
    headerText: () => {
      return (
        <Text style={styles.text}>
          A ansiedade social é caracterizada por medo ou ansiedade intensa em
          situações nas quais o indivíduo acredita que possa ser avaliado ou
          julgado de forma negativa pelos outros. As situações sociais quase
          sempre provocam medo ou ansiedade, fazendo com que o indivíduo muitas
          vezes evite se envolver nessas atividades.
        </Text>
      )
    },
    footerText: () => {
      return (
        <Text style={styles.text}>
          De acordo com o DSM-5-TR, para o diagnóstico de Transtorno de
          Ansiedade Social, devem estar presentes medo, ansiedade ou esquiva
          acentuados em relação a uma ou mais situações sociais. Além disso,
          também deve haver temor de que sintomas de ansiedade demonstrados
          sejam avaliados negativamente. Em crianças, os sintomas devem ocorrer
          em contextos com pares e podem ser expressos através de choro, raiva
          ou retraimento.
        </Text>
      )
    },
  },
  {
    tagName: 'autismo',
    headerText: () => {
      return (
        <Text style={styles.text}>
          O espectro autista identifica indivíduos com dificuldades persistentes
          na comunicação e na interação social, que interferem na capacidade de
          iniciar ou manter uma conversa, fazer amigos e desenvolver
          relacionamentos. No espectro autista observam-se ainda padrões
          restritos e repetitivos de comportamento, interesses ou atividades,
          incluindo movimentos ou falas repetitivas, interesses fixos, apego a
          rotinas ou reações incomuns a estímulos sensoriais.
        </Text>
      )
    },
    footerText: () => {
      return (
        <Text style={styles.text}>
          De acordo com o DSM-5-TR, para o diagnóstico de Transtorno do Espectro
          Autista, deve haver evidência de déficits nas áreas de{' '}
          <Text style={styles.strong}>reciprocidade socioemocional</Text>,{' '}
          <Text style={styles.strong}>comunicação não-verbal</Text> e{' '}
          <Text style={styles.strong}>relacionamentos</Text>. Além disso, é
          necessária a presença de pelo menos dois dentre quatro tipos de{' '}
          <Text style={styles.strong}>
            padrões de comportamento e interesses restritos ou repetitivos{' '}
          </Text>
          (estereotipias, insistência no mesmo/inflexibilidade, hiperfocos e
          alterações sensoriais).
        </Text>
      )
    },
  },
  {
    tagName: 'depressão',
    headerText: () => {
      return (
        <Text style={styles.text}>
          A depressão é caracterizada por sentimentos intensos e persistentes de
          tristeza, desesperança, falta de interesse ou prazer nas atividades
          diárias. Retraimento social, irritabilidade, sentimentos de culpa ou
          inutilidade, alterações de sono ou apetite e/ou outras queixas
          somáticas também podem estar presentes.
        </Text>
      )
    },
    footerText: () => {
      return (
        <Text style={styles.text}>
          De acordo com o DSM-5-TR, para o diagnóstico de Episódio Depressivo
          Maior, deve estar presente ao menos um dos{' '}
          <Text style={styles.strong}>sintomas nucleares:</Text> humor deprimido
          (no caso de crianças e adolescentes, pode ser humor irritável) ou
          perda de interesse/prazer nas atividades. É necessário ainda que um
          total de cinco sintomas estejam presentes na soma entre{' '}
          <Text style={styles.strong}>sintomas nucleares</Text> e{' '}
          <Text style={styles.strong}>sintomas adicionais</Text>. As{' '}
          <Text style={styles.strong}>perguntas complementares </Text>
          constituem exemplos ou outros fenômenos comumente observados nestes
          quadros clínicos.
        </Text>
      )
    },
  },
  {
    tagName: 'desatenção e hiperatividade',
    headerText: () => {
      return (
        <View>
          <View>
            <Text style={styles.text}>
              A desatenção manifesta-se comportamentalmente como divagação,
              procrastinação, falta de foco, desorganização e baixa
              persistência.
            </Text>
          </View>
          <View style={{ ...styles.text, marginTop: 16 }}>
            <Text>
              A hiperatividade/impulsividade apresenta-se como uma atividade
              motora excessiva, em situações inapropriadas, fazendo com que o
              indivíduo tenha dificuldade em permanecer parado ou sentado,
              remexendo-se e falando em excesso, interrompendo outros ou se
              intrometendo.
            </Text>
          </View>
        </View>
      )
    },
    footerText: () => {
      return (
        <Text style={styles.text}>
          De acordo com o DSM-5-TR, para o diagnóstico de Transtorno de Déficit
          de Atenção/Hiperatividade, é necessária a presença de ao menos seis
          (ou cinco para adolescentes acima de 17 anos e adultos) sintomas em
          uma das dimensões de <Text style={styles.strong}>desatenção</Text>{' '}
          e/ou <Text style={styles.strong}>hiperatividade/impulsividade</Text>.
        </Text>
      )
    },
  },
  {
    tagName: 'mania',
    headerText: () => {
      return (
        <Text style={styles.text}>
          A mania é um intervalo de tempo caracterizado pela presença de humor
          elevado, expansivo ou irritável e por aumento persistente da atividade
          ou da energia, acompanhado por sintomas que representam uma mudança
          notável em relação ao comportamento habitual do indivíduo.
        </Text>
      )
    },
    footerText: () => {
      return (
        <Text style={styles.text}>
          De acordo com o DSM-5-TR, para o diagnóstico de Episódio Maníaco, é
          necessária a presença dos{' '}
          <Text style={styles.strong}>sintomas nucleares</Text> de alteração de
          humor (elevado, expansivo ou irritável) e de aumento do nível de
          energia ou atividade. Além disso, devem estar presentes ao menos três
          (quatro se o humor for apenas irritável){' '}
          <Text style={styles.strong}>sintomas adicionais</Text>. Enquanto que
          em um episódio maníaco os sintomas precisam ocorrer por pelo menos uma
          semana consecutiva, em um episódio hipomaníaco a duração mínima
          exigida é de quatro dias. As{' '}
          <Text style={styles.strong}>perguntas complementares</Text> constituem
          exemplos ou outros fenômenos comumente observados nestes quadros
          clínicos.
        </Text>
      )
    },
  },
  {
    tagName: 'obsessões e compulsões',
    headerText: () => {
      return (
        <View>
          <View>
            <Text style={styles.text}>
              Obsessões são pensamentos, impulsos ou imagens recorrentes e
              persistentes que são vivenciados como intrusivos e indesejados,
              causando intenso sofrimento ou ansiedade; além disso, o indivíduo
              tenta ignorá-los, suprimi-los ou neutralizá-los com algum
              pensamento ou ação.
            </Text>
          </View>
          <View style={{ marginTop: 16 }}>
            <Text style={styles.text}>
              Compulsões são comportamentos ou atos mentais repetitivos que o
              indivíduo se sente obrigado a fazer em resposta a uma obsessão ou
              seguindo regras muito estritas; tais comportamentos são realizados
              com o objetivo de mitigar a ansiedade ou o sofrimento, ou ainda
              para evitar uma situação temida, mesmo quando não há uma conexão
              realista com o que se busca prevenir ou quando são
              desproporcionais à situação.
            </Text>
          </View>
        </View>
      )
    },
    footerText: () => {
      return (
        <Text style={styles.text}>
          De acordo com o DSM-5-TR, para o diagnóstico de Transtorno
          Obsessivo-Compulsivo, é necessária a presença de{' '}
          <Text style={styles.strong}>obsessões</Text> e/ou{' '}
          <Text style={styles.strong}>compulsões</Text> que tomam tempo (p.ex.,
          mais de uma hora por dia) e impactam negativamente a vida do
          indivíduo.
        </Text>
      )
    },
  },
  {
    tagName: 'oposição e desafio',
    headerText: () => {
      return (
        <Text style={styles.text}>
          O comportamento opositor desafiante é caracterizado por um padrão
          frequente e persistente de humor irritável/ raivoso, atitudes
          questionadoras/desafiantes ou índole vingativa, fora de uma faixa
          esperada para o nível de desenvolvimento, gênero e cultura do
          indivíduo.
        </Text>
      )
    },
    footerText: () => {
      return (
        <Text style={styles.text}>
          De acordo com o DSM-5-TR, para o diagnóstico de Transtorno de Oposição
          Desafiante, é necessária a ocorrência frequente de um total de ao
          menos quatro sintomas de qualquer das categorias acima.
        </Text>
      )
    },
  },
]
