import { useState } from 'react'

import { Box, Stack } from '@mui/material'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'
import { registerTrack } from 'helpers'
import { SecondaryConfirmationModal, ButtonClinic } from 'components'
import { useInvitePatientContext } from 'contexts'
import { useNotification } from 'hooks'
import { SendMethodEnum } from 'types/invites'
import { HelpText, ExplanationText, DescriptionText } from './send-step-styles'

function LinkModal({ isOpen, setIsOpen, handleCreateQuestionnaire }: any) {
  const [expanded, setExpanded] = useState(false)
  const [sending, setSending] = useState(false)

  const { errorToast } = useNotification()
  const { goNextStep, newQuestionnaireInfos, setNewQuestionnaireInfos } =
    useInvitePatientContext()

  const onSubmit = async () => {
    try {
      setSending(true)
      if (!newQuestionnaireInfos?.formId) {
        await handleCreateQuestionnaire({ sendMethod: SendMethodEnum.LINK })
      }

      setNewQuestionnaireInfos((newQuestionnaireInfos: any) => ({
        ...newQuestionnaireInfos,
        sendingChannel: SendMethodEnum.LINK,
      }))

      goNextStep()
    } catch {
      errorToast(
        'Ocorreu um erro ao criar o questionário, por favor tente novamente'
      )

      registerTrack('Erro criação questionário', {
        channel: SendMethodEnum.LINK,
        type: 'first_time',
      })
    } finally {
      setSending(false)
    }
  }

  return (
    <SecondaryConfirmationModal
      isOpen={isOpen}
      title='Envio do convite por Link'
      hideButtons
      onClose={() => setIsOpen(false)}
      content={
        <Stack alignItems='center'>
          <Box width={480} my={3}>
            <DescriptionText>Um link será gerado e estará</DescriptionText>
            <DescriptionText>disponível na próxima página</DescriptionText>
          </Box>
          <Stack
            direction='row'
            alignItems='center'
            spacing={1}
            sx={{ cursor: 'pointer' }}
            onClick={() => setExpanded(!expanded)}
          >
            <HelpText>Para quem devo enviar este convite?</HelpText>

            {expanded ? (
              <ExpandLessRoundedIcon
                fontSize='medium'
                sx={{ fill: '#596B7B' }}
              />
            ) : (
              <ExpandMoreRoundedIcon
                fontSize='medium'
                sx={{ fill: '#596B7B' }}
              />
            )}
          </Stack>

          {expanded && (
            <Box width={447} mt={3}>
              <ExplanationText>
                Você deve enviar este convite para a pessoa que irá administrar
                a conta do Wida (paciente OU responsável). Apenas o
                administrador da conta poderá compartilhar o questionário com
                outras pessoas e ser notificado automaticamente quando existirem
                novos questionários.
              </ExplanationText>
            </Box>
          )}

          <Stack direction='row' spacing={4} mt={3} mb={3}>
            <ButtonClinic
              variant='outlined'
              onClick={() => setIsOpen(false)}
              width={140}
              color='purple'
              size='small'
              fontWeight='bold'
            >
              Cancelar
            </ButtonClinic>
            <ButtonClinic
              variant='contained'
              onClick={onSubmit}
              width={140}
              color='purple'
              size='small'
              fontWeight='bold'
              loading={sending}
            >
              {sending ? 'Gerando Link' : 'Gerar Link'}
            </ButtonClinic>
          </Stack>
        </Stack>
      }
    />
  )
}

export default LinkModal
