// TODO refactor to backend
export const subdomainsOrderByTag: any = {
  'ansiedade generalizada': {
    'Sintomas nucleares': 1,
    'Sintomas adicionais': 2,
    'Perguntas complementares': 3,
  },
  'ansiedade social': {
    'Lista de sintomas': 1,
  },
  autismo: {
    'Reciprocidade socioemocional': 1,
    'Comunicação não-verbal': 2,
    Relacionamentos: 3,
    'Padrões de comportamento e interesses restritos ou repetitivos': 4,
  },
  depressão: {
    'Sintomas nucleares': 1,
    'Sintomas adicionais': 2,
    'Perguntas complementares': 3,
  },
  'desatenção e hiperatividade': {
    Desatenção: 1,
    'Hiperatividade/impulsividade': 2,
  },
  mania: {
    'Sintomas nucleares': 1,
    'Sintomas adicionais': 2,
    'Perguntas complementares': 3,
  },
  'obsessões e compulsões': {
    Obsessões: 1,
    Compulsões: 2,
  },
  'oposição e desafio': {
    'Humor raivoso/irritável': 1,
    'Comportamento questionador/desafiante': 2,
    'Índole vingativa': 3,
  },
}
