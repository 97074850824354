import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

export const LogoAnimation = styled(Box)({
  background:
    'linear-gradient(310deg, #A9B1FA -6.47%, #8D92BE 85.17%, #8D92BE 131.44%)',
  backgroundSize: '500% 500%',
  animation: 'gradient 12s ease infinite',
  '@keyframes gradient': {
    '0%': {
      backgroundPosition: '0% 50%',
    },
    '50%': {
      backgroundPosition: '100% 50%',
    },
    '100%': {
      backgroundPosition: '0% 50%',
    },
  },
})
