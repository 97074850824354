import { Navigate } from 'react-router-dom'
import { useAuthContext } from 'contexts'
import { isUserClinic } from 'helpers'

function Home(): JSX.Element {
  const { user } = useAuthContext()

  return <Navigate to={isUserClinic(user) ? '/patients' : '/patient'} replace />
}

export default Home
