import client from 'services/client'

export const updateUserInfos = async (body: any) => {
  try {
    await client.patch('/users/my-infos', body)
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const sendContactMessage = async (body: any) => {
  try {
    await client.post('/users/contact-us', body)
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const archiveUser = async (id: string) => {
  try {
    const { data } = await client.patch(`/users/archive-user/${id}`)
    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const activateUser = async (id: string) => {
  try {
    const { data } = await client.patch(`/users/activate-user/${id}`)
    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}
