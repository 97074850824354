import { useEffect } from 'react'
import { Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { firstNameQuestion, lastNameQuestion } from './questions'
import { useSignUpPatientContext } from 'contexts'
import {
  birthdateQuestion as bQuestion,
  pronounQuestion as pQuestion,
} from './questions'
import { registerPageAccess } from 'helpers'
import { InputField, SelectField } from 'components/inputs'
import { Title } from './sign-up-patient-steps-styles'

function PersonalInfoStep(): JSX.Element {
  const { control, watch } = useFormContext()
  const { patient, patientName, type } = useSignUpPatientContext()

  const firstName = watch('firstName')

  const birthdateQuestion = bQuestion[patient || 'he'] as any
  const pronounQuestion = pQuestion[patient || 'he'] as any

  useEffect(() => {
    registerPageAccess('Informação pessoal', {
      patient_name: patientName,
      source: type,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Box mb={2}>
        <Title>Ok! Você ainda não possui conta conosco.</Title>
      </Box>
      <Box mb={5}>
        <Title>Insira algumas informações para seu cadastro.</Title>
      </Box>

      <Box mt={2} mb={3}>
        <InputField
          name='firstName'
          control={control}
          label={firstNameQuestion[patient || 'he']}
        />
      </Box>
      <Box mb={3}>
        <InputField
          name='lastName'
          control={control}
          label={lastNameQuestion[patient || 'he']}
        />
      </Box>
      {firstName && (
        <>
          <Box mb={3}>
            <Box>
              <InputField
                mask='00/00/0000'
                name='birthdate'
                control={control}
                label={birthdateQuestion}
              />
            </Box>
          </Box>
          <Box mt={2} mb={3}>
            <SelectField
              label={pronounQuestion}
              name='pronoun'
              control={control}
              options={[
                { value: 'he', label: 'Ele' },
                { value: 'she', label: 'Ela' },
              ]}
            />
          </Box>
        </>
      )}
    </>
  )
}

export default PersonalInfoStep
