import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { prepareQuestionsToGraphic, extractTagsFromQuestions } from 'helpers'
import {
  ABSOLUTE_PRESENT_THRESHOLD,
  PROPORTION_PRESENT_THRESHOLD,
  RATIO_PRESENT_THRESHOLD,
} from 'helpers/constants'

export const formatDateToGraphic = (date: any) =>
  format(new Date(date), 'dd MMM', { locale: ptBR })

export const extractDatesFromResponses = (responses: any) => {
  const dates = responses
    .slice()
    .sort(
      (respA: any, respB: any) =>
        (new Date(respB.formDateUpdate) as any) -
        (new Date(respA.formDateUpdate) as any)
    )
    .map((response: any) => formatDateToGraphic(response.formDateUpdate))

  return Array.from(new Set(dates))
}

export const filterFormsByDates = (forms: any, dates: string[]) => {
  return forms.filter((form: any) =>
    dates.includes(formatDateToGraphic(form.formDateUpdate))
  )
}

const generateTagCounter = (answers: any) => {
  return answers.reduce((acc: any, answer: any) => {
    return { ...acc, [answer.tag]: (acc[answer.tag] || 0) + 1 }
  }, {})
}

const generateOrderedTagsByRelevance = (answers: any) => {
  return Object.entries(generateTagCounter(answers)).sort(
    ([, aQtd]: any, [, bQtd]: any) => bQtd - aQtd
  )
}

const generateFinalRelevantTagsArray = (relevantTags: any) => {
  const limit = 3
  const groupSameQuantity = relevantTags.reduce(
    (acc: any, [tagName, qtd]: any) => ({
      ...acc,
      [qtd]: [...(acc[qtd] || []), tagName],
    }),
    {}
  )

  return Object.entries(groupSameQuantity)
    .sort(([qtdA]: any, [qtdB]: any) => qtdB - qtdA)
    .map(([, group]: any) => group)
    .reduce((acc: any, group: any) => {
      return acc.length < limit ? [...acc, ...group] : acc
    }, [])
}

const generateFinalProportionArray = (proportionArray: any) => {
  const limit = 3

  const groupSameProportion = proportionArray.reduce(
    (acc: any, tagProportion: any) => ({
      ...acc,
      [tagProportion.proportion]: [
        ...(acc[tagProportion.proportion] || []),
        tagProportion,
      ],
    }),
    {}
  )

  return Object.entries(groupSameProportion)
    .sort(([propA]: any, [propB]: any) => propB - propA)
    .map(([, group]: any) => group)
    .reduce((acc: any, group: any) => {
      return acc.length < limit ? [...acc, ...group] : acc
    }, [])
}

export const generateTransversalInfos = (
  infos: any,
  responder: any,
  patientName: string
) => {
  try {
    const distinctQuestions = prepareQuestionsToGraphic(infos.questions)

    const presents = distinctQuestions.filter(
      ({ answer }: any) => answer === 'yes'
    )

    const orderedPresentTags = generateOrderedTagsByRelevance(presents)
    const tagCounter = generateTagCounter(distinctQuestions)

    const cutOffPresentTags = orderedPresentTags.filter(
      ([, qtd]: any) => qtd >= ABSOLUTE_PRESENT_THRESHOLD
    )

    const cutOffProportionTags = orderedPresentTags.filter(
      ([, qtd]: any) => qtd >= PROPORTION_PRESENT_THRESHOLD
    )

    const presentProportion = cutOffProportionTags.map(
      ([tagName, qtd]: any) => {
        return {
          name: tagName,
          label: `${tagName} (${qtd}/${tagCounter[tagName]})`,
          proportion: Math.ceil((qtd / tagCounter[tagName]) * 100),
        }
      }
    )

    const orderedPresentProportion = presentProportion
      .filter(({ proportion }: any) => proportion + RATIO_PRESENT_THRESHOLD)
      .sort((a: any, b: any) => b.proportion - a.proportion)

    return {
      answerDate: format(new Date(infos.formDateUpdate), 'dd/MM/yyyy'),
      totalQuestions: infos.questions.length,
      isResponderPatient: !responder.relation && responder.isPatient,
      patientName,
      responderName:
        !responder.relation && responder.isPatient
          ? patientName
          : responder.label.split(' ')[0],
      presents: {
        total: presents.length,
        relevantTags: generateFinalRelevantTagsArray(cutOffPresentTags),
        hasMoreTags:
          cutOffPresentTags.length &&
          (orderedPresentTags.length !== cutOffPresentTags.length ||
            orderedPresentTags.length > 3),
        proportion: generateFinalProportionArray(orderedPresentProportion),
      },
    }
  } catch (error) {
    console.error(error)
    return {}
  }
}

export const generateLongitudinalInfos = (
  infos: any,
  responder: any,
  patientName: string
) => {
  try {
    if (!infos || infos?.length < 2) throw new Error('Informações inválidas')

    const firstForm = infos[0]
    const lastForm = infos[infos.length - 1]

    const distinctQuestionsFirstForm = prepareQuestionsToGraphic(
      firstForm.questions
    )

    const distinctQuestionsLastForm = prepareQuestionsToGraphic(
      lastForm.questions
    )

    const questionsFirstFormOnlyIntersection = filterQuestionsByIntersection(
      distinctQuestionsFirstForm,
      distinctQuestionsLastForm
    )

    const questionsLastFormOnlyIntersection = filterQuestionsByIntersection(
      distinctQuestionsLastForm,
      distinctQuestionsFirstForm
    )

    const tagDictFirstForm = generateQuestionsByTagDict(
      questionsFirstFormOnlyIntersection
    )

    const tagDictLastForm = generateQuestionsByTagDict(
      questionsLastFormOnlyIntersection
    )

    const presents = Object.entries(tagDictFirstForm).reduce(
      (acc: any, [tagName, questions]: any) => {
        if (!tagDictLastForm[tagName]) return acc

        const presentItemsFirst = filterQuestionsByAnswer(questions, 'yes')
        const presentItemsLast = filterQuestionsByAnswer(
          tagDictLastForm[tagName],
          'yes'
        )

        if (presentItemsFirst.length > presentItemsLast.length)
          return {
            ...acc,
            decrease: [
              ...acc.decrease,
              {
                tagName,
                qtdLabel: `de ${presentItemsFirst.length} para ${presentItemsLast.length}`,
              },
            ],
          }

        if (presentItemsFirst.length < presentItemsLast.length)
          return {
            ...acc,
            increase: [
              ...acc.increase,
              {
                tagName,
                qtdLabel: `de ${presentItemsFirst.length} para ${presentItemsLast.length}`,
              },
            ],
          }

        return { ...acc, noChange: [...acc.noChange, { tagName }] }
      },
      { decrease: [], increase: [], noChange: [] }
    )

    const presentsTags: any = Object.values(presents).reduce(
      (acc: any, tags: any) => [...acc, ...tags.map((tag: any) => tag.tagName)],
      []
    )

    const allTags: any = extractTagsFromQuestions([
      ...distinctQuestionsFirstForm,
      ...distinctQuestionsLastForm,
    ]).map((tag: any) => tag.value)

    const absentTags: any = allTags.filter(
      (tag: any) => !presentsTags.includes(tag)
    )

    return {
      answerDateFirst: format(new Date(firstForm.formDateUpdate), 'dd/MM/yyyy'),
      answerDateLast: format(new Date(lastForm.formDateUpdate), 'dd/MM/yyyy'),
      totalForms: infos.length,
      isResponderPatient: !responder.relation && responder.isPatient,
      patientName,
      responderName:
        !responder.relation && responder.isPatient
          ? patientName
          : responder.label.split(' ')[0],
      presents,
      absents: absentTags,
    }
  } catch (error) {
    console.error(error)
    return {}
  }
}

const generateQuestionsByTagDict = (questions: any) => {
  return questions.reduce(
    (acc: any, question: any) => ({
      ...acc,
      [question.tag]: [...(acc[question.tag] || []), question],
    }),
    {}
  )
}

const filterQuestionsByAnswer = (questions: any, answer: string) => {
  return questions.filter(
    ({ answer: questionAnswer }: any) => questionAnswer === answer
  )
}

const filterQuestionsByIntersection = (
  questionsToFilter: any,
  questionsToCompare: any
) => {
  return questionsToFilter.filter((questionToFilter: any) =>
    questionsToCompare.find(
      (questionToCompare: any) => questionToCompare.id === questionToFilter.id
    )
  )
}

const concatQuestionsShortVersion = (questions: any) =>
  questions
    .map(({ ultraShortVersion }: any) => ultraShortVersion.toLowerCase())
    .join(', ')

export const generateDescriptionInfos = (form: any, patientName: string) => {
  try {
    const orderedTags = form.tags.sort((a: any, b: any) =>
      a.tag.localeCompare(b.tag)
    )

    const tagsInfos = orderedTags.map(({ tag, id }: any) => {
      const questionsByTag = form.questions.filter((question: any) =>
        question.tags.includes(tag)
      )

      const present = questionsByTag.filter(
        ({ answer }: any) => answer === 'yes'
      )

      const partial = questionsByTag.filter(
        ({ answer }: any) => answer === 'soso'
      )
      const absent = questionsByTag.filter(({ answer }: any) => answer === 'no')

      return {
        tag,
        id,
        total: questionsByTag.length,
        present: {
          total: present.length,
          text: concatQuestionsShortVersion(present),
        },
        partial: {
          total: partial.length,
          text: concatQuestionsShortVersion(partial),
        },
        absent: {
          total: absent.length,
          text: concatQuestionsShortVersion(absent),
        },
      }
    })

    return {
      answerDate: format(new Date(form.formDateUpdate), 'dd/MM/yyyy'),
      tags: tagsInfos,
      totalQuestions: form.questions.length,
      responder: {
        isPatient: !form.relation && form.isPatient,
        relation: form.relation,
        name:
          !form.relation && form.isPatient
            ? patientName
            : form.label.split(' ')[0],
      },
      patient: { name: patientName },
    }
  } catch (error) {
    console.error(error)
    return {}
  }
}

// a little function to help us with reordering the result
export const reorder = <T>(
  list: T[],
  startIndex: number,
  endIndex: number
): T[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}
