import client from 'services/client'

export const inactivateFormRequestFrequency = async (id: string) => {
  try {
    await client.patch(`/form-request-frequencies/${id}/inactivate`)
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const updateFormRequestFrequency = async (
  id: string,
  frequency: string,
  lastFormId: string,
  patientId: string,
  startDate: Date
) => {
  try {
    await client.patch(`/form-request-frequencies/${id}`, {
      frequency,
      formId: lastFormId,
      patientId,
      startDate,
    })
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}
