import Box from '@mui/material/Box'
import { IPatientReportIcon } from './patient-report-icon-types'

function NotAskedIcon({
  size = 10,
  color = '#000000',
}: IPatientReportIcon): JSX.Element {
  return (
    <Box
      sx={{
        width: size,
        height: size,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box sx={{ height: '1px', width: size, background: color }} />
    </Box>
  )
}

export default NotAskedIcon
