import { useForm } from 'react-hook-form'
import { editSecurityResolver } from 'resolvers'

export default function useSettingsSecurityForm() {
  return useForm({
    resolver: editSecurityResolver,
    defaultValues: {
      currentPassword: '',
      password: '',
      confirmPassword: '',
    },
  })
}
