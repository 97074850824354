import { useState } from 'react'
import { Divider, Button, CircularProgress } from '@mui/material'
import { Editor, EditorState, convertFromRaw } from 'draft-js'
import '../rich-text.css'
import '../../../../../node_modules/draft-js/dist/Draft.css'
import { getRecordFile } from 'services'

function RichTextDisplay({ record }: any): JSX.Element {
  const [loadingFileId, setLoadingFileId] = useState<any>()
  const { richText, files, id } = record
  const editorState: any = richText
    ? EditorState.createWithContent(convertFromRaw(richText))
    : ''

  function getBlockStyle(block: any) {
    return block.getType()
  }

  const handleOpenFile = async (file: any) => {
    try {
      setLoadingFileId(file.id)
      const downloadFile = await getRecordFile(file.id, id)

      // create link to download file and remove it
      const href = URL.createObjectURL(downloadFile)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', file.name)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    } catch (error: any) {
      console.log(error)
    } finally {
      setLoadingFileId(null)
    }
  }

  return (
    <div className='RichEditor-display'>
      {richText && (
        <Editor
          readOnly
          blockStyleFn={getBlockStyle}
          editorState={editorState}
          onChange={() => {}}
        />
      )}
      {!!files?.length && (
        <>
          <Divider sx={{ margin: '10px 0' }} />
          {files.map((file: any) => (
            <Button
              key={file.id}
              onClick={() => handleOpenFile(file)}
              sx={{ textTransform: 'none', color: '#999' }}
            >
              {loadingFileId === file.id ? (
                <CircularProgress
                  size={'10px'}
                  sx={{ color: '#01B3A7', marginRight: '10px' }}
                />
              ) : (
                ''
              )}
              {file.name}
            </Button>
          ))}
        </>
      )}
    </div>
  )
}

export default RichTextDisplay
