import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import EmailIcon from '@mui/icons-material/EmailOutlined'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import SendIcon from '@mui/icons-material/Send'
import LinkIcon from 'components/icons/link-icon'
import EditIcon from 'components/icons/edit-icon'
import ReportIcon from 'components/icons/report-icon'

export const icons: any = {
  forwardArrow: (props: any) => <ArrowForward {...props} />,
  whatsapp: (props: any) => <Whatsapp {...props} />,
  email: (props: any) => <Email {...props} />,
  link: (props: any) => <Link {...props} />,
  edit: (props: any) => <Edit {...props} />,
  addPerson: (props: any) => <AddPerson {...props} />,
  send: (props: any) => <Send {...props} />,
  report: (props: any) => <Report {...props} />,
}

const ArrowForward = ({ size = 18, placement = 'end' }) => (
  <ArrowForwardIosRoundedIcon
    sx={{
      height: size,
      width: size,
      ...(placement === 'end' ? { marginLeft: '8px' } : { marginRight: '8px' }),
    }}
  />
)

const Whatsapp = ({ size = 18, placement = 'end' }) => (
  <WhatsAppIcon
    sx={{
      height: size,
      width: size,
      ...(placement === 'end' ? { marginLeft: '8px' } : { marginRight: '8px' }),
    }}
  />
)

const Email = ({ size = 18, placement = 'end' }) => (
  <EmailIcon
    sx={{
      height: size,
      width: size,
      ...(placement === 'end' ? { marginLeft: '8px' } : { marginRight: '8px' }),
    }}
  />
)

const Link = ({ placement = 'end' }) => (
  <LinkIcon
    style={{
      ...(placement === 'end' ? { marginLeft: '8px' } : { marginRight: '8px' }),
    }}
  />
)

const Edit = ({ placement = 'end' }) => (
  <EditIcon
    style={{
      ...(placement === 'end' ? { marginLeft: '8px' } : { marginRight: '8px' }),
    }}
  />
)

const AddPerson = ({ size = 18, placement = 'end' }) => (
  <PersonAddAlt1Icon
    sx={{
      height: size,
      width: size,
      ...(placement === 'end' ? { marginLeft: '8px' } : { marginRight: '8px' }),
    }}
  />
)
const Send = ({ size = 18, placement = 'end' }) => (
  <SendIcon
    sx={{
      height: size,
      width: size,
      ...(placement === 'end' ? { marginLeft: '8px' } : { marginRight: '8px' }),
    }}
  />
)

const Report = ({ placement = 'end' }) => (
  <ReportIcon
    style={{
      ...(placement === 'end' ? { marginLeft: '8px' } : { marginRight: '8px' }),
    }}
  />
)
