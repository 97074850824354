interface TrashIconProps {
  color?: string
  margin?: string
}

const TrashIcon = ({ color = '#657787', margin }: TrashIconProps) => {
  return (
    <svg
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ margin }}
    >
      <g clip-path='url(#clip0_1019_3955)'>
        <path
          d='M3.33251 5.5V17.7222C3.32734 17.936 3.36434 18.1487 3.44141 18.3482C3.51847 18.5476 3.63408 18.73 3.78163 18.8848C3.92919 19.0395 4.10579 19.1637 4.30136 19.2502C4.49692 19.3367 4.70761 19.3838 4.9214 19.3889H15.0492C15.2653 19.3875 15.479 19.343 15.6778 19.2581C15.8766 19.1733 16.0564 19.0496 16.2069 18.8945C16.3574 18.7394 16.4755 18.5558 16.5543 18.3545C16.6331 18.1533 16.671 17.9383 16.6658 17.7222V5.5H3.33251ZM8.33251 16.6111H7.2214V8.27778H8.33251V16.6111ZM12.777 16.6111H11.6658V8.27778H12.777V16.6111Z'
          fill={color}
        />
        <path
          d='M17.0727 3.27751H12.7782V2.72195C12.7782 2.42727 12.6611 2.14465 12.4528 1.93628C12.2444 1.7279 11.9618 1.61084 11.6671 1.61084H8.22266C7.94763 1.63848 7.69278 1.76763 7.50787 1.97309C7.32296 2.17854 7.22127 2.44554 7.22266 2.72195V3.27751H2.77821C2.63087 3.27751 2.48956 3.33604 2.38537 3.44023C2.28119 3.54441 2.22266 3.68572 2.22266 3.83306C2.22266 3.9804 2.28119 4.12171 2.38537 4.2259C2.48956 4.33009 2.63087 4.38862 2.77821 4.38862H17.0727C17.22 4.38862 17.3613 4.33009 17.4655 4.2259C17.5697 4.12171 17.6282 3.9804 17.6282 3.83306C17.6282 3.68572 17.5697 3.54441 17.4655 3.44023C17.3613 3.33604 17.22 3.27751 17.0727 3.27751Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_1019_3955'>
          <rect
            width='20'
            height='20'
            fill='white'
            transform='translate(0 0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default TrashIcon
