import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import EmailIcon from '@mui/icons-material/EmailOutlined'
import LinkIcon from '@mui/icons-material/Link'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

interface ISendMethodProps {
  type?: 'whatsapp' | 'email' | 'link'
  label?: any
  handleClick?: () => void
  active?: boolean
  disabled?: boolean
}

const methods: any = {
  whatsapp: { icon: <WhatsAppIcon />, label: 'WhatsApp' },
  email: { icon: <EmailIcon />, label: 'E-mail' },
  link: {
    icon: <LinkIcon sx={{ transform: 'rotate(135deg)' }} />,
    label: 'Link',
  },
}

function SendMethod({
  type = 'link',
  label,
  handleClick = () => {},
  active = false,
  disabled = false,
}: ISendMethodProps) {
  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <SendMethodButton
        size='large'
        onClick={handleClick}
        active={Number(active)}
        disabled={disabled}
      >
        <Box>
          {methods[type].icon}
          <Typography
            sx={{
              fontFamily: 'Zen Kaku Gothic Antique',
              fontSize: '16px',
              color: '#353F48',
            }}
          >
            {label || methods[type].label}
          </Typography>
        </Box>
      </SendMethodButton>
    </Box>
  )
}

const SendMethodButton: any = styled(IconButton)(
  ({ active, disabled }: any) => ({
    width: '140px',
    height: '115px',
    borderRadius: '5px',
    backgroundColor: '#F9F9FB',
    border: active ? '1.5px solid #07B2A5' : 'none',
    color: '#07B2A5',
    ':hover': {
      opacity: '0.7',
    },
    svg: {
      height: '2em',
      width: '2em',
    },
  })
)

export default SendMethod
