import { Fragment } from 'react'
import { useFormContext } from 'react-hook-form'
import { ClickAwayListener } from '@mui/material'
import { useResultsContext } from 'contexts'
import {
  OPACITY,
  CONTAINER_P_X,
  CONTAINER_P_Y,
  GRAPHS_DISTANCE,
  GRAPH_WIDTH,
  GRAPHIC_HEIGHT,
  QUESTION_HEIGHT,
  dictColorAnswers,
} from 'helpers/constants'
import {
  generatePositions,
  updatePositions,
  calculateMarginTop,
  calculateQuestionHeight,
} from 'helpers'

function Sankey({ responses, maxTagQuestions }: any) {
  const { setFilterQuestionIds, sankeyHighlight, setSankeyHighlight } =
    useResultsContext()

  const { watch } = useFormContext()

  const activeProportion = watch('activeProportion')

  const questionHeight = activeProportion
    ? calculateQuestionHeight(GRAPHIC_HEIGHT, maxTagQuestions)
    : QUESTION_HEIGHT

  const handleCleanFilterQuestions = () => {
    setSankeyHighlight(null)
    setFilterQuestionIds([])
  }

  return (
    <svg
      width='100%'
      height={GRAPHIC_HEIGHT}
      style={{
        position: 'absolute',
        top: '0',
        left: '0',
      }}
    >
      {responses.map(
        ({ origin, destination, sankeys, active }: any, index: number) => {
          if (!active) {
            return (
              <Fragment
                key={`sankey-group-${origin.formRequestId}:${origin.tag.id}-${destination.formRequestId}:${destination.tag.id}`}
              />
            )
          }

          const xo = CONTAINER_P_X + GRAPH_WIDTH + GRAPHS_DISTANCE * index
          const xd = CONTAINER_P_X + GRAPHS_DISTANCE * (index + 1)
          const diffx = (xd - xo) / 3

          const marginTopOrigin = calculateMarginTop(
            origin.quantities,
            questionHeight
          )
          const marginTopDestination = calculateMarginTop(
            destination.quantities,
            questionHeight
          )

          let originPositions: any = generatePositions(origin.quantities)
          let destinationPositions: any = generatePositions(
            destination.quantities
          )

          return (
            <ClickAwayListener
              onClickAway={handleCleanFilterQuestions}
              key={`sankey-group-${origin.formRequestId}:${origin.tag.id}-${destination.formRequestId}:${destination.tag.id}`}
            >
              <g
                key={`sankey-group-${origin.formRequestId}:${origin.tag.id}-${destination.formRequestId}:${destination.tag.id}`}
              >
                {sankeys.map((sankeyResponse: any) => {
                  const { answerOrigin, answerDestination, qtd, variation } =
                    sankeyResponse

                  const sankeyId = `sankey-${origin.formRequestId}:${origin.tag.id}-${destination.formRequestId}:${destination.tag.id}-${variation}`

                  if (answerOrigin === 'skip' || answerDestination === 'skip') {
                    return <Fragment key={sankeyId} />
                  }

                  let yo = Math.ceil(
                    CONTAINER_P_Y +
                      marginTopOrigin +
                      (questionHeight / 2) * qtd +
                      originPositions[answerOrigin].current * questionHeight
                  )
                  let yd = Math.ceil(
                    CONTAINER_P_Y +
                      marginTopDestination +
                      (questionHeight / 2) * qtd +
                      destinationPositions[answerDestination].current *
                        questionHeight
                  )

                  originPositions = updatePositions(
                    originPositions,
                    answerOrigin,
                    qtd
                  )

                  destinationPositions = updatePositions(
                    destinationPositions,
                    answerDestination,
                    qtd
                  )

                  return (
                    <g
                      key={sankeyId}
                      className='links'
                      fill='none'
                      strokeOpacity={sankeyHighlight === sankeyId ? 1 : OPACITY}
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        e.stopPropagation()
                        setSankeyHighlight(sankeyId)
                        setFilterQuestionIds(sankeyResponse.questionIds)
                      }}
                    >
                      <g className='link' style={{ mixBlendMode: 'multiply' }}>
                        <linearGradient
                          id={sankeyId}
                          gradientUnits='userSpaceOnUse'
                          x1={xo}
                          x2={xd}
                        >
                          <stop
                            offset='0%'
                            stopColor={dictColorAnswers[answerOrigin]}
                          />

                          <stop
                            offset='100%'
                            stopColor={dictColorAnswers[answerDestination]}
                          />
                        </linearGradient>
                        <path
                          d={
                            `M` +
                            ` ${xo} ${yo} ` +
                            `C` +
                            ` ${xo + diffx} ${yo},` +
                            ` ${xd - diffx} ${yd},` +
                            ` ${xd} ${yd}`
                          }
                          stroke={`url(#${sankeyId})`}
                          strokeWidth={questionHeight * qtd}
                        />
                      </g>
                    </g>
                  )
                })}
              </g>
            </ClickAwayListener>
          )
        }
      )}
    </svg>
  )
}

export default Sankey
