import {
  Avatar,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material'
import CaregiverTooltip from 'components/caregiver-tooltip/caregiver-tooltip'
import FilterTableHeader from 'components/filter-table-header/filter-table-header'
import { format } from 'date-fns'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ArchivedPatientTableRow } from './archived-patient-list-styles'
import brLocale from 'date-fns/locale/pt-BR'
import PatientsTableActions from 'components/patients-table-actions/patients-table-actions'

interface ArchivedPatientListProps {
  archivedPatients: any
  onFilter: (value: string) => void
  fetchPatients: () => void
}

type Order = 'asc' | 'desc'

interface Data {
  firstName: string
  age: string
  lastResponseDate: string
  frequencyName: string
}

interface HeadCell {
  id: string
  label: string
  order?: boolean
}

const headerFields: HeadCell[] = [
  { id: 'firstName', label: 'Nome', order: true },
  { id: 'age', label: 'Idade', order: true },
  { id: 'lastResponseDate', label: 'Últimos resultados', order: true },
  { id: 'archivedAt', label: 'Arquivado em', order: true },
  { id: 'actions', label: 'Ações' },
]

function descendingComparator(a: any, b: any, orderBy: any) {
  if ('age' === orderBy) {
    // for numbers
    return Number(a[orderBy]) - Number(b[orderBy])
  } else if ('archivedAt' === orderBy) {
    // for archivedAt Date
    const aDate = new Date(a.user.archivedAt || 0)
    const bDate = new Date(b.user.archivedAt || 0)
    if (bDate < aDate) return -1
    if (bDate > aDate) return 1
    return 0
  } else if ('lastResponseDate' === orderBy) {
    // for dates
    const aDate = new Date(a[orderBy] || 0)
    const bDate = new Date(b[orderBy] || 0)
    if (bDate < aDate) return -1
    if (bDate > aDate) return 1
    return 0
  } else {
    // for strings
    if (b[orderBy] < a[orderBy]) return -1
    if (b[orderBy] > a[orderBy]) return 1
    return 0
  }
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function ArchivedPatientList({
  archivedPatients,
  onFilter,
  fetchPatients,
}: ArchivedPatientListProps) {
  const [orderBy, setOrderBy] = useState<keyof Data>('firstName')
  const [order, setOrder] = useState<Order>('asc')

  const navigate = useNavigate()

  const handleShowProfile = async (patient: any) => {
    if (Boolean(patient.lastResponseDate)) {
      navigate(`/patients/${patient.patientId}/results`)
      return
    }

    navigate(`/patients/${patient.patientId}/profile`)
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      alignItems='center'
      flex={1}
    >
      <TableContainer
        component={Paper}
        sx={{ marginTop: '16px', boxShadow: 'none' }}
      >
        <Table aria-label='tabela de pacientes arquivados'>
          <FilterTableHeader
            headerFields={headerFields}
            onFilter={onFilter}
            orderBy={orderBy}
            order={order}
            setOrderBy={setOrderBy}
            setOrder={setOrder}
          />
          <TableBody>
            <TableRow>
              <TableCell colSpan={6} sx={{ border: 'none', height: '40px' }} />
            </TableRow>
            {archivedPatients
              .sort(getComparator(order, orderBy))
              .map((archivedPatient: any) => {
                return (
                  <ArchivedPatientTableRow
                    onClick={() => handleShowProfile(archivedPatient)}
                    key={archivedPatient.user.id}
                  >
                    <TableCell width={500}>
                      <Box display='flex' alignItems='center'>
                        <Avatar sx={{ marginRight: '8px' }}>
                          {archivedPatient.firstName[0] || 'W'}
                        </Avatar>
                        <span>
                          <b>{archivedPatient.firstName}</b>
                          {` ${archivedPatient.lastName}`}
                        </span>
                        {archivedPatient.isTutor ? (
                          <CaregiverTooltip
                            caregiver={archivedPatient.caregiver}
                          />
                        ) : (
                          ''
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>{archivedPatient.age}</TableCell>
                    <TableCell>
                      {archivedPatient.lastResponseDate
                        ? format(
                            new Date(archivedPatient.lastResponseDate),
                            'dd MMM yy',
                            {
                              locale: brLocale,
                            }
                          )
                        : ' - '}
                    </TableCell>
                    <TableCell>
                      {format(
                        new Date(archivedPatient.user.archivedAt),
                        'dd MMM yy',
                        {
                          locale: brLocale,
                        }
                      )}
                    </TableCell>
                    <TableCell
                      width={200}
                      align='center'
                      onClick={(e) => e.stopPropagation()}
                    >
                      <PatientsTableActions
                        patientName={`${archivedPatient.firstName} ${archivedPatient.lastName}`.trim()}
                        lastFormId={archivedPatient.lastFormId}
                        userId={archivedPatient.user.id}
                        patientId={archivedPatient.patientId}
                        caregiverName={
                          archivedPatient.caregiver
                            ? `${archivedPatient.caregiver.firstName} ${archivedPatient.caregiver.lastName}`.trim()
                            : ''
                        }
                        formRequestFrequency={
                          archivedPatient.formRequestFrequency
                        }
                        fetchPatients={fetchPatients}
                        hasResults={Boolean(archivedPatient.lastResponseDate)}
                        viewed={archivedPatient.viewed}
                        formRequestId={archivedPatient.formRequestId}
                        responseDate={archivedPatient.responseDate}
                        isArchived
                      />
                    </TableCell>
                  </ArchivedPatientTableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default ArchivedPatientList
