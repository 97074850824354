import { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import { generateResumeInfos, formatAnswerLabel } from 'helpers'
import { ResumeText } from './tag-card-resume-styles'

function TagCardResume({ response }: any): JSX.Element {
  const [resumeInfos, setResumeInfos] = useState<any>()

  useEffect(() => {
    if (!response) return

    setResumeInfos(generateResumeInfos(response))
  }, [response])

  if (!resumeInfos) return <></>

  return (
    <Box width='182px'>
      {Object.entries(resumeInfos).map(([answer, shortVersions]: any) => (
        <Box mb={1}>
          <ResumeText>
            {answer !== 'skip' ? (
              <>
                <b>{formatAnswerLabel(answer, shortVersions.length)}</b>{' '}
                {shortVersions.join(', ')}
              </>
            ) : (
              <>
                {shortVersions.length}{' '}
                {`pergunta${shortVersions.length > 1 ? 's' : ''} sem resposta`}
              </>
            )}
          </ResumeText>
        </Box>
      ))}
    </Box>
  )
}

export default TagCardResume
