import { useState, useEffect } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useFormContext } from 'react-hook-form'
import {
  Box,
  Stack,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
  ClickAwayListener,
} from '@mui/material'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { Graphic, TagCardResume } from 'components/results'
import {
  capitalizeWordsOnSentence,
  formatDbDateToLabel,
  generateResumeInfos,
  formatAnswerLabel,
} from 'helpers'
import { useResultsContext } from 'contexts'
import {
  Container,
  DividerLine,
  TagName,
  DateLabel,
  InfosContainer,
  CopyResumeButton,
  ContentContainer,
} from './tag-card-styles'
import flipIcon from 'images/icons/flip-icon-light.svg'
import expandIcon from 'images/icons/expand-icon-light.svg'

function TagCard({
  tag,
  index,
  responses,
  flipped,
  setFlipped,
}: any): JSX.Element {
  const [selectedResponse, setSelectedResponse] = useState<any>()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)

  const { setShowDetail } = useResultsContext()
  const { watch, setValue, getValues } = useFormContext()

  const activeProportion = watch('activeProportion')

  const tagName = capitalizeWordsOnSentence(tag.name)

  useEffect(() => {
    setSelectedResponse(responses[0])
  }, [responses])

  const handleOpenDetail = (e: any) => {
    e.stopPropagation()
    const selectedTags = getValues('selectedTags')

    setValue(
      'selectedTags',
      selectedTags.filter((selectedTag: string) => selectedTag === tag.id)
    )

    setShowDetail(true)
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (e?: any) => {
    if (e) e.stopPropagation()
    setAnchorEl(null)
  }

  const handleSelectDate = (formRequestId: any) => {
    const newSelectedResponse = responses.find(
      (response: any) => response.formRequestId === formRequestId
    )
    setSelectedResponse(newSelectedResponse)

    handleClose()
  }

  const handleShowLabel = () => {
    setTooltipOpen(!tooltipOpen)
  }

  const handleCopyResume = (e: any) => {
    e.stopPropagation()

    const resumeInfos = generateResumeInfos(selectedResponse)

    const resume = Object.entries(resumeInfos).map(
      ([answer, shortVersions]: any) => {
        if (answer !== 'skip') {
          return `${formatAnswerLabel(
            answer,
            shortVersions.length
          )} ${shortVersions.join(', ')}`
        }

        if (answer === 'skip') {
          return `${shortVersions.length} pergunta${
            shortVersions.length > 1 ? 's' : ''
          } sem resposta`
        }

        return ''
      }
    )

    navigator.clipboard.writeText(resume.join('\n\n'))
  }

  if (!selectedResponse) {
    return (
      <Container alignItems='center' justifyContent='center'>
        <CircularProgress />
      </Container>
    )
  }

  return (
    <Draggable draggableId={tag.id} index={index}>
      {(provided: any, snapshot: any) => {
        if (snapshot.isDragging) setTooltipOpen(false)

        return (
          <Box
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            position='relative'
            onClick={handleShowLabel}
          >
            <Container isDragging={snapshot.isDragging} flipped={flipped}>
              <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
                <ContentContainer
                  height='100%'
                  display='flex'
                  flipped={flipped}
                >
                  <Box position='absolute' sx={{ top: 0, right: 0 }}>
                    <IconButton onClick={handleOpenDetail}>
                      <img
                        src={expandIcon}
                        height='20px'
                        alt='ícone para abrir o detalhamento'
                      />
                    </IconButton>
                  </Box>
                  <InfosContainer>
                    {snapshot.isDragging && (
                      <DragIndicatorIcon
                        style={{ fill: '#BDBDBD', marginTop: '-24px' }}
                      />
                    )}

                    <Box mb={1} px={3}>
                      <TagName>{tagName}</TagName>
                    </Box>

                    {/* Box do gráfico e detalhamento quando flipado */}
                    <Box
                      flexGrow={1}
                      display='flex'
                      alignItems='center'
                      width='100%'
                      px={2}
                    >
                      <Stack
                        alignItems='center'
                        key={selectedResponse.formRequestId}
                        flexGrow={flipped ? 0 : 1}
                      >
                        <Graphic
                          quantities={selectedResponse.quantities}
                          maxTagQuestions={selectedResponse.totalAsked}
                          totalAsked={selectedResponse.totalAsked}
                          isProportional={activeProportion}
                          tooltipOpen={tooltipOpen}
                          setTooltipOpen={setTooltipOpen}
                        />
                        <DividerLine flipped={flipped} />
                      </Stack>

                      {flipped && (
                        <Box ml={2} flexGrow={1}>
                          <TagCardResume response={selectedResponse} />
                        </Box>
                      )}
                    </Box>

                    <Box mt={1} alignItems='space-between'>
                      {flipped ? (
                        <Stack direction='row' spacing={1} alignItems='center'>
                          <CopyResumeButton
                            variant='text'
                            onClick={handleCopyResume}
                          >
                            Copiar resumo
                          </CopyResumeButton>
                        </Stack>
                      ) : (
                        <Stack direction='row' spacing={1} alignItems='center'>
                          <DateLabel>
                            {formatDbDateToLabel(selectedResponse.responseDate)}
                          </DateLabel>
                          <IconButton
                            sx={{ height: '24px', width: '24px' }}
                            onClick={handleClick}
                          >
                            <ExpandMoreRoundedIcon sx={{ fill: '#F36F60' }} />
                          </IconButton>
                        </Stack>
                      )}
                    </Box>

                    <Box
                      display='flex'
                      width='100%'
                      justifyContent='flex-start'
                      alignSelf={'flex-end'}
                    >
                      <IconButton
                        onClick={(e: any) => {
                          e.stopPropagation()
                          setFlipped(tag.id)
                        }}
                      >
                        <img
                          src={flipIcon}
                          height='24px'
                          alt='Ícone para abrir o resumo'
                        />
                      </IconButton>
                    </Box>
                  </InfosContainer>

                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {responses.map((response: any) => (
                      <MenuItem
                        key={response.formRequestId}
                        onClick={(e: any) => {
                          e.stopPropagation()
                          handleSelectDate(response.formRequestId)
                        }}
                        disableRipple
                      >
                        {formatDbDateToLabel(response.responseDate)}
                      </MenuItem>
                    ))}
                  </Menu>
                </ContentContainer>
              </ClickAwayListener>
            </Container>
          </Box>
        )
      }}
    </Draggable>
  )
}

export default TagCard
