export function generateDataset(respondersWithResponses: any) {
  return respondersWithResponses.reduce(
    (acc: any, respondersWithResponse: any) => {
      const unityResponses = respondersWithResponse.responses.reduce(
        (acc: any, response: any) => {
          const tagResponses = response.tags.map((tag: any) => {
            const questions = response.questions.filter(({ tags }: any) =>
              tags.includes(tag.tag)
            )

            return {
              formRequestId: response.formRequestId,
              formDateCreate: response.formDateCreate,
              formDateUpdate: response.formDateUpdate,
              responseDate: response.responseDate,
              tag,
              responder: {
                id: respondersWithResponse.id,
                isPatient: respondersWithResponse.isPatient,
                name: respondersWithResponse.name,
                relation: respondersWithResponse.relation,
              },
              quantities: generateAnswersQuantitiesObj(questions),
              questions: questions,
              totalAsked: questions.length,
              viewed: response.viewed,
            }
          })
          return [...acc, ...tagResponses]
        },
        []
      )
      return [...acc, ...unityResponses]
    },
    []
  )
}

function generateAnswersQuantitiesObj(questions: any) {
  return questions.reduce(
    (acc: any, { answer }: any) => ({
      ...acc,
      [answer]: (acc[answer] || 0) + 1,
    }),
    {}
  )
}

export function orderReponderOptions(responderOptions: any) {
  return responderOptions.slice().sort((respA: any, respB: any) => {
    if (respA.isPatient) return -1
    if (respB.isPatient) return 1

    if (respA.relation === 'paciente') return -1
    if (respB.relation === 'paciente') return 1

    return respA.name.localeCompare(respB.name)
  })
}
