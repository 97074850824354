import { Button } from './icon-button-clinic-styles'

interface IIconButtonClinic {
  onClick?: () => void
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
  children: any
  size: number
  color: 'purple'
}

function IconButtonClinic({
  onClick = () => {},
  disabled = false,
  children,
  size = 42,
  color = 'purple',
  type = 'button',
}: IIconButtonClinic): JSX.Element {
  const commonProps = {
    onClick,
    children,
    disabled,
    size,
    color,
    type,
  }

  return <Button {...commonProps}>{children}</Button>
}

export default IconButtonClinic
