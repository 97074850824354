import { Box, Stack, Tooltip } from '@mui/material'
import { format } from 'date-fns'
import brLocale from 'date-fns/locale/pt-BR'
import React, { useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import {
  NoIcon,
  SkippedIcon,
  SoSoIcon,
  YesIcon,
} from 'components/icons/patient-report'
import SelectAnswer from 'components/select-answer/select-answer'
import QuestionAssociation from 'components/question-association/question-association'
import {
  answersSymbols,
  capitalizeWordsOnSentence,
  getQuestionsWithoutSubdomain,
  getSubdomainsWithQuestions,
  legendsIcons,
} from 'helpers'
import { PatientReport, Question } from 'types/patient-report-types'
import {
  LegendText,
  LineDivider,
  QuestionLine,
  QuestionText,
  ResponderInfoSubtext,
  ResponderNameText,
  SubdomainTitle,
  Title,
} from './report-step-by-tag-styles'
import FooterTagText from './tag-texts/footer-text'
import HeaderTagText from './tag-texts/header-text'

const QUESTION_NAME_COLUMN_WIDTH = 300
const ANSWER_COLUMN_WIDTH = 180

interface IReportStepByTag {
  tagId: string
  patientReport: PatientReport
}

const answerPlaceholder = (
  <Box
    sx={{
      width: 14,
      height: 14,
      borderRadius: '50%',
      backgroundColor: '#D4D4D4',
      border: '1px solid #D4D4D4',
    }}
  />
)

const answerOptions = [
  {
    label: answerPlaceholder,
    value: 'not_answer',
  },
  {
    label: <YesIcon size={14} />,
    value: '2',
    hoverText: 'Presente',
  },
  {
    label: <SoSoIcon size={14} />,
    value: '1',
    hoverText: 'Indeterminado',
  },
  {
    label: <NoIcon size={14} />,
    value: '0',
    hoverText: 'Ausente',
  },
  {
    label: <SkippedIcon size={14} />,
    value: 'skip',
    hoverText: 'Não responder',
  },
]

function ReportStepByTag({
  tagId,
  patientReport,
}: IReportStepByTag): JSX.Element {
  const { control, setValue } = useFormContext()
  const location = useLocation()
  const [highlightQuestion, setHighlightQuestion] = useState<any>(null)

  const clinicalOpinions = patientReport?.clinicalOpinions
  const isCreatingReport = location.pathname.includes('/create_report')
  const withClinicalOpinion = patientReport?.withClinicalOpinion
  const showClinicalOpinion = withClinicalOpinion || isCreatingReport
  const isReadOnlyPatientReport =
    withClinicalOpinion && location.pathname.includes('/reports')

  const handleEmphasisQuestion = (question: any) => {
    setHighlightQuestion(highlightQuestion === question.id ? null : question.id)
  }

  const { selectedTag, formRequests } = useMemo(() => {
    const findSelectedTag = patientReport?.tags?.find(
      ({ id }: any) => id === tagId
    )

    const questions = findSelectedTag?.questions

    const subdomainsWithQuestions = getSubdomainsWithQuestions(
      questions!,
      findSelectedTag
    )
    const questionsWithoutSubdomain = getQuestionsWithoutSubdomain(questions)

    return {
      selectedTag: {
        ...findSelectedTag,
        subdomainsWithQuestions,
        questionsWithoutSubdomain,
      },
      formRequests: patientReport?.formRequests,
    }
  }, [patientReport?.formRequests, patientReport?.tags, tagId])

  const getTooltipText = (question: any) => {
    if (patientReport?.patient?.personalInfo?.pronoun === 'HE') {
      return question.question3rdM
    } else {
      return question.question3rdF
    }
  }

  const hasAssociationQuestions = () => {
    return (
      selectedTag?.questions?.some(
        (question: any) => question?.associations?.length > 0
      ) || false
    )
  }

  const renderHeaderInfos = () => (
    <Box display='flex'>
      {formRequests.map((formRequest: any) => (
        <Stack
          key={formRequest.formRequestId}
          width={ANSWER_COLUMN_WIDTH}
          alignItems='center'
          sx={{ paddingTop: clinicalOpinions?.length ? '8px' : '' }}
        >
          <Tooltip
            arrow
            placement='top'
            title={`${formRequest.sendTo.personalInfo.firstName} ${formRequest.sendTo.personalInfo.lastName}`}
          >
            <Stack>
              <ResponderNameText>
                {formRequest.sendTo.personalInfo.firstName}
              </ResponderNameText>
              <ResponderInfoSubtext>
                {formRequest.relation
                  ? formRequest.relation.name.toLowerCase()
                  : 'paciente'}
              </ResponderInfoSubtext>
              <ResponderInfoSubtext>
                {format(new Date(formRequest.responseDate), 'dd MMM yy', {
                  locale: brLocale,
                })}
              </ResponderInfoSubtext>
            </Stack>
          </Tooltip>
        </Stack>
      ))}
      {showClinicalOpinion && (
        <Stack
          width={ANSWER_COLUMN_WIDTH}
          alignItems='center'
          justifyContent='center'
          sx={{
            backgroundColor: '#FAFAFA',
            paddingBottom: '32px',
            borderRadius: '8px 8px 0 0',
            paddingTop: '16px',
          }}
        >
          <ResponderNameText px={5}>Avaliação clínica</ResponderNameText>
        </Stack>
      )}
    </Box>
  )

  const renderHeaderSpacing = () => (
    <Box>
      {showClinicalOpinion &&
        formRequests.map((fr) => (
          <Box key={fr.formRequestId} width={ANSWER_COLUMN_WIDTH} />
        ))}
      <Box
        sx={{
          backgroundColor: showClinicalOpinion ? '#FAFAFA' : '',
          height: '108px',
          width: ANSWER_COLUMN_WIDTH,
        }}
      />
    </Box>
  )

  const renderHeader = (subdomainName: string, index: number) => (
    <Stack
      direction='row'
      mb={!showClinicalOpinion && index === 0 ? '24px' : ''}
      px={2}
      justifyContent='space-between'
    >
      <Box
        display='flex'
        width={QUESTION_NAME_COLUMN_WIDTH}
        justifyContent='flex-start'
        alignItems='flex-end'
        mb={showClinicalOpinion || index !== 0 ? '24px' : ''}
      >
        <SubdomainTitle>{subdomainName}</SubdomainTitle>
      </Box>
      {index === 0 ? renderHeaderInfos() : renderHeaderSpacing()}
    </Stack>
  )

  const renderQuestions = (questions?: Question[]) =>
    questions?.map((question: any) => {
      let highlightStatus = 'normal'
      if (highlightQuestion) {
        highlightStatus =
          question.id === highlightQuestion ? 'highlight' : 'opaque'
      }

      return (
        <QuestionLine
          key={`${selectedTag.id}.${question.id}`}
          height={'44px'}
          direction='row'
          highlight={highlightStatus}
        >
          <Box ml={'-10px'}>
            {question.associationMark ? (
              <QuestionAssociation type={question.associationMark} />
            ) : (
              <Box width={10} />
            )}
          </Box>

          <QuestionLine
            mx={2}
            alignItems='center'
            justifyContent='space-between'
            flex={1}
            direction='row'
            borderBottom={
              highlightStatus === 'normal' || highlightStatus === 'opaque'
                ? '1px dashed #B7B7B7'
                : ''
            }
          >
            <Box width={QUESTION_NAME_COLUMN_WIDTH}>
              <Tooltip title={getTooltipText(question)} placement='right' arrow>
                <QuestionText>{question.ultraShortVersion}</QuestionText>
              </Tooltip>
            </Box>
            <Box display='flex' alignItems='center'>
              {question.answers.map(({ formRequestId, answer }: any) => (
                <Box
                  key={formRequestId}
                  width={ANSWER_COLUMN_WIDTH}
                  display='flex'
                  justifyContent='center'
                >
                  {answersSymbols[answer]}
                </Box>
              ))}
              {showClinicalOpinion && (
                <Box
                  width={ANSWER_COLUMN_WIDTH}
                  display='flex'
                  justifyContent='center'
                  sx={{ backgroundColor: '#FAFAFA', paddingY: '9px' }}
                >
                  <SelectAnswer
                    control={control}
                    name={`tags.${selectedTag.id}.${question.id}`}
                    options={answerOptions}
                    disabled={!isCreatingReport}
                    defaultValue={
                      isReadOnlyPatientReport
                        ? answerOptions[4].value
                        : answerOptions[0].value
                    }
                    onOpen={() => handleEmphasisQuestion(question)}
                    onClose={() => setHighlightQuestion(null)}
                  />
                </Box>
              )}
            </Box>
          </QuestionLine>
        </QuestionLine>
      )
    })

  const renderColoredColumn = () => {
    return (
      <Box
        sx={{
          height: '120px',
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
          px: 2,
        }}
      >
        <Box
          sx={{
            backgroundColor: withClinicalOpinion ? '#FAFAFA' : '#FFF',
            width: ANSWER_COLUMN_WIDTH,
          }}
        />
      </Box>
    )
  }

  const renderReport = () => (
    <Stack my='68px' ml='97px'>
      {selectedTag.subdomainsWithQuestions.map(
        (subdomain: any, index: number) => (
          <React.Fragment key={subdomain.subdomainName}>
            {renderHeader(subdomain.subdomainName, index)}
            {renderQuestions(subdomain.questions)}
          </React.Fragment>
        )
      )}
      {!!selectedTag?.questionsWithoutSubdomain?.length && (
        <>
          {!selectedTag.subdomainsWithQuestions.length
            ? renderHeader('', 0)
            : renderColoredColumn()}
          {renderQuestions(selectedTag?.questionsWithoutSubdomain)}
        </>
      )}
    </Stack>
  )

  useEffect(() => {
    if (clinicalOpinions?.length) {
      clinicalOpinions.forEach(({ answer, question, tag }) =>
        setValue(`tags.${tag}.${question}`, answer)
      )
    }
  }, [clinicalOpinions, setValue])

  return (
    <Box mr='32px'>
      <Stack ml={'30px'} alignItems={'flex-start'}>
        <Box mt={4}>
          <Title>
            Domínio: {capitalizeWordsOnSentence(selectedTag?.name || '')}
          </Title>
        </Box>

        <LineDivider />

        <Box mt={2} mb={4}>
          <HeaderTagText tagName={selectedTag?.name || ''} />
        </Box>

        {/* Legendas */}
        <Stack
          direction='row'
          spacing={'22px'}
          sx={{ width: '570px', height: '34px', background: '#F9F9F9' }}
          alignItems='center'
          paddingX='12px'
        >
          {legendsIcons.map(({ label, icon }: any) => (
            <Stack key={label} direction='row' spacing={1} alignItems='center'>
              {icon}
              <LegendText key={label}>{label}</LegendText>
            </Stack>
          ))}
        </Stack>
      </Stack>

      {/* Tabela */}
      {renderReport()}

      <Box mt={2} mb={6} ml={'30px'}>
        <FooterTagText
          hasAssociation={hasAssociationQuestions()}
          tagName={selectedTag?.name || ''}
        />
      </Box>
    </Box>
  )
}

export default ReportStepByTag
