import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { InputField } from 'components/inputs'
import { Title, InfoText, ErrorMessage } from '../styles/general-steps-styles'

function ContactStep(): JSX.Element {
  const [atLeastOneError, setAtLeastOneError] = useState(false)

  const {
    control,
    formState: { errors },
  } = useFormContext()

  useEffect(() => {
    setAtLeastOneError(
      Boolean(
        [errors?.email?.message, errors?.whatsapp?.message].includes(
          'Pelo menos um canal de comunicação deve ser preenchido'
        )
      )
    )
  }, [errors?.email?.message, errors?.whatsapp?.message])

  return (
    <>
      <Box mb={2}>
        <Title>
          Como você gostaria de ser notificado de que há novas perguntas para
          você no Wida?
        </Title>
      </Box>

      <Box mb={10}>
        <InfoText>Insira pelo menos uma forma de contato.</InfoText>
      </Box>

      <Box mt={4} mb={3}>
        <InputField
          mask='(00) 00000-0000'
          name='whatsapp'
          control={control}
          label='WhatsApp'
          notShowError={atLeastOneError}
        />
      </Box>
      <Box mb={3}>
        <InputField
          name='email'
          control={control}
          label='E-mail'
          invalidChars={[' ']}
          notShowError={atLeastOneError}
        />
      </Box>
      <Box>
        {atLeastOneError && (
          <ErrorMessage>{errors?.email?.message}</ErrorMessage>
        )}
      </Box>
    </>
  )
}

export default ContactStep
