import client from 'services/client'

export const getQuestions = async () => {
  try {
    const { data }: any = await client.get('/questions')

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}
