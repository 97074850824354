export const professionalCouncils = [
  'CBOO',
  'CONAHOM',
  'CRAS',
  'CRBM',
  'CREFITO',
  'COREN',
  'CREF',
  'CRF',
  'CRFA',
  'CRM',
  'CRN',
  'CRO',
  'CRP',
  'CRT',
  'CRNT',
]
