import { Stack, Box } from '@mui/material'
import { InputField } from 'components/inputs'
import { IPersonalInfoFields } from './personal-info-fields-types'
import { TitleText } from './personal-info-fields-styles'

function InformantPersonalInfoFields({
  control,
}: IPersonalInfoFields): JSX.Element {
  return (
    <Stack width='100%'>
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        height='20px'
        mb={3}
      >
        <TitleText>Dados pessoais</TitleText>
      </Box>
      <Stack spacing={2}>
        <InputField name='firstName' control={control} label='Primeiro nome' />
        <InputField name='lastName' control={control} label='Sobrenome' />
      </Stack>
    </Stack>
  )
}

export default InformantPersonalInfoFields
