import { PatientReportClinicalOpinion } from 'types/patient-report-clinical-opinion-types'
import client from './client'

export const createPatientReportClinicalOpinion = async (
  body: PatientReportClinicalOpinion
) => {
  try {
    const { data } = await client.post('/patient-report-clinical-opinion', body)

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}
