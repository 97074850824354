import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Stack, CircularProgress } from '@mui/material'
import { Button, SwitchIos } from 'components'
import { useNotification } from 'hooks'
import { getUserInformants, updateInformantPatient } from 'services'
import { InformantName, InformantRelation } from './informants-styles'
import { Title, Description } from '../settings-styles'

import { useForm } from 'react-hook-form'

const MAX_CARACTERES = 30

function SettingsInformants(): JSX.Element {
  const [loading, setLoading] = useState(true)
  const [informants, setInformants] = useState([])

  const { control, handleSubmit, setValue } = useForm()

  const { errorToast, successToast } = useNotification()
  const navigate = useNavigate()

  const fetchData = async () => {
    try {
      setLoading(true)

      const informants = await getUserInformants()

      const formattedInformants = informants.map((informant: any) => {
        const relation =
          informant.relation?.name === 'Sou eu'
            ? 'paciente'
            : informant.relation?.name.toLowerCase() || ''

        const fullName = `${informant.personalInfo?.firstName || ''} ${
          informant.personalInfo?.lastName || ''
        }`

        return {
          ...informant,
          name:
            fullName.length > MAX_CARACTERES
              ? fullName.slice(0, MAX_CARACTERES) + '...'
              : fullName,
          relation,
        }
      })

      setInformants(
        formattedInformants.sort((a: any, b: any) => {
          if (a.name < b.name) return -1
          if (a.name > b.name) return 1
          return 0
        })
      )
    } catch (error: any) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (informants.length === 0) return

    informants.forEach(({ informantPatientId, active }: any) => {
      setValue(informantPatientId, active)
    })
  }, [setValue, informants])

  const onSubmit = async (params: any) => {
    try {
      setLoading(true)

      await Promise.all(
        Object.entries(params).map(([informantPatientId, active]: any) =>
          updateInformantPatient(informantPatientId, { active })
        )
      )

      fetchData()

      successToast('Convidados atualizados com sucesso!')
    } catch (error: any) {
      setLoading(false)
      errorToast(error?.message || 'Erro desconhecido')
    }
  }

  return (
    <Box
      flexGrow={1}
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='space-between'
      component='form'
      onSubmit={handleSubmit(onSubmit)}
      px={2}
      pt={5}
      sx={{
        backgroundColor: '#ffffff',
        borderRadius: '28px 28px 0px 0px',
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Stack width='100%'>
            <Stack display='flex' flexDirection='column' spacing={2} mb={3}>
              <Title>Convidados</Title>
              <Description>
                {informants.length === 0
                  ? 'Não há convidados cadastrados.'
                  : 'Você pode remover pessoas da sua lista de convidados. Elas não aparecerão mais como sugestão para envios futuros.'}
              </Description>
            </Stack>
            <Stack spacing={2}>
              {informants.map((informant: any) => (
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  key={informant.informantPatientId}
                >
                  <Stack spacing={0.5} flexGrow={1}>
                    <InformantName>{informant.name}</InformantName>
                    <InformantRelation>{informant.relation}</InformantRelation>
                  </Stack>
                  <SwitchIos
                    name={informant.informantPatientId}
                    control={control}
                  />
                </Stack>
              ))}
            </Stack>
          </Stack>

          <Stack my={3} width='295px' spacing={1}>
            {informants.length !== 0 && (
              <Button type='submit' disabled={loading}>
                Salvar
              </Button>
            )}
            <Button variant='text' onClick={() => navigate('/settings')}>
              Voltar
            </Button>
          </Stack>
        </>
      )}
    </Box>
  )
}

export default SettingsInformants
