import { Typography, Avatar, Box, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'

export const WelcomeText = styled(Typography)({
  fontFamily: 'Zen Kaku Gothic Antique',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '23px',
  textAlign: 'center',
  color: '#FFFFFF',
})

export const AvatarStyled = styled(Avatar)({
  width: 92,
  height: 92,
  background: '#EDEEFD',
  color: '#05128A',
  border: '6px solid #FFFFFF',
  fontSize: '36px',
})

export const InviteContainer = styled(Box)({
  height: '60px',
  margin: '16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
})

export const InviteText = styled(Typography)({
  fontFamily: 'Zen Kaku Gothic Antique',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  color: '#FFFFFF',
})

export const ExternalContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  backgroundColor: '#8D92BE',
  borderRadius: '28px 28px 0px 0px',
})

export const InternalContainer = styled(Stack)({
  flexGrow: 1,
  backgroundColor: '#ffffff',
  borderRadius: '28px 28px 0px 0px',
  padding: '40px 16px 40px 16px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingRight: '70px',
  paddingLeft: '70px',
})

export const InternalText = styled(Typography)({
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '21px',
  textAlign: 'center',
  color: '#657787',
})

export const ScheduleText = styled(Typography)({
  color: '#596B7B',
  textAlign: 'center',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '18px',
})
