import { Text, View } from '@react-pdf/renderer'
import { styles } from './page-counter-styles'

function PageCounter() {
  return (
    <View style={styles.view}>
      <Text
        style={styles.currentPageText}
        render={({ pageNumber }) => pageNumber}
      />
      <View style={styles.lineView} />
      <Text
        style={styles.totalPagesText}
        render={({ totalPages }) => totalPages}
      />
    </View>
  )
}

export default PageCounter
