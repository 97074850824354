import { View } from '@react-pdf/renderer'

interface SkippedIconProps {
  size?: number
}

function SkippedIcon({ size = 9 }: SkippedIconProps) {
  return (
    <View
      style={{
        width: size,
        height: size,
        borderRadius: '50%',
        border: '1px dashed #000',
      }}
    />
  )
}

export default SkippedIcon
