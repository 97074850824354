import Box from '@mui/material/Box'
import Button, { ButtonProps } from '@mui/material/Button'
import { styled } from '@mui/material/styles'

export const Container = styled(Box)({
  flexGrow: 1,
})

export const MenuButton = styled(Button)<ButtonProps & { selected: boolean }>(
  ({ selected }) => ({
    textTransform: 'none',
    color: '#353F48',
    padding: '5px 20px',
    fontFamily: 'Quicksand',
    fontStyle: 'normal',
    fontWeight: '400',
    borderRadius: '5px',
    minHeight: '40px',
    background: selected ? '#F9F9FB' : 'transparent',
  })
)
