import { useEffect, useRef, useState } from 'react'
import { Stack } from '@mui/material'
import { Button } from './button-clinic-styles'
import { icons } from './icons'

interface IButtonClinic {
  variant: 'contained' | 'outlined' | 'text'
  onClick?: () => void
  children: any
  size: 'small' | 'medium' | 'large'
  fontWeight: 'regular' | 'bold'
  color: 'purple' | 'orange' | 'green'
  icon?:
    | 'forwardArrow'
    | 'whatsapp'
    | 'email'
    | 'link'
    | 'edit'
    | 'addPerson'
    | 'send'
    | 'report'
  disabled?: boolean
  width?: number
  height?: number
  type?: 'button' | 'submit' | 'reset'
  iconPosition?: 'start' | 'end'
  iconSize?: number
  borderRadius?: string
  loading?: boolean
}

function ButtonClinic({
  variant = 'contained',
  onClick = () => {},
  icon,
  disabled = false,
  children,
  width = 0,
  height = 0,
  size = 'medium',
  fontWeight = 'bold',
  color = 'purple',
  type = 'button',
  iconPosition = 'end',
  iconSize = 18,
  borderRadius = '50px',
  loading = false,
}: IButtonClinic): JSX.Element {
  const [loadingText, setLoadingText] = useState('.')

  const intervalRef: any = useRef(null)

  useEffect(() => {
    if (!loading) {
      clearInterval(intervalRef.current)
      return
    }

    intervalRef.current = setInterval(() => {
      setLoadingText((loadingText) =>
        loadingText === '.' ? '..' : loadingText === '..' ? '...' : '.'
      )
    }, 600)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const commonProps = {
    onClick,
    children,
    disabled: disabled || loading,
    width,
    height,
    variant,
    size,
    fontWeight,
    color,
    type,
    borderRadius,
    loading,
  }

  return (
    <Button {...commonProps}>
      <Stack direction='row' alignItems='center'>
        {icon &&
          iconPosition === 'start' &&
          icons[icon]({ size: iconSize, placement: iconPosition })}
        {children}
        {loading && loadingText}
        {icon &&
          iconPosition === 'end' &&
          icons[icon]({ size: iconSize, placement: iconPosition })}
      </Stack>
    </Button>
  )
}

export default ButtonClinic
