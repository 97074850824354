interface ListIconProps {
  color?: string
  margin?: string
}

const ListIcon = ({ color = '#657787', margin }: ListIconProps) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ margin }}
    >
      <path
        d='M17.25 13.6667H10.25C9.147 13.6667 8.25 14.4142 8.25 15.3333C8.25 16.2525 9.147 17 10.25 17H17.25C18.353 17 19.25 16.2525 19.25 15.3333C19.25 14.4142 18.353 13.6667 17.25 13.6667ZM17.25 7.83333H10.25C9.147 7.83333 8.25 8.58083 8.25 9.5C8.25 10.4192 9.147 11.1667 10.25 11.1667H17.25C18.353 11.1667 19.25 10.4192 19.25 9.5C19.25 8.58083 18.353 7.83333 17.25 7.83333ZM17.25 2H10.25C9.147 2 8.25 2.7475 8.25 3.66667C8.25 4.58583 9.147 5.33333 10.25 5.33333H17.25C18.353 5.33333 19.25 4.58583 19.25 3.66667C19.25 2.7475 18.353 2 17.25 2Z'
        fill={color}
      />
      <path
        d='M4.16683 17.9167C5.31742 17.9167 6.25016 16.9839 6.25016 15.8333C6.25016 14.6827 5.31742 13.75 4.16683 13.75C3.01624 13.75 2.0835 14.6827 2.0835 15.8333C2.0835 16.9839 3.01624 17.9167 4.16683 17.9167Z'
        fill={color}
      />
      <path
        d='M4.16683 12.0832C5.31742 12.0832 6.25016 11.1504 6.25016 9.99984C6.25016 8.84924 5.31742 7.9165 4.16683 7.9165C3.01624 7.9165 2.0835 8.84924 2.0835 9.99984C2.0835 11.1504 3.01624 12.0832 4.16683 12.0832Z'
        fill={color}
      />
      <path
        d='M4.16683 6.25016C5.31742 6.25016 6.25016 5.31742 6.25016 4.16683C6.25016 3.01624 5.31742 2.0835 4.16683 2.0835C3.01624 2.0835 2.0835 3.01624 2.0835 4.16683C2.0835 5.31742 3.01624 6.25016 4.16683 6.25016Z'
        fill={color}
      />
    </svg>
  )
}

export default ListIcon
