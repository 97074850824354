import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Container = styled(Box)({
  display:'grid',
  gridTemplateColumns:'min-content min-content',
  gap:'20px',
  margin: '24px 48px 0',
})

export const TitleText = styled(Typography)({
  color: '#596B7B',
  fontFamily: 'Quicksand',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '33px',
})

export const Text = styled(Typography)({
  color: '#596B7B',
  fontFamily: "Zen Kaku Gothic Antique",
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '18px',
})

export const BoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  width: '452px',
  backgroundColor: '#F9F9F9',
  borderRadius: '8px',
  padding: '16px',
})

export const LinkButton = styled('button')({
  cursor: 'pointer',
  border: 'none',
  backgroundColor: 'transparent',
  padding: 0,
})

export const LinkText = styled(Typography)({
  color: '#8D92BE',
  fontFamily: 'Quicksand',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '24px',
})