import { useNavigate } from 'react-router-dom'
import { Box, Stack } from '@mui/material'
import {
  updateMyPatientInfos,
  updateMyCaregiverInfos,
  updateMyInformantInfos,
} from 'services'
import { Button } from 'components'
import { InputField } from 'components/inputs'
import { useAuthContext } from 'contexts'
import { useNotification } from 'hooks'
import useSettingsSecurityForm from './security-form'
import { Title, Description } from '../settings-styles'

const roleDefinitions: any = {
  patient: {
    updateMyInfos: updateMyPatientInfos,
  },
  caregiver: {
    updateMyInfos: updateMyCaregiverInfos,
  },
  informant: {
    updateMyInfos: updateMyInformantInfos,
  },
}

// TODO mudar senha deveria ser em user e não na role
function SettingsSecurity(): JSX.Element {
  const { userInfos, fetchUser } = useAuthContext()
  const role = userInfos?.user?.roles[0]

  const { control, handleSubmit } = useSettingsSecurityForm()
  const { errorToast, successToast } = useNotification()
  const navigate = useNavigate()

  const onSubmit = async (params: any) => {
    try {
      await roleDefinitions[role].updateMyInfos({
        currentPassword: params.currentPassword.trim(),
        password: params.password.trim(),
      })

      successToast('Informações atualizadas com sucesso')

      fetchUser()
    } catch (error: any) {
      errorToast(
        error?.response?.data?.message || error?.message || 'Erro desconhecido'
      )
    }
  }

  return (
    <Box
      flexGrow={1}
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='space-between'
      component='form'
      onSubmit={handleSubmit(onSubmit)}
      px={2}
      pt={5}
      sx={{
        backgroundColor: '#ffffff',
        borderRadius: '28px 28px 0px 0px',
      }}
    >
      <Stack width='100%'>
        <Stack display='flex' flexDirection='column' spacing={2} mb={3}>
          <Title>Segurança</Title>
          <Description>
            Sua senha deve ter no mínimo 8 dígitos, símbolo, letra maiúscula e
            número.
          </Description>
        </Stack>
        <Stack spacing={2}>
          <InputField
            type='password'
            name='currentPassword'
            control={control}
            label='Senha atual'
          />
          <InputField
            type='password'
            name='password'
            control={control}
            label='Nova senha'
          />
          <InputField
            type='password'
            name='confirmPassword'
            control={control}
            label='Confirmar nova senha'
          />
        </Stack>
      </Stack>

      <Stack my={3} width='295px' spacing={1}>
        <Button type='submit'>Salvar</Button>
        <Button variant='text' onClick={() => navigate('/settings')}>
          Voltar
        </Button>
      </Stack>
    </Box>
  )
}

export default SettingsSecurity
