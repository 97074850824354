import { View } from '@react-pdf/renderer'

interface NoIconProps {
  size?: number
}

function NoIcon({ size = 9 }: NoIconProps) {
  return (
    <View
      style={{
        width: size,
        height: size,
        borderRadius: '50%',
        border: '1px solid #000',
      }}
    />
  )
}

export default NoIcon
