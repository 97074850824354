import { Box, Container, Button as ButtonMui } from '@mui/material'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import logoColor from 'images/wida-logo-color.svg'
import { Title, Subtitle } from './invite-clinic-styles'

function InviteClinic(): JSX.Element {
  const handleInviteClinic = () => {
    const message =
      'Olá! Descobri uma forma inovadora de cuidar da minha saúde mental. Você poderia me enviar um convite para o Wida? https://wida.app'

    window.open(`https://api.whatsapp.com/send?text=${message}`)
  }

  return (
    <Container
      maxWidth='xs'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Box display='flex' alignItems='center' height='100px'>
        <img
          src={logoColor}
          height='20px'
          alt='Logo Wida'
          style={{
            flex: '1',
            display: 'flex',
            justifyContent: 'center',
          }}
        />
      </Box>
      <Box flexGrow={1} px={5}>
        <Box mt={10}>
          <Title>
            O primeiro passo de sua jornada no Wida é receber um convite.
          </Title>
        </Box>
        <Box mt={1}>
          <Subtitle>
            Peça ao profissional de saúde responsável pelo seu atendimento para
            convidar você.
          </Subtitle>
        </Box>
      </Box>
      <Box mb={5} width='230px'>
        <ButtonMui
          variant='contained'
          startIcon={<WhatsAppIcon />}
          fullWidth
          sx={{
            backgroundColor: '#01B3A7',
            '&:hover': { backgroundColor: '#01B3A7AA' },
            textTransform: 'none',
            fontSize: '16px',
          }}
          onClick={handleInviteClinic}
        >
          Eu quero usar o Wida
        </ButtonMui>
      </Box>
    </Container>
  )
}

export default InviteClinic
