import client from 'services/client'
import { sortPatientAttentionsByDates } from 'helpers'

export const getPatientAttentions = async (patientId: string) => {
  try {
    const { data }: any = await client.get(
      `/patient-attentions?patientId=${patientId}`
    )

    const sortedData = data.map((info: any) => {
      return {
        ...info,
        answers: info.answers
          .slice()
          .sort((a: any, b: any) => sortPatientAttentionsByDates(a, b, 'asc')),
      }
    })

    return sortedData
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const createPatientAttention = async (body: any) => {
  try {
    const { data }: any = await client.post('/patient-attentions', body)

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const deletePatientAttention = async (id: string) => {
  try {
    const { data }: any = await client.delete(`/patient-attentions/${id}`)

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}
