import { View } from '@react-pdf/renderer'
import { auxiliaryTagTexts } from './tag-texts'

interface IHeaderTextProps {
  tagName: string
}

function HeaderTagText({ tagName }: IHeaderTextProps) {
  const findTextByName = (tagName: string) => {
    const findTagText = auxiliaryTagTexts.find(obj => obj.tagName === tagName)
    if (!findTagText) return <></>

    const text = findTagText.headerText()

    return text
  }

  return <View>{findTextByName(tagName)}</View>
}

export default HeaderTagText
