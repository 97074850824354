import { Box } from '@mui/material'
import { auxiliaryTagTexts } from './tag-texts'
import {
  AssociationText,
  Subtitle,
  DiagnosticTitleText,
} from './tag-texts-styles'

interface IHeaderTextProps {
  tagName: string
  hasAssociation: boolean
}

function FooterTagText({ tagName, hasAssociation }: IHeaderTextProps) {
  const findTextByName = (tagName: string) => {
    const findTagText = auxiliaryTagTexts.find((obj) => obj.tagName === tagName)
    if (!findTagText) return <></>

    const text = findTagText.footerText()
    return text
  }

  return (
    <>
      <Box mb={2}>
        <DiagnosticTitleText>Critérios diagnósticos</DiagnosticTitleText>
      </Box>
      <Box>{findTextByName(tagName)}</Box>
      <Box mt={3}>
        <Subtitle>
          É importante estar atento a critérios adicionais relacionados a
          frequência e duração; sofrimento e/ou prejuízo acarretados; e
          explicações alternativas (uso de substâncias, condição médica ou outro
          transtorno mental).
        </Subtitle>
        {hasAssociation && (
          <Box style={{ display: 'flex', marginTop: 24, alignItems: 'center' }}>
            <svg
              width='9'
              height='50'
              viewBox='0 0 9 50'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1.5 1L1.5 49'
                stroke='#C7C8D7'
                stroke-width='2'
                stroke-linecap='round'
              />
              <path
                d='M1.5 1H7.5'
                stroke='#C7C8D7'
                stroke-width='2'
                stroke-linecap='round'
              />
              <path
                d='M1.5 49H7.5'
                stroke='#C7C8D7'
                stroke-width='2'
                stroke-linecap='round'
              />
            </svg>
            <AssociationText ml={1}>
              Fenômenos distintos ou exemplos que no DSM-5-TR aparecem agrupados
              em um único critério diagnóstico estão aqui apresentados sob forma
              de perguntas independentes.
            </AssociationText>
          </Box>
        )}
      </Box>
    </>
  )
}

export default FooterTagText
