import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  view: {
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    width: 20,
    height: 24,
  },
  currentPageText: {
    position: 'absolute',
    top: 1,
    right: 11,
    fontSize: '9px',
    color: '#596B7B',
    fontWeight: 500,
    fontStyle: 'semibold',
  },
  totalPagesText: {
    position: 'absolute',
    bottom: 2,
    left: 10,
    fontSize: '9px',
    color: '#596B7B',
    fontWeight: 500,
    fontStyle: 'semibold',
    opacity: 0.5,
  },
  lineView: {
    height: 0.5,
    width: 14,
    opacity: 0.5,
    backgroundColor: '#596B7B',
    transform: 'rotate(-45deg)',
  },
})
