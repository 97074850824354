import { createContext, useContext } from 'react'

interface IClinicContext {
  clinic: any
  isEdit: boolean
  fetchClinic: () => void
  handleSave: any
  hasUnsavedChanges: any
}

export const ClinicContext = createContext<IClinicContext>(null!)

export const useClinicContext = () => {
  const context = useContext(ClinicContext)
  return context
}
