import { useNavigate } from 'react-router-dom'
import { Box, IconButton } from '@mui/material'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

function HeaderMenuPatientGroup(): JSX.Element {
  const navigate = useNavigate()

  return (
    <Box mt={5} mb={4} px={1} display='flex' justifyContent='space-between'>
      <IconButton onClick={() => navigate('/patient')}>
        <CloseRoundedIcon sx={{ fill: 'white' }} />
      </IconButton>

      <IconButton onClick={() => navigate('/settings')}>
        <SettingsOutlinedIcon sx={{ fill: 'white' }} />
      </IconButton>
    </Box>
  )
}

export default HeaderMenuPatientGroup
