import Quicksand from 'fonts/Quicksand.ttf'
import QuicksandBold from 'fonts/Quicksand-Bold.ttf'
import QuicksandSemiBold from 'fonts/Quicksand-SemiBold.ttf'
import ZenKakuGothicAntique from 'fonts/ZenKakuGothicAntique.ttf'
import ZenKakuGothicAntiqueBold from 'fonts/ZenKakuGothicAntique-Bold.ttf'
import ZenKakuGothicAntiqueSemiBold from 'fonts/ZenKakuGothicAntique-SemiBold.ttf'
import ZenKakuGothicAntiqueLight from 'fonts/ZenKakuGothicAntique-Light.ttf'

export const fonts = {
  Quicksand: {
    family: 'Quicksand',
    fonts: [
      {
        src: Quicksand,
        fontWeight: 400,
      },
      {
        src: QuicksandBold,
        fontWeight: 700,
        fontStyle: 'bold',
      },
      {
        src: QuicksandSemiBold,
        fontWeight: 500,
        fontStyle: 'semibold',
      },
    ],
  },
  ZenKakuGothicAntique: {
    family: 'Zen Kaku Gothic Antique',
    fonts: [
      {
        src: ZenKakuGothicAntique,
        fontWeight: 400,
      },
      {
        src: ZenKakuGothicAntiqueBold,
        fontWeight: 700,
        fontStyle: 'bold',
      },
      {
        src: ZenKakuGothicAntiqueSemiBold,
        fontWeight: 500,
        fontStyle: 'semibold',
      },
      {
        src: ZenKakuGothicAntiqueLight,
        fontWeight: 300,
        fontStyle: 'light',
      },
    ],
  },
}
