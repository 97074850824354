import { Box, Typography, InputBase } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})

export const TitleText = styled(Typography)`
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #353f48;
`

export const FilterTextField = styled(InputBase)({
  width: '100%',
  height: '48px',
  minHeight: '48px',
  maxHeight: '48px',
  padding: '8px 24px',
  flex: 1,
  border: '1.5px solid #F6F7FB',
  borderRadius: '32px',
  backgroundColor: '#F6F7FB',
  color: '#596B7B',
  // Typography
  fontFamily: 'Quicksand',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '31px',
  // ------------------------
  '&.Mui-focused, &:hover': {
    border: '1.5px solid #BDC1DD',
    backgroundColor: '#F6F7FB',
  },
})
