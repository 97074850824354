import { Text, View } from '@react-pdf/renderer'
import Title from '../title/title'
import {
  Answer,
  ClinicalOpinion,
  FormRequest,
  PatientReport,
  Question,
  Tag,
} from 'types/patient-report-types'
import { capitalizeWordsOnSentence } from 'helpers'
import { styles } from './results-styles'
import { getFormatedResponseDate } from '../../helpers/date'
import { getFullName } from '../../helpers/name'

interface ResultsProps {
  data?: PatientReport
  isClinicalAvaliation?: boolean
}

enum AnswerValue {
  Absent = '0',
  Indeterminate = '1',
  Present = '2',
}

const symptomps = [
  {
    title: 'Presente',
    backgroundColor: '#FF824C',
  },
  {
    title: 'Indeterminado',
    backgroundColor: '#FFEA8A',
  },
  {
    title: 'Ausente',
    backgroundColor: '#D7F2CF',
  },
]

const percentages = ['0%', '25%', '50%', '75%', '100%']

function Results({ data, isClinicalAvaliation }: ResultsProps) {
  const responders = data?.formRequests
  const hasMultipleTags: boolean = Number(data?.tags?.length) > 1

  const patientFullName = getFullName({
    firstName: data?.patient.personalInfo?.firstName,
    lastName: data?.patient.personalInfo?.lastName,
  })

  const clinicFullName = getFullName({
    firstName: data?.clinic.personalInfo?.firstName,
    lastName: data?.clinic.personalInfo?.lastName,
  })

  const countSymptoms = (
    filteredAnswers: (Answer | ClinicalOpinion | undefined)[] | undefined
  ) => {
    let present = 0,
      indeterminate = 0,
      absent = 0

    if (filteredAnswers?.length) {
      for (const answer of filteredAnswers) {
        switch (answer?.answer) {
          case AnswerValue.Absent:
            absent++
            break
          case AnswerValue.Indeterminate:
            indeterminate++
            break
          case AnswerValue.Present:
            present++
            break
        }
      }
    }

    return { present, indeterminate, absent }
  }

  const filterAnswers = (questions: Question[], formRequestId: string) => {
    return questions.map((question) =>
      question.answers.find((answer) => answer.formRequestId === formRequestId)
    )
  }

  const renderRespondersGraph = (
    responders: FormRequest[],
    questions: Question[]
  ) => {
    return responders?.map((responder, index) => {
      const filteredAnswers = filterAnswers(questions, responder.formRequestId)
      const { present, indeterminate, absent } = countSymptoms(filteredAnswers)

      return (
        <View key={responder.formRequestId}>
          <View style={{ flexDirection: 'row', gap: 4 }}>
            <View style={styles.respondersDot}>
              <Text style={styles.symptomsText}>{index + 1}</Text>
            </View>
            <View style={styles.allSymptomsView}>
              <View
                style={{
                  flex: present,
                  backgroundColor: '#FF824C',
                  ...(indeterminate === 0 &&
                    absent === 0 && {
                      borderTopRightRadius: 2,
                      borderBottomRightRadius: 2,
                    }),
                }}
              />
              <View
                style={{
                  flex: indeterminate,
                  backgroundColor: '#FFEA8A',
                  ...(absent === 0 && {
                    borderTopRightRadius: 2,
                    borderBottomRightRadius: 2,
                  }),
                }}
              />
              <View
                style={{
                  flex: absent,
                  backgroundColor: '#D7F2CF',
                  borderTopRightRadius: 2,
                  borderBottomRightRadius: 2,
                }}
              />
            </View>
          </View>
        </View>
      )
    })
  }

  const renderClinicAvaliationGraph = (tag: Tag) => {
    const clinicalOpinionsByTag = data?.clinicalOpinions?.filter(
      (clinicalOpinion) => clinicalOpinion.tag === tag.id
    )
    const { present, indeterminate, absent } = countSymptoms(
      clinicalOpinionsByTag
    )

    return (
      <View>
        <View style={{ flexDirection: 'row', gap: 4 }}>
          <View style={{ height: 16, width: 10 }} />
          <View style={{ ...styles.allSymptomsView, height: 16 }}>
            <View
              style={{
                flex: present,
                backgroundColor: '#FF824C',
                ...(indeterminate === 0 &&
                  absent === 0 && {
                    borderTopRightRadius: 2,
                    borderBottomRightRadius: 2,
                  }),
              }}
            />
            <View
              style={{
                flex: indeterminate,
                backgroundColor: '#FFEA8A',
                ...(absent === 0 && {
                  borderTopRightRadius: 2,
                  borderBottomRightRadius: 2,
                }),
              }}
            />
            <View
              style={{
                flex: absent,
                backgroundColor: '#D7F2CF',
                borderTopRightRadius: 2,
                borderBottomRightRadius: 2,
              }}
            />
          </View>
        </View>
      </View>
    )
  }

  const renderGraphFooter = () => {
    return (
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }} />
        <View style={{ flex: 3.35 }}>
          <View style={{ flex: 3.35, borderTop: '0.5px solid #DEDEDE' }} />
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            {percentages.map((percentage) => (
              <View key={percentage}>
                <View style={{ ...styles.graphFooterLine }}>
                  <View style={{ width: 18, position: 'relative' }}>
                    <Text
                      style={{
                        ...styles.symptomsText,
                        position: 'absolute',
                        top: 8,
                        right: '60%',
                      }}
                    >
                      {percentage}
                    </Text>
                  </View>
                </View>
              </View>
            ))}
          </View>
        </View>
      </View>
    )
  }

  const renderGraph = () => {
    return (
      <View style={{ flex: 3 }}>
        <View style={{ position: 'relative' }}>
          <View
            style={
              !isClinicalAvaliation
                ? styles.graphLine
                : { ...styles.graphLine, top: -12 }
            }
          />
          <View style={{ gap: 16 }}>
            {data?.tags.map((tag) => (
              <View
                key={tag.id}
                style={{ flexDirection: 'row', alignItems: 'center', gap: 12 }}
              >
                <View style={{ flex: 1 }}>
                  <Text style={{ ...styles.symptomsText, textAlign: 'right' }}>
                    {capitalizeWordsOnSentence(tag.name)}
                  </Text>
                </View>
                <View style={{ flex: 5, gap: 4 }}>
                  {isClinicalAvaliation
                    ? renderClinicAvaliationGraph(tag)
                    : responders &&
                      renderRespondersGraph(responders, tag.questions)}
                </View>
              </View>
            ))}
            {renderGraphFooter()}
          </View>
        </View>
      </View>
    )
  }

  const renderSymptoms = () => {
    return (
      <>
        <Text style={{ ...styles.symptomsTitle, marginBottom: 4 }}>
          Sintomas
        </Text>
        {symptomps.map(({ backgroundColor, title }) => (
          <View key={title} style={styles.symptomsView}>
            <View style={{ ...styles.singleSymptomView, backgroundColor }} />
            <Text style={styles.symptomsText}>{title}</Text>
          </View>
        ))}
      </>
    )
  }

  const renderResponders = () => {
    return (
      <>
        <Text
          style={{ ...styles.symptomsTitle, marginTop: 24, marginBottom: 4 }}
        >
          Respondedores
        </Text>
        <View style={{ gap: 4 }}>
          {responders?.map((responder, index) => (
            <View
              key={responder.formRequestId}
              style={{ flexDirection: 'row', gap: 4 }}
            >
              <View style={styles.respondersDot}>
                <Text style={styles.symptomsText}>{index + 1}</Text>
              </View>
              <Text style={styles.symptomsText}>
                {responder?.sendTo.personalInfo.firstName} (
                {responder.relation?.name.toLocaleLowerCase() ?? 'paciente'}){' '}
                {getFormatedResponseDate(responder.responseDate)}
              </Text>
            </View>
          ))}
        </View>
      </>
    )
  }

  if (!responders) return <></>

  return (
    <>
      <Title
        title={isClinicalAvaliation ? 'Avaliação clínica' : 'Resultados'}
      />

      <View style={{ paddingBottom: 22, paddingHorizontal: 24 }}>
        {isClinicalAvaliation ? (
          <Text style={styles.text}>
            A seguir são apresentados os resultados da avaliação clínica
            conforme a impressão do profissional responsável, {clinicFullName}.
            Cada barra representa um domínio avaliado, com as cores indicando o
            percentual de sintomas observados.
            {hasMultipleTags
              ? ' A quantidade de perguntas varia conforme o domínio.'
              : ''}
          </Text>
        ) : (
          <Text style={styles.text}>
            A seguir é apresentada a percepção de cada respondedor acerca do
            quadro clínico de {patientFullName}, conforme respostas submetidas
            ao Wida durante a avaliação. Cada barra representa um
            respondedor/data, conforme indicado à direita do gráfico.
            {hasMultipleTags
              ? ' A quantidade de perguntas varia conforme o domínio.'
              : ''}
          </Text>
        )}
      </View>

      <View style={styles.contentView}>
        {renderGraph()}
        <View style={{ flex: 1 }}>
          <View>
            {renderSymptoms()}
            {!isClinicalAvaliation && renderResponders()}
          </View>
        </View>
      </View>
    </>
  )
}

export default Results
