import { Control, Controller } from 'react-hook-form'
import Box from '@mui/material/Box'
import { FieldContainer, Label, Input, HelperText } from './text-field-styles'

interface TextFieldProps {
  name: string
  control: Control<any>
  label?: string
  type?: string
  disabled?: boolean
  invalidChars?: string[]
  placeholder?: string
}

function TextField({
  name,
  control,
  label,
  type,
  disabled,
  invalidChars = [],
  placeholder = '',
}: TextFieldProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value },
        fieldState: { invalid, error },
      }) => (
        <Box width='100%'>
          <Box mb={0.5}>
            <Label error={invalid}>{label}</Label>
          </Box>
          <FieldContainer
            disabled={disabled}
            error={invalid}
            variant='standard'
            fullWidth
          >
            <Input
              onChange={
                Boolean(invalidChars.length)
                  ? (value) => {
                      const sanitizedValue = invalidChars.reduce(
                        (acc: string, invalidChar: string) =>
                          acc.replaceAll(invalidChar, ''),
                        value.target.value
                      )
                      return onChange(sanitizedValue)
                    }
                  : onChange
              }
              value={value}
              color='secondary'
              type={type}
              placeholder={placeholder}
            />
            {invalid && (
              <HelperText>{error?.message || 'Erro desconhecido'}</HelperText>
            )}
          </FieldContainer>
        </Box>
      )}
    />
  )
}

export default TextField
