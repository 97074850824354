import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Subtitle = styled(Typography)({
  color: '#949494',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '22px',
})

export const StrongText = styled('span')({
  color: '#596B7B',
  fontWeight: 700,
})

export const Paragraph = styled('p')({
  margin: '16px 0 0 0',
})

export const AssociationText = styled(Typography)({
  color: '#596B7B',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 300,
  lineHeight: '20px',
})

export const DiagnosticTitleText = styled(Typography)({
  fontSize: 11,
  fontFamily: 'Quicksand',
  fontWeight: 700,
  fontStyle: 'bold',
  color: '#D57164',
})
