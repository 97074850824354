import { styled } from '@mui/material/styles'
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector'
import { StepLabel as StepLabelMui } from '@mui/material'

export const Connector = styled(StepConnector)({
  [`&.${stepConnectorClasses.horizontal}`]: {
    display: 'flex',
    justifyContent: 'center',
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#BDBDBD',
    borderTopWidth: '2px',
    borderRadius: '43px',
    width: '80%',
  },
})

export const StepLabel = styled(StepLabelMui)({
  '.MuiStepLabel-label': {
    fontSize: '16px',
    color: '#9D9CB5',
  },
})

export const stepLabelStyle = {
  '&.MuiStepLabel-root': {
    svg: {
      borderRadius: '50%',
      color: '#FFF',
      text: {
        fontSize: '1rem',
        fontWeight: '700',
      },
    },
  },
  '&.MuiStepLabel-root:not(.Mui-disabled)': {
    span: {
      color: '#F36F60',
    },
    svg: {
      backgroundColor: '#F36F60',
      border: '1.5px solid #F36F60',
      text: {
        fill: '#F36F60',
      },
    },
  },
  '&.Mui-disabled': {
    svg: {
      backgroundColor: '#9D9CB5',
      border: '1.5px solid #9D9CB5',
      text: {
        fill: '#9D9CB5',
      },
    },
  },
}
