import client from 'services/client'

export const getTags = async () => {
  try {
    const { data }: any = await client.get('tags')

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const getTagsWithSizes = async () => {
  try {
    const { data }: any = await client.get('questions-tags/tag-sizes')

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}
