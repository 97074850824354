import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { styled } from '@mui/material/styles'
import { Stack, Typography, Box } from '@mui/material'
import { useResultsContext } from 'contexts'
import emptyResultsIcon from 'components/icons/empty-results-icon.svg'

// TODO texto para casos diferentes

function ResultsEmpty(): JSX.Element {
  const { responderOptions, tagOptions } = useResultsContext()
  const { watch } = useFormContext()

  const [selectedResponders, selectedTags] = watch([
    'selectedResponders',
    'selectedTags',
  ])

  const responderName = useMemo(() => {
    if (selectedResponders.length !== 1) return ''

    const findResponder: any = responderOptions.find(({ id }: any) =>
      selectedResponders.includes(id)
    )

    return findResponder?.name || ''
  }, [responderOptions, selectedResponders])

  const responderTag = useMemo(() => {
    if (selectedTags.length !== 1) return ''

    const findTag: any = tagOptions.find(({ value }: any) =>
      selectedTags.includes(value)
    )

    return findTag?.label || ''
  }, [tagOptions, selectedTags])

  return (
    <Container spacing={6}>
      <img
        src={emptyResultsIcon}
        alt='Sem respostas para os filtros selecionados'
      />
      <Box maxWidth='700px' paddingX={4}>
        <EmptyText>
          {!responderName || !responderTag
            ? 'Ops, não há respostas para os filtros selecionados.'
            : `Ops, não há respostas de ${responderName} sobre ${responderTag}.`}{' '}
          Utilize o menu acima para escolher um domínio que contenha respostas.
        </EmptyText>
      </Box>
    </Container>
  )
}

const Container = styled(Stack)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
})

const EmptyText = styled(Typography)({
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '20px',
  lineHeight: '26px',
  textAlign: 'center',
  color: '#353F48',
})

export default ResultsEmpty
