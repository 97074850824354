import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const TitleText = styled(Typography)({
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '21px',
  color: '#657787',
})
