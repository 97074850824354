import { toast } from 'react-toastify'

const useNotification = () => ({
  toast: toast,
  successToast: toast.success,
  errorToast: toast.error,
  infoToast: toast.info,
  warnToast: toast.warn,
})

export default useNotification
