import { StepIconProps } from '@mui/material/StepIcon'
import { StepIconRoot } from './steps-styles'
import { useStepperContext } from 'contexts'

function StepIcon({ active, completed, className }: StepIconProps) {
  const { totalSteps, stepWidth } = useStepperContext()
  return (
    <StepIconRoot
      ownerState={{ active, totalSteps, stepWidth }}
      className={className}
    >
      <div className={completed ? 'StepIcon-completed' : 'StepIcon-circle'} />
    </StepIconRoot>
  )
}

export default StepIcon
