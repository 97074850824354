import { useState, useEffect, useMemo, useCallback } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import { differenceInDays } from 'date-fns'
import { Box, CircularProgress } from '@mui/material'
import { ClinicLayout, PatientCentralLayout } from 'components'
import {
  ResultsHeader,
  TagsContainer,
  ResultDetails,
  QuestionBox,
} from 'components/results'
import { useAuthContext, ResultsContext } from 'contexts'
import {
  fetchPatient,
  getPatientResponders,
  getPatientResponses,
  getInformantResponses,
  getPatientAttentions,
  getTagsWithSizes,
  updateViewedFormRequest,
} from 'services'
import {
  correctsTagsInRespondersWithResponses,
  capitalizeWordsOnSentence,
  filterDataset,
  sortDataset,
} from 'helpers'
import { FETCHING, IDLE, PREVIOUS_THRESHOULD } from 'helpers/constants'
import { generateDataset, orderReponderOptions } from './results-helpers'
import { TagName } from './results-styles'
import { useNotification } from 'hooks'

function Results(): JSX.Element {
  const [status, setStatus] = useState(FETCHING)
  const [patient, setPatient] = useState<any>()
  const [patientAttentions, setPatientAttentions] = useState<any>([])
  const [filterQuestionIds, setFilterQuestionIds] = useState<any>([])
  const [sankeyHighlight, setSankeyHighlight] = useState<any>()
  const [responderOptions, setResponderOptions] = useState<any>([])
  const [tagOptions, setTagOptions] = useState<any>([])
  const [showDetail, setShowDetail] = useState<boolean>(false)
  const [databaseTags, setDatabaseTags] = useState<any>([])
  const [dataset, setDataset] = useState<any>([])
  const [filteredDataset, setFilteredDataset] = useState<any>([])
  // * details states
  const [timeline, setTimeline] = useState<any>([])
  const [visibleArea, setVisibleArea] = useState<number>(7)
  const [timelineVisibleRange, setTimelineVisibleRange] = useState<number[]>([
    0, 7,
  ])

  const { user } = useAuthContext()
  const { patient_id } = useParams()
  const { state } = useLocation()
  const { errorToast } = useNotification()

  const { notification }: any = state || {}

  const methods = useForm({
    defaultValues: {
      activeProportion: true,
      activeSankey: false,
      selectedTags: [],
      selectedResponders: [],
    },
  })
  const { watch, setValue } = methods

  const [selectedResponders, selectedTags]: any = watch([
    'selectedResponders',
    'selectedTags',
  ] as any)

  const trackUsersInfosPayload = useMemo(
    () => ({
      clinic_id: user.clinicId,
      patient_id: patient_id,
    }),
    [user, patient_id]
  )

  const fetchPatientAttentions = useCallback(async () => {
    try {
      if (!patient_id) return

      const patientAttentions = await getPatientAttentions(patient_id)
      setPatientAttentions(patientAttentions)
    } catch (error: any) {
      console.error(error.message)
    }
  }, [patient_id])

  useEffect(() => {
    fetchPatientAttentions()
  }, [fetchPatientAttentions, patient_id])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [patient, responders, databaseTags] = await Promise.all([
          fetchPatient(patient_id as string),
          getPatientResponders(patient_id as string),
          getTagsWithSizes(),
        ])

        const formattedResponders = responders.map((responder: any) => {
          return {
            id: responder.id,
            isPatient: responder.isPatient,
            name: responder.fullName,
            relation:
              !responder.relation?.name || responder.relation?.name === 'Sou eu'
                ? 'paciente'
                : responder.relation?.name?.toLowerCase(),
          }
        })

        const responsesArray = await Promise.all(
          formattedResponders.map(({ id, isPatient }: any) =>
            isPatient
              ? getPatientResponses(id)
              : getInformantResponses(patient_id as string, id)
          )
        )

        const respondersWithResponses = formattedResponders.map(
          (selectedResponder: any, index: number) => {
            const orderedResponseDates = responsesArray[index].sort(
              (respA: any, respB: any) =>
                (new Date(respB.formDateUpdate) as any) -
                (new Date(respA.formDateUpdate) as any)
            )

            const uniqueResponseDates = [
              ...new Set(
                orderedResponseDates.map(
                  (response: any) => response.responseDate
                )
              ),
            ]

            return {
              ...selectedResponder,
              responses: orderedResponseDates,
              responseDates: uniqueResponseDates,
            }
          }
        )

        const correctRespondersWithResponses =
          correctsTagsInRespondersWithResponses(
            respondersWithResponses,
            databaseTags
          )

        const responderOptions = correctRespondersWithResponses.map(
          (respondersWithResponse: any) => {
            const firstName = respondersWithResponse?.name.split(' ')[0]

            return {
              id: respondersWithResponse.id,
              value: respondersWithResponse.id,
              label: `${firstName} (${respondersWithResponse.relation})`,
              relation: respondersWithResponse.relation,
              name: respondersWithResponse?.name,
              responseDates: respondersWithResponse.responseDates,
              isPatient: respondersWithResponse.isPatient,
              old:
                differenceInDays(
                  new Date(),
                  new Date(respondersWithResponse.responseDates[0])
                ) >= PREVIOUS_THRESHOULD,
            }
          }
        )

        // * Criação de um dataset para aplicar filtros, unidade é um conjunto de questões dividido por tag e tem data e respondedor
        const dataset = generateDataset(correctRespondersWithResponses)

        // * define estados do componente
        setPatient(patient)
        setResponderOptions(orderReponderOptions(responderOptions))
        setDatabaseTags(databaseTags)
        setDataset(dataset)

        // * seleciona o paciente/caregiver a partir da notificação
        if (notification) {
          const idToFind =
            notification.payload?.informantId || notification.payload?.patientId

          const findnotificationResponder = responderOptions.find(
            ({ id }: any) => id === idToFind
          )
          if (findnotificationResponder) {
            setValue('selectedResponders', [idToFind] as any)
            return
          }
        }

        // * seleciona o paciente/caregiver e sua resposta mais recente
        const findPatientResponder = responderOptions.find(
          ({ id }: any) => id === patient.patientId
        )

        const selectedResponder =
          findPatientResponder?.id || responderOptions[0]?.id

        setValue('selectedResponders', [selectedResponder] as any)
      } catch (error: any) {
        console.error(error.message)
      } finally {
        setStatus(IDLE)
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_id, notification])

  useEffect(() => {
    // * Change the quantity of graphics at the screen
    const handleResize = () => {
      const visibleArea = Math.ceil((window.innerWidth - 760) / 200)
      setVisibleArea(visibleArea < 1 ? 1 : visibleArea)
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    // * Define tag options for tag selection component
    if (selectedResponders.length === 0) return

    const responderDataset = dataset.filter((data: any) =>
      selectedResponders.includes(data.responder.id)
    )

    const tagOptions = responderDataset.reduce(
      (acc: any, { tag, responseDate }: any) => {
        return acc.some(({ value: tagId }: any) => tagId === tag.id)
          ? acc
          : [
              ...acc,
              {
                value: tag.id,
                label: capitalizeWordsOnSentence(tag.tag),
                newestResponseDate: responseDate,
                old:
                  differenceInDays(new Date(), new Date(responseDate)) >=
                  PREVIOUS_THRESHOULD,
              },
            ]
      },
      []
    )

    setTagOptions(tagOptions)

    if (showDetail) {
      setValue(
        'selectedTags',
        tagOptions.some(({ value }: any) => value === selectedTags[0])
          ? selectedTags
          : [tagOptions[0].value]
      )
    } else {
      setValue(
        'selectedTags',
        tagOptions.every(({ old }: any) => old)
          ? tagOptions.map(({ value }: any) => value)
          : tagOptions
              .filter(({ old }: any) => !old)
              .map(({ value }: any) => value)
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedResponders])

  useEffect(() => {
    // * filter dataset to be displayed
    const filteredDataset = sortDataset(
      filterDataset(dataset, {
        tagIds: selectedTags,
        responderIds: selectedResponders,
      }),
      'ASC'
    )

    setFilteredDataset(filteredDataset)
    setValue('activeSankey', false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataset, selectedResponders, selectedTags])

  useEffect(() => {
    const setFormRequestViewed = async () => {
      try {
        dataset.forEach(
          async (formRequest: any) =>
            !formRequest.viewed &&
            (await updateViewedFormRequest(formRequest.formRequestId))
        )
      } catch (error: any) {
        errorToast(error.message)
      }
    }

    setFormRequestViewed()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataset])

  const selectedTagName = useMemo(() => {
    if (databaseTags?.length === 0 || selectedTags.length !== 1) return ''

    const tag = databaseTags.find(
      (databaseTag: any) => databaseTag.id === selectedTags[0]
    )
    if (!tag) return ''

    return capitalizeWordsOnSentence(tag.name)
  }, [selectedTags, databaseTags])

  const handleGoBackResume = () => {
    setShowDetail(false)
    setValue(
      'selectedTags',
      tagOptions.every(({ old }: any) => old)
        ? tagOptions.map(({ value }: any) => value)
        : tagOptions
            .filter(({ old }: any) => !old)
            .map(({ value }: any) => value)
    )
  }

  if (status !== IDLE) {
    return (
      <Box
        display='flex'
        width='100%'
        height='100vh'
        alignItems='center'
        justifyContent='center'
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <ClinicLayout>
      <FormProvider {...methods}>
        <ResultsContext.Provider
          value={{
            trackUsersInfosPayload,
            patient,
            patientAttentions,
            fetchPatientAttentions,
            setFilterQuestionIds,
            sankeyHighlight,
            setSankeyHighlight,
            showDetail,
            setShowDetail,
            visibleArea,
            responderOptions,
            tagOptions,
            dataset,
            databaseTags,
            filteredDataset,
            timeline,
            setTimeline,
            timelineVisibleRange,
            setTimelineVisibleRange,
            handleGoBackResume,
          }}
        >
          <PatientCentralLayout
            parentPatientAttentions={patientAttentions}
            refetchPatientAttentions={fetchPatientAttentions}
            sidebarSize={showDetail ? 'small' : 'normal'}
          >
            <ResultsHeader />

            {showDetail &&
              Boolean(selectedTags.length === 1) &&
              ((
                <Box ml={2.5} my={3}>
                  <TagName>{selectedTagName}</TagName>
                </Box>
              ) as any)}
            {showDetail ? <ResultDetails /> : <TagsContainer />}
          </PatientCentralLayout>
          {showDetail && (
            <Box width='100%' flexGrow={999}>
              <QuestionBox
                timeline={timeline || []}
                filterQuestionIds={filterQuestionIds}
              />
            </Box>
          )}
        </ResultsContext.Provider>
      </FormProvider>
    </ClinicLayout>
  )
}

export default Results
