import { Paragraph, StrongText, Subtitle } from './tag-texts-styles'

interface auxiliaryTagTextsProps {
  tagName: string
  headerText: () => JSX.Element
  footerText: () => JSX.Element
}

export const auxiliaryTagTexts: auxiliaryTagTextsProps[] = [
  {
    tagName: 'ansiedade generalizada',
    headerText: () => {
      return (
        <Subtitle>
          A ansiedade generalizada é caracterizada por preocupações excessivas,
          persistentes, intensas e de difícil controle, sobre atividades da
          rotina diária, responsabilidades, trabalho, saúde, finanças,
          tragédias, entre outros. As preocupações costumam ser desproporcionais
          à probabilidade real ou ao impacto antecipado do evento.
        </Subtitle>
      )
    },
    footerText: () => {
      return (
        <Subtitle>
          De acordo com o DSM-5-TR, para o diagnóstico de Transtorno de
          Ansiedade Generalizada, devem estar presentes necessariamente{' '}
          <StrongText>sintomas nucleares</StrongText> de ansiedade e
          preocupações excessivas; além disso, o indivíduo deve considerar
          difícil controlar a preocupação. Também devem estar presentes pelo
          menos três <StrongText>sintomas adicionais</StrongText> (um para
          crianças). As <StrongText>perguntas complementares</StrongText>{' '}
          constituem exemplos ou outros fenômenos comumente observados nestes
          quadros clínicos.
        </Subtitle>
      )
    },
  },
  {
    tagName: 'ansiedade social',
    headerText: () => {
      return (
        <Subtitle>
          A ansiedade social é caracterizada por medo ou ansiedade intensa em
          situações nas quais o indivíduo acredita que possa ser avaliado ou
          julgado de forma negativa pelos outros. As situações sociais quase
          sempre provocam medo ou ansiedade, fazendo com que o indivíduo muitas
          vezes evite se envolver nessas atividades.
        </Subtitle>
      )
    },
    footerText: () => {
      return (
        <Subtitle>
          De acordo com o DSM-5-TR, para o diagnóstico de Transtorno de
          Ansiedade Social, devem estar presentes medo, ansiedade ou esquiva
          acentuados em relação a uma ou mais situações sociais. Além disso,
          também deve haver temor de que sintomas de ansiedade demonstrados
          sejam avaliados negativamente. Em crianças, os sintomas devem ocorrer
          em contextos com pares e podem ser expressos através de choro, raiva
          ou retraimento.
        </Subtitle>
      )
    },
  },
  {
    tagName: 'autismo',
    headerText: () => {
      return (
        <Subtitle>
          O espectro autista identifica indivíduos com dificuldades persistentes
          na comunicação e na interação social, que interferem na capacidade de
          iniciar ou manter uma conversa, fazer amigos e desenvolver
          relacionamentos. No espectro autista observam-se ainda padrões
          restritos e repetitivos de comportamento, interesses ou atividades,
          incluindo movimentos ou falas repetitivas, interesses fixos, apego a
          rotinas ou reações incomuns a estímulos sensoriais.
        </Subtitle>
      )
    },
    footerText: () => {
      return (
        <Subtitle>
          De acordo com o DSM-5-TR, para o diagnóstico de Transtorno do Espectro
          Autista, deve haver evidência de déficits nas áreas de{' '}
          <StrongText>reciprocidade socioemocional</StrongText>,{' '}
          <StrongText>comunicação não-verbal</StrongText> e{' '}
          <StrongText>relacionamentos</StrongText>. Além disso, é necessária a
          presença de pelo menos dois dentre quatro tipos de{' '}
          <StrongText>
            padrões de comportamento e interesses restritos ou repetitivos{' '}
          </StrongText>
          (estereotipias, insistência no mesmo/inflexibilidade, hiperfocos e
          alterações sensoriais).
        </Subtitle>
      )
    },
  },
  {
    tagName: 'depressão',
    headerText: () => {
      return (
        <Subtitle>
          A depressão é caracterizada por sentimentos intensos e persistentes de
          tristeza, desesperança, falta de interesse ou prazer nas atividades
          diárias. Retraimento social, irritabilidade, sentimentos de culpa ou
          inutilidade, alterações de sono ou apetite e/ou outras queixas
          somáticas também podem estar presentes.
        </Subtitle>
      )
    },
    footerText: () => {
      return (
        <Subtitle>
          De acordo com o DSM-5-TR, para o diagnóstico de Episódio Depressivo
          Maior, deve estar presente ao menos um dos{' '}
          <StrongText>sintomas nucleares:</StrongText> humor deprimido (no caso
          de crianças e adolescentes, pode ser humor irritável) ou perda de
          interesse/prazer nas atividades. É necessário ainda que um total de
          cinco sintomas estejam presentes na soma entre{' '}
          <StrongText>sintomas nucleares</StrongText> e{' '}
          <StrongText>sintomas adicionais</StrongText>. As{' '}
          <StrongText>perguntas complementares </StrongText>
          constituem exemplos ou outros fenômenos comumente observados nestes
          quadros clínicos.
        </Subtitle>
      )
    },
  },
  {
    tagName: 'desatenção e hiperatividade',
    headerText: () => {
      return (
        <Subtitle>
          <Paragraph>
            A desatenção manifesta-se comportamentalmente como divagação,
            procrastinação, falta de foco, desorganização e baixa persistência.
          </Paragraph>
          <Paragraph>
            A hiperatividade/impulsividade apresenta-se como uma atividade
            motora excessiva, em situações inapropriadas, fazendo com que o
            indivíduo tenha dificuldade em permanecer parado ou sentado,
            remexendo-se e falando em excesso, interrompendo outros ou se
            intrometendo.
          </Paragraph>
        </Subtitle>
      )
    },
    footerText: () => {
      return (
        <Subtitle>
          De acordo com o DSM-5-TR, para o diagnóstico de Transtorno de Déficit
          de Atenção/Hiperatividade, é necessária a presença de ao menos seis
          (ou cinco para adolescentes acima de 17 anos e adultos) sintomas em
          uma das dimensões de <StrongText>desatenção</StrongText> e/ou{' '}
          <StrongText>hiperatividade/impulsividade</StrongText>.
        </Subtitle>
      )
    },
  },
  {
    tagName: 'mania',
    headerText: () => {
      return (
        <Subtitle>
          A mania é um intervalo de tempo caracterizado pela presença de humor
          elevado, expansivo ou irritável e por aumento persistente da atividade
          ou da energia, acompanhado por sintomas que representam uma mudança
          notável em relação ao comportamento habitual do indivíduo.
        </Subtitle>
      )
    },
    footerText: () => {
      return (
        <Subtitle>
          De acordo com o DSM-5-TR, para o diagnóstico de Episódio Maníaco, é
          necessária a presença dos <StrongText>sintomas nucleares</StrongText>{' '}
          de alteração de humor (elevado, expansivo ou irritável) e de aumento
          do nível de energia ou atividade. Além disso, devem estar presentes ao
          menos três (quatro se o humor for apenas irritável){' '}
          <StrongText>sintomas adicionais</StrongText>. Enquanto que em um
          episódio maníaco os sintomas precisam ocorrer por pelo menos uma
          semana consecutiva, em um episódio hipomaníaco a duração mínima
          exigida é de quatro dias. As{' '}
          <StrongText>perguntas complementares</StrongText> constituem exemplos
          ou outros fenômenos comumente observados nestes quadros clínicos.
        </Subtitle>
      )
    },
  },
  {
    tagName: 'obsessões e compulsões',
    headerText: () => {
      return (
        <Subtitle>
          <Paragraph>
            Obsessões são pensamentos, impulsos ou imagens recorrentes e
            persistentes que são vivenciados como intrusivos e indesejados,
            causando intenso sofrimento ou ansiedade; além disso, o indivíduo
            tenta ignorá-los, suprimi-los ou neutralizá-los com algum pensamento
            ou ação.
          </Paragraph>
          <Paragraph>
            Compulsões são comportamentos ou atos mentais repetitivos que o
            indivíduo se sente obrigado a fazer em resposta a uma obsessão ou
            seguindo regras muito estritas; tais comportamentos são realizados
            com o objetivo de mitigar a ansiedade ou o sofrimento, ou ainda para
            evitar uma situação temida, mesmo quando não há uma conexão realista
            com o que se busca prevenir ou quando são desproporcionais à
            situação.
          </Paragraph>
        </Subtitle>
      )
    },
    footerText: () => {
      return (
        <Subtitle>
          De acordo com o DSM-5-TR, para o diagnóstico de Transtorno
          Obsessivo-Compulsivo, é necessária a presença de{' '}
          <StrongText>obsessões</StrongText> e/ou{' '}
          <StrongText>compulsões</StrongText> que tomam tempo (p.ex., mais de
          uma hora por dia) e impactam negativamente a vida do indivíduo.
        </Subtitle>
      )
    },
  },
  {
    tagName: 'oposição e desafio',
    headerText: () => {
      return (
        <Subtitle>
          O comportamento opositor desafiante é caracterizado por um padrão
          frequente e persistente de humor irritável/raivoso, atitudes
          questionadoras/desafiantes ou índole vingativa, fora de uma faixa
          esperada para o nível de desenvolvimento, gênero e cultura do
          indivíduo.
        </Subtitle>
      )
    },
    footerText: () => {
      return (
        <Subtitle>
          De acordo com o DSM-5-TR, para o diagnóstico de Transtorno de Oposição
          Desafiante, é necessária a ocorrência frequente de um total de ao
          menos quatro sintomas de qualquer das categorias acima.
        </Subtitle>
      )
    },
  },
]
