import { useEffect, useState, SyntheticEvent, useRef } from 'react'
import { Box, Stack, Tab } from '@mui/material'
import {
  ButtonClinic,
  ClinicLayout,
  SecondaryConfirmationModal,
} from 'components'
import { useAuthContext, ClinicContext } from 'contexts'
import { getClinicInfo } from 'services'
import { registerPageAccess, registerTrack } from 'helpers'
import { FETCHING, IDLE } from 'helpers/constants'
import { formatClinicFromBack } from 'helpers/formatters'
import { useNotification } from 'hooks'
import { tabs } from './my-profile-constants'
import {
  Tabs,
  LabelText,
  ChangeTabModalTitleText,
  ChangeTabModalSubtitleText,
} from './my-profile-styles'
import { useLocation } from 'react-router-dom'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ flexGrow: value !== index ? 0 : 1, display: 'flex' }}
      {...other}
    >
      {value === index && (
        <Box py={2} display='flex' flexDirection='column' flexGrow={1}>
          {children}
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

function MyProfile(): JSX.Element {
  const [isEdit, setIsEdit] = useState(false)
  const [status, setStatus] = useState(FETCHING)
  const [clinic, setClinic] = useState<any>({})
  const [activeTab, setActiveTab] = useState<number>(0)
  const [nextTab, setNextTab] = useState<number>(-1)
  const [openChangeTabModal, setOpenChangeTabModal] = useState<boolean>(false)

  const { user } = useAuthContext()
  const { errorToast } = useNotification()
  const { state } = useLocation()
  const selectedTab = (state as any)?.tab

  const findTab = tabs.findIndex((tab: any) => tab.label === selectedTab)
  const initialTab = findTab === -1 ? 0 : findTab

  const handleSave = useRef(() => false)
  const hasUnsavedChanges = useRef(false)

  const fetchClinic = async () => {
    try {
      setStatus(FETCHING)
      const clinic = await getClinicInfo()
      setClinic(formatClinicFromBack(clinic))
    } catch (error: any) {
      errorToast(error.message)
    } finally {
      setStatus(IDLE)
    }
  }

  useEffect(() => {
    fetchClinic()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    registerPageAccess('Perfil Clinico', { clinic_id: user.clinicId })
  }, [user])

  useEffect(() => {
    hasUnsavedChanges.current = false
  }, [isEdit])

  useEffect(() => {
    setActiveTab(initialTab)
  }, [initialTab])

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    if (!hasUnsavedChanges.current) {
      setIsEdit(false)
      setActiveTab(newValue)
      return
    }

    setOpenChangeTabModal(true)
    setNextTab(newValue)
  }

  const handleEditClick = async () => {
    if (isEdit) {
      const save: boolean = await handleSave.current()
      if (!save) return
    }

    setIsEdit(!isEdit)
  }

  if (status !== IDLE) return <></>

  return (
    <ClinicLayout>
      <Box
        mt={2}
        mr={1}
        display='flex'
        height='100%'
        flexGrow={1}
        flexDirection='column'
      >
        <Box
          display='flex'
          flexDirection='column'
          flexGrow={1}
          height='100%'
          width='100%'
        >
          <Box
            px={2}
            py={1}
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <Tabs
              TabIndicatorProps={{
                style: {
                  backgroundColor: 'transparent',
                  borderRadius: '4px',
                },
              }}
              value={activeTab}
              onChange={handleChange}
            >
              {tabs.map(({ icon, label }: any, index: number) => (
                <Tab
                  key={label}
                  label={
                    <Stack direction='row' spacing={1} alignItems='center'>
                      {icon({
                        color: activeTab === index ? '#EE7262' : '#657787',
                      })}
                      <LabelText>{label}</LabelText>
                    </Stack>
                  }
                  onClick={() => {
                    registerTrack(`Clica ${label}`, {
                      clinic_id: user.clinicId,
                    })
                  }}
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
            {tabs[activeTab]?.editButtonStatus !== 'hidden' && (
              <ButtonClinic
                width={178}
                height={40}
                variant='outlined'
                color='purple'
                fontWeight='bold'
                size='small'
                icon='edit'
                iconPosition='start'
                onClick={handleEditClick}
                disabled={tabs[activeTab]?.editButtonStatus === 'disabled'}
              >
                {isEdit ? 'Salvar alterações' : 'Editar dados'}
              </ButtonClinic>
            )}
          </Box>
          <Box display='flex' flexDirection='column' flexGrow={1} height='100%'>
            <ClinicContext.Provider
              value={{
                clinic,
                fetchClinic,
                isEdit,
                handleSave,
                hasUnsavedChanges,
              }}
            >
              {tabs.map(({ component, label }: any, index: number) => (
                <TabPanel key={label} value={activeTab} index={index}>
                  {component}
                </TabPanel>
              ))}
            </ClinicContext.Provider>
          </Box>
        </Box>

        <SecondaryConfirmationModal
          isOpen={openChangeTabModal}
          title='Atenção'
          hideButtons
          onClose={() => setOpenChangeTabModal(false)}
          content={
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              alignItems='center'
              flexGrow={1}
            >
              <Box mt={2}>
                <ChangeTabModalTitleText>
                  As alterações serão perdidas se você não salvá-las
                </ChangeTabModalTitleText>
                <ChangeTabModalSubtitleText>
                  Utilize o botão no canto superior direito para salvar
                </ChangeTabModalSubtitleText>
              </Box>
              <Stack
                direction='row'
                alignSelf='center'
                justifySelf='flex-end'
                spacing={4}
                justifyContent='space-between'
                mb={3}
              >
                <ButtonClinic
                  width={200}
                  variant='outlined'
                  color='purple'
                  fontWeight='bold'
                  size='small'
                  onClick={() => {
                    setIsEdit(false)
                    setActiveTab(nextTab)
                    setNextTab(-1)
                    setOpenChangeTabModal(false)
                  }}
                  type='button'
                >
                  Descartar alterações
                </ButtonClinic>

                <ButtonClinic
                  width={200}
                  variant='contained'
                  color='purple'
                  fontWeight='bold'
                  size='small'
                  onClick={() => setOpenChangeTabModal(false)}
                >
                  Voltar para edição
                </ButtonClinic>
              </Stack>
            </Box>
          }
        />
      </Box>
    </ClinicLayout>
  )
}

export default MyProfile
