import { Stack, IconButton } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import {
  Status,
  InformantName,
  InformantRelation,
} from './informant-list-item-styles'

function InformantListItem({
  item,
  onClick,
  removeButton = false,
}: any): JSX.Element {
  return (
    <Stack
      key={item.id}
      direction='row'
      justifyContent='space-between'
      alignItems='center'
    >
      <Stack>
        <InformantName>{item.label}</InformantName>
        {item.type === 'informant' && (
          <InformantRelation>{item.relation}</InformantRelation>
        )}
      </Stack>
      <Stack direction='row' alignItems='center'>
        <Status bgColor={item.status.color}>{item.status.label}</Status>
        {!removeButton && (
          <IconButton onClick={onClick} disabled={item.status.disabledButton}>
            <SendIcon
              sx={{
                fill: item.status.disabledButton ? '#BDBDBD' : '#F36F60',
              }}
            />
          </IconButton>
        )}
      </Stack>
    </Stack>
  )
}

export default InformantListItem
