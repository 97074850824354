import Stack from '@mui/material/Stack'
import { TIMELINE_SPACING } from 'helpers/constants'
import Gradient from 'components/results/gradient/gradient'

function AnswersTimelineGradient({ question }: any): JSX.Element {
  return (
    <Stack
      zIndex={1}
      direction='row'
      alignItems='center'
      spacing={TIMELINE_SPACING}
    >
      <Gradient answers={question.answers} />
    </Stack>
  )
}
export default AnswersTimelineGradient
