import { Box, Container, IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import LogoutOutlined from '@mui/icons-material/LogoutOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { useAuthContext } from 'contexts'
import logoColor from 'images/wida-logo-color.svg'
import logoBW from 'images/wida-logo.svg'

interface HeaderProps {
  color?: boolean
  hasGoBackButton?: boolean
  hasGoForwardButton?: boolean
  hasProfileButton?: boolean
  hasLogoutButton?: boolean
  handleBack?: () => void
  handleForward?: () => void
  disabledGoHome?: boolean
  removeLogo?: boolean
}

function Header({
  handleBack = () => {},
  handleForward = () => {},
  hasGoBackButton,
  hasGoForwardButton,
  hasProfileButton,
  hasLogoutButton,
  color,
  disabledGoHome = false,
  removeLogo = false,
}: HeaderProps): JSX.Element {
  const navigate = useNavigate()
  const { signout } = useAuthContext()

  const handleProfile = () => navigate('/settings')

  const handleHome = () => navigate('/')

  const handleSignOut = () => signout('patient')

  return (
    <Container maxWidth='xs'>
      <Box display='flex' alignItems='center' height='100px'>
        <Box
          sx={{
            flex: '1',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {hasGoBackButton && (
            <IconButton onClick={handleBack} sx={{ marginRight: 'auto' }}>
              <ArrowBackIosNewIcon sx={{ fill: color ? '#657787' : 'white' }} />
            </IconButton>
          )}

          {hasProfileButton && (
            <IconButton onClick={handleProfile} sx={{ marginRight: 'auto' }}>
              <SettingsOutlinedIcon
                sx={{ fill: color ? '#657787' : 'white' }}
              />
            </IconButton>
          )}
        </Box>
        {removeLogo ? (
          <Box />
        ) : (
          <img
            src={color ? logoColor : logoBW}
            height='20px'
            alt='Logo Wida'
            onClick={disabledGoHome ? () => {} : handleHome}
            style={{
              cursor: disabledGoHome ? 'default' : 'pointer',
              flex: '1',
              display: 'flex',
              justifyContent: 'center',
              margin: '5px 0 0 0',
            }}
          />
        )}
        <Box
          style={{
            flex: '1',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {hasGoForwardButton && (
            <IconButton onClick={handleForward} sx={{ marginLeft: 'auto' }}>
              <ArrowForwardIosIcon sx={{ fill: color ? '#657787' : 'white' }} />
            </IconButton>
          )}

          {hasLogoutButton && (
            <IconButton onClick={handleSignOut} sx={{ marginLeft: 'auto' }}>
              <LogoutOutlined sx={{ fill: color ? '#657787' : 'white' }} />
            </IconButton>
          )}
        </Box>
      </Box>
    </Container>
  )
}

export default Header
