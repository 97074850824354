import { CSSProperties } from 'react'

interface LeftArrowIconProps {
  color?: string
  style?: CSSProperties
}

const LeftArrowIcon = ({ color = '#596B7B', style }: LeftArrowIconProps) => {
  return (
    <svg
      width='5'
      height='9'
      viewBox='0 0 5 9'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={style}
    >
      <path
        id='Previous'
        d='M4.18165 1.0996L0.78125 4.5L4.18165 7.9004'
        stroke={color}
        stroke-width='1.20222'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export default LeftArrowIcon
