import { useMemo } from 'react'
import { Box, Stack } from '@mui/material'
import { ButtonClinic } from 'components'
import { useInvitePatientContext } from 'contexts'
import { Title, SupportText } from '../styles/common-styles'

function QuestionsPersonStep(): JSX.Element {
  const { goNextStep, setNewQuestionnaireInfos, newQuestionnaireInfos } =
    useInvitePatientContext()

  const handleSelectPerson = (questionsPerson: string) => {
    setNewQuestionnaireInfos((newQuestionnaireInfos: any) => ({
      ...newQuestionnaireInfos,
      questionsPerson,
    }))

    goNextStep()
  }

  const isFemale = useMemo(
    () => newQuestionnaireInfos.questionsPronoun === 'she',
    [newQuestionnaireInfos.questionsPronoun]
  )

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      flexGrow={1}
    >
      <Title>Qual versão de perguntas você prefere</Title>
      <Title>visualizar enquanto cria o questionário?</Title>

      <Stack direction='row' my={6} alignItems='flex-start' spacing={3}>
        <ButtonClinic
          variant='outlined'
          size='large'
          color='purple'
          fontWeight='regular'
          width={400}
          onClick={() => handleSelectPerson('first')}
        >
          <span>
            <b>Você</b> tem se sentido cansad{isFemale ? 'a' : 'o'}?
          </span>
        </ButtonClinic>
        <ButtonClinic
          variant='outlined'
          size='large'
          color='purple'
          fontWeight='regular'
          width={400}
          onClick={() => handleSelectPerson('third')}
        >
          <span>
            <b>El{isFemale ? 'a' : 'e'}</b> tem se sentido cansad
            {isFemale ? 'a' : 'o'}?
          </span>
        </ButtonClinic>
      </Stack>

      <SupportText>
        No Wida, todas as perguntas possuem versões para o próprio paciente
        (você) e para terceiros (ela/ele).
      </SupportText>
      <SupportText>
        Fique tranquilo, nossa plataforma identifica automaticamente qual versão
        usar em cada situação.
      </SupportText>
    </Box>
  )
}

export default QuestionsPersonStep
