import { Box } from '@mui/material'
import Button from 'components/button/button'
import RouteLayout from 'components/route-layout/route-layout'
import { secondaryButton, SecondaryText } from '../start-questionnaire-styles'

function SecondComponent({ type, handleGoNext, patientInfo }: any) {
  return (
    <RouteLayout bg='default'>
      <Box
        m={5}
        flexGrow={1}
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        alignItems='center'
      >
        <Box />
        <Box>
          {type === 'patient' ? (
            <>
              <Box my={3}>
                <SecondaryText>
                  Não existem respostas certas ou erradas.
                </SecondaryText>
              </Box>
              <Box my={3}>
                <SecondaryText>
                  O objetivo é entender como você se sente.
                </SecondaryText>
              </Box>
            </>
          ) : (
            <>
              <Box my={3}>
                <SecondaryText>
                  Não existem respostas certas ou erradas.
                </SecondaryText>
              </Box>
              <Box my={3}>
                <SecondaryText>
                  O objetivo é entender como você percebe{' '}
                  <b>{patientInfo?.firstName || '_'}</b>.
                </SecondaryText>
              </Box>
            </>
          )}
        </Box>
        <Box width={223}>
          <Button onClick={handleGoNext} sx={secondaryButton}>
            Próximo
          </Button>
        </Box>
      </Box>
    </RouteLayout>
  )
}

export default SecondComponent
