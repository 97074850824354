import { Stack, Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import patientCoverIcon from 'components/icons/patient-cover-icon.svg'
import { CoverText } from './sign-up-caregiver-steps-styles'

function PatientCoverStep(): JSX.Element {
  const { watch } = useFormContext()

  const patientFirstName = watch('firstName')

  return (
    <Stack
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img src={patientCoverIcon} height='80px' alt='Ícone de paciente' />
      <Box mt={2}>
        <CoverText>
          Agora, precisamos de algumas informações sobre {patientFirstName}{' '}
          (paciente)
        </CoverText>
      </Box>
    </Stack>
  )
}

export default PatientCoverStep
