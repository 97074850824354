import {
  Box,
  ButtonBase,
  Typography,
  Checkbox as CheckboxMui,
} from '@mui/material'
import { styled } from '@mui/material/styles'

export const AddNewResponderButton = styled(ButtonBase)({
  borderRadius: '12px',
  background: '#F9F9FB',
  height: '48px',

  color: '#8D92BE',
  fontFamily: 'Quicksand',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,

  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',

  '&:disabled': {
    color: '#CBCCCF',
  },
})

export const Container = styled(Box)({
  flexGrow: 1,
  padding: '32px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
})

export const Title = styled(Typography)({
  color: '#8D92BE',
  textAlign: 'center',
  fontFamily: 'Quicksand',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '37px',
})

export const Subtitle = styled(Typography)({
  color: '#949494',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '22px',
})

export const Label = styled(Typography)({
  color: '#596B7B',
  fontFamily: 'Quicksand',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
})

export const TagLabel = styled(Typography)({
  color: 'rgba(0, 0, 0, 0.87)',
  fontFamily: 'Quicksand',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
})

export const Checkbox = styled(CheckboxMui)({
  marginY: '3px',
  p: '8px',
  color: 'rgba(0, 0, 0, 0.6)',
  '&.Mui-checked': {
    color: '#8D92BE',
  },
})
