import { useMemo } from 'react'
import { Box, Stack } from '@mui/material'
import { ButtonClinic } from 'components'
import { useInvitePatientContext } from 'contexts'
import { Title, SupportText } from '../styles/common-styles'

function QuestionsPronounStep(): JSX.Element {
  const { goNextStep, setNewQuestionnaireInfos, newQuestionnaireInfos } =
    useInvitePatientContext()

  const patientName = useMemo(() => {
    return newQuestionnaireInfos.patientName?.split(' ')[0] || 'o paciente'
  }, [newQuestionnaireInfos.patientName])

  const handleSelectPronoun = (questionsPronoun: string) => {
    setNewQuestionnaireInfos((newQuestionnaireInfos: any) => ({
      ...newQuestionnaireInfos,
      questionsPronoun,
    }))

    goNextStep()
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      flexGrow={1}
    >
      <Title>As perguntas sobre {patientName} devem ser formuladas no</Title>

      <Stack direction='row' my={6} alignItems='flex-start' spacing={3}>
        <ButtonClinic
          variant='outlined'
          size='large'
          color='purple'
          fontWeight='regular'
          width={300}
          onClick={() => handleSelectPronoun('she')}
        >
          Feminino
        </ButtonClinic>
        <ButtonClinic
          variant='outlined'
          size='large'
          color='purple'
          fontWeight='regular'
          width={300}
          onClick={() => handleSelectPronoun('he')}
        >
          Masculino
        </ButtonClinic>
      </Stack>

      <SupportText>
        Os questionários são personalizados para cada paciente,
      </SupportText>
      <SupportText>
        podendo as perguntas aparecer no feminino ou no masculino.
      </SupportText>
    </Box>
  )
}

export default QuestionsPronounStep
