import client from 'services/client'

export const createUserQuestionnaires = async (body: any) => {
  try {
    const { data }: any = await client.post('/invites', body)

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const getUserQuestionnaire = async () => {
  try {
    const { data }: any = await client.get('/form-requests?requestedByMe=false')

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const answerUserQuestionnaire = async (body: any) => {
  try {
    await client.post('/questions-answers', body)
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const getLastForm = async (lastFormId: any) => {
  try {
    const { data }: any = await client.get(`/forms/${lastFormId}`)

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}
