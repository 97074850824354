import { Box } from '@mui/material'
import Button from 'components/button/button'
import RouteLayout from 'components/route-layout/route-layout'
import { sxButton, Text } from './start-questionnaire-styles'

function Return({ type, handleGoNext, patientInfo }: any) {
  return (
    <RouteLayout bg='gradient'>
      <Box
        m={5}
        flexGrow={1}
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        alignItems='center'
      >
        <Box />
        <Box>
          {type === 'patient' ? (
            <>
              <Box my={3}>
                <Text>Como você tem se sentido recentemente?</Text>
              </Box>
              <Box my={3}>
                <Text>
                  Lembre-se de que não existem respostas certas ou erradas.
                </Text>
              </Box>
              <Box my={3}>
                <Text>Vamos lá?</Text>
              </Box>
            </>
          ) : (
            <>
              <Box my={3}>
                <Text>
                  Como você tem percebido <b>{patientInfo?.firstName || '_'}</b>{' '}
                  recentemente?
                </Text>
              </Box>
              <Box my={3}>
                <Text>
                  Lembre-se de que não existem respostas certas ou erradas.
                </Text>
              </Box>
              <Box my={3}>
                <Text>Vamos lá?</Text>
              </Box>
            </>
          )}
        </Box>
        <Box width={223}>
          <Button color='white' onClick={handleGoNext} sx={sxButton}>
            Começar
          </Button>
        </Box>
      </Box>
    </RouteLayout>
  )
}

export default Return
