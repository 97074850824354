import { useEffect, useMemo } from 'react'
import { Box, Grid, Stack } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { TextFieldClinic, ButtonClinic, SelectStyled } from 'components'
import { useSignUpClinicContext } from 'contexts'
import { generateOptions, registerPageAccess } from 'helpers'
import { professionalCouncils, states } from 'helpers/constants'
import { Title } from './sign-up-clinic-steps-styles'

function ProfessionalAndContactInfoStep(): JSX.Element {
  const { control } = useFormContext()
  const { goBackStep, goNextStep, loading } = useSignUpClinicContext()

  useEffect(() => {
    registerPageAccess('Cadastro clínico: terceira página')
  }, [])

  const professionalCouncilsOptions = useMemo(
    () => generateOptions(professionalCouncils),
    []
  )

  return (
    <Box
      flexGrow={1}
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
    >
      <Box>
        <Box mb={{ xs: 3, sm: 4, lg: 3 }}>
          <Title>Dados profissionais</Title>
        </Box>
        <Grid container width='100%' spacing={2}>
          <Grid item xs={12} md={4} mb={{ xs: 3 }}>
            <SelectStyled
              label='Conselho profissional'
              name='professionalCouncil'
              control={control}
              options={professionalCouncilsOptions}
            />
          </Grid>

          <Grid item xs={12} md={4} mb={{ xs: 3 }}>
            <SelectStyled
              label='Estado'
              name='professionalCouncilState'
              control={control}
              options={states}
            />
          </Grid>

          <Grid item xs={12} md={4} mb={{ xs: 3 }}>
            <TextFieldClinic
              name='register'
              control={control}
              label='Número de inscrição'
            />
          </Grid>
        </Grid>

        <Box mt={{ xs: 1, md: 2, lg: 5}} mb={3}>
          <Title>Dados de contato</Title>
        </Box>
        <Grid container width='100%' spacing={1}>
          <Grid item xs={12} md={6} mb={2}>
            <TextFieldClinic
              mask='(00) 00000-0000'
              name='whatsapp'
              control={control}
              label='Telefone'
            />
          </Grid>
          <Grid item xs={12} md={6} mb={2}>
            <TextFieldClinic
              name='email'
              control={control}
              label='E-mail'
              invalidChars={[' ']}
            />
          </Grid>
        </Grid>
      </Box>
      <Stack
        width='100%'
        direction='row'
        spacing={{ xs: 1.5, sm: 6 }}
        justifyContent={{ xs: 'center', sm: 'center', md: 'center', lg: 'space-between'}}
        alignItems='center'
        mt={4}
      >
        <ButtonClinic
          width={150}
          variant='outlined'
          size='small'
          color='purple'
          fontWeight='bold'
          type='button'
          onClick={goBackStep}
          disabled={loading}
        >
          Voltar
        </ButtonClinic>

        <ButtonClinic
          width={150}
          variant='contained'
          size='small'
          color='purple'
          fontWeight='bold'
          type='button'
          onClick={goNextStep}
          loading={loading}
        >
          Próximo
        </ButtonClinic>
      </Stack>
    </Box>
  )
}

export default ProfessionalAndContactInfoStep
