import { createContext, useContext } from 'react'

type PatientType = 'you' | 'he' | 'she'

interface SignUpPatientContextType {
  goNextStep: () => void
  patient: PatientType
  patientName: string
  type: string
  sendValidationCode: (arg0: any, arg1: any) => void
  loadingSendCode: boolean
}

export const SignUpPatientContext = createContext<SignUpPatientContextType>({
  goNextStep: () => {},
  patient: 'you',
  patientName: '',
  type: '',
  sendValidationCode: () => {},
  loadingSendCode: false,
})

export const useSignUpPatientContext = () => {
  const context = useContext(SignUpPatientContext)
  return context
}
