import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const NotificationChoiceText = styled(Typography)({
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '18px',
  color: '#657787',
})

export const ErrorMessage = styled(Typography)({
  fontFamily: 'Zen Kaku Gothic Antique',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  color: '#d32f2f',
})

export const ResendButton = styled('span')<any>(({ disabled }) => ({
  fontFamily: 'Zen Kaku Gothic Antique',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '23px',
  textDecorationLine: 'underline',
  color: '#636363',
  cursor: disabled ? 'default' : 'pointer',
}))
