interface IResultsIcon {
  color: string
}

const ResultsIcon = ({ color }: IResultsIcon) => {
  return (
    <svg
      width='20'
      height='24'
      viewBox='0 0 20 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='2.625'
        y='1.125'
        width='5.75'
        height='18.75'
        rx='2.875'
        stroke={color}
        stroke-width='1.25'
      />
      <rect
        x='11.625'
        y='4.125'
        width='5.75'
        height='18.75'
        rx='2.875'
        stroke={color}
        stroke-width='1.25'
      />
      <line
        x1='1'
        y1='11.875'
        x2='20'
        y2='11.875'
        stroke={color}
        stroke-width='1.25'
        stroke-dasharray='1.67 0.83'
      />
    </svg>
  )
}

export default ResultsIcon
