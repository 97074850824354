import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const InformantName = styled(Typography)({
  fontFamily: 'Zen Kaku Gothic Antique',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '23px',
  color: '#657787',
})

export const InformantRelation = styled(Typography)({
  fontFamily: 'Zen Kaku Gothic Antique',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: '17px',
  color: '#657787',
})
