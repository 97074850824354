import { Box, Typography } from '@mui/material'
import noPatientsImage from 'images/no-patients.svg'

function PatientListEmpty(): JSX.Element {
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      flexGrow={1}
      height='100%'
    >
      <Box mb={4}>
        <img
          src={noPatientsImage}
          height='40px'
          alt='Lista de pacientes vazia'
        />
      </Box>
      <Box mb={1}>
        <Typography
          variant='text'
          color='#949494'
          fontFamily='Quicksand'
          fontSize='20px'
          fontWeight='500'
          lineHeight='36.5px'
        >
          Bem-vindo ao Wida!
        </Typography>
      </Box>
      <Box mb={3} display='flex' flexDirection='column' alignItems='center'>
        <Typography
          variant='text'
          textAlign='center'
          color='#949494'
          fontFamily='Zen Kaku Gothic Antique'
          fontSize='18px'
          lineHeight='24.5px'
        >
          Clique no botão verde e convide agora mesmo seus <br />
          pacientes para responder a questionários personalizados.
        </Typography>
      </Box>
    </Box>
  )
}

export default PatientListEmpty
