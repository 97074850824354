const ContactDataIcon = ({ color = '#657787' }: { color?: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z'
        fill={color}
      />
      <path
        d='M15 13C15.5523 13 16 12.5523 16 12C16 11.4477 15.5523 11 15 11C14.4477 11 14 11.4477 14 12C14 12.5523 14.4477 13 15 13Z'
        fill={color}
      />
      <path
        d='M9 13C9.55228 13 10 12.5523 10 12C10 11.4477 9.55228 11 9 11C8.44772 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13Z'
        fill={color}
      />
      <path
        d='M17.6099 6.35382C16.3035 5.03697 14.5793 4.21785 12.7346 4.03767C10.8898 3.85748 9.04011 4.32754 7.50432 5.36679C5.96852 6.40604 4.8429 7.94936 4.3215 9.73068C3.80011 11.512 3.91562 13.4197 4.64812 15.1249C4.72447 15.2833 4.74952 15.4617 4.71978 15.6352L4.01914 19.008C3.99215 19.1374 3.99766 19.2714 4.03518 19.398C4.07271 19.5247 4.14106 19.64 4.23411 19.7336C4.31039 19.8095 4.40121 19.8691 4.5011 19.9088C4.60098 19.9485 4.70787 19.9676 4.81532 19.9649H4.97456L8.38219 19.2791C8.55538 19.2583 8.73101 19.283 8.89174 19.3509C10.5944 20.0845 12.4992 20.2002 14.2779 19.678C16.0565 19.1558 17.5976 18.0285 18.6353 16.4904C19.673 14.9524 20.1423 13.0999 19.9624 11.2524C19.7825 9.40484 18.9646 7.67808 17.6497 6.36977L17.6099 6.35382ZM18.2707 13.0198C18.115 13.9719 17.7458 14.8761 17.1907 15.6646C16.6357 16.4531 15.9093 17.1052 15.0662 17.5719C14.2231 18.0386 13.2854 18.3076 12.3234 18.3589C11.3615 18.4102 10.4005 18.2423 9.51276 17.8678C9.19791 17.7337 8.85968 17.6632 8.51754 17.6605C8.36808 17.6615 8.21896 17.6749 8.07168 17.7004L5.82646 18.1549L6.28029 15.9063C6.37067 15.4193 6.31241 14.9164 6.11309 14.463C5.7392 13.574 5.57153 12.6115 5.62272 11.6482C5.6739 10.6848 5.9426 9.74562 6.40858 8.90129C6.87457 8.05696 7.52571 7.32948 8.313 6.7736C9.10029 6.21771 10.0032 5.84791 10.9538 5.69201C11.9517 5.528 12.9741 5.60428 13.9367 5.91452C14.8993 6.22477 15.7742 6.76007 16.4892 7.47615C17.2042 8.19222 17.7387 9.0685 18.0485 10.0325C18.3583 10.9965 18.4345 12.0205 18.2707 13.0198Z'
        fill={color}
      />
    </svg>
  )
}

export default ContactDataIcon
