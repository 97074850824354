import { useEffect } from 'react'
import { Box, Stack } from '@mui/material'
import { SubmitHandler } from 'react-hook-form'
import { useNavigate, useLocation } from 'react-router-dom'
import { Button, RouteLayout, HeaderLoginPatientGroup } from 'components'
import { InputField } from 'components/inputs'
import { useAuthContext } from 'contexts'
import { useNotification } from 'hooks'
import { registerPageAccess, sanitizeString } from 'helpers'
import { PATIENT } from 'helpers/constants'
import { IFormInput } from './sign-in-patient-group-types'
import useFormSignIn from './sign-in-patient-group-form'
import {
  LoginContainer,
  Title,
  InviteClinicText,
  ForgetPasswordLink,
  GradientLink,
} from './sign-in-patient-group-styles'

function SignInPatientGroup(): JSX.Element {
  const { control, handleSubmit, watch } = useFormSignIn()
  const navigate = useNavigate()
  const { signin } = useAuthContext()
  const { errorToast } = useNotification()

  const location: any = useLocation()
  const cpf = watch('cpf')
  const pass = watch('password')

  useEffect(() => {
    registerPageAccess('Login')
  }, [])

  const onSubmit: SubmitHandler<IFormInput> = async (params) => {
    try {
      const body = {
        cpf: sanitizeString(params.cpf),
        password: params.password,
      }
      await signin(body, PATIENT)
      const from = (location?.state?.from?.pathname as string) || '/'
      navigate(from, { replace: true })
    } catch (error: any) {
      console.error(error)
      errorToast(error.message)
    }
  }

  return (
    <RouteLayout bg='gradient'>
      <HeaderLoginPatientGroup />

      <LoginContainer component='form' onSubmit={handleSubmit(onSubmit)}>
        <Box mb={3}>
          <Title>Login</Title>
        </Box>

        <InputField
          name='cpf'
          control={control}
          label='CPF'
          mask='000.000.000-00'
        />
        <InputField
          name='password'
          control={control}
          type='password'
          label='Senha'
        />
        <Box mb={5} display='flex' justifyContent='flex-end'>
          <ForgetPasswordLink
            onClick={() => navigate('/password_reset/patient')}
          >
            Esqueci minha senha
          </ForgetPasswordLink>
        </Box>
        <Button disabled={!cpf || !pass} type='submit'>
          Entrar
        </Button>
        <Stack mt={10} alignItems='center'>
          <InviteClinicText>Não tem uma conta? </InviteClinicText>
          <GradientLink onClick={() => navigate('/invite_clinic')}>
            Sou paciente e gostaria de usar
          </GradientLink>
        </Stack>
      </LoginContainer>
    </RouteLayout>
  )
}

export default SignInPatientGroup
