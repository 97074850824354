import { useState } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  ButtonClinic,
  MedicalRecord,
  SecondaryConfirmationModal,
} from 'components'
import { RichTextEditor } from 'components/inputs'
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from '@mui/lab'
import { AddCircleOutlined, CloseOutlined } from '@mui/icons-material'

function RecordTimeline({
  onSaveRecord,
  onDeleteRecord,
  records,
  draft,
  setDraft,
}: any): JSX.Element {
  const [loading, setLoading] = useState(false)
  const [textOpen, setTextOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const saveRecord = async (data: any, close: boolean = false) => {
    if (close) {
      setLoading(true)
      await onSaveRecord(data, close)
      setTextOpen(false)
      return setLoading(false)
    }
    await onSaveRecord(data)
  }

  const confirmCloseModal = async () => {
    setLoading(true)
    setTextOpen(false)
    setModalOpen(false)
    if (draft) {
      await onDeleteRecord(draft.id)
      setDraft(null)
    }
    setLoading(false)
  }

  return (
    <Timeline
      sx={{
        paddingBottom: '30px',
        '& li:before': {
          content: 'none',
        },
      }}
    >
      <TimelineItem>
        <TimelineSeparator sx={{ marginLeft: '30px' }}>
          <Box display={'flex'}>
            <TimelineDot
              sx={{
                backgroundColor: 'transparent',
                boxShadow: 'none',
                margin: '5px 1px 10px 1px',
              }}
            >
              {textOpen || draft ? (
                <CloseOutlined
                  onClick={() => setModalOpen(true)}
                  sx={{
                    fontSize: '30px',
                    color: '#F36F60',
                    padding: 0,
                    cursor: 'pointer',
                  }}
                />
              ) : (
                <AddCircleOutlined
                  onClick={() => setTextOpen(true)}
                  sx={{
                    fontSize: '30px',
                    color: '#F36F60',
                    padding: 0,
                    cursor: 'pointer',
                  }}
                />
              )}
            </TimelineDot>
          </Box>
          <TimelineConnector
            sx={{ backgroundColor: '#ECECEC', float: 'right' }}
          />
        </TimelineSeparator>
        <TimelineContent>
          {textOpen || draft ? (
            <RichTextEditor
              saveRecord={saveRecord}
              draft={draft}
              loading={loading}
            />
          ) : (
            <Box mb={2} mt={1}>
              Adicionar nota
            </Box>
          )}
        </TimelineContent>
      </TimelineItem>
      {records
        ?.filter((record: any) => !record.isDraft)
        .map((record: any) => (
          <MedicalRecord key={record.id} record={record} />
        ))}

      {modalOpen && (
        <SecondaryConfirmationModal
          isOpen={modalOpen}
          title='Cancelar registro'
          hideButtons
          onClose={() => setModalOpen(false)}
          content={
            <Stack alignItems='center'>
              <Box maxWidth={494} mb={4} mt={3}>
                <ModalDescriptionText>
                  Você perderá as informações inseridas neste registro. Deseja
                  continuar?
                </ModalDescriptionText>
              </Box>

              <Stack direction='row' spacing={4} mt={3} mb={3}>
                <ButtonClinic
                  variant='outlined'
                  onClick={() => setModalOpen(false)}
                  width={140}
                  color='purple'
                  size='small'
                  fontWeight='bold'
                >
                  Não
                </ButtonClinic>

                <ButtonClinic
                  variant='contained'
                  onClick={confirmCloseModal}
                  width={140}
                  color='purple'
                  size='small'
                  fontWeight='bold'
                >
                  Sim
                </ButtonClinic>
              </Stack>
            </Stack>
          }
        />
      )}
    </Timeline>
  )
}

const ModalDescriptionText = styled(Typography)({
  color: '#596B7B',
  textAlign: 'center',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '28px',
})

export default RecordTimeline
