import { Typography, Chip } from '@mui/material'
import { styled } from '@mui/material/styles'

export const QuestionText = styled(Typography)<any>(({ highlight }: any) => ({
  display: 'inline-block',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  color: '#353F48',

  borderRadius: '2px',
  backgroundColor: highlight ? '#CDD1ED' : 'transparent',
}))

export const StyledChip = styled(Chip)<any>(() => ({
  backgroundColor: '#01B3A7',
  border: '1px solid #DFDFDF',
  borderRadius: '4px',
  whiteSpace: 'nowrap',
  color: '#ffffff',
  fontFamily: 'Quicksand',
  fontSize: '10px',
  lineHeight: '13px',
  fontWeight: 400,
  marginRight: '10px',
  marginTop: '5px',
  marginBottom: '5px',
  height: '20px',
  '& .MuiChip-label': {
    paddingRight: '8px',
    paddingLeft: '8px',
  },
}))
