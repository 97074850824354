import BookmarkIcon from '@mui/icons-material/Bookmark'
import { styled } from '@mui/material/styles'

export const BookmarkIconStyled = styled(BookmarkIcon)({
  fill: '#37405D',
  height: '18px',
  marginRight: '4px',
})

export const Title = styled('span')({
  fontFamily: 'Zen Kaku Gothic Antique',
  fontWeight: '500',
  fontSize: '16px',
  color: '#353F48',
})

export const QuestionText = styled('span')({
  fontFamily: 'Zen Kaku Gothic Antique',
  fontWeight: '400',
  fontSize: '14px',
  color: '#353F48',
})
