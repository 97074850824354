import { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import { ClinicLayout, ProgressBar } from 'components'
import { invitePatientSteps } from 'components/send-questionnaire-steps'
import { SendQuestionnaireContext } from 'contexts'
import { getTags } from 'services'
import { SENDING_TYPE_NOW } from 'helpers/constants'

function InvitePatient(): JSX.Element {
  const [activeStep, setActiveStep] = useState(0)
  const [tags, setTags] = useState([])
  const [selectedTag, setSelectedTag] = useState<any>(null) // used for filter + question step
  const [newQuestionnaireInfos, setNewQuestionnaireInfos] = useState({
    // * Infos para criação do quesionário
    patientName: '',
    selectedQuestions: [],
    selectedTags: [],
    frequency: '',
    startDate: '',
    sendTo: null,
    // * Infos para envio do questionário
    invitationToken: null,
    invitationCode: null,
    // * Configs
    questionsPerson: '',
    questionsPronoun: '',
    sendingType: '',
    sendingChannel: '',
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tags = await getTags()
        setTags(tags)
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  const goNextStep = (offset: number = 1) => {
    setActiveStep(activeStep + offset)
  }

  const goBackStep = (offset: number = 1) => {
    setActiveStep(activeStep - offset)
  }

  return (
    <ClinicLayout>
      <Box
        py={4}
        display='flex'
        flexDirection='column'
        height='100%'
        flexGrow={1}
      >
        {activeStep !== 0 && (
          <Box mx={6}>
            <ProgressBar
              hasHandleBackButton={invitePatientSteps[activeStep].hasBackButton}
              handleBack={() => {
                if (
                  invitePatientSteps[activeStep].label === 'Enviar' &&
                  newQuestionnaireInfos.sendingType === SENDING_TYPE_NOW
                ) {
                  goBackStep(2)
                  return
                }

                goBackStep()
              }}
              totalSteps={invitePatientSteps.length - 1}
              activeStep={activeStep}
            />
          </Box>
        )}
        <SendQuestionnaireContext.Provider
          value={{
            sendQuestionnaireType: 'invite',
            goNextStep,
            goBackStep,
            newQuestionnaireInfos,
            setNewQuestionnaireInfos,
            selectedTag,
            setSelectedTag,
            tags,
          }}
        >
          {invitePatientSteps[activeStep].component}
        </SendQuestionnaireContext.Provider>
      </Box>
    </ClinicLayout>
  )
}

export default InvitePatient
