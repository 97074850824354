import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  sectionDot: {
    width: 6,
    height: 6,
    borderRadius: 3,
    backgroundColor: '#8D92BE',
  },
  sectionTitle: {
    fontSize: 12,
    fontWeight: 700,
    fontStyle: 'bold',
  },
  sectionView: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    gap: 8,
    color: '#596B7B',
    backgroundColor: '#F3F3F3',
    paddingHorizontal: 24,
    marginBottom: 16,
    paddingVertical: 8,
    borderTopRightRadius: 24,
    borderBottomRightRadius: 24,
  },
})
