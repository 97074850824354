import { Control, Controller } from 'react-hook-form'
import SwitchMui, { SwitchProps as SwitchPropsMui } from '@mui/material/Switch'
import { FormControlLabel } from '@mui/material'
import { styled } from '@mui/material/styles'

type SwitchProps = SwitchPropsMui & {
  name: string
  control: Control<any>
  label?: string
  disabled?: boolean
  width?: number
  labelPlacement?: string
}
function Switch({
  control,
  name,
  label = '',
  disabled = false,
  width = 116,
  labelPlacement = 'start',
}: SwitchProps): JSX.Element {
  return (
    <Controller
      control={control}
      name={name || ''}
      defaultValue={false}
      render={({ field: { onChange, value } }) => (
        <FormControlLabelStyled
          control={
            <SwitchMui
              color='secondary'
              checked={value}
              onChange={onChange}
              disabled={disabled}
            />
          }
          label={label}
          labelPlacement={labelPlacement}
          width={width}
        />
      )}
    />
  )
}

const FormControlLabelStyled = styled(FormControlLabel)<any>(
  ({ width }: any) => ({
    '	.MuiFormControlLabel-label': {
      width: width,
      fontFamily: 'Zen Kaku Gothic Antique',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '17px',
      color: '#353F48',
    },
  })
)

export default Switch
