import { useForm } from 'react-hook-form'
import {
  editPatientPersonalInfosResolver,
  editCaregiverPersonalInfosResolver,
  editInformantPersonalInfosResolver,
} from 'resolvers'

const roleConfigForm: any = {
  patient: {
    resolver: editPatientPersonalInfosResolver,
    defaultValues: {
      firstName: '',
      lastName: '',
      pronoun: '',
      birthdate: '',
    },
  },
  caregiver: {
    resolver: editCaregiverPersonalInfosResolver,
    defaultValues: {
      caregiverFirstName: '',
      caregiverLastName: '',
      firstName: '',
      lastName: '',
      pronoun: '',
      birthdate: '',
    },
  },
  informant: {
    resolver: editInformantPersonalInfosResolver,
    defaultValues: { firstName: '', lastName: '' },
  },
}

export default function useSettingsPersonalInfosForm(role: string) {
  return useForm(roleConfigForm[role])
}
