import { Stack } from '@mui/material'
import { AnswerLabel } from 'components'
import { ANSWERS } from 'helpers/constants'
import { HtmlTooltip } from './graphic-label-styles'

function GraphicLabel({ quantities, children, ...props }: any) {
  return (
    <HtmlTooltip
      placement='right'
      title={
        <Stack spacing={1}>
          {ANSWERS.filter((answer: string) => Boolean(quantities[answer])).map(
            (answer: string) => (
              <AnswerLabel
                key={answer}
                answer={answer}
                qtd={quantities[answer] || 0}
              />
            )
          )}
        </Stack>
      }
      {...props}
    >
      {children}
    </HtmlTooltip>
  )
}

export default GraphicLabel
