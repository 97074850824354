import { TextField } from '@mui/material'
import { styled } from '@mui/material/styles'

export const TextAreaStyled = styled(TextField)({
  '& label.Mui-focused': {
    color: '#CCCCCC',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#CCCCCC',
  },
  '& .MuiOutlinedInput-root': {
    fontFamily: 'Zen Kaku Gothic Antique',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '17px',
    color: '#657787',
    '& fieldset': {
      borderColor: '#CCCCCC',
      borderRadius: '16px',
    },
    '&:hover fieldset': {
      borderColor: '#CCCCCC',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#CCCCCC',
    },
  },
})
