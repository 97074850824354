import { createContext, useContext } from 'react'

interface ISignUpClinicContextType {
  goNextStep: () => void
  goBackStep: () => void
  loading: boolean
}

export const SignUpClinicContext = createContext<ISignUpClinicContextType>({
  goNextStep: () => {},
  goBackStep: () => {},
  loading: false,
})

export const useSignUpClinicContext = () => {
  const context = useContext(SignUpClinicContext)
  return context
}
