import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'

export const TitleText = styled(Typography)({
  color: '#FFFFFF',
  textShadow: '-1px 1px 0px #8D92BE',
  fontFamily: 'Quicksand',
  fontSize: '32px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '47px',
})

export const SubtitleText = styled(Typography)({
  color: '#FFFFFF',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '27px',
})

export const DescriptionText = styled(Typography)({
  color: '#FFFFFF',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '27px',
})

export const ItemText = styled(Typography)({
  color: '#FFFFFF',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '27px',
})

export const SigninDescriptionText = styled(Typography)({
  color: '#FFFFFF',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '26px',
})
