import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  text: {
    fontSize: 11,
    fontFamily: 'Zen Kaku Gothic Antique',
    color: '#596B7B',
  },
  strong: {
    fontSize: 11,
    fontFamily: 'Zen Kaku Gothic Antique',
    color: '#596B7B',
    fontWeight: 700,
    fontStyle: 'bold',
  },
  tableTitle: {
    fontSize: 11,
    fontFamily: 'Quicksand',
    fontWeight: 700,
    fontStyle: 'bold',
    color: '#D57164',
  },
  tableText: {
    fontSize: 9,
    fontFamily: 'Zen Kaku Gothic Antique',
    color: '#596B7B',
    textAlign: 'center',
    width: 55,
  },
  dateText: {
    fontSize: 8,
    fontWeight: 300,
    fontFamily: 'Zen Kaku Gothic Antique',
    textAlign: 'center',
    color: '#596B7B',
    width: 55,
  },
  answerView: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  line: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px dashed #B7B7B7',
  },
  associationText: {
    fontSize: 9,
    fontWeight: 300,
    fontFamily: 'Zen Kaku Gothic Antique',
    color: '#596B7B',
  },
})
