import { forwardRef } from 'react'
import { Control, Controller } from 'react-hook-form'
import { IMaskInput } from 'react-imask'
import Box from '@mui/material/Box'
import { FieldContainer, Label, Input, HelperText } from './mask-field-styles'

interface MaskFieldProps {
  name: string
  control: Control<any>
  mask: string
  label?: string
  type?: string
  disabled?: boolean
  placeholder?: string
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
  mask: string
}

const TextMaskCustom = forwardRef(function TextMaskCustom(
  props: CustomProps,
  ref: any
) {
  const { onChange, mask, ...other } = props
  return (
    <IMaskInput
      {...other}
      mask={mask}
      inputRef={ref}
      onAccept={(value: any) =>
        onChange({ target: { name: props.name, value } })
      }
      overwrite
    />
  )
})

function MaskField({
  name,
  control,
  label,
  type,
  disabled,
  mask,
  placeholder = '',
}: MaskFieldProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value },
        fieldState: { invalid, error },
      }) => (
        <Box width='100%'>
          <Box mb={0.5}>
            <Label error={invalid}>{label}</Label>
          </Box>
          <FieldContainer
            disabled={disabled}
            error={invalid}
            variant='standard'
            fullWidth
          >
            <Input
              onChange={onChange}
              value={value}
              color='secondary'
              type={type}
              inputComponent={TextMaskCustom as any}
              inputProps={{
                mask,
                placeholder,
              }}
            />
            {invalid && (
              <HelperText>{error?.message || 'Erro desconhecido'}</HelperText>
            )}
          </FieldContainer>
        </Box>
      )}
    />
  )
}

export default MaskField
