export const registerPageAccess = (name: string, properties?: Object) => {
  if (properties) window.analytics.page(name, properties)
  else window.analytics.page(name)
}

export const registerTrack = (name: string, properties?: Object) => {
  if (properties) window.analytics.track(name, properties)
  else window.analytics.track(name)
}

export const registerIdentify = (userId: string, properties: Object) => {
  window.analytics.identify(userId, properties)
}
