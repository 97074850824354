import { Box, IconButton, Modal as ModalMui, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'

function DesktopModal({ isOpen, handleClose }: any) {
  return (
    <ModalMui open={isOpen} onClose={handleClose} disableEnforceFocus>
      <Box
        display='flex'
        flexDirection='column'
        mx={2}
        mt={`${(window.innerHeight - 240) / 2}px`}
        sx={{
          position: 'relative',
          width: window.innerWidth - 32,
          height: 256,
          backgroundColor: '#FFFFFF',
          borderRadius: '10px',
          border: 'none',
          paddingBottom: '40px',
        }}
        alignItems='center'
        justifyContent='space-between'
      >
        <Box sx={{ position: 'absolute', top: '10px', right: '10px' }}>
          <IconButton onClick={handleClose}>
            <CloseIcon
              sx={{
                fill: '#F36F60',
              }}
            />
          </IconButton>
        </Box>
        <Box
          display='flex'
          justifyContent='space-between'
          padding='40px'
          pb='20px'
        >
          <Title>Atenção</Title>
        </Box>
        <Description>
          Para uma melhor experiência, utilize o Wida através do seu computador.
        </Description>
        <Description>
          A navegação não está otimizada para uso em celular.
        </Description>

        <Box mt={1}>
          <Description>
            <b>Acesse {process.env.REACT_APP_WIDA_URL}</b>
          </Description>
        </Box>
      </Box>
    </ModalMui>
  )
}

const Title = styled(Typography)({ fontSize: '20px', color: '#353F48' })

const Description = styled('span')({
  fontFamily: 'Zen Kaku Gothic Antique',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '23px',
  color: '#657787',
  padding: '0 40px',
  textAlign: 'center',
})

export default DesktopModal
