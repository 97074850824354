import { useState } from 'react'
import { Box, Stack } from '@mui/material'
import { ButtonClinic } from 'components'
import { useInvitePatientContext } from 'contexts'
import { useNotification } from 'hooks'
import { Title, SupportText } from '../styles/common-styles'
import { SENDING_TYPE_NOW, SENDING_TYPE_SCHEDULE } from 'helpers/constants'

function SendingTypeStep(): JSX.Element {
  const [sending, setSending] = useState(false)

  const { errorToast } = useNotification()

  const {
    goNextStep,
    setNewQuestionnaireInfos,
    sendQuestionnaireType,
    handleCreateQuestionnaire,
  } = useInvitePatientContext()

  const handleSelectSendingType = async (sendingType: string) => {
    setNewQuestionnaireInfos((newQuestionnaireInfos: any) => ({
      ...newQuestionnaireInfos,
      sendingType: sendingType,
    }))

    // * Se selecionou AGORA pula 2
    if (sendingType === SENDING_TYPE_NOW) {
      setNewQuestionnaireInfos((newQuestionnaireInfos: any) => ({
        ...newQuestionnaireInfos,
        frequency: 'once',
        startDate: null,
      }))

      if (sendQuestionnaireType === 'resend') {
        try {
          setSending(true)
          await handleCreateQuestionnaire({
            frequency: 'once',
            startDate: new Date(),
          })
          setSending(false)
        } catch (error) {
          errorToast('Não foi possível enviar. Tente novamente')
          setSending(false)
          return
        }
      }

      goNextStep(2)
      return
    }

    // * Senão selecionou AGENDAR e pula 1
    goNextStep()
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      flexGrow={1}
    >
      <Title>Quando você deseja enviar o questionário?</Title>

      <Stack direction='row' my={6} alignItems='flex-start' spacing={3}>
        <ButtonClinic
          variant='outlined'
          size='large'
          color='purple'
          fontWeight='regular'
          width={400}
          onClick={() => handleSelectSendingType(SENDING_TYPE_NOW)}
          loading={sending}
        >
          {sending ? 'Enviando' : 'Enviar agora'}
        </ButtonClinic>
        <ButtonClinic
          variant='outlined'
          size='large'
          color='purple'
          fontWeight='regular'
          width={400}
          onClick={() => handleSelectSendingType(SENDING_TYPE_SCHEDULE)}
        >
          Programar envio
        </ButtonClinic>
      </Stack>

      <SupportText>
        Ao programar o envio, você poderá escolher uma data para enviar o
        questionário ou agendar envios periódicos automáticos.
      </SupportText>
    </Box>
  )
}

export default SendingTypeStep
