import client from 'services/client'

export const getTokenByCode = async (code: string) => {
  try {
    const { data }: any = await client.get(`/invite-short-codes/${code}`)

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}
