import { Text, View } from '@react-pdf/renderer'

import { PatientReport } from 'types/patient-report-types'
import {
  getFormatedResponseDate,
  parseBirthdateIntoAge,
} from '../../helpers/date'
import { getFullName } from '../../helpers/name'
import { styles } from './info-header-styles'

interface InfoHeaderProps {
  data?: PatientReport
}

function InfoHeader({ data }: InfoHeaderProps) {
  const patientFullName = getFullName({
    firstName: data?.patient.personalInfo?.firstName,
    lastName: data?.patient.personalInfo?.lastName,
  })

  const clinicfullName = getFullName({
    firstName: data?.clinic.personalInfo?.firstName,
    lastName: data?.clinic.personalInfo?.lastName,
  })

  const age = parseBirthdateIntoAge(data?.patient.personalInfo?.birthdate)

  return (
    <View style={styles.view}>
      <View>
        <Text style={styles.headerTitle}>Paciente</Text>
        <Text style={styles.headerText}>
          <Text
            style={{
              ...styles.headerText,
              fontWeight: 500,
              fontStyle: 'semibold',
            }}
          >
            {patientFullName}
          </Text>
          , {age}
        </Text>
      </View>
      <View>
        <Text style={styles.headerTitle}>Profissional</Text>
        <Text style={styles.headerText}>
          {clinicfullName}
        </Text>
      </View>
      <View>
        <Text style={styles.headerTitle}>Data de emissão</Text>
        <Text style={styles.headerText}>
          {getFormatedResponseDate(data?.createdAt)}
        </Text>
      </View>
    </View>
  )
}

export default InfoHeader
