import { styled } from '@mui/material/styles'
import InputLabelMui from '@mui/material/InputLabel'
import InputMui from '@mui/material/Input'
import FormControlMui from '@mui/material/FormControl'
import FormHelperTextMui from '@mui/material/FormHelperText'

export const FieldContainer = styled(FormControlMui)({})

export const Label = styled(InputLabelMui)({
  fontWeight: '500',
  fontSize: '12px',
  lineHeight: '18px',
})

export const Input = styled(InputMui)({
  height: '42px',
  fontSize: '16px',
})

export const HelperText = styled(FormHelperTextMui)({})
