import { Routes, Route } from 'react-router-dom'
import {
  Home,
  SignUp,
  SignUpPatient,
  SignUpInformant,
  SignUpClinic,
  SignIn,
  PasswordReset,
  Questionnaire,
  InviteNewInformant,
  InviteInformant,
  MyPatients,
  MyProfile,
  InvitePatient,
  PatientHome,
  ForgotPassword,
  PatientProfile,
  Results,
  PatientRecords,
  SignUpCaregiver,
  InviteClinic,
  Settings,
  SignInPatientGroup,
  PasswordResetPatientGroup,
  PasswordResetSuccessPatientGroup,
  ForgotPasswordPatientGroup,
  ExpiredInvitation,
  ResendPatient,
  RedirectSignupCode,
  PatientReports,
  CreatePatientReport,
  PatientReport,
} from 'routes'
import {
  SettingsRoot,
  SettingsPersonalInfos,
  SettingsSecurity,
  SettingsNotifications,
  SettingsContactUs,
  SettingsInformants,
} from 'routes/settings'
import { RequireAuthWrapper, DesktopModal } from 'components'
import { CLINIC, PATIENT, CAREGIVER, INFORMANT } from 'helpers/constants'
import { useAuthContext } from 'contexts'
import { isUserClinic } from 'helpers'
import { useEffect, useState } from 'react'
import { Font } from '@react-pdf/renderer'
import { hyphenationCallback } from 'components/patient-reports/pdf-document/helpers/hyphenation'
import { fonts } from 'components/patient-reports/pdf-document/helpers/fonts'

declare global {
  interface Window {
    analytics: any
  }
}

function App() {
  const [desktopModalOpen, setDesktopModalOpen] = useState(false)

  const { user } = useAuthContext()

  useEffect(() => {
    if (isUserClinic(user)) setDesktopModalOpen(window.innerWidth <= 850)
  }, [user])

  useEffect(() => {
    Font.register(fonts.Quicksand)
    Font.register(fonts.ZenKakuGothicAntique)
    Font.registerHyphenationCallback(hyphenationCallback)
  }, [])

  return (
    <>
      <DesktopModal
        isOpen={desktopModalOpen}
        handleClose={() => setDesktopModalOpen(false)}
      />
      <Routes>
        <Route
          path='/'
          element={
            <RequireAuthWrapper
              alowedRoles={[CLINIC, PATIENT, CAREGIVER, INFORMANT]}
            >
              <Home />
            </RequireAuthWrapper>
          }
        />

        <Route path='/r/:code' element={<RedirectSignupCode />} />

        <Route path='signup'>
          <Route index element={<SignUp />} />
          <Route path='patient' element={<SignUpPatient />} />
          <Route path='caregiver' element={<SignUpCaregiver />} />
          <Route path='informant' element={<SignUpInformant />} />
          <Route path='clinic' element={<SignUpClinic />} />
        </Route>

        <Route path='signin'>
          <Route path='clinic' element={<SignIn type={CLINIC} />} />
          <Route path='patient' element={<SignInPatientGroup />} />
        </Route>

        <Route
          path='profile'
          element={
            <RequireAuthWrapper alowedRoles={[CLINIC]}>
              <MyProfile />
            </RequireAuthWrapper>
          }
        />

        <Route
          path='settings'
          element={
            <RequireAuthWrapper alowedRoles={[PATIENT, CAREGIVER, INFORMANT]}>
              <Settings />
            </RequireAuthWrapper>
          }
        >
          <Route
            index
            element={
              <RequireAuthWrapper alowedRoles={[PATIENT, CAREGIVER, INFORMANT]}>
                <SettingsRoot />
              </RequireAuthWrapper>
            }
          />
          <Route
            path='personal_infos'
            element={
              <RequireAuthWrapper alowedRoles={[PATIENT, CAREGIVER, INFORMANT]}>
                <SettingsPersonalInfos />
              </RequireAuthWrapper>
            }
          />
          <Route
            path='notifications'
            element={
              <RequireAuthWrapper alowedRoles={[PATIENT, CAREGIVER, INFORMANT]}>
                <SettingsNotifications />
              </RequireAuthWrapper>
            }
          />
          <Route
            path='security'
            element={
              <RequireAuthWrapper alowedRoles={[PATIENT, CAREGIVER, INFORMANT]}>
                <SettingsSecurity />
              </RequireAuthWrapper>
            }
          />
          <Route
            path='contact_us'
            element={
              <RequireAuthWrapper alowedRoles={[PATIENT, CAREGIVER, INFORMANT]}>
                <SettingsContactUs />
              </RequireAuthWrapper>
            }
          />
          <Route
            path='informants'
            element={
              <RequireAuthWrapper alowedRoles={[PATIENT, CAREGIVER]}>
                <SettingsInformants />
              </RequireAuthWrapper>
            }
          />
        </Route>

        <Route
          path='patient'
          element={
            <RequireAuthWrapper alowedRoles={[PATIENT, CAREGIVER, INFORMANT]}>
              <PatientHome />
            </RequireAuthWrapper>
          }
        />

        <Route path='patients'>
          <Route
            index
            element={
              <RequireAuthWrapper alowedRoles={[CLINIC]}>
                <MyPatients />
              </RequireAuthWrapper>
            }
          />

          <Route path=':patient_id'>
            <Route
              path='results'
              element={
                <RequireAuthWrapper alowedRoles={[CLINIC]}>
                  <Results />
                </RequireAuthWrapper>
              }
            />

            <Route
              path='profile'
              element={
                <RequireAuthWrapper alowedRoles={[CLINIC]}>
                  <PatientProfile />
                </RequireAuthWrapper>
              }
            />

            <Route
              path='patient_records'
              element={
                <RequireAuthWrapper alowedRoles={[CLINIC]}>
                  <PatientRecords />
                </RequireAuthWrapper>
              }
            />

            <Route path='reports'>
              <Route
                index
                element={
                  <RequireAuthWrapper alowedRoles={[CLINIC]}>
                    <PatientReports />
                  </RequireAuthWrapper>
                }
              />

              <Route
                path=':report_id'
                element={
                  <RequireAuthWrapper alowedRoles={[CLINIC]}>
                    <PatientReport />
                  </RequireAuthWrapper>
                }
              />
            </Route>

            <Route
              path='create_report'
              element={
                <RequireAuthWrapper alowedRoles={[CLINIC]}>
                  <CreatePatientReport />
                </RequireAuthWrapper>
              }
            />
          </Route>
        </Route>

        <Route
          path='questionnaire'
          element={
            <RequireAuthWrapper alowedRoles={[PATIENT, CAREGIVER, INFORMANT]}>
              <Questionnaire />
            </RequireAuthWrapper>
          }
        />

        <Route path='password_reset'>
          <Route path='clinic' element={<PasswordReset type={CLINIC} />} />

          <Route path='patient'>
            <Route index element={<PasswordResetPatientGroup />} />
            <Route
              path='success'
              element={<PasswordResetSuccessPatientGroup />}
            />
          </Route>
        </Route>

        <Route path='forgot-password' element={<ForgotPassword />} />

        <Route path='forgot_password'>
          <Route path='patient' element={<ForgotPasswordPatientGroup />} />
        </Route>

        <Route
          path='invite_informant'
          element={
            <RequireAuthWrapper alowedRoles={[PATIENT, CAREGIVER]}>
              <InviteInformant />
            </RequireAuthWrapper>
          }
        />

        <Route
          path='invite_new_informant'
          element={
            <RequireAuthWrapper alowedRoles={[PATIENT, CAREGIVER]}>
              <InviteNewInformant />
            </RequireAuthWrapper>
          }
        />

        <Route
          path='invite_patient'
          element={
            <RequireAuthWrapper alowedRoles={[CLINIC]}>
              <InvitePatient />
            </RequireAuthWrapper>
          }
        />

        <Route
          path='resend_patient'
          element={
            <RequireAuthWrapper alowedRoles={[CLINIC]}>
              <ResendPatient />
            </RequireAuthWrapper>
          }
        />

        <Route path='invite_clinic' element={<InviteClinic />} />

        <Route path='expired_invitation' element={<ExpiredInvitation />} />
      </Routes>
    </>
  )
}

export default App
