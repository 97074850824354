import { Text, View } from '@react-pdf/renderer'
import YesIcon from '../../icons/yes-icon/yes-icon'
import SosoIcon from '../../icons/soso-icon/soso-icon'
import NoIcon from '../../icons/no-icon/no-icon'
import SkippedIcon from '../../icons/skipped-icon/skipped-icon'
import NotAskedIcon from '../../icons/not-asked-icon/not-asked-icon'
import { styles } from './answers-options-styles'

const answersOptions = [
  {
    title: 'Presente',
    icon: <YesIcon />,
  },
  {
    title: 'Indeterminado',
    icon: <SosoIcon />,
  },
  {
    title: 'Ausente',
    icon: <NoIcon />,
  },
  {
    title: 'Não respondido',
    icon: <SkippedIcon />,
  },
  {
    title: 'Não enviado',
    icon: <NotAskedIcon />,
  },
]

function AnswersOptions() {
  return (
    <View style={styles.answerVariationView}>
      {answersOptions.map((answer) => (
        <View key={answer.title} style={styles.answerVariationItemView}>
          {answer.icon}
          <Text style={styles.answerVariationText}>{answer.title}</Text>
        </View>
      ))}
    </View>
  )
}

export default AnswersOptions
