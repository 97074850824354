import { answersOrderDict, tagOrderDict } from 'helpers/constants'

export function extractTagsFromQuestions(questions: any) {
  const dictTags = questions
    .reduce((acc: any, { tags }: any) => (tags ? [...acc, ...tags] : acc), [])
    .reduce((acc: any, tag: any) => {
      return { ...acc, [tag]: (acc[tag] || 0) + 1 }
    }, {})

  const tags = Object.entries(dictTags).map(([tag, qtd]: any) => ({
    value: tag,
    label: `${tag} (${qtd})`,
    name: tag,
    total: qtd,
  }))

  return tags
}

interface ITagObj {
  label: string
  name: string
  total: number
  value: string
}

export const filterMultipleTags = (tags: ITagObj[], questions: any) => {
  let removedQuestions: any[] = []

  const filteredTags: ITagObj[] = tags.filter((tag: ITagObj) => {
    const questionsByTag = questions.filter((question: any) => {
      return question.tags.includes(tag.value)
    })

    const hasOnlyQuestionsMultipleTags = questionsByTag.every(
      (question: any) => question.tags.length > 1
    )

    if (hasOnlyQuestionsMultipleTags) {
      removedQuestions = questionsByTag.reduce((acc: any, question: any) => {
        const alreadyIncludedQuestion = removedQuestions.some(
          ({ id }: any) => id === question.id
        )
        return alreadyIncludedQuestion ? acc : acc.concat(question)
      }, removedQuestions)
    }

    return !hasOnlyQuestionsMultipleTags
  })

  // Código para reinserção de tags que podem ter sumido completamente excluindo perguntas,
  //  nesse momento o algoritmo não agrega essa tag imediatamente, somente após o final de
  //  gerar reinsertableTags.
  // Pode ser interessante ir adicionando a tag imediatamente para que ela faça parte da
  //  próxima rodada do reduce e, assim, diminuindo a diversidade de tags.
  const reinsertableTags = removedQuestions.reduce(
    (acc: any, question: any) => {
      if (!Boolean(question.tags.length)) return acc

      const hasSomeTagIncluded = filteredTags.some((filteredTag: ITagObj) =>
        question.tags.includes(filteredTag.value)
      )

      if (hasSomeTagIncluded) return acc

      const alreadyReinsertedTag = acc.some(
        (tag: ITagObj) => tag.value === question.tags[0]
      )

      if (alreadyReinsertedTag) return acc

      const findTag = tags.find(({ value }: any) => question.tags[0] === value)

      return findTag ? [...acc, findTag] : acc
    },
    []
  )

  return [...filteredTags, ...reinsertableTags]
}

export const prepareQuestions = (
  questions: any,
  pronoun: any,
  userRole: any
) => {
  const chosenQuestions = questions.map((question: any) =>
    chooseQuestion(question, pronoun, userRole)
  )

  return chosenQuestions
}

const chooseQuestion = (question: any, pronoun: any, userRole: any) => {
  const selectedQuestionProp =
    'question' +
    (userRole === 'patient' ? 'Self' : '3rd') +
    (pronoun === 'HE' ? 'M' : 'F')

  return {
    id: question.id,
    block: question.block,
    example: question.example,
    text: question[selectedQuestionProp],
    tags: question.tags,
    answer: '',
    identifier: question.identifier,
  }
}

export const prepareQuestionsToGraphic = (questions: any) => {
  const separatedQuestions = questions.reduce((acc: any, question: any) => {
    return [
      ...acc,
      ...question.tags.map((tag: string) => ({ ...question, tag })),
    ]
  }, [])

  const orderedQuestions = separatedQuestions
    .slice()
    .sort((questionA: any, questionB: any) => {
      return (
        (tagOrderDict[questionA.tag] || 99) -
          (tagOrderDict[questionB.tag] || 99) ||
        answersOrderDict[questionA.answer] - answersOrderDict[questionB.answer]
      )
    })

  return orderedQuestions
}

export const filterResponsesByAnswers = (responses: any, answers: string[]) =>
  responses.filter((response: any) => answers.includes(response.answer))

export const filterResponsesByTags = (responses: any, tags: string[]) =>
  responses.filter((response: any) => tags.includes(response.tag))

export function sortTagQuestionsByOrder(questions: any, orderTagId: string) {
  return questions.slice().sort((questionA: any, questionB: any) => {
    const questionAOrder =
      questionA.questionTags.find(({ tagId }: any) => tagId === orderTagId)
        ?.order || Number.MAX_VALUE

    const questionBOrder =
      questionB.questionTags.find(({ tagId }: any) => tagId === orderTagId)
        ?.order || Number.MAX_VALUE

    return questionAOrder - questionBOrder
  })
}
