import { styled } from '@mui/material/styles'
import FormControlMui from '@mui/material/FormControl'
import FormHelperTextMui from '@mui/material/FormHelperText'
import InputBaseMui from '@mui/material/InputBase'
import { Typography } from '@mui/material'

export const FieldContainer = styled(FormControlMui)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

export const InputBase = styled(InputBaseMui)<any>(({ hasLabel }: any) => ({
  width: '100%',
  height: '48px',
  minHeight: '48px',
  maxHeight: '48px',
  padding: '8px 24px',
  flex: 1,
  border: hasLabel ? '1.5px solid #F0F0F0' : '1.5px solid rgba(0, 0, 0, 0.23)',
  borderRadius: '32px',
  backgroundColor: '#FDFDFD',
  color: '#596B7B',
  // Typography
  fontFamily: 'Quicksand',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '31px',
  // ------------------------
  '&.Mui-focused, &:hover': {
    border: '1.5px solid #BDC1DD',
    backgroundColor: '#FDFDFD',
  },
  '&.Mui-disabled': {
    border: hasLabel ? '1.5px solid #F4F4F4' : '1.5px solid #BDC1DD',
    backgroundColor: '#F4F4F4',
    color: hasLabel ? '#CDCDCD ' : '#596B7B',
  },
  '&.Mui-error': {
    border: '1.5px solid #E74C3C',
    color: '#E74C3C',
    background:
      'linear-gradient(0deg, rgba(231, 76, 60, 0.05) 0%, rgba(231, 76, 60, 0.05) 100%), #FDFDFD',
  },
}))

export const HelperText = styled(FormHelperTextMui)({
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Quicksand',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '14px',
  margin: 0,
  marginTop: '8px',
})

export const LabelText = styled(Typography)({
  alignSelf: 'flex-start',
  color: '#596B7B',
  fontFamily: 'Quicksand',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
})
