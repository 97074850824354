import { useEffect } from 'react'
import { Box } from '@mui/material'
import { SubmitHandler } from 'react-hook-form'
import { useNavigate, useLocation } from 'react-router-dom'
import { ButtonClinic, LogoLayout, SignUpHeader } from 'components'
import { TextFieldClinic } from 'components'
import { useAuthContext } from 'contexts'
import { useNotification } from 'hooks'
import { registerPageAccess, sanitizeString } from 'helpers'
import logoColor from 'images/wida-logo-color.svg'
import { IFormInput } from './sign-in-types'
import useFormSignIn from './sign-in-form'
import {
  TitleText,
  ChangeLoginTypeText,
  ChangeLoginTypeLink,
  ForgotPasswordButton,
} from './sign-in-styles'

function SignIn({ type }: any): JSX.Element {
  const { control, handleSubmit, watch } = useFormSignIn()
  const navigate = useNavigate()
  const { signin } = useAuthContext()
  const { errorToast } = useNotification()

  const location: any = useLocation()
  const cpf = watch('cpf')
  const pass = watch('password')

  useEffect(() => {
    registerPageAccess('Login')
  }, [])

  const onSubmit: SubmitHandler<IFormInput> = async (params) => {
    try {
      const body = {
        cpf: sanitizeString(params.cpf),
        password: params.password,
      }
      await signin(body, type)
      const from = (location?.state?.from?.pathname as string) || '/'
      navigate(from, { replace: true })
    } catch (error: any) {
      console.error(error)
      errorToast(error.message)
    }
  }

  return (
    <LogoLayout textType='signin'>
      <Box
        px={{ xs: 3, sm: 14, lg: 8 }}
        pt={{ xs: 4.5, sm: 11, lg: 4.5 }}
        pb={{ xs: 4, sm: 11, lg: 8 }}
        height='100%'
        display='flex'
        flexDirection='column'
        component='form'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box alignSelf='flex-end'>
          <SignUpHeader type={type} />
        </Box>
        <Box
          width='100%'
          mt={{ xs: 5, sm: 10, lg: 5 }}
          mb={{ xs: 6, sm: 14, lg: 6 }}
          display='flex'
          justifyContent='center'
        >
          <img src={logoColor} alt='Wida Logo' height={38} />
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          flexGrow={1}
          width='100%'
        >
          <TitleText>Faça seu login</TitleText>
          <Box mt={{ xs: 0, sm: 2, lg: 1.5 }} mb={{ xs: 2, sm: 6, lg: 8 }}>
            <ChangeLoginTypeText>
              {type === 'clinic'
                ? 'Não é profissional de saúde'
                : 'É profissional de saúde'}
              ?{' '}
              <ChangeLoginTypeLink
                onClick={() =>
                  navigate(
                    `/signin/${type === 'clinic' ? 'patient' : 'clinic'}`
                  )
                }
              >
                Clique aqui
              </ChangeLoginTypeLink>
            </ChangeLoginTypeText>
          </Box>

          <Box
            display='flex'
            flexDirection='column'
            alignSelf='center'
            width={{ xs: '224px', sm: '280px', lg: '100%' }}
          >
            <Box
              display='flex'
              flexDirection={{ xs: 'column', lg: 'row' }}
              gap={{ xs: 3, sm: 4 }}
            >
              <TextFieldClinic
                name='cpf'
                control={control}
                label='CPF'
                mask='000.000.000-00'
              />
              <TextFieldClinic
                type='password'
                name='password'
                control={control}
                label='Senha'
              />
            </Box>
            <Box width='100%' mt={1.5} display='flex' justifyContent='flex-end'>
              <ForgotPasswordButton
                onClick={() => navigate(`/password_reset/${type}`)}
              >
                Esqueci minha senha
              </ForgotPasswordButton>
            </Box>
          </Box>

          <Box
            flexGrow={1}
            display='flex'
            justifyContent='center'
            alignItems='flex-end'
            mt={{ xs: 8 }}
          >
            <ButtonClinic
              width={180}
              variant='outlined'
              size='small'
              color='purple'
              fontWeight='bold'
              disabled={!cpf || !pass}
              type='submit'
            >
              Entrar
            </ButtonClinic>
          </Box>
        </Box>
      </Box>
    </LogoLayout>
  )
}

export default SignIn
