import { Button as ButtonMui } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Button = styled(ButtonMui)`
  justify-content: start;
  text-transform: none;
  color: #353F48;
  transition: 0.4s;
  margin: 15px 0;
  
  &:hover {
    opacity: 0.6;
  }

  & svg {
    color: #F36F60
  }
`
