import { View } from '@react-pdf/renderer'

interface YesIconProps {
  size?: number
}

function YesIcon({ size = 9 }: YesIconProps) {
  return (
    <View
      style={{
        width: size,
        height: size,
        borderRadius: '50%',
        border: '1px solid #000',
        backgroundColor: '#000',
      }}
    />
  )
}

export default YesIcon
