import { forwardRef } from 'react'
import { Control, Controller } from 'react-hook-form'
import { useState, MouseEvent } from 'react'
import { IMaskInput } from 'react-imask'
import { Box, InputLabel } from '@mui/material'
import { StyledInput } from './input-field-styles'
import FormControlMui from '@mui/material/FormControl'
import FormHelperTextMui from '@mui/material/FormHelperText'
import { InputAdornment, IconButton } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

interface InputFieldProps {
  name: string
  control: Control<any>
  label?: string
  type?: string
  disabled?: boolean
  invalidChars?: string[]
  mask?: string
  onBlur?: () => void
  notShowError?: boolean
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
  mask: string
}

const TextMaskCustom = forwardRef(function TextMaskCustom(
  props: CustomProps,
  ref: any
) {
  const { onChange, mask, ...other } = props
  return (
    <IMaskInput
      {...other}
      mask={mask}
      inputRef={ref}
      onAccept={(value: any) =>
        onChange({ target: { name: props.name, value } })
      }
      overwrite
    />
  )
})

function InputField({
  name,
  control,
  label,
  disabled,
  invalidChars = [],
  type = 'text',
  mask,
  onBlur: customBlur = () => {},
  notShowError = false,
}: InputFieldProps) {
  const isPassword = type === 'password'
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const getInputType = () => {
    if (!isPassword) return type
    return showPassword ? 'text' : 'password'
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const maskProps = mask && {
    inputProps: { mask },
    inputComponent: TextMaskCustom as any,
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { invalid, error },
      }) => (
        <Box width='100%'>
          <FormControlMui
            disabled={disabled}
            variant='standard'
            fullWidth
            {...((!notShowError && { error: invalid }) as any)}
          >
            <InputLabel
              style={{ color: '#353F48', fontSize: '14px' }}
              htmlFor={name}
            >
              {label}
            </InputLabel>
            <StyledInput
              id={name}
              onBlur={() => {
                onBlur()
                customBlur()
              }}
              onChange={
                Boolean(invalidChars.length) && !isPassword
                  ? (value) => {
                      const sanitizedValue = invalidChars.reduce(
                        (acc: string, invalidChar: string) =>
                          acc.replaceAll(invalidChar, ''),
                        value.target.value
                      )
                      return onChange(sanitizedValue)
                    }
                  : onChange
              }
              value={value}
              color='primary'
              type={getInputType()}
              {...maskProps}
              sx={disabled ? { '&&&::before': { border: 'none' } } : void 0}
              endAdornment={
                isPassword && (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
            {!notShowError && invalid && (
              <FormHelperTextMui>
                {error?.message || 'Erro desconhecido'}
              </FormHelperTextMui>
            )}
          </FormControlMui>
        </Box>
      )}
    />
  )
}

export default InputField
