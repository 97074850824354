import { useState } from 'react'
import { Container, Box } from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useAuthContext } from 'contexts'
import { registerTrack } from 'helpers'
import { SendMethod, WhatsappForm, EmailForm } from 'components'
import { useNotification } from 'hooks'
import { Title, SummaryText, Dialog } from './resend-invite-modal-styles'
import { updateSendMethod } from 'services'
import { SendMethodEnum } from 'types/invites'

interface ResendInviteModalProps {
  onClose: () => void
  informantion: {
    open: boolean
    inviteId: string
    formId: string
    token: string
    name: string
    code: string
    email: string
    whatsapp: string
    sendMethod: SendMethodEnum
  }
}

function ResendInviteModal({ onClose, informantion }: ResendInviteModalProps) {
  const [activeSendMethod, setActiveSendMethod] = useState('')
  const [sendMethod, setSendMethod] = useState<SendMethodEnum | null>(
    informantion.sendMethod ? informantion.sendMethod : null
  )

  const { user } = useAuthContext()
  const { successToast, errorToast } = useNotification()

  const firstName = informantion.name?.split(' ')[0] || ''

  const invitePatientLink = `${process.env.REACT_APP_WIDA_URL as string}/r/${
    informantion.code
  }`

  const handleSendMethod = async (type: SendMethodEnum) => {
    try {
      if (sendMethod === type) return onClose()

      await updateSendMethod({
        inviteId: informantion.inviteId,
        sendMethod: type,
      })

      setSendMethod(type)
      onClose()
    } catch (error: any) {
      errorToast(error.message)
    }
  }

  return (
    <Dialog onClose={onClose} open={informantion.open}>
      <Container maxWidth='md' sx={{ padding: '40px 0px' }}>
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{ color: '#657787', position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>
          <Title>Tudo pronto!</Title>
          <SummaryText>
            Escolha uma das opções abaixo para enviar sua seleção de perguntas
            para {firstName}.
          </SummaryText>
        </DialogTitle>
        <DialogContent>
          <Box my={3} display='flex' justifyContent='space-between'>
            <SendMethod
              type='whatsapp'
              handleClick={() => {
                registerTrack('Clica Whatsapp', {
                  user_id: user.id,
                  source: 'reenvio',
                })
                setActiveSendMethod('whatsapp')
              }}
              active={activeSendMethod === 'whatsapp'}
            />
            <SendMethod
              type='email'
              handleClick={() => {
                registerTrack('Clica Email', {
                  user_id: user.id,
                  source: 'reenvio',
                })

                setActiveSendMethod('email')
              }}
              active={activeSendMethod === 'email'}
            />
            <SendMethod
              type='link'
              handleClick={() => {
                registerTrack('Clica Link', {
                  user_id: user.id,
                  source: 'reenvio',
                })
                navigator.clipboard.writeText(invitePatientLink)
                successToast('Convite copiado com sucesso!')
                setActiveSendMethod('link')
                handleSendMethod(SendMethodEnum.LINK)
              }}
              active={activeSendMethod === 'link'}
            />
          </Box>
          {activeSendMethod === 'whatsapp' && (
            <WhatsappForm
              invitationToken={informantion.token}
              invitationCode={informantion.code}
              value={informantion.whatsapp}
              formId={informantion.formId}
              direction='row'
              source='repeat'
              setAlreadySent={() => handleSendMethod(SendMethodEnum.WHATSAPP)}
            />
          )}
          {activeSendMethod === 'email' && (
            <EmailForm
              invitationToken={informantion.token}
              invitationCode={informantion.code}
              value={informantion.email}
              formId={informantion.formId}
              direction='row'
              source='repeat'
              setAlreadySent={() => handleSendMethod(SendMethodEnum.EMAIL)}
            />
          )}
        </DialogContent>
      </Container>
    </Dialog>
  )
}

export default ResendInviteModal
