import Box from '@mui/material/Box'
import { Input } from './input-with-button-styles'
import { ButtonClinic } from 'components'

interface InputWithButtonProps {
  displayText: string
  buttonLabel: string
  handleClick: () => void
}

function InputWithButton({
  displayText,
  buttonLabel,
  handleClick,
}: InputWithButtonProps) {
  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      border='1px solid #BDC1DD'
      borderRadius='32px'
      height='48px'
      boxSizing='content-box'
      width={570}
    >
      <Input value={displayText} fullWidth disabled />

      <ButtonClinic
        width={200}
        variant='contained'
        size='medium'
        fontWeight='bold'
        color='purple'
        icon='link'
        iconPosition='start'
        borderRadius='0px 32px 32px 0px'
        onClick={handleClick}
      >
        {buttonLabel}
      </ButtonClinic>
    </Box>
  )
}

export default InputWithButton
