import yesIcon from 'images/icons/yes.svg'
import sosoIcon from 'images/icons/soso.svg'
import noIcon from 'images/icons/no.svg'
import skippedIcon from 'images/icons/na.svg'
import { AnswerDictType } from 'types'
import { COLOR_YES, COLOR_SOSO, COLOR_NO } from 'helpers/constants'

export const answerDict: AnswerDictType = {
  yes: {
    alt: 'Ícone de resposta sim',
    icon: yesIcon,
    label: 'SIM',
    color: COLOR_YES,
  },
  soso: {
    alt: 'Ícone de resposta talvez',
    icon: sosoIcon,
    label: 'TALVEZ',
    color: COLOR_SOSO,
  },
  no: {
    alt: 'Ícone de resposta não',
    icon: noIcon,
    label: 'NÃO',
    color: COLOR_NO,
  },
  skip: {
    alt: 'Ícone de não respondido',
    icon: skippedIcon,
    label: 'NÃO RESPONDIDO',
    color: 'transparent',
  },
}

export const TIMELINE_SPACING = 23.1
export const TIMELINE_SPACING_PX = TIMELINE_SPACING * 8
export const ANSWER_WIDTH = 16
