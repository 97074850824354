import { useEffect, useState } from 'react'
import { Box, Checkbox, Grid, Stack } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useSignUpClinicContext } from 'contexts'
import { Modal, StaticLink, ButtonClinic, TextFieldClinic } from 'components'
import { registerPageAccess } from 'helpers'
import { collectDataLabel } from 'helpers/constants'
import {
  CheckboxText,
  Title,
  InfoLeftText,
  TooltipPasswordText,
} from './sign-up-clinic-steps-styles'

function AcceptAndPasswordStep(): JSX.Element {
  const [collectDataChecked, setCollectDataChecked] = useState(false)
  const [termsAndPolicyChecked, setTermsAndPolicyChecked] = useState(false)
  const [termsModalOpen, setTermsModalOpen] = useState(false)
  const [policyModalOpen, setPolicyModalOpen] = useState(false)

  const { control } = useFormContext()
  const { loading, goBackStep } = useSignUpClinicContext()

  useEffect(() => {
    registerPageAccess('Cadastro clínico: aceites')
  }, [])

  const handleCollectDataChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCollectDataChecked(event.target.checked)
  }

  const handleTermsAndPolicyChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTermsAndPolicyChecked(event.target.checked)
  }

  return (
    <Box
      flexGrow={1}
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
    >
      <Box>
        <Box mb={{ xs: 3, sm: 4, lg: 3}}>
          <Title>Quase lá!</Title>
        </Box>

        <Grid container spacing={{ xs: 1, sm: 4}} mb={{ xs: 4, lg: 6}}>
          <Grid item xs={12} md={6}>
            <TextFieldClinic
              type='password'
              name='password'
              control={control}
              label='Crie sua senha'
              fieldInfo={
                <Stack>
                  <TooltipPasswordText>
                    Sua senha deve conter:
                  </TooltipPasswordText>
                  <TooltipPasswordText>
                    - no mínimo 8 caracteres
                  </TooltipPasswordText>
                  <TooltipPasswordText>
                    - pelo menos uma letra maiúscula
                  </TooltipPasswordText>
                  <TooltipPasswordText>
                    - um ou mais números ou símbolos
                  </TooltipPasswordText>
                </Stack>
              }
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextFieldClinic
              type='password'
              name='confirmPassword'
              control={control}
              label='Confirme sua senha'
            />
          </Grid>
        </Grid>

        <Box>
          <InfoLeftText>
            No Wida, respeitamos sua privacidade e adotamos as melhores práticas
            em segurança de dados.
          </InfoLeftText>
        </Box>

        <Box display={'flex'} alignItems={'center'} mt={{ xs: 3, sm: 4}}>
          <Checkbox
            checked={collectDataChecked}
            onChange={handleCollectDataChange}
            sx={{
              marginY: '3px',
              p: '8px',
              pl: 0,
              color: 'rgba(0, 0, 0, 0.6)',
              '&.Mui-checked': {
                color: '#8D92BE',
              },
            }}
          />
          <CheckboxText>{collectDataLabel}</CheckboxText>
        </Box>

        <Box display={'flex'} alignItems={'center'} mt={{ xs: 2.5, sm: 3}}>
          <Checkbox
            checked={termsAndPolicyChecked}
            onChange={handleTermsAndPolicyChange}
            sx={{
              marginY: '3px',
              p: '8px',
              pl: 0,
              color: 'rgba(0, 0, 0, 0.6)',
              '&.Mui-checked': {
                color: '#8D92BE',
              },
            }}
          />
          <CheckboxText>
            Declaro que li e concordo com os{' '}
            <StaticLink
              to='/'
              size={'mini'}
              onClick={(e: any) => {
                e.preventDefault()
                setTermsModalOpen(true)
              }}
            >
              {' '}
              Termos de Uso
            </StaticLink>{' '}
            e{' '}
            <StaticLink
              to='/'
              size={'mini'}
              onClick={(e: any) => {
                e.preventDefault()
                setPolicyModalOpen(true)
              }}
            >
              Política de Privacidade{' '}
            </StaticLink>
            da Plataforma Wida
          </CheckboxText>
        </Box>
      </Box>
      <Stack
        width='100%'
        direction='row'
        spacing={{ xs: 1.5, sm: 6, lg: 0 }}
        justifyContent={{ xs: 'center', sm: 'center', md: 'center', lg: 'space-between'}}
        alignItems='center'
        mt={{ xs: 6, sm: 0}}
      >
        <ButtonClinic
          width={150}
          variant='outlined'
          size='small'
          color='purple'
          fontWeight='bold'
          type='button'
          onClick={goBackStep}
          disabled={loading}
        >
          Voltar
        </ButtonClinic>

        <ButtonClinic
          width={150}
          variant='contained'
          size='small'
          color='purple'
          fontWeight='bold'
          type='submit'
          disabled={!(collectDataChecked && termsAndPolicyChecked)}
          loading={loading}
        >
          {loading ? 'Cadastrando' : 'Cadastrar'}
        </ButtonClinic>
      </Stack>

      <Modal
        title=''
        isOpen={termsModalOpen}
        handleClose={() => setTermsModalOpen(false)}
        component={
          <iframe
            title='term of use'
            src='/term-of-use.html'
            style={{ width: '100%', border: 'none' }}
          />
        }
        headerPadding='30px 16px 0'
        bodyPadding='0 8px 40px 40px'
      />
      <Modal
        title=''
        isOpen={policyModalOpen}
        handleClose={() => setPolicyModalOpen(false)}
        component={
          <iframe
            title='privacy policy'
            src='/privacy-policy.html'
            style={{ width: '100%', border: 'none' }}
          />
        }
        headerPadding='30px 16px 0'
        bodyPadding='0 8px 40px 40px'
      />
    </Box>
  )
}

export default AcceptAndPasswordStep
