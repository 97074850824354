import { Tooltip, IconButton } from '@mui/material'
import ReminderIcon from 'components/icons/reminder-icon'

function PatientListReminderButton({
  patient,
  setReminderPatient,
}: any): JSX.Element {
  return (
    <>
      <Tooltip
        title='Enviar lembrete'
        placement='top'
        arrow
        componentsProps={{
          tooltip: {
            style: {
              color: '#FFF',
              fontFamily: 'Zen Kaku Gothic Antique',
              fontSize: '10px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
            },
          },
        }}
      >
        <IconButton
          sx={{ ml: '8px', height: '36px' }}
          onClick={(e: any) => {
            e.stopPropagation()
            setReminderPatient(patient)
          }}
        >
          <ReminderIcon />
        </IconButton>
      </Tooltip>
    </>
  )
}

export default PatientListReminderButton
