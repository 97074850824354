import { Box, IconButton, Modal as ModalMui, Typography } from '@mui/material'
import { Button } from 'components'
import CloseIcon from '@mui/icons-material/Close'

interface Props {
  title: string
  component: JSX.Element
  isOpen: boolean
  handleClose: () => void
  action?: {
    name: string
    func: () => void
  }
  headerPadding?: string
  bodyPadding?: string
  height?: string
  width?: string
}

export default function Modal({
  title,
  component,
  isOpen,
  handleClose,
  action,
  headerPadding = '40px',
  bodyPadding = '0 40px 40px',
  height = '95%',
  width = '80%',
}: Props): JSX.Element {
  return (
    <ModalMui open={isOpen} onClose={handleClose}>
      <Box
        display='flex'
        flexDirection='column'
        sx={{
          position: 'absolute' as 'absolute',
          width: width,
          height: height,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#FFFFFF',
          borderRadius: '10px',
        }}
      >
        <Box
          display='flex'
          justifyContent='space-between'
          padding={headerPadding}
        >
          <Typography
            style={{
              fontSize: '20px',
              color: '#353F48',
            }}
          >
            {title}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon
              sx={{
                fill: '#F36F60',
              }}
            />
          </IconButton>
        </Box>
        <Box display='flex' flex={1} sx={{ padding: bodyPadding }}>
          {component}
        </Box>
        {action && (
          <Box display='flex' justifyContent='center' padding='40px'>
            <Button
              sx={{
                width: {
                  xs: '100%',
                  md: '25%',
                },
              }}
              type='button'
              onClick={() => action.func()}
            >
              {action.name}
            </Button>
          </Box>
        )}
      </Box>
    </ModalMui>
  )
}
