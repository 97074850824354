import { Link as LinkRRD } from 'react-router-dom'
import { styled } from '@mui/material/styles'

const sizeOptions = {
  small: '10px',
  medium: '12px',
}

interface LinkProps {
  size?: 'small' | 'medium'
}

const Link = styled(LinkRRD)<LinkProps>(({ theme, size = 'medium' }) => ({
  color: theme.palette.typography.main,
  fontSize: sizeOptions[size],
}))

export default Link
