import { Text, View } from '@react-pdf/renderer'
import { styles } from './title-styles'

interface TitleProps {
  title: string
}

function Title({ title }: TitleProps) {
  return (
    <View fixed style={styles.sectionView}>
      <View style={styles.sectionDot} />
      <Text style={styles.sectionTitle}>{title}</Text>
    </View>
  )
}

export default Title
