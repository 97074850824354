import { Link } from './static-link-styles'
import { styled } from '@mui/material/styles'

const sizeOptions = {
  mini: '12px',
  small: '14px',
  medium: '16px',
}

interface LinkProps {
  size?: 'mini' | 'small' | 'medium'
}

const StaticLink: any = styled(Link)<LinkProps>(
  ({ theme, size = 'medium' }) => ({
    color: theme.palette.typography.main,
    fontSize: sizeOptions[size],
  })
)

export default StaticLink
