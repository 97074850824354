interface IdbTag {
  id: string
  tag: string
}

function findTagInfosByName(tagName: string, dbTags: IdbTag[]) {
  const findTag = dbTags.find((databaseTag: any) => {
    return databaseTag.name === tagName
  })

  return findTag
}

export function correctsTagWithDBInfo(tags: any, dbTags: IdbTag[]) {
  return tags.map((tag: any) => {
    return tag.id
      ? tag
      : {
          tag: tag.tag,
          id: findTagInfosByName(tag.tag, dbTags)?.id,
        }
  })
}
