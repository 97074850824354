import { useEffect } from 'react'
import { Box, Stack } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useSignUpCaregiverContext } from 'contexts'
import { registerPageAccess } from 'helpers'
import { Title } from './sign-up-caregiver-steps-styles'
import { InputField, CheckboxField } from 'components/inputs'

function PatientPersonalInfoTwoStep(): JSX.Element {
  const { control, watch } = useFormContext()
  const { patientName, type } = useSignUpCaregiverContext()

  const firstName = watch('firstName')

  useEffect(() => {
    registerPageAccess('Informação pessoal', {
      patient_name: patientName,
      source: type,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Box mb={10}>
        <Title>
          Por fim, informe os dados abaixo para confirmar que você é responsável
          por {firstName} no Wida.{' '}
        </Title>
      </Box>

      <Stack spacing={2}>
        <InputField
          mask='00/00/0000'
          name='birthdate'
          control={control}
          label='Data de nasc. do paciente (dd/mm/aaaa)'
        />
        <InputField
          mask='000.000.000-00'
          name='patientCpf'
          control={control}
          label='Digite o CPF do paciente'
        />
        <Box mb={5}>
          <CheckboxField
            control={control}
            name='patientWithoutCpf'
            label='Paciente não possui CPF'
          />
        </Box>
      </Stack>
    </>
  )
}

export default PatientPersonalInfoTwoStep
