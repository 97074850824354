import { Box, Typography } from '@mui/material'
import { usePatientCentralContext } from 'contexts'
import noPatientReportsImage from 'images/no-patient-reports.svg'
import { styled } from '@mui/material/styles'

function PatientReportsEmpty(): JSX.Element {
  const { patient } = usePatientCentralContext()

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      flexGrow={1}
    >
      <Box mb={'24px'} mt={'20vh'}>
        <img
          src={noPatientReportsImage}
          height='55px'
          alt='Lista de relatórios vazia'
        />
      </Box>
      <Box mb={1}>
        <TitleText>
          Você ainda não gerou nenhum relatório sobre {patient?.firstName}
        </TitleText>
      </Box>
      <Box display='flex' flexDirection='column' alignItems='center'>
        <DescriptionText>
          Utilize o botão "Gerar relatório" para criar um documento com os
          resultados de {patient?.firstName}. <br />
          Aqui você vai encontrar a lista completa de relatórios gerados para
          este paciente.
        </DescriptionText>
      </Box>
    </Box>
  )
}

const TitleText = styled(Typography)({
  color: '#949494',
  textAlign: 'center',
  fontFamily: 'DM Sans',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '36px',
})

const DescriptionText = styled(Typography)({
  color: '#949494',
  textAlign: 'center',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
})

export default PatientReportsEmpty
