import { useMemo } from 'react'
import { Box } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { QuestionButtonAnswerType } from 'types'
import optionsMap from './question-button-options'
import { OptionLabel } from './question-button-styles'

interface QuestionButtonProps {
  answerType: QuestionButtonAnswerType
  selected: boolean
  handleSelectClick: Function
  disabled: boolean
}

function QuestionButton({
  answerType,
  selected,
  handleSelectClick,
  disabled,
}: QuestionButtonProps) {
  const option = useMemo(() => optionsMap[answerType], [answerType])
  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      width='100px'
    >
      <IconButton
        sx={{
          padding: 0,
          margin: 0,
          height: '53px',
          width: '53px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          ...(selected
            ? {
                background: 'linear-gradient(to right, #F36F60, 70%, #01B3A7)',
              }
            : { border: '1px solid #9D9CB5', background: '#ffffff' }),
        }}
        onClick={() => {
          if (!disabled) handleSelectClick(answerType)
        }}
        disableRipple
      >
        {selected && (
          <Box
            sx={{
              padding: 0,
              margin: 0,
              height: '48px',
              width: '48px',
              backgroundColor: '#ffffff',
              borderRadius: '50%',
            }}
          />
        )}
      </IconButton>
      <Box mt={2}>
        <OptionLabel>{option.label}</OptionLabel>
      </Box>
    </Box>
  )
}

export default QuestionButton
