import { useState, useEffect } from 'react'
import { Box, Stack } from '@mui/material'
import { InputField, CheckboxField } from 'components/inputs'
import { Title } from '../../settings-styles'
import { NotificationChoiceText, ErrorMessage } from '../notifications-styles'
import { useFormContext } from 'react-hook-form'
import { useAuthContext } from 'contexts'

function NotificationsContactStep(): JSX.Element {
  const [atLeastOneErrorMessage, setAtLeastOneErrorMessage] = useState('')

  const { userInfos } = useAuthContext()
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext()

  useEffect(() => {
    const hasEmailCommunication =
      userInfos?.user?.communication?.includes('email')

    const hasWhatsappCommunication =
      userInfos?.user?.communication?.includes('whatsapp')

    setValue('whatsapp', userInfos?.user?.whatsapp || '')
    setValue('email', userInfos?.user?.email || '')
    setValue('enableEmailNotification', hasEmailCommunication)
    setValue('enableWhatsappNotification', hasWhatsappCommunication)
  }, [userInfos, setValue])

  useEffect(() => {
    setAtLeastOneErrorMessage(
      errors?.enableEmailNotification?.message ||
        errors?.enableWhatsappNotification?.message ||
        ''
    )
  }, [
    errors?.enableEmailNotification?.message,
    errors?.enableWhatsappNotification?.message,
  ])

  return (
    <Stack width='100%'>
      <Stack display='flex' flexDirection='column' spacing={2} mb={3}>
        <Title>Notificações</Title>
      </Stack>
      <Stack spacing={2}>
        <InputField
          mask='(00) 00000-0000'
          name='whatsapp'
          control={control}
          label='WhatsApp'
        />
        <InputField
          name='email'
          control={control}
          label='E-mail'
          invalidChars={[' ']}
        />
      </Stack>
      <Stack display='flex' flexDirection='column' spacing={2} my={3}>
        <NotificationChoiceText>
          Como você gostaria de ser notificado de que há novas perguntas para
          você no Wida?
        </NotificationChoiceText>
      </Stack>
      <Stack spacing={10} direction='row'>
        <CheckboxField
          name='enableWhatsappNotification'
          control={control}
          label='WhatsApp'
          size='medium'
          notShowError={Boolean(atLeastOneErrorMessage)}
        />
        <CheckboxField
          name='enableEmailNotification'
          control={control}
          label='E-mail'
          size='medium'
          notShowError={Boolean(atLeastOneErrorMessage)}
        />
      </Stack>
      <Box>
        {atLeastOneErrorMessage && (
          <ErrorMessage>{atLeastOneErrorMessage}</ErrorMessage>
        )}
      </Box>
    </Stack>
  )
}

export default NotificationsContactStep
