import { Box } from '@mui/material'

import Button from 'components/button/button'
import RouteLayout from 'components/route-layout/route-layout'

import { sxButton, Text, UserName } from '../start-questionnaire-styles'

function FirstComponent({ type, handleGoNext, patientInfo, userInfo }: any) {
  const isMale = 'HE' === patientInfo.pronoun

  return (
    <RouteLayout bg='gradient'>
      <Box
        m={5}
        flexGrow={1}
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        alignItems='center'
      >
        <Box />
        <Box>
          {type === 'patient' ? (
            <>
              <UserName>
                Oi, <b>{patientInfo?.firstName || '_'}</b>,
              </UserName>
              <Box my={3}>
                <Text>
                  As perguntas que você verá a seguir foram selecionadas
                  especificamente para você.
                </Text>
              </Box>
            </>
          ) : (
            <>
              <UserName>
                Oi, <b>{userInfo?.firstName || '_'}</b>,
              </UserName>
              <Box my={3}>
                <Text>
                  As perguntas que você verá a seguir sobre{' '}
                  <b>{patientInfo?.firstName || '_'}</b> foram selecionadas por
                  um profissional de saúde especificamente para{' '}
                  {isMale ? 'ele' : 'ela'}.
                </Text>
              </Box>
            </>
          )}
        </Box>
        <Box width={223}>
          <Button color='white' onClick={handleGoNext} sx={sxButton}>
            Próximo
          </Button>
        </Box>
      </Box>
    </RouteLayout>
  )
}

export default FirstComponent
