interface IOrderDict {
  [key: string]: number
}

interface IDict {
  [key: string]: string
}

export const answersDictFromBack: IDict = {
  '0': 'no',
  '1': 'soso',
  '2': 'yes',
  skip: 'skip',
}

export const answersDictToBack: IDict = {
  no: '0',
  soso: '1',
  yes: '2',
  skip: 'skip',
}

export const answersOrderDict: IOrderDict = {
  yes: 1,
  soso: 2,
  no: 3,
  skip: 4,
}

export const ANSWERS: string[] = ['yes', 'soso', 'no', 'skip']

export const COLOR_YES = '#FF824B'
export const COLOR_SOSO = '#FFE989'
export const COLOR_NO = '#D6F1CE'

export const dictColorAnswers: any = {
  yes: COLOR_YES,
  soso: COLOR_SOSO,
  no: COLOR_NO,
}
