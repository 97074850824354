import { useEffect } from 'react'
import { Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { TextFieldClinic, ButtonClinic } from 'components'
import { useSignUpClinicContext } from 'contexts'
import { registerPageAccess } from 'helpers'
import {
  Title,
  ChangeLoginTypeText,
  ChangeLoginTypeLink,
  InfoCenterText,
} from './sign-up-clinic-steps-styles'

function AccessStep(): JSX.Element {
  const { control } = useFormContext()
  const { goNextStep, loading } = useSignUpClinicContext()
  const navigate = useNavigate()

  useEffect(() => {
    registerPageAccess('Cadastro clínico: primeira página')
  }, [])

  return (
    <Box flexGrow={1} display='flex' flexDirection='column'>
      <Box flexGrow={1} display='flex' flexDirection='column' width='100%'>
        <Title>Crie sua conta</Title>
        <Box my={{ xs: 0, sm: 2}}>
          <ChangeLoginTypeText>
            Não é profissional de saúde?{' '}
            <ChangeLoginTypeLink onClick={() => navigate('/signin/patient')}>
              Clique aqui
            </ChangeLoginTypeLink>
          </ChangeLoginTypeText>
        </Box>
        <Box 
          width={{ xs: '224px', sm: '280px' }} 
          alignSelf='center' 
          mt={{xs: 4, sm: 3, lg: 7}} 
          mb={{xs: 4, sm: 5, lg: 9}}>
          <TextFieldClinic
            mask='000.000.000-00'
            name='cpf'
            control={control}
            label='CPF'
          />
        </Box>
        <Box alignSelf='center'>
          <InfoCenterText>
            Usamos o número do CPF para garantir que nenhuma conta no Wida
            esteja duplicada.
          </InfoCenterText>
        </Box>
      </Box>
      <Box
        width='100%'
        display='flex'
        justifyContent={{ xs: 'center', lg: 'flex-end' }}
        mt={5}
      >
        <ButtonClinic
          width={150}
          variant='contained'
          size='small'
          color='purple'
          fontWeight='bold'
          type='button'
          onClick={goNextStep}
          loading={loading}
        >
          Próximo
        </ButtonClinic>
      </Box>
    </Box>
  )
}

export default AccessStep
