import { useFormContext } from 'react-hook-form'
import { Graphic, Sankey } from 'components/results'
import { Stack, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { formatDbDateToLabel, generateQuestionQuantities } from 'helpers'
import {
  DividerLine,
  DateLabel,
  ResponderName,
  ResponderRelation,
} from './timeline-styles'
import { useResultsContext } from 'contexts'
import { useMemo } from 'react'

function Timeline({
  timeline,
  mappedResponses,
  handleSelectSankey,
}: any): JSX.Element {
  const { watch } = useFormContext()
  const { filteredDataset } = useResultsContext()

  const [activeProportion, selectedTags] = watch([
    'activeProportion',
    'selectedTags',
  ])

  const onlyOneTag: boolean = selectedTags.length === 1

  const maxQuestions = useMemo(() => {
    return filteredDataset.reduce((acc: any, { totalAsked }: any) => {
      return totalAsked > acc ? totalAsked : acc
    }, 0)
  }, [filteredDataset])

  return (
    <Container>
      {onlyOneTag && <DividerLine isLongType={onlyOneTag} />}
      <Stack direction='row' justifyContent='center'>
        {timeline?.map((timelineResponse: any, index: number) => {
          const responseDate = formatDbDateToLabel(
            timelineResponse.responseDate
          )

          const quantities = generateQuestionQuantities(
            timelineResponse.questions
          )

          const firstName = timelineResponse.responder.name.split(' ')[0] || ''
          const relation = timelineResponse.responder.relation

          const hasSquareBorder = mappedResponses
            .filter(({ active }: any) => active)
            .some(
              ({ origin, destination }: any) =>
                timelineResponse.formRequestId === origin.formRequestId ||
                timelineResponse.formRequestId === destination.formRequestId
            )

          const key = `${timelineResponse.formRequestId}:${timelineResponse.tag.id}`

          return (
            <Stack
              alignItems='center'
              key={key}
              minWidth='200px'
              width='200px'
              maxWidth='200px'
            >
              <Graphic
                quantities={quantities}
                maxTagQuestions={maxQuestions}
                totalAsked={timelineResponse.totalAsked}
                onClick={
                  onlyOneTag ? handleSelectSankey(timelineResponse) : undefined
                }
                hasSquareBorder={hasSquareBorder}
                lastGraph={index === timeline.length - 1}
                isProportional={activeProportion}
              />
              <Stack alignItems='center' mt={1}>
                {!onlyOneTag && (
                  <Stack alignItems='center' mt={0.5}>
                    <ResponderName>{firstName}</ResponderName>
                    <ResponderRelation>({relation})</ResponderRelation>
                  </Stack>
                )}
                <Box mt={0.5}>
                  <DateLabel>{responseDate}</DateLabel>
                </Box>
              </Stack>
              {!onlyOneTag && <DividerLine isLongType={onlyOneTag} />}
            </Stack>
          )
        })}
      </Stack>
      {onlyOneTag && (
        <Sankey responses={mappedResponses} maxTagQuestions={maxQuestions} />
      )}
    </Container>
  )
}

const Container = styled(Box)({
  position: 'relative',
})

export default Timeline
