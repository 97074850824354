import { useForm } from 'react-hook-form'
import { passwordResetResolver } from 'resolvers'
import { IFormInput } from './password-reset-types'

function useFormPasswordReset() {
  const methods = useForm<IFormInput>({
    mode: 'onBlur',
    resolver: passwordResetResolver,
    defaultValues: {
      cpf: '',
    },
  })

  return methods
}

export default useFormPasswordReset
