import { useEffect, useRef, useState } from 'react'
import { createRecordFile, deleteRecordFile, getRecordFile } from 'services'
import {
  Editor,
  EditorState,
  RichUtils,
  getDefaultKeyBinding,
  convertToRaw,
  convertFromRaw,
  Modifier,
  SelectionState,
} from 'draft-js'
import RichTextHeader from './rich-text-header'
import RichTextFooter from './rich-text-footer'
import '../rich-text.css'
import '../../../../../node_modules/draft-js/dist/Draft.css'
import { Box, Button, CircularProgress, Divider } from '@mui/material'
import { CloseOutlined } from '@mui/icons-material'
import { useDebounce, useNotification } from 'hooks'

// chars to insert manually at the text
const errorChars = ['´', '`', '¨']

function RichTextEditor({ saveRecord, draft, loading }: any): JSX.Element {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [hasText, setHasText] = useState<boolean>(false)
  const [className, setClassName] = useState<string>('RichEditor-editor')
  const [selectedFiles, setSelectedFiles] = useState<any>([])
  const [loadingFiles, setLoadingFiles] = useState<any>([])
  // const [textAlignment, setTextAlignment] = useState<any>('left')
  const editorRef = useRef<any>()

  const [saveStatus, setSaveStatus] = useState<
    'no' | 'saving' | 'processing' | 'done'
  >('no')
  const { successToast, errorToast } = useNotification()

  // debounce typing
  const [typingTerm, setTypingTerm] = useState('')
  const debounceTyping = useDebounce(typingTerm, 1000)

  useEffect(() => {
    if (draft) {
      setEditorState(
        EditorState.createWithContent(convertFromRaw(draft.richText))
      )
      setSelectedFiles(draft.files || [])
    } else {
      save()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const contentState = editorState.getCurrentContent()
    if (!contentState.hasText()) {
      setHasText(false)
      if (contentState.getBlockMap().first().getType() !== 'unstyled') {
        setClassName(className + ' RichEditor-hidePlaceholder')
      }
    } else {
      setHasText(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorState])

  useEffect(() => {
    if (!!draft && editorState.getCurrentContent().hasText()) {
      save()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceTyping])

  const focus = () => editorRef.current.focus()
  const onChange = (editorState: any) => {
    setSaveStatus('no')
    setTypingTerm(editorState.getCurrentContent())
    setEditorState(editorState)
  }

  const save = async () => {
    setSaveStatus('saving')
    const data = buildData()
    await saveRecord(data)
    setSaveStatus('done')
  }

  const handleFinish = async () => {
    setSaveStatus('saving')
    const data = buildData()
    await saveRecord(data, true)
    successToast('Criado com sucesso!')
  }

  const buildData = () => {
    const data: any = {
      richText: convertToRaw(editorState.getCurrentContent()),
    }
    if (draft) {
      data.id = draft.id
    }
    return data
  }

  const handleUploadFiles = async (filesToUpload: any) => {
    try {
      setLoadingFiles(Array.from(filesToUpload).map((f: any) => f.name))
      setSaveStatus('processing')
      const files: any = await createRecordFile(filesToUpload, draft.id)
      setSelectedFiles((prevFiles: any) => [...prevFiles, ...files])
    } catch (error: any) {
      errorToast(error?.message || 'Erro desconhecido')
    } finally {
      setSaveStatus('done')
      setLoadingFiles([])
    }
  }

  const handleOpenFile = async (file: any) => {
    try {
      setSaveStatus('processing')
      const downloadFile = await getRecordFile(file.id, draft.id)

      // create download file and romove it
      const href = URL.createObjectURL(downloadFile)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', file.name)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    } catch (error: any) {
      errorToast(error)
    } finally {
      setSaveStatus('done')
    }
  }

  const handleRemoveFile = async (id: string) => {
    try {
      setSaveStatus('processing')
      await deleteRecordFile(id, draft.id)
      setSelectedFiles((prevFiles: any) =>
        prevFiles.filter((file: any) => file.id !== id)
      )
    } catch (error: any) {
      errorToast(error)
    } finally {
      setSaveStatus('done')
    }
  }

  function handleKeyCommand(command: any, editorState: EditorState): any {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      onChange(newState)
      return true
    }
    return false
  }

  function handleBeforeInput(char: string, editorState: EditorState): any {
    if (errorChars.includes(char)) {
      // get current editor state
      const currentContent = editorState.getCurrentContent()
      const selection = editorState.getSelection()

      //insert text at the selection created above
      const textWithInsert = Modifier.insertText(
        currentContent,
        selection,
        `${char} `
      )
      const editorWithInsert = EditorState.push(
        editorState,
        textWithInsert,
        'insert-characters'
      )

      // also focuses cursor after of the addition
      const newSelection = new SelectionState({
        anchorKey: selection.getAnchorKey(),
        anchorOffset: selection.getAnchorOffset() + 2,
        focusKey: selection.getFocusKey(),
        focusOffset: selection.getFocusOffset() + 2,
      })
      setEditorState(EditorState.forceSelection(editorWithInsert, newSelection))
      return true
    }
    return false
  }

  function mapKeyToEditorCommand(e: any): any {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(e, editorState, 4 /* maxDepth */)
      if (newEditorState !== editorState) {
        onChange(newEditorState)
      }
      return
    }
    return getDefaultKeyBinding(e)
  }

  function getBlockStyle(block: any) {
    return block.getType()
  }

  return (
    <div className='RichEditor-root'>
      {/* Header */}
      <RichTextHeader
        onChange={onChange}
        editorState={editorState}
        handleUploadFiles={handleUploadFiles}
        setSelectedFiles={setSelectedFiles}
        // textAlignment={textAlignment}
        // setTextAlignment={setTextAlignment}
      />
      <Divider />
      <Box className={className} onClick={focus}>
        <Editor
          placeholder='Digite aqui'
          ref={editorRef}
          editorState={editorState}
          // textAlignment={textAlignment}
          blockStyleFn={getBlockStyle}
          handleKeyCommand={handleKeyCommand}
          handleBeforeInput={handleBeforeInput}
          keyBindingFn={mapKeyToEditorCommand}
          onChange={onChange}
        />
      </Box>

      {/* Files */}
      <Box mt={2}>
        {selectedFiles.map((file: any) => (
          <Box
            key={file.id}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{
              wordBreak: 'break-word',
              width: '50%',
              backgroundColor: '#f9f9fb',
              borderRadius: '4px',
              marginBottom: '5px',
              paddingX: '5px',
            }}
          >
            <Button
              onClick={() => handleOpenFile(file)}
              sx={{
                textTransform: 'none',
                color: '#999',
                width: '100%',
                justifyContent: 'start',
              }}
            >
              {file.name}
            </Button>
            <CloseOutlined
              onClick={() => handleRemoveFile(file.id)}
              fontSize={'small'}
              sx={{
                color: '#999',
                padding: '0',
                marginX: '5px',
                cursor: 'pointer',
                '&: hover': {
                  opacity: 0.6,
                },
              }}
            />
          </Box>
        ))}
        {loadingFiles.map((file: any, i: number) => (
          <Box
            key={i}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{
              width: '50%',
              backgroundColor: '#f9f9fb',
              borderRadius: '4px',
              marginBottom: '5px',
              paddingX: '5px',
            }}
          >
            <Button
              sx={{
                textTransform: 'none',
                color: '#999',
                width: '100%',
                justifyContent: 'start',
              }}
            >
              <CircularProgress
                size={'10px'}
                sx={{ color: '#01B3A7', marginRight: '10px' }}
              />
              {file}
            </Button>
          </Box>
        ))}
      </Box>
      <Divider sx={{ marginBottom: '10px' }} />

      {/* Footer */}
      <RichTextFooter
        saveStatus={saveStatus}
        canFinish={!loading && !!draft && (hasText || !!selectedFiles.length)}
        handleFinish={handleFinish}
        save={save}
      />
    </div>
  )
}

export default RichTextEditor
