/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from 'react'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { Box, CircularProgress } from '@mui/material'
import {
  QuestionnaireCategory,
  QuestionnaireSkippedCategory,
  FinishQuestionnaire,
  Question,
} from 'components'
import {
  answerUserQuestionnaire,
  getFormRequest,
  getPatientAlreadyAnsweredForm,
} from 'services'
import {
  prepareQuestions,
  isUserPatient,
  assembleQuestionnaireSteps,
  addSkippedCategoryToQuestionnaireSteps,
  addSkippedQuestionsToQuestionnaireSteps,
  registerTrack,
} from 'helpers'
import { useAuthContext } from 'contexts'
import { useNotification } from 'hooks'
import FirstComponent from 'components/start-questionnaire/first-time/first-component'
import SecondComponent from 'components/start-questionnaire/first-time/second-component'
import ThirdComponent from 'components/start-questionnaire/first-time/third-component'
import Return from 'components/start-questionnaire/return'

interface QuestionType {
  text: string
  example: string
  answer: string
}

const answersEnum: any = {
  no: '0',
  'so-so': '1',
  yes: '2',
}

const directionDict: any = {
  backward: { out: 'left', in: 'right' },
  forward: { out: 'right', in: 'left' },
}

function Questionnaire() {
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [steps, setSteps] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const [loadingAnswer, setLoadingAnswer] = useState(false)
  const [questions, setQuestions] = useState<QuestionType[]>([])
  const [stepQuestions, setStepQuestions] = useState<number>(0)
  const [skippedQuestions, setSkippedQuestions] = useState<any[]>([])
  const [stepSkippedQuestions, setStepSkippedQuestions] = useState<number>(0)
  const [startedSkippedQuestions, setStartedSkippedQuestions] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState(false)
  const [firstTimeStep, setFirstTimeStep] = useState<number>(0)
  const [isFirstTime, setIsFirstTime] = useState<boolean>(false)
  const [isFirstTimeLoading, setIsFirstTimeLoading] = useState<boolean>(true)

  const [questionAnimation, setQuestionAnimation] = useState<any>({
    direction: 'left',
    active: true,
  })

  const navigate = useNavigate()
  const { warnToast, errorToast } = useNotification()
  const location: any = useLocation()
  const { user } = useAuthContext()

  const form = useMemo(() => location?.state?.form, [location])

  useEffect(() => {
    const checkValidForm = async () => {
      try {
        if (!Boolean(form)) {
          warnToast('Você não tem um questionário para responder')
          navigate('/')
          return
        }

        const formRequest = await getFormRequest(form.formRequestId)

        if (!formRequest?.isActive) {
          warnToast('Você não tem um questionário para responder')
          navigate('/')
          return
        }

        let responderQuestions: any[] = []

        if (form.informantPatientRelation?.informantUserGroup) {
          const groupTags = form.userGroupTags.find(
            ({ usergroup }: any) =>
              usergroup ===
              form.informantPatientRelation.informantUserGroup.name
          )?.tags

          responderQuestions = groupTags
            ? form.questions.filter(({ tags }: any) =>
                tags.some((tag: string) => groupTags.includes(tag))
              )
            : []
        } else {
          responderQuestions = form.questions
        }

        if (!Boolean(responderQuestions.length)) {
          warnToast('Você não tem um questionário para responder')
          navigate('/')
          return
        }

        const questions: QuestionType[] = prepareQuestions(
          responderQuestions || [],
          form.patientPersonalInfo.pronoun,
          isUserPatient(user) ||
            form.informantPatientRelation?.relation?.name === 'Sou eu'
            ? 'patient'
            : 'other'
        )

        const steps = assembleQuestionnaireSteps(
          questions,
          user,
          form.informantPatientRelation?.relation?.name === 'Sou eu'
        )
        setSteps(steps)

        setQuestions(questions)
        setLoading(false)
      } catch (error) {
        warnToast('Um erro ocorreu, por favor tente novamente.')
        navigate('/')
        return
      }
    }

    checkValidForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsFirstTimeLoading(true)
        const data = await getPatientAlreadyAnsweredForm(user.userId)
        setIsFirstTime(data.hasAnswered)
      } catch (error: any) {
        errorToast('Um erro ocorreu, por favor tente novamente.')
      } finally {
        setIsFirstTimeLoading(false)
      }
    }

    loadData()
  }, [])

  const handleAnimation = (direction: string) => {
    setQuestionAnimation({
      direction: directionDict[direction].out,
      active: false,
    })

    setTimeout(() => {
      setQuestionAnimation({
        direction: directionDict[direction].in,
        active: true,
      })
    }, 300)
  }

  const goNextStep = () => {
    handleAnimation('forward')
    setCurrentStep((currentStep) => currentStep + 1)
  }

  const goBackStep = () => {
    if (currentStep === 0) navigate('/')

    if (currentStep > 0) {
      handleAnimation('backward')
      setCurrentStep((currentStep) => currentStep - 1)
    }

    const previousStepIndex = currentStep - 1

    if (steps[previousStepIndex]?.type === 'question') {
      setStepQuestions((stepQuestions) => stepQuestions - 1)
    } else if (steps[previousStepIndex]?.type === 'skipped-question') {
      setStepSkippedQuestions(
        (stepSkippedQuestions) => stepSkippedQuestions - 1
      )
    }
  }

  const skipQuestion = () => {
    const currentQuestion = steps[currentStep]?.payload?.question

    registerTrack('Pular pergunta', {
      user_id: user.userId,
      patient_id: form.patientPersonalInfo.patientId,
      identifier: currentQuestion?.identifier,
      source: isUserPatient(user) ? 'patient' : 'caregiver',
      order: stepQuestions,
    })

    goForwardQuestionStep(questions)
    goNextStep()
  }

  const goForwardQuestionStep = (updatedQuestions?: any) => {
    const beforeSendStepIndex = steps.length - 2

    if (currentStep < beforeSendStepIndex) {
      if (startedSkippedQuestions) {
        setStepSkippedQuestions(
          (stepSkippedQuestions) => stepSkippedQuestions + 1
        )
      } else {
        setStepQuestions((stepQuestions) => stepQuestions + 1)
      }
    } else {
      const skippedQuestions = updatedQuestions.filter(
        ({ answer }: any) => !answer
      )
      const hasSkippedQuestions = Boolean(skippedQuestions.length)

      if (!hasSkippedQuestions) {
        sendQuestionnaire(updatedQuestions)
      } else {
        if (!startedSkippedQuestions) {
          const stepsWhithSkippedCategory =
            addSkippedCategoryToQuestionnaireSteps(steps)
          setSteps(
            addSkippedQuestionsToQuestionnaireSteps(
              stepsWhithSkippedCategory,
              skippedQuestions
            )
          )
          setSkippedQuestions(skippedQuestions)
          setStartedSkippedQuestions(true)
        } else {
          sendQuestionnaire(updatedQuestions)
        }
      }
    }
  }

  const handleChooseAnswer = (answer: string) => {
    const currentQuestion = steps[currentStep]?.payload?.question

    const oldQuestionState = questions.find(
      ({ id }: any) => id === currentQuestion?.id
    )

    const hasToDeselectAnswer = oldQuestionState?.answer === answer

    const newQuestions = questions.map((question: any) => {
      return question.id === currentQuestion?.id
        ? { ...question, answer: hasToDeselectAnswer ? '' : answer }
        : question
    })

    const answerDict: any = {
      yes: 'Sim',
      'so-so': 'Mais ou Menos',
      no: 'Não',
    }

    setQuestions(newQuestions)

    if (hasToDeselectAnswer) return

    registerTrack(`Responde ${answerDict[answer] as string}`, {
      user_id: user.userId,
      patient_id: form.patientPersonalInfo.patientId,
      identifier: currentQuestion?.identifier,
      source: isUserPatient(user) ? 'patient' : 'caregiver',
      order: stepQuestions,
    })

    // * animação do clique na resposta
    setLoadingAnswer(true)
    setTimeout(() => {
      goForwardQuestionStep(newQuestions)
      goNextStep()
      setLoadingAnswer(false)
    }, 500)
  }

  const sendQuestionnaire = async (finalQuestions: any) => {
    try {
      setError(false)

      const questionsWithAnswers = finalQuestions
        .filter(({ answer }: any) => answer)
        .map((question: any) => ({
          questionId: question.id,
          answer: answersEnum[question.answer],
        }))

      if (questionsWithAnswers.length === 0) {
        errorToast('Nenhuma resposta registrada')
        navigate('/')
        return
      }

      const body: any = {
        formRequestId: location.state.form.formRequestId,
        questionsNAnswers: questionsWithAnswers,
      }

      setSubmitting(true)
      await answerUserQuestionnaire(body)
    } catch (error: any) {
      console.error(error)
      setError(true)
    } finally {
      setSubmitting(false)
    }
  }

  if (!Boolean(form)) return <Navigate to='/' replace />

  if (loading)
    return (
      <Box
        display='flex'
        flexGrow={1}
        alignItems='center'
        justifyContent='center'
        height={window.innerHeight}
      >
        <CircularProgress />
      </Box>
    )

  const firstTimeSteps = [
    {
      component: (
        <FirstComponent
          {...steps[currentStep].payload}
          patientInfo={form.patientPersonalInfo}
          userInfo={user.personalInfos}
          handleGoNext={() => setFirstTimeStep(1)}
        />
      ),
    },
    {
      component: (
        <SecondComponent
          {...steps[currentStep].payload}
          patientInfo={form.patientPersonalInfo}
          handleGoNext={() => setFirstTimeStep(2)}
        />
      ),
    },
    {
      component: (
        <ThirdComponent
          {...steps[currentStep].payload}
          patientInfo={form.patientPersonalInfo}
          handleGoNext={goNextStep}
        />
      ),
    },
  ]

  if (steps[currentStep].type === 'start') {
    if (isFirstTimeLoading) return <></>
    if (!isFirstTime) {
      return firstTimeSteps[firstTimeStep].component
    } else {
      return (
        <Return
          {...steps[currentStep].payload}
          patientInfo={form.patientPersonalInfo}
          handleGoNext={goNextStep}
        />
      )
    }
  }

  if (steps[currentStep].type === 'cover') {
    return (
      <QuestionnaireCategory
        {...steps[currentStep].payload}
        handleGoNext={goNextStep}
        handleGoBack={goBackStep}
        patientInfo={form.patientPersonalInfo}
      />
    )
  }

  if (steps[currentStep].type === 'question') {
    return (
      <Question
        question={questions.find(
          (question: any) =>
            question.id === steps[currentStep]?.payload?.question?.id
        )}
        loadingAnswer={loadingAnswer}
        callbackChooseAnswer={handleChooseAnswer}
        step={stepQuestions}
        totalSteps={questions.length}
        goBackStep={goBackStep}
        goForwardStep={skipQuestion}
        patientInfo={form.patientPersonalInfo}
        questionAnimation={questionAnimation}
        formId={form.formId}
      />
    )
  }

  if (steps[currentStep].type === 'skipped-question') {
    return (
      <Question
        question={questions.find(
          (question: any) =>
            question.id === steps[currentStep]?.payload?.question?.id
        )}
        loadingAnswer={loadingAnswer}
        callbackChooseAnswer={handleChooseAnswer}
        step={stepSkippedQuestions}
        totalSteps={skippedQuestions.length}
        goBackStep={goBackStep}
        goForwardStep={skipQuestion}
        patientInfo={form.patientPersonalInfo}
        questionAnimation={questionAnimation}
        formId={form.formId}
      />
    )
  }

  if (steps[currentStep].type === 'skip') {
    return (
      <QuestionnaireSkippedCategory
        skippedQuestions={skippedQuestions}
        handleYes={() => {
          registerTrack('Clica Responder Puladas', {
            user_id: user.userId,
            patient_id: form.patientPersonalInfo.patientId,
            qtd_skipped: skippedQuestions.length,
            source: isUserPatient(user) ? 'patient' : 'caregiver',
          })
          goNextStep()
        }}
        handleNo={() => {
          registerTrack('Clica Não Responder Puladas', {
            user_id: user.userId,
            patient_id: form.patientPersonalInfo.patientId,
            qtd_skipped: skippedQuestions.length,
            source: isUserPatient(user) ? 'patient' : 'caregiver',
          })
          sendQuestionnaire(questions)
          setCurrentStep(steps.length - 1)
        }}
      />
    )
  }

  if (steps[currentStep].type === 'finish') {
    return (
      <FinishQuestionnaire
        patientInfo={form.patientPersonalInfo}
        submitting={submitting}
        error={error}
        resendQuestionnaire={() => sendQuestionnaire(questions)}
        isInformantPatient={
          form.informantPatientRelation?.relation?.name === 'Sou eu'
        }
        formId={form.formId}
      />
    )
  }

  return <></>
}

export default Questionnaire
