import { useState } from 'react'
import { Controller } from 'react-hook-form'
import { FormControl, Box } from '@mui/material'
import {
  OldText,
  EmptyNewText,
  StyledCheckbox,
  DividerOptions,
  StyledMenuItem,
  StyledSelectMui,
} from './results-select-styles'

interface OptionType {
  value: string
  label: string
}

function ResultsSelect({
  name,
  placeholder = 'Selecione uma opção',
  placeholderAll = 'Todos selecionados',
  options,
  newOptions = [],
  oldOptions = [],
  control,
  singleSelect = false,
  handleClickOldLabel = () => {},
  emptyNewText = '',
  removeDivider = false,
}: any): JSX.Element {
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <FormControl variant='standard'>
          <StyledSelectMui
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={value}
            onChange={(event: any, child?: any) => {
              if (singleSelect) {
                onChange([child!.props!.value])
                handleClose()
                return
              }

              // * multiple: não permite ficar sem nenhuma selecionada
              if (event.target.value.length === 0) return

              onChange(event)
            }}
            displayEmpty
            color='success'
            disableUnderline
            renderValue={(selectedValues: any) => {
              if (selectedValues.length === 1) {
                return options.find(
                  ({ value }: any) => value === selectedValues[0]
                )?.label
              }

              return selectedValues.length === options.length
                ? placeholderAll
                : placeholder
            }}
            multiple
          >
            {newOptions.map(({ value: optValue, label }: OptionType) => (
              <StyledMenuItem
                key={optValue}
                value={optValue}
                multiple={!singleSelect}
              >
                {!singleSelect && (
                  <StyledCheckbox checked={value.includes(optValue)} />
                )}
                {label}
              </StyledMenuItem>
            ))}
            {!removeDivider && emptyNewText && newOptions.length === 0 && (
              <Box m={1} display='flex' justifyContent='center'>
                <EmptyNewText>{emptyNewText}</EmptyNewText>
              </Box>
            )}
            {!removeDivider && oldOptions.length !== 0 && <DividerOptions />}
            {!removeDivider && oldOptions.length !== 0 && (
              <Box display='flex' justifyContent='flex-end' mx={1} mt={1}>
                <OldText
                  onClick={!singleSelect ? handleClickOldLabel : () => {}}
                >
                  + 90 dias
                </OldText>
              </Box>
            )}
            {oldOptions.map(({ value: optValue, label }: OptionType) => (
              <StyledMenuItem
                key={optValue}
                value={optValue}
                multiple={!singleSelect}
              >
                {!singleSelect && (
                  <StyledCheckbox checked={value.includes(optValue)} />
                )}
                {label}
              </StyledMenuItem>
            ))}
          </StyledSelectMui>
        </FormControl>
      )}
    />
  )
}

export default ResultsSelect
