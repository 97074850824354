import { useForm } from 'react-hook-form'
import { clinicProfileContactsResolver } from 'resolvers'

function useContactsTabForm({ clinic }: any) {
  const methods = useForm<any>({
    mode: 'onBlur',
    resolver: clinicProfileContactsResolver,
    defaultValues: {
      email: clinic.user.email,
      telephone: clinic.user.whatsapp,
      communication: {
        email: clinic?.user?.communication?.includes('email'),
        whatsapp: clinic?.user?.communication?.includes('whatsapp'),
      },
    },
  })

  return methods
}

export default useContactsTabForm
