import {
  commomProps,
  HORIZONTAL_CENTER,
  VERTICAL_START,
  VERTICAL_CENTER,
  VERTICAL_END,
} from './question-association-constants'

function ConnectionLineStart(): JSX.Element {
  return (
    <line
      x1={HORIZONTAL_CENTER}
      y1={VERTICAL_CENTER}
      x2={HORIZONTAL_CENTER}
      y2={VERTICAL_END}
      {...commomProps}
    />
  )
}

function ConnectionLineMiddle(): JSX.Element {
  return (
    <line
      x1={HORIZONTAL_CENTER}
      y1={VERTICAL_START}
      x2={HORIZONTAL_CENTER}
      y2={VERTICAL_END}
      {...commomProps}
    />
  )
}

function ConnectionLineEnd(): JSX.Element {
  return (
    <line
      x1={HORIZONTAL_CENTER}
      y1={VERTICAL_START}
      x2={HORIZONTAL_CENTER}
      y2={VERTICAL_CENTER}
      {...commomProps}
    />
  )
}

export const connectionLineDict = {
  start: <ConnectionLineStart />,
  middle: <ConnectionLineMiddle />,
  end: <ConnectionLineEnd />,
}
