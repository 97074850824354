export const statusDict: any = {
  notRegistered: {
    label: 'aguardando cadastro',
    color: '#F36F60',
    disabledButton: false,
  },
  waitingForAnswer: {
    label: 'aguardando resposta',
    color: '#BDBDBD',
    disabledButton: true,
  },
  answered: { label: 'respondido', color: '#01B3A7', disabledButton: true },
  notSent: { label: '', color: 'transparent', disabledButton: false },
}

export const MAX_CARACTERES = 16
