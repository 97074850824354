import { Line } from '@react-pdf/renderer'
import {
  commomProps,
  HORIZONTAL_START,
  HORIZONTAL_CENTER,
  VERTICAL_CENTER,
} from './question-association-constants'

function PointingLine(): JSX.Element {
  return (
    <Line
      x1={HORIZONTAL_START}
      y1={VERTICAL_CENTER}
      x2={HORIZONTAL_CENTER}
      y2={VERTICAL_CENTER}
      {...commomProps}
    />
  )
}

export default PointingLine
