import ButtonBase from '@mui/material/ButtonBase'
import { styled } from '@mui/material/styles'

const buttonColors: any = {
  purple: { base: '#8D92BE', shadow: '#7E82A6', disabled: '#CBCCCF' },
  orange: { base: '#EE7262', shadow: '#D57164', disabled: '#CBCCCF' },
  green: { base: '#01B3A7', shadow: '#079C92', disabled: '#CBCCCF' },
}

const buttonTextSizes: any = {
  small: {
    fontSize: '14px',
    lineHeight: '18px',
    padding: '8px 14px',
  },
  medium: {
    fontSize: '16px',
    lineHeight: '20px',
    padding: '12px 16px',
  },
  large: {
    fontSize: '20px',
    lineHeight: '24px',
    padding: '16px 20px',
  },
}

const fontWeights: any = {
  regural: 400,
  bold: 700,
}

const buttonVariants: any = {
  contained: ({ color }: any) => ({
    border: '1px solid transparent',
    backgroundColor: buttonColors[color].base,
    color: '#FFFFFF',
    svg: {
      fill: '#FFFFFF',
    },

    '&:hover, &:active': {
      boxShadow: 'none',
      backgroundColor: buttonColors[color].shadow,
      borderColor: buttonColors[color].shadow,
    },
    '&:disabled': {
      boxShadow: 'none',
      backgroundColor: buttonColors[color].disabled,
      borderColor: buttonColors[color].disabled,
      color: '#FFFFFF',
      svg: {
        fill: '#FFFFFF',
      },
    },
  }),
  outlined: ({ color }: any) => ({
    border: `1px solid ${buttonColors[color].base}`,
    backgroundColor: '#FDFDFD',
    color: buttonColors[color].base,
    svg: {
      fill: buttonColors[color].base,
    },

    '&:hover, &:active': {
      boxShadow: 'none',
      backgroundColor: buttonColors[color].base,
      borderColor: buttonColors[color].base,
      color: '#FFFFFF',
      svg: {
        fill: '#FFFFFF',
      },
    },

    '&:disabled': {
      boxShadow: 'none',
      backgroundColor: '#FDFDFD',
      borderColor: '#DCDCDC',
      color: '#DCDCDC',
      svg: {
        fill: '#DCDCDC',
      },
    },
  }),
  text: ({ color }: any) => ({
    border: '1px solid transparent',
    backgroundColor: '#FFFFFF',
    color: buttonColors[color].base,
    padding: '14px 14px',
    svg: {
      fill: buttonColors[color].base,
    },

    '&:hover, &:active': {
      boxShadow: 'none',
      color: buttonColors[color].shadow,

      svg: {
        fill: buttonColors[color].shadow,
      },
    },
    '&:disabled': {
      boxShadow: 'none',
      color: buttonColors[color].disabled,
      svg: {
        fill: buttonColors[color].disabled,
      },
    },
  }),
}

export const Button = styled(ButtonBase)<any>(
  ({
    color = 'purple',
    size = 'medium',
    variant = 'contained',
    fontWeight = 'bold',
    width,
    height,
    borderRadius = '50px',
  }) => {
    return {
      ...(width && { width }),
      ...(height && { height }),
      fontFamily: 'Quicksand',
      boxShadow: 'none',
      textTransform: 'none',
      borderRadius: borderRadius,
      ...buttonTextSizes[size],
      fontStyle: 'normal',
      textAlign: 'center',
      fontWeight: fontWeights[fontWeight],
      ...buttonVariants[variant]({ color }),
    }
  }
)
