import { useEffect, KeyboardEvent } from 'react'
import { Stack, Box } from '@mui/material'
import { Button, TextField } from 'components'
import { useNotification } from 'hooks'
import { sendEmailInvitePatient, sendLastFormByEmail } from 'services'
import { registerTrack } from 'helpers'
import { useInvitePatientContext } from 'contexts'
import { emailSchema } from './email-schema'
import { useForm } from 'react-hook-form'

interface IEmailFormProps {
  invitationToken: string
  invitationCode: string
  direction: 'row' | 'column'
  isInvite?: boolean
  value?: string
  formId?: string
  disabled?: boolean
  setAlreadySent?: any
  source: string
}

function EmailForm({
  invitationToken,
  invitationCode,
  isInvite = true,
  value = '',
  formId,
  disabled = false,
  direction,
  setAlreadySent = () => {},
  source,
}: IEmailFormProps) {
  const { control, handleSubmit, setValue } = useForm()
  const { successToast, errorToast } = useNotification()
  const { newQuestionnaireInfos } = useInvitePatientContext()

  const invite = newQuestionnaireInfos?.invite

  useEffect(() => {
    if (value || invite?.email) setValue('email', value || invite?.email)
  }, [setValue, value, invite])

  const onSubmit = async (data: any) => {
    try {
      const validation = await emailSchema.isValid(data)

      if (!validation) {
        errorToast('Dados de E-mail inválidos')
        return
      }

      const body = {
        ...(isInvite && { invitationCode, invitationToken }),
        email: data.email.toLowerCase(),
      }

      isInvite
        ? await sendEmailInvitePatient(body)
        : await sendLastFormByEmail(body)

      registerTrack('Questionário enviado', {
        channel: 'email',
        type: source,
        form_id: formId,
      })

      successToast('E-mail enviado com sucesso!')
      setAlreadySent(true)
    } catch (error: any) {
      registerTrack('Erro envio questionário', {
        channel: 'email',
        type: source,
      })

      errorToast(error?.message || 'Erro desconhecido')
    }
  }

  const checkKeyDown = (e: KeyboardEvent) => {
    if (e.code !== 'Enter') return
    e.preventDefault()
    handleSubmit(onSubmit)()
  }

  return (
    <Box display='flex' flexDirection='column'>
      <Stack
        spacing={1}
        component='form'
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={checkKeyDown}
        direction={direction}
        alignItems='flex-end'
      >
        <TextField
          name='email'
          control={control}
          disabled={disabled}
          invalidChars={[' ']}
          placeholder='Digite o e-mail do titular da conta'
        />
        <Button
          color='secondary'
          type='submit'
          sx={{
            height: '42px',
            ...(direction === 'row' && { width: '200px' }),
          }}
        >
          Enviar
        </Button>
      </Stack>
    </Box>
  )
}

export default EmailForm
