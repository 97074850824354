import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const LoginContainer = styled(Box)({
  flexGrow: 1,
  padding: '60px 40px',
  backgroundColor: '#ffffff',
  borderRadius: '28px 28px 0px 0px',
})

export const Title = styled(Typography)({
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '26px',
  color: '#657787',
})

export const Description = styled(Typography)({
  fontFamily: 'Zen Kaku Gothic Antique',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '23px',
  color: '#657787',
})

export const InviteClinicText = styled(Typography)({
  fontFamily: 'Zen Kaku Gothic Antique',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  color: '#657787',
})

export const GradientLink = styled('a')`
  background: -webkit-linear-gradient(left, #00b3a6, #f36f60, #f36f60);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: 0.4s;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  font-weight: 700;
  &:link,
  &:visited,
  &:active,
  &:focus,
  &:default {
    background: -webkit-linear-gradient(left, #00b3a6, #f36f60, #f36f60);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: 0.4s;

    &:hover {
      opacity: 0.6;
    }
  }
`
