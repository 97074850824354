import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

export const HelpText = styled(Typography)({
  color: '#596B7B',
  textAlign: 'center',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '14px',
})

export const ExplanationText = styled(Typography)({
  color: '#596B7B',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
})

export const DescriptionText = styled(Typography)({
  color: '#596B7B',
  textAlign: 'center',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
})

export const TooltipText = styled(Typography)({
  color: '#FFFFFF',
  textAlign: 'center',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '14px',
})
