import { isUserPatient } from 'helpers'
import { BLOCK_MIN_QUESTION_NUMBER, blockOrderDict } from 'helpers/constants'

export const assembleQuestionnaireSteps = (
  questions: any,
  user: any,
  isInformantPatient?: boolean
) => {
  const startStep = {
    type: 'start',
    payload: {
      type: isUserPatient(user) || isInformantPatient ? 'patient' : 'other',
    },
  }

  const finishStep = {
    type: 'finish',
    payload: {},
  }

  const blockDict = questions.reduce((acc: any, question: any) => {
    return {
      ...acc,
      [question.block]: [...(acc[question.block] || []), question],
    }
  }, {})

  const specificBlocks: any = Object.values(blockDict).filter(
    (tag: any) => tag.length >= BLOCK_MIN_QUESTION_NUMBER
  )

  const orderedSpecificBlocks = specificBlocks.sort(
    ([{ block: blockA }]: any, [{ block: blockB }]: any) => {
      return (blockOrderDict[blockA] || 99) - (blockOrderDict[blockB] || 99)
    }
  )

  const especificBlocksSteps = orderedSpecificBlocks.reduce(
    (acc: any, questions: any) => {
      return [
        ...acc,
        {
          type: 'cover',
          payload: { block: questions[0].block, type: 'specific' },
        },
        ...questions.map((question: any) => ({
          type: 'question',
          payload: { question },
        })),
      ]
    },
    []
  )

  const genericBlockQuestions: any = Object.values(blockDict)
    .filter((tag: any) => tag.length < BLOCK_MIN_QUESTION_NUMBER)
    .reduce((acc: any, tag: any) => acc.concat(tag), [])

  const genericQuestionsSteps: any = genericBlockQuestions.map(
    (question: any) => ({
      type: 'question',
      payload: { question },
    })
  )

  const genericBlockCoverStep = {
    type: 'cover',
    payload: { type: 'generic' },
  }

  return [
    startStep,
    ...especificBlocksSteps,
    ...(Boolean(genericQuestionsSteps.length) &&
    Boolean(especificBlocksSteps.length)
      ? [genericBlockCoverStep]
      : []),
    ...genericQuestionsSteps,
    finishStep,
  ]
}

export const addSkippedCategoryToQuestionnaireSteps = (steps: any) => {
  const lastPos = steps.length - 1
  return [
    ...steps.slice(0, lastPos),
    {
      type: 'skip',
      payload: {},
    },
    ...steps.slice(lastPos),
  ]
}

export const addSkippedQuestionsToQuestionnaireSteps = (
  steps: any,
  questions: any
) => {
  const lastPos = steps.length - 1

  return [
    ...steps.slice(0, lastPos),
    ...questions.map((question: any) => ({
      type: 'skipped-question',
      payload: { question },
    })),
    ...steps.slice(lastPos),
  ]
}
