import { Typography, Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const QuestionText = styled(Typography)({
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '31px',
  textAlign: 'center',
  color: '#657787',
})

export const HelpContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  backgroundColor: '#8D92BE',
  borderRadius: '28px 28px 0px 0px',
})

export const QuestionContainer = styled(Box)({
  flexGrow: 1,
  backgroundColor: '#ffffff',
  borderRadius: '28px 28px 0px 0px',
  paddingTop: 50,
  paddingBottom: 70,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
})

export const HelpTitle = styled(Typography)({
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '26px',
  color: '#FFFFFF',
})

export const HelpDescription = styled(Typography)({
  fontFamily: 'Zen Kaku Gothic Antique',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '23px',
  textAlign: 'center',
  color: '#FFFFFF',
})

export const HelpButtonText = styled(Typography)({
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: '15px',
  leadingTrim: 'both',
  textEdge: 'cap',
  textAlign: 'center',
  color: '#FFFFFF',
})
