import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { getUserGroups } from 'services'
import { useNotification } from 'hooks'
import { SelectField } from 'components/inputs'
import { useSignUpInformantContext } from 'contexts'
import { Title } from './sign-up-informant-steps-styles'

function EnvironmentStep(): JSX.Element {
  const [options, setOptions] = useState([])

  const { control } = useFormContext()
  const { errorToast } = useNotification()
  const { invitationInfos } = useSignUpInformantContext()

  const patientFirstName =
    invitationInfos?.createdBy?.name?.split(' ')[0] || 'o paciente'

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userGroups = await getUserGroups()

        setOptions(
          userGroups?.map(({ id, name }: any) => ({
            value: id,
            label: name,
          })) || []
        )
      } catch (error: any) {
        console.error(error)
        errorToast(error?.message || 'Erro desconhecido')
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Title>Em qual ambiente você mais convive com {patientFirstName}?</Title>
      <Box mt={5}>
        <SelectField
          name='environment'
          control={control}
          options={options}
          label='Selecione uma opção'
        />
      </Box>
    </>
  )
}

export default EnvironmentStep
