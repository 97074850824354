import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const TitleText = styled(Typography)(({ theme }) => ({
  color: '#657787',
  fontFamily: 'Quicksand',
  fontSize: '20px',
  fontStyle: 'normal',
  textAlign: 'center',
  fontWeight: 600,
  lineHeight: '24px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
  },
}))

export const TooltipPasswordText = styled(Typography)({
  color: '#FFFFFF',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '14px',
  leadingTrim: 'both',
  textEdge: 'cap',
})
