interface ArchiveIconProps {
  color?: string
  margin?: string
}

const ArchiveIcon = ({ color = '#657787', margin }: ArchiveIconProps) => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ margin }}
    >
      <path
        d='M22.3466 5.291L19.3476 2.293C19.2549 2.19996 19.1447 2.12617 19.0234 2.07589C18.9021 2.0256 18.772 1.99981 18.6406 2H6.64063C6.50928 1.99981 6.3792 2.0256 6.25786 2.07589C6.13653 2.12617 6.02633 2.19996 5.93363 2.293L2.93463 5.291C2.8412 5.38368 2.7671 5.49398 2.71663 5.61552C2.66616 5.73705 2.64033 5.8674 2.64063 5.999V19C2.64063 20.103 3.53763 21 4.64063 21H20.6406C21.7436 21 22.6406 20.103 22.6406 19V5.999C22.6409 5.8674 22.6151 5.73705 22.5646 5.61552C22.5142 5.49398 22.4401 5.38368 22.3466 5.291ZM7.05463 4H18.2266L19.2256 4.999H6.05563L7.05463 4ZM4.64063 19V6.999H20.6406L20.6426 19H4.64063Z'
        fill={color}
      />
      <path
        d='M15.6406 12H9.64062V10H7.64062V14H17.6406V10H15.6406V12Z'
        fill={color}
      />
    </svg>
  )
}

export default ArchiveIcon
