// const devFrequencyOptions = [
//   {
//     value: 'everyFiveMinutes',
//     label: '[DEV] a cada cinco minutos',
//   },
//   { value: 'hourly', label: '[DEV] de hora em hora' },
// ]

import { addDays, addMonths, addWeeks } from 'date-fns'

export const frequencyOptions = [
  // ...(process.env.REACT_APP_ENV === 'development' ? devFrequencyOptions : []),
  { value: 'once', label: 'uma única vez' },
  { value: 'daily', label: 'diariamente' },
  { value: 'weekly', label: 'semanalmente' },
  { value: 'biweekly', label: 'a cada duas semanas' },
  { value: 'monthly', label: 'mensalmente' },
  { value: 'everyThreeMonths', label: 'trimestralmente' },
]

export const frequencyResumeTextDict: { [key: string]: string } = {
  once: 'uma única vez',
  daily: 'diariamente',
  weekly: 'semanalmente',
  biweekly: 'a cada duas semanas',
  monthly: 'mensalmente',
  everyThreeMonths: 'trimestralmente',
}

export const frequencyTextDict: { [key: string]: string } = {
  once: 'Uma única vez',
  everyFiveMinutes: 'A cada cinco minutos',
  hourly: 'A cada hora',
  daily: 'Diária',
  weekly: 'Semanal',
  biweekly: 'A cada duas semanas',
  monthly: 'Mensal',
  everyThreeMonths: 'Trimestral',
}

export const frequencyDictOrder: any = {
  everyFiveMinutes: 1,
  hourly: 2,
  daily: 3,
  weekly: 4,
  biweekly: 5,
  monthly: 6,
  everyThreeMonths: 7,
}

export const frequencyDate = (lastRun: Date) => ({
  daily: addDays(new Date(lastRun), 1),
  weekly: addWeeks(new Date(lastRun), 1),
  biweekly: addWeeks(new Date(lastRun), 2),
  monthly: addMonths(new Date(lastRun), 1),
  everyThreeMonths: addMonths(new Date(lastRun), 3),
})
