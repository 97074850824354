import { Text, View } from '@react-pdf/renderer'
import {
  capitalizeWordsOnSentence,
  getQuestionsWithoutSubdomain,
  getSubdomainsWithQuestions,
} from 'helpers'
import {
  ClinicalOpinion,
  FormRequest,
  Question,
  Tag,
} from 'types/patient-report-types'
import { getFormatedResponseDate } from '../../helpers/date'
import NoIcon from '../../icons/no-icon/no-icon'
import NotAskedIcon from '../../icons/not-asked-icon/not-asked-icon'
import SkippedIcon from '../../icons/skipped-icon/skipped-icon'
import SosoIcon from '../../icons/soso-icon/soso-icon'
import YesIcon from '../../icons/yes-icon/yes-icon'
import Title from '../title/title'
import { styles } from './tag-answers-styles'
import FooterTagText from './tag-texts/footer-text'
import HeaderTagText from './tag-texts/header-text'
import QuestionAssociation from '../question-association/question-association'

interface TagsProps {
  tag?: Tag
  formRequests?: FormRequest[]
  clinicalOpinions?: ClinicalOpinion[]
  withClinicalAvaliation?: boolean
  emissionDate?: Date
}

const answersIcons = {
  not_asked: <NotAskedIcon />,
  skip: <SkippedIcon />,
  '0': <NoIcon />,
  '1': <SosoIcon />,
  '2': <YesIcon />,
}

function TagAnswers({
  tag,
  formRequests,
  clinicalOpinions,
  withClinicalAvaliation,
  emissionDate,
}: TagsProps) {
  const flexSpacing = 8 + (formRequests?.length || 0)
  let subPage = 0

  const renderTitle = (subdomainName: string) => {
    return (
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          minHeight: 52,
        }}
      >
        <View
          style={{
            flex: flexSpacing,
            justifyContent: 'flex-end',
            marginBottom: 8,
            width: 230,
          }}
        >
          <Text style={styles.tableTitle}>{subdomainName}</Text>
        </View>
        <View
          style={{
            flex: 1,
            backgroundColor: withClinicalAvaliation ? '#F7F7F7' : '',
            width: 55,
          }}
        />
      </View>
    )
  }

  const hasAssociationQuestions = () => {
    return (
      tag?.questions.some(
        (question: any) => question.associations?.length > 0
      ) || false
    )
  }

  const renderHeader = (subdomainName: string, subPageNumber: number) => {
    return (
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          minHeight: 52,
        }}
      >
        {updateSubPage(subPageNumber)}
        <View
          style={{ justifyContent: 'flex-end', marginBottom: 8, width: 230 }}
        >
          <Text style={styles.tableTitle}>{subdomainName}</Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          {formRequests?.map((formRequest) => (
            <View
              key={formRequest.formRequestId}
              style={{
                ...styles.answerView,
                marginBottom: 8,
                paddingLeft: 4,
                paddingRight: 5,
              }}
            >
              <Text style={styles.tableText}>
                {formRequest.sendTo.personalInfo.firstName}
              </Text>
              <Text style={styles.tableText}>
                ({formRequest.relation?.name.toLocaleLowerCase() ?? 'paciente'})
              </Text>
              <Text style={styles.dateText}>
                {getFormatedResponseDate(formRequest.responseDate)}
              </Text>
            </View>
          ))}
          {withClinicalAvaliation && (
            <View
              style={{
                ...styles.answerView,
                backgroundColor: '#F7F7F7',
                borderTopLeftRadius: 6,
                borderTopRightRadius: 6,
                paddingBottom: 8,
              }}
            >
              <Text style={styles.tableText}>Avaliação</Text>
              <Text style={styles.tableText}>clínica</Text>
              <Text style={styles.dateText}>
                {getFormatedResponseDate(emissionDate)}
              </Text>
            </View>
          )}
        </View>
      </View>
    )
  }

  const updateSubPage = (subPageNumber: number) => {
    subPage = subPageNumber
  }

  const renderQuestions = (questions: Question[]) => {
    const clinicalOpinionsFilteredByTag = clinicalOpinions?.filter(
      (clinicalOpinion) => clinicalOpinion.tag === tag?.id
    )

    return questions.map((question: any) => {
      const findClinicalOpinionAnswer = clinicalOpinionsFilteredByTag?.find(
        (clinicalOpinion) => clinicalOpinion.question === question.id
      )?.answer
      return (
        <View
          key={question.id}
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            minHeight: 23,
          }}
        >
          <View style={{ marginLeft: -16, marginRight: 6 }}>
            {question.associationMark ? (
              <QuestionAssociation type={question.associationMark} />
            ) : (
              <View style={{ width: 10 }} />
            )}
          </View>
          <View style={{ ...styles.line, width: '100%' }}>
            <View style={{ width: 230, justifyContent: 'center' }}>
              <Text style={styles.text}>{question.ultraShortVersion}</Text>
            </View>
            <View
              style={{
                ...styles.answerView,
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              {formRequests?.map((formRequest, index) => {
                const answer = question.answers.find(
                  (answer: any) =>
                    answer.formRequestId === formRequest?.formRequestId
                )?.answer
                if (!answer) return <></>
                return (
                  <View
                    key={index}
                    style={{
                      ...styles.answerView,
                      width: 55,
                      paddingHorizontal: 32,
                    }}
                  >
                    {answersIcons?.[answer as never]}
                  </View>
                )
              })}
              {withClinicalAvaliation && (
                <View
                  style={{
                    ...styles.answerView,
                    backgroundColor: '#F7F7F7',
                    height: '100%',
                    width: 55,
                  }}
                >
                  {findClinicalOpinionAnswer ? (
                    answersIcons?.[findClinicalOpinionAnswer as never]
                  ) : (
                    <SkippedIcon />
                  )}
                </View>
              )}
            </View>
          </View>
        </View>
      )
    })
  }

  const shouldBreak = (subdomainName: string, tagName: string) => {
    const domainNSubdomain = [
      {
        tag: 'depressão',
        subdomain: 'perguntas complementares',
      },
      {
        tag: 'obsessões e compulsões',
        subdomain: 'compulsões',
      },
      {
        tag: 'desatenção e hiperatividade',
        subdomain: 'hiperatividade/impulsividade',
      },
    ]

    return domainNSubdomain.some(
      (item) =>
        item.subdomain.toLowerCase() === subdomainName.toLowerCase() &&
        item.tag.toLowerCase() === tagName.toLowerCase()
    )
  }

  const render = () => {
    if (!tag?.questions) return

    const questions = tag.questions
    const subdomainsWithQuestions = getSubdomainsWithQuestions(questions, tag)
    const questionsWithoutSubdomain = getQuestionsWithoutSubdomain(questions)

    return (
      <View style={{ marginVertical: 22, gap: 48 }}>
        {!!subdomainsWithQuestions.length && (
          <View>
            {subdomainsWithQuestions.map(
              ({ subdomainName, questions }: any) => {
                const hasToBreak = shouldBreak(subdomainName, tag.name)
                return (
                  <View key={subdomainName} break={hasToBreak} wrap={false}>
                    <View
                      style={{ minHeight: 52 }}
                      render={({ subPageNumber }) => (
                        <View>
                          {subPageNumber !== subPage
                            ? renderHeader(subdomainName, subPageNumber)
                            : renderTitle(subdomainName)}
                        </View>
                      )}
                    />
                    {renderQuestions(questions)}
                  </View>
                )
              }
            )}
          </View>
        )}
        {!!questionsWithoutSubdomain.length && (
          <View wrap={false}>
            {!subdomainsWithQuestions.length && renderHeader('', 1)}
            {renderQuestions(questionsWithoutSubdomain)}
          </View>
        )}
      </View>
    )
  }

  return (
    <>
      <Title title={capitalizeWordsOnSentence(tag?.name ?? '')} />
      <View style={{ marginHorizontal: 24 }}>
        <HeaderTagText tagName={tag?.name || ''} />

        {render()}

        <View style={{ minHeight: 80 }} wrap={false}>
          <FooterTagText
            hasAssociations={hasAssociationQuestions()}
            tagName={tag?.name || ''}
          />
        </View>
      </View>
    </>
  )
}

export default TagAnswers
