import { compareAsc } from 'date-fns'
import { HeadCell, Order } from './patient-list-types'

function descendingComparator(a: any, b: any, orderBy: any) {
  if ('age' === orderBy) {
    // for numbers
    return Number(a[orderBy]) - Number(b[orderBy])
  } else if ('status' === orderBy) {
    // for status
    if (!a.isActive && !a.isCompleted && !a.lastResponseDate) {
      return -1
    }

    if (!b.isActive && !b.isCompleted && !b.lastResponseDate) {
      return 1
    }

    const isAnsweredA =
      a.responseDate || (a.lastResponseDate && !a.isActive && !a.isCompleted)

    const isAnsweredB =
      b.responseDate || (b.lastResponseDate && !b.isActive && !b.isCompleted)

    if (isAnsweredA && isAnsweredB) {
      return compareAsc(
        new Date(a.lastResponseDate),
        new Date(b.lastResponseDate)
      )
    }

    if (isAnsweredA) {
      return 1
    }

    if (isAnsweredB) {
      return -1
    }

    return compareAsc(new Date(a.createdAt), new Date(b.createdAt))
  } else if ('lastResponseDate' === orderBy) {
    // for dates
    const aDate = new Date(a[orderBy] || 0)
    const bDate = new Date(b[orderBy] || 0)
    if (bDate < aDate) return -1
    if (bDate > aDate) return 1
    return 0
  } else {
    // for strings
    if (b[orderBy] < a[orderBy]) return -1
    if (b[orderBy] > a[orderBy]) return 1
    return 0
  }
}

export const headerFields: HeadCell[] = [
  { id: 'firstName', label: 'Nome', order: true },
  { id: 'age', label: 'Idade', order: true },
  { id: 'lastResponseDate', label: 'Últimos resultados', order: true },
  { id: 'status', label: 'Status', order: true },
  { id: 'actions', label: 'Ações' },
]

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}
