import { Typography, Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Title = styled(Typography)({
  color: '#657787',
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '20px',
  letterSpacing: '0.04em',
})

export const ResponderName = styled(Typography)({
  color: '#657787',
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '20px',
  letterSpacing: '0.04em',
  marginLeft: '5px',
  textDecoration: 'underline',
  cursor: 'pointer',
})

export const SettingsLabel = styled(Typography)({
  color: '#000000',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '20px',
})

export const BreadcrumbDivider = styled(Typography)({
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '21px',
  color: '#657787',
})

export const ExplanationContainer = styled(Box)({
  border: '1px dashed #AEAEAE',
  borderRadius: '8px',
  padding: '8px 16px',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between',
  marginTop: '12px',
})

export const ExplanationText = styled(Typography)({
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '21px',
})
