import { Box, Stack } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { InputField, SelectField } from 'components/inputs'
import { Title } from './sign-up-caregiver-steps-styles'
import { useSignUpCaregiverContext } from 'contexts'
import { useEffect, useState } from 'react'
import { getRelations, getUserGroups } from 'services'
import { useNotification } from 'hooks'

function CaregiverPersonalInfoStep(): JSX.Element {
  const [relationOptions, setRelationOptions] = useState([])

  const { control, watch, setValue } = useFormContext()
  const { patientName } = useSignUpCaregiverContext()
  const { errorToast } = useNotification()

  const patientFirstName =
    watch('firstName') || patientName?.split(' ')[0] || 'o paciente'

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [relations, userGroups] = await Promise.all([
          getRelations(),
          getUserGroups(),
        ])

        const sortedRelations = relations.sort((a: any, b: any) => {
          if (a.name === 'Sou eu') return -1
          if (b.name === 'Sou eu') return 1
          return 0
        })

        setRelationOptions(
          sortedRelations?.map(({ id, name }: any) => ({
            value: id,
            label: name,
          })) || []
        )

        const findHome = userGroups.find(
          (userGroup: any) => userGroup.name === 'Casa'
        )

        setValue('environment', findHome?.id || userGroups[0]?.id)
      } catch (error: any) {
        console.error(error)
        errorToast(error?.message || 'Erro desconhecido')
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Box mb={2}>
        <Title>Ok! Você ainda não possui conta conosco.</Title>
      </Box>
      <Box mb={10}>
        <Title>Insira algumas informações sobre você para seu cadastro. </Title>
      </Box>
      <Stack spacing={2}>
        <InputField
          name='caregiverFirstName'
          control={control}
          label='Seu primeiro nome'
        />
        <InputField
          name='caregiverLastName'
          control={control}
          label='Seu sobrenome'
        />
        <SelectField
          name='relation'
          control={control}
          label={`O que você é de ${patientFirstName}?`}
          options={relationOptions}
        />
      </Stack>
    </>
  )
}

export default CaregiverPersonalInfoStep
