import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Box, Grid } from '@mui/material'
import { Modal, StaticLink } from 'components'
import { CheckboxField } from 'components/inputs'
import { collectDataLabel } from 'helpers/constants'
import { Title } from '../styles/general-steps-styles'

function TermsStep(): JSX.Element {
  const [termsModalOpen, setTermsModalOpen] = useState(false)
  const [policyModalOpen, setPolicyModalOpen] = useState(false)

  const { control } = useFormContext()

  return (
    <>
      <Box mb={10}>
        <Title>
          No Wida, respeitamos sua privacidade e adotamos as melhores práticas
          em segurança de dados.
        </Title>
      </Box>
      <Modal
        title=''
        isOpen={termsModalOpen}
        handleClose={() => setTermsModalOpen(false)}
        component={
          <iframe
            title='term of use'
            src='/term-of-use.html'
            style={{ width: '100%', border: 'none' }}
          />
        }
        headerPadding='30px 16px 0'
        bodyPadding='0 8px 40px 40px'
      />
      <Modal
        title=''
        isOpen={policyModalOpen}
        handleClose={() => setPolicyModalOpen(false)}
        component={
          <iframe
            title='privacy policy'
            src='/privacy-policy.html'
            style={{ width: '100%', border: 'none' }}
          />
        }
        headerPadding='30px 16px 0'
        bodyPadding='0 8px 40px 40px'
      />
      <Grid item xs={12} mb={2}>
        <CheckboxField
          name='acceptedShare'
          control={control}
          label={collectDataLabel}
        />
      </Grid>
      <Grid item xs={12} mb={2}>
        <CheckboxField
          name='acceptedTermsAndPolicies'
          control={control}
          label={
            <>
              Declaro que li e concordo com os{' '}
              <StaticLink
                to='/'
                size={'mini'}
                onClick={(e: any) => {
                  e.preventDefault()
                  setTermsModalOpen(true)
                }}
              >
                {' '}
                Termos de Uso
              </StaticLink>{' '}
              e{' '}
              <StaticLink
                to='/'
                size={'mini'}
                onClick={(e: any) => {
                  e.preventDefault()
                  setPolicyModalOpen(true)
                }}
              >
                Política de Privacidade{' '}
              </StaticLink>
              da Plataforma Wida
            </>
          }
        />
      </Grid>
    </>
  )
}

export default TermsStep
