import NotificationsContactStep from './notifications-contact-step'
import NotificationsWhatsappValidationCodeStep from './notifications-whatsapp-validation-code-step'
import NotificationsEmailValidationCodeStep from './notifications-email-validation-code-step'

export const notificationsSteps: any = [
  {
    id: 'notifications-contact-step',
    component: <NotificationsContactStep />,
    fields: [
      'whatsapp',
      'email',
      'enableWhatsappNotification',
      'enableEmailNotification',
    ],
  },
  {
    id: 'notifications-whatsapp-validation-code-step',
    component: <NotificationsWhatsappValidationCodeStep />,
    fields: ['whatsappValidationCode'],
  },
  {
    id: 'notifications-email-validation-code-step',
    component: <NotificationsEmailValidationCodeStep />,
    fields: ['emailValidationCode'],
  },
]
