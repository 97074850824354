import { Button } from './icon-link-styles'

function IconLink(props: any) {
  return (
    <Button {...props}>
      {props.children}
    </Button>
  )

}

export default IconLink
