function PrivacyPolicy(): JSX.Element {
  return (
    <>
      <p style={{ textAlign: 'justify' }}>
        Bem-vindo (a) à Política de Privacidade da plataforma Wida (“
        <strong>Política</strong>”).
      </p>

      <p style={{ textAlign: 'justify' }}>
        A Wida Ltda., inscrita no CNPJ 47.007.977/0001-02, com endereço na R.
        Félix da Cunha, nº 737, Cj. 302, Floresta, CEP 90.570-001, Porto
        Alegre/RS (“<strong>plataforma Wida</strong>”), é uma plataforma que
        oferece funcionalidades de acompanhamento e monitoramento que auxiliam
        pacientes e profissionais da saúde na gestão de cuidados em saúde mental
        (“<strong>Serviços</strong>”).
      </p>
      <p>
        A plataforma Wida <strong>NÃO</strong> fornece: (i) orientação médica,
        (ii) atendimento médico de qualquer natureza ou (iii) diagnóstico
        médico. Os nossos serviços não substituem a orientação, diagnóstico e
        tratamento fornecidos por um profissional da área de saúde e servem
        estritamente para facilitar o trabalho desses profissionais.&nbsp;
      </p>

      <p style={{ textAlign: 'justify' }}>
        Os termos “plataforma Wida,” “nós,” e/ou "nosso" incluem o Wida e seus
        parceiros terceirizados.
      </p>

      <p style={{ textAlign: 'justify' }}>
        Esta Política se aplica a pacientes, profissionais da saúde e demais
        usuários que interagem com a plataforma por meio do nosso site e
        aplicativos para dispositivos móveis, localizado em plataforma.wida.app
        ("<strong>site</strong>" ou "<strong>app</strong>"), assim como a
        informação armazenada no servidor www.wida.app e associada aos e-mails e
        mensagens de SMS, WhatsApp e similares enviados como parte ou em conexão
        às atividades da plataforma Wida ou outro meio de comunicação em
        ambiente físico ou digital, com o objetivo de esclarecer de forma
        objetiva como coletamos e tratamos dados pessoais, nos termos da Lei
        Geral de Proteção de Dados Pessoais (Lei nº 13.709/2018) e demais
        regulamentações aplicáveis, bem como informar os meios em que você pode
        acessar e atualizar seus Dados Pessoais, e exercer os seus direitos
        relativos a eles.
      </p>

      <p>
        A PLATAFORMA WIDA NÃO SUBSTITUI EM NENHUMA CIRCUNSTÂNCIA QUALQUER
        ATENÇÃO DE EMERGÊNCIA. SE VOCÊ TIVER UMA EMERGÊNCIA MÉDICA OU DE SAÚDE
        MENTAL, SE ESTIVER CONSIDERANDO SUICÍDIO OU SE ESTIVER NECESSITANDO DE
        ATENDIMENTO MÉDICO URGENTE, PROCURE UM MÉDICO DE SUA CONFIANÇA OU
        ATENDIMENTO DE SAÚDE{' '}
        <span
          style={{
            textDecorationLine: 'underline',
            textDecorationSkipInk: 'none',
          }}
        >
          IMEDIATAMENTE
        </span>
        .
      </p>

      <p style={{ textAlign: 'justify' }}>
        Caso você tenha alguma dúvida ou tenha interesse em conversar conosco
        sobre qualquer sugestão de melhorias no sistema ou questão relacionada
        aos seus dados, fique à vontade para contatar nossa equipe pelo canal
        <strong>info@wida.app</strong>.
      </p>

      <p style={{ textAlign: 'justify', marginBottom: '8pt' }}>
        <strong>1. QUEM SOMOS, QUAIS DADOS COLETAMOS E QUANDO COLETAMOS</strong>
      </p>

      <p style={{ textAlign: 'justify' }}>
        Esta Política de Privacidade se aplica a informações coletadas pelo Wida
        para nossos próprios objetivos, inclusive informações que coletamos de e
        sobre pessoas que acessam o nosso site, clientes que se cadastram para
        acessar e acessam a sua conta da plataforma Wida e pessoas que acessam
        os dados em relação às nossas atividades de marketing.
      </p>
      <p style={{ textAlign: 'justify' }}>
        “<strong>Informação Pessoal</strong>” significa dados pessoais e dados
        pessoais sensíveis.
        <span
          style={{
            textDecorationLine: 'underline',
            textDecorationSkipInk: 'none',
          }}
        >
          Dados pessoais
        </span>{' '}
        são quaisquer informações que direta ou indiretamente possam
        identificá-lo(a), como dados cadastrais e identificadores eletrônicos.
        Os{' '}
        <span
          style={{
            textDecorationLine: 'underline',
            textDecorationSkipInk: 'none',
          }}
        >
          dados pessoais sensíveis
        </span>{' '}
        são aqueles dados pessoais relacionados a quaisquer informações sobre
        origem racial ou étnica, convicção religiosa, opinião política, filiação
        a sindicato ou a organização de caráter religioso, filosófico ou
        político, dado referente à saúde ou à vida sexual, dado genético ou
        biométrico, quando vinculado a uma pessoa natural.
      </p>
      <p style={{ textAlign: 'justify', marginBottom: '12pt' }}>
        Nós armazenamos Informação Pessoal quando você se cadastra para utilizar
        o Serviço, usa o Serviço ou se comunica conosco. Caso você seja
        paciente, também é possível que nós recebamos Informação Pessoal a
        partir de seu profissional da saúde e de pessoas convidadas por você
        para fornecerem informações complementares (informantes). Além disso,
        coletamos informações como estatísticas de utilização anônima através de
        cookies, acesso à conta e ao servidor e outras tecnologias semelhantes
        durante o seu uso do Serviço.
      </p>

      <p style={{ marginBottom: '12pt' }}>
        <strong>2. COMO O SERVIÇO COLETA INFORMAÇÕES SOBRE VOCÊ</strong>
      </p>

      <p style={{ textAlign: 'justify' }}>
        <span
          style={{
            textDecorationLine: 'underline',
            textDecorationSkipInk: 'none',
          }}
        >
          Preenchimento de formulários
        </span>
        . Quando você preenche formulários em nosso site, inclusive quando
        solicita informações sobre nosso Serviço, realiza uma demonstração ou
        faz contato com a nossa equipe, podemos pedir que você nos forneça
        informações de contato como nome, profissão, email, endereço físico e
        número de telefone.
      </p>
      <p style={{ textAlign: 'justify' }}>
        <span
          style={{
            textDecorationLine: 'underline',
            textDecorationSkipInk: 'none',
          }}
        >
          Compra de assinatura
        </span>
        . Dependendo de seu contrato conosco, podemos pedir que você forneça
        para nós ou para um parceiro terceirizado as informações necessárias
        para cobrança, inclusive sobre cartões de crédito ou outras formas de
        pagamento. Ao fornecer essas informações, você concorda expressamente
        com o compartilhamento de sua informação com parceiros terceirizados de
        processamento de contas e outros serviços (inclusive, mas não limitado
        a, fornecedores de serviços de detecção de fraude). Em geral, não
        armazenamos informações de cartão de crédito, mas alguma informação pode
        ser repassada a nós pelos parceiros que processam as informações, por
        exemplo os últimos quatro dígitos do cartão, o país de origem e a data
        de expiração.
      </p>
      <p style={{ textAlign: 'justify' }}>
        <span
          style={{
            textDecorationLine: 'underline',
            textDecorationSkipInk: 'none',
          }}
        >
          Criação de conta e cadastro
        </span>
        . Para se cadastrar na plataforma Wida é necessário que você forneça
        informações como nome, CPF, endereço de e-mail, número de celular e
        senha. Também, se for o caso, solicitaremos informações como o seu
        registro profissional, a organização de saúde para a qual você presta
        serviços, o endereço comercial e o número de telefone.
      </p>
      <p style={{ textAlign: 'justify' }}>
        <span
          style={{
            textDecorationLine: 'underline',
            textDecorationSkipInk: 'none',
          }}
        >
          Utilização do Serviço
        </span>
        . Nós coletamos informações que você fornece quando usa o serviço,
        inclusive quando adiciona ou envia um documento, imagem ou outro arquivo
        de dados ou quando envia um convite ou uma mensagem a outro usuário.
      </p>
      <p style={{ textAlign: 'justify' }}>
        <span
          style={{
            textDecorationLine: 'underline',
            textDecorationSkipInk: 'none',
          }}
        >
          Newsletters e ofertas.
        </span>
        Podemos coletar informações na realização de cadastro pelo usuário para
        enviar newsletters, ofertas, dicas de uso ou participação em
        enquetes/pesquisas de satisfação.
      </p>
      <p style={{ textAlign: 'justify' }}>
        <span
          style={{
            textDecorationLine: 'underline',
            textDecorationSkipInk: 'none',
          }}
        >
          Contatos com a plataforma Wida
        </span>
        . Podemos coletar Informações Pessoais através dos contatos com nossa
        equipe de suporte ou outros contatos conosco, inclusive através de redes
        sociais.
      </p>
      <p style={{ textAlign: 'justify' }}>
        <span
          style={{
            textDecorationLine: 'underline',
            textDecorationSkipInk: 'none',
          }}
        >
          Informações de seu computador ou dispositivo móvel
        </span>
        <span style={{ textDecoration: 'none' }}>
          . O serviço coleta automaticamente informações sobre seu dispositivo,
          como modelo, sistema operacional, versão do navegador e add-ons, ID e
          características do dispositivo, ID de cookies e provedor de internet
          ou empresa de serviço de telefonia celular.
        </span>
      </p>
      <p style={{ textAlign: 'justify' }}>
        <span
          style={{
            textDecorationLine: 'underline',
            textDecorationSkipInk: 'none',
          }}
        >
          Coleta automática de informações: cookies e outras tecnologias
        </span>
        <span style={{ textDecoration: 'none' }}>
          . Quando você utiliza ou visita a plataforma Wida ou abre nossos
          e-mails, nós e nossos parceiros terceirizados, por exemplo, que
          analisam dados de tráfego no site, podem coletar informações de forma
          automatizada, como através de cookies, web beacons e web server logs.
          Ao utilizar o serviço, você concorda com a inserção de cookies,
          beacons e outras tecnologias semelhantes em seu navegador e em
          e-mails, de acordo com a presente Política. As informações coletadas
          desta forma incluem endereço de IP, características do navegador, ID e
          características do dispositivo, versão do sistema operacional,
          preferências de língua, URLs de referência e informações sobre a
          utilização do nosso Serviço.
        </span>
      </p>
      <p style={{ textAlign: 'justify', marginBottom: '12pt' }}>
        <span
          style={{
            textDecorationLine: 'underline',
            textDecorationSkipInk: 'none',
          }}
        >
          Fontes disponíveis publicamente e outros parceiros
        </span>
        <span style={{ textDecoration: 'none' }}>
          . Poderemos obter Informações Pessoais sobre você de outras fontes,
          como registros públicos, informações públicas de sites da Internet e
          outros parceiros, com objetivo de atualizar, expandir e analisar os
          nossos registros.
        </span>
      </p>

      <p style={{ marginBottom: '12pt' }}>
        <strong>
          3. COMO A PLATAFORMA WIDA USA AS SUAS INFORMAÇÕES PESSOAIS
        </strong>
      </p>

      <p>
        <span style={{ textDecoration: 'none' }}>
          Nós usamos as Informações Pessoais para prestar o Serviço, bem como
          para facilitar e melhorar nossos serviços ou para nos comunicarmos com
          você.
        </span>
      </p>
      <p>
        <span
          style={{
            textDecorationLine: 'underline',
            textDecorationSkipInk: 'none',
          }}
        >
          Para fornecer o Serviço
        </span>
        <span style={{ textDecoration: 'none' }}>
          . Nós usamos informações, inclusive as suas Informações Pessoais, para
          fornecer o Serviço, caso você se cadastre na plataforma Wida.
        </span>
      </p>
      <p>
        <span
          style={{
            textDecorationLine: 'underline',
            textDecorationSkipInk: 'none',
          }}
        >
          Utilização interna e relacionada ao Serviço
        </span>
        <span style={{ textDecoration: 'none' }}>
          . Ao utilizar o Serviço você nos autoriza a coletar, processar,
          analisar e reter dados relacionados ao fornecimento do Serviço.
        </span>
      </p>
      <p>
        <span
          style={{
            textDecorationLine: 'underline',
            textDecorationSkipInk: 'none',
          }}
        >
          Análise de dados
        </span>
        <span style={{ textDecoration: 'none' }}>
          . Nós analisamos as informações que coletamos para fornecer o nosso
          Serviço, como por exemplo emitir relatórios para nossos clientes. Até
          onde a lei permite e de acordo com os contratos estabelecidos com
          provedores de serviços de saúde, poderemos desidentificar e/ou agregar
          informações e usar esse tipo de informações para fins do negócio (por
          exemplo, para pesquisa científica e para análises estatísticas).
        </span>
      </p>
      <p>
        <span
          style={{
            textDecorationLine: 'underline',
            textDecorationSkipInk: 'none',
          }}
        >
          Contatos feitos pela plataforma Wida
        </span>
        <span style={{ textDecoration: 'none' }}>
          . Nós usamos as suas Informações Pessoais para fazer contato com você,
          inclusive pelo envio de e-mails, mensagens via WhatApp, por exemplo,
          para o endereço de e-mail ou telefone que você nos fornece, ou para
          enviar notificações ao seu dispositivo móvel caso as notificações
          estejam habilitadas, e/ou mensagens de texto para o seu dispositivo
          móvel para verificar sua conta e para objetivos de informação e
          operação, como para gestão da conta, lembretes, serviços ao cliente,
          manutenção do sistema e outros objetivos relacionados ao Serviço.
        </span>
      </p>
      <p>
        <span
          style={{
            textDecorationLine: 'underline',
            textDecorationSkipInk: 'none',
          }}
        >
          Marketing
        </span>
        <span style={{ textDecoration: 'none' }}>
          . Nós usamos informação, inclusive suas Informações Pessoais, para
          enviar detalhes que possam interessar a você sobre nossos produtos e
          serviços, inclusive materiais promocionais e informações sobre
          eventos, programas, ofertas, enquetes e pesquisa de mercado. Você pode
          cancelar o recebimento de marketing por e-mail clicando a opção de
          "descadastrar" no corpo do e-mail.
        </span>
      </p>
      <p style={{ marginBottom: '12pt' }}>
        <span
          style={{
            textDecorationLine: 'underline',
            textDecorationSkipInk: 'none',
          }}
        >
          Consentimento
        </span>
        <span style={{ textDecoration: 'none' }}>
          . Nós usamos informações, inclusive as suas Informações Pessoais, para
          qualquer outro objetivo que tenha sido descrito e informado a você no
          momento da coleta da informação.
        </span>
      </p>

      <p style={{ marginBottom: '8pt' }}>
        <strong>4. COMPARTILHAMENTO DE SEUS DADOS </strong>
      </p>

      <p>
        <span style={{ textDecoration: 'none' }}>
          Podemos compartilhar seus dados pessoais nas seguintes hipóteses:
        </span>
      </p>
      <p style={{ textAlign: 'center' }}>
        <b style={{ fontWeight: 'normal' }}></b>
      </p>
      <ul style={{ paddingInlineStart: '48px' }}>
        <li style={{ textDecoration: 'none' }}>
          <p style={{ lineHeight: 1.295 }}>
            <span style={{ textDecoration: 'none' }}>
              Com empresas, parceiros e prestadores de serviços que realizam
              atividades subsidiárias, tais como administração, guarda e
              segurança de dados, para que possamos executar nossas atividades
              de negócio, listadas na tabela abaixo:&nbsp;
            </span>
          </p>
        </li>
      </ul>
      <p style={{ textAlign: 'center' }}>
        <b style={{ fontWeight: 'normal' }}></b>
      </p>
      <div style={{ marginLeft: '30.6pt' }}>
        <table style={{ border: 'none', borderCollapse: 'collapse' }}>
          <colgroup>
            <col width='255' />
            <col width='263' />
          </colgroup>
          <tbody>
            <tr style={{ height: '0pt' }}>
              <td
                style={{
                  border: 'solid #000000 0.5pt',
                  verticalAlign: 'top',
                  backgroundColor: '#efefef',
                  padding: '0pt 5.4pt 0pt 5.4pt',
                  overflow: 'hidden',
                  overflowWrap: 'break-word',
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <span style={{ fontWeight: 700, textDecoration: 'none' }}>
                    Instituição
                  </span>
                </p>
              </td>
              <td
                style={{
                  border: 'solid #000000 0.5pt',
                  verticalAlign: 'top',
                  backgroundColor: '#efefef',
                  padding: '0pt 5.4pt 0pt 5.4pt',
                  overflow: 'hidden',
                  overflowWrap: 'break-word',
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <span style={{ fontWeight: 700, textDecoration: 'none' }}>
                    Motivo do compartilhamento
                  </span>
                </p>
              </td>
            </tr>
            <tr style={{ height: '0pt' }}>
              <td
                style={{
                  border: 'solid #000000 0.5pt',
                  verticalAlign: 'top',
                  padding: '0pt 5.4pt 0pt 5.4pt',
                  overflow: 'hidden',
                  overflowWrap: 'break-word',
                }}
              >
                <p>
                  <span style={{ textDecoration: 'none' }}>
                    Heroku (SalesForce)
                  </span>
                </p>
              </td>
              <td
                style={{
                  border: 'solid #000000 0.5pt',
                  verticalAlign: 'top',
                  padding: '0pt 5.4pt 0pt 5.4pt',
                  overflow: 'hidden',
                  overflowWrap: 'break-word',
                }}
              >
                <p>
                  <span style={{ textDecoration: 'none' }}>
                    Processamento e armazenamento de dados necessários para
                    fornecimento dos serviços ofertados
                  </span>
                </p>
              </td>
            </tr>
            <tr style={{ height: '0pt' }}>
              <td
                style={{
                  border: 'solid #000000 0.5pt',
                  verticalAlign: 'top',
                  padding: '0pt 5.4pt 0pt 5.4pt',
                  overflow: 'hidden',
                  overflowWrap: 'break-word',
                }}
              >
                <p>
                  <span style={{ textDecoration: 'none' }}>
                    Amazon Web Services
                  </span>
                </p>
              </td>
              <td
                style={{
                  border: 'solid #000000 0.5pt',
                  verticalAlign: 'top',
                  padding: '0pt 5.4pt 0pt 5.4pt',
                  overflow: 'hidden',
                  overflowWrap: 'break-word',
                }}
              >
                <p>
                  <span style={{ textDecoration: 'none' }}>
                    Processamento e armazenamento de dados necessários para
                    fornecimento dos serviços ofertados
                  </span>
                </p>
              </td>
            </tr>
            <tr style={{ height: '0pt' }}>
              <td
                style={{
                  border: 'solid #000000 0.5pt',
                  verticalAlign: 'top',
                  padding: '0pt 5.4pt 0pt 5.4pt',
                  overflow: 'hidden',
                  overflowWrap: 'break-word',
                }}
              >
                <p>
                  <span style={{ textDecoration: 'none' }}>MixPanel</span>
                </p>
              </td>
              <td
                style={{
                  border: 'solid #000000 0.5pt',
                  verticalAlign: 'top',
                  padding: '0pt 5.4pt 0pt 5.4pt',
                  overflow: 'hidden',
                  overflowWrap: 'break-word',
                }}
              >
                <p>
                  <span style={{ textDecoration: 'none' }}>
                    Processamento e armazenamento de dados desidentificados para
                    análise de uso e melhorias na plataforma
                  </span>
                </p>
              </td>
            </tr>
            <tr style={{ height: '0pt' }}>
              <td
                style={{
                  border: 'solid #000000 0.5pt',
                  verticalAlign: 'top',
                  padding: '0pt 5.4pt 0pt 5.4pt',
                  overflow: 'hidden',
                  overflowWrap: 'break-word',
                }}
              >
                <p>
                  <span style={{ textDecoration: 'none' }}>Twilio Segment</span>
                </p>
              </td>
              <td
                style={{
                  border: 'solid #000000 0.5pt',
                  verticalAlign: 'top',
                  padding: '0pt 5.4pt 0pt 5.4pt',
                  overflow: 'hidden',
                  overflowWrap: 'break-word',
                }}
              >
                <p>
                  <span style={{ textDecoration: 'none' }}>
                    Processamento e armazenamento de dados desidentificados para
                    análise de uso e melhorias na plataforma
                  </span>
                </p>
              </td>
            </tr>
            <tr style={{ height: '0pt' }}>
              <td
                style={{
                  border: 'solid #000000 0.5pt',
                  verticalAlign: 'top',
                  padding: '0pt 5.4pt 0pt 5.4pt',
                  overflow: 'hidden',
                  overflowWrap: 'break-word',
                }}
              >
                <p>
                  <span style={{ textDecoration: 'none' }}>Digital Ocean</span>
                </p>
              </td>
              <td
                style={{
                  border: 'solid #000000 0.5pt',
                  verticalAlign: 'top',
                  padding: '0pt 5.4pt 0pt 5.4pt',
                  overflow: 'hidden',
                  overflowWrap: 'break-word',
                }}
              >
                <p>
                  <span style={{ textDecoration: 'none' }}>
                    Processamento e armazenamento de dados desidentificados para
                    análise de uso e melhorias na plataforma
                  </span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p style={{ textAlign: 'center' }}>
        <b style={{ fontWeight: 'normal' }}></b>
      </p>
      <ul style={{ paddingInlineStart: '48px' }}>
        <li style={{ textDecoration: 'none' }}>
          <p style={{ lineHeight: 1.295 }}>
            <span style={{ textDecoration: 'none' }}>
              Com autoridades judiciais, administrativas ou governamentais
              competentes, sempre que houver determinação legal, requerimento,
              requisição ou ordem judicial;&nbsp;
            </span>
          </p>
        </li>
      </ul>
      <p style={{ textAlign: 'center' }}>
        <b style={{ fontWeight: 'normal' }}></b>
      </p>
      <ul style={{ paddingInlineStart: '48px' }}>
        <li style={{ textDecoration: 'none' }}>
          <p style={{ lineHeight: 1.295 }}>
            <span style={{ textDecoration: 'none' }}>
              De forma automática, em caso de alteração do nosso quadro
              societário, como no caso de fusão, aquisição ou incorporação, bem
              como entre quaisquer empresas atualmente ou futuramente
              detentoras, subsidiárias ou vinculadas, ou outras empresas sob
              controle e posse compartilhados com a plataforma Wida;
            </span>
          </p>
        </li>
      </ul>
      <p style={{ textAlign: 'center' }}>
        <b style={{ fontWeight: 'normal' }}></b>
      </p>
      <ul style={{ paddingInlineStart: '48px' }}>
        <li style={{ textDecoration: 'none' }}>
          <p style={{ lineHeight: 1.295 }}>
            <span style={{ textDecoration: 'none' }}>
              Quando requerido pela lei aplicável; e
            </span>
          </p>
        </li>
      </ul>
      <p style={{ textAlign: 'center' }}>
        <b style={{ fontWeight: 'normal' }}></b>
      </p>
      <p style={{ textAlign: 'justify' }}>
        <span style={{ textDecoration: 'none' }}>
          É possível que, ao compartilhar seus dados com nossos parceiros
          conforme descrito acima, seus dados sejam transferidos para servidores
          fora do país (
        </span>
        <span style={{ fontStyle: 'italic', textDecoration: 'none' }}>
          cloud computing
        </span>
        <span style={{ textDecoration: 'none' }}>
          ). Nesses casos, temos o cuidado de garantir que seus dados estejam
          seguros, seja por garantias contratuais oferecidas por nossos
          parceiros, seja por legislações reconhecidamente adequadas.
        </span>
      </p>

      <p style={{ marginBottom: '8pt' }}>
        <strong>5. TEMPO DE GUARDA DESSES DADOS </strong>
      </p>

      <p>
        <span style={{ textDecoration: 'none' }}>
          Podemos manter seus dados conosco nas seguintes hipóteses:
        </span>
      </p>

      <ul style={{ paddingInlineStart: '48px' }}>
        <li style={{ textDecoration: 'none', marginLeft: '2.4pt' }}>
          <p style={{ lineHeight: 1.295 }}>
            <span style={{ textDecoration: 'none' }}>
              Enquanto eles forem necessários para cumprir as finalidades
              descritas acima;
            </span>
          </p>
        </li>
      </ul>

      <ul style={{ paddingInlineStart: '48px' }}>
        <li style={{ textDecoration: 'none', marginLeft: '2.4pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ textDecoration: 'none' }}>
              Enquanto durar uma obrigação legal ou regulatória que nos obrigue
              a manter os dados conosco;
            </span>
          </p>
        </li>
      </ul>

      <ul style={{ paddingInlineStart: '48px' }}>
        <li style={{ textDecoration: 'none', marginLeft: '2.4pt' }}>
          <p style={{ textAlign: 'justify', marginBottom: '8pt' }}>
            <span style={{ textDecoration: 'none' }}>
              Pelo prazo legal do possível ajuizamento de demandas judiciais
              e/ou administrativas por ou em face da plataforma Wida.
            </span>
          </p>
        </li>
      </ul>
      <p style={{ textAlign: 'justify' }}>
        <span style={{ textDecoration: 'none' }}>
          Se qualquer das hipóteses acima não mais justificar a manutenção
          desses dados, nós iremos apagá-los completamente ou alterá-los de
          forma que seja impossível para nós e nossos parceiros identificá-lo
          como titular do dado pessoal.
        </span>
      </p>

      <p style={{ marginBottom: '8pt' }}>
        <strong>6. SEGURANÇA DOS SEUS DADOS </strong>
      </p>

      <p style={{ textAlign: 'justify' }}>
        <span style={{ textDecoration: 'none' }}>
          Nós da plataforma Wida garantimos que cuidamos de seus dados por meio
          de medidas técnicas e operacionais adequadas e buscamos o mesmo nível
          de excelência de nossos parceiros, mas é importante salientar que
          nenhum sistema está imune a ataques cibernéticos e afins. Por isso,
          caso tome conhecimento de qualquer evento que possa colocar em risco
          dados disponibilizados à plataforma Wida, pedimos que entre em contato
          conosco através do e-mail:{' '}
        </span>
        <span style={{ fontWeight: 700, textDecoration: 'none' }}>
          info@wida.app
        </span>
      </p>

      <p style={{ textAlign: 'justify' }}>
        <span style={{ textDecoration: 'none' }}>
          É muito importante que você saiba que a segurança de seus dados também
          é seu papel. Por isso, recomendamos que você sempre adote os seguintes
          cuidados:
        </span>
      </p>

      <ul style={{ paddingInlineStart: '48px' }}>
        <li style={{ textDecoration: 'none' }}>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ textDecoration: 'none' }}>
              Utilize equipamentos e conexões seguras, navegadores web, sistemas
              operacionais e antivírus atualizados;
            </span>
          </p>
        </li>
      </ul>

      <ul style={{ paddingInlineStart: '48px' }}>
        <li style={{ textDecoration: 'none' }}>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ textDecoration: 'none' }}>
              Não compartilhe dados enviados à plataforma Wida
            </span>
            <span style={{ fontWeight: 700, textDecoration: 'none' }}> </span>
            <span style={{ textDecoration: 'none' }}>
              em outros sites, nós não lhe solicitaremos outros dados fora de
              nossos canais de contato oficiais;
            </span>
          </p>
        </li>
      </ul>

      <ul style={{ paddingInlineStart: '48px' }}>
        <li style={{ textDecoration: 'none' }}>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ textDecoration: 'none' }}>
              Caso você se comunique conosco por e-mail, fique atento ao domínio
              utilizado. A plataforma Wida
            </span>
            <span style={{ fontWeight: 700, textDecoration: 'none' }}> </span>
            <span style={{ textDecoration: 'none' }}>
              adota exclusivamente o domínio{' '}
            </span>
            <span style={{ fontWeight: 700, textDecoration: 'none' }}>
              wida.app{' '}
            </span>
            <span style={{ textDecoration: 'none' }}>para comunicações;</span>
          </p>
        </li>
      </ul>

      <ul style={{ paddingInlineStart: '48px' }}>
        <li style={{ textDecoration: 'none' }}>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ textDecoration: 'none' }}>
              Mantenha suas informações pessoais atualizadas conosco, elas serão
              indispensáveis para confirmar sua identidade caso você solicite
              alguma informação ou documento de forma remota.
            </span>
          </p>
        </li>
      </ul>

      <p style={{ marginBottom: '8pt' }}>
        <strong>7. UTILIZAÇÃO DE COOKIES </strong>
      </p>

      <p style={{ textAlign: 'justify' }}>
        <span style={{ textDecoration: 'none' }}>
          Usamos cookies e outras tecnologias (coletivamente, "
        </span>
        <span style={{ fontWeight: 700, textDecoration: 'none' }}>cookies</span>
        <span style={{ textDecoration: 'none' }}>
          ") para melhorar o uso e a funcionalidade da plataforma Wida
        </span>
        <span style={{ fontWeight: 700, textDecoration: 'none' }}> </span>
        <span style={{ textDecoration: 'none' }}>
          e entender melhor como nossos visitantes usam os nossos sites. Os
          cookies nos ajudam a adaptar os sites da plataforma Wida
        </span>
        <span style={{ fontWeight: 700, textDecoration: 'none' }}> </span>
        <span style={{ textDecoration: 'none' }}>
          às suas necessidades pessoais, facilitar cada vez mais o seu uso,
          receber feedback da satisfação do consumidor e nos comunicarmos com
          você de outros locais na internet.
        </span>
      </p>

      <p style={{ textAlign: 'justify' }}>
        <span style={{ textDecoration: 'none' }}>
          Os cookies são pequenos arquivos adicionados ao seu dispositivo ou
          computador pelos sites que você visita, e que permitem guardar
          determinada quantidade de dados sobre suas atividades na internet.
          Eles são empregados para fazerem os sites funcionarem de maneira mais
          efetiva, bem como para fornecer uma experiência personalizada de
          acesso e fornecer informações aos proprietários do site.
        </span>
      </p>

      <p style={{ textAlign: 'justify' }}>
        <span style={{ textDecoration: 'none' }}>
          Como padrão apenas utilizamos cookies indispensáveis para garantir sua
          experiência em nossa página, como o carregamento eficiente de
          informações ou adaptação da nossa página ao seu dispositivo. Quaisquer
          dados eventualmente coletados correspondem exclusivamente a
          configurações do seu sistema, não contendo Informações Pessoais.
        </span>
      </p>

      <p style={{ marginBottom: '8pt' }}>
        <strong>8. SEUS DIREITOS </strong>
      </p>

      <p style={{ textAlign: 'justify' }}>
        <span style={{ textDecoration: 'none' }}>
          Você tem os seguintes direitos em relação aos seus dados pessoais:
        </span>
      </p>

      <ul style={{ paddingInlineStart: '48px' }}>
        <li style={{ textDecoration: 'none' }}>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ textDecoration: 'none' }}>
              Solicitar a cópia de seus dados pessoais mantidos em nossa base de
              dados;
            </span>
          </p>
        </li>
      </ul>

      <ul style={{ paddingInlineStart: '48px' }}>
        <li style={{ textDecoration: 'none' }}>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ textDecoration: 'none' }}>
              Solicitar que os seus dados sejam corrigidos se estiverem
              imprecisos ou desatualizados;
            </span>
          </p>
        </li>
      </ul>

      <ul style={{ paddingInlineStart: '48px' }}>
        <li style={{ textDecoration: 'none' }}>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ textDecoration: 'none' }}>
              Solicitar que seus dados pessoais sejam excluídos;
            </span>
          </p>
        </li>
      </ul>

      <ul style={{ paddingInlineStart: '48px' }}>
        <li style={{ textDecoration: 'none' }}>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ textDecoration: 'none' }}>
              Solicitar a anonimização, bloqueio ou eliminação de dados
              excessivos ou tratados de forma contrária à lei;
            </span>
          </p>
        </li>
      </ul>
      <p style={{ textAlign: 'center' }}>
        <b style={{ fontWeight: 'normal' }}></b>
      </p>
      <ul style={{ paddingInlineStart: '48px' }}>
        <li style={{ textDecoration: 'none' }}>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ textDecoration: 'none' }}>
              Eliminação de dados tratados com base no seu consentimento; e
            </span>
          </p>
        </li>
      </ul>

      <ul style={{ paddingInlineStart: '48px' }}>
        <li style={{ textDecoration: 'none' }}>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ textDecoration: 'none' }}>
              Retirar qualquer consentimento para o processamento de dados
              pessoais a qualquer momento, sendo informado das consequências.
            </span>
          </p>
        </li>
      </ul>

      <p style={{ textAlign: 'justify' }}>
        <span style={{ textDecoration: 'none' }}>
          Conforme legislação vigente, a responsabilidade pela guarda e pelo
          armazenamento dos dados relacionados ao prontuário dos pacientes é de
          responsabilidade exclusiva dos profissionais da saúde, clínicas ou
          hospitais (conforme aplicável).&nbsp;
        </span>
      </p>

      <p style={{ textAlign: 'justify' }}>
        <span style={{ textDecoration: 'none' }}>
          Tendo em vista o papel de operador que o Wida desempenha em relação
          aos dados de pacientes, a portabilidade dos dados de prontuários de
          saúde deverá ser solicitada pelo próprio paciente ao profissional de
          saúde que o atende ou à clínica em que realize tratamento de saúde,
          responsável pela guarda dos dados dos prontuários.
        </span>
      </p>

      <p style={{ textAlign: 'justify' }}>
        <span style={{ textDecoration: 'none' }}>
          Os pedidos para acesso ou para disponibilização de informações
          constantes de prontuários de saúde somente poderão ser solicitados ao
          profissional de saúde ou à clínica em que realizado o tratamento de
          saúde (conforme aplicável).
        </span>
      </p>

      <p style={{ textAlign: 'justify' }}>
        <span style={{ textDecoration: 'none' }}>
          Há casos em que alguns desses direitos não poderão ser exercidos, ou
          poderão ser exercidos apenas parcialmente, por conta de obrigações
          legais de cumprimento da plataforma Wida
        </span>
        <span style={{ fontWeight: 700, textDecoration: 'none' }}> </span>
        <span style={{ textDecoration: 'none' }}>
          ou em caso de um direito da plataforma Wida
        </span>
        <span style={{ fontWeight: 700, textDecoration: 'none' }}> </span>
        <span style={{ textDecoration: 'none' }}>
          de manter esse dado consigo. Nestes casos, garantimos que o acesso e
          utilização a tais dados serão restritos exclusivamente a tais
          finalidades.&nbsp;
        </span>
      </p>

      <p style={{ textAlign: 'justify' }}>
        <span style={{ textDecoration: 'none' }}>
          Em caso de dúvida sobre seus direitos de privacidade e proteção de
          dados, você pode entrar em contato conosco por meio do e-mail{' '}
        </span>
        <span style={{ fontWeight: 700, textDecoration: 'none' }}>
          info@wida.app{' '}
        </span>
        <span style={{ textDecoration: 'none' }}>
          e lhe responderemos o mais rapidamente possível.
        </span>
      </p>

      <p style={{ marginBottom: '8pt' }}>
        <strong>9. ELIMINAÇÃO DE DADOS </strong>
      </p>

      <p style={{ textAlign: 'justify' }}>
        <span style={{ textDecoration: 'none' }}>
          Ao término do contrato entre o usuário Profissional de Saúde e o Wida,
          o Profissional de Saúde é integralmente responsável por solicitar e
          realizar a exportação de todos os dados inseridos em sua conta na
          Plataforma Wida dentro do prazo de 60 (sessenta) dias a contar do
          término do contrato.
        </span>
      </p>

      <p style={{ textAlign: 'justify' }}>
        <span style={{ textDecoration: 'none' }}>
          Caso haja solicitação de eliminação dos dados por qualquer usuário, a
          eliminação somente poderá ocorrer se já não houver mais finalidade de
          uso nem obrigação legal, regulatória ou judicial que justifique sua
          retenção.&nbsp;
        </span>
      </p>

      <p style={{ marginBottom: '8pt' }}>
        <strong>10. ALTERAÇÕES NESTA POLÍTICA </strong>
      </p>

      <p style={{ textAlign: 'justify' }}>
        É possível que esta política sofra alterações, por isso recomendamos que
        você acesse este documento sempre que acessar nosso website. De qualquer
        forma, sempre que alterarmos nossa Política iremos informar você pelos
        canais de contato estabelecidos conosco.&nbsp;
      </p>

      <p style={{ textAlign: 'justify' }}>
        Se o seu consentimento se tornar necessário, vamos entrar em contato com
        você para obtê-lo antes da utilização. Recomendamos que você esteja
        atento aos canais de contato fornecidos à plataforma Wida, pois, neste
        caso, não poderemos utilizar seus dados em seu benefício enquanto seu
        consentimento não for devidamente confirmado.
      </p>

      <p style={{ textAlign: 'justify', marginBottom: '8pt' }}>
        <strong>11. LEI APLICÁVEL E FORO</strong>
      </p>

      <p style={{ textAlign: 'justify' }}>
        Sempre que a legislação permitir, a lei aplicável a esta Política de
        Privacidade serão as leis da República Federativa do Brasil e o foro
        para discussão de quaisquer demandas originadas deste documento será o
        foro central da comarca de Porto Alegre - RS, em prejuízo de qualquer
        outro, por mais privilegiados que o sejam.&nbsp;
      </p>

      <p style={{ textAlign: 'justify', marginBottom: '8pt' }}>
        <strong>12. DÚVIDAS ESCLARECIMENTOS</strong>
      </p>

      <p style={{ textAlign: 'justify' }}>
        Se mesmo após a leitura atenta deste documento você ainda tiver dúvidas
        ou precisar de algum esclarecimento, estamos à disposição por meio do
        e-mail: <strong>info@wida.app</strong>
      </p>
    </>
  )
}

export default PrivacyPolicy
