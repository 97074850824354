import { useState, useEffect } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { getTokenByCode } from 'services'

function RedirectSignupCode(): JSX.Element {
  const [tokenData, setTokenData] = useState<any>(null)

  const { code } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const fetchToken = async () => {
      try {
        if (!code)
          throw new Error('Ocorreu um erro ao tentar acessar o convite')

        const tokenData = await getTokenByCode(code!)
        setTokenData(tokenData)
      } catch (error: any) {
        navigate('/expired_invitation')
      }
    }

    fetchToken()
  }, [code, navigate])

  if (!tokenData) return <></>

  return (
    <Navigate
      to={
        tokenData?.type === 'informant'
          ? `/signup/informant?invitationToken=${tokenData.token}`
          : `/signup?invitationToken=${tokenData.token}`
      }
      replace
    />
  )
}

export default RedirectSignupCode
