import { Stack } from '@mui/material'
import { SecondaryConfirmationModal, ButtonClinic } from 'components'
import { DescriptionText } from './resend-patient-warning-modal-styles'

function ResendPatientWarningModal({
  handleCancel,
  handleConfirm,
}: any): JSX.Element {
  return (
    <SecondaryConfirmationModal
      isOpen={true}
      title='Atenção: deseja sobrescrever?'
      hideButtons
      onClose={handleCancel}
      content={
        <Stack alignItems='center'>
          <Stack px={4} my={2}>
            <DescriptionText>
              Ao criar um novo, o questionário atual será sobrescrito e não
              estará mais disponível ao paciente.
            </DescriptionText>
            <DescriptionText>
              Esta ação não poderá ser desfeita.
            </DescriptionText>
          </Stack>

          <Stack direction='row' spacing={4} mt={3} mb={3}>
            <ButtonClinic
              variant='outlined'
              onClick={handleCancel}
              width={140}
              color='purple'
              size='small'
              fontWeight='bold'
            >
              Cancelar
            </ButtonClinic>

            <ButtonClinic
              variant='contained'
              onClick={handleConfirm}
              width={140}
              color='purple'
              size='small'
              fontWeight='bold'
            >
              Continuar
            </ButtonClinic>
          </Stack>
        </Stack>
      }
    />
  )
}

export default ResendPatientWarningModal
