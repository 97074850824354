import { Document, Page } from '@react-pdf/renderer'

import { PatientReport } from 'types/patient-report-types'
import Footer from './components/footer/footer'
import Header from './components/header/header'
import Results from './components/results/results'
import TagAnswers from './components/tag-answers/tag-answers'
import { styles } from './pdf-document-styles'

interface PdfDocumentProps {
  data?: PatientReport
}

function PdfDocument({ data }: PdfDocumentProps) {
  const withClinicalOpinion = !!data?.withClinicalOpinion

  return (
    <Document>
      {withClinicalOpinion && (
        <Page size='A4' style={styles.page}>
          <Header data={data} isFirstPage={withClinicalOpinion} />
          <Results data={data} isClinicalAvaliation />
          <Footer />
        </Page>
      )}
      <Page size='A4' style={styles.page}>
        <Header data={data} isFirstPage={!withClinicalOpinion} />
        <Results data={data} />
        <Footer />
      </Page>
      {data?.tags.map((tag) => (
        <Page key={tag.id} size='A4' style={styles.page}>
          <Header data={data} />
          <TagAnswers
            tag={tag}
            emissionDate={data.createdAt}
            formRequests={data.formRequests}
            clinicalOpinions={data?.clinicalOpinions}
            withClinicalAvaliation={withClinicalOpinion}
          />
          <Footer showAnswers />
        </Page>
      ))}
    </Document>
  )
}

export default PdfDocument
