import { useNavigate } from 'react-router-dom'
import { Box, Divider, MenuItem } from '@mui/material'
import { useAuthContext } from 'contexts'
import { StyledMenu } from './profile-menu-styles'

function ProfileMenu(props: any): JSX.Element {
  const { signout } = useAuthContext()
  const navigate = useNavigate()

  const goToMyProfile = (tab?: string) =>
    navigate('/profile', { state: { tab } })

  const handleSignout = () => signout('clinic')

  const handleAbout = () => window.open('https://www.wida.app')

  const handleFAQ = () => window.open('https://www.wida.app/faq-profissionais')

  const menuItems = [
    { title: 'Dados pessoais', onClick: goToMyProfile },
    { title: 'Notificações', onClick: () => goToMyProfile('Notificações') },
    { title: 'Pagamento', onClick: () => goToMyProfile('Dados de pagamento') },
    { title: 'Termos de uso', onClick: () => goToMyProfile('Termos de uso') },
    { title: 'Fale conosco', onClick: () => goToMyProfile('Fale Conosco') },
    {},
    { title: 'Sobre o Wida', onClick: handleAbout },
    { title: 'Perguntas frequentes', onClick: handleFAQ },
    {},
    { title: 'Sair', onClick: handleSignout },
  ]

  const handleClick = (onClick?: () => void) => {
    if (!onClick) return

    onClick()
    props?.onClose()
  }

  return (
    <StyledMenu {...props}>
      {menuItems.map(({ title, onClick }, index) =>
        !title ? (
          <Box key={index} my={1} mx={3}>
            <Divider />
          </Box>
        ) : (
          <MenuItem
            key={title}
            onClick={() => handleClick(onClick)}
            disableRipple
          >
            {title}
          </MenuItem>
        )
      )}
    </StyledMenu>
  )
}

export default ProfileMenu
