import { styled } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'

export const Input = styled(InputBase)({
  width: '370px',
  borderRadius: '32px 0px 0px 32px',
  padding: '8px 24px',
  color: '#596B7B',
  fontFamily: 'Quicksand',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '31px',
  '& .Mui-disabled': {
    color: '#596B7B',
    '-webkit-text-fill-color': '#596B7B',
  },
})
