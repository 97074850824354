import { getPatientReport } from 'services'
import PdfDocument from '../pdf-document'
import { pdf } from '@react-pdf/renderer'
import saveAs from 'file-saver'
import { getFullName } from './name'
import { getFormatedResponseDate } from './date'

export const handleDownloadPdf = async (patientReportId: string) => {
  const data = await getPatientReport(patientReportId)

  const doc = <PdfDocument data={data} />
  const asPdf = pdf()
  asPdf.updateContainer(doc)
  const blob = await asPdf.toBlob()

  const fullName = getFullName({
    firstName: data?.patient.personalInfo?.firstName,
    lastName: data?.patient.personalInfo?.lastName,
  })
  saveAs(
    blob,
    `Relatorio Wida - ${fullName} - ${getFormatedResponseDate(
      data?.createdAt
    )}.pdf`
  )
}
