import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

export const BackButton = styled(Typography)({
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '21px',
  cursor: 'pointer',
  color: '#F36F60',
  transition: '0.4s',
  '&:hover': {
    opacity: '0.6',
  },
})

export const CheckboxText = styled(Typography)({
  color: '#596B7B',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
})

export const Title = styled(Typography)(({ theme }) => ({
  color: '#657787',
  fontFamily: 'Quicksand',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '24px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px'
  }
}))

export const ChangeLoginTypeText = styled(Typography)(({ theme }) => ({
  color: '#596B7B',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
  },
}))

export const ChangeLoginTypeLink = styled('span')(({ theme }) => ({
  color: '#8D92BE',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px',
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
  },
}))

export const InfoCenterText = styled(Typography)(({ theme }) => ({
  color: '#596B7B',
  textAlign: 'center',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
  },
}))

export const InfoLeftText = styled(Typography)(({ theme }) => ({
  color: '#596B7B',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '13px',
    lineHeight: '19.5px'
  },
}))

export const TooltipPasswordText = styled(Typography)({
  color: '#FFFFFF',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '14px',
  leadingTrim: 'both',
  textEdge: 'cap',
})

export const WelcomeText = styled(Typography)(({ theme }) => ({
  color: '#596B7B',
  textAlign: 'center',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  [theme.breakpoints.down('md')]: {
    fontSize: '15px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '13px',
    lineHeight: '22px'
  },
}))
