import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  symptomsView: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
  symptomsTitle: {
    fontSize: 8,
    fontWeight: 600,
    fontStyle: 'bold',
    color: '#596B7B',
  },
  symptomsText: {
    fontSize: 8,
    color: '#596B7B',
  },
  text: {
    fontSize: 11,
    fontFamily: 'Zen Kaku Gothic Antique',
    color: '#596B7B',
  },
  respondersDot: {
    width: 10,
    height: 10,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: '#F7F7F7',
  },
  contentView: {
    flexDirection: 'row',
    marginLeft: 24,
    marginRight: 52,
    gap: 24,
  },
  allSymptomsView: {
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'center',
    height: 8,
  },
  singleSymptomView: {
    height: 17,
    width: 6,
    borderTopLeftRadius: 1.4,
    borderTopRightRadius: 1.4,
  },
  graphLine: {
    position: 'absolute',
    top: -5,
    left: '23%',
    bottom: 6,
    borderLeft: '0.5px solid #DEDEDE',
  },
  graphFooterLine: {
    height: 6,
    width: 0.5,
    backgroundColor: '#DEDEDE',
  },
})
