function TermsOfUse({ scrollToPolicy }: any): JSX.Element {
  return (
    <>
      <p style={{ textAlign: 'justify' }}>
        Estes termos de uso ("<strong>Termos de Uso</strong>") regulam seu
        acesso e uso do site público ("<strong>Site</strong>") e do portal
        privado que requer registro para ser acessado ("
        <strong>Portal Privado</strong>", e juntamente com o Site, "
        <strong>Plataforma Wida</strong>") localizados em www.wida.app,
        plataforma.wida.app e outros subdomínios. As palavras "
        <strong>Wida</strong>", "<strong>nós</strong>", "<strong>nos</strong>"
        ou "<strong>nosso</strong>" significam, coletivamente, Wida Ltda.,
        inscrita no CNPJ 47.007.977/0001-02, com endereço na R. Félix da Cunha,
        nº 737, Cj. 302, Floresta, CEP 90.570-001, Porto Alegre/RS, e todas as
        suas respectivas afiliadas, sucessoras ou cessionárias.
      </p>

      <p style={{ textAlign: 'justify' }}>
        Por favor, leia atentamente estes Termos de Uso. Estes Termos de Uso
        regem seu acesso e uso da Plataforma Wida e constituem um acordo legal
        vinculante entre você e o Wida.
      </p>

      <p style={{ textAlign: 'justify' }}>
        A utilização da Plataforma Wida está expressamente condicionada à
        aceitação destes Termos de Uso.
      </p>

      <p style={{ textAlign: 'justify' }}>
        Se você não puder cumprir as obrigações destes Termos de Uso ou a
        Política de Privacidade, você não poderá usar a Plataforma Wida e seus
        serviços.
      </p>

      <p style={{ textAlign: 'justify' }}>
        A PLATAFORMA WIDA NÃO SUBSTITUI EM NENHUMA CIRCUNSTÂNCIA QUALQUER
        ATENÇÃO DE EMERGÊNCIA.&nbsp;
      </p>
      <p style={{ textAlign: 'justify' }}>
        SE VOCÊ TIVER UMA EMERGÊNCIA MÉDICA OU DE SAÚDE MENTAL, SE ESTIVER
        CONSIDERANDO SUICÍDIO OU SE ESTIVER NECESSITANDO DE ATENDIMENTO URGENTE,
        PROCURE UM PROFISSIONAL DE SUA CONFIANÇA OU ATENDIMENTO DE SAÚDE{' '}
        <span
          style={{
            textDecorationLine: 'underline',
            textDecorationSkipInk: 'none',
          }}
        >
          IMEDIATAMENTE
        </span>
        .
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        <strong>1. Documentos Incorporados por Referência</strong>
      </p>

      <p style={{ lineHeight: 1.2 }}>
        Faz parte destes Termos de Uso, por referência, o seguinte documento:
      </p>

      <p style={{ marginLeft: '35pt', textAlign: 'justify' }}>
        <span
          style={{
            fontStyle: 'italic',
            textDecorationLine: 'underline',
            textDecorationSkipInk: 'none',
            cursor: 'pointer',
          }}
          onClick={scrollToPolicy}
        >
          Política de Privacidade
        </span>
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        <strong>2. Serviços da Plataforma Wida</strong>
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        2.1. Wida é uma provedora de serviços de tecnologia que facilita os
        serviços prestados por Profissionais de Saúde aos Pacientes destes,
        incluindo, sem limitação, os seguintes serviços:
      </p>

      <ol style={{ paddingInlineStart: '48px' }}>
        <li style={{ listStyleType: 'lower-alpha' }}>
          <p style={{ textAlign: 'justify' }} role='presentation'>
            a entrada e a retenção de dados clínicos e de documentos relativos
            ao(s) Paciente(s) pelo Profissional de Saúde;
          </p>
        </li>
      </ol>

      <ol style={{ paddingInlineStart: '48px' }} start={2}>
        <li style={{ listStyleType: 'lower-alpha' }}>
          <p style={{ textAlign: 'justify' }} role='presentation'>
            o envio de questionários de saúde mental ao(s) Paciente(s), conforme
            definido exclusivamente pelo Profissional de Saúde;
          </p>
        </li>
      </ol>

      <ol style={{ paddingInlineStart: '48px' }} start={3}>
        <li style={{ listStyleType: 'lower-alpha' }}>
          <p style={{ textAlign: 'justify' }} role='presentation'>
            a exibição dos resultados de tais questionários para o Profissional
            de Saúde;
          </p>
        </li>
      </ol>

      <ol style={{ paddingInlineStart: '48px' }} start={4}>
        <li style={{ listStyleType: 'lower-alpha' }}>
          <p style={{ textAlign: 'justify' }} role='presentation'>
            a facilitação da comunicação e da colaboração entre o Paciente e
            Profissional de Saúde;&nbsp;
          </p>
        </li>
      </ol>

      <ol style={{ paddingInlineStart: '48px' }} start={5}>
        <li style={{ listStyleType: 'lower-alpha' }}>
          <p style={{ textAlign: 'justify' }} role='presentation'>
            a colaboração de terceiros (Informantes) no tratamento realizado
            pelo Profissional de Saúde por meio de envio de informações sobre o
            Paciente; e
          </p>
        </li>
      </ol>

      <ol style={{ paddingInlineStart: '48px' }} start={6}>
        <li style={{ listStyleType: 'lower-alpha' }}>
          <p style={{ textAlign: 'justify' }} role='presentation'>
            o fornecimento de outros produtos e serviços por meio da Plataforma
            Wida, conforme desenvolvidos de tempos em tempos.
          </p>
        </li>
      </ol>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        2.2. Os serviços da Plataforma Wida têm por único objetivo auxiliar, por
        meio da tecnologia, o Profissional de Saúde no seu atendimento, restando
        claro que toda a responsabilidade pelo atendimento do Paciente é
        <strong>exclusivamente</strong> do Profissional da Saúde.&nbsp;
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        2.3. Cabe unicamente ao Profissional de Saúde avaliar a conveniência e a
        oportunidade da utilização da Plataforma Wida caso a caso.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        2.4. Os serviços disponibilizados pela Plataforma Wida são prestados
        exclusivamente ao Profissional de Saúde, sendo certo que a participação
        de Pacientes ou Informantes (conforme definido abaixo) restringe-se a
        livremente prestar informações sobre o Paciente ao Profissional da Saúde
        por meio da Plataforma Wida.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        <strong>3. Profissionais da Saúde</strong>
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        3.1. O Profissional da Saúde é aquele profissional que está habilitado
        legalmente para prestar serviços na área de cuidados em saúde mental,
        incluindo, mas não se limitando a, médicos, psicólogos, assistentes
        sociais, terapeutas ocupacionais e outros psicoterapeutas ("
        <strong>Profissionais de Saúde</strong>").
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        3.2. Os Profissionais de Saúde são os únicos responsáveis pelo
        tratamento e pelo atendimento de seus pacientes, incluindo a obrigação
        de avaliar se a utilização, incluindo, mas não se limitando a, a forma
        de envio de questionários e à frequência de uso, da Plataforma Wida é
        adequada a cada Paciente.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        3.3. Os Profissionais de Saúde poderão lançar mão de questionários ou de
        outras funcionalidades da Plataforma Wida como subsídio ao cuidado
        clínico e à atenção ao Paciente, por conta e risco.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        3.4. É dever e responsabilidade exclusivos do Profissional de Saúde
        avaliar a conveniência e os riscos de autorizar a utilização da
        Plataforma Wida para auxílio de tratamento de menores de idade ou
        pessoas absoluta ou relativamente incapazes, especialmente para o fim de
        autorizar os Pacientes a convidarem terceiros (como Informantes) para
        responderem a questões sobre a saúde mental do Paciente.&nbsp;
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        3.5. O Profissional da Saúde deve analisar caso a caso a possibilidade
        de utilizar a Plataforma Wida no cuidado de menores e pessoas incapazes.
        É responsabilidade exclusiva do Profissional de Saúde obter todas as
        autorizações necessárias dos pais ou dos representantes legais nessas
        situações, de forma que estes estejam cientes e aceitem os presentes
        Termos de Uso.&nbsp;
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        <strong>4. Pacientes</strong>
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        4.1. O "<strong>Paciente</strong>" é qualquer pessoa que abra uma conta
        na Plataforma Wida que esteja sendo atendido por um Profissional de
        Saúde e sobre quem tal Profissional da Saúde insira ou registre
        informações na Plataforma Wida, incluindo os representantes legais de um
        Paciente menor ou incapaz.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        4.2. Ao abrir uma conta no Wida, o Paciente (ou seu representante legal)
        será o único responsável por enviar convites para terceiros
        (“Informantes”) para que estes possam responder perguntas diretamente na
        Plataforma Wida.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        4.3. Para utilização da Plataforma Wida por menores de idade, pessoas
        absolutamente ou relativamente incapazes, é necessária a autorização
        expressa de seu representante legal (pais, tutor, curador, assistente ou
        outro representante legal). Neste caso, o representante legal deverá
        aceitar estes Termos de Uso e todas as menções neste documento a
        “Paciente” abrangem tanto o paciente quanto o seu representante legal.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        4.4. No caso de Pacientes absoluta ou relativamente incapazes,
        pressupõe-se que a aceitação destes Termos de Uso tenha ocorrido com a
        ciência e com a concordância dos seus representantes legais. O
        Profissional da Saúde e/ou o Paciente deverão notificar imediatamente o
        Wida através do e-mail <strong>info@wida.app </strong>caso haja a
        suspeita de irregularidades no processo de cadastro ou na utilização
        indevida da Plataforma Wida por Pacientes absoluta ou relativamente
        incapazes.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        <strong>5. Informantes</strong>
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        5.1. Os Informantes são pessoas do círculo de convívio familiar, social,
        educacional, ocupacional, ou outro do Paciente, selecionadas e indicadas
        por este exclusivamente, para responderem questões relativas à saúde
        mental do Paciente (“<strong>Informantes</strong>”).&nbsp;
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        5.2. Os Informantes não terão acesso a quaisquer informações clínicas do
        Paciente, as respostas fornecidas por si mesmo ou outros Informantes ou
        qualquer outro dado sensível, estando limitados a responderem conjuntos
        de perguntas selecionadas pelo Profissional da Saúde sobre o Paciente.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        5.3. Os Informantes que sejam absoluta ou relativamente incapazes
        deverão obter a autorização expressa de seu representante legal (pais,
        tutor, curador, assistente ou outro representante legal). Neste caso, o
        representante legal deverá aceitar estes Termos de Uso e todas as
        menções neste documento a “Informante” abrangem tanto o paciente quanto
        o seu representante legal.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        5.4. No caso de Informantes absoluta ou relativamente incapazes,
        pressupõe-se que a aceitação destes Termos de Uso tenha ocorrido com a
        ciência e com a concordância dos seus representantes legais. O
        Profissional da Saúde ou o Informante deverão notificar imediatamente o
        Wida através do e-mail <strong>info@wida.app </strong>caso haja a
        suspeita de irregularidades no processo de cadastro ou na utilização
        indevida da Plataforma Wida por Informantes absoluta ou relativamente
        incapazes.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        <strong>6. Aplicação destes Termos de Uso</strong>
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        6.1. O acesso e o uso da Plataforma Wida estão condicionados à aceitação
        integral destes Termos de Uso e da Política de Privacidade.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        6.2. Caso você não aceite estes Termos de Uso, você não está autorizado
        a seguir com seu cadastro e nem abrir uma conta na Plataforma Wida.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        <strong>7. Ausência de aconselhamento médico</strong>
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        <strong>
          6.1. O WIDA NÃO PRESTA QUAISQUER SERVIÇOS DE SAÚDE, SERVIÇOS DE SAÚDE
          MENTAL, SERVIÇOS SOCIAIS OU DE PSICOTERAPIA, OU QUAISQUER OUTROS
          SERVIÇOS PROFISSIONAIS REGULAMENTADO, COMO DIAGNÓSTICO E TRATAMENTO.
        </strong>
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        <strong>
          6.2. O TRATAMENTO E O CUIDADO DO PACIENTE SÃO DE RESPONSABILIDADE
          EXCLUSIVA DO PROFISSIONAL DE SAÚDE, DEVIDAMENTE REGULAMENTADO PARA
          REALIZAR O DIAGNÓSTICO, ACOMPANHAMENTO E TRATAMENTO. NENHUMA DAS
          INFORMAÇÕES DA PLATAFORMA WIDA REPRESENTA OU GARANTE QUE QUALQUER
          TRATAMENTO OU MEDICAMENTO PRESCRITO É SEGURO, ADEQUADO OU EFICAZ AO
          PACIENTE. ESSA AVALIAÇÃO É DE RESPONSABILIDADE E COMPETÊNCIA EXCLUSIVA
          DO PROFISSIONAL DE SAÚDE DO PACIENTE.
        </strong>
      </p>

      <p style={{ textAlign: 'justify' }}>
        <strong>7. Elegibilidade do Paciente e do Informante</strong>
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        7.1. O cadastro do{' '}
        <span
          style={{
            textDecorationLine: 'underline',
            textDecorationSkipInk: 'none',
          }}
        >
          Paciente
        </span>{' '}
        na Plataforma Wida depende exclusivamente (i) de um convite por
        Profissional de Saúde registrado na Plataforma e (ii) da aceitação dos
        Termos de Uso e da Política de Privacidade do Wida por parte do Paciente
        (ou seu representante legal).
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        7.2. O cadastro do{' '}
        <span
          style={{
            textDecorationLine: 'underline',
            textDecorationSkipInk: 'none',
          }}
        >
          Informante
        </span>{' '}
        na Plataforma Wida depende exclusivamente (i) de um convite eletrônico,
        individual e intransferível, enviado diretamente por um Paciente ou seu
        Responsável através da Plataforma Wida; (ii) e da aceitação do
        Informante dos Termos de Uso e Política de Privacidade do Wida.&nbsp;
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        7.3. O Paciente/Responsável e o Informante, conforme aplicável, declaram
        que:
      </p>

      <ol style={{ paddingInlineStart: '48px' }}>
        <li style={{ listStyleType: 'lower-alpha' }}>
          <p style={{ textAlign: 'justify' }} role='presentation'>
            <span>
              cada um tem capacidade legal e autoridade para concordar com os
              termos e condições destes Termos de Uso; e&nbsp;
            </span>
          </p>
        </li>
      </ol>

      <ol style={{ paddingInlineStart: '48px' }} start={2}>
        <li style={{ listStyleType: 'lower-alpha' }}>
          <p style={{ textAlign: 'justify' }} role='presentation'>
            <span>
              todas as informações de identificação e cadastro enviadas são
              precisas e verdadeiras e atualizadas.
            </span>
          </p>
        </li>
      </ol>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        <strong>8. Acesso do Paciente ou do Informante</strong>
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        8.1. Para acessar a Plataforma Wida, um formulário de cadastro online
        deverá ser preenchido para criação de uma conta pessoal (“
        <strong>Conta</strong>”).
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        8.2. Para acesso e abertura da Conta, você concorda em fornecer
        informações verdadeiras, atuais e completas, conforme solicitado em
        qualquer formulário de cadastro para o qual a Plataforma Wida possa
        contatá-lo, e em atualizar essas informações conforme necessário.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        8.3. Para o cadastro, você informará o seu CPF e criará uma senha da
        Conta.
        <strong>
          Você é responsável por manter essa senha e seus dados confidenciais
        </strong>
        , bem como<strong>&nbsp;</strong>por toda e qualquer atividade que
        ocorra na Plataforma Wida sob tal Conta. Você concorda em notificar
        imediatamente o&nbsp;Wida sobre qualquer uso não autorizado de sua Conta
        ou de qualquer outra violação de segurança ou privacidade que você tome
        conhecimento. Você concorda em não disponibilizar acesso à sua Conta a
        qualquer outra pessoa ou sistema. Considerando que seu usuário na
        Plataforma Wida será identificado por seu número de CPF, qualquer
        terceiro que eventualmente digite o número de seu CPF no sistema de
        acesso poderá ser notificado a respeito da existência de usuário
        cadastrado na Plataforma Wida com o CPF informado.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        8.4. Ao criar uma Conta, você concorda expressamente com o uso de: (a)
        meios eletrônicos para assinar estes Termos de Uso e para fornecer a
        você quaisquer notificações e avisos referentes à utilização da
        Plataforma Wida; (b) registros eletrônicos para armazenar informações
        relacionadas a estes Termos de Uso ou aos serviços. O Wida não pode e
        não será responsável por qualquer perda ou dano decorrente de sua falha
        em cumprir os requisitos acima.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        8.5. Você concorda que a utilização da Plataforma Wida poderá ser
        suspensa ou interrompida, sem aviso prévio, a fim de manter ou restaurar
        a segurança ou o desempenho de quaisquer sistemas Wida, inclusive para
        atualizações necessárias ou motivos de força maior ou caso fortuito.
        Igualmente, a nosso exclusivo critério, poderemos suspender ou bloquear
        o acesso à Plataforma se tivermos razões para suspeitar que esteja
        ocorrendo a violação destes Termos de Uso ou da Política de Privacidade,
        ou que sua Conta foi ou pode estar sendo usada por pessoa(s) ou por
        agente(s) não autorizado(s).
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        <strong>9. Conta do Paciente</strong>
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        9.1. A Conta do Paciente (e/ou de seu representante legal) será
        registrada na Plataforma Wida para fins de inserir, armazenar e acessar
        informações de cadastro e status dos questionários enviados, incluindo
        status dos questionários compartilhados por você com Informantes (“
        <strong>Informações Pessoais</strong>”). Todas as Informações Pessoais
        contidas no perfil do Paciente serão mantidas de acordo com os Termos de
        Uso e nossa Política de Privacidade. As respostas fornecidas nos
        questionários, seja pelo próprio Paciente, seu representante legal ou
        por Informantes, não estarão disponíveis para visualização na Conta do
        Paciente, estando disponíveis apenas para o Profissional da Saúde
        vinculado ao Paciente e responsável pela elaboração do questionário.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        9.2. Ao consentir em receber os serviços usando a Plataforma Wida, o
        Paciente concorda com os riscos reais inerentes e associados ao
        <span style={{ fontStyle: 'italic' }}>upload</span>, transmissão e
        armazenamento eletrônico de suas Informações Pessoais. Ao fornecer ou
        fazer <span style={{ fontStyle: 'italic' }}>upload</span>
        de qualquer uma das Informações Pessoais do Paciente na Plataforma Wida,
        o Paciente concorda com a coleta, uso e divulgação de suas Informações
        Pessoais para fins de fornecimento dos serviços ao Paciente pelo
        Profissional da Saúde.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        9.3. Embora o Wida use tecnologia de criptografia avançada e outros
        protocolos de segurança para proteger suas Informações Pessoais, o
        Paciente reconhece que, ao usar a Plataforma Wida, existem riscos
        inerentes a qualquer tecnologia, por mais remotos que sejam, que podem
        causar falha ou violação de protocolos de segurança, acesso não
        autorizado por terceiros, coleta, uso ou divulgação de suas Informações
        Pessoais. O Wida irá notificar o Paciente e o Profissional da Saúde
        conforme exigido por lei sobre qualquer falha ou violação do protocolo
        de segurança que tenha resultado na coleta, uso ou divulgação não
        autorizada de Informações Pessoais do Paciente.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        <strong>10. Conta do Informante</strong>
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        10.1. A Conta do Informante será registrada na Plataforma Wida tão
        somente para realizar o seu cadastro na Plataforma Wida na qualidade de
        Informante. Os Informantes não terão acesso a dados pessoais ou
        informações clínicas do Paciente (ou seu representante legal).
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        10.2. A Conta do Informante limitar-se-á a habilitá-lo a responder
        questionários, previamente definidos pelo Profissional de Saúde, e cujas
        respostas serão disponibilizadas exclusivamente ao Profissional de Saúde
        responsável pelo atendimento clínico do Paciente.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        10.3. Ao realizar o cadastro na Plataforma Wida, o Informante concorda
        com os riscos reais inerentes e associados ao upload, transmissão e
        armazenamento eletrônico de seus dados cadastrais.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        10.4. Embora o Wida use tecnologia de criptografia avançada e outros
        protocolos de segurança para proteger os dados de acesso do Informante,
        este reconhece que, ao usar a Plataforma Wida, existem riscos inerentes
        a qualquer tecnologia, por mais remotos que sejam, que podem causar
        falha ou violação de protocolos de segurança, acesso não autorizado por
        terceiros, coleta, uso ou divulgação de suas Informações Pessoais. O
        Wida irá notificar o Informante conforme exigido por lei sobre qualquer
        falha ou violação do protocolo de segurança que tenha resultado na
        coleta, uso ou divulgação não autorizada dos dados do Informante.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        <strong>11. Responsabilidade do cuidado do Paciente</strong>
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        11.1. A responsabilidade pelo tratamento e cuidado do Paciente é
        exclusiva do Profissional da Saúde.&nbsp;
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        11.2. O Paciente neste ato expressamente declara e entende que o Wida
        não possui qualquer responsabilidade pelo método de tratamento utilizado
        pelo Profissional de Saúde, sendo este o único responsável legal pelos
        serviços prestados ao Paciente.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        11.3. Todos os Profissionais de Saúde cadastrados na Plataforma Wida
        expressamente declararam ser profissionais de saúde habilitados
        legalmente para o desempenho de serviços médicos e de atenção à saúde.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        11.4. O Wida não se responsabiliza por informações inverídicas ou
        desatualizadas ou declarações falsas prestadas pelos usuários do Wida.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        11.5. O WIDA NÃO CONFERE A VALIDADE DE QUALQUER HABILITAÇÃO PROFISSIONAL
        DO SEU PROFISSIONAL DE SAÚDE, DE FORMA QUE A RESPONSABILIDADE PELO
        TRATAMENTO REALIZADO É EXCLUSIVA DESTE.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        <strong>
          12. Compartilhamento de Informações Pessoais dos Pacientes
        </strong>
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        12.1. As Informações Pessoais dos Pacientes somente serão acessíveis
        pelo respectivo Profissional de Saúde do Paciente e somente poderão ser
        transmitidas a terceiros, inclusive a outros profissionais de saúde do
        Paciente, caso solicitado e consentido por escrito pelo Paciente.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        12.2. As informações e o prontuário do Paciente são de titularidade
        deste, devendo o Profissional de Saúde, nos termos da legislação
        vigente, garantir que o prontuário esteja permanentemente à disposição
        do Paciente. Caso o Paciente solicite acesso ou a transferência de seu
        prontuário a terceiros, o Profissional de Saúde será o único responsável
        por disponibilizá-lo com as informações pertinentes, conforme previsto
        na legislação aplicável.&nbsp;
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        12.3. Tendo em vista o papel de operador que o Wida desempenha em
        relação aos dados de pacientes, a portabilidade dos dados de prontuários
        deverá ser solicitada pelo próprio Paciente ao Profissional de Saúde que
        o atende ou à clínica em que realize tratamento de saúde, responsável
        pela guarda dos dados dos prontuários.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        12.4. O Wida em nenhuma hipótese terá a obrigação de fornecer ao
        Paciente informações sobre o prontuário ou outras informações médicas do
        Paciente, cuja responsabilidade é exclusiva do Profissional da Saúde.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        <strong>13. Obrigações do Paciente e do Informante</strong>
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        13.1. Ao usar a Plataforma Wida, o Paciente e o Informante, conforme
        aplicável, concordam e obrigam-se a:
      </p>

      <ol style={{ paddingInlineStart: '48px' }}>
        <li style={{ listStyleType: 'lower-alpha' }}>
          <p style={{ textAlign: 'justify' }} role='presentation'>
            <span>
              sempre usar a Plataforma Wida e os serviços de acordo com todas as
              leis e regulamentos aplicáveis;
            </span>
          </p>
        </li>
      </ol>

      <ol style={{ paddingInlineStart: '48px' }} start={2}>
        <li style={{ listStyleType: 'lower-alpha' }}>
          <p style={{ textAlign: 'justify' }} role='presentation'>
            <span>
              manter a confidencialidade das Informações Pessoais de todos os
              dados inseridos na Plataforma Wida;
            </span>
          </p>
        </li>
      </ol>

      <ol style={{ paddingInlineStart: '48px' }} start={3}>
        <li style={{ listStyleType: 'lower-alpha' }}>
          <p style={{ textAlign: 'justify' }} role='presentation'>
            <span>
              garantir que as informações da Conta sejam atuais e precisas.
              Essas informações de contato incluem, mas não se limitam a nome,
              endereço, números de telefone, CPF e endereços de e-mail. Tais
              informações deverão ser atualizadas sempre que necessário pelo
              Paciente ou pelo Informante;
            </span>
          </p>
        </li>
      </ol>

      <ol style={{ paddingInlineStart: '48px' }} start={4}>
        <li style={{ listStyleType: 'lower-alpha' }}>
          <p style={{ textAlign: 'justify' }} role='presentation'>
            <span>não fazer </span>
            <span style={{ fontStyle: 'italic' }}>upload</span>
            <span>
              , postar, enviar por e-mail ou transmitir qualquer Conteúdo
              (conforme definido na Seção 15 abaixo) que você não tenha o
              direito legal de copiar, transmitir e exibir (incluindo qualquer
              Conteúdo que viole qualquer confidencialidade);
            </span>
          </p>
        </li>
      </ol>

      <ol style={{ paddingInlineStart: '48px' }} start={5}>
        <li style={{ listStyleType: 'lower-alpha' }}>
          <p style={{ textAlign: 'justify' }} role='presentation'>
            <span>
              não usar os serviços para coletar ou armazenar informações sobre a
              Plataforma Wida ou sobre dados pessoais sobre outros usuários;
            </span>
          </p>
        </li>
      </ol>

      <ol style={{ paddingInlineStart: '48px' }} start={6}>
        <li style={{ listStyleType: 'lower-alpha' }}>
          <p style={{ textAlign: 'justify' }} role='presentation'>
            <span>
              desabilitar quaisquer recursos relacionados à segurança dos
              serviços ou recursos que impeçam ou restrinjam o uso, ou favoreçam
              a cópia de qualquer Conteúdo;
            </span>
          </p>
        </li>
      </ol>

      <ol style={{ paddingInlineStart: '48px' }} start={7}>
        <li style={{ listStyleType: 'lower-alpha' }}>
          <p style={{ textAlign: 'justify' }} role='presentation'>
            <span>
              não violar nem prejudicar qualquer medida de segurança ou
              autenticação da Plataforma Wida;
            </span>
          </p>
        </li>
      </ol>

      <ol style={{ paddingInlineStart: '48px' }} start={8}>
        <li style={{ listStyleType: 'lower-alpha' }}>
          <p style={{ textAlign: 'justify' }} role='presentation'>
            <span>
              não fazer engenharia reversa nem tentar descobrir ou determinar o
              código-fonte de qualquer software ou qualquer algoritmo
              proprietário da Plataforma Wida;
            </span>
          </p>
        </li>
      </ol>

      <ol style={{ paddingInlineStart: '48px' }} start={9}>
        <li style={{ listStyleType: 'lower-alpha' }}>
          <p style={{ textAlign: 'justify' }} role='presentation'>
            <span>
              não usar os serviços de qualquer forma que concorra com a
              Plataforma Wida, incluindo, sem limitação, deturpar a própria
              identidade ou se passar por um Paciente para obter, direta ou
              indiretamente, informações sobre a Plataforma Wida ou sobre o
              Conteúdo; e
            </span>
          </p>
        </li>
      </ol>

      <ol style={{ paddingInlineStart: '48px' }} start={10}>
        <li style={{ listStyleType: 'lower-alpha' }}>
          <p style={{ textAlign: 'justify' }} role='presentation'>
            <span>
              não encorajar nem instruir qualquer outra pessoa ou entidade a
              fazer qualquer um dos itens anteriores.
            </span>
          </p>
        </li>
      </ol>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        13.2. O uso da Plataforma Wida depende de dispositivos e equipamentos
        compatíveis, serviço de conexão à internet com antivírus e
        <i>firewall</i>
        instalados, de responsabilidade do Paciente ou do Informante, conforme
        aplicável.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        13.3. A Plataforma Wida, bem como todos os materiais, informações,
        produtos e serviços nela incluídos, são fornecidos “COMO ESTÃO” e
        “CONFORME DISPONÍVEIS” sem garantias de qualquer tipo ou de resultado.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        13.4. O Wida e suas afiliadas isentam-se de quaisquer garantias
        relativamente a segurança, precisão, confiabilidade e desempenho dos
        serviços ou que os serviços estarão livres de erros ou que quaisquer
        erros serão corrigidos.&nbsp;
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        <strong>14. Informações Proprietárias do Wida</strong>
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        14.1. O Paciente e o Informante, conforme aplicável, reconhecem que
        terão acesso a textos, informações e materiais que são de propriedade
        <strong>exclusiva</strong> do Wida, suas subsidiárias ou afiliadas e/ou
        de terceiros e em posse ou licença para o Wida, e que constituem
        recursos valiosos, informações confidenciais e/ou proprietárias,{' '}
        <i>know-how</i> e segredos comerciais pertencentes ao Wida, suas
        subsidiárias ou afiliadas e/ou terceiros (doravante denominados "
        <strong>Informações Proprietárias</strong>"). Você concorda em manter as
        Informações Proprietárias em estrita confidencialidade e não as copiar,
        divulgar ou disponibilizar a qualquer pessoa ou terceiro sem o
        consentimento prévio por escrito do Wida. Você concorda que todas as
        Informações Proprietárias devem ser usadas apenas com a finalidade
        prevista nestes Termos de Uso e
        <strong>NÃO DEVEM SER PRODUZIDAS, COPIADAS, NO TODO OU EM PARTE</strong>
        . Você deve se desfazer de todas as Informações Proprietárias,
        juntamente com quaisquer cópias, reproduções ou outros registros das
        Informações Proprietárias de qualquer forma, quando você deixar de usar
        os serviços e a Plataforma Wida.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        <strong>15. Direito de propriedade intelectual</strong>
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        15.1. O Wida e seus provedores de serviços terceirizados retêm todos os
        direitos, títulos e interesses na Plataforma Wida e todo o conteúdo que
        você vê ou experimenta na Plataforma Wida (“<strong>Conteúdo</strong>”),
        incluindo todos os direitos autorais, patentes, segredos comerciais,
        marcas e outros direitos de propriedade intelectual. Reservamo-nos todos
        os direitos não concedidos expressamente nestes Termos de Uso. Estes
        Termos de Uso não concedem ou implicam quaisquer direitos a qualquer um
        de nossos serviços ou Conteúdo, incluindo nossas marcas registradas,
        nomes comerciais, informações do site, <i>know-how</i>, conteúdo do
        questionário, conteúdo da Plataforma Wida e designs de logotipo, ou
        aqueles de nossos provedores de serviços terceirizados.
        <strong>Você não pode usar nenhum de nossos Conteúdos</strong>,
        incluindo nomes, marcas registradas, designs de logotipo ou direitos
        autorais, perguntas ou conteúdo dos questionários, conteúdos da
        Plataforma Wida, de qualquer maneira sem nosso consentimento prévio por
        escrito.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        <strong>16. Sua licença para usar os serviços</strong>
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        16.1. Ao usar a Plataforma Wida e nossos serviços, você irá acessar
        direitos de propriedade intelectual que nós ou nossos licenciadores
        possuímos ou licenciamos.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        16.2. Você não pode usar, copiar, adaptar, modificar, preparar trabalhos
        derivados com base, distribuir, licenciar, vender, transferir, exibir
        publicamente, executar publicamente, transmitir, difundir ou explorar os
        serviços ou Conteúdo, sem o consentimento prévio por escrito do Wida.
        Nenhuma licença ou direito é concedido a você por implicação ou de outra
        forma sob quaisquer direitos de propriedade intelectual pertencentes ou
        controlados pelo Wida ou seus licenciadores.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        <strong>17. Indenização</strong>
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        17.1. Você concorda em indenizar e isentar o Wida, suas afiliadas,
        membros, diretores, executivos, funcionários e agentes de e contra todas
        e quaisquer perdas, despesas, danos, reclamações, multas, penalidades,
        custos e responsabilidades, resultantes de seu (ou de qualquer pessoa
        agindo sob sua Conta) uso indevido da Plataforma Wida, ou de qualquer
        violação&nbsp;destes Termos de Uso por você.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        17.2. Você reconhece que
        <strong>WIDA NÃO É UM PRESTADOR DE SERVIÇOS DE SAÚDE</strong>. O Wida
        não pode e não assumirá responsabilidade ou controle sobre o cuidado de
        qualquer Paciente, que será, em todos os momentos, de responsabilidade
        do Profissional de Saúde. O Wida não se responsabiliza por qualquer
        atividade que envolva o exercício da medicina, da psicologia ou de
        qualquer&nbsp;prestação de serviços na área de saúde ou serviços sociais
        ou de terapia. Para maior clareza,
        <strong>
          o Wida não fornece nenhum diagnóstico ou tratamento, aconselhamento ou
          opinião aos Pacientes, sendo esta responsabilidade exclusiva do
          Profissional da Saúde. Igualmente, o Wida não oferece nenhuma
          recomendação, orientação, sugestão ou indicação de diagnóstico ou
          tratamento aos Profissionais de Saúde.
        </strong>
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        17.3. Em nenhum caso o Wida, seus representantes legais, diretores,
        funcionários, parceiros, agentes, fornecedores, ou licenciados, serão
        responsabilizados por quaisquer danos indiretos, punitivos, incluindo,
        sem limitação, lucros cessantes, perda de receitas, perda de uma chance,
        dano moral ou outras perdas monetárias e intangíveis, relacionadas a
        qualquer reclamação, ação judicial ou outro procedimento relacionado à
        utilização do serviço, conteúdo, funcionalidades ou ferramentas da
        Plataforma Wida.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        17.4. O Wida não terá qualquer responsabilidade por qualquer destes
        danos: (i) danos e prejuízos que o usuário possa experimentar pela
        indisponibilidade temporária ou funcionamento parcial da Plataforma
        Wida; (ii) danos e prejuízos que os Pacientes ou Informantes possam
        experimentar em razão das informações inseridas pelo Paciente, pelo
        Informante ou Profissional da Saúde na Plataforma Wida; (iii)
        <strong>
          danos e prejuízos que o usuário ou terceiros possam experimentar em
          decorrência da aplicação de qualquer conduta sugerida pelo
          Profissional da Saúde pela análise do caso clínico
        </strong>
        ; (iv) danos e prejuízos que o usuário ou terceiros possam experimentar
        em decorrência do uso da plataforma em desconformidade com estes Termos
        de Uso ou com a política de privacidade; ou incapacidade de acessar ou
        usar o serviço; (v) danos e prejuízos que o Paciente, Informante ou
        Profissional da Saúde ou terceiros possam experimentar em decorrência do
        uso da Plataforma Wida em desconformidade com a lei, os costumes ou a
        ordem pública; (vi) danos e prejuízos que o usuário ou terceiros possam
        experimentar em decorrência de falhas no aplicativo, inclusive
        decorrentes de falhas no sistema, no servidor ou na conexão de rede, ou
        ainda de interações maliciosas como vírus que possam danificar o
        equipamento ou acessar informações do equipamento do usuário; ou (vii)
        qualquer conteúdo ou conduta de qualquer terceiro na Plataforma
        Wida.&nbsp;
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        <strong>18. Erros e inconsistências</strong>
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        18.1 A Plataforma Wida pode conter erros tipográficos ou outros erros ou
        imprecisões, e pode não estar completa ou atual. Wida reserva-se o
        direito de corrigir quaisquer erros, imprecisões ou omissões e alterar
        ou atualizar informações a qualquer momento sem aviso prévio. O Wida, no
        entanto, não garante que tais erros, imprecisões ou omissões sejam
        corrigidos.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        <strong>19. Mudanças e interrupções no serviço</strong>
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        19.1. O Wida reserva-se o direito de, a qualquer momento e de tempos em
        tempos, modificar ou descontinuar, temporária ou permanentemente, a
        Plataforma Wida (ou qualquer parte dela) com ou sem aviso prévio. Além
        disso, o Wida se reserva o direito de alterar estes Termos de Uso a
        qualquer momento e notificá-lo publicando uma versão atualizada dos
        Termos de Uso. Nós iremos lhe manter informado se quaisquer dessas
        circunstâncias ocorrerem.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        19.2. Seu único direito em relação a qualquer insatisfação com (1) estes
        Termos de Uso, (2) qualquer política ou prática nossa na operação da
        Plataforma Wida ou (3) qualquer Conteúdo disponível através da
        Plataforma Wida é parar de utilizar a Plataforma Wida.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        19.3. Podemos, de tempos em tempos, realizar manutenção na Plataforma
        Wida ou enfrentar problemas de <i>hardware</i>, <i>software</i>
        ou outros relacionados à Plataforma Wida, resultando em interrupção do
        serviço, atrasos ou erros nos serviços. Tentaremos fornecer aviso prévio
        de tais interrupções, atrasos ou erros, mas não podemos garantir que tal
        aviso seja fornecido.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        <strong>20. Término e Extinção</strong>
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        20.1. Você pode encerrar sua Conta a qualquer momento e por qualquer
        motivo, mediante envio de aviso prévio por e-mail ao seguinte endereço
        eletrônico: info@wida.app. Após qualquer rescisão por você, sua Conta
        não estará mais acessível.&nbsp;
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        20.2. Podemos suspender ou rescindir seu direito de usar nossos serviços
        ou acessar a Plataforma Wida a qualquer momento. Após tal rescisão, o
        Wida não terá mais nenhuma obrigação de fornecer os serviços.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        20.3. Os Pacientes poderão solicitar ao seu Profissional de Saúde acesso
        aos registros de saúde e prontuário, nos limites da legislação
        aplicável. Nesse caso, o pedido de acesso de informações pelo Paciente
        deverá ser realizado diretamente ao seu Profissional de Saúde, a quem
        compete a guarda e o armazenamento dessas informações de acordo com a
        legislação aplicável.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        20.4. Após a rescisão do seu direito de usar nossos serviços ou a
        Plataforma Wida ou nossa rescisão da Plataforma Wida, todas as licenças
        e outros direitos concedidos a você por estes Termos de Uso serão
        imediatamente extintos.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        <strong>21. Cessão</strong>
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        21.1. Estes Termos de Usuário são pessoais e não podem ser cedidos por
        você a qualquer outra parte.&nbsp;
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        21.2. Reservamo-nos o direito de ceder as obrigações do Wida previstas
        nestes Termos de Uso, a nosso exclusivo critério e sem o seu
        consentimento, para empresas afiliadas e do mesmo grupo econômico do
        Wida, bem como podemos atribuir ou delegar alguns de nossos direitos e
        responsabilidades sob estes Termos de Uso a subcontratados.
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        <strong>22. Legislação aplicável</strong>
      </p>

      <p style={{ textAlign: 'justify' }} role='presentation'>
        22.1. Estes Termos de Uso serão regidos pelas leis da República
        Federativa do Brasil e o foro para discussão de quaisquer demandas
        originadas deste documento será o foro central da comarca de Porto
        Alegre - RS, em prejuízo de qualquer outro, por mais privilegiados que o
        sejam.
      </p>
    </>
  )
}

export default TermsOfUse
