import { addHours, format } from 'date-fns'
import brLocale from 'date-fns/locale/pt-BR'

export function formatDateToLabel(date: string) {
  const isNotDatebleFormat = /(\d{2})\/(\d{2})\/(\d{4})/.test(date)

  const datableDate = isNotDatebleFormat
    ? date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1')
    : date

  return format(new Date(datableDate + ' 12:00:00.000'), 'dd MMM yy', {
    locale: brLocale,
  })
}

export function formatDbDateToLabel(date: string) {
  if (!date) return ''

  const formatedDateLabel = format(new Date(date), 'dd MMM yy', {
    locale: brLocale,
  })

  return formatedDateLabel.replace(/([a-z])/, (c) => c.toUpperCase())
}

export const orderFormattedDates = (dateArray: any) => {
  return dateArray.slice().sort((stringDateA: any, stringDateB: any) => {
    const dateA: any = new Date(
      stringDateA.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1') +
        ' 12:00:00.000'
    )
    const dateB: any = new Date(
      stringDateB.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1') +
        ' 12:00:00.000'
    )

    return dateA - dateB
  })
}

export const sortPatientAttentionsByDates = (a: any, b: any, order = 'asc') => {
  const date1 = new Date(a.responseDate)
  const date2 = new Date(b.responseDate)
  if (order === 'asc') return date1.getTime() - date2.getTime()
  return date2.getTime() - date1.getTime()
}

export const toDate = (dateStr: string) => {
  const d = dateStr.split('/')
  const formattedDate = new Date(`${d[1]}/${d[0]}/${d[2]}`)
  return new Date(formattedDate)
}

export const fromDate = (dateStr: string) => {
  return dateStr.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1')
}

export const sortDates = (a: string, b: string, order = 'asc') => {
  const date1 = new Date(a)
  const date2 = new Date(b)
  if (order === 'asc') return date1.getTime() - date2.getTime()
  return date2.getTime() - date1.getTime()
}

export const timeSince = (date: Date) => {
  var seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000)
  var interval = seconds / 31536000
  if (interval > 1) {
    return `Há ${Math.floor(interval)} ${
      Math.floor(interval) === 1 ? 'ano' : 'anos'
    }`
  }
  interval = seconds / 2592000
  if (interval > 1) {
    return `Há ${Math.floor(interval)} ${
      Math.floor(interval) === 1 ? 'mês' : 'meses'
    }`
  }
  interval = seconds / 86400
  if (interval > 1) {
    return `Há ${Math.floor(interval)} ${
      Math.floor(interval) === 1 ? 'dia' : 'dias'
    }`
  }
  interval = seconds / 3600
  if (interval > 1) {
    return `Há ${Math.floor(interval)} ${
      Math.floor(interval) === 1 ? 'hora' : 'horas'
    }`
  }
  interval = seconds / 60
  if (interval > 1) {
    return `Há ${Math.floor(interval)} ${
      Math.floor(interval) === 1 ? 'minuto' : 'minutos'
    }`
  }
  return 'Agora'
}

export const parseDateToUTC = (date: Date) => {
  const offset = new Date().getTimezoneOffset() / 60
  const currentTimeZone = offset * -1

  const utcDate = addHours(date, currentTimeZone)

  return utcDate
}
