import { styled, Typography } from '@mui/material'

export const TextStyled = styled(Typography)({
  fontFamily: 'QuickSand',
  fontSize: '16px',
  fontWeight: '500',
  lineHeight: '32px',
  color: '#596B7B',
})

export const TitleStyled = styled(Typography)({
  fontFamily: 'QuickSand',
  fontSize: '18px',
  fontWeight: '700',
  lineHeight: '24px',
  color: '#8D92BE',
})

export const CancelTextStyled = styled(Typography)({
  fontFamily: 'QuickSand',
  fontSize: '16px',
  fontWeight: '500',
  lineHeight: '28px',
  color: '#596B7B',
})
