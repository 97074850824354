import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const ClinicContainer = styled(Box)({
  minHeight: '100vh',
  backgroundColor: '#BDC1DD',
  minWidth: '995px',
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  flexGrow: 1,
})

export const ContentContainer = styled(Box)({
  boxShadow: '0px 6px 34px rgba(115, 122, 182, 0.8)',
  borderRadius: '10px 10px 0px 0px',
  background: '#FFFFFF',
  flexGrow: 1,
  minWidth: '945px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginTop: '24px',
})
