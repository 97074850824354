import { useEffect } from 'react'
import { Box, Stack } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { TextFieldClinic, ButtonClinic } from 'components'
import { useSignUpClinicContext } from 'contexts'
import { registerPageAccess } from 'helpers'
import { Title } from './sign-up-clinic-steps-styles'

function PersonalInfoStep(): JSX.Element {
  const { control } = useFormContext()
  const { goBackStep, goNextStep, loading } = useSignUpClinicContext()

  useEffect(() => {
    registerPageAccess('Cadastro clínico: segunda página')
  }, [])

  return (
    <Box
      flexGrow={1}
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
    >
      <Stack width='100%' spacing={{ xs: 3, sm: 4}}>
        <Title>Dados pessoais</Title>

        <Box>
          <TextFieldClinic name='firstName' control={control} label='Nome' />
        </Box>

        <Box>
          <TextFieldClinic
            name='lastName'
            control={control}
            label='Sobrenome'
          />
        </Box>
      </Stack>
      <Stack
        width='100%'
        direction='row'
        spacing={{ xs: 1.5, sm: 6}}
        justifyContent={{ xs: 'center', sm: 'center', md: 'center', lg: 'space-between'}}
        alignItems='center'
        mt={4}
      >
        <ButtonClinic
          width={150}
          variant='outlined'
          size='small'
          color='purple'
          fontWeight='bold'
          type='button'
          onClick={goBackStep}
          disabled={loading}
        >
          Voltar
        </ButtonClinic>

        <ButtonClinic
          width={150}
          variant='contained'
          size='small'
          color='purple'
          fontWeight='bold'
          type='button'
          onClick={goNextStep}
          loading={loading}
        >
          Próximo
        </ButtonClinic>
      </Stack>
    </Box>
  )
}

export default PersonalInfoStep
