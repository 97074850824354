import { Box, Typography } from '@mui/material'
import DialogMui from '@mui/material/Dialog'
import { styled } from '@mui/material/styles'

export const Dialog = styled(DialogMui)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '16px',
  },
  '& .MuiDialogTitle-root': {
    marginTop: theme.spacing(4),
  },
  '& .MuiDialogActions-root': {
    paddingBottom: '20px',
    display: 'flex',
    justifyContent: 'center',
  },
}))

export const InformationTitle = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '16px',
  lineHeight: '144%',
  color: '#657787',
  textAlign: 'center',
})

export const InformationText = styled(Typography)({
  fontWeight: 'normal',
  fontSize: '14px',
  lineHeight: '144%',
  color: '#657787',
  textAlign: 'center',
})

export const CancelButton = styled(Box)({
  display: 'flex',
  cursor: 'pointer',
  width: '50%',
  textDecoration: 'underline',
  fontSize: '14px',
  opacity: 0.5,
  transition: '0.6s',
  '&:hover': {
    opacity: 1,
  },
})
