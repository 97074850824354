import { Box, Typography } from '@mui/material'
import noInvitesImage from 'images/no-invites.svg'

function InviteListEmpty() {
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      flexGrow={1}
    >
      <img src={noInvitesImage} height='57px' alt='Lista de pacientes vazia' />
      <Typography
        variant='title'
        color='#949494'
        lineHeight='36.5px'
        fontFamily='Quicksand'
        fontWeight={500}
        fontSize={20}
        mt={4}
        mb={1}
      >
        Que bom, não há convites pendentes!
      </Typography>
      <Typography
        variant='text'
        textAlign='center'
        color='#949494'
        fontFamily='Zen Kaku Gothic Antique'
        fontSize={18}
        fontWeight={400}
        lineHeight='24.5px'
      >
        Aqui você verá os pacientes que ainda não se cadastraram na plataforma
        após <br /> você ter enviado o convite para responder a um primeiro
        questionário.
      </Typography>
    </Box>
  )
}

export default InviteListEmpty
