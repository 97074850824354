import { useMemo } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Box, Stack, Tooltip } from '@mui/material'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { ButtonClinic } from 'components'
import { ResponderOption } from 'components/create-patient-report'
import { useCreatePatientReportContext } from 'contexts'
import {
  AddNewResponderButton,
  Title,
  Subtitle,
  Label,
} from '../create-patient-report-styles'

function SelectedRespondersStep(): JSX.Element {
  const navigate = useNavigate()
  const { control, watch, getValues } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'responders',
  })

  const {
    respondersWithResponses,
    setSelectedFormRequests,
    setActiveStep,
    patient,
  } = useCreatePatientReportContext()

  const respondersField = watch('responders')

  const responderOptions = useMemo(() => {
    return respondersWithResponses.map((responder: any) => ({
      value: responder.id,
      label: `${responder.name} (${responder.relation})`,
    }))
  }, [respondersWithResponses])

  const handleForward = () => {
    const { responders } = getValues()

    const selectedFormRequests = [
      ...new Set(
        responders.reduce(
          (acc: any, { formRequest }: any) =>
            formRequest ? [...acc, formRequest] : acc,
          []
        )
      ),
    ]

    setSelectedFormRequests(selectedFormRequests)
    setActiveStep((step: number) => step + 1)
  }

  return (
    <>
      <Stack alignItems='center'>
        <Box mt={4}>
          <Title>
            Vamos gerar um Relatório Wida sobre {patient.firstName}?
          </Title>
        </Box>
        <Box mt={2} maxWidth={678} mb={4}>
          <Subtitle>
            Utilize os campos abaixo para incluir até 4 conjuntos de respostas
            no relatório. Você pode escolher diferentes respondedores ou
            comparar um mesmo respondedor em diferentes datas.
          </Subtitle>
        </Box>
        {/* Responders */}
        <Stack width='621px'>
          <Stack direction='row' width='100%' spacing={3} mb={1}>
            <Box width='361px'>
              <Label>Respondedor</Label>
            </Box>
            <Box width='236px'>
              <Label>Data</Label>
            </Box>
          </Stack>

          {fields.map((field, index: number) => (
            <ResponderOption
              key={field.id}
              index={index}
              remove={remove}
              options={responderOptions}
            />
          ))}

          <Tooltip
            title={
              respondersField.length >= 4
                ? 'Você já adicionou o máximo de respondedores'
                : ''
            }
            arrow
            placement='top'
          >
            <Stack mt={3}>
              <AddNewResponderButton
                disabled={respondersField.length >= 4}
                onClick={() => append({ responder: '', formRequest: '' })}
              >
                <AddRoundedIcon fontSize='small' sx={{ mx: 1 }} />
                Adicionar respondedor/data
              </AddNewResponderButton>
            </Stack>
          </Tooltip>
        </Stack>
      </Stack>

      <Stack direction='row' width='100%' justifyContent='space-between'>
        <ButtonClinic
          height={34}
          width={150}
          variant='outlined'
          color='purple'
          size='small'
          fontWeight='bold'
          onClick={() => navigate('/patients')}
        >
          Voltar
        </ButtonClinic>
        <ButtonClinic
          height={34}
          width={150}
          variant='contained'
          color='purple'
          size='small'
          fontWeight='bold'
          onClick={handleForward}
          disabled={
            respondersField.length === 0 ||
            !respondersField?.every(({ formRequest }: any) =>
              Boolean(formRequest)
            )
          }
        >
          Continuar
        </ButtonClinic>
      </Stack>
    </>
  )
}

export default SelectedRespondersStep
