import client from 'services/client'

export const getUserGroups = async () => {
  try {
    const { data }: any = await client.get('user-groups')

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}
