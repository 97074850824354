import { useMemo, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { IconButton, Divider, Box, Stack } from '@mui/material'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
// import EventOutlinedIcon from '@mui/icons-material/EventOutlined'
import { useAuthContext } from 'contexts'
import { formatUserName } from 'helpers'
import logoColor from 'images/wida-logo-color.svg'
import ListIcon from 'components/icons/list-icon'
import ClinicNotifications from './clinic-notifications/clinic-notifications'
import ProfileMenu from './profile-menu/profile-menu'
import {
  Container,
  MenuButton,
  BetaLabel,
  UserNameText,
} from './header-clinic-styles'

const menuOptions = [
  // {
  //   generateIcon: (color?: string) => (
  //     <EventOutlinedIcon sx={{ color: color || '#657787' }} />
  //   ),
  //   label: 'Agenda',
  //   route: '/agenda',
  // },
  {
    generateIcon: (color?: string) => <ListIcon color={color || '#657787'} />,
    label: 'Meus pacientes',
    route: '/patients',
  },
  {
    generateIcon: (color?: string) => (
      <PersonAddAlt1Icon sx={{ color: color || '#657787' }} />
    ),

    label: 'Novo paciente',
    route: '/invite_patient',
  },
  // { icon: settingsIcon, label: 'Configurações', route: '/settings' },
]

function HeaderClinic(): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const { user } = useAuthContext()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const userName = useMemo(() => formatUserName(user), [user])

  // TODO imagem vinda do profile do usuário
  // const userAvatarUrl =
  //   'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3MMleJTsvxXrWLqg8XEeMcVgRkFu1okrTaQ&usqp=CAU'

  const goToHome = () => navigate('/')

  return (
    <Container minWidth='945px'>
      <Box display='flex' alignItems='center'>
        <Stack display='flex' mr='16px' mt='12px'>
          <img
            src={logoColor}
            height='18px'
            alt='Logo Wida'
            onClick={goToHome}
            style={{
              cursor: 'pointer',
              marginBottom: '1px',
              marginRight: '9px',
            }}
          />
          <BetaLabel sx={{ alignSelf: 'flex-end' }}>BETA</BetaLabel>
        </Stack>
        <Divider orientation='vertical' sx={{ height: '38px' }} />
        <Stack direction='row' spacing={1} ml='32px'>
          {menuOptions.map(({ generateIcon, label, route }) => {
            return (
              <MenuButton
                key={label}
                startIcon={generateIcon(
                  pathname.includes(route) ? '#F36F60' : '#657787'
                )}
                variant='text'
                onClick={() => navigate(route)}
                selected={pathname.includes(route)}
              >
                {label}
              </MenuButton>
            )
          })}
        </Stack>
      </Box>
      <Box display='flex' alignItems='center'>
        <ClinicNotifications />
        <Divider
          orientation='vertical'
          sx={{ height: '32px', marginRight: '16px', marginLeft: '16px' }}
        />
        <Box mr={2}>
          <UserNameText>{userName}</UserNameText>
        </Box>
        <IconButton onClick={handleClickMenu}>
          {Boolean(anchorEl) ? (
            <CloseRoundedIcon sx={{ fill: '#BDBDBD' }} />
          ) : (
            <MoreVertIcon sx={{ fill: '#BDBDBD' }} />
          )}
        </IconButton>
        <ProfileMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        />
      </Box>
    </Container>
  )
}

export default HeaderClinic
