import { DatePicker } from '@mui/x-date-pickers'
import { styled } from '@mui/material'

export const DatePickerStyled = styled(DatePicker)(() => ({
  border: '1.5px solid #F0F0F0',
  borderRadius: '25px',

  input: {
    borderRadius: '25px',
    padding: '0 16px',
    height: '48px',

    background: '#FDFDFD',

    color: '#596B7B',
    fontFamily: 'Quicksand',
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: '31px',
  },

  fieldset: {
    border: 'none',
  },

  '&.Mui-focused, &:hover': {
    border: '1.5px solid #BDC1DD',
    backgroundColor: '#FDFDFD',
  },
}))
