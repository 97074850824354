import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const TitleText = styled(Typography)({
  color: '#949494',
  textAlign: 'center',
  fontFamily: 'Quicksand',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '36px',
})

export const SubtitleText = styled(Typography)({
  color: '#949494',
  textAlign: 'center',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
})
