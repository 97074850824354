import { Control, Controller } from 'react-hook-form'
import SwitchMui, { SwitchProps as SwitchPropsMui } from '@mui/material/Switch'
import { styled } from '@mui/material/styles'

type SwitchProps = SwitchPropsMui & {
  name: string
  control: Control<any>
  disabled?: boolean
}
function SwitchIos({
  control,
  name,
  disabled = false,
}: SwitchProps): JSX.Element {
  return (
    <Controller
      control={control}
      name={name || ''}
      defaultValue={false}
      render={({ field: { onChange, value } }) => (
        <IOSSwitch checked={value} onChange={onChange} disabled={disabled} />
      )}
    />
  )
}

const IOSSwitch = styled((props: SwitchPropsMui) => (
  <SwitchMui
    focusVisibleClassName='.Mui-focusVisible'
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 32,
  height: 16,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 1,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#72C7C1',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#72C7C1',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 14,
    height: 14,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

export default SwitchIos
