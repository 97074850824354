import { useEffect, useState } from 'react'
import { useResultsContext } from 'contexts'
import { styled } from '@mui/material/styles'
import { Box, IconButton } from '@mui/material'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import { Timeline } from 'components/results'
import { generateSankeys } from 'helpers'

import { useFormContext } from 'react-hook-form'

function ResultDetails(): JSX.Element {
  const [mappedResponses, setMappedResponses] = useState([])

  const {
    visibleArea,
    filteredDataset,
    timeline,
    setTimeline,
    timelineVisibleRange,
    setTimelineVisibleRange,
  } = useResultsContext()
  const { watch, setValue } = useFormContext()

  const activeSankey = watch('activeSankey')

  useEffect(() => {
    setMappedResponses((mappedResponses: any) =>
      mappedResponses.map((mappedResponse: any) => ({
        ...mappedResponse,
        active: activeSankey,
      }))
    )
  }, [activeSankey])

  useEffect(() => {
    if (!timeline || timeline.length === 0) return

    const sankeyResponses: any = [...Array(timeline.length - 1).keys()].map(
      (index) => {
        const origin = timeline[index]
        const destination = timeline[index + 1]

        const sankeys = generateSankeys(origin, destination)

        return {
          id: `${origin.formRequestId}:${destination.formRequestId}`,
          origin,
          destination,
          sankeys,
          active: false,
        }
      }
    )

    setMappedResponses(sankeyResponses)
  }, [timeline])

  useEffect(() => {
    if (!filteredDataset) return

    setTimelineVisibleRange(
      filteredDataset.length > visibleArea
        ? [filteredDataset.length - visibleArea, filteredDataset.length]
        : [0, visibleArea]
    )
  }, [filteredDataset, visibleArea, setTimelineVisibleRange])

  useEffect(() => {
    // * defines timeline
    if (!filteredDataset) return

    setTimeline(filteredDataset.slice(...timelineVisibleRange))
    setValue('activeSankey', false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelineVisibleRange, filteredDataset])

  const handleSelectSankey = (timelineResponse: any) => () => {
    const graphicIndex = timeline.findIndex(
      ({ formRequestId }: any) =>
        formRequestId === timelineResponse.formRequestId
    )

    const currentGraphicId = timeline[graphicIndex]?.formRequestId
    const nextGraphicId = timeline[graphicIndex + 1]?.formRequestId

    if (!nextGraphicId) return

    const findMappedResponse: any = mappedResponses.find(
      ({ id }: any) => id === `${currentGraphicId}:${nextGraphicId}`
    )

    const newMappedResponses: any = mappedResponses.map(
      (mappedResponse: any) => {
        if (mappedResponse.id !== findMappedResponse.id) return mappedResponse

        return { ...mappedResponse, active: !mappedResponse.active }
      }
    )

    if (newMappedResponses.every(({ active }: any) => active)) {
      setValue('activeSankey', true)
    }
    if (newMappedResponses.every(({ active }: any) => !active)) {
      setValue('activeSankey', false)
    }

    setMappedResponses(newMappedResponses)
  }

  const handleBackward = () => {
    const [lowerLimit, upperLimit] = timelineVisibleRange

    if (lowerLimit <= 0) return
    setTimelineVisibleRange([lowerLimit - 1, upperLimit - 1])
  }

  const handleForward = () => {
    const [lowerLimit, upperLimit] = timelineVisibleRange

    if (upperLimit >= filteredDataset?.length) return
    setTimelineVisibleRange([lowerLimit + 1, upperLimit + 1])
  }

  return (
    <Container display='flex' alignItems='center'>
      <Box width='24px' mb='36px' mr={2}>
        {timelineVisibleRange[0] !== 0 && (
          <IconButton onClick={handleBackward}>
            <ArrowBackIosRoundedIcon sx={{ fill: '#b7b7b7' }} />
          </IconButton>
        )}
      </Box>

      <Timeline
        timeline={timeline}
        mappedResponses={mappedResponses}
        handleSelectSankey={handleSelectSankey}
      />

      <Box width='24px' mb='36px'>
        {timelineVisibleRange[1] < filteredDataset?.length && (
          <IconButton onClick={handleForward}>
            <ArrowForwardIosRoundedIcon sx={{ fill: '#b7b7b7' }} />
          </IconButton>
        )}
      </Box>
    </Container>
  )
}

const Container = styled(Box)({
  height: '100%',
})

export default ResultDetails
