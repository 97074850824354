import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { format, isValid, isToday } from 'date-fns'
import brLocale from 'date-fns/locale/pt-BR'
import { Box } from '@mui/material'
import { ButtonClinic, InputWithButton } from 'components'
import { useInvitePatientContext, useAuthContext } from 'contexts'
import { registerTrack, registerPageAccess } from 'helpers'
import { updateSendMethod } from 'services'
import { SendMethodEnum } from 'types/invites'
import { DescriptionText } from './send-success-step-styles'
import { Title } from '../styles/common-styles'
import SuccessIcon from 'components/icons/success-icon.svg'

function SendSuccessStep(): JSX.Element {
  const { user } = useAuthContext()

  const [hasCopiedLink, setHasCopiedLink] = useState(false)

  const navigate = useNavigate()
  const { newQuestionnaireInfos, sendQuestionnaireType, patient } =
    useInvitePatientContext()

  const patientName = useMemo(
    () => newQuestionnaireInfos.patientName?.split(' ')[0] || 'o paciente',
    [newQuestionnaireInfos.patientName]
  )

  const isResend = useMemo(
    () => sendQuestionnaireType === 'resend',
    [sendQuestionnaireType]
  )

  const sendMomentText = useMemo(() => {
    if (newQuestionnaireInfos.sendingChannel === 'link') {
      return 'Este link deve ser usado para se cadastrar no Wida e ter acesso ao questionário criado.'
    }

    const dateText =
      isValid(newQuestionnaireInfos?.startDate) &&
      !isToday(newQuestionnaireInfos?.startDate)
        ? format(newQuestionnaireInfos?.startDate, "dd 'de' MMMM 'de' yyyy", {
            locale: brLocale,
          })
        : 'hoje'

    if (isResend) {
      return dateText === 'hoje'
        ? 'Uma mensagem será enviada avisando sobre o novo questionário.'
        : `Uma mensagem do Wida será enviada em ${dateText}, como programado.`
    }

    return dateText === 'hoje'
      ? 'foi enviada agora.'
      : `será enviada em ${dateText}, como programado.`
  }, [
    newQuestionnaireInfos?.startDate,
    newQuestionnaireInfos?.sendingChannel,
    isResend,
  ])

  const invitePatientLink = useMemo(() => {
    return `${process.env.REACT_APP_WIDA_URL as string}/r/${
      newQuestionnaireInfos.invitationCode
    }`
  }, [newQuestionnaireInfos.invitationCode])

  const receiverName = useMemo(() => {
    if (!isResend) return ''
    const { caregiver, firstName: patientFirstName } = patient

    return caregiver
      ? `${
          caregiver.firstName
        } (${caregiver.relation.name.toLowerCase()} de ${patientFirstName})`
      : patientFirstName
  }, [patient, isResend])

  const receiverMethod = useMemo(() => {
    if (!isResend) return ''

    const communicationLabels: any = {
      whatsapp: 'WhatsApp',
      email: 'e-mail',
    }

    return patient?.user?.communication
      ?.map((com: string) => communicationLabels[com] || '')
      ?.join(' e ')
  }, [patient, isResend])

  useEffect(() => {
    if (!isResend) return

    registerPageAccess('Enviar Perguntas', {
      clinic_id: user.clinicId,
      patient_id: patient?.patientId,
    })

    registerTrack('Questionário enviado', {
      channel: patient?.user?.communication?.slice().sort().join(', '),
      type: 'repeat',
      form_id: newQuestionnaireInfos.formId,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCopyLink = async () => {
    try {
      navigator.clipboard.writeText(invitePatientLink)
      registerTrack('Questionário enviado', {
        channel: 'link',
        type: 'first_time',
        form_id: newQuestionnaireInfos?.formId,
      })
      setHasCopiedLink(true)

      await updateSendMethod({
        inviteId: newQuestionnaireInfos?.invite?.id,
        sendMethod: SendMethodEnum.LINK,
      })
    } catch (error: any) {
      console.error(error)
    }
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      flexGrow={1}
      pt={10}
    >
      {newQuestionnaireInfos.sendingChannel === 'link' ? (
        <>
          <Title>O questionário de {patientName} foi criado.</Title>
          <Title>Lembre-se de compartilhar o link!</Title>
        </>
      ) : (
        <Title>Tudo certo! O questionário de {patientName} foi criado.</Title>
      )}
      <Box my={4}>
        <img src={SuccessIcon} alt='Ícone de sucesso' />
      </Box>

      {newQuestionnaireInfos.sendingChannel !== 'link' && (
        <DescriptionText>
          {isResend
            ? `${receiverName} escolheu receber suas notificações por ${receiverMethod}.`
            : 'Uma mensagem do Wida com link para cadastro e acesso ao questionário'}
        </DescriptionText>
      )}

      <DescriptionText>{sendMomentText}</DescriptionText>

      {!isResend && (
        <DescriptionText>
          <br />
          Em caso de dúvidas, escreva para info@wida.app.
        </DescriptionText>
      )}
      {newQuestionnaireInfos.sendingChannel === 'link' && (
        <Box mt={8}>
          <InputWithButton
            displayText={invitePatientLink.replace(/^(https?:\/\/)/, '')}
            buttonLabel={hasCopiedLink ? 'Copiado!' : 'Copiar Link'}
            handleClick={handleCopyLink}
          />
        </Box>
      )}

      <Box mt={8}>
        <ButtonClinic
          variant='outlined'
          size='small'
          fontWeight='bold'
          color='purple'
          onClick={() => navigate('/patients')}
        >
          Voltar para Meus pacientes
        </ButtonClinic>
      </Box>
    </Box>
  )
}

export default SendSuccessStep
