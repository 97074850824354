import { Box, TableHead, TableSortLabel } from '@mui/material'
import {
  StartBoxCell,
  MiddleBoxCell,
  EndBoxCell,
  StyledTableCell,
  HeaderTableRow,
} from './patient-reports-table-header-styles'
import { visuallyHidden } from '@mui/utils'

function PatientReportsTableHeader({
  headerFields,
  orderBy,
  setOrderBy,
  order,
  setOrder,
  component,
}: any): JSX.Element {
  const handleRequestSort = (property: any) => {
    if (orderBy !== property) {
      setOrder('desc')
      setOrderBy(property)
      return
    }
    setOrder(order === 'asc' ? 'desc' : 'asc')
  }

  const mountTableSortLabel = (id: string, label: string) => (
    <TableSortLabel
      active={orderBy === id}
      direction={orderBy === id ? order : 'asc'}
      onClick={() => handleRequestSort(id)}
    >
      {label}
      {orderBy === id ? (
        <Box component='span' sx={visuallyHidden}>
          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
        </Box>
      ) : null}
    </TableSortLabel>
  )

  return (
    <TableHead>
      <HeaderTableRow>
        {headerFields.map((headCell: any, i: number) => {
          if (i === 0) {
            return (
              <StyledTableCell
                key={headCell.id}
                sortDirection={headCell.order ? order : false}
              >
                <StartBoxCell>
                  {headCell.order
                    ? mountTableSortLabel(headCell.id, headCell.label)
                    : headCell.label}
                </StartBoxCell>
              </StyledTableCell>
            )
          } else if (i === headerFields.length - 1) {
            return (
              <StyledTableCell
                key={headCell.id}
                sortDirection={headCell.order ? order : false}
              >
                <EndBoxCell
                  justifyContent={
                    component === 'pendingInviteList' ? 'center' : 'flex-start'
                  }
                >
                  {headCell.order
                    ? mountTableSortLabel(headCell.id, headCell.label)
                    : headCell.label}
                </EndBoxCell>
              </StyledTableCell>
            )
          }
          return (
            <StyledTableCell
              key={headCell.id}
              sortDirection={headCell.order ? order : false}
            >
              <MiddleBoxCell>
                {headCell.order
                  ? mountTableSortLabel(headCell.id, headCell.label)
                  : headCell.label}
              </MiddleBoxCell>
            </StyledTableCell>
          )
        })}
      </HeaderTableRow>
    </TableHead>
  )
}

export default PatientReportsTableHeader
