import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { format } from 'date-fns'
import brLocale from 'date-fns/locale/pt-BR'
import { Box, InputAdornment } from '@mui/material'
import { Search } from '@mui/icons-material'
import { ClinicLayout, PatientCentralLayout, RecordTimeline } from 'components'
import { useAuthContext } from 'contexts'
import { findInWord, registerPageAccess, registerTrack } from 'helpers'
import { useDebounce } from 'hooks'
import {
  closePatientRecord,
  createPatientRecord,
  deletePatientRecord,
  getPatientRecords,
  updatePatientRecord,
} from 'services'
import { Container, FilterTextField } from './patient-records-styles'

function PatientRecords(): JSX.Element {
  const [records, setRecords] = useState([])
  const [draft, setDraft] = useState<any>(null)
  const [filteredRecords, setFilteredRecords] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  const { patient_id: patientId = '' } = useParams()
  const { user } = useAuthContext()
  const debounceSearch = useDebounce(searchTerm, 500)

  const { firstName, lastName } = user.personalInfos

  const fetchData = async () => {
    try {
      const patientRecords = await getPatientRecords(patientId)

      // fill dates to search
      for (const record of patientRecords) {
        const date = new Date(record.updatedAt)
        record.widaDate = `${format(date, 'dd MMM yy', {
          locale: brLocale,
        })} às ${format(date, 'HH:mm')} por ${firstName} ${lastName}`
        record.brDate = format(date, 'dd/MM/yyyy')
      }
      setRecords(patientRecords)
    } catch (error: any) {
      console.error(error.message)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const draftRecord = records.find((record: any) => record.isDraft)
    setDraft(draftRecord)
  }, [records])

  useEffect(() => {
    registerPageAccess('Anotações', { clinic_id: user.clinicId })
  }, [user])

  useEffect(() => {
    const filterRecords = (filter: string = '') => {
      if (filter) {
        return setFilteredRecords(
          records.filter(
            (data: any) =>
              findInWord(JSON.stringify(data.richText), filter) ||
              findInWord(data.updatedAt, filter) ||
              findInWord(data.widaDate, filter) ||
              findInWord(data.brDate, filter) ||
              data.files.some((file: any) => findInWord(file.name, filter))
          )
        )
      }
      return setFilteredRecords(records)
    }

    filterRecords(debounceSearch)
  }, [debounceSearch, records])

  const onSaveRecord = async (body: any, close: boolean = false) => {
    body.patientId = patientId

    if (body.id) {
      // update
      await updatePatientRecord(body, body.id)
      if (close) {
        // finish
        await closePatientRecord(body.id)
        await fetchData()
        registerTrack('Finalizar Registro em Anotações')
      }
    } else {
      // create
      const createdDraft = await createPatientRecord(body)
      setDraft(createdDraft)
    }
  }

  const onDeleteRecord = async (recordId: string) => {
    await deletePatientRecord(recordId)
    await fetchData()
  }

  return (
    <ClinicLayout>
      <PatientCentralLayout>
        <Container>
          <Box pt={1} alignSelf='flex-end'>
            <Box width='290px'>
              <FilterTextField
                onChange={(e) => setSearchTerm(e.target.value)}
                type='text'
                size='small'
                placeholder='Pesquisar'
                startAdornment={
                  <InputAdornment position='start'>
                    <Search style={{ fill: '#BDBDBD' }} />
                  </InputAdornment>
                }
              />
            </Box>
          </Box>
          <Box sx={{ maxHeight: '75vh', overflow: 'scroll' }}>
            <RecordTimeline
              onSaveRecord={onSaveRecord}
              onDeleteRecord={onDeleteRecord}
              records={filteredRecords}
              draft={draft}
              setDraft={setDraft}
            />
          </Box>
        </Container>
      </PatientCentralLayout>
    </ClinicLayout>
  )
}

export default PatientRecords
