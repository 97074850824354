import { styled } from '@mui/material/styles'
import TabsMui from '@mui/material/Tabs'
import TabMui from '@mui/material/Tab'

export const Tabs = styled(TabsMui)`
  button[aria-selected='true'] {
    color: #353f48;
    background-color: #f9f9fb;

    svg {
      color: #f36f60;
    }
  }
  button[aria-selected='false'] {
    color: #657787;
  }
`

export const Tab = styled(TabMui)({
  minHeight: '40px',
  borderRadius: '5px',
  textTransform: 'none',
  fontFamily: 'Quicksand',
})
