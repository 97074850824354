import { useEffect, useMemo } from 'react'
import { Grid } from '@mui/material'
import { TextFieldClinic, SelectStyled } from 'components'
import { useAuthContext, useClinicContext } from 'contexts'
import { updateClinicInfo } from 'services'
import { useNotification } from 'hooks'
import { generateOptions } from 'helpers'
import { professionalCouncils, states } from 'helpers/constants'
import useMyInfoGeneralSectionForm from './my-info-general-section-form'
import { Section } from '../clinic-profile-tabs-styles'

function MyInfoGeneralSection(): JSX.Element {
  const { user } = useAuthContext()
  const { clinic, fetchClinic, isEdit, handleSave, hasUnsavedChanges } =
    useClinicContext()
  const {
    control,
    trigger,
    getValues,
    formState: { isDirty },
  } = useMyInfoGeneralSectionForm({ user, clinic })
  const { errorToast } = useNotification()

  const professionalCouncilsOptions = useMemo(
    () => generateOptions(professionalCouncils),
    []
  )

  useEffect(() => {
    if (isDirty) hasUnsavedChanges.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty])

  const onSubmit = async () => {
    try {
      const params = getValues()

      const body = {
        personalInfo: {
          firstName: params.firstName.trim(),
          lastName: params.lastName.trim(),
        },
        register: params.register.trim(),
        professionalCouncil: params.professionalCouncil,
        professionalCouncilState: params.professionalCouncilState,
      }

      await updateClinicInfo(body)
      fetchClinic()
      return true
    } catch (error: any) {
      errorToast(error.message)
      return false
    }
  }

  useEffect(() => {
    handleSave.current = async () => {
      const validate = await trigger()

      if (!validate) return false

      return onSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit, trigger])

  return (
    <Section>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={3} mb={2}>
          <TextFieldClinic
            disabled={!isEdit}
            name='firstName'
            control={control}
            label='Nome'
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={3} mb={2}>
          <TextFieldClinic
            disabled={!isEdit}
            name='lastName'
            control={control}
            label='Sobrenome'
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={3} mb={2}>
          <TextFieldClinic
            disabled
            mask='000.000.000-00'
            name='cpf'
            control={control}
            label='CPF'
          />
        </Grid>
      </Grid>

      <Grid mt={5} container columnSpacing={2}>
        <Grid item xs={12} sm={12} md={2} mb={2}>
          <SelectStyled
            disabled={!isEdit}
            label='Conselho profissional'
            name='professionalCouncil'
            control={control}
            options={professionalCouncilsOptions}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2} mb={2}>
          <SelectStyled
            disabled={!isEdit}
            label='Estado'
            name='professionalCouncilState'
            control={control}
            options={states}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} mb={2}>
          <TextFieldClinic
            disabled={!isEdit}
            name='register'
            control={control}
            label='Número de inscrição'
          />
        </Grid>
      </Grid>
    </Section>
  )
}

export default MyInfoGeneralSection
