import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Title = styled(Typography)({
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '24px',
  lineHeight: '31px',
  textAlign: 'center',
  color: '#FFFFFF',
})

export const sxButton = {
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '21px',
  color: '#657787',
}
