import { useForm } from 'react-hook-form'
import { signUpCaregiverResolver } from 'resolvers'

export default function useSignUpCaregiverForm() {
  return useForm({
    mode: 'onBlur',
    resolver: signUpCaregiverResolver,
    defaultValues: {
      cpf: '',
      email: '',
      emailValidationCode: '',
      whatsapp: '',
      whatsappValidationCode: '',
      communication: [],
      firstName: '',
      lastName: '',
      pronoun: '',
      birthdate: '',
      caregiverFirstName: '',
      caregiverLastName: '',
      relation: '',
      environment: '',
      password: '',
      confirmPassword: '',
      patientCpf: '',
      patientWithoutCpf: false,
      acceptedShare: false,
      acceptedTermsAndPolicies: false,
    },
  })
}
