import { useForm } from 'react-hook-form'
import { inviteNewInformantResolver } from 'resolvers'

function useInviteNewInformantForm() {
  return useForm({
    mode: 'onBlur',
    resolver: inviteNewInformantResolver,
    defaultValues: {
      name: '',
    },
  })
}

export default useInviteNewInformantForm
