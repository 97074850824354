import { Path, Svg, Text, View } from '@react-pdf/renderer'
import { auxiliaryTagTexts } from './tag-texts'
import { styles } from '../tag-answers-styles'

interface IHeaderTextProps {
  tagName: string
  hasAssociations: boolean
}

function FooterTagText({ tagName, hasAssociations }: IHeaderTextProps) {
  const findTextByName = (tagName: string) => {
    const findTagText = auxiliaryTagTexts.find((obj) => obj.tagName === tagName)
    if (!findTagText) return <></>

    const text = findTagText.footerText()
    return text
  }

  return (
    <>
      <View style={{ paddingBottom: 16 }}>
        <Text
          style={{
            fontSize: 11,
            fontFamily: 'Quicksand',
            fontWeight: 700,
            fontStyle: 'bold',
            color: '#D57164',
          }}
        >
          Critérios diagnósticos
        </Text>
      </View>
      <View style={{ paddingBottom: 16 }}>{findTextByName(tagName)}</View>
      <View>
        <Text style={styles.text}>
          É importante estar atento a critérios adicionais relacionados a
          frequência e duração; sofrimento e/ou prejuízo acarretados; e
          explicações alternativas (uso de substâncias, condição médica ou outro
          transtorno mental).
        </Text>
      </View>
      {hasAssociations && (
        <View style={{ display: 'flex', flexDirection: 'row', paddingTop: 22 }}>
          <Svg width='5' height='26' viewBox='0 0 5 26'>
            <Path
              d='M1 1.25L1 25.25'
              stroke='#C7C8D7'
              stroke-width='1.5'
              stroke-linecap='round'
            />
            <Path
              d='M1 1.25H4'
              stroke='#C7C8D7'
              stroke-width='1.5'
              stroke-linecap='round'
            />
            <Path
              d='M1 25.25H4'
              stroke='#C7C8D7'
              stroke-width='1.5'
              stroke-linecap='round'
            />
          </Svg>
          <Text style={{ ...styles.associationText, marginLeft: 10 }}>
            Fenômenos distintos ou exemplos que no DSM-5-TR aparecem agrupados
            em um único critério diagnóstico estão aqui apresentados sob forma
            de perguntas independentes.
          </Text>
        </View>
      )}
    </>
  )
}

export default FooterTagText
