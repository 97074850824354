import { useEffect, useMemo, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Box, CircularProgress, Stack } from '@mui/material'
import { IDLE, FETCHING } from 'helpers/constants'
import { ButtonClinic, ClinicLayout, ReportStepByTag } from 'components'
import { getPatientReport } from 'services'
import { useNotification } from 'hooks'
import { Container } from './patient-report-styles'
import { FormProvider, useForm } from 'react-hook-form'

function PatientReport(): JSX.Element {
  const [status, setStatus] = useState(FETCHING)
  const [activeStep, setActiveStep] = useState<number>(0)
  const [reportSteps, setReportSteps] = useState<any>([])

  const { report_id, patient_id } = useParams()
  const navigate = useNavigate()
  const { errorToast } = useNotification()
  const methods = useForm()

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 })
  }, [activeStep])

  const isLastStep = useMemo(
    () => activeStep === reportSteps.length - 1,
    [activeStep, reportSteps]
  )

  const handleForward = () => {
    if (isLastStep) {
      navigate(`/patients/${patient_id}/reports`)
      return
    }

    setActiveStep((step: number) => step + 1)
  }

  const handleBackward = () => {
    if (activeStep === 0) {
      navigate(`/patients/${patient_id}/reports`)
      return
    }

    setActiveStep((step: number) => step - 1)
  }

  useEffect(() => {
    const fetchReport = async () => {
      try {
        setStatus(FETCHING)
        if (!report_id) throw new Error('Acesso inválido')

        const report = await getPatientReport(report_id)

        setReportSteps(
          report.tags.map((tag: any) => ({
            id: `report_by_tag:${tag.id}`,
            component: (
              <ReportStepByTag tagId={tag.id} patientReport={report} />
            ),
          }))
        )
      } catch (error) {
        errorToast(
          'Ocorreu um erro ao buscar as informações do relatório do paciente.'
        )
      } finally {
        setStatus(IDLE)
      }
    }

    fetchReport()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report_id])

  return (
    <ClinicLayout>
      <FormProvider {...methods}>
        {status === FETCHING ? (
          <Box
            display='flex'
            flex={1}
            width='100%'
            height='100%'
            alignItems='center'
            justifyContent='center'
          >
            <CircularProgress />
          </Box>
        ) : (
          <Container>
            {reportSteps[activeStep]?.component}
            {reportSteps[activeStep]?.id?.includes('report_by_tag') && (
              <Stack
                direction='row'
                width='100%'
                justifyContent='space-between'
              >
                <ButtonClinic
                  height={34}
                  width={150}
                  variant='outlined'
                  color='purple'
                  size='small'
                  fontWeight='bold'
                  onClick={handleBackward}
                >
                  Voltar
                </ButtonClinic>

                <ButtonClinic
                  height={34}
                  width={150}
                  variant='contained'
                  color='purple'
                  size='small'
                  fontWeight='bold'
                  onClick={handleForward}
                >
                  {isLastStep ? 'Finalizar' : 'Continuar'}
                </ButtonClinic>
              </Stack>
            )}
          </Container>
        )}
      </FormProvider>
    </ClinicLayout>
  )
}

export default PatientReport
