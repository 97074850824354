import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Container = styled(Box)({
  flexGrow: 1,
  padding: '32px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
})
