import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import FormControlMui from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import FormHelperTextMui from '@mui/material/FormHelperText'

interface SelectMuiStyledProps {
  isOpen?: boolean
  hasSelectedItem?: boolean
  hasSpace?: boolean
}

export const SelectMuiStyled = styled(Select)<SelectMuiStyledProps>(
  ({ isOpen, hasSelectedItem, hasSpace }) => ({
    width: '100%',
    height: '48px',
    border: isOpen ? '1.5px solid #BDC1DD' : '1.5px solid #F0F0F0',
    borderRadius: isOpen ? hasSpace ? '25px 25px 0 0' : '0 0 25px 25px' : '25px',
    borderTop: isOpen ? hasSpace ? '1.5px solid #BDC1DD' : 'none' : '1.5px solid #F0F0F0',
    borderBottom: isOpen ? hasSpace ? 'none' : '1.5px solid #BDC1DD' : '1.5px solid #F0F0F0',
    transition: 'ease 400ms',
    padding: '10px 16px',
    background: '#FDFDFD',

    color: hasSelectedItem ? '#596B7B' : '#CDCDCD',
    fontFamily: 'Quicksand',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '31.2px',

    ':before, :after': {
      content: 'none',
    },

    '& .MuiInput-input:focus': {
      backgroundColor: 'transparent',
    },

    // ------------------------
    '&:hover': {
      border: '1.5px solid #BDC1DD',
      backgroundColor: '#FDFDFD',
    },
    '&.Mui-disabled': {
      border: '1.5px solid #F4F4F4',
      backgroundColor: '#F4F4F4',
      color: '#CDCDCD',
    },
    '&.Mui-error': {
      border: '1.5px solid #E74C3C',
      color: '#E74C3C',
      background:
        'linear-gradient(0deg, rgba(231, 76, 60, 0.05) 0%, rgba(231, 76, 60, 0.05) 100%), #FDFDFD',
    },
  })
)

export const FieldContainer = styled(FormControlMui)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

export const LabelText = styled(Typography)({
  alignSelf: 'flex-start',
  color: '#596B7B',
  fontFamily: 'Quicksand',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
})

export const HelperText = styled(FormHelperTextMui)({
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Quicksand',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '14px',
  margin: 0,
  marginTop: '8px',
})
