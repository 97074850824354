export enum SendMethodEnum {
  WHATSAPP = 'whatsapp',
  EMAIL = 'email',
  LINK = 'link',
}

export interface SendMethodDto {
  inviteId: string
  sendMethod: string
}
