import { useNavigate } from 'react-router-dom'
import { Box, Container, Stack } from '@mui/material'
import { Button } from 'components'
import expiredInvitationIcon from 'components/icons/expired-invitation-icon.svg'
import { Title, Subtitle } from './expired-invitation-styles'

function ExpiredInvitation(): JSX.Element {
  const navigate = useNavigate()

  return (
    <Container
      maxWidth='xs'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100vh',
        padding: '40px',
      }}
    >
      <Box />
      <Stack alignItems='center' px={6}>
        <Box display='flex' alignItems='center' height='80px'>
          <img
            src={expiredInvitationIcon}
            height='80px'
            alt='Ícone de convite expirado'
            style={{
              flex: '1',
              display: 'flex',
              justifyContent: 'center',
            }}
          />
        </Box>
        <Box mb={7} mt={2}>
          <Title>Convite expirado</Title>
        </Box>
        <Subtitle>
          Lamentamos, mas este link está expirado. Peça um novo convite para
          quem lhe enviou este.
        </Subtitle>
      </Stack>
      <Stack
        px={5}
        width='100%'
        direction='row'
        mb={7}
        justifyContent='flex-end'
      >
        <Box>
          <Button onClick={() => navigate('/signin/patient')}>
            Ok, entendi.
          </Button>
        </Box>
      </Stack>
    </Container>
  )
}

export default ExpiredInvitation
