import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

export const QuestionText = styled('span')({
  fontFamily: 'Zen Kaku Gothic Antique',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  color: '#353F48',
  cursor: 'pointer',
})

export const QuestionBoxContainer = styled(Stack)({
  backgroundColor: 'white',
  borderTop: '1px solid #F2F3F3',
  paddingTop: '40px',
})

export const DividerLine = styled('div')<any>({
  borderBottom: '1px dashed #DBDBDB',
  width: 'calc(100% - 360px)',
  position: 'relative',
  top: '-28px',
  left: '326px',
})

const questionLineProps: any = {
  highlight: {
    border: '1px solid #DCDCDC',
    boxShadow: '4px 4px 16px rgba(0, 0, 0, 0.08)',
    opacity: 1,
  },
  opaque: {
    border: 'none',
    boxShadow: 'none',
    opacity: 0.5,
  },
  normal: {
    border: 'none',
    boxShadow: 'none',
    opacity: 1,
  },
}

export const QuestionLine = styled(Stack)<any>(({ highlight }) => {
  return {
    alignItems: 'center',
    borderRadius: '32px',
    padding: '8px 16px',
    margin: '0px 16px 8px 0px',
    ...questionLineProps[highlight],
  }
})

export const BookmarkIconStyled = styled(BookmarkIcon)({
  fill: 'grey',
  height: '17px',
  marginRight: '4px',
  cursor: 'pointer',
})

export const BookmarkBorderIconStyled = styled(BookmarkBorderIcon)({
  fill: '#ddd',
  height: '17px',
  marginRight: '4px',
  cursor: 'pointer',
})
