import { WIDTH, HEIGHT } from './question-association-constants'
import { connectionLineDict } from './connection-line'
import PointingLine from './pointing-line'
import { QuestionAssociantion as QuestionAssociantionInterface } from './question-association-types'

function QuestionAssociation({
  type,
}: QuestionAssociantionInterface): JSX.Element {
  return (
    <svg width={WIDTH} height={HEIGHT}>
      <PointingLine />

      {connectionLineDict[type]}
    </svg>
  )
}

export default QuestionAssociation
