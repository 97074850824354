import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { sanitizeString } from 'helpers'
import {
  resolveEmailValidationCode,
  resolveWhatsappValidationCode,
  checkCpfPatientGroup,
  testStrongPassword,
  checkValidBirthdateForUser,
  checkValidBirthdateForDependent,
} from 'helpers/resolvers'
import { checkValidationCode, checkCpfUsed, getRelations } from 'services'

yup.setLocale({
  mixed: {
    required: 'Campo obrigatório',
  },
})

export const signInResolver = yupResolver(
  yup
    .object({
      cpf: yup
        .string()
        .required()
        .min(14, 'CPF inválido')
        .max(14, 'CPF inválido'),
      password: yup.string().required(),
    })
    .required()
)

export const signUpPatientResolver = yupResolver(
  yup.object().shape(
    {
      cpf: yup
        .string()
        .required()
        .min(14, 'CPF inválido')
        .max(14, 'CPF inválido')
        .test(
          'is-valid',
          'Já existe uma conta com este CPF',
          checkCpfPatientGroup
        ),
      email: yup.string().when('whatsapp', {
        is: (whatsapp: any) => !Boolean(whatsapp),
        then: yup
          .string()
          .email('Digite um email válido')
          .required('Pelo menos um canal de comunicação deve ser preenchido'),
      }),
      emailValidationCode: yup
        .string()
        .when(['email', 'whatsappValidationCode'], {
          is: (email: any, whatsappValidationCode: any) =>
            Boolean(email) && !Boolean(whatsappValidationCode),
          then: yup
            .string()
            .required()
            .min(6, 'Código inválido')
            .max(6, 'Código inválido')
            .test('is-valid', 'Código inválido', resolveEmailValidationCode),
        }),
      whatsapp: yup.string().when('email', {
        is: (email: any) => !Boolean(email),
        then: yup
          .string()
          .required('Pelo menos um canal de comunicação deve ser preenchido')
          .min(11, 'Telefone inválido'),
      }),
      whatsappValidationCode: yup.string().when('whatsapp', {
        is: (whatsapp: any) => Boolean(whatsapp),
        then: yup
          .string()
          .required()
          .min(6, 'Código inválido')
          .max(6, 'Código inválido')
          .test('is-valid', 'Código inválido', resolveWhatsappValidationCode),
      }),
      firstName: yup
        .string()
        .required()
        .min(2, 'Primeiro nome deve ter entre 2 e 25 caracteres')
        .max(25, 'Primeiro nome deve ter entre 2 e 25 caracteres'),
      lastName: yup
        .string()
        .required()
        .min(2, 'Sobrenome deve ter entre 2 e 50 caracteres')
        .max(50, 'Sobrenome deve ter entre 2 e 50 caracteres'),
      pronoun: yup.string().required(),
      birthdate: yup
        .string()
        .nullable()
        .required()
        .test(
          'valid_date',
          'Digite uma data válida',
          checkValidBirthdateForUser
        ),
      password: yup
        .string()
        .min(8, 'Sua senha deve ter mais que 8 caracteres')
        .test(
          'strong_password',
          'Sua senha deve ter letra minúscula, maiúscula, símbolo e número',
          testStrongPassword
        )
        .required(),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Senhas devem ser iguais'),
      acceptedShare: yup.bool().oneOf([true], 'Aceite para continuar'),
      acceptedTermsAndPolicies: yup
        .bool()
        .oneOf([true], 'Aceite para continuar'),
    },
    ['email', 'whatsapp'] as any
  )
)

export const signUpCaregiverResolver = yupResolver(
  yup.object().shape(
    {
      cpf: yup
        .string()
        .required()
        .min(14, 'CPF inválido')
        .max(14, 'CPF inválido')
        .test(
          'is-valid',
          'Já existe uma conta com este CPF',
          checkCpfPatientGroup
        ),
      firstName: yup
        .string()
        .required()
        .min(2, 'Primeiro nome deve ter entre 2 e 25 caracteres')
        .max(25, 'Primeiro nome deve ter entre 2 e 25 caracteres'),
      lastName: yup
        .string()
        .required()
        .min(2, 'Sobrenome deve ter entre 2 e 50 caracteres')
        .max(50, 'Sobrenome deve ter entre 2 e 50 caracteres'),
      birthdate: yup
        .string()
        .nullable()
        .required()
        .test(
          'valid_date',
          'Digite uma data válida',
          checkValidBirthdateForDependent
        ),
      email: yup.string().when('whatsapp', {
        is: (whatsapp: any) => !Boolean(whatsapp),
        then: yup
          .string()
          .email('Digite um email válido')
          .required('Pelo menos um canal de comunicação deve ser preenchido'),
      }),
      emailValidationCode: yup
        .string()
        .when(['email', 'whatsappValidationCode'], {
          is: (email: any, whatsappValidationCode: any) =>
            Boolean(email) && !Boolean(whatsappValidationCode),
          then: yup
            .string()
            .required()
            .min(6, 'Código inválido')
            .max(6, 'Código inválido')
            .test('is-valid', 'Código inválido', resolveEmailValidationCode),
        }),
      whatsapp: yup.string().when('email', {
        is: (email: any) => !Boolean(email),
        then: yup
          .string()
          .required('Pelo menos um canal de comunicação deve ser preenchido')
          .min(11, 'Telefone inválido'),
      }),
      whatsappValidationCode: yup.string().when('whatsapp', {
        is: (whatsapp: any) => Boolean(whatsapp),
        then: yup
          .string()
          .required()
          .min(6, 'Código inválido')
          .max(6, 'Código inválido')
          .test('is-valid', 'Código inválido', resolveWhatsappValidationCode),
      }),
      caregiverFirstName: yup.string().required(),
      caregiverLastName: yup.string().required(),
      relation: yup.string().required(),
      environment: yup.string().required(),
      password: yup
        .string()
        .min(8, 'Sua senha deve ter mais que 8 caracteres')
        .test(
          'strong_password',
          'Sua senha deve ter letra minúscula, maiúscula, símbolo e número',
          testStrongPassword
        )
        .required(),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Senhas devem ser iguais'),
      patientCpf: yup.string().when('patientWithoutCpf', {
        is: false,
        then: yup
          .string()
          .required()
          .min(14, 'CPF inválido')
          .max(14, 'CPF inválido'),
      }),
      acceptedShare: yup.bool().oneOf([true], 'Aceite para continuar'),
      acceptedTermsAndPolicies: yup
        .bool()
        .oneOf([true], 'Aceite para continuar'),
    },
    ['email', 'whatsapp'] as any
  )
)

export const signUpInformantResolver = yupResolver(
  yup.object().shape(
    {
      cpf: yup
        .string()
        .required()
        .min(14, 'CPF inválido')
        .max(14, 'CPF inválido')
        .test(
          'is-valid',
          'Já existe uma conta com este CPF',
          checkCpfPatientGroup
        ),
      email: yup.string().when('whatsapp', {
        is: (whatsapp: any) => !Boolean(whatsapp),
        then: yup
          .string()
          .email('Digite um email válido')
          .required('Pelo menos um canal de comunicação deve ser preenchido'),
      }),
      emailValidationCode: yup
        .string()
        .when(['email', 'whatsappValidationCode'], {
          is: (email: any, whatsappValidationCode: any) =>
            Boolean(email) && !Boolean(whatsappValidationCode),
          then: yup
            .string()
            .required()
            .min(6, 'Código inválido')
            .max(6, 'Código inválido')
            .test('is-valid', 'Código inválido', resolveEmailValidationCode),
        }),
      whatsapp: yup.string().when('email', {
        is: (email: any) => !Boolean(email),
        then: yup
          .string()
          .required('Pelo menos um canal de comunicação deve ser preenchido')
          .min(11, 'Telefone inválido'),
      }),
      whatsappValidationCode: yup.string().when('whatsapp', {
        is: (whatsapp: any) => Boolean(whatsapp),
        then: yup
          .string()
          .required()
          .min(6, 'Código inválido')
          .max(6, 'Código inválido')
          .test('is-valid', 'Código inválido', resolveWhatsappValidationCode),
      }),
      firstName: yup
        .string()
        .required()
        .min(2, 'Primeiro nome deve ter entre 2 e 25 caracteres')
        .max(25, 'Primeiro nome deve ter entre 2 e 25 caracteres'),
      lastName: yup
        .string()
        .required()
        .min(2, 'Sobrenome deve ter entre 2 e 50 caracteres')
        .max(50, 'Sobrenome deve ter entre 2 e 50 caracteres'),
      relation: yup.string().required(),
      environment: yup
        .string()
        .test(
          'is-required',
          'Campo obrigatório',
          async (environment: any, testContext: any) => {
            try {
              const relations = await getRelations()
              const findPatientRelation: any = relations.find(
                ({ name }: any) => name === 'Sou eu'
              )

              const isInformantPatient =
                testContext.parent.relation === findPatientRelation.id

              return isInformantPatient || Boolean(environment)
            } catch (error: any) {
              console.error(error?.message)
              return false
            }
          }
        ),
      password: yup
        .string()
        .min(8, 'Sua senha deve ter mais que 8 caracteres')
        .test(
          'strong_password',
          'Sua senha deve ter letra minúscula, maiúscula, símbolo e número',
          testStrongPassword
        )
        .required(),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Senhas devem ser iguais'),
      acceptedShare: yup.bool().oneOf([true], 'Aceite para continuar'),
      acceptedTermsAndPolicies: yup
        .bool()
        .oneOf([true], 'Aceite para continuar'),
    },
    ['email', 'whatsapp'] as any
  )
)

export const signUpClinicResolver = yupResolver(
  yup
    .object({
      email: yup.string().email('Digite um email válido').required(),
      firstName: yup
        .string()
        .required()
        .min(2, 'Primeiro nome deve ter entre 2 e 25 caracteres')
        .max(25, 'Primeiro nome deve ter entre 2 e 25 caracteres'),
      lastName: yup
        .string()
        .required()
        .min(2, 'Sobrenome deve ter entre 2 e 50 caracteres')
        .max(50, 'Sobrenome deve ter entre 2 e 50 caracteres'),
      password: yup
        .string()
        .min(8, 'Sua senha deve ter mais que 8 caracteres')
        .test(
          'strong_password',
          'Sua senha deve ter letra minúscula, maiúscula, símbolo e número',
          testStrongPassword
        )
        .required(),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Senhas devem ser iguais'),
      register: yup.string().required(),
      professionalCouncil: yup.string().required(),
      professionalCouncilState: yup.string().required(),
      whatsapp: yup.string().required().min(11, 'Telefone inválido'),
      cpf: yup
        .string()
        .required()
        .min(14, 'CPF inválido')
        .max(14, 'CPF inválido')
        .test(
          'is-valid',
          'CPF já existente. Se estiver correto, faça Login.',
          async (val: any) => {
            if (val.length !== 14) return false
            try {
              await checkCpfUsed({
                cpf: sanitizeString(val),
                role: 'clinic',
              })
              return true
            } catch (error) {
              return false
            }
          }
        ),
      emailValidationCode: yup
        .string()
        .required()
        .min(6, 'Código inválido')
        .max(6, 'Código inválido')
        .test('is-valid', 'Código inválido', async (val: any, context: any) => {
          if (val.length < 6) return false

          try {
            await checkValidationCode({
              code: val,
              type: 'email',
              contact: context.parent.email.toLowerCase(),
            })
            return true
          } catch (error) {
            return false
          }
        }),
    })
    .required()
)

export const passwordResetResolver = yupResolver(
  yup
    .object({
      cpf: yup
        .string()
        .required()
        .min(14, 'CPF inválido')
        .max(14, 'CPF inválido'),
    })
    .required()
)

export const forgotPasswordResolver = yupResolver(
  yup
    .object({
      password: yup
        .string()
        .min(8, 'Senha deve ter mais que 8 caracteres')
        .test(
          'strong_password',
          'Sua senha deve ter letra minúscula, maiúscula, símbolo e número',
          testStrongPassword
        )
        .required(),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Senhas devem ser iguais'),
    })
    .required()
)
