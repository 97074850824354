import { Stack } from '@mui/material'
import yesIcon from 'images/icons/yes.svg'
import sosoIcon from 'images/icons/soso.svg'
import noIcon from 'images/icons/no.svg'
import naIcon from 'images/icons/na.svg'
import { AnswerDictType, AnswerLabelType } from './answer-label-types'
import { AnswerLegendText } from './answer-label-styles'

const answerDict: AnswerDictType = {
  yes: {
    alt: 'Ícone de resposta sim',
    icon: yesIcon,
    label: 'sintomas presentes',
  },
  soso: {
    alt: 'Ícone de resposta talvez',
    icon: sosoIcon,
    label: 'sintomas indeterminados',
  },
  no: {
    alt: 'Ícone de resposta não',
    icon: noIcon,
    label: 'sintomas ausentes',
  },
  skip: { alt: 'Ícone de sem info', icon: naIcon, label: 'não respondidos' },
}

const AnswerLabel = ({ answer, qtd }: AnswerLabelType) => {
  const { alt, icon, label } = answerDict[answer]

  return (
    <Stack direction={'row'} alignItems='center' spacing='4px'>
      <img src={icon} height='12px' alt={alt} />
      <AnswerLegendText>
        {qtd} {label}
      </AnswerLegendText>
    </Stack>
  )
}

export default AnswerLabel
