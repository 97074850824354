import { ComponentProps, FC } from 'react'
import {
  ThemeProvider,
  RouterProvider,
  DateLocalizationProvider,
  AuthProvider,
} from 'providers'

const combineComponents = (components: FC[]): FC =>
  components.reduce(
    (AccumulatedComponents, CurrentComponent) =>
      ({ children }: ComponentProps<FC>): JSX.Element =>
        (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        ),
    ({ children }) => <>{children}</>
  )

const AppProvider = combineComponents([
  RouterProvider,
  ThemeProvider,
  DateLocalizationProvider,
  AuthProvider,
])

export default AppProvider
