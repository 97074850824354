import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const PatientName = styled(Typography)({
  color: '#657787',
  fontWeight: '500',
  fontSize: '18px',
  lineHeight: '24px',
  letterSpacing: '0.04em',
})

export const PatientAge = styled(Typography)({
  color: '#657787',
  fontWeight: '500',
  fontSize: '12px',
  lineHeight: '18px',
  letterSpacing: '0.04em',
})

export const TagName = styled(Typography)({
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '20px',
  lineHeight: '26px',
  color: '#353F48',
})
