import { useState, useEffect } from 'react'
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  InputAdornment,
  TableSortLabel,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import {
  StartBoxCell,
  MiddleBoxCell,
  EndBoxCell,
  StyledTableCell,
  FilterTextField,
  HeaderTableRow,
} from './filter-table-header-styles'
import { visuallyHidden } from '@mui/utils'
import { useDebounce } from 'hooks'

function FilterTableHeader({
  headerFields,
  onFilter,
  orderBy,
  setOrderBy,
  order,
  setOrder,
  component,
}: any): JSX.Element {
  // debounce search
  const [searchTerm, setSearchTerm] = useState('')
  const debounceSearch = useDebounce(searchTerm, 500)

  useEffect(() => {
    onFilter(debounceSearch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearch])

  const handleRequestSort = (property: any) => {
    if (orderBy !== property) {
      setOrder('desc')
      setOrderBy(property)
      return
    }
    setOrder(order === 'asc' ? 'desc' : 'asc')
  }

  const mountTableSortLabel = (id: string, label: string) => (
    <TableSortLabel
      active={orderBy === id}
      direction={orderBy === id ? order : 'asc'}
      onClick={() => handleRequestSort(id)}
    >
      {label}
      {orderBy === id ? (
        <Box component='span' sx={visuallyHidden}>
          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
        </Box>
      ) : null}
    </TableSortLabel>
  )

  return (
    <TableHead>
      <TableRow>
        <TableCell
          colSpan={6}
          sx={{
            padding: 0,
            border: 'none',
          }}
        >
          <FilterTextField
            onChange={(e) => setSearchTerm(e.target.value)}
            type='text'
            size='small'
            hiddenLabel
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon style={{ fill: '#BDBDBD' }} />
                </InputAdornment>
              ),
              placeholder: 'Pesquise por um paciente',
              style: {
                fontFamily: 'QuickSand',
              },
            }}
          />
        </TableCell>
      </TableRow>
      <HeaderTableRow>
        {headerFields.map((headCell: any, i: number) => {
          if (i === 0) {
            return (
              <StyledTableCell
                key={headCell.id}
                sortDirection={headCell.order ? order : false}
              >
                <StartBoxCell>
                  {headCell.order
                    ? mountTableSortLabel(headCell.id, headCell.label)
                    : headCell.label}
                </StartBoxCell>
              </StyledTableCell>
            )
          } else if (i === headerFields.length - 1) {
            return (
              <StyledTableCell
                key={headCell.id}
                sortDirection={headCell.order ? order : false}
              >
                <EndBoxCell
                  justifyContent={
                    component === 'pendingInviteList' ? 'center' : 'flex-start'
                  }
                >
                  {headCell.order
                    ? mountTableSortLabel(headCell.id, headCell.label)
                    : headCell.label}
                </EndBoxCell>
              </StyledTableCell>
            )
          }
          return (
            <StyledTableCell
              key={headCell.id}
              sortDirection={headCell.order ? order : false}
            >
              <MiddleBoxCell>
                {headCell.order
                  ? mountTableSortLabel(headCell.id, headCell.label)
                  : headCell.label}
              </MiddleBoxCell>
            </StyledTableCell>
          )
        })}
      </HeaderTableRow>
    </TableHead>
  )
}

export default FilterTableHeader
