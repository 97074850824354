import { Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { InputField } from 'components/inputs'
import { Title } from '../styles/general-steps-styles'

function PasswordStep(): JSX.Element {
  const { control } = useFormContext()

  return (
    <>
      <Title>Por favor, crie sua senha.</Title>
      <Box mt={5} mb={5}>
        <InputField
          type='password'
          name='password'
          control={control}
          label='Senha'
        />
      </Box>
      <Box>
        <InputField
          type='password'
          name='confirmPassword'
          control={control}
          label='Confirmar senha'
        />
      </Box>
    </>
  )
}

export default PasswordStep
