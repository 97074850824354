import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

yup.setLocale({
  mixed: {
    required: 'Campo obrigatório',
  },
})

export const newQuestionnairePatientStepResolver = yupResolver(
  yup
    .object({
      patientName: yup.string().required(),
    })
    .required()
)

export const newQuestionnaireQuestionsStepResolver = yupResolver(
  yup
    .object({
      selectedQuestions: yup.array().min(1, 'Selecione pelo menos uma questão'),
    })
    .required()
)

export const newQuestionnaireGroupsAndFrequencyStepResolver = yupResolver(
  yup
    .object({
      frequency: yup.string().required(),
    })
    .required()
)

export const questionnaireScheduleStepResolver = yupResolver(
  yup
    .object({
      frequency: yup.string().required(),
    })
    .required()
)

export const whatsappResolver = yupResolver(
  yup
    .object({
      whatsapp: yup
        .string()
        .required(
          'Número inválido. Verifique o número do telefone ou escolha outro modo de envio.'
        )
        .min(
          15,
          'Número inválido. Verifique o número do telefone ou escolha outro modo de envio.'
        ),
    })
    .required()
)

export const emailResolver = yupResolver(
  yup
    .object({
      email: yup
        .string()
        .required(
          'E-mail inválido. Verifique o endereço ou escolha outro modo de envio.'
        )
        .email(
          'E-mail inválido. Verifique o endereço ou escolha outro modo de envio.'
        ),
    })
    .required()
)
