import { statusDict, MAX_CARACTERES } from './invite-informant-constants'

export function sortByName(a: any, b: any) {
  if (a.name < b.name) return -1
  if (a.name > b.name) return 1
  return 0
}

export function formatInformant(informant: any) {
  let status = statusDict.notSent

  if (informant.lastFormRequest) {
    status = informant.lastFormRequest.isCompleted
      ? statusDict.answered
      : statusDict.waitingForAnswer
  }

  const relation =
    informant.relation?.name === 'Sou eu'
      ? 'paciente'
      : informant.relation?.name.toLowerCase() || ''

  const fullName = `${informant.personalInfo?.firstName || ''} ${
    informant.personalInfo?.lastName || ''
  }`

  return {
    ...informant,
    name: fullName,
    label:
      fullName.length > MAX_CARACTERES
        ? fullName.slice(0, MAX_CARACTERES) + '...'
        : fullName,
    status,
    relation,
    type: 'informant',
  }
}

export function formatInvite(openInvite: any) {
  return {
    ...openInvite,
    name: openInvite.name,
    label:
      openInvite.name.length > MAX_CARACTERES
        ? openInvite.name.slice(0, MAX_CARACTERES) + '...'
        : openInvite.name,
    status: statusDict.notRegistered,
    type: 'invite',
  }
}
