interface QuestionButtonOptionType {
  label: string
}

interface OptionsMapType {
  [key: string]: QuestionButtonOptionType
}

const optionsMap: OptionsMapType = {
  no: {
    label: 'não',
  },
  'so-so': {
    label: 'mais ou menos',
  },
  yes: {
    label: 'sim',
  },
}

export default optionsMap
