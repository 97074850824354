import { differenceInYears, format } from 'date-fns'
import { ptBR } from 'date-fns/locale'

export const getFormatedResponseDate = (date?: Date | null) => {
  if (!date) return ''

  return format(new Date(date), 'dd MMM yy', {
    locale: ptBR,
  }).toLocaleLowerCase()
}

export const parseBirthdateIntoAge = (birthdate?: Date | null) => {
  if (!birthdate) return '-'

  const years = differenceInYears(new Date(), new Date(birthdate))

  if (years === 1) {
    return `${years} ano`
  }

  return `${years} anos`
}
