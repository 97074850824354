import { Box } from '@mui/material'
import Button from 'components/button/button'
import RouteLayout from 'components/route-layout/route-layout'
import { useAuthContext } from 'contexts'
import { isUserPatient, registerPageAccess } from 'helpers'
import { useEffect } from 'react'
import { sxButton, Text } from '../start-questionnaire-styles'

function ThirdComponent({ type, handleGoNext, patientInfo }: any) {
  const { user } = useAuthContext()
  useEffect(() => {
    registerPageAccess('Questionário', {
      user_id: user.userId,
      patient_id: patientInfo.patientId,
      source: isUserPatient(user) ? 'patient' : 'caregiver',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <RouteLayout bg='gradient'>
      <Box
        m={5}
        flexGrow={1}
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        alignItems='center'
      >
        <Box />
        <Box>
          {type === 'patient' ? (
            <>
              <Box my={3}>
                <Text>Considere o momento atual.</Text>
              </Box>
              <Box my={3}>
                <Text>Como você tem se sentido recentemente?</Text>
              </Box>
              <Box my={3}>
                <Text>Vamos lá?</Text>
              </Box>
            </>
          ) : (
            <>
              <Box my={3}>
                <Text>Considere o momento atual.</Text>
              </Box>
              <Box my={3}>
                <Text>
                  Como você tem percebido <b>{patientInfo?.firstName || '_'}</b>{' '}
                  recentemente?
                </Text>
              </Box>
              <Box my={3}>
                <Text>Vamos lá?</Text>
              </Box>
            </>
          )}
        </Box>
        <Box width={223}>
          <Button color='white' onClick={handleGoNext} sx={sxButton}>
            Começar
          </Button>
        </Box>
      </Box>
    </RouteLayout>
  )
}

export default ThirdComponent
