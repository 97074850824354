import { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import { SubmitHandler, FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { LogoLayout, SignInHeader } from 'components'
import { useNotification } from 'hooks'
import { signUpClinicSteps } from 'components/sign-up-clinic-steps'
import { SignUpClinicContext, useAuthContext } from 'contexts'
import { sanitizeString, registerPageAccess } from 'helpers'
import logoColor from 'images/wida-logo-color.svg'
import { ISignUpClinicForm } from './sign-up-clinic-types'
import useFormSignUpClinic from './sign-up-clinic-form'

function SignUpClinic(): JSX.Element {
  const [step, setStep] = useState(0)
  const [loading, setLoading] = useState(false)

  const { signup } = useAuthContext()
  const navigate = useNavigate()
  const { errorToast, successToast } = useNotification()
  const methods = useFormSignUpClinic()

  const {
    handleSubmit,
    trigger,
    formState: { errors },
  } = methods

  useEffect(() => {
    registerPageAccess('Signup clinic')
  }, [])

  useEffect(() => {
    const isLastStep = step === signUpClinicSteps.length - 2

    if (isLastStep) {
      const errorsEntries = Object.entries(errors)

      if (errorsEntries.length === 0) return

      const errorsMessage = errorsEntries.reduce(
        (acc: string, [property, error]: any) => {
          if (property === 'emailValidationCode') {
            return (
              acc +
              'O código de validação de e-mail expirou, por favor solicite um novo.' +
              '\n'
            )
          }

          return acc + error.message + '\n'
        },
        ''
      )

      errorToast(errorsMessage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors])

  const handleKeyDown = (e: any) => {
    if (e.code !== 'Enter' && e.code !== 'NumpadEnter') return
    // * ignora a tecla Enter nos passos do formulário
    e.preventDefault()
  }

  const onSubmit: SubmitHandler<ISignUpClinicForm> = async (params) => {
    try {
      setLoading(true)

      const body = {
        cpf: sanitizeString(params.cpf),
        ddi: '55', // TODO use custom ddi
        whatsapp: sanitizeString(params.whatsapp),
        communication: ['email'],
        password: params.password,
        role: 'clinic',
        firstName: params.firstName.trim(),
        lastName: params.lastName.trim(),
        email: params.email.trim().toLowerCase(),
        clinicAdditionalInfos: {
          register: params.register.trim(),
          professionalCouncil: params.professionalCouncil,
          professionalCouncilState: params.professionalCouncilState,
        },
        emailValidationCode: params.emailValidationCode,
      }

      await signup(body)
      successToast('Cadastro realizado com sucesso')
      setStep((step) => step + 1)
    } catch (error: any) {
      console.error(error)
      errorToast(error.message)
    } finally {
      setLoading(false)
    }
  }

  const goBackStep = () => {
    setLoading(true)

    if (step === 0) navigate('/signin/clinic')

    if (step > 0) {
      setStep((step) => step - 1)
    }

    setLoading(false)
  }

  const goNextStep = async () => {
    setLoading(true)

    const valid = await trigger(signUpClinicSteps[step].fields)

    if (!valid) {
      setLoading(false)
      return
    }

    if (step === 4) {
      await handleSubmit(onSubmit)()
      setLoading(false)
      return
    }

    setLoading(false)
    setStep((step) => step + 1)
  }

  return (
    <LogoLayout textType='signup'>
      <>
        <Box
          display='flex'
          flexDirection='column'
          px={{ xs: 3, sm: 14, lg: 8 }}
          pt={{ xs: step === 0 ? 4.5: 7.5, sm: step === 0 ? 11: 14, lg: step === 0 ? 4.5 : 7.5 }}
          pb={{ xs: 4, sm: 11, lg: 8 }}
          height='100%'
        >
          <Box
            alignSelf='flex-end'
            sx={{ display: step === 0 ? 'block' : 'none' }}
          >
            <SignInHeader />
          </Box>

          <Box
            width='100%'
            mt={{ xs: 5, sm: 10, lg: 5 }}
            mb={{ xs: 6, sm: 14, lg: 6 }}
            display='flex'
            justifyContent='center'
          >
            <img src={logoColor} alt='Wida Logo' height={38} />
          </Box>
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            flexGrow={1}
            width='100%'
            component='form'
            onKeyDown={handleKeyDown}
            onSubmit={(e: any) => {
              e.preventDefault()
              goNextStep()
            }}
          >
            <SignUpClinicContext.Provider
              value={{ goNextStep, goBackStep, loading }}
            >
              <FormProvider {...methods}>
                {signUpClinicSteps[step].component}
              </FormProvider>
            </SignUpClinicContext.Provider>
          </Box>
        </Box>
      </>
    </LogoLayout>
  )
}

export default SignUpClinic
