import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  view: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 54,
    backgroundColor: '#F3F3F3',
    paddingHorizontal: 24,
    paddingVertical: 12,
  },
  headerTitle: {
    color: '#596B7B',
    fontSize: 10,
    fontWeight: 700,
    fontStyle: 'bold',
    lineHeight: 1.5,
  },
  headerText: {
    color: '#596B7B',
    fontSize: 10,
    lineHeight: 1.5,
    fontFamily: 'Zen Kaku Gothic Antique',
  },
})
