import { styled } from '@mui/material/styles'

interface StepIconRootProps {
  ownerState: { active?: boolean; totalSteps?: number; stepWidth?: number }
}

export const StepIconRoot = styled('div')<StepIconRootProps>(
  ({ ownerState }) => {
    const width = ownerState.stepWidth || 4

    return {
      padding: 0,
      color: 'rgba(255, 255, 255, 0.5)',
      display: 'flex',
      height: 22,
      alignItems: 'center',
      ...(ownerState.active && {
        color: '#ffffff',
      }),
      '& .StepIcon-completed': {
        width: `${width}px`,
        height: width < 4 ? `${width}px` : 4,
        borderRadius: '9px',
        backgroundColor: '#ffffff',
        padding: 0,
      },
      '& .StepIcon-circle': {
        width: `${width}px`,
        height: width < 4 ? `${width}px` : 4,
        borderRadius: '9px',
        backgroundColor: 'currentColor',
        padding: 0,
      },
    }
  }
)
