import { useState, MouseEvent } from 'react'
import { Control, Controller } from 'react-hook-form'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import InputLabel from '@mui/material/InputLabel'
import {
  Input,
  HelperText,
  FieldContainer,
} from './question-password-field-styles'

interface QuestionPasswordFieldProps {
  name: string
  control: Control<any>
  label?: string
  disabled?: boolean
  placeholder?: string
}

function QuestionPasswordField({
  name,
  control,
  label = '',
  disabled,
  placeholder = 'Digite aqui',
}: QuestionPasswordFieldProps) {
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { invalid, error } }) => (
        <FieldContainer
          disabled={disabled}
          error={invalid}
          variant='standard'
          fullWidth
        >
          <InputLabel>{label}</InputLabel>
          <Input
            {...field}
            type={showPassword ? 'text' : 'password'}
            placeholder={placeholder}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          {invalid && (
            <HelperText>{error?.message || 'Erro desconhecido'}</HelperText>
          )}
        </FieldContainer>
      )}
    />
  )
}

export default QuestionPasswordField
