import { useEffect, useState } from 'react'
import { format } from 'date-fns'
import brLocale from 'date-fns/locale/pt-BR'
import { useNavigate } from 'react-router-dom'
import { Stack, Box, IconButton, CircularProgress } from '@mui/material'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined'
import DoneRoundedIcon from '@mui/icons-material/DoneRounded'
import { Button, RouteLayout } from 'components'
import { getUserQuestionnaire } from 'services'
import { useNotification } from 'hooks'
import { useAuthContext } from 'contexts'
import {
  isUserInformant,
  getUserRole,
  registerPageAccess,
  isUserPatient,
} from 'helpers'
import { generateInitial } from 'helpers/formatters'
import {
  WelcomeText,
  AvatarStyled,
  InviteContainer,
  InviteText,
  ExternalContainer,
  InternalContainer,
  InternalText,
  ScheduleText,
} from './patient-home-styles'

function PatientHome(): JSX.Element {
  const [hasFormToAnswer, setHasFormToAnswer] = useState<boolean>(false)
  const [formToAnswer, setFormToAnswer] = useState<any>()
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate()
  const { warnToast, errorToast } = useNotification()
  const { userInfos } = useAuthContext()

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const form = await getUserQuestionnaire()

        setFormToAnswer(form)
        setHasFormToAnswer(form?.hasFormRequestToAnswer)
      } catch {
        errorToast(
          'Ocorreu um erro ao buscar o questionário, por favor recarregue a página.'
        )
        setHasFormToAnswer(false)
      } finally {
        setLoading(false)
      }
    }

    if (userInfos?.user && getUserRole(userInfos?.user)) {
      registerPageAccess(`Home ${getUserRole(userInfos?.user)}`) // TODO multiroles
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfos?.user])

  const handleAnswerQuestionnaire = async () => {
    try {
      setLoading(true)
      const form = await getUserQuestionnaire()

      if (!form) throw new Error('Ocorreu um erro ao buscar o questionário')

      if (!form?.hasFormRequestToAnswer) {
        return warnToast('Você já respondeu o último questionário')
      }

      navigate('/questionnaire', { state: { form } })
    } catch (error: any) {
      errorToast(error?.message || 'Erro desconhecido')
    } finally {
      setLoading(false)
    }
  }

  const handleInformantInvite = async () => {
    try {
      setLoading(true)
      const form = await getUserQuestionnaire()

      if (!form) throw new Error('Ocorreu um erro ao buscar o questionário')

      if (!form?.lastAvailableFormId) {
        warnToast(
          'Você ainda não tem um questionário válido para convidar outras pessoas'
        )
        return
      }

      registerPageAccess('Convidar Informantes', {
        user_id: userInfos?.user?.userId,
        source: isUserPatient(userInfos?.user) ? 'patient' : 'caregiver',
        answered: !form?.hasFormRequestToAnswer,
      })

      navigate(`/invite_informant?form_id=${form?.lastAvailableFormId}`)
    } catch (error: any) {
      warnToast(error?.message || 'Erro desconhecido')
    } finally {
      setLoading(false)
    }
  }

  return (
    <RouteLayout bg='gradient'>
      {/* NEW HEADER */}
      <Box
        pt={5}
        px={3}
        pb={16}
        display='flex'
        flexDirection='column'
        alignItems='center'
      >
        <IconButton
          onClick={() => navigate('/settings')}
          sx={{ marginLeft: 'auto' }}
        >
          <SettingsOutlinedIcon sx={{ fill: 'white' }} />
        </IconButton>
        <Box mt={6} mb={2}>
          <WelcomeText>
            Olá, {userInfos?.personalInfos?.firstName || ''}!
          </WelcomeText>
        </Box>
        <AvatarStyled>
          {generateInitial(
            userInfos.personalInfos.firstName,
            userInfos.personalInfos.lastName
          )}
        </AvatarStyled>
      </Box>

      <ExternalContainer>
        {!isUserInformant(userInfos?.user) && (
          <InviteContainer onClick={handleInformantInvite}>
            <InviteText>Convidar outras pessoas para responder</InviteText>
            <ShareOutlinedIcon sx={{ marginLeft: '8px', fill: '#ffffff' }} />
          </InviteContainer>
        )}

        <InternalContainer spacing={4}>
          {loading ? (
            <CircularProgress />
          ) : hasFormToAnswer ? (
            <>
              <InternalText>
                Existem perguntas
                {isUserPatient(userInfos?.user)
                  ? ' '
                  : ` sobre ${formToAnswer?.patientPersonalInfo?.firstName} `}
                esperando você.
              </InternalText>
              <Box width='223px'>
                <Button onClick={handleAnswerQuestionnaire}>
                  Responder agora
                </Button>
              </Box>
            </>
          ) : (
            <>
              <InternalText>Não existem perguntas no momento.</InternalText>
              <DoneRoundedIcon
                sx={{ height: '60px', width: '60px', fill: '#01B3A7' }}
              />
              {formToAnswer?.formRequestSendDate && (
                <Stack>
                  <ScheduleText>
                    Um questionário estará disponível em
                  </ScheduleText>
                  <ScheduleText>
                    {format(
                      new Date(formToAnswer?.formRequestSendDate),
                      'dd MMM yy (EEEE)',
                      {
                        locale: brLocale,
                      }
                    )}
                  </ScheduleText>
                </Stack>
              )}
            </>
          )}
        </InternalContainer>
      </ExternalContainer>
    </RouteLayout>
  )
}

export default PatientHome
