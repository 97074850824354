import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Stack } from '@mui/material'
import { Button } from 'components'
import { useAuthContext } from 'contexts'
import { useNotification } from 'hooks'
import { sendContactMessage } from 'services'
import { Title, Description } from '../settings-styles'
import { TextAreaStyled } from './contact-us-styles'

function SettingsContactUs(): JSX.Element {
  const [message, setMessage] = useState('')
  const [sending, setSending] = useState(false)
  const { userInfos } = useAuthContext()

  const { errorToast, successToast } = useNotification()
  const navigate = useNavigate()

  const handleSendMessage = async () => {
    try {
      setSending(true)
      const body = {
        message:
          message +
          `\n\nuserName: ${userInfos?.personalInfos?.firstName} ${userInfos?.personalInfos?.lastName}`,
      }

      await sendContactMessage(body)

      successToast('Mensagem enviada com sucesso')
      setMessage('')
    } catch (error: any) {
      errorToast(
        error?.response?.data?.message || error?.message || 'Erro desconhecido'
      )
    } finally {
      setSending(false)
    }
  }

  return (
    <Box
      flexGrow={1}
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='space-between'
      px={2}
      pt={5}
      sx={{
        backgroundColor: '#ffffff',
        borderRadius: '28px 28px 0px 0px',
      }}
    >
      <Stack width='100%'>
        <Stack display='flex' flexDirection='column' spacing={2} mb={3}>
          <Title>Fale conosco</Title>
          <Description>
            Digite abaixo sua mensagem, entraremos em contato o mais breve
            possível.
          </Description>
        </Stack>
        <Stack spacing={2}>
          <TextAreaStyled
            multiline
            rows={4}
            variant='outlined'
            placeholder='Digite aqui...'
            value={message}
            onChange={(e: any) => setMessage(e.target.value)}
          />
        </Stack>
      </Stack>

      <Stack my={3} width='295px' spacing={1}>
        <Button
          onClick={handleSendMessage}
          disabled={!Boolean(message) || sending}
        >
          Enviar
        </Button>
        <Button variant='text' onClick={() => navigate('/settings')}>
          Voltar
        </Button>
      </Stack>
    </Box>
  )
}

export default SettingsContactUs
