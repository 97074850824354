import { styled } from '@mui/material/styles'
import LinearProgress from '@mui/material/LinearProgress'

export const LinearProgressStyled = styled(LinearProgress)({
  borderRadius: '92px',
  height: '10px',
  backgroundColor: '#F7F8FB',
  '.MuiLinearProgress-bar': {
    backgroundColor: '#E5E7F8',
  },
})
