interface IGetFullNameProps {
  firstName?: string
  lastName?: string
}

export const getFullName = ({
  firstName = '',
  lastName = '',
}: IGetFullNameProps) => {
  const fullName = `${firstName} ${lastName}`.trim()
  if (fullName.length) return fullName
  return '-'
}
