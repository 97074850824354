import { useState, useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'
import jwtDecode from 'jwt-decode'
import { Box, Stack, CircularProgress } from '@mui/material'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import { Button, RouteLayout, SignUpStepper } from 'components'
import { caregiverSteps } from 'components/sign-up-steps/sign-up-caregiver-steps'
import { SignUpCaregiverContext, useAuthContext } from 'contexts'
import { SignUpCaregiverBody } from 'types/services'
import { useNotification, useQuery } from 'hooks'
import {
  registerPageAccess,
  registerTrack,
  sanitizeString,
  toDate,
} from 'helpers'
import { generateValidationCode, checkInviteIsValid } from 'services'
import { CAREGIVER } from 'helpers/constants'
import useSignUpCaregiverForm from './use-sign-up-caregiver-form'

const errorMessages: any = {
  'Token not found': 'Você precisa de um convite válido para se cadastrar',
  'User not authorized.': 'Esse convite já foi utilizado',
}

function SignUpCaregiver() {
  const [step, setStep] = useState<number>(0)
  const [loadingSendCode, setLoadingSendCode] = useState(false)
  const [contacts, setContacts] = useState<any>({
    email: '',
    whatsapp: '',
  })
  const [loading, setLoading] = useState(false)

  const { successToast, warnToast, errorToast } = useNotification()
  const navigate = useNavigate()
  const query = useQuery()
  const methods = useSignUpCaregiverForm()
  const { signup } = useAuthContext()

  const {
    watch,
    setValue,
    trigger,
    getValues,
    formState: { errors },
  } = methods

  useEffect(() => {
    const isLastStep = step === caregiverSteps.length - 1

    if (isLastStep) {
      const errorsEntries = Object.entries(errors).filter(
        ([property]: any) =>
          !['acceptedShare', 'acceptedTermsAndPolicies'].includes(property)
      )

      if (errorsEntries.length === 0) return

      const errorsMessage = errorsEntries.reduce(
        (acc: string, [property, error]: any) => {
          if (property === 'emailValidationCode') {
            return (
              acc +
              'O código de validação de email expirou, por favor solicite um novo.' +
              '\n'
            )
          }

          if (property === 'whatsappValidationCode') {
            return (
              acc +
              'O código de validação de whatsapp expirou, por favor solicite um novo.' +
              '\n'
            )
          }

          return acc + error.message + '\n'
        },
        ''
      )

      errorToast(errorsMessage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors])

  const pronoun = watch('pronoun')

  const invitationToken = query.get('invitationToken')
  const userInfos: any = jwtDecode(invitationToken!)

  useEffect(() => {
    if (!errors.password && !errors.confirmPassword) return

    const message = errors.password?.message || errors.confirmPassword?.message

    if (message === 'Senhas devem ser iguais') {
      registerTrack('Senhas Diferentes', {
        patient_name: userInfos?.name,
        source: CAREGIVER,
      })
    }

    if (
      message ===
      'Sua senha deve ter letra minúscula, maiúscula, símbolo e número'
    ) {
      registerTrack('Senha Fraca', {
        patient_name: userInfos?.name,
        source: CAREGIVER,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors.password, errors.confirmPassword])

  useEffect(() => {
    const verifyToken = async () => {
      try {
        if (!invitationToken) {
          warnToast('Você precisa de um convite para acessar essa página')
          navigate('/signin/patient')
        }

        const { isActive } = await checkInviteIsValid(invitationToken!)

        if (!isActive) navigate('/expired_invitation')

        const [firstName, lastName] = getValues(['firstName', 'lastName'])
        const names = userInfos?.name?.split(' ')

        setValue('firstName', firstName || names?.shift() || '')
        setValue('lastName', lastName || names?.join(' ') || '')

        registerPageAccess(`Signup ${CAREGIVER.toLowerCase()}`)
      } catch (error) {
        navigate('/signup/patient')
      }
    }
    verifyToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sendValidationCode = async (
    contactType: string,
    showToast: boolean
  ) => {
    try {
      setLoadingSendCode(true)
      const [caregiverFirstName, contact] = getValues([
        'caregiverFirstName',
        contactType,
      ] as any)

      await generateValidationCode({
        name: caregiverFirstName,
        type: contactType,
        contact:
          contactType === 'whatsapp'
            ? 55 + sanitizeString(contact)
            : contact.toLowerCase(),
      })

      if (showToast) successToast('Código enviado com sucesso!')
    } catch (error) {
      errorToast(
        'Ocorreu um erro ao solicitar o código, por favor tente novamente.'
      )
    } finally {
      setLoadingSendCode(false)
    }
  }

  const onSubmit: any = async () => {
    const params = getValues()
    try {
      setLoading(true)

      const valid = await trigger()

      if (!valid) {
        setLoading(false)
        return
      }

      const communication = []
      if (params.emailValidationCode) communication.push('email')
      if (params.whatsappValidationCode) communication.push('whatsapp')

      const body: SignUpCaregiverBody = {
        cpf: sanitizeString(params.cpf),
        firstName: params.firstName.trim(),
        lastName: params.lastName.trim(),
        password: params.password.trim(),
        pronoun: params.pronoun.toUpperCase(),
        ...(!params.patientWithoutCpf && {
          patientCpf: sanitizeString(params.patientCpf!),
        }),

        birthdate: format(toDate(params.birthdate), 'yyyy-MM-dd'),
        communication: communication,
        ...(params.email && { email: params.email.toLowerCase() }),
        ...(params.whatsapp && {
          ddi: '55',
          whatsapp: sanitizeString(params.whatsapp),
        }),
        ...(params.emailValidationCode && {
          emailValidationCode: params.emailValidationCode,
        }),
        ...(params.whatsappValidationCode && {
          whatsappValidationCode: params.whatsappValidationCode,
        }),
        relationId: params.relation,
        userGroupId: params.environment,
        caregiverFirstName: params.caregiverFirstName.trim(),
        caregiverLastName: params.caregiverLastName.trim(),
        role: CAREGIVER,
        invitationToken: invitationToken!,
      }

      await signup(body)

      setLoading(false)

      successToast('Sua conta foi criada com sucesso!')
      navigate('/')
    } catch (error: any) {
      console.error(error)
      if (error?.message === 'Usuário já cadastrado.') {
        registerTrack('Usuário Existente', {
          patient_name: userInfos?.name,
          source: CAREGIVER,
          email: params.email,
        })
      }
      errorToast(
        errorMessages[error?.message] || error.message || 'Erro desconhecido'
      )
      setLoading(false)
    }
  }

  const goBackStep = () => {
    setLoading(true)

    if (step === 0) {
      setLoading(false)
      navigate(`/signup?invitationToken=${invitationToken}`)
    }

    if (step < 0) {
      setLoading(false)
      return
    }

    const whatsapp = getValues('whatsapp')

    if (caregiverSteps[step].id === 'password-step' && whatsapp) {
      setStep((step) => step - 2)
      setLoading(false)
      return
    }

    if (caregiverSteps[step].id === 'email-verification-code-step') {
      setStep((step) => step - 2)
      setLoading(false)
      return
    }

    setStep((step) => step - 1)
    setLoading(false)
  }

  const goNextStep = async () => {
    setLoading(true)

    const valid = await trigger(caregiverSteps[step].fields as any)

    if (!valid || step >= caregiverSteps.length - 1) {
      setLoading(false)
      return
    }

    if (caregiverSteps[step].id === 'contact-step') {
      const [whatsapp, email] = getValues(['whatsapp', 'email'])

      if (whatsapp && whatsapp !== contacts.whatsapp) {
        await sendValidationCode('whatsapp', false)
        setValue('whatsappValidationCode', '')
      }

      if (!whatsapp && email && email !== contacts.email) {
        await sendValidationCode('email', false)
        setValue('emailValidationCode', '')
      }

      setContacts((oldState: any) =>
        whatsapp ? { whatsapp, email: oldState.email } : { whatsapp, email }
      )

      setStep((step) => step + (Boolean(whatsapp) ? 1 : 2))
      setLoading(false)
      return
    }

    if (caregiverSteps[step].id === 'whatsapp-verification-code-step') {
      setStep((step) => step + 2)
      setLoading(false)
      return
    }

    setStep((step) => step + 1)
    setLoading(false)
  }

  const handleKeyDown = (e: any) => {
    if (e.code !== 'Enter' && e.code !== 'NumpadEnter') return
    // * ignora a tecla Enter nos passos do formulário
    e.preventDefault()
  }

  return (
    <SignUpCaregiverContext.Provider
      value={{
        goNextStep,
        patient: pronoun as any,
        patientName: userInfos?.name,
        type: CAREGIVER,
        sendValidationCode,
        loadingSendCode,
      }}
    >
      <FormProvider {...methods}>
        <RouteLayout bgColor='white'>
          <Box
            display='flex'
            flexDirection='column'
            height={window.innerHeight}
            component='form'
            onKeyDown={handleKeyDown}
          >
            {caregiverSteps[step].id !== 'patient-cover-step' && (
              <SignUpStepper
                totalSteps={caregiverSteps[step].stepperTotalSteps}
                activeStep={caregiverSteps[step].stepperIndex}
                stepTitle={
                  caregiverSteps[step].stepTitle ||
                  `Sobre ${
                    userInfos?.name?.split(' ')[0] || 'paciente'
                  } (paciente)`
                }
              />
            )}

            <Box mx={5} mt={3} flexGrow={1}>
              {caregiverSteps[step].component}
            </Box>

            <Stack direction='row' mb={7} mx={5} justifyContent='space-between'>
              <Box width='100px'>
                <Button
                  type='button'
                  variant='text'
                  onClick={goBackStep}
                  startIcon={<ChevronLeftRoundedIcon />}
                >
                  voltar
                </Button>
              </Box>
              <Box width='100px'>
                {caregiverSteps[step].hasNextButton &&
                step < caregiverSteps.length - 1 ? (
                  <Button
                    type='button'
                    onClick={goNextStep}
                    disabled={loading}
                    style={{
                      wordBreak: 'normal',
                    }}
                  >
                    {loading ? <CircularProgress size='18px' /> : 'Continuar'}
                  </Button>
                ) : (
                  <Button
                    type='button'
                    onClick={onSubmit}
                    disabled={loading}
                    style={{
                      wordBreak: 'normal',
                    }}
                  >
                    {loading ? <CircularProgress size='18px' /> : 'Cadastrar'}
                  </Button>
                )}
              </Box>
            </Stack>
          </Box>
        </RouteLayout>
      </FormProvider>
    </SignUpCaregiverContext.Provider>
  )
}

export default SignUpCaregiver
