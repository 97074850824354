import { Chip, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledChip = styled(Chip)<any>(() => ({
  marginRight: '10px',
  marginTop: '5px',
  marginBottom: '5px',
  transition: '0.4s',
  backgroundColor: '#01B3A7',
  border: '0.5px solid #DFDFDF',
  color: '#ffffff',
  fontFamily: 'Quicksand',
  fontSize: '10px',
  lineHeight: '13px',
  fontWeight: 400,
  borderRadius: '4px',
  whiteSpace: 'nowrap',
  height: '20px',
  '& span': {
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
  svg: {
    position: 'absolute',
    right: '-10px',
    top: '-5px',
    height: '18px',
    fill: '#ffffff',
  },
  '.MuiChip-deleteIcon': {
    margin: '0px 5px 0 -15px',
  },
  '& .MuiChip-label': {
    paddingRight: '8px',
    paddingLeft: '8px',
  },
}))

export const StyledIconButton = styled(IconButton)<any>(() => ({
  color: '#F09C92',
  border: '2px solid #F09C92',
  borderRadius: '4px',
  padding: 0,
  marginTop: '5px',
  marginBottom: '5px',
  height: '19px',
  width: '19px',
}))
