import { Avatar, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const AvatarStyled = styled(Avatar)({
  width: 92,
  height: 92,
  background: '#EDEEFD',
  color: '#05128A',
  border: '6px solid #FFFFFF',
  fontSize: '36px',
})

export const UserName = styled(Typography)({
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '21px',
  textTransform: 'uppercase',
  color: '#FFFFFF',
})

export const Title = styled(Typography)({
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '18px',
  color: '#657787',
})

export const Description = styled(Typography)({
  fontFamily: 'Zen Kaku Gothic Antique',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '21px',
  color: '#657787',
})
