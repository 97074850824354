import { useState } from 'react'
import { format } from 'date-fns'
import brLocale from 'date-fns/locale/pt-BR'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Avatar,
  IconButton,
  Tooltip,
  capitalize,
} from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import {
  ResendInviteModal,
  FilterTableHeader,
  SecondaryConfirmationModal,
} from 'components'
import { useAuthContext } from 'contexts'
import { registerTrack } from 'helpers'
import { PatientTableRow } from './pending-invite-list-styles'
import { SendStatusEnum } from 'types'
import { SendMethodEnum } from 'types/invites'
import { formatPhoneBr } from 'helpers/formatters'
import TrashIcon from 'components/icons/trash-icon'
import { useNotification } from 'hooks'
import { cancelInvite } from 'services'

type Order = 'asc' | 'desc'

interface HeadCell {
  id: string
  label: string
  order?: boolean
}

interface Data {
  name: string
  createdAt: string
}

const headerFields: HeadCell[] = [
  { id: 'name', label: 'Nome', order: true },
  { id: 'createdAt', label: 'Envio', order: true },
  { id: 'sendMethod', label: 'Enviado para', order: true },
  { id: 'actions', label: 'Ações' },
]

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (orderBy === 'sendMethod') {
    const aSendMethod = a[a[orderBy] as never]
    const bSendMethod = b[b[orderBy] as never]

    if (bSendMethod < aSendMethod) {
      return -1
    }
    if (bSendMethod > aSendMethod) {
      return 1
    }
  }

  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function PendingInviteList({
  invites,
  onFilter,
  fetchPatients,
}: any): JSX.Element {
  const [confirmationModalInfos, setConfirmationModalInfos] = useState<any>({
    isOpen: false,
    invite: undefined,
  })
  const [orderBy, setOrderBy] = useState<keyof Data>('name')
  const [order, setOrder] = useState<Order>('asc')
  const [resendInviteInfos, setResendInviteInfos] = useState<any>({
    open: false,
    inviteId: '',
    formId: '',
    token: '',
    code: '',
    name: '',
    email: '',
    whatsapp: '',
  })

  const { user } = useAuthContext()
  const { errorToast } = useNotification()

  const handleResendInvite = (invite: any) => {
    registerTrack('Reenvia Convite', {
      clinic_id: user.clinicId,
    })

    setResendInviteInfos({
      open: true,
      inviteId: invite.inviteId,
      formId: invite.formId,
      token: invite.token,
      code: invite.code,
      name: invite.name,
      email: invite.email,
      whatsapp: invite.whatsapp,
      sendMethod: invite.sendMethod,
    })
  }

  const handleDeleteInvite = async () => {
    try {
      await cancelInvite(confirmationModalInfos?.invite?.inviteId)
      fetchPatients()
    } catch (error: any) {
      errorToast(error.message)
    } finally {
      setConfirmationModalInfos({
        isOpen: false,
        invite: undefined,
      })
    }
  }

  const onCloseResendInviteModal = () => {
    fetchPatients()
    setResendInviteInfos({ open: false, token: '', name: '' })
  }

  const getSendMethod = (invite: any) => {
    let sendMethod = []

    if (invite?.sendMethod?.includes(SendMethodEnum.WHATSAPP)) {
      sendMethod.push(formatPhoneBr(invite?.whatsapp))
    }

    if (invite?.sendMethod?.includes(SendMethodEnum.EMAIL)) {
      sendMethod.push(invite?.email)
    }

    if (invite?.sendMethod?.includes(SendMethodEnum.LINK)) {
      sendMethod.push(capitalize(SendMethodEnum.LINK))
    }

    return sendMethod.join(', ')
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      alignItems='center'
      flex={1}
    >
      <SecondaryConfirmationModal
        title='Deseja mesmo excluir esse convite?'
        text='O questionário elaborado para este paciente será apagado e o
      link de cadastro será invalidado'
        isOpen={confirmationModalInfos.isOpen}
        onClose={() =>
          setConfirmationModalInfos({ isOpen: false, invite: undefined })
        }
        onConfirm={handleDeleteInvite}
      />

      <TableContainer
        component={Paper}
        sx={{ marginTop: '16px', boxShadow: 'none' }}
      >
        <Table aria-label='tabela de pacientes'>
          <FilterTableHeader
            component='pendingInviteList'
            headerFields={headerFields}
            onFilter={onFilter}
            orderBy={orderBy}
            order={order}
            setOrderBy={setOrderBy}
            setOrder={setOrder}
          />
          <TableBody>
            <TableRow>
              <TableCell colSpan={6} sx={{ border: 'none', height: '40px' }} />
            </TableRow>
            {invites.sort(getComparator(order, orderBy)).map((invite: any) => {
              const isScheduled =
                invite?.sendStatus === SendStatusEnum.SCHEDULED

              return (
                <PatientTableRow
                  key={invite.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell width={500}>
                    <Box display='flex' alignItems='center'>
                      <Avatar sx={{ marginRight: '8px' }}>
                        {invite.name[0] || 'W'}
                      </Avatar>
                      {invite.name}
                    </Box>
                  </TableCell>
                  <TableCell>
                    {format(new Date(invite.createdAt), 'dd/MM/yyyy')}
                  </TableCell>
                  <TableCell width={260}>{getSendMethod(invite)}</TableCell>
                  <TableCell width={200} align='center'>
                    <Tooltip
                      title={
                        isScheduled
                          ? `Envio agendado ${
                              invite?.sendDate
                                ? format(
                                    new Date(invite.sendDate),
                                    "'para' dd MMM yy",
                                    {
                                      locale: brLocale,
                                    }
                                  )
                                : ''
                            }`
                          : 'Editar e/ou reenviar'
                      }
                      placement='top'
                      arrow
                    >
                      <span>
                        <IconButton
                          aria-label='Editar e/ou reenviar'
                          component='span'
                          sx={{ color: '#657787' }}
                          onClick={() => handleResendInvite(invite)}
                          disabled={isScheduled}
                        >
                          <SendIcon fontSize='small' />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title={'Cancelar convite'} placement='top' arrow>
                      <IconButton
                        aria-label='Cancelar convite'
                        component='span'
                        sx={{ color: '#657787' }}
                        onClick={() =>
                          setConfirmationModalInfos({
                            isOpen: true,
                            invite,
                          })
                        }
                      >
                        <TrashIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </PatientTableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {resendInviteInfos.open && (
        <ResendInviteModal
          onClose={onCloseResendInviteModal}
          informantion={resendInviteInfos}
        />
      )}
    </Box>
  )
}

export default PendingInviteList
