import { useEffect, useState } from 'react'
import { Box, Stack } from '@mui/material'
import { SecondaryConfirmationModal, ButtonClinic } from 'components'
import { CodeField } from 'components/inputs'
import { checkValidationCode, generateValidationCode } from 'services'
import { useNotification } from 'hooks'
import { useClinicContext } from 'contexts'
import { sanitizeString } from 'helpers'
import useCommunicationValidationCodeForm from './validation-code-modal-form'
import { Description } from './validation-code-modal-styles'

function ValidationCodeModal({
  type,
  isOpen,
  handleClose,
  contact,
  handleConfirm,
}: any) {
  const [loadingSendCode, setLoadingSendCode] = useState(false)
  const [sending, setSending] = useState(false)

  const { control, handleSubmit } = useCommunicationValidationCodeForm()
  const { errorToast, successToast } = useNotification()
  const { clinic } = useClinicContext()

  const sendValidationCode = async () => {
    try {
      setLoadingSendCode(true)

      await generateValidationCode({
        name: clinic.firstName + ' ' + clinic.lastName,
        type: type,
        contact:
          type === 'whatsapp'
            ? 55 + sanitizeString(contact)
            : contact.toLowerCase(),
      })

      successToast('Código enviado com sucesso!')
    } catch (error: any) {
      errorToast(error?.message)
    } finally {
      setLoadingSendCode(false)
    }
  }

  useEffect(() => {
    sendValidationCode()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (params: any) => {
    try {
      setSending(true)
      await checkValidationCode({
        code: params.validationCode,
        type: type,
        contact:
          type === 'whatsapp'
            ? 55 + sanitizeString(contact)
            : contact.toLowerCase(),
      })

      handleConfirm(params.validationCode)
    } catch (error: any) {
      errorToast(error?.message)
    } finally {
      setSending(false)
    }
  }

  return (
    <SecondaryConfirmationModal
      isOpen={isOpen}
      title='Validação'
      hideButtons
      onClose={handleClose}
      content={
        <Box
          component='form'
          onSubmit={handleSubmit(onSubmit)}
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          alignItems='center'
          paddingTop={4}
        >
          <Box mb={1}>
            <Description>
              Digite o código recebido no seu{' '}
              {type === 'email' ? 'e-mail' : 'celular'} para validar sua conta.
            </Description>
          </Box>
          <Box
            width='100%'
            display='flex'
            flexDirection='column'
            alignItems='center'
          >
            <CodeField control={control} name='validationCode' />

            <Box my={1}>
              <Description>
                O código pode demorar alguns instantes para chegar.
              </Description>
              <Description>Verifique a caixa de spam.</Description>
            </Box>

            <ButtonClinic
              variant='text'
              color='purple'
              fontWeight='bold'
              size='small'
              onClick={sendValidationCode}
              loading={loadingSendCode}
            >
              {loadingSendCode ? 'Aguarde' : 'Enviar novamente'}
            </ButtonClinic>
          </Box>

          <Stack
            direction='row'
            alignSelf='center'
            justifySelf='flex-end'
            spacing={4}
            justifyContent='space-between'
            mt={6}
            mb={3}
          >
            <ButtonClinic
              width={140}
              variant='outlined'
              color='purple'
              fontWeight='bold'
              size='small'
              onClick={handleClose}
              type='button'
            >
              Cancelar
            </ButtonClinic>

            <ButtonClinic
              width={140}
              variant='contained'
              color='purple'
              fontWeight='bold'
              size='small'
              type='submit'
              loading={sending}
            >
              {sending ? 'Validando' : 'Validar'}
            </ButtonClinic>
          </Stack>
        </Box>
      }
    />
  )
}

export default ValidationCodeModal
