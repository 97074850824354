import { Typography, Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Title = styled(Typography)({
  fontFamily: 'Quicksand',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '26px',
  color: '#ffffff',
})

export const Description = styled(Typography)({
  fontFamily: 'Zen Kaku Gothic Antique',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '23px',
  color: '#ffffff',
})

export const ButtonText = styled(Typography)({
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '16px',
  textAlign: 'center',
  color: '#F36F60',
})

export const ButtonContainer = styled(Box)({
  height: '50px',
  width: '50px',
  border: '1px solid #F36F60',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})
