import { Stack } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Container = styled(Stack)<any>({
  height: '100%',
  background: '#F9F9F9',
  borderRadius: '16px',
  display: 'flex',
  flexWrap: 'wrap',
})
