import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Title = styled(Typography)`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #657787;
`

export const InfoText = styled(Typography)`
  font-family: 'Zen Kaku Gothic Antique';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #657787;
`

export const ResendButton = styled('span')<any>(({ disabled }) => ({
  fontFamily: 'Zen Kaku Gothic Antique',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '23px',
  textDecorationLine: 'underline',
  color: '#636363',
  cursor: disabled ? 'default' : 'pointer',
}))

export const ErrorMessage = styled('span')({
  fontFamily: 'Zen Kaku Gothic Antique',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  color: 'red',
})
