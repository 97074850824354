import { useEffect } from 'react'
import { Box, Stack } from '@mui/material'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import { useForm } from 'react-hook-form'
import { IconButtonClinic, TextFieldClinic } from 'components'
import { useQuery } from 'hooks'
import { newQuestionnairePatientStepResolver } from 'resolvers'
import { useInvitePatientContext } from 'contexts'
import { Title, SupportText } from '../styles/common-styles'

function PatientNameStep(): JSX.Element {
  const query = useQuery()
  const { goNextStep, setNewQuestionnaireInfos, newQuestionnaireInfos } =
    useInvitePatientContext()
  const { control, handleSubmit, setValue, watch } = useForm<any>({
    defaultValues: {
      patientName: '',
    },
    resolver: newQuestionnairePatientStepResolver,
  })

  const patientName = watch('patientName')

  useEffect(() => {
    setValue(
      'patientName',
      newQuestionnaireInfos.patientName || query.get('patient_name') || ''
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = (data: any) => {
    setNewQuestionnaireInfos((newQuestionnaireInfos: any) => ({
      ...newQuestionnaireInfos,
      patientName: data.patientName,
    }))
    goNextStep()
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      flexGrow={1}
      component='form'
      onSubmit={handleSubmit(onSubmit)}
    >
      <Title>Primeiro, informe o nome do paciente</Title>

      <Stack direction='row' my={5} alignItems='flex-start'>
        <Box width={600}>
          <TextFieldClinic
            name='patientName'
            control={control}
            placeholder='Digite o nome completo do paciente'
          />
        </Box>
        <Box width={50} marginTop={0.3} ml={3}>
          <IconButtonClinic
            size={42}
            color='purple'
            type='submit'
            disabled={!Boolean(patientName)}
          >
            <ArrowForwardIosRoundedIcon
              sx={{ height: '24px', width: '24px' }}
            />
          </IconButtonClinic>
        </Box>
      </Stack>

      <SupportText>
        O nome que você informar aqui pode ser editado depois pelo próprio
        paciente -
      </SupportText>
      <SupportText>
        caso esteja incorreto, incompleto ou ele prefira ser identificado de
        outra forma.
      </SupportText>
    </Box>
  )
}

export default PatientNameStep
