import { useNavigate } from 'react-router-dom'
import { GradientLink, Link } from './gradient-link'
import { Text } from './sign-up-header-styles'

function SignUpHeader({ type }: any): JSX.Element {
  const navigate = useNavigate()

  return (
    <Text>
      Não tem uma conta?{' '}
      {type === 'clinic' ? (
        <Link href='/signup/clinic' rel='noreferrer'>
          Cadastre-se
        </Link>
      ) : (
        <GradientLink onClick={() => navigate('/invite_clinic')}>
          Cadastre-se
        </GradientLink>
      )}
    </Text>
  )
}

export default SignUpHeader
