const DownloadIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        d='M15.8333 10.8333V15C15.8333 15.4583 15.4583 15.8333 15 15.8333H5C4.54167 15.8333 4.16667 15.4583 4.16667 15V10.8333C4.16667 10.375 3.79167 10 3.33333 10C2.875 10 2.5 10.375 2.5 10.8333V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V10.8333C17.5 10.375 17.125 10 16.6667 10C16.2083 10 15.8333 10.375 15.8333 10.8333ZM10.8333 10.5583L12.4 8.99167C12.4772 8.91451 12.5687 8.85331 12.6695 8.81156C12.7703 8.76981 12.8784 8.74832 12.9875 8.74832C13.0966 8.74832 13.2046 8.76981 13.3055 8.81156C13.4063 8.85331 13.4978 8.91451 13.575 8.99167C13.6522 9.06882 13.7134 9.16041 13.7551 9.26121C13.7969 9.36202 13.8183 9.47006 13.8183 9.57917C13.8183 9.68827 13.7969 9.79632 13.7551 9.89712C13.7134 9.99792 13.6522 10.0895 13.575 10.1667L10.5833 13.1583C10.5062 13.2356 10.4147 13.2969 10.3139 13.3387C10.213 13.3805 10.105 13.402 9.99583 13.402C9.88669 13.402 9.77862 13.3805 9.67781 13.3387C9.577 13.2969 9.48543 13.2356 9.40833 13.1583L6.41667 10.1667C6.26085 10.0109 6.17332 9.79952 6.17332 9.57917C6.17332 9.35881 6.26085 9.14748 6.41667 8.99167C6.57248 8.83585 6.78381 8.74832 7.00417 8.74832C7.22452 8.74832 7.43585 8.83585 7.59167 8.99167L9.16667 10.5583V3.33333C9.16667 2.875 9.54167 2.5 10 2.5C10.4583 2.5 10.8333 2.875 10.8333 3.33333V10.5583Z'
        fill='#596B7B'
      />
    </svg>
  )
}

export default DownloadIcon
