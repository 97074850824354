import { Typography, Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const HeaderContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '0px 32px',
})

export const HeaderText = styled(Typography)({
  fontFamily: 'Zen Kaku Gothic Antique',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '35px',
  color: '#F2F2F2',
})

export const HealthProfessionalLink = styled(Typography)({
  fontFamily: 'Zen Kaku Gothic Antique',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  textDecorationLine: 'underline',
  cursor: 'pointer',
  color: '#F1F1F1',
})
