import CpfStep from './general-steps/cpf-step'
import PatientPersonalInfoOneStep from './sign-up-caregiver-steps/patient-personal-info-one-step'
import PatientPersonalInfoTwoStep from './sign-up-caregiver-steps/patient-personal-info-two-step'
import PatientCoverStep from './sign-up-caregiver-steps/patient-cover-step'
import PasswordStep from './general-steps/password-step'
import { CaregiverStepType } from 'types'
import CaregiverPersonalInfoStep from './sign-up-caregiver-steps/caregiver-personal-info-step'
import ContactStep from './general-steps/contact-step'
import TermsStep from './general-steps/terms-step'
import WhatsappValidationCodeStep from './general-steps/whatsapp-validation-code-step'
import EmailValidationCodeStep from './general-steps/email-validation-code-step'

export const caregiverSteps: CaregiverStepType[] = [
  {
    id: 'cpf-step',
    component: <CpfStep />,
    fields: ['cpf'],
    hasNextButton: true,
    stepperIndex: 0,
    stepTitle: 'Acesso',
    stepperTotalSteps: 5,
  },
  {
    id: 'caregiver-personal-info-step',
    component: <CaregiverPersonalInfoStep />,
    fields: ['caregiverFirstName', 'caregiverLastName', 'relation'],
    hasNextButton: true,
    stepperIndex: 1,
    stepTitle: 'Sobre você',
    stepperTotalSteps: 5,
  },
  {
    id: 'contact-step',
    component: <ContactStep />,
    fields: ['whatsapp', 'email'],
    hasNextButton: true,
    stepperIndex: 2,
    stepTitle: 'Contato',
    stepperTotalSteps: 5,
  },
  {
    id: 'whatsapp-verification-code-step',
    component: <WhatsappValidationCodeStep type='caregiver' />,
    fields: ['whatsappValidationCode'],
    hasNextButton: true,
    stepperIndex: 3,
    stepTitle: 'Confirmação',
    stepperTotalSteps: 5,
  },
  {
    id: 'email-verification-code-step',
    component: <EmailValidationCodeStep type='caregiver' />,
    fields: ['emailValidationCode'],
    hasNextButton: true,
    stepperIndex: 3,
    stepTitle: 'Confirmação',
    stepperTotalSteps: 5,
  },
  {
    id: 'password-step',
    component: <PasswordStep />,
    fields: ['password', 'confirmPassword'],
    hasNextButton: true,
    stepperIndex: 4,
    stepTitle: 'Segurança',
    stepperTotalSteps: 5,
  },
  {
    id: 'patient-cover-step',
    component: <PatientCoverStep />,
    fields: [],
    hasNextButton: true,
    stepperIndex: 0,
    stepTitle: 'Capa',
    stepperTotalSteps: 3,
  },
  {
    id: 'patient-personal-info-one-step',
    component: <PatientPersonalInfoOneStep />,
    fields: ['firstName', 'lastName', 'pronoun'],
    hasNextButton: true,
    stepperIndex: 0,
    stepTitle: '',
    stepperTotalSteps: 3,
  },
  {
    id: 'patient-personal-info-two-step',
    component: <PatientPersonalInfoTwoStep />,
    fields: ['birthdate', 'patientCpf', 'patientWithoutCpf'],
    hasNextButton: true,
    stepperIndex: 1,
    stepTitle: '',
    stepperTotalSteps: 3,
  },
  {
    id: 'terms-step',
    component: <TermsStep />,
    fields: ['acceptedShare', 'acceptedTermsAndPolicies'],
    hasNextButton: true,
    stepperIndex: 2,
    stepTitle: 'Segurança',
    stepperTotalSteps: 3,
  },
]
