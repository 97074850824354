const ContactUsIcon = ({ color = '#657787' }: { color?: string }) => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.70508 11.6404C4.70508 7.42063 8.06283 4 12.2051 4C16.3473 4 19.7051 7.42063 19.7051 11.6404V15.5783C19.7051 16.836 18.6401 17.7528 17.4551 17.7528H15.2051V11.6404H18.2051C18.2051 10.0194 17.5729 8.46465 16.4477 7.31836C15.3225 6.17207 13.7964 5.52809 12.2051 5.52809C10.6138 5.52809 9.08766 6.17207 7.96244 7.31836C6.83722 8.46465 6.20508 10.0194 6.20508 11.6404H9.20508V17.7528H7.75233C7.83548 18.0809 8.02331 18.3715 8.28629 18.579C8.54927 18.7864 8.87247 18.899 9.20508 18.8989H10.3781C10.6151 18.5528 11.0088 18.3258 11.4551 18.3258H12.9551C13.3032 18.3258 13.637 18.4667 13.8832 18.7175C14.1293 18.9682 14.2676 19.3083 14.2676 19.6629C14.2676 20.0175 14.1293 20.3576 13.8832 20.6084C13.637 20.8591 13.3032 21 12.9551 21H11.4551C11.0088 21 10.6151 20.7731 10.3781 20.427H9.20508C8.45351 20.4271 7.72928 20.1398 7.17569 19.6219C6.6221 19.1041 6.27946 18.3934 6.21558 17.6306C5.36058 17.3425 4.70508 16.5655 4.70508 15.5791V11.6404ZM7.70508 16.2247V13.1685H6.20508V15.5783C6.20508 15.8786 6.48258 16.2247 6.95508 16.2247H7.70508ZM18.2051 13.1685H16.7051V16.2247H17.4551C17.9276 16.2247 18.2051 15.8786 18.2051 15.5783V13.1685Z'
        fill={color}
      />
    </svg>
  )
}

export default ContactUsIcon
