import { Link as LinkRRD } from 'react-router-dom'
import { styled } from '@mui/material/styles'

export const Link = styled(LinkRRD)`
  &:link, &:visited, &:active {
    font-family: 'Zen Kaku Gothic Antique', sans-serif;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    color: #00B3A6;
    text-decoration: none;
    transition: 0.4s;
    
    &:hover {
      opacity: 0.6;
    }
  }
`
