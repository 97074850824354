import PersonalDataIcon from 'components/icons/clinic-profile/personal-data-icon'
import ContactDataIcon from 'components/icons/clinic-profile/contact-data-icon'
import PaymentDataIcon from 'components/icons/clinic-profile/payment-data-icon'
import TermsOfUseIcon from 'components/icons/clinic-profile/terms-of-use-icon'
import {
  MyInfoTab,
  ContactsTab,
  PaymentTab,
  TermsTab,
} from 'components/clinic-profile-tabs'

import ContactUs from 'components/clinic-profile-tabs/contact-us/contact-us'
import ContactUsIcon from 'components/icons/clinic-profile/contact-us-icon'

export const tabs: any = [
  {
    icon: ({ color }: any) => <PersonalDataIcon color={color} />,
    label: 'Dados pessoais',
    component: <MyInfoTab />,
    editButtonStatus: 'enabled',
  },
  {
    icon: ({ color }: any) => <ContactDataIcon color={color} />,
    label: 'Notificações',
    component: <ContactsTab />,
    editButtonStatus: 'enabled',
  },
  {
    icon: ({ color }: any) => <PaymentDataIcon color={color} />,
    label: 'Dados de pagamento',
    component: <PaymentTab />,
    editButtonStatus: 'disabled',
  },
  {
    icon: ({ color }: any) => <TermsOfUseIcon color={color} />,
    label: 'Termos de uso',
    component: <TermsTab />,
    editButtonStatus: 'hidden',
  },
  {
    icon: ({ color }: any) => <ContactUsIcon color={color} />,
    label: 'Fale Conosco',
    component: <ContactUs />,
    editButtonStatus: 'hidden',
  },
]
