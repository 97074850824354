import { compareAsc } from 'date-fns'

export type Order = 'asc' | 'desc'

export interface Data {
  createdAt: string
}

export function descendingComparator(a: any, b: any, orderBy: any) {
  // * createdAt
  return compareAsc(new Date(a.createdAt), new Date(b.createdAt))
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}
