import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { Box, MenuItem, SxProps, Tooltip } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { Control, Controller } from 'react-hook-form'
import { FieldContainer, SelectAnswerStyled } from './select-answer-styles'

interface OptionType {
  value: string
  label: any
  hoverText?: string
}

interface SelectAnswerProps {
  name: string
  control: Control<any>
  options: OptionType[]
  disabled?: boolean
  defaultValue: string
  onOpen?: () => void
  onClose?: () => void
}

const answerPlaceholder = (
  <Box
    sx={{
      display: 'flex',
      width: '28px',
      height: '24px',
      alignItems: 'center',
      justifyContent: 'center',
      paddingRight: '2px',
    }}
  >
    <Box>
      <Box
        sx={{
          width: 12,
          height: 12,
          borderRadius: '50%',
          backgroundColor: '#D4D4D4',
          border: '1px solid #D4D4D4',
        }}
      />
    </Box>
  </Box>
)

function SelectAnswer({
  name,
  control,
  options,
  disabled,
  defaultValue = options[0].value,
  onOpen,
  onClose,
}: SelectAnswerProps) {
  const [isSelectOpen, setIsSelectOpen] = useState(false)
  const [hasSelectedItem, setHasSelectedItem] = useState(false)
  const [hasSpace, setHasSpace] = useState(true)
  const [dimensions, setDimensions] = useState({
    x: 0,
    y: 0,
    height: 0,
  })
  const anchorRef = useRef<HTMLDivElement>(null)

  const optionsHeight = options.length * 18.4
  const SPACE_FOR_DROPDOWN = 103

  useEffect(() => {
    const updateDimensions = () => {
      if (anchorRef.current) {
        setDimensions({
          x: Math.round(anchorRef.current.getBoundingClientRect().x),
          y: anchorRef.current.getBoundingClientRect().y,
          height: anchorRef.current.getBoundingClientRect().height,
        })
      }
    }

    updateDimensions()

    window.addEventListener('scroll', updateDimensions)
    window.addEventListener('resize', updateDimensions)

    return () => {
      window.removeEventListener('scroll', updateDimensions)
      window.removeEventListener('resize', updateDimensions)
    }
  }, [])

  const anchorRectValues = {
    left:
      window.devicePixelRatio < 1.5
        ? `${(dimensions.x - 1).toString()}px !important`
        : `${(dimensions.x - 0.5).toString()}px !important`,
    top: hasSpace
      ? `${(dimensions.y + dimensions.height).toString}px !important`
      : !hasSpace
      ? `${dimensions.y - optionsHeight}px !important`
      : '',
  }

  const handleDropdownSpace = () => {
    if (anchorRef.current) {
      const rect = anchorRef.current.getBoundingClientRect()
      const spaceBelow = window.innerHeight - rect.bottom
      if (spaceBelow < SPACE_FOR_DROPDOWN) {
        setHasSpace(false)
      } else {
        setHasSpace(true)
      }
    }
  }

  const sx: SxProps = {
    width: '32px',
    minWidth: '32px',
    display: 'flex',
    justifyContent: 'center',
    border: '1.5px solid #BDC1DD',
    borderTop: hasSpace ? 'none' : '',
    borderBottom: hasSpace ? '' : 'none',
    borderRadius: hasSpace ? '0 0 16px 16px' : '16px 16px 0 0',
    boxShadow: 'none',
    left: `${anchorRectValues.left}`,
    top: `${anchorRectValues.top}`,

    '& .MuiMenuItem-root': {
      fontFamily: 'Quicksand',
      color: '#596B7B',
      fontSize: '16px',
      lineHeight: '24px',
      backgroundColor: 'transparent',
      padding: '4px 16px',
    },
    '& .MuiMenuItem-root:hover': {
      backgroundColor: '#F2F3F7',
    },
    '& .MuiList-root': {
      flexGrow: '1',
      paddingTop: '2px',
      paddingBottom: '2px',
    },
    '& .MuiMenuItem-root.Mui-selected, & .MuiMenuItem-root.Mui-selected:hover':
      {
        backgroundColor: '#F2F3F7',
      },
  }

  const handleRenderValue = (selected: any) => {
    const findOption = options.find(
      (option: OptionType) => selected === option.value
    )

    setHasSelectedItem(Boolean(findOption?.label))

    return findOption?.label ? (
      <Box
        sx={{
          display: 'flex',
          width: '30px',
          height: '24px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {findOption?.label}
      </Box>
    ) : (
      answerPlaceholder
    )
  }

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { value, onChange }, fieldState: { invalid } }) => (
        <FieldContainer
          variant='standard'
          error={invalid}
          disabled={disabled}
          >
          <SelectAnswerStyled
            ref={anchorRef}
            hasSelectedItem={hasSelectedItem}
            hasSpace={hasSpace}
            value={value}
            onChange={onChange}
            isOpen={isSelectOpen}
            open={isSelectOpen}
            onOpen={() => {
              handleDropdownSpace()
              setIsSelectOpen(true)
              onOpen && onOpen()
            }}
            onClose={() => {
              setIsSelectOpen(false)
              onClose && onClose()
            }}
            displayEmpty
            disabled={disabled}
            renderValue={handleRenderValue}
            IconComponent={
              disabled
                ? () => <></>
                : () =>
                    isSelectOpen ? (
                      <ExpandLessRoundedIcon
                        sx={{ cursor: 'pointer', fill: '#BDC1DD' }}
                        onClick={() => {
                          setIsSelectOpen(false)
                          onClose && onClose()
                        }}
                      />
                    ) : (
                      <ExpandMoreRoundedIcon
                        sx={{ cursor: 'pointer', fill: '#BDC1DD' }}
                        onClick={() => {
                          handleDropdownSpace()
                          setIsSelectOpen(true)
                          onOpen && onOpen()
                        }}
                      />
                    )
            }
            SelectDisplayProps={{
              style: {
                backgroundColor: 'transparent',
                zIndex: 1,
              },
            }}
            MenuProps={{ PaperProps: { sx } }}
          >
            {options.map(({ label, value, hoverText }) => {
              return value === 'not_answer' ? (
                <MenuItem
                  key={value}
                  value={value}
                  sx={{ height: 0, width: 0, padding: '0 !important' }}
                />
              ) : (
                <MenuItem key={value} value={value}>
                  {hoverText ? (
                    <Tooltip title={hoverText} placement='right'>
                      <span>{label}</span>
                    </Tooltip>
                  ) : (
                    label
                  )}
                </MenuItem>
              )
            })}
          </SelectAnswerStyled>
        </FieldContainer>
      )}
    />
  )
}

export default SelectAnswer
