import { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, CircularProgress, Tooltip } from '@mui/material'
import {
  AnswersTimelineDots,
  AnswersTimelineGradient,
} from 'components/results'
import { useResultsContext } from 'contexts'
import { createPatientAttention, deletePatientAttention } from 'services'
import { generateQuestionsAndAnswers, sortTagQuestionsByOrder } from 'helpers'
import {
  QuestionBoxContainer,
  QuestionLine,
  QuestionText,
  DividerLine,
  BookmarkIconStyled,
  BookmarkBorderIconStyled,
} from './question-box-styles'
import { useFormContext } from 'react-hook-form'

interface IQuestionBox {
  questions?: any
  timeline: any
  filterQuestionIds: string[]
}

function QuestionBox({
  timeline,
  filterQuestionIds,
}: IQuestionBox): JSX.Element {
  const [questions, setQuestions] = useState<any>([])
  const [highlightQuestion, setHighlightQuestion] = useState<any>(null)
  const [submittingAttention, setSubmittingAttention] = useState<string>('')

  const { patient_id } = useParams()
  const { fetchPatientAttentions, patientAttentions, patient } =
    useResultsContext()
  const { watch } = useFormContext()

  const selectedResponders = watch('selectedResponders')
  const selectedTags = watch('selectedTags')

  const hasPatientSelected = selectedResponders.includes(patient.patientId)

  useEffect(() => {
    const questions = generateQuestionsAndAnswers(timeline)

    const filteredQuestions =
      filterQuestionIds.length === 0
        ? questions
        : questions.filter(({ id }: any) => filterQuestionIds.includes(id))

    // TODO refatorar quando permitir multitags
    const [orderTagId] = selectedTags

    const sortedQuestions = sortTagQuestionsByOrder(
      filteredQuestions,
      orderTagId
    )

    setQuestions(sortedQuestions)
  }, [timeline, filterQuestionIds, selectedTags])

  const handleEmphasisQuestion = (question: any) => {
    return () => {
      setHighlightQuestion(
        highlightQuestion === question.id ? null : question.id
      )
    }
  }

  const handleCheckAttention = (question: any) => async (e: any) => {
    try {
      setSubmittingAttention(question.id)
      e.stopPropagation()
      await createPatientAttention({
        patientId: patient_id,
        questionId: question.id,
      })

      await fetchPatientAttentions()
    } catch (error) {
      console.error(error)
    } finally {
      setSubmittingAttention('')
    }
  }

  const handleUncheckAttention = (question: any) => async (e: any) => {
    try {
      setSubmittingAttention(question.id)
      e.stopPropagation()
      const patientAttention = patientAttentions.find(
        (patientAttention: any) =>
          question.id === patientAttention?.question?.id
      )
      await deletePatientAttention(patientAttention.id)

      await fetchPatientAttentions()
    } catch (error) {
      console.error(error)
    } finally {
      setSubmittingAttention('')
    }
  }

  const getTooltipText = (question: any) => {
    if (patient.pronoun === 'HE') {
      if (hasPatientSelected) {
        return question.questionSelfM
      } else {
        return question.question3rdM
      }
    } else {
      if (hasPatientSelected) {
        return question.questionSelfF
      } else {
        return question.question3rdF
      }
    }
  }

  return (
    <QuestionBoxContainer>
      {questions.map((question: any) => {
        let highlightStatus = 'normal'
        if (highlightQuestion) {
          highlightStatus =
            question.id === highlightQuestion ? 'highlight' : 'opaque'
        }

        const hasAttention = patientAttentions.some(
          (patientAttention: any) =>
            question.id === patientAttention?.question?.id
        )

        return (
          <Fragment key={question.id}>
            <QuestionLine
              direction='row'
              highlight={highlightStatus}
              onClick={handleEmphasisQuestion(question)}
            >
              {hasPatientSelected ? (
                Boolean(submittingAttention) &&
                submittingAttention === question.id ? (
                  <CircularProgress
                    size={16}
                    sx={{
                      marginLeft: '4px',
                      marginRight: '8px',
                      color: '#ddd',
                    }}
                  />
                ) : hasAttention ? (
                  <BookmarkIconStyled
                    onClick={handleUncheckAttention(question)}
                  />
                ) : (
                  <BookmarkBorderIconStyled
                    onClick={
                      submittingAttention
                        ? () => {}
                        : handleCheckAttention(question)
                    }
                  />
                )
              ) : (
                <Box width='28px' />
              )}
              <Box width='310px' mr='116px'>
                <Tooltip
                  title={getTooltipText(question)}
                  placement='right'
                  arrow
                >
                  <QuestionText>{question.ultraShortVersion}</QuestionText>
                </Tooltip>
              </Box>
              {question.id === highlightQuestion ? (
                <AnswersTimelineGradient question={question} />
              ) : (
                <AnswersTimelineDots question={question} />
              )}
            </QuestionLine>

            {question.id !== highlightQuestion && (
              <DividerLine sx={{ opacity: 0.5 }} />
            )}
          </Fragment>
        )
      })}
    </QuestionBoxContainer>
  )
}

export default QuestionBox
