/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { Title, Subtitle } from '../create-patient-report-styles'
import { useCreatePatientReportContext } from 'contexts'
import { ButtonClinic } from 'components'
import CheckIcon from 'components/icons/check-icon'
import { useNotification } from 'hooks'
import { useEffect, useState } from 'react'
import { handleDownloadPdf } from 'components/patient-reports/pdf-document/helpers/download-pdf'

function GeneratedReport() {
  const [isLoading, setIsLoading] = useState(false)

  const { patient, createPatientReport } = useCreatePatientReportContext()
  const { errorToast } = useNotification()
  const navigate = useNavigate()

  const handleDownload = async (patientReportId: string) => {
    try {
      setIsLoading(true)
      handleDownloadPdf(patientReportId)
    } catch (error: any) {
      errorToast('Erro ao baixar o relatório')
    } finally {
      setIsLoading(false)
    }
  }

  const handleGoBackToMyPatients = () => {
    navigate('/patients', { replace: true })
  }

  useEffect(() => {
    handleDownload(createPatientReport.id)
  }, [])

  return (
    <Stack alignItems='center'>
      <Title mt={14}>Tudo certo!</Title>
      <Title>Seu Relatório Wida sobre {patient.firstName} foi gerado</Title>
      <Box my={4}>
        <CheckIcon />
      </Box>
      <Subtitle textAlign='center' maxWidth={737}>
        Caso o download não tenha iniciado automaticamente, clique no botão
        abaixo. Salve e compartilhe o relatório com quem desejar. Todos os PDFs
        gerados ficam armazenados na aba Relatórios.
      </Subtitle>
      <Box display='flex' gap={3} mt={8}>
        <ButtonClinic
          variant='contained'
          color='purple'
          fontWeight='bold'
          size='small'
          width={247}
          onClick={() => handleDownloadPdf(createPatientReport.id)}
          loading={isLoading}
        >
          Baixar Relatório Wida
        </ButtonClinic>
        <ButtonClinic
          variant='outlined'
          color='purple'
          fontWeight='bold'
          size='small'
          width={247}
          onClick={handleGoBackToMyPatients}
        >
          Voltar para Meus pacientes
        </ButtonClinic>
      </Box>
    </Stack>
  )
}

export default GeneratedReport
