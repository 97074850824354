import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import logoColor from 'images/wida-logo-color.svg'
import userCircleIcon from 'components/icons/user-circle.svg'
import listPlusIcon from 'components/icons/list-plus.svg'
// import settingsIcon from 'components/icons/settings.svg'

const menuOptions = [
  { icon: userCircleIcon, label: 'Meus pacientes', route: '/patients' },
  { icon: listPlusIcon, label: 'Convidar paciente', route: '/invite_patient' },
  // { icon: settingsIcon, label: 'Configurações', route: '/settings' },
]

function Sidebar(): JSX.Element {
  const navigate = useNavigate()
  const goToHome = () => navigate('/')

  return (
    <Box
      width='260px'
      display='flex'
      flexDirection='column'
      alignItems='center'
      borderRight='1px solid #E8EBF2'
      borderRadius='12px'
      sx={{ paddingTop: '60px' }}
    >
      {' '}
      <img
        src={logoColor}
        height='30px'
        alt='Logo Wida'
        onClick={goToHome}
        style={{ cursor: 'pointer' }}
      />
      <Box display='flex' flexDirection='column' mt={6}>
        {menuOptions.map(({ icon, label, route }) => (
          <Box
            key={label}
            display='flex'
            alignItems='center'
            mb={2}
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate(route)}
          >
            <img src={icon} height='18px' alt={`Ícone de ${label}`} />
            <Box ml={1}>
              <Typography variant='text'>{label}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default Sidebar
