import { Box, MenuItem, Typography } from '@mui/material'
import { timeSince } from 'helpers'
import { Circle } from '@mui/icons-material'

function Notification({ notification, onClickNotification }: any): JSX.Element {
  return (
    <MenuItem onClick={() => onClickNotification(notification)}>
      <Box
        display='flex'
        flexDirection={'row'}
        alignItems='center'
        sx={{
          maxWidth: '350px',
          whiteSpace: 'normal',
          wordBreak: 'break-word'
        }}
      >
        {/* Read ? */}
        {!notification.read && <Circle sx={{ fontSize: '10px', color: '#4B93FF' }} />}

        {/* Texts */}
        <Box flexDirection={'column'} display={'flex'} px={2}>
          <Typography
            sx={{
              fontFamily: 'Zen Kaku Gothic Antique',
              fontSize: '14px',
              color: '#657787'
            }}
          >
            {notification.message}<span style={{ color: '#01B3A7' }}>{notification.payload.patientFullName}</span>.
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Zen Kaku Gothic Antique',
              fontWeight: 500,
              fontSize: '14px',
              color: '#657787',
              marginTop: '10px',
            }}
          >
            Visualizar respostas
          </Typography>
        </Box>

        {/* Time since */}
        <Box
          sx={{ width: '45%' }}
          display='flex'
          flexGrow={1}
          justifyContent='end'
        >
          <Typography
            sx={{
              fontFamily: 'Zen Kaku Gothic Antique',
              fontSize: '12px',
              color: '#BDBDBD',
            }}
          >
            {timeSince(new Date(notification.createdAt))}
          </Typography>
        </Box>
      </Box>
    </MenuItem>
  )
}

export default Notification
