import { Select, Checkbox, MenuItem, Divider, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledSelectMui = styled(Select)<any>({
  color: '#353F48',
  fontSize: '16px',
  border: '1px solid transparent',
  '& .MuiSelect-select': {
    border: '1px solid transparent',
    paddingLeft: '8px',
  },
  '& .Mui-focused': {
    backgroundColor: '#ffffff',
  },
  '& :focus': {
    backgroundColor: '#ffffff',
  },
  '& :hover': {
    backgroundColor: '#F9F9FB',
    border: '1px solid #E6E6E6',
    borderRadius: '8px',
  },
})

export const StyledMenuItem = styled(MenuItem)<any>(({ multiple }) => ({
  height: '34px',
  marginLeft: '8px',
  marginRight: '8px',
  ...(multiple && {
    '&.Mui-selected': {
      backgroundColor: '#ffffff',
    },
  }),
}))

export const DividerOptions = styled(Divider)({
  marginLeft: '8px',
  marginRight: '8px',
  backgroundColor: '#000000',
})

export const OldText = styled(Typography)({
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '10px',
  lineHeight: '10px',
  color: '#353F48',
})

export const EmptyNewText = styled(Typography)({
  fontFamily: 'Zen Kaku Gothic Antique',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '14px',
  color: '#353F48',
})

export const StyledCheckbox = styled(Checkbox)({
  paddingLeft: 0,
})
