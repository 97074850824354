import { useState } from 'react'
import { Stack, Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'

import { Title, Subtitle } from '../create-patient-report-styles'
import { useCreatePatientReportContext } from 'contexts'
import { ButtonClinic, ReportStepByTag } from 'components'
import { useNotification } from 'hooks'
import { areSameArraysOfStrings } from 'helpers'
import { FETCHING, IDLE, SUBMITTING } from 'helpers/constants'
import {
  createPatientReport,
  getPatientReport,
  setPatientReportComplete,
} from 'services'
import { createPatientReportDefaultSteps } from '.'
import { Loading } from 'components/finish-questionnaire/finish-questionnaire-styles'
import GeneratedReport from './generated-report'

function WithClinicalOpinion() {
  const [status, setStatus] = useState(FETCHING)

  const {
    patient,
    selectedFormRequests,
    selectedTags,
    generatedReportParams,
    setActiveStep,
    respondersWithResponses,
    setCreatePatientReport,
    setSteps,
    setGeneratedReportParams,
  } = useCreatePatientReportContext()
  const { patient_id } = useParams()
  const { errorToast } = useNotification()
  const { getValues } = useFormContext()

  const handleForward = async (isComplete?: boolean) => {
    try {
      if (
        !patient_id ||
        !Boolean(selectedFormRequests?.length) ||
        !Boolean(selectedTags?.length)
      ) {
        errorToast('Faltam dados para poder gerar o relatório.')
        return
      }

      const hasToRecreateReport =
        patient_id !== generatedReportParams?.patientId ||
        !areSameArraysOfStrings(
          selectedFormRequests,
          generatedReportParams?.formRequestIds
        ) ||
        !areSameArraysOfStrings(selectedTags, generatedReportParams?.tagIds)

      if (!hasToRecreateReport) {
        setActiveStep((activeStep: number) => activeStep + 1)
        return
      }

      setStatus(SUBMITTING)

      const { responders } = getValues()

      const uniqueResponders = [
        ...new Set(responders.map(({ responder }: any) => responder)),
      ]

      const respondersLabel = uniqueResponders
        .map((responderId: any) => {
          const findResponder = respondersWithResponses.find(
            ({ id }: any) => id === responderId
          )
          return `${findResponder.name.split(' ')[0]} (${
            findResponder.relation
          })`
        })
        .join(', ')

      const createPatientReportBody = {
        patientId: patient_id,
        formRequestIds: selectedFormRequests,
        tagIds: selectedTags,
        respondersLabel: respondersLabel,
        withClinicalOpinion: isComplete,
      }

      const { id: patientReportId } = await createPatientReport(
        createPatientReportBody
      )

      const patientReportComplete = await getPatientReport(patientReportId)

      setCreatePatientReport(patientReportComplete)

      if (isComplete) {
        setSteps([
          ...createPatientReportDefaultSteps,
          ...patientReportComplete.tags.map((tag: any) => ({
            id: `report_by_tag:${tag.id}`,
            component: (
              <ReportStepByTag
                tagId={tag.id}
                patientReport={patientReportComplete}
              />
            ),
          })),
          {
            id: 'generated_report',
            component: <GeneratedReport />,
          },
        ])
      } else {
        setSteps((currentSteps: any) => [
          ...currentSteps,
          {
            id: 'generated_report',
            component: <GeneratedReport />,
          },
        ])
        await setPatientReportComplete(patientReportId)
      }

      setGeneratedReportParams(createPatientReportBody)

      setActiveStep((activeStep: number) => activeStep + 1)
    } catch (error: any) {
      errorToast(error.message)
    } finally {
      setStatus(IDLE)
    }
  }

  return (
    <Stack alignItems='center'>
      <Box maxWidth={780} mt={19}>
        <Title>
          Deseja incluir sua avaliação clínica de {patient.firstName}?
        </Title>
        <Box mt={6} display='flex' gap={3}>
          <ButtonClinic
            variant='outlined'
            color='purple'
            fontWeight='regular'
            size='large'
            width={380}
            onClick={() => handleForward(false)}
          >
            <strong>Não</strong>, continuar sem incluir
          </ButtonClinic>
          <ButtonClinic
            variant='outlined'
            color='purple'
            fontWeight='regular'
            size='large'
            width={380}
            onClick={() => handleForward(true)}
          >
            <strong>Sim</strong>, quero incluir minha avaliação
          </ButtonClinic>
        </Box>
        <Box display='flex' justifyContent='center'>
          <Subtitle mt={6} textAlign='center' width={450}>
            Ao escolher "Sim", você poderá inserir seu julgamento clínico para{' '}
            cada questão no relatório de {patient.firstName}
          </Subtitle>
        </Box>
      </Box>
      {status === SUBMITTING && (
        <Loading sx={{ marginTop: 4, color: '#8D92BE' }} />
      )}
    </Stack>
  )
}

export default WithClinicalOpinion
