import { useEffect, useRef, useState } from 'react'
import { Controller, Control } from 'react-hook-form'
import { ButtonBase } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { DatePickerStyled } from './date-field-styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import brLocale from 'date-fns/locale/pt-BR'
import CalendarIcon from 'components/icons/calendar-icon'
import LeftArrowIcon from 'components/icons/left-arrow-icon'

interface DateFieldStyledProps {
  name: string
  control: Control<any>
  disabled?: boolean
  disablePast?: boolean
  isInsideModal?: boolean
}

function DateFieldStyled({
  name,
  control,
  disabled,
  disablePast,
  isInsideModal,
}: DateFieldStyledProps) {
  const [isPickerOpen, setIsPickerOpen] = useState(false)

  const ref = useRef<any>()

  const sx: React.CSSProperties = {
    border: '1.5px solid #BDC1DD',
    borderRadius: '32px',
    background: '#FDFDFD',
    marginLeft: '-40px',
    marginTop: '8px',
    boxShadow: '0px 2.40445px 19.23559px 0px rgba(170, 170, 170, 0.03)',
  }

  const theme = createTheme({
    typography: {
      fontFamily: "'QuickSand', sans-serif",
    },
    palette: {
      primary: {
        main: '#01B3A7',
        contrastText: '#fff',
      },
    },
  })

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (ref?.current && !ref?.current?.contains(event.target)) {
        setIsPickerOpen(false)
      }
    }

    document.body.addEventListener('click', handleOutsideClick)

    return () => {
      document.body.removeEventListener('click', handleOutsideClick)
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Controller
        control={control}
        name={name}
        render={({
          field: { onChange, value },
          fieldState: { invalid, error },
        }) => (
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={brLocale}
          >
            <DatePickerStyled
              value={value}
              onChange={onChange}
              format='dd MMM yyyy'
              defaultValue={new Date()}
              disablePast={disablePast}
              disabled={disabled}
              open={isPickerOpen}
              onOpen={() => setIsPickerOpen(true)}
              onClose={() => setIsPickerOpen(false)}
              slots={{
                previousIconButton: (props) => (
                  <ButtonBase
                    {...props}
                    style={{ padding: '5px', borderRadius: '50%' }}
                  >
                    <LeftArrowIcon
                      color={props.disabled ? '#C8C8C8' : '#596B7B'}
                    />
                  </ButtonBase>
                ),
                nextIconButton: (props) => (
                  <ButtonBase
                    {...props}
                    style={{
                      marginRight: '8px',
                      marginLeft: '-20px',
                      padding: '5px',
                      borderRadius: '50%',
                    }}
                  >
                    <LeftArrowIcon
                      color={props.disabled ? '#C8C8C8' : '#596B7B'}
                      style={{ transform: 'rotate(180deg)' }}
                    />
                  </ButtonBase>
                ),
                openPickerIcon: () => <CalendarIcon color='#CDCDCD' />,
                switchViewButton: () => <></>,
              }}
              slotProps={{
                desktopPaper: { style: sx },
                popper: { ...(isInsideModal ? { ref } : {}) },
                textField: { placeholder: 'dd MMM yyyy' },
                openPickerButton: { style: { marginRight: '-8px' } },
                calendarHeader: {
                  sx: { textTransform: 'capitalize', marginTop: '12px' },
                },
                day: { style: { fontSize: '15px' } },
              }}
            />
          </LocalizationProvider>
        )}
      />
    </ThemeProvider>
  )
}

export default DateFieldStyled
