import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { answerDict } from 'helpers/constants'

function AnswersTimelineDots({ question }: any): JSX.Element {
  return (
    <Stack direction='row' justifyContent='center' spacing={23}>
      {question.answers.map((answer: any, index: number) => {
        return (
          <Box zIndex={1} key={question.keys[index]} display='flex'>
            {answer !== 'na' ? (
              <img
                src={answerDict[answer].icon}
                height='16px'
                width='16px'
                alt={answerDict[answer].alt}
              />
            ) : (
              <Box width='16px' />
            )}
          </Box>
        )
      })}
    </Stack>
  )
}
export default AnswersTimelineDots
