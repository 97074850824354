import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const UserName = styled(Typography)({
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '24px',
  lineHeight: '31px',
  textAlign: 'center',
  color: '#FFFFFF',
})

export const Text = styled(Typography)({
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '24px',
  lineHeight: '31px',
  textAlign: 'center',
  color: '#FFFFFF',
})

export const SecondaryText = styled(Typography)({
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '24px',
  lineHeight: '31px',
  textAlign: 'center',
  color: '#A8ACCF',
})

export const sxButton = {
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '21px',
  color: '#657787',
}

export const secondaryButton = {
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '21px',
  color: '#FFFFFF',
  backgroundColor: '#A8ACCF',
  '&:hover': {
    backgroundColor: '#A8ACCF',
  },
}
