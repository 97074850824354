import { Path, Svg } from '@react-pdf/renderer'

interface SosoIconProps {
  size?: number
}

function SosoIcon({ size = 9 }: SosoIconProps) {
  return (
    <Svg width={size} height={size} viewBox='0 0 0.16 0.16'>
      <Path
        fill='#000000'
        fillRule='evenodd'
        d='M0.08 0a0.08 0.08 0 1 0 0 0.16A0.08 0.08 0 0 0 0.08 0zm0 0.015v0.13a0.065 0.065 0 1 0 0 -0.13z'
      />
    </Svg>
  )
}

export default SosoIcon
