import { SidebarContainer, FirstName, LastName } from './sidebar-styles'
import PatientTags from '../patient-tags/patient-tags'
import PatientAttentions from '../patient-attentions/patient-attentions'

const Sidebar = ({ patient, size }: any) => {
  return (
    <SidebarContainer size={size}>
      <FirstName>{patient?.firstName}</FirstName>
      <LastName>{patient?.lastName}</LastName>
      <PatientTags patientId={patient?.patientId} patientAge={patient?.age} />
      <PatientAttentions />
    </SidebarContainer>
  )
}

export default Sidebar
