import { useForm } from 'react-hook-form'
import { clinicProfileMyInfoPasswordResolver } from 'resolvers'

function useMyInfoPasswordSectionForm() {
  const methods = useForm<any>({
    mode: 'onBlur',
    resolver: clinicProfileMyInfoPasswordResolver,
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  })

  return methods
}

export default useMyInfoPasswordSectionForm
