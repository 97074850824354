import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { SubmitHandler } from 'react-hook-form'
import jwtDecode from 'jwt-decode'
import { Box, Stack } from '@mui/material'
import { LogoLayout, TextFieldClinic, ButtonClinic } from 'components'
import { useNotification, useQuery } from 'hooks'
import { resetPassword } from 'services'
import logoColor from 'images/wida-logo-color.svg'
import useFormForgotPassword from './forgot-password-form'
import { IFormInput } from './forgot-password-types'
import { TitleText, TooltipPasswordText } from './forgot-password-styles'

function ForgotPassword(): JSX.Element {
  const { control, handleSubmit } = useFormForgotPassword()
  const { successToast, errorToast } = useNotification()
  const query = useQuery()
  const navigation = useNavigate()

  const { type }: any = jwtDecode(query.get('token')!)

  useEffect(() => {
    if (type === 'patient') {
      navigation(`/forgot_password/patient?token=${query.get('token')}`)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  const onSubmit: SubmitHandler<IFormInput> = async (params) => {
    try {
      const token = query.get('token')

      if (!token)
        throw new Error(
          'Ocorreu um erro ao tentar recuperar a senha, por favor tente novamente.'
        )

      await resetPassword(token, {
        password: params.password,
      })

      successToast('Senha redefinida com sucesso!')
      navigation(`/signin/${type}`)
    } catch (error: any) {
      console.error(error)
      errorToast(error.message)
    }
  }

  return (
    <LogoLayout textType='signin'>
      <Box
        px={{ xs: 3, sm: 14, lg: 8 }}
        pt={{ xs: 7.5, sm: 14, lg: 7.5 }}
        pb={{ xs: 4, sm: 11, lg: 8 }}
        height='100%'
        display='flex'
        flexDirection='column'
        component='form'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          width='100%'
          mt={{ xs: 5, sm: 10, lg: 5 }}
          mb={{ xs: 6, sm: 14, lg: 11.5 }}
          display='flex'
          justifyContent='center'
        >
          <img src={logoColor} alt='Wida Logo' height={38} />
        </Box>

        <Box
          display='flex'
          flexDirection='column'
          justifyContent={{ xs: 'space-between', sm: 'flex-start' }}
          alignItems='center'
          flexGrow={1}
          width='100%'
        >
          <Box>
            <TitleText>Nova senha</TitleText>
            <Box
              mt={{ xs: 3, sm: 4 }}
              mb={4}
              width={{ xs: '224px', sm: '100%', lg: '280px' }}
            >
              <TextFieldClinic
                type='password'
                name='password'
                control={control}
                label='Digite a nova senha'
                fieldInfo={
                  <Stack>
                    <TooltipPasswordText>
                      Sua senha deve conter:
                    </TooltipPasswordText>
                    <TooltipPasswordText>
                      - no mínimo 8 caracteres
                    </TooltipPasswordText>
                    <TooltipPasswordText>
                      - pelo menos uma letra maiúscula
                    </TooltipPasswordText>
                    <TooltipPasswordText>
                      - um ou mais números ou símbolos
                    </TooltipPasswordText>
                  </Stack>
                }
              />
            </Box>
            <Box width={{ xs: '224px', sm: '100%', lg: '280px' }}>
              <TextFieldClinic
                type='password'
                name='confirmPassword'
                control={control}
                label='Digite a senha novamente'
              />
            </Box>
          </Box>

          <Box
            display='flex'
            justifyContent='center'
            justifySelf='flex-end'
            alignItems='flex-end'
            mt={8}
          >
            <Box>
              <ButtonClinic
                width={150}
                variant='outlined'
                size='small'
                color='purple'
                fontWeight='bold'
                type='submit'
              >
                Confirmar
              </ButtonClinic>
            </Box>
          </Box>
        </Box>
      </Box>
    </LogoLayout>
  )
}

export default ForgotPassword
