import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Title = styled(Typography)`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #657787;
`

export const Subtitle = styled(Typography)`
  font-family: 'Zen Kaku Gothic Antique';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #657787;
`
