import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import { Box, Stack, Divider } from '@mui/material'
import { RouteLayout, ButtonClean } from 'components'
import { checkInviteIsValid } from 'services'
import { useNotification, useQuery } from 'hooks'
import { registerPageAccess, registerTrack } from 'helpers'
import { HeaderTitle, HeaderDescription, Title } from './sign-up-styles'
import logoBW from 'images/wida-logo.svg'

function SignUp(): JSX.Element {
  const [invitationInfos, setInvitationInfos] = useState<any>({
    patientName: '',
    clinicName: '',
  })

  const navigate = useNavigate()
  const query = useQuery()
  const { warnToast } = useNotification()

  const invitationToken = query.get('invitationToken')
  const userInfos: any = jwtDecode(invitationToken!)

  useEffect(() => {
    const validateInvite = async () => {
      try {
        const { isActive } = await checkInviteIsValid(invitationToken!)
        if (!isActive) navigate('/expired_invitation')
      } catch (error: any) {
        console.error(error?.message)
        navigate('/')
      }
    }

    if (!invitationToken) {
      warnToast('Você precisa de um convite para acessar essa página')
      navigate('/signin/patient')
    }

    validateInvite()

    const userInfos: any = jwtDecode(invitationToken!)
    const names = userInfos?.name?.split(' ')

    setInvitationInfos({
      patientName: names?.shift() || '',
      clinicName: userInfos?.createdBy?.name || '',
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    registerPageAccess('Cadastro Paciente', { patient_name: userInfos.name })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleGoToPatient = () => {
    registerTrack('Clica Paciente', { patient_name: userInfos.name })
    navigate(`patient?invitationToken=${query.get('invitationToken')}`)
  }

  const handleGoToCaregiver = () => {
    registerTrack('Clica Responsavel', { patient_name: userInfos.name })
    navigate(`caregiver?invitationToken=${query.get('invitationToken')}`)
  }

  return (
    <RouteLayout bg='gradient'>
      <Stack
        display='flex'
        alignItems='center'
        justifyContent='center'
        mt={10}
        mb={3}
        px={5}
      >
        <img
          src={logoBW}
          height='30px'
          alt='Logo Wida'
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        />
        <Stack spacing={1} mt={7}>
          <HeaderTitle>Bem-vindo ao Wida</HeaderTitle>
          <HeaderDescription>
            Você foi convidado por {invitationInfos.clinicName} para criar uma
            conta no Wida.
          </HeaderDescription>
        </Stack>
      </Stack>

      <Box
        flexGrow={1}
        display='flex'
        flexDirection='column'
        alignItems='flex-start'
        justifyContent='space-between'
        px={5}
        pt={5}
        pb={10}
        sx={{
          backgroundColor: '#ffffff',
          borderRadius: '28px 28px 0px 0px',
        }}
      >
        <Box>
          <Title>Quem é você?</Title>
        </Box>
        <Stack spacing={2} width='100%'>
          <ButtonClean
            label={`Sou ${invitationInfos.patientName}\n (paciente)`}
            onClick={handleGoToPatient}
            icon='call-made'
            textColor='#F36F60'
            hideDivider
          />
          <Divider sx={{ fill: '#CCCCCC' }} />
          <ButtonClean
            label={`Sou responsável pelo\n paciente ${invitationInfos.patientName}`}
            onClick={handleGoToCaregiver}
            icon='call-made'
            textColor='#F36F60'
            hideDivider
          />
        </Stack>
      </Box>
    </RouteLayout>
  )
}

export default SignUp
