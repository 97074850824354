import { useEffect, useState, KeyboardEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormProvider, SubmitHandler } from 'react-hook-form'
import { Box, Stack } from '@mui/material'
import {
  RouteLayout,
  Button,
  HeaderMenuPatientGroup,
  SendInformantInvite,
} from 'components'
import { createInformantInvite } from 'services'
import { useNotification, useQuery } from 'hooks'
import useCreateInviteForm from './use-invite-new-informant-form'
import {
  HeaderTitle,
  HeaderDescription,
  Title,
} from './invite-new-informant-styles'
import { InputField } from 'components/inputs'
import { useAuthContext } from 'contexts'

function InviteNewInformant(): JSX.Element {
  const [selectedInvite, setSelectedInvite] = useState<any>()
  const [sending, setSending] = useState<boolean>(false)

  const navigate = useNavigate()
  const { errorToast, warnToast } = useNotification()
  const query = useQuery()
  const methods = useCreateInviteForm()
  const { handleSubmit, control } = methods
  const { userInfos } = useAuthContext()

  const formId = query.get('form_id')

  useEffect(() => {
    if (!formId) {
      warnToast(
        'Você ainda não tem um questionário válido para convidar outras pessoas'
      )
      navigate('/')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkKeyDown = (e: KeyboardEvent) => {
    if (e.code !== 'Enter') return
    e.preventDefault()
    handleSubmit(onSubmit)()
  }

  const onSubmit: SubmitHandler<any> = async (params) => {
    try {
      setSending(true)
      const body: any = {
        name: params.name,
        formId: formId,
        startDate: new Date(),
      }

      const { invitationToken, invitationCode }: any =
        await createInformantInvite(body)

      if (!invitationToken || !invitationCode)
        throw new Error('Ocorreu um erro ao criar o convite')

      const inviteInfos = {
        token: invitationToken,
        name: params.name,
        code: invitationCode,
      }

      setSelectedInvite(inviteInfos)
    } catch (error: any) {
      console.error(error)
      errorToast(error.message || 'Erro desconhecido')
      setSelectedInvite(null)
    } finally {
      setSending(false)
    }
  }

  return (
    <FormProvider {...methods}>
      <RouteLayout bg='gradient'>
        <Box mb={4} display='flex' flexDirection='column'>
          <HeaderMenuPatientGroup />
          <Stack spacing={2} px={3}>
            <HeaderTitle>Convidados</HeaderTitle>
            <HeaderDescription>
              Quer convidar outras pessoas para responder também? Compartilhe as
              perguntas com quem conhece bem{' '}
              {userInfos?.patient?.personalInfo?.firstName || 'você'}.
            </HeaderDescription>
          </Stack>
        </Box>
        <Box
          flexGrow={1}
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='space-between'
          pt={5}
          sx={{
            backgroundColor: '#ffffff',
            borderRadius: '28px 28px 0px 0px',
          }}
          component='form'
          onKeyDown={checkKeyDown}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Stack spacing={2} px={2} width='100%'>
            <Title>Novo convidado</Title>
            <InputField
              name='name'
              control={control}
              label='Qual o nome do seu convidado?'
              disabled={Boolean(selectedInvite)}
            />
          </Stack>
          {Boolean(selectedInvite) ? (
            <SendInformantInvite informantInvite={selectedInvite} />
          ) : (
            <Stack width='100%' mb={8} spacing={2} px={2}>
              <Button type='submit' disabled={sending}>
                Criar convite
              </Button>
              <Button
                variant='text'
                type='button'
                onClick={() => navigate('/')}
              >
                Voltar
              </Button>
            </Stack>
          )}
        </Box>
      </RouteLayout>
    </FormProvider>
  )
}

export default InviteNewInformant
