import { useForm } from 'react-hook-form'
import { editNotificationsResolver } from 'resolvers'

export default function useSettingsNotificationsForm() {
  return useForm({
    resolver: editNotificationsResolver,
    defaultValues: {
      email: '',
      emailValidationCode: '',
      enableEmailNotification: false,
      whatsapp: '',
      whatsappValidationCode: '',
      enableWhatsappNotification: false,
      hasNewEmailValidationCode: false,
      hasNewWhatsappValidationCode: false,
    },
  })
}
