import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

export const Text = styled(Typography)(({ theme }) => ({
  color: '#596B7B',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  [theme.breakpoints.up('sm')]: {
    fontSize: '14px'
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '15px'
  }
}))
