import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import { LinearProgressStyled } from './progress-bar-styles'
import { Stack } from '@mui/material'

interface IProgressBar {
  activeStep: number
  totalSteps: number
  handleBack?: () => void
  hasHandleBackButton?: boolean
}

function ProgressBar({
  totalSteps,
  activeStep,
  handleBack = () => {},
  hasHandleBackButton = false,
}: IProgressBar): JSX.Element {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    setProgress((activeStep / totalSteps) * 100)
  }, [activeStep, totalSteps])

  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='center'
      height='30px'
    >
      {hasHandleBackButton && (
        <IconButton aria-label='voltar' onClick={handleBack}>
          <ArrowBackIosNewRoundedIcon
            sx={{ height: '18px', width: '18px', fill: '#8D92BE' }}
          />
        </IconButton>
      )}

      <Box width='100%' ml={hasHandleBackButton ? 2 : 0}>
        <LinearProgressStyled variant='determinate' value={progress} />
      </Box>
    </Stack>
  )
}

export default ProgressBar
