import { Stack, Box } from '@mui/material'
import { InputField, SelectField } from 'components/inputs'
import { IPersonalInfoFields } from './personal-info-fields-types'
import { TitleText } from './personal-info-fields-styles'

function CaregiverPersonalInfoFields({
  control,
}: IPersonalInfoFields): JSX.Element {
  return (
    <Stack width='100%'>
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        height='20px'
        mb={3}
      >
        <TitleText>Dados pessoais</TitleText>
      </Box>
      <Stack spacing={2}>
        <InputField
          name='caregiverFirstName'
          control={control}
          label='Seu primeiro nome'
        />
        <InputField
          name='caregiverLastName'
          control={control}
          label='Seu sobrenome'
        />
      </Stack>
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        height='20px'
        my={3}
      >
        <TitleText>Dados do paciente</TitleText>
      </Box>
      <Stack spacing={2}>
        <InputField name='firstName' control={control} label='Primeiro nome' />
        <InputField name='lastName' control={control} label='Sobrenome' />

        <InputField
          mask='00/00/0000'
          name='birthdate'
          control={control}
          label='Data de Nascimento'
        />

        <SelectField
          label='Como se identifica?'
          name='pronoun'
          control={control}
          options={[
            { value: 'he', label: 'Ele' },
            { value: 'she', label: 'Ela' },
          ]}
        />
      </Stack>
    </Stack>
  )
}

export default CaregiverPersonalInfoFields
