import { useEffect, useState } from 'react'
import { format } from 'date-fns'
import brLocale from 'date-fns/locale/pt-BR'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  IconButton,
  Tooltip,
  CircularProgress,
} from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import {
  ButtonClinic,
  PatientReportsEmpty,
  PatientReportsTableHeader,
} from 'components'
import { getAllPatientReports } from 'services'
import { usePatientCentralContext } from 'contexts'
import { useNotification } from 'hooks'
import { DescriptionText, PatientTableRow } from './patient-reports-styles'
import DownloadIcon from 'components/icons/download-icon'
import { Order, Data, getComparator } from './patient-reports-helpers'
import { FETCHING, IDLE } from 'helpers/constants'
import { Loading } from 'components/finish-questionnaire/finish-questionnaire-styles'
import { handleDownloadPdf } from './pdf-document/helpers/download-pdf'

function PatientReports(): JSX.Element {
  const [status, setStatus] = useState(FETCHING)
  const [loadingPatientReport, setLoadingPatientReport] = useState<
    string | boolean
  >(false)
  const [patientReports, setPatientReports] = useState<any>([])
  const [orderBy, setOrderBy] = useState<keyof Data>('createdAt')
  const [order, setOrder] = useState<Order>('asc')

  const { patient_id } = useParams()
  const navigate = useNavigate()
  const { errorToast } = useNotification()

  useEffect(() => {
    const fetchPatientReports = async () => {
      try {
        setStatus(FETCHING)
        const patientReports = await getAllPatientReports(patient_id as string)

        setPatientReports(patientReports)
      } catch (error) {
        errorToast('Ocorreu um erro ao buscar os relatórios do paciente.')
      } finally {
        setStatus(IDLE)
      }
    }

    fetchPatientReports()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_id])

  const { patient } = usePatientCentralContext()

  const handleVisualizeReport = (patientReport: any) => {
    navigate(`/patients/${patient_id}/reports/${patientReport.id}`)
  }

  const handleDownload = async (patientReportId: string) => {
    try {
      setLoadingPatientReport(patientReportId)
      handleDownloadPdf(patientReportId)
    } catch (error: any) {
      errorToast('Erro ao baixar o relatório')
    } finally {
      setLoadingPatientReport(false)
    }
  }

  return (
    <Box flexGrow={1}>
      <Stack
        direction='row'
        width='100%'
        justifyContent='space-between'
        alignItems='center'
      >
        <Box>
          <Box display={Boolean(patientReports.length) ? 'default' : 'none'}>
            <DescriptionText>
              Abaixo estão os Relatórios Wida sobre {patient?.firstName} gerados
              até o momento.
            </DescriptionText>
            <DescriptionText>
              Para criar um novo, clique em "Gerar relatório".
            </DescriptionText>
          </Box>
        </Box>

        <ButtonClinic
          height={37}
          variant='outlined'
          color='purple'
          fontWeight='bold'
          size='small'
          icon='report'
          iconPosition='start'
          onClick={() => {
            navigate(`/patients/${patient_id}/create_report`)
          }}
        >
          Gerar relatório
        </ButtonClinic>
      </Stack>

      {status === FETCHING ? (
        <Box
          display='flex'
          width='100%'
          height='100%'
          alignItems='center'
          justifyContent='center'
        >
          <CircularProgress />
        </Box>
      ) : !Boolean(patientReports.length) ? (
        <PatientReportsEmpty />
      ) : (
        <TableContainer
          component={Paper}
          sx={{ marginTop: '16px', boxShadow: 'none' }}
        >
          <Table aria-label='tabela de pacientes'>
            <PatientReportsTableHeader
              headerFields={[
                { id: 'createdAt', label: 'Criado em', order: true },
                { id: 'respondersLabel', label: 'Respondedores' },
                { id: 'actions', label: 'Ações' },
              ]}
              orderBy={orderBy}
              order={order}
              setOrderBy={setOrderBy}
              setOrder={setOrder}
            />
            <TableBody>
              {patientReports
                .sort(getComparator(order, orderBy))
                .map((patientReport: any) => {
                  return (
                    <PatientTableRow key={patientReport.id}>
                      <TableCell width={229}>
                        {format(
                          new Date(patientReport.createdAt),
                          'dd MMM yy',
                          {
                            locale: brLocale,
                          }
                        )}
                      </TableCell>

                      <TableCell>{patientReport.respondersLabel}</TableCell>

                      <TableCell width={111}>
                        <Stack direction='row'>
                          <Tooltip title='Download' arrow placement='top'>
                            <IconButton
                              onClick={() => handleDownload(patientReport.id)}
                              sx={{ width: 40 }}
                            >
                              {loadingPatientReport === patientReport.id ? (
                                <Loading size={16} sx={{ color: '#8D92BE' }} />
                              ) : (
                                <DownloadIcon />
                              )}
                            </IconButton>
                          </Tooltip>

                          <Tooltip title='Visualizar' arrow placement='top'>
                            <IconButton
                              onClick={() =>
                                handleVisualizeReport(patientReport)
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      </TableCell>
                    </PatientTableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  )
}

export default PatientReports
