import { Text, View } from '@react-pdf/renderer'
import AnswersOptions from '../answers-options/answers-options'
import PageCounter from '../page-counter/page-counter'
import { styles } from './footer-styles'
import CopyRightIcon from '../../icons/copyright-icon/coypright-icon'

interface FooterProps {
  showAnswers?: boolean
}

function Footer({ showAnswers }: FooterProps) {
  return (
    <View fixed style={styles.footer}>
      <View style={styles.copyrightView}>
        <CopyRightIcon size={7} />
        <Text style={styles.copyrightText}> Wida 2024</Text>
      </View>
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: !showAnswers ? 'flex-end' : 'center',
          alignItems: 'center',
          gap: 24,
          marginLeft: 24,
          marginRight: 18,
        }}
      >
        {showAnswers && <AnswersOptions />}
        {<PageCounter />}
      </View>
    </View>
  )
}

export default Footer
