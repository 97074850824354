import { StepIconProps } from '@mui/material/StepIcon'
import { useStepperContext } from 'contexts'
import { FirstStepIconRoot } from './sign-up-stepper-styles'

function FirstStepIcon({ className }: StepIconProps) {
  const { firstStepWidth } = useStepperContext()

  return (
    <FirstStepIconRoot className={className} firstStepWidth={firstStepWidth}>
      <div className='StepIcon-completed' />
    </FirstStepIconRoot>
  )
}

export default FirstStepIcon
