import { useEffect, useState } from 'react'
import { Grid, Box, Stack } from '@mui/material'
import { TextFieldClinic, SwitchIos } from 'components'

import { useClinicContext } from 'contexts'
import { updateUserInfos } from 'services'
import { useNotification } from 'hooks'
import { sanitizeString } from 'helpers'
import useContactsTabForm from './contacts-tab-form'
import ValidationCodeModal from './validation-code-modal/validation-code-modal'
import { Container, Section } from '../clinic-profile-tabs-styles'
import { SwitchText, ContactInfos, ErrorMessage } from './contacts-tab-styles'

function ContactsTab(): JSX.Element {
  const { clinic, fetchClinic, isEdit, handleSave, hasUnsavedChanges } =
    useClinicContext()

  const [emailValidationCode, setEmailValidationCode] = useState(null)
  const [emailModalOpen, setEmailModalOpen] = useState(false)
  const [validatedEmail, setValidatedEmail] = useState<string>(
    clinic?.user?.communication?.includes('email') ? clinic.user.email : ''
  )
  const [whatsappValidationCode, setWhatsappValidationCode] = useState(null)
  const [whatsappModalOpen, setWhatsappModalOpen] = useState(false)
  const [validatedWhatsapp, setValidatedWhatsapp] = useState<string>(
    clinic?.user?.communication?.includes('whatsapp')
      ? clinic.user.whatsapp
      : ''
  )

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isDirty },
    getValues,
    trigger,
  } = useContactsTabForm({ clinic })
  const { errorToast } = useNotification()

  const [email, communicationEmail] = watch(['email', 'communication.email'])
  const [whatsapp, communicationWhatsapp] = watch([
    'telephone',
    'communication.whatsapp',
  ])

  useEffect(() => {
    if (email !== validatedEmail && communicationEmail) {
      setEmailModalOpen(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communicationEmail])

  useEffect(() => {
    if (
      sanitizeString(whatsapp) !== validatedWhatsapp &&
      communicationWhatsapp
    ) {
      setWhatsappModalOpen(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communicationWhatsapp])

  useEffect(() => {
    if (isDirty) hasUnsavedChanges.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty])

  const onSubmit = async () => {
    try {
      const params = getValues()

      const body = {
        ...(Boolean(whatsappValidationCode) && {
          ddi: '55',
          whatsapp: sanitizeString(params.telephone),
          whatsappValidationCode,
        }),
        ...(Boolean(emailValidationCode) && {
          email: params.email.toLowerCase(),
          emailValidationCode,
        }),
        communication: Object.entries(params.communication).reduce(
          (acc: any, [key, value]: any) => (value ? [...acc, key] : acc),
          []
        ),
      }

      await updateUserInfos(body)
      fetchClinic()
      return true
    } catch (error: any) {
      errorToast(error?.message)
      return false
    }
  }

  useEffect(() => {
    handleSave.current = async () => {
      const validate = await trigger()

      if (!validate) return false

      return onSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit, trigger])

  const handleEmailBlur = () => {
    setValue('communication.email', Boolean(email) && email === validatedEmail)
  }

  const handleWhatsappBlur = () => {
    setValue(
      'communication.whatsapp',
      Boolean(whatsapp) && whatsapp === validatedWhatsapp
    )
  }

  return (
    <Container>
      <Section component='form' onSubmit={handleSubmit(onSubmit)}>
        <Stack mb={6}>
          <ContactInfos>
            Mantenha seus dados atualizados. Você pode escolher como prefere
            receber notificações do sistema.
          </ContactInfos>
          <ContactInfos>
            Por motivos de segurança, é necessário verificar as informações
            antes de mandarmos mensagens.
          </ContactInfos>
        </Stack>
        <Grid
          container
          width={{ xs: '100%', sm: '100%', md: '600px', lg: '800px' }}
          columnSpacing={6}
        >
          <Grid item xs={12} md={6} mb={2}>
            <Stack spacing={2} alignItems='flex-start'>
              <TextFieldClinic
                disabled={!isEdit}
                mask='(00) 00000-0000'
                name='telephone'
                control={control}
                label='Telefone celular'
                onBlur={handleWhatsappBlur}
              />
              <Stack direction='row' alignItems='center' spacing={1}>
                <SwitchIos
                  name='communication.whatsapp'
                  control={control}
                  disabled={!isEdit || !whatsapp}
                />
                <SwitchText>Receber notificações por WhatsApp</SwitchText>
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12} md={6} mb={2}>
            <Stack spacing={2} alignItems='flex-start'>
              <TextFieldClinic
                disabled={!isEdit}
                name='email'
                control={control}
                label='E-mail'
                invalidChars={[' ']}
                onBlur={handleEmailBlur}
              />
              <Stack direction='row' alignItems='center' spacing={1}>
                <SwitchIos
                  name='communication.email'
                  control={control}
                  disabled={!isEdit || !email}
                />
                <SwitchText>Receber notificações por E-mail</SwitchText>
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        {isEdit && (
          <Box display='flex' flexDirection='column'>
            {errors?.communication && (
              <ErrorMessage>
                {errors?.communication?.email?.message}
              </ErrorMessage>
            )}
          </Box>
        )}
      </Section>

      {emailModalOpen && (
        <ValidationCodeModal
          isOpen={emailModalOpen}
          type='email'
          contact={email}
          handleClose={() => {
            setValue('communication.email', false)
            setEmailModalOpen(false)
          }}
          handleConfirm={(code: any) => {
            setEmailValidationCode(code)
            setValidatedEmail(email)
            setEmailModalOpen(false)
          }}
        />
      )}

      {whatsappModalOpen && (
        <ValidationCodeModal
          isOpen={whatsappModalOpen}
          type='whatsapp'
          contact={whatsapp}
          handleClose={() => {
            setValue('communication.whatsapp', false)
            setWhatsappModalOpen(false)
          }}
          handleConfirm={(code: any) => {
            setWhatsappValidationCode(code)
            setValidatedWhatsapp(sanitizeString(whatsapp))
            setWhatsappModalOpen(false)
          }}
        />
      )}
    </Container>
  )
}

export default ContactsTab
