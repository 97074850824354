import { isBefore, subYears, parse, isValid } from 'date-fns'
import { sanitizeString } from 'helpers'
import { checkValidationCode, checkCpfUsed } from 'services'
import { ptBR } from 'date-fns/locale'

export async function resolveEmailValidationCode(val: any, context: any) {
  if (val.length < 6) return false

  try {
    await checkValidationCode({
      code: val,
      type: 'email',
      contact: context.parent.email.toLowerCase(),
    })
    return true
  } catch (error) {
    return false
  }
}

export async function resolveWhatsappValidationCode(val: any, context: any) {
  if (val.length < 6) return false

  try {
    await checkValidationCode({
      code: val,
      type: 'whatsapp',
      contact: '55' + sanitizeString(context.parent.whatsapp),
    })
    return true
  } catch (error) {
    return false
  }
}

export async function checkCpfPatientGroup(val: any) {
  if (val.length !== 14) return false
  try {
    await checkCpfUsed({
      cpf: sanitizeString(val),
      role: 'patient',
    })
    return true
  } catch (error) {
    return false
  }
}

export const testStrongPassword = (value: any) =>
  /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(value)

export function checkValidBirthdateForUser(date: any) {
  if (date.length < 10) return false

  const parsedDate = parse(date, 'P', new Date(), { locale: ptBR })
  const isValidDate = isValid(parsedDate)

  if (!isValidDate) return false

  const oneYearBefore = subYears(new Date(), 1)

  return isBefore(parsedDate, oneYearBefore)
}

export function checkValidBirthdateForDependent(date: any) {
  if (date.length < 10) return false

  const parsedDate = parse(date, 'P', new Date(), { locale: ptBR })
  const isValidDate = isValid(parsedDate)

  if (!isValidDate) return false

  const today = new Date()

  return isBefore(parsedDate, today)
}
