import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Stack, IconButton, Tooltip, Box } from '@mui/material'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import InsertChartOutlinedRoundedIcon from '@mui/icons-material/InsertChartOutlinedRounded'
import SendIcon from '@mui/icons-material/Send'
import { useAuthContext } from 'contexts'
import EventRepeatIcon from '@mui/icons-material/EventRepeat'
import { SelectStyled, DateFieldStyled, ButtonClinic } from 'components'
import {
  getForm,
  updateViewedFormRequest,
  updateFormRequestFrequency,
  inactivateFormRequestFrequency,
} from 'services'
import { parseDateToUTC, registerPageAccess } from 'helpers'
import { frequencyOptions } from 'helpers/constants'
import { SecondaryConfirmationModal } from 'components/index'
import { useForm } from 'react-hook-form'
import {
  CancelTextStyled,
  TextStyled,
  TitleStyled,
} from './patient-table-actions'
import { isPast, isToday } from 'date-fns'
import { useNotification } from 'hooks'

function PatientsTableActions({
  lastFormId,
  patientName,
  patientId,
  caregiverName,
  formRequestFrequency,
  fetchPatients,
  userId,
  hasResults,
  viewed,
  formRequestId,
  responseDate,
  isArchived,
}: any): JSX.Element {
  const [isFrequencyModalOpen, setIsFrequencyModalOpen] = useState(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)

  const { user } = useAuthContext()
  const navigate = useNavigate()
  const { control, watch, setValue, getValues } = useForm()
  const { errorToast } = useNotification()

  const handlePatientRecords = async () => {
    if (responseDate && !viewed) {
      await updateViewedFormRequest(formRequestId)
    }

    registerPageAccess('Patient Records', {
      clinic_id: user.clinicId,
      patient_id: patientId,
    })

    navigate(`${patientId}/patient_records`)
  }

  const handleShowResults = async () => {
    if (responseDate && !viewed) {
      await updateViewedFormRequest(formRequestId)
    }

    registerPageAccess('Resultados', {
      clinic_id: user.clinicId,
      patient_id: patientId,
    })

    navigate(
      `/patients/${patientId}/results?last_form_id=${lastFormId}&patient_name=${patientName}&patient_id=${patientId}&user_id=${userId}`
    )
  }

  const handleNewQuestionnaire = () => {
    registerPageAccess('Novo Questionario', {
      clinic_id: user.clinicId,
      patient_id: patientId,
    })

    navigate(
      `/resend_patient?last_form_id=${lastFormId}&patient_name=${patientName}&patient_id=${patientId}&user_id=${userId}${
        caregiverName ? `&caregiver_name=${caregiverName}` : ''
      }`
    )
  }

  const handleVerifyArchivedPatient = () => {
    if (isArchived) {
      setIsConfirmationModalOpen(true)
    } else {
      handleNewQuestionnaire()
    }
  }

  const handleOpenChangeFrequencyModal = async () => {
    try {
      const form = await getForm(lastFormId)

      if (!form) throw new Error('Form not found')

      setValue(
        'frequency',
        frequencyOptions.find(
          (item) => item.value === formRequestFrequency.frequencyName
        )?.value
      )

      setIsFrequencyModalOpen(true)
    } catch (error) {
      console.error(error)
    }
  }

  const handleChangeFrequency = async () => {
    const startDate = getValues('startDate')
      ? new Date(getValues('startDate'))
      : new Date()

    if (!isToday(startDate) && isPast(startDate)) {
      errorToast('A data de próximo envio não deve ser anterior à data atual')
      return
    }

    try {
      if (getValues('frequency') === 'cancel') {
        await inactivateFormRequestFrequency(formRequestFrequency.id)
      } else {
        await updateFormRequestFrequency(
          formRequestFrequency.id,
          getValues('frequency'),
          lastFormId,
          userId,
          parseDateToUTC(startDate)
        )
      }

      setIsFrequencyModalOpen(false)

      await fetchPatients()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Stack direction='row' spacing={1} justifyContent='space-evenly'>
      <Tooltip title={'Visualizar prontuário'} placement='top' arrow>
        <IconButton
          aria-label='Visualizar prontuário'
          component='span'
          sx={{ color: '#657787' }}
          onClick={handlePatientRecords}
        >
          <AssignmentOutlinedIcon fontSize='small' />
        </IconButton>
      </Tooltip>

      <Tooltip
        title={
          hasResults
            ? 'Visualizar resultados'
            : 'Ainda não há resultados para este paciente'
        }
        placement='top'
        arrow
      >
        <span>
          <IconButton
            aria-label='Visualizar resultados'
            component='span'
            sx={{ color: '#657787' }}
            onClick={handleShowResults}
            disabled={!hasResults}
          >
            <InsertChartOutlinedRoundedIcon fontSize='small' />
          </IconButton>
        </span>
      </Tooltip>

      <Tooltip
        title={
          !formRequestFrequency?.isActive
            ? 'Recorrência não ativada'
            : 'Alterar recorrência'
        }
        placement='top'
        arrow
      >
        <span>
          <IconButton
            aria-label='Alterar recorrência'
            component='span'
            sx={{ color: '#657787' }}
            onClick={handleOpenChangeFrequencyModal}
            disabled={!formRequestFrequency?.isActive}
          >
            <EventRepeatIcon fontSize='small' />
          </IconButton>
        </span>
      </Tooltip>

      <Tooltip title={'Criar novo questionário'} placement='top' arrow>
        <IconButton
          aria-label='Criar novo questionário'
          component='span'
          sx={{ color: '#657787' }}
          onClick={handleVerifyArchivedPatient}
        >
          <SendIcon fontSize='small' />
        </IconButton>
      </Tooltip>

      <SecondaryConfirmationModal
        isOpen={isFrequencyModalOpen}
        title='Alterar recorrência'
        content={
          <>
            <TextStyled mb={1}>
              Com que frequência você gostaria de enviar este questionário?
            </TextStyled>
            <Box width={274}>
              <SelectStyled
                control={control}
                name='frequency'
                options={frequencyOptions.map((item) =>
                  item.value === 'once'
                    ? {
                        value: 'cancel',
                        label: 'cancelar recorrência',
                      }
                    : item
                )}
              />
            </Box>
            {watch('frequency') === 'cancel' ? (
              <>
                <TitleStyled mt={3} mb={0.5}>
                  Atenção!
                </TitleStyled>
                <CancelTextStyled textAlign='center' mb={1}>
                  Ao cancelar a recorrência, o envio automático de <br />{' '}
                  questionários para este paciente será suspenso.
                </CancelTextStyled>
              </>
            ) : (
              <>
                <TextStyled mt={3} mb={1}>
                  Qual será a data do próximo envio?
                </TextStyled>
                <Box width={241}>
                  <DateFieldStyled
                    control={control}
                    name='startDate'
                    disablePast
                    isInsideModal
                  />
                </Box>
              </>
            )}
            <Box mt={4} mb={3}>
              <ButtonClinic
                variant='contained'
                color='purple'
                size='small'
                fontWeight='bold'
                width={140}
                onClick={handleChangeFrequency}
              >
                Confirmar
              </ButtonClinic>
            </Box>
          </>
        }
        onClose={() => setIsFrequencyModalOpen(false)}
        hideButtons
      />

      <SecondaryConfirmationModal
        isOpen={isConfirmationModalOpen}
        title='Deseja desarquivar o paciente?'
        text='O paciente será desarquivado 
        após o envio do questionário'
        onClose={() => setIsConfirmationModalOpen(false)}
        onConfirm={handleNewQuestionnaire}
      />
    </Stack>
  )
}

export default PatientsTableActions
