import { Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { InputField } from 'components/inputs'
import { Title, InfoText } from '../styles/general-steps-styles'

function CpfStep(): JSX.Element {
  const { control } = useFormContext()

  return (
    <>
      <Title>
        Primeiro, precisamos verificar se você já tem uma conta no Wida.
      </Title>
      <Box mt={10} mb={2}>
        <InputField
          mask='000.000.000-00'
          name='cpf'
          control={control}
          label='Digite seu CPF'
        />
      </Box>
      <InfoText>
        Usamos o número do CPF para garantir que nenhuma conta no Wida esteja
        duplicada.
      </InfoText>
    </>
  )
}

export default CpfStep
