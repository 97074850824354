import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

export const Title = styled(Typography)({
  color: '#8D92BE',
  textAlign: 'center',
  fontFamily: 'Quicksand',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '37px',
})

export const Description = styled(Typography)({
  color: '#949494',
  textAlign: 'center',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '22px',
})

export const SupportText = styled(Typography)<any>(
  ({ highlight = false }: any) => ({
    display: 'inline-block',
    color: highlight ? '#8D92BE' : '#949494',
    textAlign: 'center',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Zen Kaku Gothic Antique',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: highlight ? 700 : 400,
    lineHeight: '22px',
  })
)
