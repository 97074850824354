import { Divider, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import CallMadeRoundedIcon from '@mui/icons-material/CallMadeRounded'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'

export const TermsAndPoliciesText = styled('span')({
  fontFamily: 'Zen Kaku Gothic Antique',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  color: '#FFFFFF',
})

export const TermsAndPoliciesLink = styled(TermsAndPoliciesText)({
  textDecorationLine: 'underline',
  cursor: 'pointer',
})

export const MenuItemText = styled(Typography)({
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '21px',
  color: '#FFFFFF',
})

export const DividerStyled = styled(Divider)({
  backgroundColor: '#ffffff',
})

export const CallMadeRoundedIconStyled = styled(CallMadeRoundedIcon)({
  fill: '#ffffff',
})

export const LogoutRoundedIconStyled = styled(LogoutRoundedIcon)({
  fill: '#ffffff',
})
