import client from 'services/client'

export const getForm = async (id: string) => {
  try {
    const { data: form } = await client.get(`/forms/${id}`)
    return form
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}
