import {
  useSignUpPatientContext,
  useSignUpCaregiverContext,
  useSignUpInformantContext,
} from 'contexts'

export const contexts: any = {
  patient: useSignUpPatientContext,
  caregiver: useSignUpCaregiverContext,
  informant: useSignUpInformantContext,
}
