import client from 'services/client'
import { SendMethodDto } from 'types/invites'

export const getInvite = async (id: string) => {
  try {
    const { data }: any = await client.get(`/invites/${id}`)

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const createInformantInvite = async (body: any) => {
  try {
    const { data }: any = await client.post('/invites', body)

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const sendEmailInvitePatient = async (body: any) => {
  try {
    await client.post('/invites/send-email', body)
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const sendWhatsappInvitePatient = async (body: any) => {
  try {
    await client.post('/invites/send-whatsapp', body)
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const updateSendMethod = async (body: SendMethodDto) => {
  try {
    await client.post('/invites/send-method', body)
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const getPendingInvites = async () => {
  try {
    const { data } = await client.get('/invites-users/my-open-invites')
    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const checkInviteIsValid = async (token: string) => {
  try {
    const { data } = await client.get(`/invites?token=${token}`)
    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const cancelInvite = async (id: string) => {
  try {
    const { data } = await client.post(`/invites/cancel/${id}`)
    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}
