import { Box, Typography, InputBase } from '@mui/material'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

export const SearchInput = styled(InputBase)<any>(({ showList }: any) => ({
  width: '290px',
  height: '40px',
  padding: '10px 12px',

  fontFamily: 'Quicksand',
  fontWeight: 500,
  fontSize: '16px',
  fontHeight: '20px',
  color: '#596B7B',

  backgroundColor: '#F6F7FB',
  borderRadius: showList ? '20px 20px 0px 0px' : '32px',
  border: '1px solid transparent',

  '& ::placeholder': {
    color: '#CDCDCD',
  },
  '&.Mui-focused': {
    borderTop: '1px solid #BDC1DD',
    borderLeft: '1px solid #BDC1DD',
    borderRight: '1px solid #BDC1DD',
    borderBottom: showList ? 'none' : '1px solid #BDC1DD',
  },
}))

export const SearchBox = styled(Box)<any>({
  width: '290px',
  fontSize: '14px',
  zIndex: 100,
  color: '#657787',
  position: 'absolute',

  backgroundColor: '#F6F7FB',

  borderRadius: '0px 0px 12px 12px',
  borderLeft: '1px solid #BDC1DD',
  borderRight: '1px solid #BDC1DD',
  borderBottom: '1px solid #BDC1DD',

  maxHeight: '500px',
  overflowY: 'auto',
})

export const TagGroup = styled(Box)({
  width: 'fit-content',
  color: '#FFF',
  backgroundColor: '#B6B6B6',
  border: '1px solid #DFDFDF',
  borderRadius: '4px',
  cursor: 'pointer',

  fontFamily: 'Quicksand',
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '13px',
})

export const Question = styled(Typography)<any>(({ highlight }: any) => ({
  display: 'inline-block',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
  color: '#596B7B',
  cursor: 'pointer',

  borderRadius: '2px',
  backgroundColor: highlight ? '#CDD1ED' : 'transparent',

  transition: '0.6s',
  '&:hover': {
    opacity: 0.7,
    color: '#7E82A6',
  },
}))

export const NoResultsText = styled(Typography)<any>({
  display: 'inline-block',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
  color: '#596B7B',
  borderRadius: '2px',
  backgroundColor: 'transparent',
  textAlign: 'center',
})

export const SwitchText = styled(Typography)<any>(({ disabled }: any) => ({
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '20px',
  color: disabled ? '#BEBEBE' : '#000000',
}))

export const LargeTooltip = styled(({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    fontFamily: 'Zen Kaku Gothic Antique',
    fontWeight: 400,
    fontSize: '10px',
  },
})

export const AssistText = styled(Typography)({
  fontFamily: 'Zen Kaku Gothic Antique',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: '20px',
  color: '#B1B1B5',
  textAlign: 'center',
})

export const AssistTextContainer = styled(Box)({
  width: '153px',
  position: 'absolute',
  top: -10,
  right: 54,
  backgroundColor: '#ffffff',
})
