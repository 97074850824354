import { styled } from '@mui/material/styles'
import { Typography, CircularProgress } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import DoneRoundedIcon from '@mui/icons-material/DoneRounded'

export const Title = styled(Typography)({
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '20px',
  lineHeight: '26px',
  textAlign: 'center',
  color: '#FFFFFF',
})

export const Loading = styled(CircularProgress)({
  color: '#FFFFFF',
})

export const SuccessIcon = styled(DoneRoundedIcon)({
  width: '50px',
  height: '50px',
  color: '#FFFFFF',
})

export const ErrorIcon = styled(CloseRoundedIcon)({
  width: '50px',
  height: '50px',
  color: '#FFFFFF',
})
