import { useEffect, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import { Box, Stack, CircularProgress } from '@mui/material'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import { Button, RouteLayout, SignUpStepper } from 'components'
import {
  generateValidationCode,
  getRelations,
  checkInviteIsValid,
} from 'services'
import { useNotification, useQuery } from 'hooks'
import { SignUpInformantContext, useAuthContext } from 'contexts'
import { registerPageAccess, sanitizeString } from 'helpers'
import useSignUpInformantForm from './use-sign-up-informant-form'
import { informantSteps } from 'components/sign-up-steps/sign-up-informant-steps'

function SignUpInformant(): JSX.Element {
  const [mounting, setMounting] = useState(true)
  const [step, setStep] = useState<number>(0)
  const [loadingSendCode, setLoadingSendCode] = useState(false)
  const [relationOptions, setRelationOptions] = useState([])
  const [patientRelationId, setPatientRelationId] = useState()
  const [contacts, setContacts] = useState<any>({
    email: '',
    whatsapp: '',
  })
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const { successToast, warnToast, errorToast } = useNotification()
  const query = useQuery()
  const { signup } = useAuthContext()
  const methods = useSignUpInformantForm()

  const { setValue, trigger, watch, getValues } = methods

  const invitationToken = query.get('invitationToken')

  const relation = watch('relation')

  useEffect(() => {
    if (!relation) return

    if (relation === patientRelationId) {
      setValue('environment', '')
    }
  }, [relation, relationOptions, setValue, patientRelationId])

  useEffect(() => {
    const verifyToken = async () => {
      try {
        if (!invitationToken) {
          warnToast('Você precisa de um convite para acessar essa página')
          navigate('/signup/patient')
        }

        const { isActive } = await checkInviteIsValid(invitationToken!)

        if (!isActive) navigate('/expired_invitation')

        const userInfos: any = jwtDecode(invitationToken!)

        const [firstName, lastName] = getValues(['firstName', 'lastName'])
        const names = userInfos?.name?.split(' ')

        setValue('firstName', firstName || names?.shift() || '')
        setValue('lastName', lastName || names?.join(' ') || '')

        setMounting(false)

        registerPageAccess('Signup informant')
      } catch (error) {
        navigate('/signup/patient')
      }
    }

    verifyToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const relations = await getRelations()

        const sortedRelations = relations.sort((a: any, b: any) => {
          if (a.name === 'Sou eu') return -1
          if (b.name === 'Sou eu') return 1
          return 0
        })

        const findPatientRelation: any = relations.find(
          ({ name }: any) => name === 'Sou eu'
        )

        setRelationOptions(
          sortedRelations?.map(({ id, name }: any) => ({
            value: id,
            label: name,
          })) || []
        )

        setPatientRelationId(findPatientRelation?.id)
      } catch (error: any) {
        console.error(error)
        errorToast(error?.message || 'Erro desconhecido')
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sendValidationCode = async (
    contactType: string,
    showToast: boolean
  ) => {
    try {
      setLoadingSendCode(true)
      const [firstName, contact] = getValues(['firstName', contactType] as any)

      await generateValidationCode({
        name: firstName,
        type: contactType,
        contact:
          contactType === 'whatsapp'
            ? 55 + sanitizeString(contact)
            : contact.toLowerCase(),
      })

      if (showToast) successToast('Código enviado com sucesso!')
    } catch (error) {
      errorToast(
        'Ocorreu um erro ao solicitar o código, por favor tente novamente.'
      )
    } finally {
      setLoadingSendCode(false)
    }
  }

  const onSubmit: any = async () => {
    const params = getValues()
    try {
      setLoading(true)

      const valid = await trigger()

      if (!valid) {
        setLoading(false)
        return
      }

      const communication = []
      if (params.emailValidationCode) communication.push('email')
      if (params.whatsappValidationCode) communication.push('whatsapp')

      const body: any = {
        cpf: sanitizeString(params.cpf),
        firstName: params.firstName.trim(),
        lastName: params.lastName.trim(),
        password: params.password.trim(),
        ...(params.environment && { userGroupId: params.environment }),
        relationId: params.relation,
        role: 'informant',
        invitationToken: invitationToken!,
        communication: communication,
        ...(params.email && { email: params.email.toLowerCase() }),
        ...(params.whatsapp && {
          ddi: '55',
          whatsapp: sanitizeString(params.whatsapp),
        }),
        ...(params.emailValidationCode && {
          emailValidationCode: params.emailValidationCode,
        }),
        ...(params.whatsappValidationCode && {
          whatsappValidationCode: params.whatsappValidationCode,
        }),
      }

      await signup(body)

      setLoading(false)

      successToast('Sua conta foi criada com sucesso!')
      navigate('/')
    } catch (error: any) {
      console.error(error)
      errorToast(error?.message || 'Erro desconhecido')
      setLoading(false)
    }
  }

  const goBackStep = () => {
    setLoading(true)

    if (step <= 0) {
      setLoading(false)
      return
    }

    if (
      informantSteps[step].id === 'contact-step' &&
      relation === patientRelationId
    ) {
      // skip environment step
      setStep((step) => step - 2)
      setLoading(false)
      return
    }

    const whatsapp = getValues('whatsapp')

    if (informantSteps[step].id === 'password-step' && whatsapp) {
      setStep((step) => step - 2)
      setLoading(false)
      return
    }

    if (informantSteps[step].id === 'email-verification-code-step') {
      setStep((step) => step - 2)
      setLoading(false)
      return
    }

    setStep((step) => step - 1)
    setLoading(false)
  }

  const goNextStep = async () => {
    setLoading(true)

    const valid = await trigger(informantSteps[step].fields as any)

    if (!valid || step >= informantSteps.length - 1) {
      setLoading(false)
      return
    }

    if (
      informantSteps[step].id === 'informant-personal-info-step' &&
      relation === patientRelationId
    ) {
      // skip environment step
      setStep((step) => step + 2)
      setLoading(false)
      return
    }

    if (informantSteps[step].id === 'contact-step') {
      const [whatsapp, email] = getValues(['whatsapp', 'email'])

      if (whatsapp && whatsapp !== contacts.whatsapp) {
        await sendValidationCode('whatsapp', false)
        setValue('whatsappValidationCode', '')
      }

      if (!whatsapp && email && email !== contacts.email) {
        await sendValidationCode('email', false)
        setValue('emailValidationCode', '')
      }

      setContacts((oldState: any) =>
        whatsapp ? { whatsapp, email: oldState.email } : { whatsapp, email }
      )

      setStep((step) => step + (Boolean(whatsapp) ? 1 : 2))
      setLoading(false)
      return
    }

    if (informantSteps[step].id === 'whatsapp-verification-code-step') {
      setStep((step) => step + 2)
      setLoading(false)
      return
    }

    setStep((step) => step + 1)
    setLoading(false)
  }

  const handleKeyDown = (e: any) => {
    if (e.code !== 'Enter' && e.code !== 'NumpadEnter') return
    // * ignora a tecla Enter nos passos do formulário
    e.preventDefault()
  }

  if (mounting) return <></>

  return (
    <SignUpInformantContext.Provider
      value={{
        invitationInfos: jwtDecode(invitationToken!),
        goNextStep,
        sendValidationCode,
        loadingSendCode,
        relationOptions,
      }}
    >
      <FormProvider {...methods}>
        <RouteLayout bgColor='white'>
          <Box
            display='flex'
            flexDirection='column'
            height={window.innerHeight}
            component='form'
            onKeyDown={handleKeyDown}
          >
            <SignUpStepper
              totalSteps={informantSteps.length - 1}
              activeStep={informantSteps[step].stepperIndex}
              stepTitle={informantSteps[step].stepTitle}
            />

            <Box mx={5} mt={3} flexGrow={1}>
              {informantSteps[step].component}
            </Box>

            <Stack direction='row' mb={7} mx={5} justifyContent='space-between'>
              {step === 0 ? (
                <Box />
              ) : (
                <Box width='100px'>
                  <Button
                    type='button'
                    variant='text'
                    onClick={goBackStep}
                    startIcon={<ChevronLeftRoundedIcon />}
                  >
                    voltar
                  </Button>
                </Box>
              )}
              <Box width='100px'>
                {informantSteps[step].hasNextButton &&
                step < informantSteps.length - 1 ? (
                  <Button
                    type='button'
                    onClick={goNextStep}
                    disabled={loading}
                    style={{
                      wordBreak: 'normal',
                    }}
                  >
                    {loading ? <CircularProgress size='18px' /> : 'Continuar'}
                  </Button>
                ) : (
                  <Button
                    type='button'
                    onClick={onSubmit}
                    disabled={loading}
                    style={{
                      wordBreak: 'normal',
                    }}
                  >
                    {loading ? <CircularProgress size='18px' /> : 'Cadastrar'}
                  </Button>
                )}
              </Box>
            </Stack>
          </Box>
        </RouteLayout>
      </FormProvider>
    </SignUpInformantContext.Provider>
  )
}

export default SignUpInformant
