import { useEffect } from 'react'
import { Box, Stack } from '@mui/material'
import { SubmitHandler } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Button, RouteLayout, HeaderLoginPatientGroup } from 'components'
import { InputField } from 'components/inputs'
import { resetPassword } from 'services'
import { useNotification, useQuery } from 'hooks'
import { registerPageAccess } from 'helpers'
import useFormForgotPassword from './forgot-password-patient-group-form'
import {
  LoginContainer,
  Title,
  InviteClinicText,
  Description,
  GradientLink,
} from './forgot-password-patient-group-styles'
import { IFormInput } from './forgot-password-patient-group-types'

function ForgotPasswordPatientGroup(): JSX.Element {
  const navigate = useNavigate()
  const { control, handleSubmit } = useFormForgotPassword()
  const { successToast, errorToast } = useNotification()
  const query = useQuery()
  const navigation = useNavigate()

  useEffect(() => {
    registerPageAccess('Recuperacao de Senha')
  }, [])

  const onSubmit: SubmitHandler<IFormInput> = async (params) => {
    try {
      const token = query.get('token')

      if (!token)
        throw new Error(
          'Ocorreu um erro ao tentar recuperar a senha, por favor tente novamente.'
        )

      await resetPassword(token, {
        password: params.password.trim(),
      })

      successToast('Senha redefinida com sucesso!')
      navigation('/signin/patient')
    } catch (error: any) {
      console.error(error)
      errorToast(error.message)
    }
  }

  return (
    <RouteLayout bg='gradient'>
      <HeaderLoginPatientGroup />

      <LoginContainer component='form' onSubmit={handleSubmit(onSubmit)}>
        <Box mb={3}>
          <Title>Crie uma nova senha</Title>
        </Box>

        <Box mb={2} display='flex' justifyContent='flex-end'>
          <Description>
            Crie uma senha nova para pode acessar nossa plataforma
          </Description>
        </Box>
        <Stack spacing={1} mb={8}>
          <InputField
            type='password'
            name='password'
            control={control}
            label='Senha'
          />
          <InputField
            type='password'
            name='confirmPassword'
            control={control}
            label='Confirmar senha'
          />
        </Stack>
        <Button type='submit'>Salvar</Button>
        <Stack mt={2} alignItems='center'>
          <InviteClinicText>Lembrou da senha?</InviteClinicText>
          <GradientLink onClick={() => navigate('/signin/patient')}>
            Login
          </GradientLink>
        </Stack>
      </LoginContainer>
    </RouteLayout>
  )
}

export default ForgotPasswordPatientGroup
