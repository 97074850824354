import { RichUtils } from 'draft-js'
import '../rich-text.css'
import '../../../../../node_modules/draft-js/dist/Draft.css'
import { Box, Divider } from '@mui/material'
import {
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  FormatListNumbered,
  FormatListBulleted,
  FormatAlignLeft,
  FormatAlignCenter,
  FormatAlignRight,
  AttachFileOutlined,
} from '@mui/icons-material'

function getIconFromLabel(label: string): any {
  return (
    {
      bold: <FormatBold />,
      italic: <FormatItalic />,
      underline: <FormatUnderlined />,
      ol: <FormatListNumbered />,
      ul: <FormatListBulleted />,
      left: <FormatAlignLeft />,
      center: <FormatAlignCenter />,
      right: <FormatAlignRight />,
    }[label] || label
  )
}

function RichTextHeader({
  onChange,
  editorState,
  handleUploadFiles,
}: any): JSX.Element {
  function toggleBlockType(blockType: any) {
    onChange(RichUtils.toggleBlockType(editorState, blockType))
  }

  function toggleInlineStyle(inlineStyle: any) {
    onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle))
  }

  return (
    <Box display={'flex'} sx={{ flexFlow: 'wrap' }}>
      {/* <Box mb={0} mt={1}>
        <FontStyleControls
          editorState={editorState}
          onToggle={toggleBlockType}
          toggleBlockType={toggleBlockType}
        />
      </Box> 
      <Divider orientation='vertical' sx={{ height: '25px', margin: 1 }} /> */}
      <Box mb={0} mt={1}>
        <InlineStyleControls
          editorState={editorState}
          onToggle={toggleInlineStyle}
        />
      </Box>
      {/* <Divider orientation='vertical' sx={{ height: '25px', margin: 1 }} />
      <Box mb={0} mt={1}>
        <AlignStyleControls
          textAlignment={textAlignment}
          onToggle={toggleTextAlignment}
        />
      </Box> */}
      <Divider orientation='vertical' sx={{ height: '25px', margin: 1 }} />
      <Box mb={0} mt={1}>
        <BlockStyleControls
          editorState={editorState}
          onToggle={toggleBlockType}
        />
      </Box>
      <Divider orientation='vertical' sx={{ height: '25px', margin: 1 }} />
      <Box mb={0} mt={1}>
        <input
          onChange={(e: any) => handleUploadFiles(e.target.files)}
          style={{ display: 'none' }}
          id='input-file'
          type='file'
          multiple
        />
        <label
          htmlFor='input-file'
          style={{ cursor: 'pointer', color: '#999' }}
        >
          <AttachFileOutlined />
        </label>
      </Box>
    </Box>
  )
}

function StyleButton(props: any) {
  const onToggle = (e: any) => {
    e.preventDefault()
    props.onToggle(props.style)
  }

  let className = 'RichEditor-styleButton'
  if (props.active) {
    className += ' RichEditor-activeButton'
  }

  return (
    <span className={className} onMouseDown={onToggle}>
      {getIconFromLabel(props.label)}
    </span>
  )
}

// const FONT_TYPES = [
//   { label: '10', style: 'font-size-10' },
//   { label: '12', style: 'font-size-12' },
//   { label: '14', style: 'font-size-14' },
//   { label: '18', style: 'font-size-18' },
//   { label: '24', style: 'font-size-24' },
//   { label: '30', style: 'font-size-30' },
//   { label: '34', style: 'font-size-34' },
// ]

// const FontStyleControls = (props: any) => {
//   const { editorState } = props
//   const selection = editorState.getSelection()
//   const blockType = editorState
//     .getCurrentContent()
//     .getBlockForKey(selection.getStartKey())
//     .getType()

//   return (
//     <>
//       <select
//         value={blockType}
//         onChange={(e) => props.toggleBlockType(e.target.value)}
//         className='RichEditor-select'
//       >
//         <option value={''}>Tamanho</option>
//         {FONT_TYPES.map((type) => (
//           <option key={type.label} value={type.style}>
//             {type.label}
//           </option>
//         ))}
//       </select>
//     </>
//   )
// }

const BLOCK_TYPES = [
  { label: 'ul', style: 'unordered-list-item' },
  // { label: 'ol', style: 'ordered-list-item' },
]

const BlockStyleControls = (props: any) => {
  const { editorState } = props
  const selection = editorState.getSelection()
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType()

  return (
    <>
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </>
  )
}

// function toggleTextAlignment(alignment: any) {
//   setTextAlignment(alignment)
// }

// const ALIGN_TYPES = [{ label: 'left' }, { label: 'center' }, { label: 'right' }]

// const AlignStyleControls = (props: any) => {
//   return (
//     <>
//       {ALIGN_TYPES.map((type) => (
//         <StyleButton
//           key={type.label}
//           active={type.label === props.textAlignment}
//           label={type.label}
//           onToggle={() => props.onToggle(type.label)}
//         />
//       ))}
//     </>
//   )
// }

var INLINE_STYLES = [
  { label: 'bold', style: 'BOLD' },
  { label: 'italic', style: 'ITALIC' },
  { label: 'underline', style: 'UNDERLINE' },
]

const InlineStyleControls = (props: any) => {
  const currentStyle = props.editorState.getCurrentInlineStyle()

  return (
    <>
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </>
  )
}

export default RichTextHeader
