import { useState, useEffect } from 'react'
import { Stack, Box, IconButton, Collapse, Slide } from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'
import { QuestionAnswers, RouteLayout, Steps } from 'components'
import { useAuthContext } from 'contexts'
import { isUserPatient, registerTrack } from 'helpers'
import {
  QuestionText,
  HelpContainer,
  QuestionContainer,
  HelpTitle,
  HelpDescription,
  HelpButtonText,
} from './question-styles'

function Question({
  question,
  callbackChooseAnswer,
  step,
  totalSteps,
  goBackStep,
  goForwardStep,
  loadingAnswer,
  patientInfo,
  questionAnimation,
  formId,
}: any) {
  const [showMoreInformation, setShowMoreInformation] = useState(false)

  const { user } = useAuthContext()

  const questionTrackPayload = {
    user_id: user.userId,
    patient_id: patientInfo.patientId,
    identifier: question.identifier,
    source: isUserPatient(user) ? 'patient' : 'caregiver',
    order: step,
    form_id: formId,
  }

  useEffect(() => {
    registerTrack('Visualiza Pergunta', questionTrackPayload)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setShowMoreInformation(false)
  }, [question])

  return (
    <RouteLayout bg='gradient'>
      <Box height={45} display='flex' justifyContent='center'>
        <Steps activeStep={step} totalSteps={totalSteps} />
      </Box>
      <HelpContainer>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Box
            height='54px'
            display='flex'
            alignItems='center'
            onClick={() => {
              if (!showMoreInformation) {
                registerTrack('Clica Não Entendi', questionTrackPayload)
              }
              setShowMoreInformation(!showMoreInformation)
            }}
            sx={{ cursor: 'pointer' }}
          >
            <HelpButtonText>Não entendi a pergunta</HelpButtonText>
            {showMoreInformation ? (
              <ExpandLessRoundedIcon sx={{ fill: '#FFFFFF', height: '20px' }} />
            ) : (
              <ExpandMoreRoundedIcon sx={{ fill: '#FFFFFF', height: '20px' }} />
            )}
          </Box>

          <Collapse in={showMoreInformation}>
            <Stack alignItems='center' spacing={3} px={5} height='211px'>
              <HelpTitle>Em outras palavras</HelpTitle>
              <HelpDescription>{question.example}</HelpDescription>
            </Stack>
          </Collapse>
        </Box>

        <Slide
          direction={questionAnimation.direction}
          in={questionAnimation.active}
        >
          <QuestionContainer>
            <Box
              display='flex'
              alignItems='flex-start'
              justifyContent='space-between'
            >
              <IconButton onClick={goBackStep}>
                <ArrowBackIosNewIcon
                  sx={{ fill: '#657787', width: '18px', height: '18px' }}
                />
              </IconButton>
              <QuestionText>{question.text}</QuestionText>
              <IconButton onClick={goForwardStep}>
                <ArrowForwardIosIcon
                  sx={{ fill: '#657787', width: '18px', height: '18px' }}
                />
              </IconButton>
            </Box>
            <Box mt={2}>
              <QuestionAnswers
                initialAnswer={question.answer}
                handleChooseAnswer={callbackChooseAnswer}
                loadingAnswer={loadingAnswer}
              />
            </Box>
          </QuestionContainer>
        </Slide>
      </HelpContainer>
    </RouteLayout>
  )
}

export default Question
