import ellipseNo from 'images/ellipse-no.svg'
import ellipseYes from 'images/ellipse-yes.svg'
import ellipseSoso from 'images/ellipse-soso.svg'

interface IOption {
  svg: any
  label: string
  alt: string
}

interface IOptions {
  [key: string]: IOption
}

export const answerOptions: IOptions = {
  yes: { svg: ellipseYes, label: 'presente', alt: 'Ícone de sintoma presente' },
  soso: {
    svg: ellipseSoso,
    label: 'sublimiar',
    alt: 'Ícone de sintoma sublimiar',
  },
  no: { svg: ellipseNo, label: 'ausente', alt: 'Ícone de sintoma ausente' },
}

export function extractTagsFromAnswers(answers: any) {
  const answersTags = answers.reduce(
    (acc: any, { tags }: any) => (tags ? [...acc, ...tags] : acc),
    []
  )

  return [...new Set(answersTags)].map((tag) => ({ tag }))
}
