import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  TIMELINE_SPACING_PX,
  ANSWER_WIDTH,
  answerDict,
} from 'helpers/constants'

const Gradient = styled(Box)<any>(({ answers }) => {
  const width =
    ANSWER_WIDTH * answers.length + TIMELINE_SPACING_PX * (answers.length - 1)

  const linearGradient = answers.reduce(
    (acc: string, answer: string, index: number) => {
      return (
        acc +
        (answerDict[answer]?.color || '#ffffff') +
        (index !== answers.length - 1 ? ', ' : '')
      )
    },
    ''
  )

  const hasGradient = answers.length > 1

  return {
    width: width,
    height: ANSWER_WIDTH,
    borderRadius: '100px',
    background: hasGradient
      ? 'linear-gradient(to right, ' + linearGradient + ')'
      : linearGradient,
  }
})

export default Gradient
