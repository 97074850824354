import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Stack } from '@mui/material'
import {
  updateMyPatientInfos,
  updateMyCaregiverInfos,
  updateMyInformantInfos,
} from 'services'
import { Button } from 'components'
import { useAuthContext } from 'contexts'
import { useNotification } from 'hooks'
import { fromDate } from 'helpers'
import useSettingsPersonalInfosForm from './personal-infos-form'
import {
  PatientPersonalInfoFields,
  InformantPersonalInfoFields,
  CaregiverPersonalInfoFields,
} from 'components/personal-info-fields'
import { generateUpdateBody } from './personal-infos-helpers'

const roleDefinitions: any = {
  patient: {
    updateMyInfos: updateMyPatientInfos,
    fieldsComponent: (props: any) => <PatientPersonalInfoFields {...props} />,
  },
  caregiver: {
    updateMyInfos: updateMyCaregiverInfos,
    fieldsComponent: (props: any) => <CaregiverPersonalInfoFields {...props} />,
  },
  informant: {
    updateMyInfos: updateMyInformantInfos,
    fieldsComponent: (props: any) => <InformantPersonalInfoFields {...props} />,
  },
}

function SettingsPersonalInfos(): JSX.Element {
  const { userInfos, fetchUser } = useAuthContext()
  const role = userInfos?.user?.roles[0]

  const { control, handleSubmit, setValue } = useSettingsPersonalInfosForm(role)
  const { errorToast, successToast } = useNotification()
  const navigate = useNavigate()

  useEffect(() => {
    if (role === 'patient') {
      setValue('firstName', userInfos?.personalInfos?.firstName || '')
      setValue('lastName', userInfos?.personalInfos?.lastName || '')
      setValue(
        'pronoun',
        userInfos?.personalInfos?.pronoun?.toLowerCase() || ''
      )
      setValue('birthdate', fromDate(userInfos?.personalInfos?.birthdate) || '')
    }

    if (role === 'informant') {
      setValue('firstName', userInfos?.personalInfos?.firstName || '')
      setValue('lastName', userInfos?.personalInfos?.lastName || '')
    }

    if (role === 'caregiver') {
      setValue('caregiverFirstName', userInfos?.personalInfos?.firstName || '')
      setValue('caregiverLastName', userInfos?.personalInfos?.lastName || '')
      setValue('firstName', userInfos?.patient?.personalInfo?.firstName || '')
      setValue('lastName', userInfos?.patient?.personalInfo?.lastName || '')
      setValue(
        'pronoun',
        userInfos?.patient?.personalInfo?.pronoun?.toLowerCase() || ''
      )
      setValue(
        'birthdate',
        fromDate(userInfos?.patient?.personalInfo?.birthdate) || ''
      )
    }
  }, [userInfos, setValue, role])

  const onSubmit = async (params: any) => {
    try {
      await roleDefinitions[role].updateMyInfos(
        generateUpdateBody(role, params)
      )

      successToast('Informações atualizadas com sucesso')

      fetchUser()
    } catch (error: any) {
      errorToast(
        error?.response?.data?.message || error?.message || 'Erro desconhecido'
      )
    }
  }

  return (
    <Box
      flexGrow={1}
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='space-between'
      component='form'
      onSubmit={handleSubmit(onSubmit)}
      px={2}
      pt={5}
      sx={{
        backgroundColor: '#ffffff',
        borderRadius: '28px 28px 0px 0px',
      }}
    >
      {roleDefinitions[role].fieldsComponent({ control })}

      <Stack my={3} width='295px' spacing={1}>
        <Button type='submit'>Salvar</Button>
        <Button variant='text' onClick={() => navigate('/settings')}>
          Voltar
        </Button>
      </Stack>
    </Box>
  )
}

export default SettingsPersonalInfos
