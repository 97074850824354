import '../rich-text.css'
import '../../../../../node_modules/draft-js/dist/Draft.css'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { Check } from '@mui/icons-material'
import { useState } from 'react'
import { ButtonClinic, SecondaryConfirmationModal } from 'components'
import { styled } from '@mui/material/styles'

function RichTextFooter({
  saveStatus,
  handleFinish,
  canFinish,
  save,
}: any): JSX.Element {
  const [modalOpen, setModalOpen] = useState(false)

  const confirmCloseModal = async () => {
    setModalOpen(false)
    handleFinish()
  }

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Box display={'flex'} alignItems={'center'}>
        {saveStatus !== 'no' &&
          (saveStatus === 'done' ? (
            <>
              <Check
                sx={{ color: '#01B3A7', marginRight: '10px', fontSize: '15px' }}
              />
              <FooterText>Alterações salvas!</FooterText>
            </>
          ) : (
            <>
              <CircularProgress
                size={'10px'}
                sx={{ color: '#01B3A7', marginRight: '10px' }}
              />
              <FooterText>
                {saveStatus === 'saving'
                  ? 'Salvando alterações...'
                  : 'Processando...'}
              </FooterText>
            </>
          ))}
      </Box>
      <Stack direction='row' spacing={2}>
        <ButtonClinic
          width={64}
          height={34}
          variant='text'
          color='orange'
          fontWeight='bold'
          size='small'
          onClick={save}
        >
          Salvar
        </ButtonClinic>
        <ButtonClinic
          height={34}
          variant='contained'
          color='orange'
          fontWeight='bold'
          size='small'
          onClick={() => setModalOpen(true)}
          disabled={!canFinish}
        >
          Finalizar registro
        </ButtonClinic>
      </Stack>

      {modalOpen && (
        <SecondaryConfirmationModal
          isOpen={modalOpen}
          title='Finalizar registro'
          hideButtons
          onClose={() => setModalOpen(false)}
          content={
            <Stack alignItems='center'>
              <Box maxWidth={382} mb={4} mt={3}>
                <ModalDescriptionText>
                  Após finalizar, não será possível alterar ou excluir o
                  registro. Deseja prosseguir?
                </ModalDescriptionText>
              </Box>

              <Stack direction='row' spacing={4} mt={3} mb={3}>
                <ButtonClinic
                  variant='outlined'
                  onClick={() => setModalOpen(false)}
                  width={140}
                  color='purple'
                  size='small'
                  fontWeight='bold'
                >
                  Voltar
                </ButtonClinic>

                <ButtonClinic
                  variant='contained'
                  onClick={confirmCloseModal}
                  width={140}
                  color='purple'
                  size='small'
                  fontWeight='bold'
                >
                  Finalizar
                </ButtonClinic>
              </Stack>
            </Stack>
          }
        />
      )}
    </Box>
  )
}

const FooterText = styled(Typography)({
  color: 'rgba(0, 0, 0, 0.87)',
  fontFamily: 'Quicksand',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
})

const ModalDescriptionText = styled(Typography)({
  color: '#596B7B',
  textAlign: 'center',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '28px',
})

export default RichTextFooter
