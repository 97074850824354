import { Stepper, Step, StepLabel } from '@mui/material'
import StepIcon from './step-icon'
import { StepperContext } from 'contexts'
import { useMemo } from 'react'
interface StepsProps {
  activeStep: number
  totalSteps: number
}

function Steps({ activeStep, totalSteps }: StepsProps) {
  const stepWidth = useMemo(() => {
    const containerWidth =
      (window.innerWidth > 380 ? 380 : window.innerWidth) - (totalSteps - 1)

    return Math.floor(containerWidth / (totalSteps || 1))
  }, [totalSteps])

  return (
    <StepperContext.Provider value={{ totalSteps, stepWidth }}>
      <Stepper
        activeStep={activeStep}
        connector={null}
        sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}
      >
        {Array.from({ length: totalSteps }, (_, i) => i).map((index) => (
          <Step
            key={index}
            sx={{
              padding: 0,
              marginRight: index === totalSteps - 1 ? 0 : '1px',
              width: `${stepWidth}px`,
            }}
          >
            <StepLabel
              StepIconComponent={StepIcon}
              sx={{
                '& .MuiStepLabel-iconContainer': {
                  padding: 0,
                  paddingRight: 0,
                },
              }}
            />
          </Step>
        ))}
      </Stepper>
    </StepperContext.Provider>
  )
}

export default Steps
