import { useEffect } from 'react'
import { Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useSignUpCaregiverContext } from 'contexts'
import { registerPageAccess } from 'helpers'
import { InputField, SelectField } from 'components/inputs'
import { Title } from './sign-up-caregiver-steps-styles'

function PatientPersonalInfoOneStep(): JSX.Element {
  const { control } = useFormContext()
  const { patientName, type } = useSignUpCaregiverContext()

  const firstName = patientName?.split(' ')[0] || 'o paciente'

  useEffect(() => {
    registerPageAccess('Informação pessoal', {
      patient_name: patientName,
      source: type,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Title>
        Insira algumas informações básicas sobre {firstName} (paciente).
      </Title>

      <Box mt={2} mb={3}>
        <InputField
          name='firstName'
          control={control}
          label='Primeiro nome do paciente'
        />
      </Box>
      <Box mb={3}>
        <InputField
          name='lastName'
          control={control}
          label='Sobrenome do paciente'
        />
      </Box>

      <Box mt={2} mb={3}>
        <SelectField
          label='Qual pronome devemos usar?'
          name='pronoun'
          control={control}
          options={[
            { value: 'he', label: 'Ele' },
            { value: 'she', label: 'Ela' },
          ]}
        />
      </Box>
    </>
  )
}

export default PatientPersonalInfoOneStep
