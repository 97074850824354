import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

export const DescriptionText = styled(Typography)({
  color: '#949494',
  textAlign: 'center',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '22px',
})
