import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const ContactInfos = styled(Typography)({
  color: '#596B7B',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '24px',
})

export const SwitchText = styled(Typography)({
  color: 'rgba(0, 0, 0, 0.38)',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '17px',
})

export const ErrorMessage = styled(Typography)({
  color: '#E74C3C',
  leadingTrim: 'both',
  textEdge: 'cap',

  fontFamily: 'Quicksand',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '31px',
})
