import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

yup.setLocale({
  mixed: {
    required: 'Campo obrigatório',
  },
})

export const inviteNewInformantResolver = yupResolver(
  yup
    .object({
      name: yup.string().required(),
    })
    .required()
)

export const loginInviteResolver = yupResolver(
  yup
    .object({
      email: yup.string().email('Digite um email válido').required(),
    })
    .required()
)
