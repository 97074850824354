import CpfStep from './general-steps/cpf-step'
import PersonalInfoStep from './sign-up-patient-steps/personal-info-step'
import PasswordStep from './general-steps/password-step'
import { PatientStepType } from 'types'
import ContactStep from './general-steps/contact-step'
import WhatsappValidationCodeStep from './general-steps/whatsapp-validation-code-step'
import EmailValidationCodeStep from './general-steps/email-validation-code-step'
import TermsStep from './general-steps/terms-step'

export const patientSteps: PatientStepType[] = [
  {
    id: 'cpf-step',
    component: <CpfStep />,
    fields: ['cpf'],
    hasNextButton: true,
    stepperIndex: 0,
    stepTitle: 'Sua conta',
  },
  {
    id: 'personal-info-step',
    component: <PersonalInfoStep />,
    fields: ['firstName', 'lastName', 'birthdate', 'pronoun'],
    hasNextButton: true,
    stepperIndex: 1,
    stepTitle: 'Sobre você',
  },
  {
    id: 'contact-step',
    component: <ContactStep />,
    fields: ['whatsapp', 'email'],
    hasNextButton: true,
    stepperIndex: 2,
    stepTitle: 'Contato',
  },
  {
    id: 'whatsapp-verification-code-step',
    component: <WhatsappValidationCodeStep type='patient' />,
    fields: ['whatsappValidationCode'],
    hasNextButton: true,
    stepperIndex: 3,
    stepTitle: 'Confirmação',
  },
  {
    id: 'email-verification-code-step',
    component: <EmailValidationCodeStep type='patient' />,
    fields: ['emailValidationCode'],
    hasNextButton: true,
    stepperIndex: 3,
    stepTitle: 'Confirmação',
  },
  {
    id: 'password-step',
    component: <PasswordStep />,
    fields: ['password', 'confirmPassword'],
    hasNextButton: true,
    stepperIndex: 4,
    stepTitle: 'Senha',
  },
  {
    id: 'terms-step',
    component: <TermsStep />,
    fields: ['acceptedShare', 'acceptedTermsAndPolicies'],
    hasNextButton: true,
    stepperIndex: 5,
    stepTitle: 'Segurança',
  },
]
