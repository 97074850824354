import { correctsTagWithDBInfo, nearestEvenInt } from 'helpers'
import { ANSWERS } from 'helpers/constants'

const labelText: any = {
  yes: (plural: boolean) =>
    `sintoma${plural ? 's' : ''} presente${plural ? 's' : ''}`,
  soso: (plural: boolean) =>
    `sintoma${plural ? 's' : ''} indeterminado${plural ? 's' : ''}`,
  no: (plural: boolean) =>
    `sintoma${plural ? 's' : ''} ausente${plural ? 's' : ''}`,
}

export const generateQuestionQuantities = (questions: any) => {
  return questions.reduce(
    (acc: any, { answer }: any) => ({
      ...acc,
      [answer]: (acc[answer] || 0) + 1,
    }),
    {}
  )
}

export function correctsTagsInRespondersWithResponses(
  respondersWithResponses: any,
  dbTags: any
) {
  return respondersWithResponses.map((responderWithResponses: any) => {
    return {
      ...responderWithResponses,
      responses: responderWithResponses.responses.map((response: any) => {
        return {
          ...response,
          tags: correctsTagWithDBInfo(response.tags, dbTags),
        }
      }),
    }
  })
}

export function calculateQuestionHeight(
  graphHeight: number,
  tagSize: number
): number {
  return nearestEvenInt(graphHeight / (tagSize * 2))
}

export function sortDataset(dataset: any, direction: 'ASC' | 'DESC' = 'DESC') {
  return dataset
    .slice()
    .sort((respA: any, respB: any) =>
      direction === 'DESC'
        ? (new Date(respB.responseDate) as any) -
          (new Date(respA.responseDate) as any)
        : (new Date(respA.responseDate) as any) -
          (new Date(respB.responseDate) as any)
    )
}

interface IFilters {
  tagIds?: string[]
  responderIds?: string[]
}

export function filterDataset(dataset: any, filters: IFilters) {
  const { tagIds, responderIds } = filters

  return dataset.filter((response: any) => {
    let include = true

    if (Boolean(tagIds?.length)) {
      include = tagIds!.includes(response.tag.id)
    }

    if (Boolean(responderIds?.length)) {
      include = include && responderIds!.includes(response.responder.id)
    }

    return include
  })
}

export function generateQuestionsAndAnswers(timeline: any) {
  if (!timeline) return []

  const uniqueQuestions = timeline.reduce((acc: any, timelineResponse: any) => {
    const responseUniqueQuestions = timelineResponse.questions.filter(
      (question: any) =>
        !acc.some((accQuestion: any) => accQuestion.id === question.id)
    )
    return [...acc, ...responseUniqueQuestions]
  }, [])

  const questionsWithAnswers: any = uniqueQuestions.map(
    ({ answer, ...uniqueQuestion }: any) => {
      return {
        ...uniqueQuestion,
        answers: timeline.map(({ questions: timelineQuestions }: any) => {
          const findAnswer = timelineQuestions.find((timelineQuestion: any) => {
            return timelineQuestion.id === uniqueQuestion.id
          })
          return findAnswer ? findAnswer.answer : 'na'
        }),
        keys: timeline.map(
          ({ formRequestId, tag }: any) =>
            `${uniqueQuestion.id}:${formRequestId}:${tag.id}`
        ),
        emphasis: false,
      }
    }
  )

  return questionsWithAnswers
}

export function generateResumeInfos(response: any) {
  const resumeInfos = ANSWERS.reduce((acc: any, answerOpt: string) => {
    const answerResponses = response.questions.filter(
      ({ answer: questionAnswer }: any) => questionAnswer === answerOpt
    )

    return answerResponses.length
      ? {
          ...acc,
          [answerOpt]: answerResponses.map(
            ({ ultraShortVersion }: any) => ultraShortVersion
          ),
        }
      : acc
  }, {})

  return resumeInfos
}

export function formatAnswerLabel(answer: string, qtd: number) {
  return `${qtd} ${labelText[answer](qtd > 1)}:`
}
