import { Container } from '../clinic-profile-tabs-styles'
import MyInfoGeneralSection from './my-info-general-section'
import MyInfoPasswordSection from './my-info-password-section'

function MyInfoTab(): JSX.Element {
  return (
    <Container>
      <MyInfoGeneralSection />

      <MyInfoPasswordSection />
    </Container>
  )
}

export default MyInfoTab
