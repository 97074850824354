import { useState } from 'react'
import { compareAsc, format, formatDistanceStrict, isToday } from 'date-fns'
import brLocale from 'date-fns/locale/pt-BR'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Avatar,
  Tooltip,
  Stack,
} from '@mui/material'
import {
  PatientsTableActions,
  CaregiverTooltip,
  FilterTableHeader,
  PatientListReminderButton,
  SecondaryConfirmationModal,
  ButtonClinic,
} from 'components'
import { updateViewedFormRequest, sendFormRequestReminder } from 'services'
import { useNotification } from 'hooks'
import { capitalizeFirstLetter } from 'helpers'
import { frequencyTextDict, frequencyDate } from 'helpers/constants'
import { SendStatusEnum } from 'types'
import { headerFields, getComparator } from './patient-list-helpers'
import { Order, Data } from './patient-list-types'
import { PatientTableRow, StatusTag, ReminderText } from './patient-list-styles'

function PatientList({ patients, fetchPatients, onFilter }: any): JSX.Element {
  const [orderBy, setOrderBy] = useState<keyof Data>('firstName')
  const [order, setOrder] = useState<Order>('asc')
  const [reminderPatient, setReminderPatient] = useState<any>(null)
  const [sendingReminder, setSendingReminder] = useState(false)

  const navigate = useNavigate()
  const { errorToast, successToast } = useNotification()

  const handleShowProfile = async (patient: any) => {
    if (patient.responseDate && !patient.viewed) {
      await updateViewedFormRequest(patient.formRequestId)
    }

    if (Boolean(patient.lastResponseDate)) {
      navigate(`/patients/${patient.patientId}/results`)
      return
    }

    navigate(`/patients/${patient.patientId}/profile`)
  }

  const getDiffInDaysFromNow = (date: any) => {
    return formatDistanceStrict(new Date(date), new Date(), {
      addSuffix: true,
      locale: brLocale,
      unit: 'day',
    })
  }

  const getStatus = (patient: any) => {
    if (
      !patient.isActive &&
      !patient.isCompleted &&
      !patient.lastResponseDate
    ) {
      return 'Sem questionários'
    }

    if (
      patient.responseDate ||
      (patient.lastResponseDate && !patient.isActive && !patient.isCompleted)
    ) {
      return 'Respondido'
    }

    if (patient.formRequestSendStatus === SendStatusEnum.SCHEDULED) {
      return 'Envio programado'
    }

    const diffInDays = getDiffInDaysFromNow(patient.createdAt)

    return isToday(new Date(patient.createdAt))
      ? 'Enviado hoje'
      : `Aguardando ${diffInDays}`
  }

  const getTooltip = (patient: any) => {
    const { startDate, lastRun, frequencyName } = patient.formRequestFrequency

    const sanitizedStartDate = new Date(startDate).setHours(0, 0, 0, 0)
    const sanitizedLastRun = new Date(lastRun).setHours(0, 0, 0, 0)

    const isStartDateAfterLastRun =
      compareAsc(sanitizedStartDate, sanitizedLastRun) === 1

    return `Próximo envio ${format(
      isStartDateAfterLastRun
        ? new Date(startDate)
        : frequencyDate(lastRun)[frequencyName as never],
      'dd MMM yy',
      { locale: brLocale }
    )}`
  }

  const getScheduleText = (patient: any) => {
    const sendDate = new Date(patient.formRequestSendDate).setHours(0, 0, 0, 0)

    return `Envio em ${format(sendDate, 'dd MMM yy', {
      locale: brLocale,
    })}`
  }

  const handleSendReminder = async (patient: any) => {
    try {
      setSendingReminder(true)
      if (!patient) return

      await sendFormRequestReminder(patient.formRequestId)

      successToast('Lembrete enviado com sucesso!')
      setSendingReminder(false)
      setReminderPatient(null)
    } catch (error: any) {
      errorToast('Não foi possível enviar, tente novamente.')
      console.error(error)
      setSendingReminder(false)
    }
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      alignItems='center'
      flex={1}
    >
      <TableContainer
        component={Paper}
        sx={{ marginTop: '16px', boxShadow: 'none' }}
      >
        <Table aria-label='tabela de pacientes'>
          <FilterTableHeader
            headerFields={headerFields}
            onFilter={onFilter}
            orderBy={orderBy}
            order={order}
            setOrderBy={setOrderBy}
            setOrder={setOrder}
          />
          <TableBody>
            <TableRow>
              <TableCell colSpan={6} sx={{ border: 'none', height: '40px' }} />
            </TableRow>
            {patients
              .sort(getComparator(order, orderBy))
              .map((patient: any) => {
                const status = getStatus(patient)

                const isWaiting = status.includes('Aguardando')

                return (
                  <PatientTableRow
                    onClick={() => handleShowProfile(patient)}
                    key={patient.user.id}
                  >
                    <TableCell width={500}>
                      <Box display='flex' alignItems='center'>
                        <Avatar sx={{ marginRight: '8px' }}>
                          {patient.firstName[0] || 'W'}
                        </Avatar>
                        <span>
                          <b>{patient.firstName}</b>
                          {` ${patient.lastName}`}
                        </span>
                        {patient.isTutor ? (
                          <CaregiverTooltip caregiver={patient.caregiver} />
                        ) : (
                          ''
                        )}
                      </Box>
                    </TableCell>
                    <TableCell width={100}>{patient.age}</TableCell>
                    <TableCell width={180}>
                      {patient.lastResponseDate
                        ? format(
                            new Date(patient.lastResponseDate),
                            'dd MMM yy',
                            {
                              locale: brLocale,
                            }
                          )
                        : ' - '}
                    </TableCell>
                    <TableCell>
                      <Box display='flex' alignItems='center'>
                        {status}
                        {isWaiting && (
                          <PatientListReminderButton
                            patient={patient}
                            setReminderPatient={setReminderPatient}
                          />
                        )}

                        {patient?.formRequestFrequency?.isActive && (
                          <Tooltip
                            title={getTooltip(patient)}
                            placement='top'
                            arrow
                            componentsProps={{
                              tooltip: {
                                style: {
                                  fontFamily: 'Quicksand',
                                },
                              },
                            }}
                          >
                            <StatusTag>
                              {capitalizeFirstLetter(
                                frequencyTextDict[
                                  patient.formRequestFrequency.frequencyName
                                ]
                              )}
                            </StatusTag>
                          </Tooltip>
                        )}
                        {patient.formRequestSendStatus ===
                          SendStatusEnum.SCHEDULED &&
                          !patient.formRequestFrequency && (
                            <Tooltip
                              title={getScheduleText(patient)}
                              placement='top'
                              arrow
                              componentsProps={{
                                tooltip: {
                                  style: {
                                    fontFamily: 'Quicksand',
                                  },
                                },
                              }}
                            >
                              <StatusTag>{frequencyTextDict['once']}</StatusTag>
                            </Tooltip>
                          )}
                        {patient.responseDate && !patient.viewed && (
                          <StatusTag isNew>Novo</StatusTag>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell
                      width={200}
                      align='center'
                      onClick={(e) => e.stopPropagation()}
                    >
                      <PatientsTableActions
                        patientName={`${patient.firstName} ${patient.lastName}`.trim()}
                        lastFormId={patient.lastFormId}
                        userId={patient.user.id}
                        patientId={patient.patientId}
                        caregiverName={
                          patient.caregiver
                            ? `${patient.caregiver.firstName} ${patient.caregiver.lastName}`.trim()
                            : ''
                        }
                        formRequestFrequency={patient.formRequestFrequency}
                        fetchPatients={fetchPatients}
                        hasResults={Boolean(patient.lastResponseDate)}
                        viewed={patient.viewed}
                        formRequestId={patient.formRequestId}
                        responseDate={patient.responseDate}
                      />
                    </TableCell>
                  </PatientTableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>

      {reminderPatient && (
        <SecondaryConfirmationModal
          isOpen={reminderPatient}
          title='Deseja mesmo enviar um lembrete?'
          hideButtons
          onClose={() => setReminderPatient(null)}
          content={
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              justifyContent='space-between'
              flexGrow={1}
              onClick={(e: any) => e.stopPropagation()}
            >
              <Stack mt={2} mb={3}>
                <ReminderText>Uma mensagem lembrando que existem</ReminderText>
                <ReminderText>perguntas aguardando resposta será</ReminderText>
                <ReminderText>
                  enviada para{' '}
                  {reminderPatient?.caregiver
                    ? `${reminderPatient?.caregiver?.firstName} (${reminderPatient?.caregiver?.relation?.name})`
                    : reminderPatient?.firstName}{' '}
                  agora
                </ReminderText>
              </Stack>
              <Stack direction='row' spacing={4} mt={3} mb={3}>
                <ButtonClinic
                  variant='outlined'
                  onClick={() => setReminderPatient(null)}
                  width={140}
                  color='purple'
                  size='small'
                  fontWeight='bold'
                >
                  Cancelar
                </ButtonClinic>
                <ButtonClinic
                  variant='contained'
                  onClick={() => handleSendReminder(reminderPatient)}
                  width={140}
                  color='purple'
                  size='small'
                  fontWeight='bold'
                  loading={sendingReminder}
                >
                  {sendingReminder ? 'Enviando' : 'Enviar'}
                </ButtonClinic>
              </Stack>
            </Box>
          }
        />
      )}
    </Box>
  )
}

export default PatientList
