import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'

export const OptionLabel = styled(Typography)({
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '17px',
  textAlign: 'center',
  color: '#657787',
})
