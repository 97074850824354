import client from 'services/client'
import awsClient from 'services/aws-client'

export const createRecordFile = async (
  filesToUpload: any,
  recordId: string
) => {
  try {
    const filesArray = Array.from(filesToUpload) || []
    const validFiles: any = []
    const uploadedFiles: any = []

    // max file size 5MB
    filesArray.forEach((file: any) => {
      const fileSize = file.size / 1024
      if (fileSize > 10240) {
        throw new Error(`Arquivo ${file.name} maior que 10MB`)
      } else {
        validFiles.push(file)
      }
    })

    // upload file
    for (const file of validFiles) {
      const body = { files: [{ name: file.name, type: file.type }] }

      const {
        data: [{ id, name, signedUrl }],
      }: any = await client.post(
        `/patient-medical-records/${recordId}/files`,
        body
      )
      await awsClient.put(signedUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      })
      await client.post(
        `/patient-medical-records/${recordId}/files/${id}/confirm`
      )

      uploadedFiles.push({ id, name })
    }

    return uploadedFiles
  } catch (error: any) {
    throw error
  }
}

export const getRecordFile = async (fileId: string, recordId: string) => {
  try {
    const { data: file }: any = await client.get(
      `/patient-medical-records/${recordId}/files/${fileId}`
    )

    const { data } = await awsClient.get(file.link, {
      responseType: 'blob',
      headers: {
        'Content-Type': file.type,
      },
    })
    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const deleteRecordFile = async (fileId: string, recordId: string) => {
  try {
    await client.delete(`/patient-medical-records/${recordId}/files/${fileId}`)
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}
