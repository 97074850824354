/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState, useEffect, useCallback } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import { ButtonClinic } from 'components'
import ResultsIcon from 'components/icons/results'
import PdfIcon from 'components/icons/pdf-icon'
import { Sidebar } from 'components/patient-central'
import {
  fetchPatient,
  getPatientAttentions,
  getPatientResponders,
} from 'services'
import {
  useAuthContext,
  PatientCentralContext,
  useResultsContext,
} from 'contexts'
import { useNotification } from 'hooks'
import { registerTrack } from 'helpers'
import { IPatientCentralLayoutProps } from './patient-central-layout-types'
import { Container, MenuButton } from './patient-central-layout-styles'
import { UserStatus } from 'types/user-status'
import { SecondaryConfirmationModal } from 'components/index'

const menuOptions: any = [
  {
    generateIcon: (color?: string) => (
      <PersonOutlineOutlinedIcon sx={{ color: color || '#657787' }} />
    ),
    label: 'Dados do paciente',
    route: '/profile',
  },
  {
    generateIcon: (color?: string) => (
      <AssignmentOutlinedIcon sx={{ color: color || '#657787' }} />
    ),
    label: 'Anotações',
    route: '/patient_records',
  },
  {
    generateIcon: (color?: string) => (
      <ResultsIcon color={color || '#657787'} />
    ),
    label: 'Resultados',
    route: '/results',
  },
  {
    generateIcon: (color?: string) => <PdfIcon color={color || '#657787'} />,
    label: 'Relatórios',
    route: '/reports',
  },
]

function PatientCentralLayout({
  children,
  parentPatientAttentions = null,
  refetchPatientAttentions = null,
  sidebarSize = 'normal',
}: IPatientCentralLayoutProps): JSX.Element {
  const [patient, setPatient] = useState<any>(null)
  const [disabledResults, setDisabledResults] = useState(true)
  const [patientAttentions, setPatientAttentions] = useState<any>(
    parentPatientAttentions || []
  )
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { handleGoBackResume } = useResultsContext()

  useEffect(() => {
    if (!parentPatientAttentions) return

    setPatientAttentions(parentPatientAttentions)
  }, [parentPatientAttentions])

  const { user } = useAuthContext()
  const { pathname } = useLocation()
  const { patient_id } = useParams()
  const navigate = useNavigate()
  const { errorToast } = useNotification()

  const trackUsersInfosPayload = useMemo(
    () => ({
      clinic_id: user.clinicId,
      patient_id: patient_id,
    }),
    [user, patient_id]
  )

  const fetchPatientAttentions = useCallback(async () => {
    try {
      if (!patient_id) return

      const patientAttentions = await getPatientAttentions(patient_id)
      setPatientAttentions(patientAttentions)
    } catch (error: any) {
      console.error(error.message)
    }
  }, [patient_id])

  const fetchData = async () => {
    try {
      const patient = await fetchPatient(patient_id || '')
      const responders = await getPatientResponders(patient_id as string)

      setPatient(patient)
      setDisabledResults(!Boolean(responders.length))
    } catch (error: any) {
      console.error(error.message)
      errorToast(error.message || 'Erro desconhecido')
      navigate('/')
    }
  }

  useEffect(() => {
    fetchData()
    fetchPatientAttentions()
  }, [errorToast, navigate, patient_id, fetchPatientAttentions])

  const handleNewQuestionnaire = async () => {
    if (!patient) return

    const patientName = `${patient.firstName} ${patient.lastName}`
    const lastFormId = patient.lastFormId

    registerTrack('Clica Novo Questionario', {
      ...trackUsersInfosPayload,
      source: 'results',
    })

    const caregiverName = patient.caregiver
      ? `${patient.caregiver.firstName} ${patient.caregiver.lastName}`.trim()
      : ''

    navigate(
      `/resend_patient?last_form_id=${lastFormId}&patient_name=${patientName}&patient_id=${patient_id}&user_id=${
        patient.user.id
      }${caregiverName ? `&caregiver_name=${caregiverName}` : ''}`
    )
  }

  const handleVerifyUserStatus = async () => {
    if (patient?.user.status === UserStatus.ARCHIVED) {
      setIsModalOpen(true)
    } else {
      handleNewQuestionnaire()
    }
  }

  return (
    <PatientCentralContext.Provider
      value={{
        fetchData,
        patientAttentions,
        fetchPatientAttentions: refetchPatientAttentions
          ? refetchPatientAttentions
          : fetchPatientAttentions,
        patient,
      }}
    >
      <Container display='flex'>
        <Sidebar patient={patient} size={sidebarSize} />

        <SecondaryConfirmationModal
          isOpen={isModalOpen}
          title='Deseja desarquivar o paciente?'
          text='O paciente será desarquivado 
          após o envio do questionário'
          onClose={() => setIsModalOpen(false)}
          onConfirm={handleNewQuestionnaire}
        />

        <Box width='100%'>
          <Box
            width='100%'
            borderBottom='1px solid #D9D9D9'
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            height='56px'
            px={2}
          >
            <Stack
              direction='row'
              spacing={1}
              alignItems='center'
              height='100%'
            >
              {menuOptions.map(({ generateIcon, label, route }: any) => (
                <Tooltip
                  key={label}
                  title={
                    label === 'Resultados' && disabledResults
                      ? 'Ainda não há resultados para este paciente'
                      : ''
                  }
                  placement='top'
                >
                  <span>
                    <MenuButton
                      key={label}
                      startIcon={generateIcon(
                        pathname.includes(route) ? '#F36F60' : '#657787'
                      )}
                      variant='text'
                      onClick={() => {
                        navigate(`/patients/${patient_id}${route}`)
                        if (label === 'Resultados' && handleGoBackResume) {
                          handleGoBackResume()
                        }
                      }}
                      selected={pathname.includes(route)}
                      disabled={label === 'Resultados' && disabledResults}
                    >
                      {label}
                    </MenuButton>
                  </span>
                </Tooltip>
              ))}
            </Stack>

            <ButtonClinic
              width={192}
              variant='contained'
              size='small'
              color='green'
              fontWeight='bold'
              onClick={handleVerifyUserStatus}
              icon='send'
              iconPosition='start'
            >
              Enviar questionário
            </ButtonClinic>
          </Box>
          <Box py={2} px={2} display='flex' flexDirection='column' flexGrow={1}>
            {children}
          </Box>
        </Box>
      </Container>
    </PatientCentralContext.Provider>
  )
}

export default PatientCentralLayout
