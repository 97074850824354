import { Box, Dialog, styled, Typography } from '@mui/material'

export const DialogStyled = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '24px',
    minHeight: '326px',
    width: '594px',
  },
}))

export const ContainerStyled = styled(Box)(() => ({
  display: 'flex',
  flexGrow: '1',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '24px',
}))

export const HeaderContainerStyled = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '86px',
  padding: '8px',
  backgroundColor: '#F5F5F9',
}))

export const FooterContainerStyled = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '32px',
  marginBottom: '24px',
}))

export const TitleStyled = styled(Typography)(() => ({
  color: '#8D92BE',
  fontSize: '20px',
  fontFamily: 'Quicksand',
  fontWeight: '700',
  lineHeight: '48px',
}))

export const TextStyled = styled(Typography)(() => ({
  color: '#596B7B',
  textAlign: 'center',
  fontSize: '20px',
  fontFamily: 'Zen Kaku Gothic Antique',
  lineHeight: '28px',
}))
