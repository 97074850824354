import { createContext, useContext } from 'react'

type PatientType = 'you' | 'he' | 'she'

interface SignUpCaregiverContextType {
  goNextStep: () => void
  patient: PatientType
  patientName: string
  type: string
  sendValidationCode: (arg0: any, arg1: any) => void
  loadingSendCode: boolean
}

export const SignUpCaregiverContext = createContext<SignUpCaregiverContextType>(
  {
    goNextStep: () => {},
    patient: 'you',
    patientName: '',
    type: '',
    sendValidationCode: () => {},
    loadingSendCode: false,
  }
)

export const useSignUpCaregiverContext = () => {
  const context = useContext(SignUpCaregiverContext)
  return context
}
