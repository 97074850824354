import { styled } from '@mui/material/styles'
import { TableRow } from '@mui/material'

export const PatientTableRow = styled(TableRow)({
  zIndex: '0',
  cursor: 'pointer',
  transition: '0.4s',
  '&:hover': {
    opacity: '0.7',
    backgroundColor: '#F9F9FB'
  },
  '&:last-child td, &:last-child th': {
    border: 0
  },
  'td': {
    fontFamily: "'Zen Kaku Gothic Antique', sans-serif",
    fontSize: '16px'
  }
})
