import { Box, Typography } from '@mui/material'
import { GroupIcon, StyledTooltip } from './caregiver-tooltip-styles'

function makeTitle({ firstName, lastName }: any) {
  return (
    <Box p={3}>
      <Typography mb={2}><b>Responsável</b></Typography>
      <Typography>{`${firstName} ${lastName}`}</Typography>
    </Box>
  )
}


function CaregiverTooltip({ caregiver }: any): JSX.Element {
  return (
    <StyledTooltip
      title={makeTitle(caregiver)}
    >
      <GroupIcon />
    </StyledTooltip>
  )
}

export default CaregiverTooltip
