import { Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const InviteText = styled(Typography)({
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '20px',
  lineHeight: '26px',
  textAlign: 'center',
  color: '#657787',
})

export const MethodContainer = styled(Stack)({
  width: '80px',
  alignItems: 'center',
  cursor: 'pointer',
})

export const MethodText = styled(Typography)({
  fontFamily: 'Zen Kaku Gothic Antique',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '23px',
  color: '#F36F60',
})

export const MethodIconContainer = styled(Stack)({
  width: '44px',
  height: '44px',
  border: '1px solid #F36F60',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
})
