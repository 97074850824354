import { useEffect, useRef, useState } from 'react'
import { Controller, Control } from 'react-hook-form'
import { MenuItem, SxProps, Stack, Tooltip } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import {
  SelectMuiStyled,
  LabelText,
  HelperText,
  FieldContainer,
} from './select-styles'

interface OptionType {
  value: string
  label: string
}

interface SelectProps {
  name: string
  control: Control<any>
  options: OptionType[]
  placeholder?: string
  disabled?: boolean
  label?: string
  fieldInfo?: string
}

function SelectStyled({
  name,
  control,
  placeholder = 'Selecione',
  options,
  label = '',
  fieldInfo,
  disabled,
}: SelectProps): JSX.Element {
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  })
  const [isSelectOpen, setIsSelectOpen] = useState(false)
  const [hasSelectedItem, setHasSelectedItem] = useState(false)
  const [hasSpace, setHasSpace] = useState(true)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const updateDimensions = () => {
      if (ref?.current) {
        setDimensions({
          width: ref.current.offsetWidth,
          height: ref.current.getBoundingClientRect().height,
          x: ref.current.getBoundingClientRect().x,
          y: ref.current.getBoundingClientRect().y,
        })
      }
    }

    updateDimensions()

    window.addEventListener('resize', updateDimensions)
    window.addEventListener('scroll', updateDimensions)
    
    return () => {
      window.removeEventListener('resize', updateDimensions)
      window.addEventListener('scroll', updateDimensions)
    }
  }, [])

  const optionsReportsHeight = options.length < 6 ? options.length * 37 : 185

  const anchorRectValues = {
    left: `${(dimensions.x - 12).toString()}px !important`,
    top: hasSpace
      ? `${(dimensions.y + dimensions.height).toString()}px !important`
      : !hasSpace
      ? `${(dimensions.y - optionsReportsHeight).toString()}px !important`
      : '',
  }

  const handleDropdownSpace = () => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect()
      const spaceBelow = window.innerHeight - rect.bottom
      if (spaceBelow < optionsReportsHeight) {
        setHasSpace(false)
      } else {
        setHasSpace(true)
      }
    }
  }

  const sx: SxProps = {
    width: dimensions.width,
    display: 'flex',
    border: '1.5px solid #BDC1DD',
    borderTop: hasSpace ? 'none' : '',
    borderBottom: hasSpace ? '' : 'none',
    borderRadius: hasSpace ? '0 0 12px 12px' : '12px 12px 0 0',
    boxShadow: 'none',
    ml: '12px',
    left: `${anchorRectValues.left}`,
    top: `${anchorRectValues.top}`,
    maxHeight: optionsReportsHeight,
    scrollbarColor: '#657787 transparent',

    '& .MuiMenuItem-root': {
      fontFamily: 'Quicksand',
      color: '#596B7B',
      fontSize: '16px',
      lineHeight: '24px',
      backgroundColor: 'transparent',
    },
    '& .MuiMenuItem-root:hover': {
      backgroundColor: '#F2F3F7',
    },
    '& .MuiList-root': {
      flexGrow: '1',
      paddingTop: 0,
    },
    '& .MuiMenuItem-root.Mui-selected, & .MuiMenuItem-root.Mui-selected:hover':
      {
        backgroundColor: '#F2F3F7',
      },
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value, onChange },
        fieldState: { invalid, error },
      }) => (
        <FieldContainer
          variant='standard'
          disabled={disabled}
          error={invalid}
          fullWidth
        >
          {label && <Label label={label} info={fieldInfo} />}
          <SelectMuiStyled
            hasSelectedItem={hasSelectedItem}
            value={value}
            ref={ref}
            onChange={onChange}
            open={isSelectOpen}
            hasSpace={hasSpace}
            onOpen={() => {
              handleDropdownSpace()
              setIsSelectOpen(true)
            }}
            onClose={() => {
              setIsSelectOpen(false)
            }}
            SelectDisplayProps={{
              style: {
                backgroundColor: 'transparent',
                zIndex: 1,
              },
            }}
            isOpen={isSelectOpen}
            displayEmpty
            renderValue={(selected: unknown) => {
              const findOption = options.find(
                (option: OptionType) => selected === option.value
              )

              setHasSelectedItem(Boolean(findOption?.label))

              return findOption?.label || placeholder
            }}
            IconComponent={() =>
              isSelectOpen ? (
                <ExpandLessRoundedIcon
                  sx={{
                    cursor: disabled ? 'default' : 'pointer',
                    fill: '#CDCDCD',
                  }}
                  onClick={() => {
                    !disabled && setIsSelectOpen(false)
                  }}
                />
              ) : (
                <ExpandMoreRoundedIcon
                  sx={{
                    cursor: disabled ? 'default' : 'pointer',
                    fill: '#CDCDCD',
                  }}
                  onClick={() => {
                    if (!disabled) {
                      handleDropdownSpace()
                      setIsSelectOpen(true)
                    }
                  }}
                />
              )
            }
            MenuProps={{ PaperProps: { sx } }}
          >
            {options.map(({ value, label }: OptionType) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </SelectMuiStyled>
          {invalid && (
            <HelperText>{error?.message || 'Erro desconhecido'}</HelperText>
          )}
        </FieldContainer>
      )}
    />
  )
}

function Label({ label, info }: any): JSX.Element {
  return (
    <Stack
      width='100%'
      mb='4px'
      direction='row'
      alignItems='center'
      justifyContent='flex-start'
      spacing={1}
    >
      <LabelText>{label}</LabelText>
      {info && (
        <Tooltip title={info} arrow placement='top'>
          <InfoOutlinedIcon
            sx={{ height: '18px', width: '18px', fill: '#657787' }}
          />
        </Tooltip>
      )}
    </Stack>
  )
}

export default SelectStyled
