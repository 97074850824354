import ButtonBase from '@mui/material/ButtonBase'
import { styled } from '@mui/material/styles'

const buttonColors: any = {
  purple: { base: '#8D92BE', shadow: '#7E82A6', disabled: '#DEDEDE' },
}

export const Button = styled(ButtonBase)<any>(
  ({ color = 'normal', size = 42 }) => {
    return {
      width: size,
      height: size,
      borderRadius: '50px',
      border: '1px solid transparent',
      backgroundColor: buttonColors[color].base,
      color: '#FFFFFF',
      '&:hover, &:active': {
        boxShadow: 'none',
        backgroundColor: buttonColors[color].shadow,
        borderColor: buttonColors[color].shadow,
        color: '#FFFFFF',
      },
      '&:disabled': {
        boxShadow: 'none',
        backgroundColor: buttonColors[color].disabled,
        borderColor: buttonColors[color].disabled,
        color: '#FFFFFF',
      },
    }
  }
)
