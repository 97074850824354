import { ComponentProps, FC } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import brLocale from 'date-fns/locale/pt-BR'

function DateLocalizationProvider({
  children,
}: ComponentProps<FC>): JSX.Element {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
      {children}
    </LocalizationProvider>
  )
}

export default DateLocalizationProvider
