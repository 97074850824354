import { useState, useEffect, useCallback } from 'react'
import { getPatientTags, createPatientTag, deletePatientTag } from 'services'
import { useNotification } from 'hooks'
import { StyledChip, StyledIconButton } from './patient-tags-styles'
import { Box, IconButton, InputAdornment, TextField } from '@mui/material'
import { AddOutlined, Close } from '@mui/icons-material'

const PatientTags = ({ patientId, patientAge }: any) => {
  const [tags, setTags] = useState<any>([])
  const [isAdding, setIsAdding] = useState<boolean>(false)
  const [hoverTag, setHoverTag] = useState<string>('')
  const { errorToast } = useNotification()

  const getTags = useCallback(async () => {
    try {
      if (!patientId) return

      const tags = await getPatientTags(patientId)
      setTags(tags)
    } catch (error: any) {
      console.error(error.message)
    }
  }, [patientId])

  useEffect(() => {
    getTags()
  }, [getTags])

  async function deleteTag(tagId: string) {
    try {
      await deletePatientTag(tagId)
      getTags()
    } catch (error: any) {
      console.error(error.message)
      errorToast(error.message || 'Erro desconhecido')
    }
  }

  async function createTag(text: string) {
    try {
      await createPatientTag(patientId, text)
      setIsAdding(false)
      getTags()
    } catch (error: any) {
      console.error(error.message)
      errorToast(error.message || 'Erro desconhecido')
    }
  }

  async function handleTextField(e: any) {
    if ('Escape' === e.key) {
      return setIsAdding(false)
    }
    if ('Enter' === e.key) {
      await createTag(e.target.value)
    }
  }

  return (
    <Box pt={1} mb={3} maxHeight='200px' sx={{ overflowY: 'auto' }}>
      <StyledChip label={`Idade: ${patientAge}`} />
      {tags.map(({ id, text }: any) => (
        <StyledChip
          key={id}
          label={text}
          onMouseEnter={() => setHoverTag(id)}
          onMouseLeave={() => setHoverTag('')}
          onDelete={
            id === hoverTag ? async () => await deleteTag(id) : undefined
          }
        />
      ))}
      {!isAdding && (
        <StyledIconButton onClick={() => setIsAdding(true)}>
          <AddOutlined sx={{ height: '16px', width: '16px' }} />
        </StyledIconButton>
      )}
      {isAdding && (
        <TextField
          autoFocus
          variant='standard'
          onKeyDown={handleTextField}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={() => setIsAdding(false)} edge='end'>
                  <Close
                    color='primary'
                    sx={{ height: '16px', width: '16px' }}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          inputProps={{
            maxLength: 30,
          }}
        />
      )}
    </Box>
  )
}

export default PatientTags
