import client from 'services/client'

export const createPatientReport = async (body: any) => {
  try {
    const { data }: any = await client.post('/patient-report', body)

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const getPatientReport = async (id: string) => {
  try {
    const { data }: any = await client.get(`/patient-report/${id}`)

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const getAllPatientReports = async (patientId: string) => {
  try {
    const { data }: any = await client.get(
      `/patient-report/patient/${patientId}`
    )

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const setPatientReportComplete = async (id: string) => {
  try {
    const { data }: any = await client.post(`/patient-report/${id}/complete`)

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}
