import { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import {
  Container,
  Section,
  TitleText,
  FilterTextField,
} from './patient-profile-styles'
import {
  ClinicLayout,
  PatientCentralLayout,
  LogTimeline,
  PatientInfo,
} from 'components'
import { fetchPatient, getPatientLogs, getPatientInformants } from 'services'
import { useAuthContext } from 'contexts'
import { findInWord, registerPageAccess, sortDates } from 'helpers'
import { FETCHING, IDLE } from 'helpers/constants'
import { Box, Divider, InputAdornment } from '@mui/material'
import { Search } from '@mui/icons-material'
import { useDebounce } from 'hooks'

const getLogType = (type: string): any => {
  return {
    system: 'Sistema',
    clinic: 'Clínico',
    informant: 'Informante',
    patient: 'Paciente',
    caregiver: 'Responsável',
  }[type]
}

const sortByDate = (a: any, b: any) =>
  sortDates(a.createdAt, b.createdAt, 'desc')

function PatientProfile(): JSX.Element {
  const [status, setStatus] = useState(FETCHING)
  const [patient, setPatient] = useState()
  const [patientInformants, setPatientInformants] = useState([])
  const [logs, setLogs] = useState([])
  const [filteredLogs, setFilteredLogs] = useState([])
  const { patient_id = '' } = useParams()
  const { user } = useAuthContext()

  // usado pra calcular a altura maxima do divider
  const dividerRef = useRef<any>()
  const [dividerHeight, setDividerHeight] = useState<any>(0)

  // debounce search
  const [searchTerm, setSearchTerm] = useState('')
  const debounceSearch = useDebounce(searchTerm, 500)

  useEffect(() => {
    const filterLogs = (filter: string = '') => {
      if (filter) {
        return setFilteredLogs(
          logs.filter((i: any) => {
            return (
              findInWord(getLogType(i.type), filter) ||
              findInWord(i.message, filter)
            )
          })
        )
      }
      return setFilteredLogs(logs)
    }

    filterLogs(debounceSearch)
  }, [debounceSearch, logs])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setStatus(FETCHING)
        const [patient, logs, patientInformants] = await Promise.all([
          fetchPatient(patient_id),
          getPatientLogs(patient_id),
          getPatientInformants(patient_id),
        ])

        const orderedLogs = logs.sort(sortByDate)

        setPatient(patient)
        setPatientInformants(patientInformants)
        setLogs(orderedLogs)
        setFilteredLogs(orderedLogs)
      } catch (error: any) {
        console.error(error.message)
      } finally {
        setStatus(IDLE)
      }
    }

    fetchData()
  }, [patient_id])

  useEffect(() => {
    setDividerHeight(`calc(98vh - ${dividerRef.current?.offsetTop || 0}px)`)
  }, [status])

  useEffect(() => {
    registerPageAccess('Perfil Paciente', { clinic_id: user.clinicId })
  }, [user])

  if (status !== IDLE) return <></>

  return (
    <ClinicLayout>
      <PatientCentralLayout>
        <Container>
          <Box flexGrow={1} display='flex' flexDirection='row' width='100%'>
            <Section>
              <TitleText>Dados do paciente</TitleText>
              <PatientInfo
                patient={patient}
                patientInformants={patientInformants}
              />
            </Section>
            <Divider
              ref={dividerRef}
              sx={{
                color: '#ECECEC',
                minHeight: dividerHeight,
                borderBottomWidth: '2px',
              }}
              orientation='vertical'
              flexItem
            />
            <Section>
              <FilterTextField
                onChange={(e) => setSearchTerm(e.target.value)}
                type='text'
                size='small'
                hiddenLabel
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Search style={{ fill: '#BDBDBD' }} />
                    </InputAdornment>
                  ),
                  placeholder: 'Pesquisar log',
                }}
              />
              <TitleText>Log do sistema</TitleText>
              <LogTimeline logs={filteredLogs} />
            </Section>
          </Box>
        </Container>
      </PatientCentralLayout>
    </ClinicLayout>
  )
}

export default PatientProfile
