import { Box, Stack } from '@mui/material'
import {
  TitleText,
  SubtitleText,
  DescriptionText,
  ItemText,
  SigninDescriptionText,
} from './logo-text-container-styles'

export function LogoTextContainerSignup(): JSX.Element {
  return (
    <Box
      px={'52px'}
      display='flex'
      flexDirection='column'
      justifyContent='flex-end'
      mb={4}
    >
      <TitleText>Estamos em pré-lançamento!</TitleText>
      <Box mb={'8px'}>
        <HorizontalLine />
      </Box>
      <SubtitleText>Venha conhecer e ser parte desta verdadeira</SubtitleText>
      <SubtitleText>
        transformação nos cuidados em <b>saúde mental.</b>
      </SubtitleText>

      <Box mt={'46px'} mb={'35px'}>
        <DescriptionText>
          Crie agora mesmo sua conta e aproveite
        </DescriptionText>
        <DescriptionText>
          os <b>benefícios</b> do pré-lançamento:
        </DescriptionText>
      </Box>

      <Stack spacing={'12px'}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <VerticalLine />
          <ItemText>
            Acesso gratuito a <b>todas</b> as funcionalidades
          </ItemText>
        </Stack>

        <Stack direction='row' spacing={1} alignItems='center'>
          <VerticalLine />
          <ItemText>
            Número <b>ilimitado</b> de pacientes
          </ItemText>
        </Stack>

        <Stack direction='row' spacing={1} alignItems='center'>
          <VerticalLine />
          <ItemText>
            Manutenção, após o lançamento, do acesso aos dados coletados
          </ItemText>
        </Stack>

        <Stack direction='row' spacing={1} alignItems='center'>
          <VerticalLine />
          <ItemText>
            Sem cartão de crédito: seu <b>feedback</b> vale ouro para nós
          </ItemText>
        </Stack>
      </Stack>
    </Box>
  )
}

export function LogoTextContainerSignin(): JSX.Element {
  return (
    <Box
      px={10}
      display='flex'
      flexDirection='column'
      justifyContent='flex-end'
      mb={9}
    >
      <TitleText>Cuidado na medida certa.</TitleText>
      <Box my={1}>
        <HorizontalLine />
      </Box>
      <SigninDescriptionText>
        Comece agora mesmo a avaliar e acompanhar o quadro clínico
      </SigninDescriptionText>
      <SigninDescriptionText>
        dos seus pacientes. Transforme dados em informações clínicas
      </SigninDescriptionText>
      <SigninDescriptionText>
        relevantes e obtenha resultados mais efetivos.
      </SigninDescriptionText>
    </Box>
  )
}

function HorizontalLine() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='36'
      height='2'
      viewBox='0 0 36 2'
      fill='none'
    >
      <path d='M35 1L0.999999 1' stroke='white' stroke-linecap='round' />
    </svg>
  )
}

function VerticalLine() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='2'
      height='22'
      viewBox='0 0 2 22'
      fill='none'
    >
      <path d='M1 1L0.999999 21' stroke='white' stroke-linecap='round' />
    </svg>
  )
}
