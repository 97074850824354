import { useFormContext } from 'react-hook-form'
import { Box } from '@mui/material'
import { CodeField } from 'components/inputs'
import { contexts } from '../helpers/contexts'
import { Title, InfoText, ResendButton } from '../styles/general-steps-styles'

interface IEmailValidationCodeStep {
  type: 'patient' | 'caregiver' | 'informant'
}

function EmailValidationCodeStep({
  type,
}: IEmailValidationCodeStep): JSX.Element {
  const { control } = useFormContext()
  const { sendValidationCode, loadingSendCode } = contexts[type]()

  return (
    <>
      <Title>Para sua segurança, enviamos um código para o seu e-mail.</Title>
      <Box mt={3} mb={10}>
        <InfoText>Digite o código recebido para validar sua conta.</InfoText>
      </Box>
      <Box display='flex' mb={5} justifyContent='center'>
        <CodeField name='emailValidationCode' control={control} />
      </Box>
      <Box display='flex' justifyContent='center'>
        <ResendButton
          onClick={
            loadingSendCode ? () => {} : () => sendValidationCode('email', true)
          }
          disabled={loadingSendCode}
        >
          {loadingSendCode ? 'Aguarde...' : 'Enviar novamente'}
        </ResendButton>
      </Box>
    </>
  )
}

export default EmailValidationCodeStep
