import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const LogType = styled(Typography)`
  font-family: 'Zen Kaku Gothic Antique';
  font-weight: 500;
  font-size: 14px;
  color: #353F48;
`
export const LogMessage = styled(Typography)`
  font-family: 'Zen Kaku Gothic Antique';
  font-weight: 400;
  font-size: 14px;
  color: #657787;
`
export const LogDate = styled(Typography)`
  font-family: 'Zen Kaku Gothic Antique';
  font-weight: 400;
  font-size: 12px;
  color: #657787;
` 
