import Menu, { MenuProps } from '@mui/material/Menu'
import { styled } from '@mui/material/styles'

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: -20,
      horizontal: 232,
    }}
    {...props}
  />
))({
  '& .MuiPaper-root': {
    borderRadius: '16px',
    border: '1px solid #F5F5F5',
    minWidth: '248px',
    width: '248px',
    paddingTop: '16px',
    paddingBottom: '16px',
    color: 'rgb(55, 65, 81)',
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.05)',
    gap: '8px',
    backgroundColor: '#F9F9F9',
    '& .MuiMenuItem-root': {
      padding: 0,
      color: '#596B7B',
      textAlign: 'center',
      fontFamily: 'Quicksand',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      paddingLeft: '24px',
      paddingRight: '24px',
      paddingTop: '8px',
      paddingBottom: '8px',
      '&:active, &:hover': {
        backgroundColor: '#F4F4F4',
      },
    },
    '& .MuiList-root': {
      padding: 0,
    },
  },
})
