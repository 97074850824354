import { useForm } from 'react-hook-form'
import { signUpInformantResolver } from 'resolvers'

function useSignUpInformantForm() {
  return useForm({
    mode: 'onBlur',
    resolver: signUpInformantResolver,
    defaultValues: {
      cpf: '',
      email: '',
      emailValidationCode: '',
      whatsapp: '',
      whatsappValidationCode: '',
      communication: [],
      firstName: '',
      lastName: '',
      environment: '',
      relation: '',
      password: '',
      confirmPassword: '',
      acceptedShare: false,
      acceptedTermsAndPolicies: false,
    },
  })
}

export default useSignUpInformantForm
