import Box from '@mui/material/Box'
import { IPatientReportIcon } from './patient-report-icon-types'

function NoIcon({
  size = 10,
  color = '#000000',
}: IPatientReportIcon): JSX.Element {
  return (
    <Box
      sx={{
        width: size,
        height: size,
        borderRadius: '50%',
        border: `1px solid ${color}`,
      }}
    />
  )
}

export default NoIcon
