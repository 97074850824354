import { useState } from 'react'
import {
  CakeOutlined,
  FemaleOutlined,
  MaleOutlined,
  AlternateEmailOutlined,
  GroupOutlined,
  PersonOutlined,
  LocalPhoneOutlined,
} from '@mui/icons-material'
import { Box, Stack, Tooltip } from '@mui/material'
import SecondaryConfirmationModal from 'components/secondary-confirmation-modal/secondary-confirmation-modal'
import { fromDate } from 'helpers'
import { formatCpf, formatPhoneBr } from 'helpers/formatters'
import { activateUser, archiveUser } from 'services'
import { useNotification } from 'hooks'
import { usePatientCentralContext } from 'contexts'
import { UserStatus } from 'types/user-status'
import cpfIcon from 'images/icons/cpf-icon.svg'
import {
  InfoTitle,
  InfoText,
  CenterBox,
  InfoBox,
  StyledVerifiedIcon,
  StatusLabelStyled,
  SwitchStyled,
} from './patient-info-styles'

function PatientInfo({ patient, patientInformants }: any): JSX.Element {
  const [isActive, setIsActive] = useState(
    !!(patient.user.status === UserStatus.ACTIVE)
  )
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { errorToast } = useNotification()
  const { fetchData } = usePatientCentralContext()

  const isMale = 'HE' === patient.pronoun
  const pronoun = 'HE' === patient.pronoun ? 'Masculino' : 'Feminino'

  const handleStatusChange = async (checked: boolean) => {
    if (checked) {
      try {
        await activateUser(patient.user.id)
        fetchData()
        setIsActive(true)
      } catch (error: any) {
        errorToast(error.message)
      }
    } else {
      setIsModalOpen(true)
    }
  }

  const onConfirm = async () => {
    try {
      await archiveUser(patient.user.id)
      fetchData()
      setIsActive(false)
      setIsModalOpen(false)
    } catch (error: any) {
      errorToast(error.message)
    }
  }

  return (
    <>
      {/* Sobre  */}
      <InfoBox>
        <InfoTitle>Sobre</InfoTitle>
        <CenterBox>
          <PersonOutlined
            sx={{
              fontSize: 20,
              color: '#596B7B',
              marginRight: '10px',
            }}
          />
          <InfoText>
            {patient.firstName} {patient.lastName}
          </InfoText>
        </CenterBox>
        <CenterBox>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            sx={{ marginRight: '10px' }}
          >
            <img src={cpfIcon} height='14px' alt='Logo CPF' />
          </Box>
          <InfoText>
            {patient?.cpf ? formatCpf(patient.cpf) : 'Sem CPF'}
          </InfoText>
        </CenterBox>
        <CenterBox>
          <CakeOutlined
            sx={{
              fontSize: 20,
              color: '#596B7B',
              marginRight: '10px',
            }}
          />
          <InfoText>{fromDate(patient.birthdate)}</InfoText>
        </CenterBox>
        <CenterBox>
          {isMale ? (
            <MaleOutlined
              sx={{
                fontSize: 20,
                color: '#596B7B',
                marginRight: '10px',
              }}
            />
          ) : (
            <FemaleOutlined
              sx={{
                fontSize: 20,
                color: '#596B7B',
                marginRight: '10px',
              }}
            />
          )}
          <InfoText>{pronoun}</InfoText>
        </CenterBox>
      </InfoBox>

      {/* Informações do responsável  */}
      {patient.caregiver && (
        <InfoBox>
          <InfoTitle>Informações do responsável</InfoTitle>
          <CenterBox>
            <GroupOutlined
              sx={{
                fontSize: 20,
                color: '#596B7B',
                marginRight: '10px',
              }}
            />
            <InfoText>{`${patient.caregiver.firstName} ${patient.caregiver.lastName} (${patient.caregiver.relation.name})`}</InfoText>
          </CenterBox>
          <CenterBox>
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              sx={{ marginRight: '10px' }}
            >
              <img src={cpfIcon} height='14px' alt='Logo CPF' />
            </Box>
            <InfoText>{formatCpf(patient.user.cpf)}</InfoText>
          </CenterBox>
        </InfoBox>
      )}

      {/* Contato  */}
      <InfoBox>
        <InfoTitle>Contato</InfoTitle>
        {patient.user?.email && (
          <CenterBox>
            <AlternateEmailOutlined
              sx={{
                fontSize: 20,
                color: '#596B7B',
                marginRight: '10px',
              }}
            />
            <InfoText>{patient.user.email}</InfoText>
            {patient.user.communication.includes('email') && (
              <Tooltip title='canal validado'>
                <StyledVerifiedIcon />
              </Tooltip>
            )}
          </CenterBox>
        )}

        {patient.user?.whatsapp && (
          <CenterBox>
            <LocalPhoneOutlined
              sx={{
                fontSize: 20,
                color: '#596B7B',
                marginRight: '10px',
              }}
            />
            <InfoText>
              +{patient.user.ddi} {formatPhoneBr(patient.user.whatsapp)}
            </InfoText>
            {patient.user.communication.includes('whatsapp') && (
              <Tooltip title='canal validado'>
                <StyledVerifiedIcon />
              </Tooltip>
            )}
          </CenterBox>
        )}
      </InfoBox>

      {/* Convidados  */}
      {Boolean(patientInformants.length) && (
        <InfoBox>
          <InfoTitle>Convidados</InfoTitle>
          {patientInformants.map(({ informant, relation }: any, i: number) => {
            const informantName = `${informant?.personalInfo?.firstName} ${informant?.personalInfo?.lastName}`
            return (
              <CenterBox key={i}>
                <PersonOutlined
                  sx={{
                    fontSize: 20,
                    color: '#596B7B',
                    marginRight: '10px',
                  }}
                />
                <InfoText>{`${informantName} (${relation.name})`}</InfoText>
              </CenterBox>
            )
          })}
        </InfoBox>
      )}

      <InfoBox>
        <InfoTitle>Status</InfoTitle>
        <Stack direction='row' alignItems='center' spacing={1}>
          <Tooltip
            title={`${isActive ? 'Arquivar' : 'Desarquivar'} paciente`}
            placement='top'
            arrow
          >
            <SwitchStyled
              checked={isActive}
              onChange={(e) => handleStatusChange(e.target.checked)}
              color='secondary'
            />
          </Tooltip>
          <StatusLabelStyled>
            Paciente {isActive ? 'ativo' : 'arquivado'}
          </StatusLabelStyled>
        </Stack>
      </InfoBox>

      <SecondaryConfirmationModal
        isOpen={isModalOpen}
        title='Deseja mesmo arquivar este paciente?'
        text='Caso haja questionários não respondidos
        ou recorrências ativas, os mesmos 
        serão cancelados'
        onClose={() => setIsModalOpen(false)}
        onConfirm={onConfirm}
      />
    </>
  )
}
export default PatientInfo
