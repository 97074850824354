import { answersDictFromBack } from 'helpers/constants'
import { extractTagsFromAnswers } from 'helpers'

const formatQuestionWithAnswerFromBack = (question: any) => {
  return {
    id: question.id,
    ultraShortVersion: question.ultraShortVersion || '',
    shortVersionM: question.shortVersionM || '',
    shortVersionF: question.shortVersionF || '',
    questionSelfM: question.questionSelfM || '',
    questionSelfF: question.questionSelfF || '',
    question3rdM: question.question3rdM || '',
    question3rdF: question.question3rdF || '',
    block: question.block || '',
    tags: question.tags || [],
    questionTags: question.questionTags,
    answer: answersDictFromBack[String(question.answer?.answer || 'skip')],
  }
}

const formatUserResponseFromBack = (response: any) => {
  return {
    formDateCreate: response.createdAt || '',
    formDateUpdate: response.updatedAt || '',
    formRequestId: response.formRequestId,
    formId: response.formId,
    responseDate: response.responseDate || response.updatedAt,
    tags:
      response?.tags?.length > 0
        ? response.tags
        : extractTagsFromAnswers(response.questions),
    questions: response.questions.map(formatQuestionWithAnswerFromBack),
    viewed: response.viewed,
  }
}

export const formatUserResponsesFromBack = (responses: any) => {
  const validResponses = responses
    .filter((response: any) => response.isCompleted)
    .map((form: any) => ({
      ...form,
      questions: form.questions,
    }))

  return validResponses.map(formatUserResponseFromBack)
}
