import { useForm } from 'react-hook-form'
import { clinicProfileMyInfoGeneralResolver } from 'resolvers'

function useMyInfoGeneralSectionForm({ user, clinic }: any) {
  const methods = useForm<any>({
    mode: 'onBlur',
    resolver: clinicProfileMyInfoGeneralResolver,
    defaultValues: {
      cpf: user.cpf,
      firstName: clinic.firstName,
      lastName: clinic.lastName,
      professionalCouncil: clinic.professionalCouncil,
      professionalCouncilState: clinic.professionalCouncilState,
      register: clinic.register,
    },
  })

  return methods
}

export default useMyInfoGeneralSectionForm
