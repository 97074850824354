export const BLOCK_MIN_QUESTION_NUMBER = 5

export const blockOrderDict: any = {
  'ações e comportamentos': 0,
  comunicação: 1,
  // conflitos: 2,
  // agitação: 3,
  'foco e concentração': 4,
  // 'pensamentos e sentimentos': 5,
  // 'interação social': 6,
  // sono: 7,
  'agitação e impulsividade': 8,
  pensamentos: 9,
  sentimentos: 10,
  relacionamentos: 11,
}
