import { Typography } from '@mui/material'
import DialogMui from '@mui/material/Dialog'
import { styled } from '@mui/material/styles'

export const Dialog = styled(DialogMui)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '16px',
  },
  '& .MuiDialogTitle-root': {
    marginTop: theme.spacing(4),
  },
  '& .MuiDialogActions-root': {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export const Title = styled(Typography)({
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '30px',
  color: '#07B2A5',
})

export const SummaryText = styled(Typography)({
  marginTop: '8px',
  fontSize: '16px',
  lineHeight: '24px',
  color: '#9D9CB5',
})

export const WhatsAppWebText = styled(Typography)({
  marginRight: '2px',
  fontSize: '16px',
  lineHeight: '24px',
  color: '#9D9CB5',
})
