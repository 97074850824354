import AccessStep from './access-step'
import PersonalInfoStep from './personal-info-step'
import AcceptAndPasswordStep from './accept-and-password-step'
import ProfessionalAndContactInfoStep from './professional-and-contact-info-step'
import WelcomeStep from './welcome-step'
import EmailVerificationCodeStep from './email-verification-code-step'
import { ClinicStepType } from 'types'

export const signUpClinicSteps: ClinicStepType[] = [
  {
    component: <AccessStep />,
    fields: ['cpf'],
  },
  {
    component: <PersonalInfoStep />,
    fields: ['firstName', 'lastName'],
  },
  {
    component: <ProfessionalAndContactInfoStep />,
    fields: [
      'professionalCouncil',
      'professionalCouncilState',
      'register',
      'email',
      'whatsapp',
    ],
  },
  {
    component: <EmailVerificationCodeStep />,
    fields: ['emailValidationCode'],
  },
  {
    component: <AcceptAndPasswordStep />,
    fields: ['password', 'confirmPassword'],
  },
  {
    component: <WelcomeStep />,
    fields: [],
  },
]
