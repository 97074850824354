import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

export const AccordionTitle = styled(Typography)({
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '21px',
  color: '#828197',
})

export const TdNormal = styled('td')({
  borderBottom: '2px solid #E8EBF2',
  padding: '16px',
})
