import { styled } from '@mui/material/styles'
import { TableRow, Typography } from '@mui/material'
import { StatusTagProps } from './patient-list-types'

export const PatientTableRow = styled(TableRow)({
  zIndex: '0',
  cursor: 'pointer',
  transition: '0.4s',
  '&:hover': {
    opacity: '0.7',
    backgroundColor: '#F9F9FB',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  td: {
    fontFamily: "'Zen Kaku Gothic Antique', sans-serif",
    fontSize: '16px',
  },
})

export const StatusTag = styled('span')<StatusTagProps>(({ isNew }) => ({
  fontSize: '10px',
  fontFamily: 'QuickSand',
  fontWeight: '600',
  color: 'white',
  padding: '3.5px 8px 3.5px 9px',
  borderRadius: '4px',
  marginLeft: '15px',
  backgroundColor: isNew ? '#01B3A7' : '#B6B6B6',
}))

export const ReminderText = styled(Typography)({
  color: '#596B7B',
  textAlign: 'center',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Zen Kaku Gothic Antique',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '28px',
})
