import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const HeaderTitle = styled(Typography)({
  fontFamily: 'Quicksand',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '26px',
  color: '#ffffff',
})

export const HeaderDescription = styled(Typography)({
  fontFamily: 'Zen Kaku Gothic Antique',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '23px',
  color: '#ffffff',
})

export const Title = styled(Typography)({
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '21px',
  color: '#657787',
})
