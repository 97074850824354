import Box from '@mui/material/Box'
import StepperMui from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import { Connector, StepLabel, stepLabelStyle } from './stepper-styles'

interface IStepperProps {
  activeStep: number
  setActiveStep: (arg0: number) => void
  steps: string[]
}

// TODO mudar para regra de volta e bloqueio de steps serem definidos por props para ficar genérico para reuso

function Stepper({
  steps,
  activeStep,
  setActiveStep,
}: IStepperProps): JSX.Element {
  // Align step numbers
  const allText = document.querySelectorAll('text')
  for (const text of allText) {
    text.setAttribute('y', '17')
  }

  return (
    <Box>
      <StepperMui activeStep={activeStep} connector={<Connector />}>
        {steps.map((label, index) => {
          return (
            <Step
              key={label}
              onClick={() => {
                if (index >= activeStep || activeStep === steps.length - 1)
                  return
                setActiveStep(index)
              }}
              sx={{
                cursor:
                  index < activeStep && activeStep !== steps.length - 1
                    ? 'pointer'
                    : 'normal',
              }}
            >
              <StepLabel sx={stepLabelStyle}>{label}</StepLabel>
            </Step>
          )
        })}
      </StepperMui>
    </Box>
  )
}

export default Stepper
