import { CSSProperties } from 'react'

interface ReportIconProps {
  color?: string
  style?: CSSProperties
}

const ReportIcon = ({ color = '#8D92BE', style }: ReportIconProps) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      style={style}
    >
      <path d='M12.4992 20.2C12.1992 20.2 11.9992 20.2 11.6992 20.2H6.19922C5.39922 20.2 4.69922 19.5 4.69922 18.7V7.19995C4.69922 6.39995 5.39922 5.69995 6.19922 5.69995H7.29922C7.59922 6.99995 8.79922 7.89995 10.0992 7.89995H11.7992C13.0992 7.89995 14.2992 6.99995 14.5992 5.69995H15.7992C16.5992 5.69995 17.2992 6.39995 17.2992 7.19995V12C17.2992 12.2 17.2992 12.5 17.2992 12.7C17.2992 12.8 17.2992 13.1 17.4992 13.3C17.6992 13.5 17.8992 13.5 17.9992 13.5C18.0992 13.5 18.2992 13.5 18.4992 13.3C18.5992 13.2 18.6992 13 18.6992 12.8V7.19995C18.6992 5.49995 17.3992 4.19995 15.6992 4.19995H14.3992C13.9992 2.99995 12.9992 2.19995 11.6992 2.19995H9.99922C8.69922 2.19995 7.69922 2.99995 7.29922 4.19995H6.19922C4.49922 4.19995 3.19922 5.49995 3.19922 7.19995V18.7C3.19922 20.4 4.49922 21.7 6.19922 21.7H12.3992C12.7992 21.7 13.1992 21.4 13.1992 20.9C13.1992 20.8 13.1992 20.6 12.9992 20.4C12.7992 20.2 12.4992 20.2 12.4992 20.2ZM10.0992 3.79995H11.7992C12.4992 3.79995 13.0992 4.39995 13.0992 5.09995C13.0992 5.79995 12.4992 6.39995 11.7992 6.39995H10.0992C9.39922 6.39995 8.79922 5.79995 8.79922 5.09995C8.79922 4.39995 9.39922 3.79995 10.0992 3.79995Z' />
      <path d='M12.7988 11C12.7988 10.6 12.4988 10.2 11.9988 10.2H7.59883C7.19883 10.2 6.79883 10.5 6.79883 11C6.79883 11.5 7.09883 11.8 7.59883 11.8H11.9988C12.3988 11.8 12.7988 11.4 12.7988 11Z' />
      <path d='M7.59883 14.2C7.19883 14.2 6.79883 14.5 6.79883 15C6.79883 15.5 7.09883 15.8 7.59883 15.8H10.8988C11.2988 15.8 11.6988 15.5 11.6988 15C11.6988 14.5 11.3988 14.2 10.8988 14.2H7.59883Z' />
      <path d='M21.6984 21.5999L19.2984 19.1999C19.5984 18.6999 19.7984 18.1999 19.7984 17.5999C19.7984 15.8999 18.3984 14.3999 16.5984 14.3999C14.7984 14.3999 13.3984 15.7999 13.3984 17.5999C13.3984 19.3999 14.7984 20.7999 16.5984 20.7999C17.1984 20.7999 17.7984 20.5999 18.2984 20.2999L20.6984 22.6999C20.7984 22.7999 20.9984 22.8999 21.1984 22.8999C21.3984 22.8999 21.5984 22.7999 21.6984 22.6999C21.9984 22.2999 21.9984 21.7999 21.6984 21.5999ZM16.4984 19.1999C15.5984 19.1999 14.7984 18.3999 14.7984 17.4999C14.7984 16.5999 15.5984 15.7999 16.4984 15.7999C17.3984 15.7999 18.1984 16.5999 18.1984 17.4999C18.1984 18.3999 17.3984 19.1999 16.4984 19.1999Z' />
    </svg>
  )
}

export default ReportIcon
