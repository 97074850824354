import { useState } from 'react'
import { Box, Stack, Tooltip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import yesIcon from 'images/icons/yes.svg'
import sosoIcon from 'images/icons/soso.svg'
import noIcon from 'images/icons/no.svg'
import { deletePatientAttention } from 'services'
import { usePatientCentralContext } from 'contexts'
import { answersDictFromBack } from 'helpers/constants'
import {
  BookmarkIconStyled,
  Title,
  QuestionText,
} from './patient-attentions-styles'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import { formatDbDateToLabel } from 'helpers'

const answers: any = {
  no: { alt: 'Ícone de resposta não', icon: noIcon, label: 'NÃO' },
  soso: { alt: 'Ícone de resposta talvez', icon: sosoIcon, label: 'TALVEZ' },
  yes: { alt: 'Ícone de resposta sim', icon: yesIcon, label: 'SIM' },
}

const PatientAttentions = () => {
  const [hoverAttention, setHoverAttention] = useState(null)
  const { patientAttentions = [], fetchPatientAttentions } =
    usePatientCentralContext()

  const handleRemove = (patientAttentionId: string) => async () => {
    try {
      await deletePatientAttention(patientAttentionId)

      await fetchPatientAttentions()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <Stack direction='row' alignItems='center' mb={1}>
        <BookmarkIconStyled />
        <Title>Focos de atenção</Title>
      </Stack>
      <Box mt={1} sx={{ overflowY: 'auto' }}>
        <Stack ml={1}>
          {!Boolean(patientAttentions.length) && (
            <Stack direction='row' alignItems='center'>
              <QuestionText>Não há focos de atenção selecionados.</QuestionText>
              <Tooltip
                title={
                  <>
                    <span>
                      Você pode destacar focos de atenção para o paciente
                      marcando (
                    </span>

                    <BookmarkBorderIcon
                      sx={{
                        display: 'inline',
                        height: '14px',
                        width: '14px',
                        marginBottom: '-2px',
                      }}
                    />
                    <span>) os itens desejados na seção de Resultados</span>
                  </>
                }
              >
                <InfoOutlinedIcon
                  sx={{
                    height: '14px',
                    width: '14px',
                    ml: '4px',
                    fill: '#657787',
                  }}
                />
              </Tooltip>
            </Stack>
          )}

          {patientAttentions.map((patientAttention: any) => {
            const hasMouseOver = hoverAttention === patientAttention.id

            return (
              <Stack
                key={patientAttention.id}
                direction='row'
                alignItems='center'
                onMouseEnter={() => {
                  setHoverAttention(patientAttention.id)
                }}
                onMouseLeave={() => {
                  setHoverAttention(null)
                }}
              >
                <CloseIcon
                  sx={{
                    width: '12px',
                    mr: '8px',
                    color: hasMouseOver ? '#37405D' : 'transparent',
                    cursor: hasMouseOver ? 'pointer' : 'default',
                  }}
                  onClick={
                    hasMouseOver ? handleRemove(patientAttention.id) : () => {}
                  }
                />
                <Box width='200px' display='flex' alignItems='center'>
                  <QuestionText>
                    {patientAttention.question.ultraShortVersion}
                  </QuestionText>
                </Box>
                <Stack direction='row' justifyContent='flex-start'>
                  {patientAttention.answers.slice(-5).map((answer: any) => (
                    <Tooltip
                      title={formatDbDateToLabel(answer.responseDate)}
                      placement='top'
                      arrow
                    >
                      <Box mr={0.5} display='flex' alignItems='center'>
                        <img
                          src={answers[answersDictFromBack[answer.answer]].icon}
                          height='6px'
                          alt={answers[answersDictFromBack[answer.answer]].alt}
                        />
                      </Box>
                    </Tooltip>
                  ))}
                </Stack>
              </Stack>
            )
          })}
        </Stack>
      </Box>
    </>
  )
}

export default PatientAttentions
