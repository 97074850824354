const PaymentDataIcon = ({ color = '#657787' }: { color?: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M15.8636 14.5833C15.7061 14.5833 15.555 14.6492 15.4436 14.7664C15.3323 14.8836 15.2697 15.0426 15.2697 15.2083C15.2697 15.3741 15.3323 15.5331 15.4436 15.6503C15.555 15.7675 15.7061 15.8333 15.8636 15.8333H18.6349C18.7924 15.8333 18.9434 15.7675 19.0548 15.6503C19.1661 15.5331 19.2287 15.3741 19.2287 15.2083C19.2287 15.0426 19.1661 14.8836 19.0548 14.7664C18.9434 14.6492 18.7924 14.5833 18.6349 14.5833H15.8636ZM3 8.125C3 7.2962 3.31283 6.50134 3.86967 5.91529C4.42651 5.32924 5.18175 5 5.96925 5H19.0308C19.8182 5 20.5735 5.32924 21.1303 5.91529C21.6872 6.50134 22 7.2962 22 8.125V16.875C22 17.7038 21.6872 18.4987 21.1303 19.0847C20.5735 19.6708 19.8182 20 19.0308 20H5.97004C5.18254 20 4.42731 19.6708 3.87046 19.0847C3.31362 18.4987 3.00079 17.7038 3.00079 16.875V8.125H3ZM5.96925 6.25C5.49675 6.25 5.04361 6.44754 4.7095 6.79917C4.3754 7.15081 4.1877 7.62772 4.1877 8.125V8.75H20.8123V8.125C20.8123 7.62772 20.6246 7.15081 20.2905 6.79917C19.9564 6.44754 19.5033 6.25 19.0308 6.25H5.96925ZM4.1877 16.875C4.1877 17.3723 4.3754 17.8492 4.7095 18.2008C5.04361 18.5525 5.49675 18.75 5.96925 18.75H19.0308C19.5033 18.75 19.9564 18.5525 20.2905 18.2008C20.6246 17.8492 20.8123 17.3723 20.8123 16.875V10H4.18849V16.875H4.1877Z'
        fill={color}
      />
    </svg>
  )
}

export default PaymentDataIcon
