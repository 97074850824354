import { useNavigate } from 'react-router-dom'
import { Box, Stack } from '@mui/material'
import {
  HeaderContainer,
  HeaderText,
  HealthProfessionalLink,
} from './header-login-patient-group-styles'
import logoBW from 'images/wida-logo.svg'

function HeaderLoginPatientGroup(): JSX.Element {
  const navigate = useNavigate()

  return (
    <HeaderContainer>
      <Box display='flex' justifyContent='center' mt={8} mb={12}>
        <a href='https://www.wida.app' target='_blank' rel='noreferrer'>
          <img
            src={logoBW}
            height='30px'
            alt='Logo Wida'
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          />
        </a>
      </Box>
      <Stack spacing={2} mb={3}>
        <HeaderText>Acesse sua conta</HeaderText>
        <HealthProfessionalLink onClick={() => navigate('/signin/clinic')}>
          Entrar como profissional de saúde
        </HealthProfessionalLink>
      </Stack>
    </HeaderContainer>
  )
}

export default HeaderLoginPatientGroup
