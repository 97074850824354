import { useRef } from 'react'
import { Box } from '@mui/material'
import { format } from 'date-fns'
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from '@mui/lab'
import { LogType, LogMessage, LogDate } from './log-timeline-styles'

function getDotColor(type: string) {
  return (
    {
      clinic: '#657787',
      informant: '#9D9CB5',
      patient: '#6CC1B4',
      caregiver: '#F09C92',
    }[type] || '#BDBDBD'
  )
}

function getLogType(type: string) {
  return (
    {
      clinic: 'Clínico',
      informant: 'Informante',
      patient: 'Paciente',
      caregiver: 'Responsável',
    }[type] || 'Sistema'
  )
}

function LogTimeline({ logs }: any): JSX.Element {
  // usado pra calcular a altura maxima dos logs
  const logRef = useRef<any>()
  const logHeight = `calc(70vh - ${logRef.current?.offsetTop || 0}px)`

  const logsLength = logs.length

  return (
    <Timeline
      sx={{
        paddingBottom: '30px',
        maxHeight: logHeight,
        overflow: 'scroll',
        '& li:before': {
          content: 'none',
        },
      }}
    >
      {logs?.map(({ type, message, createdAt }: any, i: number) => (
        <TimelineItem key={i}>
          <TimelineSeparator>
            <TimelineDot
              sx={{
                backgroundColor: getDotColor(type),
                boxShadow: 'none',
                borderWidth: '4px',
              }}
            />
            {i !== logsLength - 1 && (
              <TimelineConnector sx={{ backgroundColor: '#ECECEC' }} />
            )}
          </TimelineSeparator>
          <TimelineContent>
            <Box mb={2}>
              <LogType>{getLogType(type)}</LogType>
              <LogMessage>{message}</LogMessage>
              <LogDate>
                {format(new Date(createdAt), 'dd/MM/yyyy HH:mm')}
              </LogDate>
            </Box>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  )
}
export default LogTimeline
