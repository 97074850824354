import { createContext, useContext } from 'react'

interface SignUpInformantContextType {
  goNextStep: () => void
  invitationInfos: any
  sendValidationCode: (arg0: any, arg1: any) => void
  loadingSendCode: boolean
  relationOptions: any
}

export const SignUpInformantContext = createContext<SignUpInformantContextType>(
  {
    invitationInfos: {},
    goNextStep: () => {},
    sendValidationCode: () => {},
    loadingSendCode: false,
    relationOptions: [],
  }
)

export const useSignUpInformantContext = () => {
  const context = useContext(SignUpInformantContext)
  return context
}
